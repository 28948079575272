import React, { useEffect, useState } from 'react'
import { observer } from "mobx-react";
import { Card, Col, Form, Row, Spin, Tooltip, Checkbox } from 'antd';
import InputComponent from '../../../../../components/InputComponent';
import { vsmTrigger } from '../../../../../config/messages';
import useStore from '../../../../../store';
import { InfoCircleOutlined } from '@ant-design/icons'
import Cron from "react-js-cron";
import debounce from 'lodash/debounce';

const FormComponent = observer(({ form, handleSubmit, formDataList, setFetchMapping, fetchMapping, setFetchStatus, fetchStatus, visible, setFormDataList, setDisabled, value, setValue }) => {
	const { TriggerStore, TransactionFieldMappingStore, AllTransactionStore } = useStore()
	const [callChange, setCallChange] = useState(false)
	const [loader, setLoader] = useState(false)

	const handleChange = debounce(async (data, type = null) => {
		if (callChange || type !== 'cron') {
			await setFormDataList(TriggerStore.GetFormDataList(form))
			form.validateFields().then(() => {
				setDisabled(false)
			}).catch(e => {
				if (e.errorFields.length > 0) {
					setDisabled(true)
				}
			})
		}
		if (!callChange) {
			setCallChange(true)
		}
	}, 200)

	const onFinish = (data) => {
		handleSubmit(data)
	}

	const getModalList = (type = null) => {
		var tempDataList = TriggerStore.GetFormDataList(form)
		var mapping_id = 0
		if (tempDataList.includes('mapping') && form.getFieldValue('mapping')) {
			mapping_id = form.getFieldValue('mapping')
		} else if (tempDataList.includes('transaction_status') && form.getFieldValue('transaction_status')) {
			mapping_id = null
		} else if (tempDataList.includes('time_schedule')) {
			mapping_id = null
		}

		if (mapping_id === 0) {
			setModalData(null)
		} else {
			if ((type === 'transaction_status' && mapping_id === null) || (type === null)) {
				setLoader(true)
				TriggerStore.getModalList(mapping_id).then((data) => {
					if (JSON.stringify(data) !== JSON.stringify(TriggerStore.currentModals && TriggerStore.currentModals.map(x => ({ id: x.id, model_name: x.model_name })))) {
						setModalData(data)
					}
					setLoader(false)
				}).finally(() => setLoader(false))
			}
		}
	}

	const setModalData = (data) => {
		TriggerStore.currentModals = data
		data && data.forEach(x => x.date = 'current date')
		form.setFieldsValue({ selected_models: data })
	}

	// Set the form values to on change
	useEffect(() => {
		if (visible) {
			var data = TriggerStore.GetFormDataList(form)
			if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
				setFormDataList(data)
			}
		}
	}, [form, TriggerStore, setFormDataList, formDataList, visible])

	const CheckboxComponent = ({ name, onChange, form }) => {
		return (
			<Form.List name={name}>
				{
					(fields) => {
						if (fields && fields.length > 0) {
							return (
								<div className='d-flex'>
									<Card title="Select Models to Apply" className='w-100'>
										<Spin spinning={loader}>
											{
												<Row gutter={24}>
													{fields.map((field, index) => {
														return (
															<Col span={12} key={field.key}>
																<Row gutter={24}>
																	<Col span={12}>
																		<Form.Item
																			valuePropName="checked"
																			noStyle
																			name={[field.name, "model"]}
																			key={[field.key, "model"]}
																			initialValue={false}
																		>
																			<Checkbox onChange={onChange}>{form.getFieldValue(name)[index] && form.getFieldValue(name)[index]['model_name']}</Checkbox>
																		</Form.Item>
																	</Col>
																	<Col span={12}>
																		<InputComponent
																			required
																			type="select"
																			initialValue={"current date"}
																			options={{
																				values: TriggerStore.checkBox_dropdown,
																			}}
																			onChange={onChange}
																			name={[field.name, 'date']}
																			key={[field.key, 'date']}
																			placeholder="Select DB Field"
																		/>
																	</Col>
																</Row>
															</Col>
														)
													})}
												</Row>
											}
										</Spin>
									</Card>
									<div className='mt-10'>
										<Tooltip placement="topRight" title={"Based on your mapping selection, specific Models will be displayed. Selected models will be applied on each transaction to generate journal logs for those transactions."}>
											<InfoCircleOutlined className="ml-10" />
										</Tooltip>
									</div>
								</div>
							)
						}
						return null
					}
				}
			</Form.List>
		)
	}

	return (
		<Form
			form={form}
			id={"addeditform"}
			onFinish={onFinish}
			layout='vertical'
			initialValues={TriggerStore.initialValues}
		>
			<InputComponent
				required
				placeholder="Enter trigger name"
				label="Trigger Name"
				name='trigger_name'
				rules={vsmTrigger.validation.trigger_name}
				onChange={handleChange}
				maxLength={100}
				tooltip="Trigger Name is one of the basic parameters for defining a Trigger. Name must be unique and alphanumerical."
			/>

			<Row gutter={24}>
				<Col span={12}>
					<InputComponent
						required
						type="select"
						mode="multiple"
						placeholder="Select when to execute the trigger"
						label="When to execute"
						name='when_to_execute'
						rules={vsmTrigger.validation.when_to_execute}
						onChange={() => { getModalList(); handleChange() }}
						options={{
							values: [{ value: 'Import', text: 'Import' }, { value: 'New Transaction', text: 'New Transaction' }, { value: 'Transaction Status Change', text: 'Transaction Status Change' }, { value: 'Timely Schedule', text: 'Timely Schedule' }]
						}}
						tooltip="Trigger will be executed on the selected option that refers to new inserts, updates of existing Transaction data in database and causes Journal entries to be created with accordance to the selected Transaction Model(s)."
					/>
				</Col>
				<Col span={12}>
					<InputComponent
						required
						allowClear
						type="select"
						placeholder="Select what to execute"
						label="What to execute"
						name='what_to_execute'
						rules={vsmTrigger.validation.what_to_execute}
						onChange={handleChange}
						options={{
							values: [{ value: 'Execute Model', text: 'Execute Model' }]
						}}
						tooltip="The Executable item list, for example: “Model“ on which the Trigger will be executed."
					/>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={12}>
					{(formDataList.includes('mapping')) && (
						<InputComponent
							required
							allowClear
							type="select"
							placeholder="Select Trade Transaction Name"
							label="Trade Transaction Name"
							name='mapping'
							rules={vsmTrigger.validation.mapping}
							onChange={(value) => {
								getModalList()
								handleChange()
							}}
							onFocus={() => fetchMapping && TransactionFieldMappingStore.getAllRecords().then(() => setFetchMapping(false))}
							notFoundContent={fetchMapping ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: TransactionFieldMappingStore.dropdown_list || (TriggerStore.editValues && TriggerStore.editValues.field_mapping && [{
									id: TriggerStore.editValues.field_mapping.id,
									mapping_name: TriggerStore.editValues.field_mapping.mapping_name
								}]),
								value_key: 'id',
								text_key: 'mapping_name',
							}}
							tooltip="Select Trade Transaction Name which is combination of Trade Type & Transaction Type. It Defines which fields and models will be generated on imported transactions."
						/>
					)}
				</Col>
				<Col span={12}>
					{(formDataList.includes('transaction_status')) && (
						<InputComponent
							required
							allowClear
							type="select"
							placeholder="Select status"
							label="Transaction Status"
							name='transaction_status'
							rules={vsmTrigger.validation.transaction_status}
							onChange={(value) => {
								getModalList('transaction_status')
								handleChange()
							}}
							onFocus={() => fetchStatus && AllTransactionStore.getStatusList().then(() => setFetchStatus(false))}
							notFoundContent={fetchStatus ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: AllTransactionStore.status_dropdown || (TriggerStore.editValues && TriggerStore.editValues.tte_status && [{
									id: TriggerStore.editValues.tte_status.id,
									status_name: TriggerStore.editValues.tte_status.status_name
								}]),
								value_key: "id",
								text_key: "status_name"
							}}
							tooltip="It is a defined status of a transaction that may have post executable actions, possible values are “Requested”, “Posted”, “Approved”, “Traded”, etc."
						/>
					)}
				</Col>
			</Row>

			{((form.getFieldValue('when_to_execute') || []).includes('Timely Schedule')) && (
				<Form.Item label='Timely Schedule' required>
					<Form.Item noStyle >
						<Cron value={value} setValue={async (x) => { await setValue(x); handleChange(x, 'cron') }} allowEmpty={false} />
						<Form.Item noStyle name="time_schedule" />
					</Form.Item>
					<Tooltip title="To select the week, day, date, time for the schedule.">
						<InfoCircleOutlined className="ml-10" />
					</Tooltip>
				</Form.Item>
			)}

			<CheckboxComponent
				name="selected_models"
				dropdown={TriggerStore.checkBox_dropdown}
				onChange={handleChange}
				form={form}
			/>

		</Form>
	)
})

export default FormComponent
