import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppsBreadcrumbConfig } from '../../../../config/BreadcrumbConfig';
import AppsBreadcrumbComponent from '../../../../components/Originator/AppsBreadcrumbComponent';
import useStore from '../../../../store';
import Unauthorized from '../../../Unauthorized';
import { Button, PageHeader } from 'antd';

const TradeFloor = observer(() => {
    const { AppsAuthStore } = useStore()
    const [tradeDrawer, setOpenTradeDrawer] = useState(false)

    const openTradeDrawer = () => {
        setOpenTradeDrawer(true)
    }

    return (
        <>
            <AppsBreadcrumbComponent items={AppsBreadcrumbConfig.TradeFloor.path} />
            {(!AppsAuthStore.checkPermission(88, 'list')) ? (<Unauthorized />) : (
                <PageHeader
                    className={AppsAuthStore.fullscreen_class}
                    title={AppsBreadcrumbConfig.TradeFloor.title}
                    extra={[
                        (AppsAuthStore.checkPermission(88, 'add')) && <Button key="1" onClick={openTradeDrawer}>New</Button>
                    ]}
                >
                    {tradeDrawer}
                </PageHeader>
            )}
        </>
    );
})

export default TradeFloor;