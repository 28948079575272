import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Form, Row, Spin } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { vsmMember } from '../../../../config/messages'
import useStore from '../../../../store'

const RatingAgencyElement = observer(({ form, onChange }) => {
	const { CredebtorStore: { ratingAgencyList, getRatingAgency } } = useStore()
	const [ratingAgency, setUatingAgency] = useState(true)

	return (
		<div className='w-100'>
			<Form.List name='rating_agency'>
				{(fields, { add, remove }) => {
					return (
						<>
							{fields.map((field, index) => {
								return (
									<div className='w-100' key={field.key}>
										<Row gutter={24}>
											<Col span={6}>
												<InputComponent
													type='select'
													placeholder={'Select Rating Agency'}
													label='Rating Agency'
													name={[field.name, 'rating_agency_name']}
													key={[field.key, 'rating_agency_name']}
													onChange={onChange}
													options={{
														values: ratingAgencyList,
														value_key: 'id',
														text_key: 'rating_agency_name'
													}}
													onFocus={() => ratingAgency && getRatingAgency().then(() => setUatingAgency(false))}
													notFoundContent={ratingAgency ? <Spin size='small' /> : 'No Record Found.'}
													autoComplete="off"
													tooltip='An organization that gives ratings  to people, companies, or countries according to how safe it is to lend money to them'
												/>
											</Col>
											<Col span={18}>
												<Form.Item dependencies={[["rating_agency", field.name, "rating_agency_name"]]}>
													{({ getFieldValue }) => {
														return (
															getFieldValue("rating_agency") && getFieldValue("rating_agency")[field.name] && getFieldValue("rating_agency")[field.name]["rating_agency_name"] && getFieldValue("rating_agency")[field.name]["rating_agency_name"] ?
																<Row gutter={24}>
																	<Col span={4}>
																		<InputComponent
																			placeholder='Rate'
																			label='Rate'
																			name={[field.name, 'rate']}
																			key={[field.key, 'rate']}
																			onChange={onChange}
																			tooltip='Rate'
																			rules={vsmMember.validation.rating_agency_rate}
																		/>
																	</Col>
																	<Col span={4}>
																		<InputComponent
																			placeholder='Limit'
																			label='Limit'
																			name={[field.name, 'limit']}
																			key={[field.key, 'limit']}
																			onChange={onChange}
																			tooltip='Limit'
																			rules={vsmMember.validation.rating_agency_limit}
																		/>
																	</Col>
																	<Col span={4}>
																		<InputComponent
																			placeholder='Contract'
																			label='Contract'
																			name={[field.name, 'contract']}
																			key={[field.key, 'contract']}
																			onChange={onChange}
																			tooltip='Contract'
																			rules={vsmMember.validation.rating_agency_contract}
																		/>
																	</Col>
																	<Col span={4}>
																		<InputComponent
																			placeholder='Long Term'
																			label='Long Term'
																			name={[field.name, 'long_term']}
																			key={[field.key, 'long_term']}
																			onChange={onChange}
																			tooltip='Long Term'
																			rules={vsmMember.validation.rating_agency_long_term}
																		/>
																	</Col>
																	<Col span={4}>
																		<InputComponent
																			placeholder='Short Term'
																			label='Short Term'
																			name={[field.name, 'short_term']}
																			key={[field.key, 'short_term']}
																			onChange={onChange}
																			tooltip='Short Term'
																			rules={vsmMember.validation.rating_agency_short_term}
																		/>
																	</Col>
																	<Col span={4}>
																		<div className="d-flex mb-20">
																			{
																				(fields.length > 1) && (
																					<Button type="danger" onClick={() => {
																						remove(field.name)
																					}} icon={<MinusOutlined />}></Button>
																				)
																			}
																			{(index === (fields.length - 1)) && (fields.length < 12) && (
																				<Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
																					await add({ rating_agency_name: null })
																					onChange()
																				}} icon={<PlusOutlined />} />
																			)}
																		</div>
																	</Col>
																</Row>
																: null
														)
													}}
												</Form.Item>
											</Col>
										</Row>
									</div>
								)
							})}
						</>
					)
				}}
			</Form.List>

		</div>
	)
})

export default RatingAgencyElement