import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../store';

const EditComponent = observer((props) => {
    const [isDisabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formDataList, setFormDataList] = useState([])
    const [selectMultiple, setSelectMultiple] = useState([])
    const [selectSingle, setSelectSingle] = useState([])
    const [fetchWidget, setFetchWidget] = useState(true)
    const [fetchReport, setFetchReport] = useState(true)
    const defaultFormDataList = ['widget_type']
    const { DashboardStore } = useStore()
    const singleSelect = [{ key: 7, value: '7 days' }];

    const handleSubmit = (data) => {
        if (selectMultiple && selectMultiple.length > 0) {
            data.multi_select = selectMultiple.sort(function (a, b) { return a - b })
        }
        if (props.form.getFieldValue('extra_option') === 'single') {
            data.multi_select = [singleSelect[0].key]
        }
        if (props.form.getFieldValue('widget_type') === 'number') {
            data.type = 'number'
        }
        if (props.form.getFieldValue('widget_type') === 'reports') {
            data.type = 'reports'
        }
        setSaving(false)
        props.onEditItem(data)
    }

    const close = () => {
        setFetchWidget(true)
        setFetchReport(true)
        props.form.resetFields()
        props.close()
    }

    useEffect(() => {
        if (props.visible && props.apiData && props.apiData?.settings_json) {
            props.form.setFieldsValue({
                widget_type: DashboardStore.readData.settings_json.i.widget_type,
                extra_option: DashboardStore.readData.settings_json.i.extra_option,
                widget_id: DashboardStore.readData.settings_json.i.widget_id,
                title: DashboardStore.readData.settings_json.i.title,
                type: DashboardStore.readData.settings_json.i.type,
                single_select: DashboardStore.readData.settings_json.i?.single_select,
                multi_select: DashboardStore.readData.settings_json.i?.multi_select,
                x_value: DashboardStore.readData.settings_json.i?.x_value,
                y_value: DashboardStore.readData.settings_json.i?.y_value,
                report_id: DashboardStore.readData?.widget_id,
                report_fields: DashboardStore.readData.settings_json.i?.report_fields,
                range: DashboardStore.readData.settings_json.i?.range,
                default_selected: DashboardStore.readData.settings_json.i?.default_selected
            })
            if (props.apiData?.settings_json.i.extra_option === 'single') {
                setSelectSingle(props.apiData?.settings_json.i.multi_select)
            } else {
                setSelectMultiple(props.apiData?.settings_json.i.multi_select)
            }
        }
    }, [props.visible, setSelectSingle, setSelectMultiple, DashboardStore.readData.settings_json.i, props.apiData, props.form, DashboardStore.readData])

    return (
        <Modal
            centered
            visible={props.visible}
            onCancel={close}
            placement='right'
            title={`Edit Widget`}
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
                <div className="text-center">
                    <Button form='addEditForm' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <FormComponent
                form={props.form}
                setDisabled={setDisabled}
                handleSubmit={handleSubmit}
                defaultFormDataList={defaultFormDataList}
                formDataList={formDataList}
                setFormDataList={setFormDataList}
                initialValues={DashboardStore.initialValues}
                setSelectMultiple={setSelectMultiple}
                selectMultiple={selectMultiple}
                singleSelect={singleSelect}
                setFetchWidget={setFetchWidget}
                fetchWidget={fetchWidget}
                fetchReport={fetchReport}
                setFetchReport={setFetchReport}
                selectSingle={selectSingle}
                setSelectSingle={setSelectSingle}
            />
        </Modal>
    );
})

export default EditComponent;