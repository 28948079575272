import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../../store'
import { DateComparator } from '../../../../../utils/GlobalFunction'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(44, 'edit') && <Button type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>}
			{(!UserStore.checkPermission(44, 'delete')) ? null : (
				(props.data.count_in_peoples_count > 0) ? (
					<Tooltip placement="topRight" color={"red"} title={"Sorry, You can not delete this Role as it is associated with People"} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					<Tooltip title={"Delete"} >
						<Button type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
					</Tooltip>
				)
			)}

		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Role Name',
		field: 'role_name',
		valueGetter: (params) => (params.data && params.data.role_name) ? params.data.role_name : "",
		width: 150
	}, {
		headerName: 'No. of People',
		field: 'count_in_peoples_count',
		width: 130
	}, {
		headerName: 'Associated Role Name',
		field: 'associated_role',
		width: 150
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		valueGetter: (params) => (params.data && params.data.updated_by) ? params.data.updated_by : "",
		width: 185
	}, {
		headerName: 'Last Updated On',
		field: 'updated_on',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
