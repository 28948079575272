import { Checkbox, Col, Divider, Form, message, Row, Upload } from 'antd'
import React from 'react'
import { useState } from 'react'
import InputComponent from '../../../../components/InputComponent'
import { vsmCredebtor } from '../../../../config/messages'
import useStore from '../../../../store'
import { Credebtor_Credit_Risks } from '../../../../utils/GlobalFunction'
import RatingAgencyElement from '../element/RatingAgencyElement'
import { InboxOutlined } from '@ant-design/icons'

const CreditRisk = ({ form, setDisabled }) => {
	const [RavCheck, setRavCheck] = useState(false)
	const [Override180Check, setOverRide180Check] = useState(false)
	const { CredebtorStore: { creditRiskInitialValue } } = useStore()
	const [renderComp, setRenderComp] = useState(false)
	const [CreditSafeLogo, setCreditSafeLogo] = useState({ expReport: [], creditSafeReport: [] })

	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const percentagesNode = (e, name) => {
		let value = Number(e.target.value)
		if (value < 10) {
			form.setFieldsValue({ [name]: value.toFixed(3) })
		} else if (value > 10 && value < 100) {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		} else if (value <= 100) {
			form.setFieldsValue({ [name]: value.toFixed(1) })
		} else {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		}
	}


	const onRemoveLogo = () => {

	}

	const uploadPropsCreditSafeReport = {
		name: "creditSafeReport",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(CreditSafeLogo).length ? CreditSafeLogo['creditSafeReport'] ? CreditSafeLogo['creditSafeReport'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			setCreditSafeLogo(prev => ({ ...prev, creditSafeReport: newFileList ? newFileList : [] }))
		}
	}

	const handleFileUploadChangeCreditSafeReport = ({ file }) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1
		if (!isCSV) {
			message.error(`Experience Report must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const handleFileUploadChangeExperienceReport = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1
		if (!isCSV) {
			message.error(`Experience Report must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const uploadPropsExperienceReport = {
		name: "expReport",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(CreditSafeLogo).length ? CreditSafeLogo['expReport'] ? CreditSafeLogo['expReport'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			setCreditSafeLogo(prev => ({ ...prev, expReport: newFileList ? newFileList : [] }))
		}
	}


	return (
		<Form form={form} layout="vertical" initialValues={creditRiskInitialValue}>
			<Row gutter={24}>
				{Credebtor_Credit_Risks.map((item) => {
					return (
						<Col span={24}>
							<Row gutter={24}>
								<Col span={4}>
									<p>{item.title}</p>
								</Col>
								{item.columns_field.map((fields) => {
									return (
										<Col span={4}>
											<InputComponent
												required maxLength={12} onChange={handleChange} onBlur={(e) => percentagesNode(e, fields.name)}
												label={fields.label && fields.label} name={fields.name} placeholder={fields.placeholder}
												tooltip={fields.placeholder}
												rules={fields.validation === 'number' ? vsmCredebtor.validation.common_field_number_val : vsmCredebtor.validation.common_field_val}
											/>
										</Col>
									)
								})}
							</Row>
							<Divider />
						</Col>
					)
				})}
			</Row>
			<Row gutter={24}>
				<Col span={6}>
					<InputComponent type="text" label="No Trade Starting Price %" name="no_trade_starting_price_%" placeholder="No Trade Starting Price %" required={true} onBlur={(e) => percentagesNode(e, 'no_trade_starting_price_%')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>
				<Col span={5}>
					<InputComponent type="text" label="Minimum Offer %" name="minimum_offer_%" placeholder="Minimum Offer %" required={true} onBlur={(e) => percentagesNode(e, 'minimum_offer_%')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>
				<Col span={5}>
					<InputComponent type="text" label="Maximum Offer %" name="maximum_offer_%" placeholder="Maximum Offer %" required={true} onBlur={(e) => percentagesNode(e, 'maximum_offer_%')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>
				<Col span={4}>
					<InputComponent type="text" label="Maximum Bid %" name="maximum_bid_%" placeholder="Maximum Bid %" required={true} onBlur={(e) => percentagesNode(e, 'maximum_offer_%')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>
				<Col span={4}>
					<InputComponent type="text" label="CDP Cost %" name="cdp_cost_%" placeholder="CDP Cost %" required={true} onBlur={(e) => percentagesNode(e, 'cdp_cost_%')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>

			</Row>
			<Row gutter={24}>
				<Col span={4}>
					<InputComponent type="date" label="Trade Limit" name="trade_limit" placeholder="trade Limit" />
				</Col>
				<Col span={5}>
					<InputComponent type="date" label="Trade Limit Review Date" name="trade_limit_review_date" placeholder="trade Limit Review Date" />
				</Col>
				<Col span={5}>
					<InputComponent type="date" label="Insurance Specified Limit" name="insurance_specified_limit" placeholder="Insurance Specified Limit" />
				</Col>
				<Col span={5}>
					<InputComponent type="date" label="AIG Discretionary Limit" name="aig_discretionary_limit" placeholder="AIG Discretionary Limit" rules={vsmCredebtor.validation.aig_discretionary_limit} />
				</Col>
				<Col span={5}>
					<Form.Item name="rav" label="Rav Enabled">
						<Checkbox checked={RavCheck} onChange={() => setRavCheck(!RavCheck)}></Checkbox>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="override_180_day_rule" label="Override 180 day rule" tooltip="">
						<Checkbox checked={Override180Check} onChange={() => setOverRide180Check(!Override180Check)}></Checkbox>
					</Form.Item>
				</Col>
				<Col span={6}>
					<InputComponent type="text" label="Total Value" required={true} name="total_value" onBlur={(e) => percentagesNode(e, 'total_value')} rules={vsmCredebtor.validation.invoice_predicted_per_annum} placeholder="Total Values" />
				</Col>
				<Col span={6}>
					<InputComponent placeholder="Average Payment cycle" type="text" label="Average Payment Cycle" required={true} name="average_payment_cycle" rules={vsmCredebtor.validation.average_payment_cycle} />
				</Col>
				<Col span={6}>
					<InputComponent type="text" label="Perchase Payment" name="purchase_payment" placeholder="Purchase Payment" required={true} onBlur={(e) => percentagesNode(e, 'purchase_payment')} rules={vsmCredebtor.validation.No_Trade_Starting_Price} />
				</Col>
				<Col span={6}>
					<InputComponent type="text" label="Requested Credit Limit" name="requested_credit_limit" placeholder="Requested Credit Limit" required={true} onBlur={(e) => percentagesNode(e, 'purchase_payment')} rules={vsmCredebtor.validation.requested_credit_limit} />
				</Col>
				<Col span={6}>
					<Form.Item
						name="expReport"
						className="full-width-dragger"
						label='ExportSafe Report'
						tooltip="Drag PDF Experian Report">
						<Upload.Dragger
							accept=".csv,.xlsx,.xls"
							className="expReport"
							multiple={false}
							beforeUpload={handleFileUploadChangeExperienceReport}
							{...uploadPropsExperienceReport}
							onRemove={onRemoveLogo}
							showUploadList={true}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Drag Experience Report File
							</p>
						</Upload.Dragger>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="CreditSafe Report"
						className="full-width-dragger"
						name="creditSafeReport"
						tooltip="Drag PDF CreditSafe Report"
						rules={vsmCredebtor.validation.creditsafe_report}
					>
						<Upload.Dragger
							{...uploadPropsCreditSafeReport}
							beforeUpload={handleFileUploadChangeCreditSafeReport}
							fileList={Object.keys(CreditSafeLogo).length ? CreditSafeLogo['creditSafeReport'] ? CreditSafeLogo['creditSafeReport'] : [] : []}
							disabled={false}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Drag Credit Safe Report File
							</p>
						</Upload.Dragger>
					</Form.Item>
				</Col>
				<Col span={24}>
					<RatingAgencyElement onChange={handleChange} form={form} setRenderComp={setRenderComp} renderComp={renderComp} />
				</Col>
			</Row>
		</Form>
	)
}

export default CreditRisk;