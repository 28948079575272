import React from 'react';
import { observer } from 'mobx-react';
import RGL, { WidthProvider } from "react-grid-layout";
import _ from "lodash";
import { DeleteOutlined, EditOutlined, DragOutlined } from '@ant-design/icons'
import NumberWidget from './Elements/NumberWidget';
import LineWidget from './Elements/LineWidget';
import BarWidget from './Elements/BarWidget';
import PieWidget from './Elements/PieWidget';
import ReportWidget from './Elements/ReportWidget';
import useStore from '../../../store';
import GlobalReportWidget from './Elements/GlobalReportWidget';
import AuditReportWidget from './Elements/AuditReportWidget';
import CombinedReportWidget from './Elements/CombinedReportWidget';
import CashReportWidget from './Elements/CashReportWidget';
import { Button } from 'antd';

const ReactGridLayout = WidthProvider(RGL);

const WidgetComponent = observer((props) => {
    const { data, layout, layoutChange, editModal, removeItem } = props
    const { DashboardStore } = useStore()

    const createElement = (el) => {
        const removeStyle = {
            position: "fixed",
            right: "95px",
            top: '5px',
            cursor: "pointer",
            padding: '2px 8px'
        };
        const editStyle = {
            position: "fixed",
            right: "55px",
            top: '5px',
            cursor: "pointer",
            padding: '2px 8px',
        };
        const moveStyle = {
            position: "fixed",
            right: "15px",
            top: '5px',
            cursor: "pointer",
            padding: '2px 8px'
        };

        const i = el.id
        switch (el.settings_json?.i.widget_type) {
            case 'number':
                return (
                    <div key={JSON.stringify(i)} data-grid={el.settings_json}>
                        <NumberWidget newCounter={el} />
                        <Button
                            className="moveEl"
                            style={moveStyle}
                        >
                            <DragOutlined />
                        </Button>
                        <Button
                            className="remove"
                            style={editStyle}
                            onClick={() => editModal(el.id)}
                        >
                            <EditOutlined />
                        </Button>
                        <Button
                            className="remove"
                            style={removeStyle}
                            onClick={() => removeItem(el)}
                        >
                            <DeleteOutlined />
                        </Button>
                    </div>
                )
            case 'graphical':
                switch (el.settings_json?.i.type) {
                    case 'line':
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <LineWidget newCounter={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    case 'bar':
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <BarWidget newCounter={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    case 'pie':
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <PieWidget newCounter={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    default:
                        break
                }
                break
            case 'reports':
                if (el.settings_json?.i.report_fields === null) {
                    if ([1, 2, 3, 4].includes(el.settings_json?.i.report_type)) { // Globle& Single & Multiple Report Type- Done
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <GlobalReportWidget el={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    } else if (el.settings_json?.i.report_type === 5) {  // Combine Report
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <CombinedReportWidget el={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    } else if (el.settings_json?.i.report_type === 6) { // Cash Report Type
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <CashReportWidget el={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    } else if (el.settings_json?.i.report_type === 7) { // Audit Report Type
                        return (
                            <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                                <AuditReportWidget el={el} />
                                <Button
                                    className="moveEl"
                                    style={moveStyle}
                                >
                                    <DragOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={editStyle}
                                    onClick={() => { editModal(el.id) }}
                                >
                                    <EditOutlined />
                                </Button>
                                <Button
                                    className="remove"
                                    style={removeStyle}
                                    onClick={() => removeItem(el)}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </div>
                        )
                    }
                } else {
                    DashboardStore.convertReportData(el.chart)
                    return (
                        <div key={JSON.stringify(i)} data-grid={el.settings_json} className='widget__title__design'>
                            <ReportWidget newCounter={el} />
                            <Button
                                className="moveEl"
                                style={moveStyle}
                            >
                                <DragOutlined />
                            </Button>
                            <Button
                                className="remove"
                                style={editStyle}
                                onClick={() => { editModal(el.id) }}
                            >
                                <EditOutlined />
                            </Button>
                            <Button
                                className="remove"
                                style={removeStyle}
                                onClick={() => removeItem(el)}
                            >
                                <DeleteOutlined />
                            </Button>
                        </div>
                    )
                }
                break
            default:
                break
        }
    }

    return (
        <div>
            <ReactGridLayout
                {...data}
                layout={layout}
                onLayoutChange={layoutChange}
                draggableHandle='.moveEl'
            >
                {_.map(layout, el => createElement(el))}
            </ReactGridLayout>
        </div>
    )
})

export default WidgetComponent;