import { Button, PageHeader, Drawer, Row, Col, Form } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import useStore from '../../../../../store'
import ListComponent from './ListComponent'
import ContentLoader from '../../../../../components/ContentLoader'
import InputComponent from '../../../../../components/InputComponent'

const FXViewHistory = observer((props) => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(true)
	const [currentTab, setCurrentTab] = useState("date")
	const [filter, setFilter] = useState("everyone");
	const { FXCurrenciesStore } = useStore()

	const handleChange = () => {
		setCurrentTab(form.getFieldValue("filters"))
	}

	useEffect(() => {
		if (FXCurrenciesStore.viewValues && props.visible) {
			setLoading(true)
			setCurrentTab("date")
			setFilter("everyone")
			FXCurrenciesStore.viewHistory_list = null
			FXCurrenciesStore.getViewHistoryList(props.historyId).then(() => {
				setLoading(false)
			}).catch(() => setLoading(false))
			form.setFieldsValue({
				currencies: FXCurrenciesStore.viewValues.id
			})
		}
	}, [props, form, FXCurrenciesStore, FXCurrenciesStore.viewValues])

	const histroyCall = async () => {
		form.resetFields(["date", "date_range", "filters"])
		props.setId(form.getFieldValue("currencies"))
		var values = FXCurrenciesStore.list_data && FXCurrenciesStore.list_data.find(item => item.id === form.getFieldValue("currencies"))
		FXCurrenciesStore.setViewValues(values)
	}

	const handleFilter = (data) => {
		setFilter(data)
	}

	const close = () => {
		form.resetFields()
		props.setId(null)
		props.close()
		FXCurrenciesStore.viewHistory_list = null
	}

	return FXCurrenciesStore.viewValues ? (
		<>
			<Drawer
				visible={props.visible}
				onClose={close}
				title={`View History - #${FXCurrenciesStore.viewValues.id} - ${FXCurrenciesStore.viewValues.currency_name}`}
				placement='right'
				width={'75%'}
				destroyOnClose={true}
				footer={[
					<div key="1" className="d-flex justify-content-center">
						<Button className="ml-10" onClick={close}>Back to List</Button>
					</div>
				]}
			>
				{
					!loading ?
						<PageHeader>
							<Form form={form} id="addform"
								labelCol={{ span: 8 }}
								onChange={handleChange}
							>
								<Row>
									<Col span={12}>
										<Row>
											<Col className="ant-form-item-label">
												<label className="ant-form-item-required">Filters</label>
											</Col>
											<Col>
												<InputComponent
													required type="radio_button" name="filters" initialValue="date" onChange={() => { handleChange(); form.resetFields(["date", "date_range"]) }}
													options={{
														values: [
															{ value: 'date', text: 'Date' },
															{ value: 'date-range', text: 'Date Range' },
														]
													}}
												/>
											</Col>
										</Row>
									</Col>
									<Col span={12} className="text-right">
										<Button key="1" onClick={FXCurrenciesStore.exportData}>Export as CSV</Button>
									</Col>
								</Row>
								<Row>
									{
										currentTab === 'date' ?
											<>
												<Col className="ant-form-item-label">
													<label className="ant-form-item-required">Date</label>
												</Col>
												<Col span={12}>
													<InputComponent
														type="date" name="date"
														onChange={handleFilter}
													/>
												</Col>
											</>
											:
											<>
												<Col className="ant-form-item-label">
													<label className="ant-form-item-required">Date Range</label>
												</Col>
												<Col span={12}>
													<InputComponent
														type="date_range" name="date_range"
														onChange={handleFilter}
													/>
												</Col>
											</>
									}
									<Col flex={1}>
										<InputComponent
											type="select" allowClear label="Currencies" name="currencies"
											onChange={histroyCall}
											options={{
												values: FXCurrenciesStore.list_data,
												value_key: 'id',
												text_key: 'currency_code'
											}}
										/>
									</Col>
								</Row>
							</Form>
							<ListComponent filter={filter} form={form} setLoading={setLoading} />
						</PageHeader> : <ContentLoader />
				}
			</Drawer>
		</>
	) : null
})

export default FXViewHistory
