import React from 'react'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import SwitchComponent from './SwitchComponent'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { gotoEditScreen, openDeleteModal, gotoViewScreen } = props.agGridReact.props.frameworkComponents

	const { UserStore } = useStore()

	return (
		<div className="action-column">
			{UserStore.checkPermission(27, 'view') &&
				<Button title="View Algorithm" type="text" onClick={() => { gotoViewScreen(props.data.id) }}><EyeOutlined /></Button>
			}
			{
				(props.data && !props.data.status) ?
					(
						<>
							{UserStore.checkPermission(27, 'edit') &&
								<Button title="Edit Algorithm" type="text" onClick={() => { gotoEditScreen(props.data.id) }}><EditOutlined /></Button>
							}
							{UserStore.checkPermission(27, 'delete') &&
								<Button title="Delete Algorithm" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
							}
						</>
					) :
					(<>
						{UserStore.checkPermission(27, 'edit') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot edit Published Algorithm. Please Unpublish it first and then edit it."}>
							<Button type="text" disabled><EditOutlined /></Button>
						</Tooltip>}
						{UserStore.checkPermission(27, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete Published Algorithm. Please Unpublish it first before deleting."}>
							<Button type="text" disabled><DeleteOutlined /></Button>
						</Tooltip>}
					</>)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Algorithm Name',
		field: 'algorithm_name',
		width: 150
	}, {
		headerName: 'Description',
		field: 'algorithm_desc',
		tooltipField: 'algorithm_desc',
		width: 220
	}, {
		headerName: 'Last Parse Status',
		field: 'last_parse_result',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 120
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		width: 185,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
	}, {
		headerName: 'Status',
		field: 'published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Yes", "No"],
			defaultToNothingSelected: true
		},
		width: 150
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
