import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import SwitchComponent from './SwitchComponent'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { gotoEditScreen, openDeleteModal, gotoVerifyScreen } = props.agGridReact.props.frameworkComponents

	const { UserStore } = useStore()

	return (
		<div className="action-column">
			<Button title="Verify Transaction Model" type="text" onClick={() => { gotoVerifyScreen(props.data.id) }}><FontAwesomeIcon className={props.data.is_tested ? "greenVerifyIcon" : "redVerifyIcon"} icon={faCheckCircle} /></Button>
			{
				(props.data && props.data.status === true) ? (
					<>
						{UserStore.checkPermission(26, 'edit') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot Edit a Published Transaction Model. Please Unpublish the Transaction Model first before editing it."}>
							<Button type="text" disabled><EditOutlined /></Button>
						</Tooltip>}
						{UserStore.checkPermission(26, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete a Published Transaction Model. Please Unpublish the model first before deleting it."}>
							<Button disabled type="text"><DeleteOutlined /></Button>
						</Tooltip>}
					</>
				) : (
					<>
						{UserStore.checkPermission(26, 'edit') &&
							<Button title="Edit Transaction Model" type="text" onClick={() => { gotoEditScreen(props.data.id) }}><EditOutlined /></Button>
						}
						{UserStore.checkPermission(26, 'delete') &&
							<Button title="Delete Transaction Model" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
						}
					</>
				)
			}

		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Trade Type',
		field: 'transaction_type_field_mapping.mapping_name',
		filter: 'agSetColumnFilter',
		width: 180
	}, {
		headerName: 'Code',
		field: 'model_code',
		width: 120
	}, {
		headerName: 'Model Name',
		field: 'model_name',
		width: 150
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		filterParams: {
			filterOptions: ['contains'],
			suppressAndOrCondition: true,
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			suppressAndOrCondition: true,
		},
		width: 185
	}, {
		headerName: 'Published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Yes", "No"],
			defaultToNothingSelected: true
		},
		width: 120
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
