import React from 'react';
import { observer } from 'mobx-react-lite';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import useStore from '../../../../store';
import GridConfig from '../../../../config/GridConfig';
import { vsmCommon } from '../../../../config/messages';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

import moment from 'moment';
import { Button } from 'antd';


const ListComponent = observer((props) => {
	const { CredebtorStore, UserStore } = useStore()

	const ActionRenderer = (params) => {
		return (
			<div className="action-column">
				{UserStore.checkPermission(119, 'edit') && <Button title="Edit Member" type="text" onClick={() => props.setOpenEditDrawer(params.data)}><EditOutlined /></Button>}
				{UserStore.checkPermission(119, 'view') && <Button title="Edit Member" type="text"><EyeOutlined /></Button>}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'entity_id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			valueGetter: (params) =>
				(params.data?.organisation?.id) ? params.data?.id : null,
			width: 80,
		},
		{
			headerName: 'Credebtor Name',
			field: 'organisation_name',
			tooltipField: 'organisation_name',
			valueGetter: (params) =>
				(params.data?.organisation.organisation_name) ? params.data?.organisation.organisation_name : null,
			width: 240,
		},
		{
			headerName: 'Trade Name',
			field: 'trade_name',
			tooltipField: 'trade_name',
			width: 120
		},
		{
			headerName: 'Parent Organisation',
			field: 'originator_organisation.organisation_name',
			tooltipField: 'organisation.global_nace_sections.get_nace_category.category_name',
			width: 240,
			filter: false,
			sortable: false
		},
		{
			headerName: 'NACE Category',
			field: 'organisation.global_nace_sections.section',
			tooltipField: 'organisation.global_nace_sections.get_nace_category.category_name',
			filter: 'agSetColumnFilter',
			width: 240,
			valueGetter: (params) =>
				(params.data?.organisation?.global_nace_sections?.get_nace_category?.category_name) ? params.data?.organisation?.global_nace_sections?.get_nace_category.category_name : null,
			filterParams: {
				defaultToNothingSelected: false,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					CredebtorStore.getNaceCategory().then((data) => {
						data.data.forEach((item) => {
							arr.push(item.category_name)
						})
						params.success(arr);
					})
				},
			},
		},
		{
			headerName: 'NACE Code',
			field: 'organisation.global_nace_sections.code',
			tooltipField: 'organisation.global_nace_sections.code',
			width: 120,
			filter: false,
			sortable: false
		},
		{
			headerName: 'Trade Limit',
			field: 'trade_limit',
			tooltipField: 'trade_limit',
			filter: false,
			sortable: false,

			width: 180
		}, {
			headerName: 'Role',
			filter: 'agSetColumnFilter',
			field: 'role_name',
			tooltipField: 'role_name',
			width: 120,
			filterParams: {
				defaultToNothingSelected: false,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					CredebtorStore.getCredeborRole().then((data) => {
						data.data.forEach((item) => {
							if (["Creditor", "Debtor"].includes(item.role_name)) {
								arr.push(item.role_name)
							}
						})
						params.success(arr);
					})
				},
			},
		},
		{
			headerName: 'Ragistration Number',
			field: 'registration_number',
			tooltipField: 'registration_number',
			width: 150
		},
		{
			headerName: 'Country',
			field: 'organisation.global_countries.country_name',
			tooltipField: 'organisation.global_countries.country_name',
			filter: 'agSetColumnFilter',
			width: 110,
			filterParams: {
				defaultToNothingSelected: false,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					CredebtorStore.getAllCountries().then((data) => {
						data.data.forEach((item) => {
							arr.push(item.country_name)
						})
						params.success(arr);
					})
				},
			},
		},
		{
			headerName: 'Status',
			field: 'organisation.status.status_name',
			tooltipField: 'organisation.status.status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					CredebtorStore.getStatus().then((data) => {
						data.data.forEach((item) => {
							if (["Authorised", "Unauthorised"].includes(item.status_name)) {
								arr.push(item.status_name)
							}
						})
						params.success(arr);
					})
				},
			},
			width: 110
		},
		{
			headerName: 'Last Updated On',
			field: 'global_organisations.updated_at',
			filter: "agDateColumnFilter",
			tooltipField: 'organisation.updated_at',
			width: 150,
			valueGetter: (params) =>
				(params.data?.organisation.updated_at) ? params.data?.organisation.updated_at : null,
			cellRendererFramework: (params) => {
				return (
					<span>
						{params.data?.updated_at
							? moment(params.data?.updated_at).format('YYYY-MM-DD')
							: null}
					</span>
				);
			},
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			filter: false,
			sortable: false,
			pinned: 'right',
			width: 90
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('credebtors_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer
				}}
				onGridReady={CredebtorStore.setupGrid}
				onColumnResized={CredebtorStore.onGridChanged}
				onColumnMoved={CredebtorStore.onGridChanged}
				onColumnPinned={CredebtorStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent