import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import { Button, PageHeader, Result, Spin } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import useStore from '../../../store'
import ListComponent from './component/ListComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import FilterComponent from './component/FilterComponent'
import DeleteComponent from './component/DeleteComponent'
import EditComponent from './component/EditComponent'
import ViewComponent from './component/ViewComponent'
import AddComponent from './component/AddComponent'
import AdditionalColumnListComponent from './component/AdditionalColumnListComponent'
import Unauthorized from '../../Unauthorized'
import InputComponent from '../../../components/InputComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { TableOutlined, FilterOutlined } from '@ant-design/icons'
import { vsmAllTransactions, vsmNotify } from '../../../config/messages'
import EnterFullScreen from "../../../components/EnterFullscreen"

const AllTransactions = observer(() => {
	const [transactionId, setTransactionId] = useState(null)
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [filterModal, setFilterModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [fetchRoles, setFetchRoles] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [transactionStatus, setTransactionStatus] = useState()
	const [additionalColumnModal, setAdditionalColumnModal] = useState(false)
	const { UserStore, TransactionFieldStore, AllTransactionStore: { per_page, list_status, listLoading, list_data, advance_filter, setImportId, setDeleteValues, setPageSize, exportLoading, exportData }, AllTransactionStore } = useStore()
	const { id } = useParams()
	let history = useHistory()

	const Store = {
		Field: TransactionFieldStore
	}

	// set id on refresh form
	useEffect(() => {
		let data = id ? id : null
		setImportId(data)
	}, [setImportId, id])

	const setId = (id) => setTransactionId(id)

	// open drawer for add
	const openAddDrawer = () => setAddDrawer(true)

	// close drawer for add
	const closeAddDrawer = () => setAddDrawer(false)

	// open drawer for edit
	const openEditDrawer = (id) => {
		setEditDrawer(true)
		setId(id)
	}

	// close drawer for edit
	const closeEditDrawer = () => setEditDrawer(false)

	// open drawer for view
	const openViewDrawer = (id) => {
		setViewDrawer(true)
		setId(id)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// Open alert for apply advance filter
	const openFilterModal = () => {
		Store.Field.getAllRecord()
		setFilterModal(true)
	}

	// Close alert for apply advance filter
	const closeFilterModal = () => setFilterModal(false)

	// Open confirmation alert before deleting existing transactions
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	//open modal for additional column list
	const openAdditionColumnList = () => {
		AllTransactionStore.GetAdditionalColumnList()
		setAdditionalColumnModal(true)
	}

	//close modal for additional column list
	const closeAdditionColumnList = () => setAdditionalColumnModal(false)

	// reset function
	const handleReset = async () => {
		var temp_transaction_grid = JSON.parse(localStorage.getItem('transaction_grid'))
		temp_transaction_grid.forEach((itemField) => {
			itemField.hide = false
		})
		localStorage.setItem('transaction_grid', JSON.stringify(temp_transaction_grid))
		await AllTransactionStore.setupGrid(AllTransactionStore.agGrid)
		AllTransactionStore.advance_filter = null
		await localStorage.removeItem('transaction_grid')
		await localStorage.removeItem('ADDITIONAL_COLUMN_LIST')
		AllTransactionStore.agGrid.api.setFilterModel(null)
		AllTransactionStore.agGrid.api.setSortModel(null)
		AllTransactionStore.agGrid.columnApi.resetColumnState()
		AllTransactionStore.agGrid.api.onFilterChanged(null)
	}

	if (list_status === 404) {
		return (
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={<Button type="primary" onClick={() => { history.goBack() }}>Go Back</Button>}
			/>
		)
	} else {
		return (
			<>
				<BreadcrumbComponent items={BreadcrumbConfig.AllTransactions.path} />
				{(!UserStore.checkPermission(22, 'list')) ? (<Unauthorized />) : (
					<PageHeader
						className={UserStore.fullscreen_class}
						title={BreadcrumbConfig.AllTransactions.title}
						extra={[
							<Button key="3" type={advance_filter && 'primary'} onClick={openFilterModal} title="Advance Filter"><FilterOutlined /></Button>,
							<Button key="4" loading={exportLoading} onClick={exportData} disabled={listLoading || (list_data && list_data.length === 0)}>Export</Button>,
							(UserStore.checkPermission(22, 'add')) && <Button key="5" onClick={openAddDrawer}>New</Button>,
							<RecordPerPage key="6" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
							<Button key="2" disabled={AllTransactionStore.list_data && !AllTransactionStore.list_data.length > 0} title="Add Additional Column in the List" onClick={openAdditionColumnList}><TableOutlined /></Button>,
							<Button key="7" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
							<EnterFullScreen key="8" />
						]}
					>
						<div className='ml-10 mb-10 d-flex justify-content-end'>
							<InputComponent
								allowClear
								key='1'
								placeholder='Select by Role'
								noStyle={true}
								type="select"
								onChange={(value) => {
									AllTransactionStore.roleID = value
									AllTransactionStore.setupGrid(AllTransactionStore.agGrid)
								}}
								onFocus={() => fetchRoles && AllTransactionStore.getRoleFilter({ name: "trade", type: 1 }).then(() => setFetchRoles(false))}
								notFoundContent={fetchRoles ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: AllTransactionStore.filter_role,
									value_key: "role_id",
									text_key: "entity_role"
								}}
							/>
							<InputComponent
								className='ml-10'
								allowClear
								key='9'
								type="select"
								placeholder="Select status"
								noStyle={true}
								value={transactionStatus}
								disabled={AllTransactionStore.rowSelection}
								onChange={(e) => {
									setTransactionStatus(e)
									AllTransactionStore.BulkStatusChange(e).then(() => {
										setTransactionStatus(null)
										vsmNotify.success({
											message: vsmAllTransactions.BulkStatusChange
										})
									})
								}}
								onFocus={() => fetchStatus && AllTransactionStore.getStatusList().then(() => setFetchStatus(false))}
								notFoundContent={fetchStatus ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: AllTransactionStore.status_dropdown,
									value_key: "id",
									text_key: "status_name"
								}}
							/>
						</div>
						<ListComponent openEditDrawer={openEditDrawer} openViewDrawer={openViewDrawer} openDeleteModal={openDeleteModal} />
						<AddComponent visible={addDrawer} close={closeAddDrawer} />
						<EditComponent transactionId={transactionId} setId={setId} visible={editDrawer} close={closeEditDrawer} />
						<ViewComponent transactionId={transactionId} setId={setId} visible={viewDrawer} close={closeViewDrawer} />
						<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
						<FilterComponent visible={filterModal} close={closeFilterModal} />
						<AdditionalColumnListComponent visible={additionalColumnModal} close={closeAdditionColumnList} />
					</PageHeader>
				)}
			</>
		)
	}
})

export default AllTransactions
