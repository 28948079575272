import React, { useState } from 'react';
import { observer } from 'mobx-react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import PageNotFound from '../../PageNotFound';
import { Button, PageHeader } from 'antd';
import useStore from '../../../store';
import RecordPerPage from '../../../components/RecordPerPage';
import EnterFullscreen from '../../../components/EnterFullscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import ListComponent from './components/ListComponent';
import EditComponent from './components/EditComponent';
import ViewComponent from './components/ViewComponent';

const MailTemplate = observer(() => {

    const { UserStore, MailTemplateStore } = useStore()
    const [editDrawer, setEditDrawer] = useState(false)
    const [viewDrawer, setViewDrawer] = useState(false)

    // open edit drawer for edit template
    const openEditDrawer = (data) => {
        MailTemplateStore.ReadData(data.id)
        setEditDrawer(true)
    }

    // close edit drawer for edit template
    const closeEditDrawer = () => setEditDrawer(false)

    // open view drawer to view template
    const openViewDrawer = (data) => {
        MailTemplateStore.ReadData(data.id)
        setViewDrawer(true)
    }

    // close view drawer to view template
    const closeViewDrawer = () => setViewDrawer(false)

    // reset function
    const handleReset = async () => {
        await localStorage.removeItem('mail_template_grid')
        MailTemplateStore.agGrid.api.setFilterModel(null)
        MailTemplateStore.agGrid.api.setSortModel(null)
        MailTemplateStore.agGrid.columnApi.resetColumnState()
        MailTemplateStore.agGrid.api.onFilterChanged(null)
    }

    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.Mail_Template.path} />
            {(!UserStore.checkPermission(61, 'list')) ? (<PageNotFound />) : (
                <>
                    <PageHeader
                        title={BreadcrumbConfig.Mail_Template.title}
                        className={UserStore.fullscreen_class}
                        extra={[
                            <RecordPerPage key="2" defaultValue={MailTemplateStore.per_page + ' per page'} onChange={MailTemplateStore.setPageSize} />,
                            <Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
                            <EnterFullscreen key='4' />
                        ]}
                    >
                        <ListComponent openEditDrawer={openEditDrawer} openViewDrawer={openViewDrawer} />
                        <EditComponent visible={editDrawer} close={closeEditDrawer} />
                        <ViewComponent visible={viewDrawer} close={closeViewDrawer} />
                    </PageHeader>
                </>
            )}
        </>
    );
})

export default MailTemplate;