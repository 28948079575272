import { Button, Card, Checkbox, Col, Form, Row, Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import InputComponent from '../../../../components/InputComponent'
import { vsmMember, vsmUserManagement } from '../../../../config/messages'
import useStore from '../../../../store'
import { defaultMemberStatus } from '../../../../utils/GlobalFunction'

const UserDetialsComponent = observer(({ form, handleSubmit, setDisabled }) => {
	const { MemberStore, MemberStore: { openFormType, getPermissionsList, userPermission, orgStatusList, getOrgStatus, viewMemberValue } } = useStore()
	const [hidePermissionTable, setHidePermissionTable] = useState(false)
	const [orgStatus, updateOrgStatus] = useState(true)

	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch((e) => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const getPermissionList = (permissionID) => {
		getPermissionsList(permissionID).then((data) => {
			form.setFieldsValue({
				permissions: data.data
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		})
	}

	const handleModifyPermissions = () => {
		if (MemberStore.viewMemberValue && MemberStore.viewMemberValue.is_member_user) {
			let payload = { user_id: MemberStore.viewMemberValue.is_member_user.id }
			getPermissionList(payload)
		} else if (userPermission && userPermission.user) {
			let payload = { role_id: userPermission.user.id }
			getPermissionList(payload)
		}
		setHidePermissionTable(true)
	}

	useEffect(() => {
		if (viewMemberValue) {
			MemberStore.orgStatusList = null
		} else {
			MemberStore.orgStatusList = [defaultMemberStatus]
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewMemberValue])

	return (
		<Form
			form={form}
			id={`${openFormType}-${openFormType !== 'Originator' ? 2 : 3}`}
			onFinish={handleSubmit}
			layout='vertical'
		>
			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						required label='First Name' name='first_name' placeholder='First Name' onChange={handleChange}
						tooltip='The first name is the name given to individuals upon birth and baptism and is mostly used for identification'
						rules={vsmMember.validation.user_first_name}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						required label='Last Name' name='last_name' placeholder='Last Name' onChange={handleChange}
						tooltip='The last name represents the family and is common to other members of the family.'
						rules={vsmMember.validation.user_last_name}
					/>
				</Col>
				<Col span={8}>
					<InputComponent
						required label='Email Name' name='user_email' placeholder='Email Name' onChange={handleChange}
						tooltip='a system for sending messages to one or more recipients via telecommunications links between computers using dedicated software'
						rules={vsmMember.validation.user_email}
						note={<Button onClick={handleModifyPermissions} type="link" style={{ padding: 0, height: "auto" }}>Modify Permission</Button>}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						type='select' label='Status' name='status_id' placeholder='Select Status' onChange={handleChange}
						options={{
							values: orgStatusList || [{
								id: viewMemberValue && viewMemberValue.status && viewMemberValue.status.id && viewMemberValue.status.id,
								status_name: viewMemberValue && viewMemberValue.status && viewMemberValue.status.status_name && viewMemberValue.status.status_name
							}],
							value_key: 'id',
							text_key: 'status_name'
						}}
						onFocus={() => orgStatus && getOrgStatus().then(() => updateOrgStatus(false))}
						notFoundContent={orgStatus ? <Spin size='small' /> : 'No Content Found.'}
						tooltip='Select status'
						rules={vsmMember.validation.status_id}
					/>
				</Col>
			</Row>
			{(hidePermissionTable) ? (
				<>
					<Form.Item name="permissions" rules={vsmUserManagement.validation.permissions} className='permissions_form_item'>
						<Form.List name="permissions">
							{
								(fields => {
									return (
										fields.map(field => {
											var value = form.getFieldValue('permissions')[field.key]
											var isDisabled = false
											value.actions.map((action, index) => {
												if (action.action_name === 'list' && action.is_selected !== 1) {
													isDisabled = true
												}
												return null
											})
											var all_checked = 0
											value.actions.map((action, index) => {
												if (action.is_selected === 1) {
													all_checked++
												}
												return null
											})
											return (
												<>
													{value.actions.length > 0 && (
														<Card className="mt-10">
															<Row gutter={24}>
																<Col span={4} style={{ borderRight: '1px solid #f0f0f0' }}><b>{value.title}</b></Col>
																<Col span={20}>
																	<Checkbox checked={all_checked === value.actions.length} onChange={(e) => {
																		var temp = form.getFieldValue('permissions')

																		temp[field.key].actions.map((action, index) => {
																			temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																			return null
																		})
																		form.setFieldsValue({
																			permissions: temp
																		})
																	}}>Check all</Checkbox>

																	{value.actions.map((action, index) => {
																		var temp = action.action_name.replace(/_/g, ' ')
																		return (
																			<div style={{ display: 'inline-block', marginRight: '15px' }}>
																				<Form.Item
																					noStyle
																					name={[field.name, 'actions', index, 'is_selected']}
																				>
																					<Checkbox disabled={isDisabled && action.action_name !== 'list'} checked={action.is_selected === 1 ? true : false} value={action.id} onChange={(e) => {
																						var temp = form.getFieldValue('permissions')
																						temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																						if (temp[field.key].actions[index].action_name === 'list' && !e.target.checked) {
																							temp[field.key].actions.map((action, index) => {
																								temp[field.key].actions[index].is_selected = 0
																								return null
																							})
																						}

																						form.setFieldsValue({
																							permissions: temp
																						})
																					}}>{temp.charAt(0).toUpperCase() + temp.slice(1)}</Checkbox>
																				</Form.Item>
																			</div>
																		)
																	})}
																</Col>
															</Row>
														</Card>
													)}
												</>
											)
										})
									)
								})
							}
						</Form.List>
					</Form.Item>
				</>
			) : null}
		</Form >
	)
})

export default UserDetialsComponent