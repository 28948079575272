import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../config/LocalGridConfig"
import { vsmNotify } from "../../config/messages"
import { globalStatus } from "../../utils/GlobalFunction"

export default class ImportStore {
	list_data = null
	viewLog_list = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	cancelValues = null
	deleteValues = null
	bank_list = null
	logValues = null
	viewLogValues = null
	verifyValues = null
	viewValues = null
	agGrid = null
	agGridLogs = null

	initialValues = {
		entity_type: 1,
		related_entity_type: 1,
		face_value: 'debit'
	}

	//set value to cancel
	setCancelvalues = (data) => {
		this.cancelValues = data
	}

	//set value to delete
	setDeletevalues = (data) => {
		this.deleteValues = data
	}

	setViewLogValues = (data) => {
		this.viewLogValues = data
	}

	setLogValues = (data) => {
		this.logValues = data
	}

	setVerifyValues = (data) => {
		this.verifyValues = data
	}

	setViewValues = (data) => {
		this.viewValues = data
	}

	// set bankAccount list
	setBankAccountList = (data) => {
		if (data) {
			data.forEach((item) => {
				item.bankAccount_names = item.name + " - " + (item.iban ? item.iban : item.account_number)
			});
		}
		this.bank_list = data
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('cte_import_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('cte_import_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Setup grid and set column size to autosize
	setupGridForViewLogs = (params) => {
		this.agGridLogs = params
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('cte_import_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('cte_import_grid', columnConfig)
		}
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.get(`cash/transactionsimport/list`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (item.ledgers_bank_accounts_account_number) {
						if (item.ledgers_bank_accounts_account_number) {
							item.bankAccount_names = item.global_banks_name + " - " + item.ledgers_bank_accounts_account_number
						} else {
							item.bankAccount_names = item.global_banks_name + " - " + item.ledgers_bank_accounts_iban
						}
					} else {
						item.bankAccount_names = null
					}

					if (item.global_banks_name) {
						item.bank_account = item.global_banks_name
					} else {
						item.bank_account = null
					}

					if (item.ledgers_bank_accounts_account_number) {
						if (item.ledgers_bank_accounts_account_number) {
							item.bankAccount_number = item.ledgers_bank_accounts_account_number
						} else {
							item.bankAccount_number = item.ledgers_bank_accounts_iban
						}
					} else {
						item.bankAccount_number = null
					}

					item.imported_by = item.users_first_name + ' ' + item.users_last_name

					item.status_text = globalStatus('cash_import', 'key', item.status)
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
			return data
		})
	}

	getViewLogList = (id) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.api.getSortModel()
		}
		return Axios.get(`/cash/transactionsimport/view-logs/${id}`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.status_text = globalStatus('cash_import_log', 'key', item.status)
				});
			}
			this.viewLog_list = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.api.setSortModel(sort)
			}
			return data
		})
	}

	// call to get record particular ID
	getRecordById = (id) => {
		return Axios.get(`cash/transactionsimport/read/${id}`).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	getVerifyRecordById = (data, id) => {
		return Axios.post(`cash/transaction/read/${id}`, data).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	getLogDetails = (id) => {
		return Axios.get(`cash/transaction/log/get/${id}`).then(({ data }) => {
			this.logValues = JSON.parse(data.data)
			return data
		})
	}

	getBankList = () => {
		return Axios.get(`cash/transaction/ledgerbankaccounts/`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					if (item.account_number) {
						item.bankAccount_names = item.banks.name + " - " + item.account_number
					} else {
						item.bankAccount_names = item.banks.name + " - " + item.iban
					}
				});
			}
			this.bank_list = data.data
			return data
		}).catch(() => {
			this.bank_list = null
		})
	}

	AddData = (data) => {
		return Axios.post(`cash/transactionsimport/add`, data).then(() => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var file_errors = []
			Object.keys(data.errors).forEach(name => {
				var patt = /^files\.([0-9]+)$/
				if (patt.test(name)) {
					file_errors.push(data.errors[name])
				} else {
					errors.push({ name, errors: data.errors[name] })
				}
			})
			if (file_errors.length > 0) {
				errors.push({ name: 'file', errors: file_errors })
			}
			data.errors = errors
			return Promise.reject(data)
		})
	}

	DeleteData = (data) => {
		return Axios.get(`cash/transactionsimport/delete/${data.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	CancelData = (data) => {
		return Axios.get(`cash/transactionsimport/cancleTransaction/${data.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ReprocessData = () => {
		let array = this.agGrid && this.agGrid.api.getSelectedNodes()
		let selectedId = [];
		array && array.map((item) => (
			selectedId.push(item.data.id)
		))
		var payload = {
			selected_ids: selectedId
		}
		if (selectedId.length > 0) {
			return Axios.post(`cash/transactionsimport/process-identification`, payload).then(({ data }) => {
				vsmNotify.success({ message: data.data })
				if (this.agGrid) {
					this.agGrid.api.deselectAll()
				}
				return data
			})
		} else {
			vsmNotify.error({ message: "Please select atleast one record to reprocess." })
			return Promise.resolve()
		}
	}

	SaveVerifyData = (data) => {
		return Axios.post(`cash/transaction/update/${data.id}`, data).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					errors.push({ name, errors: data.errors[name] })
				})
			}
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(ImportStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	agGrid: observable,
	agGridLogs: observable,
	cancelValues: observable,
	deleteValues: observable,
	viewLog_list: observable,
	bank_list: observable,
	initialValues: observable,
	logValues: observable,
	viewLogValues: observable,
	verifyValues: observable,
	viewValues: observable,
	setPageSize: action,
	setBankAccountList: action,
	setCancelvalues: action,
	setDeletevalues: action,
	getBankList: action,
	onFilterChanged: action,
	AddData: action,
	DeleteData: action,
	getLogDetails: action,
	setViewLogValues: action,
	setLogValues: action,
	setVerifyValues: action,
	getRecordById: action,
	getVerifyRecordById: action,
	setViewValues: action,
	ReprocessData: action,
	SaveVerifyData: action,
	setupGridForViewLogs: action,
	onGridChanged: action,
	onChildnGridChanged: action
})
