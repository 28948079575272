import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Card } from 'antd';
import InputComponent from '../../../../components/InputComponent';
import { vsmMailTemplate } from '../../../../config/messages';
import TinyComponent from '../../../../components/TinyComponent';
import Modal from 'antd/lib/modal/Modal';

const FormComponent = observer(({ form, handleSubmit, onChange, modaleditorInitalValue, description, view, resetContent, setModalEditorInitalValue }) => {
    const [openModal, setOpenModal] = useState(false);

    const openExpandModal = () => {
        setModalEditorInitalValue(form.getFieldValue('email_body'))
        setOpenModal(true)
    }

    const onFinish = (data) => {
        handleSubmit(data)
    }

    const editorChange = (value) => {
        form.setFieldsValue({
            email_body: value
        })
        onChange()
    }

    const close = () => {
        setModalEditorInitalValue(form.getFieldValue('email_body'))
        setOpenModal(false);
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                id="addeditform"
                onFinish={onFinish}
            >
                <InputComponent
                    required
                    name="template_name"
                    label='Template Name'
                    placeholder='Template Name'
                    onChange={onChange}
                    tooltip="It is an unique name for email template."
                    disabled
                />

                <InputComponent
                    required
                    name="email_subject"
                    label='Subject'
                    placeholder='Subject'
                    onChange={onChange}
                    tooltip="The subject line of an email is the single line of text people see when they receive your email."
                    rules={vsmMailTemplate.validation.email_subject}
                    maxLength={100}
                    disabled={view}
                />

                <Form.Item name='email_body' label="Body" tooltip='The email body includes the message content.' rules={vsmMailTemplate.validation.body}>
                    <TinyComponent
                        htmlName={`email_body`}
                        form={form}
                        editorvalue={modaleditorInitalValue}
                        view={view}
                        onChange={onChange}
                        resetContent={resetContent}
                        editorChange={editorChange}
                        openExpandModal={openExpandModal}
                        openModal={openModal}
                        close={close}
                    />
                </Form.Item>

                {!view && <Card title="The following place holders are allowed:" className='mt-20'>
                    {description && description.length && description.map((item, index) => (
                        <div key={index}>
                            <h3>{item}</h3>
                        </div>
                    ))}
                </Card>}
            </Form>
            <Modal
                centered
                visible={openModal}
                onCancel={close}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                className='ExpandModal'
            >
                <Form
                    form={form}
                    layout='vertical'
                    id="addeditform"
                    onFinish={onFinish}
                >
                    <Form.Item name='email_body' label="Body" tooltip='The email body includes the message content.' rules={vsmMailTemplate.validation.body}>
                        <TinyComponent
                            htmlName={`email_body`}
                            form={form}
                            editorvalue={modaleditorInitalValue}
                            view={view}
                            onChange={onChange}
                            resetContent={resetContent}
                            editorChange={editorChange}
                            openExpandModal={openExpandModal}
                            openModal={openModal}
                            close={close}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
})

export default FormComponent;