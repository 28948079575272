import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import AppRouter from './utils/AppRouter'
import OriginatorAppRouter from './utils/OriginatorAppRouter'
import LayoutComponent from './components/LayoutComponent'
import useStore from './store'
import { observer } from 'mobx-react'
import Login from './pages/Login'
import { message, Result } from 'antd'
import OriginatorLogin from './pages/Originator/OriginatorLogin'
import AppsLayoutComponent from './components/Originator/AppsLayoutComponent'

const App = observer(() => {
	const {
		UserStore: { open_login, setAxiosInterceptors },
		AppsAuthStore: { setAppsAxiosInterceptors, originator_open_login },
		AppsAuthStore
	} = useStore()
	const [isOnline, setOnlineStatus] = useState()
	const queryParams = new URLSearchParams(window.location.search);

	useEffect(() => {
		if (window.location.pathname === '/apps') {
			setAppsAxiosInterceptors()
		} else {
			setAxiosInterceptors()
		}
	}, [setAxiosInterceptors, setAppsAxiosInterceptors])

	useEffect(() => {
		if (isOnline === 'online') {
			message.success('You are online again.');
		}
	}, [isOnline])

	useEffect(() => {
		AppsAuthStore.theme_color = queryParams.get('color') ? `#${queryParams.get('color')}` : '#2E4369'
		AppsAuthStore.theme_button_color = queryParams.get('type')
		AppsAuthStore.theme_type = queryParams.get('theme')
	}, [AppsAuthStore, queryParams])

	window.addEventListener('online', () => setOnlineStatus('online'));
	window.addEventListener('offline', () => setOnlineStatus('offline'));
	return (
		<BrowserRouter>
			<Switch>
				<Route path="/apps">
					<AppsLayoutComponent>
						{
							originator_open_login ? (<OriginatorLogin />) : (<OriginatorAppRouter />)
						}
					</AppsLayoutComponent>
				</Route>
				<Route path="/">
					<LayoutComponent>
						{
							open_login ? (<Login />) : (<AppRouter />)
						}
					</LayoutComponent>
				</Route>
			</Switch>
			{(isOnline === 'offline') && (
				<div className="offline_section">
					<Result
						status="500"
						title="No Internet!"
						subTitle="Please check your internet connectivity."
					/>
				</div>
			)}
		</BrowserRouter>
	)
})

export default App
