import React from 'react'
import { Switch, Tooltip } from 'antd'

const SwitchComponent = (props) => {
	return (
		<div>
			{
				(props.values && props.values.transaction_type_field_mapping.mapping_name === "N/A") ?
					<Tooltip color={"red"} title={"sorry you cannot publish the transaction model as field mapping seems missing."}>
						<Switch
							size='small'
							disabled
							defaultChecked={props.values.status}
							onChange={(val) => props.onSwitchChange(val, props.values)}
						/>
					</Tooltip> :
					<>
						{
							props.values.status === false && props.values.is_tested === false ?
								<Tooltip color={"red"} title={"Please Verify Transaction Model before publishing it."}>
									<Switch
										size='small'
										disabled
										defaultChecked={props.values.status}
										onChange={(val) => props.onSwitchChange(val, props.values)}
									/>
								</Tooltip> :
								<Switch
									size='small'
									checked={props.values.status}
									onChange={(val) => props.onSwitchChange(val, props.values)}
								/>
						}
					</>

			}
		</div>
	)
}

export default SwitchComponent
