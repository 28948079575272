import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions, rsaGridOptions } from './GridComponent'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import useStore from '../../../../../store'
import { vsmCommon } from '../../../../../config/messages'
import { rsaETRType } from '../../../../../utils/GlobalFunction'

const ListComponent = observer((props) => {
	const { openSetViewImportLogDetails, openViewDrawer } = props
	const { ImportETRStore, ImportETRStore: { view_data, setupGrid, onFilterChanged, viewETRType } } = useStore()

	return (
		<div className="ag-theme-alpine grid_wrapper pb-40">
			<AgGridReact
				rowData={view_data}
				modules={AllModules}
				columnDefs={rsaETRType.includes(viewETRType) ? rsaGridOptions.columnDefs : gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openSetViewImportLogDetails, openViewDrawer
				}}
				onColumnResized={ImportETRStore.onChildnGridChanged}
				onColumnMoved={ImportETRStore.onChildnGridChanged}
				onColumnPinned={ImportETRStore.onChildnGridChanged}
				onSortChanged={ImportETRStore.onChildnGridChanged}
				onGridReady={setupGrid}
				gridOptions={LocalGridConfig.options}
				pagination={false}
				onFilterChanged={onFilterChanged}
			/>
		</div>
	)

})

export default ListComponent
