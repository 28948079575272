import React, { useState, useEffect, useCallback } from 'react'
import { Form, Button, Drawer, Spin } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import { vsmClassifications, vsmNotify } from '../../../../config/messages'
import UnlinkComponent from '../../Reconciliation/components/UnlinkComponent'
import { default as TradeTransactionViewComponent } from '../../../TradeTransaction/AllTransactions/component/ViewComponent';

const ViewComponent = observer((props) => {
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(true)
	const [unlinkModal, setUnlinkModal] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [transViewDrawer, setTransViewDrawer] = useState(false)
	const { CashTransactionStore, CTEImportStore, ClassificationStore, OrgBankAccountStore, ReconciliationStore } = useStore()

	// set id for edit / view drawer
	const setViewId = (id) => setTransactionId(id)

	// call api on refresh & useEffect
	const handleRefresh = useCallback(() => {
		setLoading(true)
		CashTransactionStore.getRecordById({ id: props.transactionId }).then((data) => {
			CashTransactionStore.setEditValues(data.data)
			CTEImportStore.setBankAccountList([data.data.ledgers_bank])
			ClassificationStore.cteTypesList = [data.data.cash_transactions_types]
			ClassificationStore.classificationList = [data.data.cte_transaction_classifications]
			OrgBankAccountStore.dropdown_currency_list = [data.data.global_currency]
			setLoading(false)
		}).catch((data) => {
			vsmNotify.error({ message: vsmClassifications.InvalidRecord })
			setLoading(false)
		})
	}, [props, CTEImportStore, OrgBankAccountStore, ClassificationStore, CashTransactionStore])

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			handleRefresh()
		}
	}, [props.visible, handleRefresh])

	// Open confirmation alert before Unlinking Cash Transaction with Trade Transaction
	const openUnlinkModal = (data) => {
		ReconciliationStore.setUnlinkValues(data)
		setUnlinkModal(true)
	}

	// Close confirmation alert for Unlinking record
	const closeUnlinkModal = () => setUnlinkModal(false)

	// open drawer for view trade transaction
	const openTransViewDrawer = (data) => {
		setViewId(data.transaction_id)
		setTransViewDrawer(true)
	}

	// close drawer for view
	const closeTransViewDrawer = () => setTransViewDrawer(false)

	// reset form and close add form
	const close = () => {
		props.close()
		props.setId(null)
		CashTransactionStore.editValues = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`View Cash Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button className="ml-10" onClick={close}>Back</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<FormComponent
					form={form}
					visible={props.visible}
					tab={"view"}
					openUnlinkModal={openUnlinkModal}
					openTransViewDrawer={openTransViewDrawer}
					parentOpenTransViewDrawer={props.openParentTransViewDrawer}
					close={close}
				/>
				<UnlinkComponent tab={"viewCash"} handleRefresh={handleRefresh} visible={unlinkModal} close={closeUnlinkModal} />
				<TradeTransactionViewComponent transactionId={transactionId} setId={setViewId} visible={transViewDrawer} close={closeTransViewDrawer} />
			</Spin>
		</Drawer>
	)
})

export default ViewComponent
