import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal, Spin } from 'antd'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmOrgBankAccount, vsmNotify, vsmClassifications } from "../../../../config/messages";

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [currentTab, setCurrentTab] = useState("account")
	const [disableTab, setDisableTab] = useState()
	const [editValues, setEditValues] = useState(null)
	const [loading, setLoading] = useState(true)
	const { PeopleBankAccountStore, OrgBankAccountStore } = useStore()

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.people_id = props.parent_id
		PeopleBankAccountStore.EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmOrgBankAccount.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable & set value of journal type
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
		}).catch(e => {
			if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// set field vaule null based on tab selection
	const changeMapwith = async () => {
		await setCurrentTab(form.getFieldValue("tab"))
		form.setFieldsValue({
			account_number: null,
			sort_code: null,
			iban: null,
			swift_bic: null
		})
		handleChange()
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			var payload = { people_id: props.parent_id && parseInt(props.parent_id), mapping_type: 2 }
			PeopleBankAccountStore.getRecordById(props.accountId, payload).then((data) => {
				PeopleBankAccountStore.setEditValues(data.data)
				setEditValues(data.data)
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmClassifications.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props, PeopleBankAccountStore])

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({
				bank_name: editValues.banks.name,
				bank_account_type_id: editValues.bank_account_type_id,
				currency_id: editValues.currency_id,
				account_number: editValues.account_number,
				sort_code: editValues.sort_code,
				iban: editValues.iban,
				swift_bic: editValues.swift_bic,
				tab: editValues.account_number ? "account" : "iban"
			})
			if (editValues.account_number) {
				setCurrentTab("account")
				setDisableTab('account')
			} else {
				setCurrentTab("iban")
				setDisableTab('iban')
			}
		}
	}, [setCurrentTab, form, editValues, props.visible])

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setCurrentTab('account')
		setEditValues(null)
		props.setId(null)
		PeopleBankAccountStore.editValues = null
		PeopleBankAccountStore.fetchBankNames = true
		PeopleBankAccountStore.fetchBankTypes = true
		PeopleBankAccountStore.fetchCurrencies = true
		OrgBankAccountStore.dropdown_currency_list = null
	}

	return (
		<Modal
			destroyOnClose={true}
			centered
			title={`Edit Bank Account - # ${editValues && editValues.id} - ${editValues && editValues.banks && editValues.banks.name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<FormComponent
					visible={props.visible}
					onChange={handleChange}
					onFinish={handleSubmit}
					tab={"add"}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
					form={form}
					disableTab={disableTab}
				/>
			</Spin>
		</Modal>
	)

})

export default EditComponent
