import React from 'react'
import { EditOutlined, BankOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import SwitchComponent from './SwitchComponent'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openCloneModal, openFieldModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(73, 'edit') && (
				<Button title="Edit Bank Account" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			)}
			{
				props.data.field_mapping_json && props.data.regex_mapping_json ? (
					<Button title="Manage Bank Fields" type="text" onClick={() => { openFieldModal(props.data, true) }}><BankOutlined /></Button>
				) : (
					<Button title="Manage Bank Fields" type="text" onClick={() => { openCloneModal(props.data) }}><BankOutlined /></Button>
				)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '#ID',
		field: 'id',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Bank Name',
		field: 'banks.name',
		width: 120
	}, {
		headerName: 'Account Type',
		field: 'bank_type',
		filter: 'agSetColumnFilter',
		defaultToNothingSelected: true,
		valueGetter: (params) => (params.data && params.data.bank_type) ? params.data.bank_type : null,
		width: 120
	}, {
		headerName: 'Currency',
		field: 'global_currencies.currency_name',
		filter: 'agSetColumnFilter',
		defaultToNothingSelected: true,
		width: 110
	}, {
		headerName: 'Account No.',
		field: 'account_number',
		valueGetter: (params) => (params.data && params.data.account_number) ? params.data.account_number : null,
		width: 130
	}, {
		headerName: 'Sort Code',
		field: 'sort_code',
		valueGetter: (params) => (params.data && params.data.sort_code) ? params.data.sort_code : null,
		width: 110
	}, {
		headerName: 'IBAN',
		field: 'iban',
		valueGetter: (params) => (params.data && params.data.iban) ? params.data.iban : null,
		width: 150
	}, {
		headerName: 'Swift or BIC Code',
		field: 'swift_bic',
		valueGetter: (params) => (params.data && params.data.swift_bic) ? params.data.swift_bic : null,
		width: 150
	}, {
		headerName: 'Status',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
			values: ["Active", "Deactive"]
		},
		width: 90
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 110
	}]
}
