import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../components/InputComponent';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmLedgerMapBank } from '../../../../config/messages';
import useStore from '../../../../store';

const DynamicFieldElement = observer(({ disabled, onChange, handleAddField, form, add_field_form }) => {
	const { LedgerBankAccountStore: { dynamicFieldList } } = useStore()

	return (
		<div className="w-100">
			<Form.List name='cte_fields'>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							let options = { rules: [{ required: false },] }
							return (
								<div className="w-100" key={field.key}>
									<Row gutter={8}>
										<Col span={9} className="ant-form-item-label">
											<InputComponent
												name={[field.name, 'cte_field_id']} key={[field.key, 'cte_field_id']}
												type="select" allowClear
												placeholder="Select or Add Field"
												onChange={onChange}
												options={{
													values: dynamicFieldList,
													value_key: "id",
													text_key: "field_name",
													accepted_key: form.getFieldValue('cte_fields')[index] && [form.getFieldValue('cte_fields')[index].cte_field_id],
													rejected_keys: form.getFieldValue('cte_fields') && form.getFieldValue('cte_fields').map((item) => item && item.cte_field_id)
												}}
												disabled={disabled}
												autoComplete="off"
												dropdownRender={menu => (
													<div>
														{menu}
														<Form form={add_field_form} onFinish={handleAddField} id="addfieldform" className="add_item_dropdown_wrapper">
															<InputComponent
																name='field_name'
																placeholder="Add Field" onChange={onChange}
																rules={vsmLedgerMapBank.validation.with_required}
																maxLength={50}
																autoComplete="off"
																disabled={disabled}
															/>
															<Button htmlType="submit" className="ml-10" type="primary" form='addfieldform'>
																<PlusOutlined />
															</Button>
														</Form>
													</div>
												)}
											/>
										</Col>
										<Col flex={1}>
											<InputComponent
												name={[field.name, 'field_value']} key={[field.key, 'field_value']}
												placeholder="Enter Field Value" onChange={onChange}
												{...options}
												maxLength={100}
												autoComplete="off"
												disabled={disabled}
											/>
										</Col>
										<Col flex={"90px"}>
											<div>
												{(fields.length > 1) && (
													<Button disabled={disabled} type="danger" onClick={() => { remove(field.name); onChange() }} icon={<MinusOutlined />}></Button>
												)}
												{(index === (fields.length - 1) && (fields.length < 12)) && (
													<Button disabled={disabled} className={index > 0 && "ml-10"} type="primary" onClick={() => { add(); onChange() }} icon={<PlusOutlined />}></Button>
												)}
											</div>
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default DynamicFieldElement
