import React from 'react'
import { observer } from 'mobx-react'
import { Button, Col, Row, Form } from 'antd'
import InputComponent from '../../../../../components/InputComponent'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { vsmManualJournalLog } from '../../../../../config/messages';
import useStore from '../../../../../store';

const RepeaterElement = observer(({ name, onChange, handleDependent, transactionId, form }) => {
	const initialState = {
		transactions_type_id: null,
		model_id: null,
		journal_id: null,
		type: "Debit",
		amount: null
	}
	const { ManualJournalLogStore } = useStore()

	var fieldList = ManualJournalLogStore.dropdown

	return (
		<div>
			<Form.List name={name} initialValue={[initialState]}>
				{
					(fields, { add, remove }) => {
						return fields.map((field, index) => {
							if (!fieldList[index]) {
								fieldList[index] = ManualJournalLogStore.mainDropdown || {}
							}
							return (
								<div key={field.key}>
									<Row className="no-wrap" gutter={10}>
										<Col span={5}>
											<InputComponent
												label="Model"
												name={[field.name, 'model_id']} key={[field.key, 'model_id']}
												placeholder={"Choose Model"} type="select" onChange={() => { handleDependent(field.key); onChange() }}
												tooltip="Transaction Models are used to define the logic of how the data associated with Transactions, should be processed to create Journal Entries. Transaction Models are used when Database Actions, Events or Triggers are executed."
												options={{
													values: fieldList[index].model,
													value_key: 'id',
													text_key: 'model_name',
												}} autoComplete="off" allowClear
											/>
										</Col>

										{
											!transactionId && (
												<Col span={5}>
													<InputComponent
														label="Trade Type"
														name={[field.name, 'transactions_type_id']} key={[field.key, 'transactions_type_id']}
														placeholder={"Choose Trade Type"} type="select" onChange={() => { handleDependent(field.key); onChange() }}
														tooltip="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Transaction Type."
														options={{
															values: fieldList[index].transaction,
															value_key: 'id',
															text_key: 'type_name',
														}} autoComplete="off" allowClear
													/>
												</Col>
											)
										}

										<Col span={5}>
											<InputComponent
												required label="Journal"
												name={[field.name, 'journal_id']} key={[field.key, 'journal_id']}
												placeholder={"Choose Journal"} type="select" onChange={() => { handleDependent(field.key); onChange() }} rules={vsmManualJournalLog.validation.journal}
												tooltip="A Journal is an account against which Journal Entries are stored, e.g. in Accounts, this would be one nominal account in the chart of accounts."
												options={{
													values: fieldList[index].journal,
													value_key: 'id',
													text_key: 'journal_label',
												}} autoComplete="off"
											/>
										</Col>

										<Col className="d-flex" style={{ minWidth: "145px" }}>
											<InputComponent
												required label="Debit/Credit" onChange={onChange}
												type="radio_button" name={[field.name, 'type']} key={[field.key, 'type']}
												tooltip="A debit is an accounting entry that either increases an asset or expense account, or decreases a liability or equity account. A credit is an accounting entry that either increases a liability or equity account, or decreases an asset or expense account."
												options={{
													values: [
														{ value: 'Debit', text: 'Debit' },
														{ value: 'Credit', text: 'Credit' },
													]
												}}
											/>
										</Col>
										<Col flex={1}>
											<InputComponent
												label="&nbsp;"
												name={[field.name, 'amount']} key={[field.key, 'amount']}
												placeholder="Enter Amount" autoComplete="off"
												rules={vsmManualJournalLog.validation.amount}
											/>
										</Col>
										<Col className="text-right d-flex" style={{ paddingTop: "23px" }}>
											{
												(fields.length > 1) && (
													<Button type="danger" onClick={() => remove(field.name)} icon={<MinusOutlined />}></Button>
												)
											}
											{(fields.length < 50) && (index === (fields.length - 1)) && (
												<Button className={fields.length > 1 ? 'ml-5' : ''} type="primary" onClick={() => add(initialState)} icon={<PlusOutlined />}></Button>
											)}
										</Col>
									</Row>
								</div>
							)
						})
					}
				}
			</Form.List>
		</div>
	)
})

export default RepeaterElement
