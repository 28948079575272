import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Unauthorized from '../../Unauthorized'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import RecordPerPage from '../../../components/RecordPerPage';
import EnterFullscreen from '../../../components/EnterFullscreen';
import { Button, PageHeader } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import useStore from '../../../store';
import ListComponent from './component/ListComponent';

const PurchaseOrder = observer((props) => {
    const { UserStore, PurchaseOrderStore: { per_page, agGrid, setPageSize, getList } } = useStore()
    const [autoRefresh, setAutoRefresh] = useState()
    // const [importDrawer, setImportDrawer] = useState(false)

    //open drawer for imoport
    // const openImportDrawer = () => {
    //     setImportDrawer(true)
    // }

    // // close drawer for import
    // const closeImportDrawer = () => setImportDrawer(false)

    const handleAutoRefresh = () => {
        if (!autoRefresh) {
            getList();
            var temp = setInterval(
                function () { getList(); }
                , 3000);
            setAutoRefresh(temp);
        }
    }

    // stop autoRefresh
    const handleStopAutoRefresh = () => {
        if (autoRefresh) {
            clearInterval(autoRefresh)
            setAutoRefresh(null)
        }
    }

    // clear interval on refresh
    useEffect(() => {
        return () => {
            if (autoRefresh) {
                clearInterval(autoRefresh)
                setAutoRefresh(null)
            }
        };
    }, [autoRefresh])

    // reset function
    const handleReset = async () => {
        await localStorage.removeItem('transaction_import_grid')
        agGrid.api.setFilterModel(null)
        agGrid.api.setSortModel(null)
        agGrid.columnApi.resetColumnState()
        agGrid.api.onFilterChanged(null)
    }
    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.PurchaseOrder.path} />
            {!(UserStore.checkPermission(117, 'list')) ? <Unauthorized /> :
                <PageHeader
                    className={UserStore.fullscreen_class}
                    title={BreadcrumbConfig.PurchaseOrder.title}
                    extra={[
                        autoRefresh ?
                            <Button key="1" onClick={handleStopAutoRefresh}>Stop Auto Refresh</Button> :
                            <Button key="1" onClick={handleAutoRefresh}>Auto Refresh</Button>,
                        <RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
                        <Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
                        <EnterFullscreen key='5' />
                    ]}
                >
                    <ListComponent
                    // openEditTradeDrawer={ }
                    // openViewTradeDrawer={ }
                    />
                </PageHeader>
            }
        </>
    )

})

export default PurchaseOrder;