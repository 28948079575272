import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../config/LocalGridConfig"
import moment from 'moment'
import { vsmNotify } from '../../config/messages'
import debounce from 'lodash/debounce'
import GridConfig from "../../config/GridConfig"
import { convertTextToID } from "../../utils/GlobalFunction"

export default class SavedReportStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	agGrid = null
	agGridList = []
	dropdown_models = []
	deleteValues = null
	previewReportValues = null
	dropdown_list = null
	main_organisation_roles = null
	main_people_roles = null
	main_organisation = null
	related_organisation = null
	related_organisation_role = null
	main_people = null
	related_people = null
	related_people_role = null
	my_records = 'all'
	financial_year_list = null
	cteTypesList = null
	auditExportData = null
	auditExportFormatedData = null
	created_by_id = null
	associated_ledger = null
	tradeName_list = null
	title_list = null
	ppsNumber_list = null

	initialValues = {
		report_type: 'trade',
		type: 1,
		journal_type: 'all',
		group_on: 'journals',
		currency: 'ledger',
		single_based_on: 'single_transaction',
		multiple_based_on: 'multiple_transaction',
		main_entity: 'organisation',
		related_entity: 'organisation',
		transaction_ids: [null],
		field_values: [null],
		transaction_dates: [null],
		generate_on: 'all_unarchive_data',
		date_selection: 'range',
		date_x_value: 2,
		date_x_value_type: 'day',
		audit_group_on: 'day',
		period_selection: 'auto',
		period_x_value: 2,
		period_x_value_type: 'day',
		period_date_range: [null, null],
		combined_reports: [null],
		cte_bank_ref: [null],
		cte_customer_ref: [null],
		org_people_bank_iban: [null],
		org_people_bank_acc_no: [null],
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	setPreviewReportValues = (data) => {
		this.previewReportValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = parseInt(page)
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('report_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('report_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('report_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('report_grid', columnConfig)
		}
	}

	// Setup grid and set column size to autosize
	setupAuditGrid = debounce((params, index) => {
		this.AuditGrid = params
	}, 200)

	// Setup grid and set column size to autosize
	setupGridList = (params, index) => {
		var gridApi = this.agGridList
		gridApi[index] = params
		this.agGridList = gridApi
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		// this.setFilter(params.api)
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// export data as a csv
	exportData = async () => {
		this.agGrid.api.exportDataAsCsv()
	}

	// export data as a csv
	exportAudit = async () => {
		var account_numbers = []
		var group_on = []
		if (this.auditExportFormatedData && this.auditExportData) {
			Object.keys(this.auditExportData).forEach(AccountNumber => {
				if (!['group_range', 'group_on', 'account_number'].includes(AccountNumber) && !account_numbers.includes(AccountNumber)) {
					account_numbers.push(AccountNumber)
				}
			})
			this.auditExportFormatedData.forEach(accountData => {
				if (!group_on.includes(accountData.group_on)) {
					group_on.push(accountData.group_on)
				}
			})
			var csv_array = []
			var csv_headerGroup = ['']
			account_numbers && account_numbers.forEach((x, index) => {
				csv_headerGroup.push(x)
				csv_headerGroup.push(x)
				csv_headerGroup.push(x)
			})

			csv_array.push(csv_headerGroup)
			group_on && group_on.forEach((x, index) => {
				var csv_row = []
				if (index === 0) {
					csv_row = ['Group On']
					account_numbers && account_numbers.forEach((x) => {
						csv_row.push('Reciept')
						csv_row.push('Payment')
						csv_row.push('External')
					})
					csv_array.push(csv_row)
				}
				csv_row = [x]
				account_numbers && account_numbers.forEach((account_no) => {
					var g_x = x
					if ((x.match(/-/g) || []).length === 1) {
						g_x = x.replace('-', '_')
					}

					if (g_x && parseInt(g_x[0]) === 0) {
						g_x = g_x.slice(1)
					}
					if (this.auditExportData[account_no] && this.auditExportData[account_no][g_x]) {
						csv_row.push(`"${this.auditExportData[account_no][g_x].receipts.main + '\n' + this.auditExportData[account_no][g_x].receipts.errors}
						"`)
						csv_row.push(`"${this.auditExportData[account_no][g_x].payments.main + '\n' + this.auditExportData[account_no][g_x].payments.errors}
						"`)
						csv_row.push(`"${this.auditExportData[account_no][g_x].externals ? (this.auditExportData[account_no][g_x].externals.main + '\n' + this.auditExportData[account_no][g_x].externals.errors) : '0\n0'}
						"`)
					} else {
						csv_row.push(`"0\n0"`)
						csv_row.push(`"0\n0"`)
						csv_row.push(`"0\n0"`)
					}
				})
				csv_array.push(csv_row)
			})
		}

		let csvContent = "data:text/csv;charset=utf-8," + csv_array.map(e => e.join(",")).join("\n");
		var encodedUri = encodeURI(csvContent);

		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `auditlog-export-${moment().format("YYYY-MM-DD hh:mm:s")}.csv`);
		document.body.appendChild(link); // Required for FF

		link.click();
		document.body.removeChild(link);
	}

	// export multiple reports as a csv
	exportReport = () => {
		if (this.agGridList && this.agGridList.length) {
			this.agGridList.forEach(item => {
				item.api.exportDataAsCsv({ columnGroups: true })
			})
		}
	}
	// export report from server as a csv
	exportReportFromServer = (payload) => {
		return Axios.post(`report/preview/export`, payload).then(({ data }) => {
			if (data.data && data.data.url) { window.open(data.data.url, '_blank'); }
			return data
		}).catch(e => {
			return Promise.reject(e)
		})
	}
	// export data from server as a csv
	exportDataFromServer = (payload) => {
		return Axios.post(`report/data/export`, payload).then(({ data }) => {
			if (data.data && data.data.url) { window.open(data.data.url, '_blank'); }
			return data
		}).catch(e => {
			return Promise.reject(e)
		})
	}

	//call dropdown api
	getAllRecords = () => {
		return Axios.get('report/combined/dropdown/list').then(({ data }) => {
			this.dropdown_list = data.data
			return data.data
		})
	}

	// call list api
	getList = (payload = {}, my_reports = this.my_records) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.get(`report/list`, payload).then(({ data }) => {
			if (data.data) {
				var temp = []
				data.data.forEach((item) => {
					item.last_generated_by = item.user ? (item.user.first_name + " " + item.user.last_name) : 'N/A'
					if (my_reports !== 'all') {
						if (my_reports === item.created_by) {
							temp.push(item)
						}
					} else {
						temp.push(item)
					}
				})
				this.list_data = temp
				this.total = temp.length

				let columnConfig = localStorage.getItem('report_grid')
				if (this.agGrid && this.agGrid.columnApi && columnConfig) {
					this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
				} else {
					if (this.agGrid && this.agGrid.columnApi) {
						this.agGrid.api.setFilterModel(filter)
						this.agGrid.columnApi.applyColumnState({ state: sort })
					}
				}
			}
			return data
		})
	}

	// Call add api
	AddData = (formdata) => {
		let url = `report/create`
		if (formdata.report_type === 'organisation') {
			url = `report/save/organisation`
		}
		if (formdata.report_type === 'people') {
			url = `report/save/people`
		}
		return Axios.post(url, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		let url = `report/edit`
		if (formdata.report_type === 'organisation') {
			url = `report/update/organisation`
		}
		if (formdata.report_type === 'people') {
			url = `report/update/people`
		}
		return Axios.post(`${url}/${formdata.id}`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call preview report api
	PreviewReport = (formdata) => {
		let url = '/report/preview'
		if (formdata.report_type === 'audit') {
			url = '/report/audit'
			if (formdata.action === 'preview_child_report') {
				url = '/report/audit/child'
			}
		}
		if (formdata.report_type === 'organisation') {
			url = `report/preview/organisation`
		}
		if (formdata.report_type === 'people') {
			url = `report/preview/people`
		}
		return Axios.post(url, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call preview report api
	PreviewData = (formdata) => {
		return Axios.post(`/report/preview/data`, formdata).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (formdata.type && formdata.type === 'combined_reports') {
						item && item.forEach((x, i) => {
							x.exchange_ref_id = x.exchange_ref_id ? x.exchange_ref_id : null
							x.entity_name = x.entity_name ? x.entity_name : null
							x.related_entity_name = x.related_entity_name ? x.related_entity_name : null
							x.reference = x.reference ? x.reference : null
							x.traded_on = x.traded_on ? x.traded_on : null
							x.posted_on = x.posted_on ? x.posted_on : null
							x.face_value = x.face_value ? x.face_value : 0.00
						})
					} else {
						item.exchange_ref_id = item.exchange_ref_id ? item.exchange_ref_id : null
						item.entity_name = item.entity_name ? item.entity_name : null
						item.related_entity_name = item.related_entity_name ? item.related_entity_name : null
						item.reference = item.reference ? item.reference : null
						item.traded_on = item.traded_on ? item.traded_on : null
						item.posted_on = item.posted_on ? item.posted_on : null
						item.face_value = item.face_value ? item.face_value : 0.00
					}
				});
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call Read report api
	ReadReport = (id) => {
		return Axios.get(`/report/read/${id}`).then(({ data }) => {
			if (data.data) {
				this.created_by_id = data.data.created_by
				this.previewReportValues = {
					...JSON.parse(data.data.generated_on_options),
					id: data.data.id,
					name: data.data.name,
					code: data.data.code,
					description: data.data.description,
					report_type: data.data.report_type
				}
			}
			return data
		}).catch(e => {
			return Promise.reject(e)
		})
	}

	// Call MODEL DROPDOWN api
	getAllModels = () => {
		return Axios.get(`/report/model/list`).then(({ data }) => {
			this.dropdown_models = data.data
			return data
		}).catch((e) => {
			return Promise.reject(e)
		})
	}


	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`report/delete/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	formatFormData = (data, forRequest = true) => {
		var temp
		if (data) {
			Object.keys(data).forEach(key => {
				if (!['form_list', 'id', 'action', 'name', 'code', 'description'].includes(key)) {
					if (!data.form_list.includes(key)) {
						delete data[key]
					}
				}
			})
			if (forRequest) {
				this.previewReportValues = data
				if (data.field_values) {
					var temp_field_values = []
					data.field_values.map((item, index) => {
						if (item && JSON.stringify(item) !== '{}') {
							temp_field_values.push(item)
						}
						return null
					})
					data.field_values = temp_field_values
				}
				if (data.transaction_ids) {
					var temp_transaction_ids = []
					data.transaction_ids.map((item, index) => {
						if (item && JSON.stringify(item) !== '{}') {
							temp_transaction_ids.push(item)
						}
						return null
					})
					data.transaction_ids = temp_transaction_ids
				}
				if (data.form_list.includes('main_organisation')) { data.main_organisation = (data.main_organisation && data.main_organisation.length > 0) ? data.main_organisation : [0] }
				if (data.form_list.includes('main_organisation_role')) { data.main_organisation_role = (data.main_organisation_role && data.main_organisation_role.length > 0) ? data.main_organisation_role : [0] }
				if (data.form_list.includes('related_organisation')) { data.related_organisation = (data.related_organisation && data.related_organisation.length > 0) ? data.related_organisation : [0] }
				if (data.form_list.includes('related_organisation_role')) { data.related_organisation_role = (data.related_organisation_role && data.related_organisation_role.length > 0) ? data.related_organisation_role : [0] }

				if (data.form_list.includes('main_people')) { data.main_people = (data.main_people && data.main_people.length > 0) ? data.main_people : [0] }
				if (data.form_list.includes('main_people_role')) { data.main_people_role = (data.main_people_role && data.main_people_role.length > 0) ? data.main_people_role : [0] }
				if (data.form_list.includes('related_people')) { data.related_people = (data.related_people && data.related_people.length > 0) ? data.related_people : [0] }
				if (data.form_list.includes('related_people_role')) { data.related_people_role = (data.related_people_role && data.related_people_role.length > 0) ? data.related_people_role : [0] }

				if (data.form_list.includes('cte_bank_ref') && data.cte_bank_ref) { data.cte_bank_ref = data.cte_bank_ref.filter(x => (x !== null && x !== "")) }
				if (data.form_list.includes('cte_customer_ref') && data.cte_customer_ref) { data.cte_customer_ref = data.cte_customer_ref.filter(x => (x !== null && x !== "")) }
				if (data.form_list.includes('org_people_bank_iban') && data.org_people_bank_iban) { data.org_people_bank_iban = data.org_people_bank_iban.filter(x => (x !== null && x !== "")) }
				if (data.form_list.includes('org_people_bank_acc_no') && data.org_people_bank_acc_no) { data.org_people_bank_acc_no = data.org_people_bank_acc_no.filter(x => (x !== null && x !== "")) }

				if (data.form_list.includes('transaction_ids') && data.transaction_ids) { data.transaction_ids = data.transaction_ids.filter(x => (x !== null && x !== "")) }
				if (data.form_list.includes('field_values') && data.field_values) { data.field_values = data.field_values.filter(x => (x !== null && x !== "")) }
				if (data.form_list.includes('transaction_dates') && data.transaction_dates) { data.transaction_dates = data.transaction_dates.filter(x => (x !== null && x !== "")) }

				if (data.date) {
					data.date = moment(data.date).format("YYYY-MM-DD")
				}
				if (data.date_range && data.date_range.length === 2) { data.date_range = { from_date: moment(data.date_range[0]).format("YYYY-MM-DD"), to_date: moment(data.date_range[1]).format("YYYY-MM-DD") } }
				if (data.period_date_range) {
					temp = []
					data.period_date_range.map((item, index) => {
						if (item && item.length === 2) {
							temp.push({
								from_date: moment(item[0]).format("YYYY-MM-DD"),
								to_date: moment(item[1]).format("YYYY-MM-DD")
							})
						}
						return null
					})
					if (temp.length > 0) {
						data.period_date_range = temp
					}
				}
				if (data.transaction_dates) {
					temp = []
					data.transaction_dates.map((item, index) => {
						if (item && item.transaction_date_value && item.transaction_date_value.length === 2) {
							temp.push({
								...item,
								transaction_date_value: {
									from_date: moment(item.transaction_date_value[0]).format("YYYY-MM-DD"),
									to_date: moment(item.transaction_date_value[1]).format("YYYY-MM-DD")
								}
							})
						}
						return null
					})
					data.transaction_dates = temp
				}
			} else {

				if (data.form_list.includes('main_organisation') && JSON.stringify(data.main_organisation) === JSON.stringify([0])) { data.main_organisation = undefined }
				if (data.form_list.includes('main_organisation_role') && JSON.stringify(data.main_organisation_role) === JSON.stringify([0])) { data.main_organisation_role = undefined }
				if (data.form_list.includes('related_organisation') && JSON.stringify(data.related_organisation) === JSON.stringify([0])) { data.related_organisation = undefined }
				if (data.form_list.includes('related_organisation_role') && JSON.stringify(data.related_organisation_role) === JSON.stringify([0])) { data.related_organisation_role = undefined }

				if (data.form_list.includes('main_people') && JSON.stringify(data.main_people) === JSON.stringify([0])) { data.main_people = undefined }
				if (data.form_list.includes('main_people_role') && JSON.stringify(data.main_people_role) === JSON.stringify([0])) { data.main_people_role = undefined }
				if (data.form_list.includes('related_people') && JSON.stringify(data.related_people) === JSON.stringify([0])) { data.related_people = undefined }
				if (data.form_list.includes('related_people_role') && JSON.stringify(data.related_people_role) === JSON.stringify([0])) { data.related_people_role = undefined }

				if (data.form_list.includes('cte_bank_ref') && data.cte_bank_ref) { data.cte_bank_ref = data.cte_bank_ref.length > 0 ? data.cte_bank_ref : [null] }
				if (data.form_list.includes('cte_customer_ref') && data.cte_customer_ref) { data.cte_customer_ref = data.cte_customer_ref.length > 0 ? data.cte_customer_ref : [null] }
				if (data.form_list.includes('org_people_bank_iban') && data.org_people_bank_iban) { data.org_people_bank_iban = data.org_people_bank_iban.length > 0 ? data.org_people_bank_iban : [null] }
				if (data.form_list.includes('org_people_bank_acc_no') && data.org_people_bank_acc_no) { data.org_people_bank_acc_no = data.org_people_bank_acc_no.length > 0 ? data.org_people_bank_acc_no : [null] }

				if (data.form_list.includes('transaction_ids') && data.transaction_ids) { data.transaction_ids = data.transaction_ids.length > 0 ? data.transaction_ids : [null] }
				if (data.form_list.includes('field_values') && data.field_values) { data.field_values = data.field_values.length > 0 ? data.field_values : [null] }
				if (data.form_list.includes('transaction_dates') && data.transaction_dates) { data.transaction_dates = data.transaction_dates.length > 0 ? data.transaction_dates : [null] }

				if (data.date) { data.date = moment(data.date) }
				if (data.date_range && data.date_range.from_date) { data.date_range = [moment(data.date_range.from_date), moment(data.date_range.to_date)] }
				if (data.period_date_range && data.period_date_range[0] && data.period_date_range[0].from_date) {
					temp = []
					data.period_date_range.forEach((item, index) => {
						temp.push([moment(item.from_date), moment(item.to_date)])
					})
					data.period_date_range = temp
				}
				if (data.transaction_dates && data.transaction_dates[0] && data.transaction_dates[0].transaction_date_value.from_date) {
					temp = []
					data.transaction_dates.forEach((item, index) => {
						if (item && item.transaction_date_value) {
							temp.push({
								...item,
								transaction_date_value: [moment(item.transaction_date_value.from_date), moment(item.transaction_date_value.to_date)]
							})
						}
						return null
					})
					if (temp.length <= 0) {
						temp = [null]
					}
					data.transaction_dates = temp
				}
			}
		}
		return data
	}

	saveToMenu = (formdata, is_favorite) => {
		return Axios.post(`report/favorite/status/${formdata}`, { is_favorite: is_favorite }).then(({ data }) => {
			vsmNotify.success({
				message: data.message
			})
			this.getList()
			return data
		}).catch(e => {
			return Promise.reject(e)
		})
	}

	getMainRoles = (type = "organisation") => {
		var payload = { main_entity: type }
		return Axios.post(`report/main/role`, payload).then(({ data }) => {
			if (type === 'people') {
				this.main_people_roles = data.data
			} else {
				this.main_organisation_roles = data.data
			}
			return data
		})
	}

	getDependentList = (payload) => {
		return Axios.post(`report/dropdown/list`, payload).then(({ data }) => {
			this.main_organisation = data.data.main_organisation
			this.related_organisation = data.data.related_organisation
			this.related_organisation_role = data.data.related_organisation_role
			this.main_people = data.data.main_people
			this.related_people = data.data.related_people
			this.related_people_role = data.data.related_people_role
			return data
		})
	}

	getFinancialYearList = () => {
		return Axios.get(`report/financial/year`).then(({ data }) => {
			this.financial_year_list = data.data

			return data
		})
	}
	PreviewDetailPayload = {}
	agPreviewDetailGrid = null
	previewDetailGrid = []
	previewReportID = null
	PreviewDetailPinnedRow = null
	ZeroRecord = true
	filter_org = []
	filter_related_org = []
	setupPreviewDetailGrid = (params) => {
		this.agPreviewDetailGrid = params
		var datasource = this.createDatasource(GridConfig.options)
		params.api.setServerSideDatasource(datasource)
	}

	getOrgFilter = (type) => {
		var extra_payload = {}
		if (SavedReportStore.previewReportID) {
			extra_payload.report_id = parseInt(SavedReportStore.previewReportID)
		}
		let formdata = {
			...this.PreviewDetailPayload,
			zero_record: this.ZeroRecord,
			dropdown_type: type
		}

		return Axios.post(`report/child/data`, { ...formdata, ...extra_payload }).then(({ data }) => {
			if (type === 'entity') {
				this.filter_org = data.data
			} else {
				this.filter_related_org = data.data
			}
			return data
		}).catch(data => {
			return Promise.reject(data)
		})
	}


	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {

				var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }
				if (filter_data.final_filter['entity_name']) {
					filter_data.final_filter['entity_name'].values = convertTextToID(filter_data.final_filter['entity_name'], this.filter_org, 'entity_name', 'entity_id')
				}
				if (filter_data.final_filter['related_entity_name']) {
					filter_data.final_filter['related_entity_name'].values = convertTextToID(filter_data.final_filter['related_entity_name'], this.filter_related_org, 'related_entity_name', 'related_entity_id')
				}

				var payload = {
					...this.PreviewDetailPayload,
					zero_record: this.ZeroRecord,
					filter_data: filter_data.final_filter,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
				}
				this.PreviewDetails(payload).then((data) => {
					this.PreviewDetailPinnedRow = data.extra_data
					if (data.total === 0) { this.agPreviewDetailGrid.api.showNoRowsOverlay() }
					else { this.agPreviewDetailGrid.api.hideOverlay(); }
					params.successCallback(data.data, data.total)
				})
			}
		}
	}

	// Call preview report details
	PreviewDetails = (formdata) => {
		var extra_payload = {}
		if (SavedReportStore.previewReportID) {
			extra_payload.report_id = parseInt(SavedReportStore.previewReportID)
		}
		if (!formdata.filter_data) { extra_payload.filter_data = {} }
		if (!formdata.sort_data) { extra_payload.sort_data = {} }
		return Axios.post(`report/child/data`, { ...formdata, ...extra_payload }).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.entity_name = item.entity_name ? item.entity_name : null
					item.related_entity_name = item.related_entity_name ? item.related_entity_name : null
					item.face_value = item.face_value ? item.face_value : 0.00
					item.journal_log_date = item.journal_log_date ? item.journal_log_date : null
					item.debit = item.debit ? item.debit : 0.00
					item.credit = item.credit ? item.credit : 0.00
					item.posting = item.posting ? item.posting : 0.00
					if (item.periods) {
						item.periods.forEach((p) => {
							p.debit = p.debit ? p.debit : 0.00
							p.credit = p.credit ? p.credit : 0.00
							p.posting = p.posting ? p.posting : 0.00
						})
					}
				});
			}
			return data
		}).catch(({ response }) => {
			let data = {}
			if (response) {
				data = response.data
				var errors = []
				Object.keys(data.errors).forEach(name => {
					errors.push({ name, errors: data.errors[name] })
				})
				data.errors = errors
			}
			return Promise.reject(data)
		})
	}

	getCashTransactionType = () => {
		return Axios.get('report/cash/transactions/types').then(({ data }) => {
			this.cteTypesList = data.data

			return data.data
		})
	}

	getAssociatedLedger = () => {
		return Axios.get(`report/organisation/associated/ledgers`).then(({ data }) => {
			this.associated_ledger = data.data
			return data.data
		})
	}

	getTradeNameList = () => {
		return Axios.post(`report/trade/name/list`, { trade_name: null }).then(({ data }) => {
			if (data.data.length > 0) {
				data.data = data.data.filter((item) => item.trade_name !== "" && item.trade_name !== null)
			}
			this.tradeName_list = data.data
			return data.data;
		})
	}

	getTitleList = () => {
		return Axios.post(`report/title/list`, { title: null }).then(({ data }) => {
			this.title_list = data.data
			return data.data
		})
	}

	getPPSNumberList = () => {
		return Axios.post(`report/pps/number/list`, { pps_number: null }).then(({ data }) => {
			this.ppsNumber_list = data.data
			return data.data
		})
	}

}

decorate(SavedReportStore, {
	list_data: observable,
	all_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	agGrid: observable,
	agGridList: observable,
	deleteValues: observable,
	initialValues: observable,
	dropdown_models: observable,
	dropdown_list: observable,
	main_organisation_roles: observable,
	main_people_roles: observable,
	main_organisation: observable,
	related_organisation: observable,
	related_organisation_role: observable,
	main_people: observable,
	related_people: observable,
	related_people_role: observable,
	my_records: observable,
	financial_year_list: observable,
	cteTypesList: observable,
	auditExportData: observable,
	auditExportFormatedData: observable,
	created_by_id: observable,
	ZeroRecord: observable,
	filter_org: observable,
	filter_related_org: observable,
	associated_ledger: observable,
	tradeName_list: observable,
	title_list: observable,
	ppsNumber_list: observable,
	getList: action,
	AddData: action,
	DeleteData: action,
	setupGrid: action,
	setupAuditGrid: action,
	setPageSize: action,
	setPreviewReportValues: action,
	PreviewReport: action,
	PreviewData: action,
	exportData: action,
	exportReport: action,
	ReadReport: action,
	saveToMenu: action,
	getAllRecords: action,
	getMainRoles: action,
	PreviewDetails: action,
	getFinancialYearList: action,
	getDependentList: action,
	getCashTransactionType: action,
	exportAudit: action,
	onGridChanged: action,
	getAssociatedLedger: action,
	getTradeNameList: action,
	getTitleList: action,
	getPPSNumberList: action,
})
