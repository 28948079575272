import React from 'react'
import useStore from '../../../../store'
import { observer } from 'mobx-react-lite'

const BankDetailView = observer(() => {
	const { MemberStore: { viewMemberValue } } = useStore()

	//get_member_bank
	return (
		<div className='members_table'>
			<table cellPadding='0' cellSpacing='0' border='0'>
				<tbody key="tbody">
					<tr>
						<th width="100px">Bank Name</th>
						<th width="100px">Account Type</th>
						<th width="100px">Currency</th>
						<th width="100px">Account No. / IBAN</th>
						<th width="100px">Sort Code / Swift or BIC Code</th>
					</tr>
					{viewMemberValue?.get_member_bank?.length > 0 &&
						viewMemberValue?.get_member_bank?.map((item, index) => {
							return (
								<tr key={index}>
									<td width="100px">{item?.bank_name}</td>
									<td width="100px">{item?.bank_accounts_type?.name}</td>
									<td width="100px">{item?.currency?.currency_name}</td>
									<td width="100px">{item?.account_number ? item?.account_number : item?.iban}</td>
									<td width="100px">{item?.sort_code ? item?.sort_code : item?.swift_bic}</td>
								</tr>
							)
						})
					}
					{
						viewMemberValue?.get_member_bank?.length === 0 &&
						<tr>
							<td width="100px"></td>
							<td width="100px"></td>
							<td width="100px"></td>
							<td width="100px"></td>
							<td width="100px"></td>
						</tr>
					}
				</tbody>
			</table>
		</div>
	)
})

export default BankDetailView