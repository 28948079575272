import React, { useState } from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmMember } from '../../../../config/messages'
import { Button, Col, Form, message, Row, Spin, Upload } from 'antd'
import { PlusOutlined, InboxOutlined, PlusCircleOutlined } from "@ant-design/icons";
import PhoneElement from '../elements/PhoneElement'
import EmailElement from '../elements/EmailElement'
import AddressElement from '../elements/AddressElement'
import useStore from '../../../../store'
import { MemeberType } from '../../../../utils/GlobalFunction'
import moment from 'moment'
import AddComponent from '../../../PeopleManagement/AllPeople/component/AddComponent'

const UserFormComponent = observer(({ form, handleSubmit, setDisabled, allFiles, updateAllFiles }) => {
	const { MemberStore: { openFormType, userInitialValues, getParentOrganisationList, parent_organisations_list, country_list, getAllCountries, industries_list, getIndustries, getNaceCode, naceCode_list, currenciesList, getCurrenciesList, viewMemberValue, personList, getPersonList } } = useStore()
	const [fetchMappedOrg, setFetchMappedOrg] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchIndustries, setFetchIndustries] = useState(true)
	const [fetctNace, setFetchNace] = useState(true)
	const [naceCode, setNaceCode] = useState()
	const [pesronList, updatePesronList] = useState(true)
	const [peopleDrawer, setPeopleDrawer] = useState(false)

	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch((e) => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const changeNACE = (e) => {
		if (e) {
			const naceCode = naceCode_list.find(list => list.id === e)
			setNaceCode(naceCode.nace_section)
			form.resetFields(['nace_section_id'])
		} else {
			setNaceCode(null)
			form.resetFields(['nace_section_id'])
		}
		handleChange()
	}

	// Logo file settings
	const uploadLogoProps = {
		onChange: ({ fileList: newFileList }) => {
			updateAllFiles(prev => ({ ...prev, logo_url: newFileList }))
		},
		// allFiles[logo_url],
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				return true;
			}
			else {
				updateAllFiles(prev => ({ ...prev, logo_url: [file] }))
				return false
			}
		}
	}

	const onRemoveImage = () => {
		form.resetFields(['logo_url'])
		updateAllFiles(prev => ({ ...prev, logo_url: [] }))
		handleChange();
	}

	// Bank Statement file setting
	const uploadPropsProofBankStatement = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['bank_proof'] ? allFiles['bank_proof'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, bank_proof: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadAgreement = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1
		if (!isCSV) {
			message.error(`Proof of Bank Statement must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestProofBankStatement = ({ file, fileList, onSuccess }) => {
		setTimeout(() => { onSuccess("ok") }, 0)
	}

	const onDownloadProofBankStatement = (info) => {
		window.open(info.url, "_blank")
	}

	// Proof of Bank statement file settings
	const uploadPropsProofAgreement = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['aggreement_proof'] ? allFiles['aggreement_proof'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, aggreement_proof: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeProofBankStatement = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Proof of Agreement must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestProofAgreement = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadProofAgreement = (info) => {
		window.open(info.url, "_blank")
	}

	const uploadPropsBankStatementDoc = {
		name: "file",
		accept: ".pdf",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['bank_statement_proof'] ? allFiles['bank_statement_proof'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, bank_statement_proof: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeBankStatementDoc = (file) => {
		let isCSV = file.type === 'application/pdf';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Bank Statement Document must be in pdf format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangeBankStatementDoc = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangeBankStatementDoc = (info) => {
		window.open(info.url, "_blank")
	}

	// Cheque Document setting
	const uploadPropsChequeDoc = {
		name: "file",
		accept: ".pdf",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['cheque_document'] ? allFiles['cheque_document'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, cheque_document: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeChequeDoc = (file) => {
		let isCSV = file.type === 'application/pdf';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Cheque Document must be in pdf format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangeChequeDoc = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangeChequeDoc = (info) => {
		window.open(info.url, "_blank")
	}

	// Personal Indentity Record file settings
	const uploadPropsPersonalIndentityRecord = {
		name: "file",
		accept: ".pdf",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['personal_identity_record'] ? allFiles['personal_identity_record'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, personal_identity_record: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangePersonalIndentityRecord = (file) => {
		let isCSV = file.type === 'application/pdf';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Personal Identity Record Form must be in pdf format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangePersonalIndentityRecord = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangePersonalIndentityRecord = (info) => {
		window.open(info.url, "_blank")
	}

	// Proof of Indentity file settings
	const uploadPropspProofIndentity = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['id_proof'] ? allFiles['id_proof'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, id_proof: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeProofIndentity = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Proof of Identity must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Proof of Identity must be in xls, xlsx, csv format. File Size should be less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangeProofIndentity = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangeProofIndentity = (info) => {
		window.open(info.url, "_blank")
	}

	// Passport Driving License file settings
	const uploadPropspassportDrivingLicense = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['passport_driving_license'] ? allFiles['passport_driving_license'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, passport_driving_license: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangePassportDrivingLicense = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error("Passport/Driving License Document Document must be in pdf format. File Size should be less then 1 MB.")
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangePassportDrivingLicense = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangePassportDrivingLicense = (info) => {
		window.open(info.url, "_blank")
	}

	// Utility Bill/Bank Statement Document File settings
	const uploadPropsBillBankStatementDoc = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['utility_document'] ? allFiles['utility_document'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, utility_document: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeBillBankStatementDoc = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error("Utility Bill/ Bank Statement Document must be in pdf format. File Size should be less then 1 MB.")
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangeBillBankStatementDoc = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangeBillBankStatementDoc = (info) => {
		window.open(info.url, "_blank")
	}

	// Signed RPA Agreement
	const uploadPropsSignedRPAAgreement = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['rpa_agreement'] ? allFiles['rpa_agreement'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, rpa_agreement: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeSignedRPAAgreement = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Signed RPA Agreement must be in pdf format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestChangeSignedRPAAgreement = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadChangeSignedRPAAgreement = (info) => {
		window.open(info.url, "_blank")
	}

	const disabledFormationYear = (current) => {
		const tooLate = current && current <= moment().endOf('years').year('1950');;
		const tooEarly = current && current > moment().endOf('years');
		return tooLate || tooEarly
	}

	/* const openPeopleDrawer = () => {
		setPeopleDrawer(true)
	} */

	const closeAddDrawer = () => {
		setPeopleDrawer(false)
	}

	const percentagesNode = (e, name) => {
		let value = Number(e.target.value)
		if (value < 10) {
			form.setFieldsValue({ [name]: value.toFixed(3) })
		} else if (value > 10 && value < 100) {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		} else if (value <= 100) {
			form.setFieldsValue({ [name]: value.toFixed(1) })
		} else {
			form.setFieldsValue({ [name]: value })
		}
	}

	return (
		<Form
			form={form}
			id={`${openFormType}-0`}
			onFinish={handleSubmit}
			layout='vertical'
			initialValues={userInitialValues}
		>
			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						required label='Organisation Name' name='organisation_name' placeholder='Organisation Name' onChange={handleChange}
						tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
						maxLength={100} rules={vsmMember.validation.organisation_name}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						label='Trade Name' name='trade_name' placeholder='Trade Name' onChange={handleChange}
						tooltip='Trade name is a trading name commonly used by an Organisation to perform business activities.'
						maxLength={100} rules={vsmMember.validation.trade_name}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						type='select' allowClear label='Parent Organisation(s)' name='parent_organisation_id' placeholder='Parent Organisation(s)' onChange={handleChange}
						options={{
							values: parent_organisations_list || [{
								id: viewMemberValue && viewMemberValue.parent && viewMemberValue.parent.id && viewMemberValue.parent.id,
								organisation_name: viewMemberValue && viewMemberValue.parent && viewMemberValue.parent.organisation_name && viewMemberValue.parent.organisation_name
							}],
							value_key: 'id',
							text_key: 'organisation_name'
						}}
						onFocus={() => (fetchMappedOrg && getParentOrganisationList().then(() => setFetchMappedOrg(false)))}
						tooltip='Parent Organisation Role is an Organisation Role that has primary role in a particular relation between two Organisations in the context of a single Transaction.'
						notFoundContent={fetchMappedOrg ? <Spin size="small" /> : "No Record Found."} filterOption={true}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						type='select' label='Type(s)' name='type' placeholder='Type(s)' tooltip='Role associated with this member.' onChange={handleChange}
						options={{ values: MemeberType }} disabled
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						required type='select' label='Currency' name='currency_id' placeholder='Select Currency' onChange={handleChange}
						options={{
							values: currenciesList || [{
								id: viewMemberValue && viewMemberValue.global_currency && viewMemberValue.global_currency.id && viewMemberValue.global_currency.id,
								currency_name: viewMemberValue && viewMemberValue.global_currency && viewMemberValue.global_currency.currency_name && viewMemberValue.global_currency.currency_name
							}],
							value_key: "id",
							text_key: "currency_name"
						}}
						tooltip='Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger.'
						onFocus={() => (fetchCurrency && getCurrenciesList().then(() => setFetchCurrency(false)))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						filterOption={true} rules={vsmMember.validation.org_currency}
					/>
				</Col>
				{openFormType === 'Originator' ?
					<Col span={4}>
						<InputComponent
							label='Turn Over' name='turn_over' placeholder='Turn Over' onChange={handleChange}
							tooltip='Enter the annual/estimated income for this organisation'
							rules={vsmMember.validation.turn_over} disabled
						/>
					</Col>
					: null}
				<Col span={5}>
					<InputComponent
						required type='select' name='industry_id' placeholder='Select Credebt® Classification' onChange={handleChange}
						label={<Button type='link' className='p-0' style={{ height: '22px' }} onClick={() => window.open("https://www.convertibill.com/credebtclassifications-popup.html", "", "width=650,height=600")}>Credebt® Classification</Button>}
						options={{
							values: industries_list || [{
								id: viewMemberValue && viewMemberValue.global_industries && viewMemberValue.global_industries.id && viewMemberValue.global_industries.id,
								name: viewMemberValue && viewMemberValue.global_industries && viewMemberValue.global_industries.name && viewMemberValue.global_industries.name
							}],
							value_key: 'id',
							text_key: 'name'
						}}
						onFocus={() => (fetchIndustries && getIndustries().then(() => setFetchIndustries(false)))}
						notFoundContent={fetchIndustries ? <Spin size="small" /> : "No Record Found."}
						tooltip='A classification is a group of productive enterprises or organisations that produce or supply goods, services or sources of income.'
						rules={vsmMember.validation.industry} filterOption={true}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						allowClear type='select' name='nace_section' placeholder='Select NACE Category' onChange={changeNACE}
						label={<Button type='link' className='p-0' style={{ height: '22px' }} onClick={() => window.open("https://www.convertibill.com/nacecategories-popup.html", "", "width=650,height=600")}>NACE Category</Button>}
						options={{
							values: naceCode_list || [{
								id: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.get_nace_category && viewMemberValue.global_nace_sections.get_nace_category.id && viewMemberValue.global_nace_sections.get_nace_category.id,
								category_name: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.get_nace_category && viewMemberValue.global_nace_sections.get_nace_category.category_name && viewMemberValue.global_nace_sections.get_nace_category.category_name
							}],
							value_key: "id",
							text_key: "category_name"
						}}
						onFocus={() => (fetctNace && getNaceCode().then(() => setFetchNace(false)))}
						tooltip='NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities.'
						notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."} filterOption={true}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required={naceCode ? true : false} allowClear type='select' label='NACE Code' name='nace_section_id' placeholder='Select NACE Code' onChange={handleChange}
						onFocus={() => (fetctNace && getNaceCode().then(() => setFetchNace(false)))}
						notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: naceCode || [{
								id: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.id && viewMemberValue.global_nace_sections.id,
								code: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.code && viewMemberValue.global_nace_sections.code,
								section: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.section && viewMemberValue.global_nace_sections.section
							}],
							value_key: "id",
							text_key: { key: ["code", " - ", "section"] }
						}}
						disabled={naceCode ? false : true} filterOption={true}
						tooltip='Select NACE Code for the selected NACE Category.'
						rules={vsmMember.validation.nace_section_id}
					/>
				</Col>
				<Col span={18}>
					<Row gutter={24}>
						<Col span={8}>
							<InputComponent
								type='select' required label='Registration Country' name='registered_country_id' placeholder='Registration Country' onChange={handleChange}
								options={{
									values: country_list || [{
										id: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.id && viewMemberValue.global_countries.id,
										iso_code_alpha2: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.iso_code_alpha3 && viewMemberValue.global_countries.iso_code_alpha3,
										country_name: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.country_name && viewMemberValue.global_countries.country_name,
									}],
									value_key: 'id',
									text_key: { key: ['iso_code_alpha2', ' - ', 'country_name'] },
								}}
								tooltip='A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities.'
								onFocus={() => fetchCountry && getAllCountries().then(() => setFetchCountry(false))}
								notFoundContent={fetchCountry ? <Spin size='small' /> : 'No Record Found.'}
								autoComplete='off'
								rules={vsmMember.validation.registration_country}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								label='Registration Number' name='registration_number' placeholder='Registration Number' onChange={handleChange}
								tooltip='A number appointed by relevant Company Registration Authorities in a country the organisation was founded in.'
								rules={vsmMember.validation.registration_number}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								label='Sales Tax/VAT Number' name='vat_number' placeholder='Sales Tax/VAT Number' maxLength={100} onChange={handleChange}
								tooltip='A number appointed by relevant Tax Authorities in a country the organisation was founded in.'
								rules={vsmMember.validation.vat_number}

							/>
						</Col>
						{openFormType === 'Originator' ?
							<>
								<Col span={8} className="withExtraNote">
									<InputComponent
										type='select' label='Tax ID Document' name='tex_id_doc' placeholder='Tax ID Document' onChange={handleChange}
										options={{ values: [] }}
										tooltip='Select the type of tax document associated with this organisation.'
										rules={vsmMember.validation.tex_id_doc} disabled
										note={<div /* onClick={openCredebtor} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '18px' }} /></div>}
									/>
								</Col>
								<Col span={8}>
									<InputComponent
										label='Tax ID Number' name='tax_id_number' placeholder='Tax ID Number' onChange={handleChange}
										tooltip='Select the personal tax identification number associated with this organisation.'
										rules={vsmMember.validation.tax_id_number} disabled
									/>
								</Col>
								<Col span={8}>
									<InputComponent
										label='Tax Clearance Access Number' name='revenue_access_number' placeholder='Tax Clearance Access Number' onChange={handleChange}
										tooltip='When Revenue issues a Tax Clearance Certificate, they will also give a Tax Clearance Access Number (TCAN).'
										rules={vsmMember.validation.tax_clearence}
									/>
								</Col>
							</>
							: (openFormType === 'Intermediary' || openFormType === 'Investor') ?
								<>
									{openFormType === 'Intermediary' ?
										<>
											<Col span={8}>
												<Form.Item
													label="Proof of Bank Statement"
													className="full-width-dragger bank-statement"
													name="bank_proof"
													tooltip="The evidence needs to show the name the account is in and the bank account number."
												>
													<Upload.Dragger
														{...uploadPropsProofBankStatement}
														beforeUpload={handleFileUploadChangeProofBankStatement}
														customRequest={dummyRequestProofBankStatement}
														onDownload={onDownloadProofBankStatement}
														fileList={Object.keys(allFiles).length ? allFiles['bank_proof'] ? allFiles['bank_proof'] : [] : []}
														onRemove={() => updateAllFiles(prev => ({ ...prev, bank_proof: [] }))}
													>
														<span className="ant-upload-drag-icon">
															<InboxOutlined style={{ fontSize: '16px' }} />
														</span>
														<span className="ant-upload-text">
															Drag Proof of Bank Statement File
														</span>
													</Upload.Dragger>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													label="Proof of Agreement"
													className="full-width-dragger bank-statement"
													name="aggreement_proof"
													tooltip="Upload proof of agreement document."
												>
													<Upload.Dragger
														{...uploadPropsProofAgreement}
														beforeUpload={handleFileUploadAgreement}
														customRequest={dummyRequestProofAgreement}
														onDownload={onDownloadProofAgreement}
														fileList={Object.keys(allFiles).length ? allFiles['aggreement_proof'] ? allFiles['aggreement_proof'] : [] : []}
														onRemove={() => updateAllFiles(prev => ({ ...prev, aggreement_proof: [] }))}
														disabled={false}
													>
														<span className="ant-upload-drag-icon">
															<InboxOutlined style={{ fontSize: '16px' }} />
														</span>
														<span className="ant-upload-text">
															Drag Proof of Agreement File
														</span>
													</Upload.Dragger>
												</Form.Item>
											</Col>
											<Col span={8}>
												<InputComponent
													label='Buy Rate' name='buy_rate' placeholder='Buy Rate'
													onChange={handleChange} onBlur={(e) => percentagesNode(e, 'buy_rate')}
													tooltip='The Buy rate is the agreed yield/return that the Intermediary, or the Investor, negotiates with the Exchange.'
													rules={vsmMember.validation.buy_rate}
												/>
											</Col>
										</>
										:
										<>
											<Col span={8}>
												<Form.Item
													label="Bank Statement Document"
													className="full-width-dragger bank-statement"
													name="bank_statement_proof"
													tooltip="The evidence needs to show the name the account is in and the bank account number."
												>
													<Upload.Dragger
														{...uploadPropsBankStatementDoc}
														beforeUpload={handleFileUploadChangeBankStatementDoc}
														customRequest={dummyRequestChangeBankStatementDoc}
														onDownload={onDownloadChangeBankStatementDoc}
														fileList={Object.keys(allFiles).length ? allFiles['bank_statement_proof'] ? allFiles['bank_statement_proof'] : [] : []}
														onRemove={() => updateAllFiles(prev => ({ ...prev, bank_statement_proof: [] }))}
													>
														<span className="ant-upload-drag-icon">
															<InboxOutlined style={{ fontSize: '16px' }} />
														</span>
														<span className="ant-upload-text">
															Drag Bank Statement Document
														</span>
													</Upload.Dragger>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													label="Cheque Document"
													className="full-width-dragger bank-statement"
													name="cheque_document"
													tooltip="A cheque is a financial document that orders a bank to pay a particular amount of money from a person's account to another individual's or company's account in whose name the cheque has been made or issued"
												>
													<Upload.Dragger
														{...uploadPropsChequeDoc}
														beforeUpload={handleFileUploadChangeChequeDoc}
														customRequest={dummyRequestChangeChequeDoc}
														onDownload={onDownloadChangeChequeDoc}
														fileList={Object.keys(allFiles).length ? allFiles['cheque_document'] ? allFiles['cheque_document'] : [] : []}
														onRemove={() => updateAllFiles(prev => ({ ...prev, cheque_document: [] }))}
														disabled={false}
													>
														<span className="ant-upload-drag-icon">
															<InboxOutlined style={{ fontSize: '16px' }} />
														</span>
														<span className="ant-upload-text">
															Drag Proof of Cheque Document
														</span>
													</Upload.Dragger>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item
													label="Personal Identity Record Form"
													className="full-width-dragger bank-statement"
													name="personal_identity_record"
													tooltip="Upload personal identity record form"
												>
													<Upload.Dragger
														{...uploadPropsPersonalIndentityRecord}
														beforeUpload={handleFileUploadChangePersonalIndentityRecord}
														customRequest={dummyRequestChangePersonalIndentityRecord}
														onDownload={onDownloadChangePersonalIndentityRecord}
														fileList={Object.keys(allFiles).length ? allFiles['personal_identity_record'] ? allFiles['personal_identity_record'] : [] : []}
														onRemove={() => updateAllFiles(prev => ({ ...prev, personal_identity_record: [] }))}
														disabled={false}
													>
														<span className="ant-upload-drag-icon">
															<InboxOutlined style={{ fontSize: '16px' }} />
														</span>
														<span className="ant-upload-text">
															Drag Proof of Personal Indentity Record Document
														</span>
													</Upload.Dragger>
												</Form.Item>
											</Col>
										</>
									}
								</>
								: null
						}
					</Row>
				</Col>
				<Col span={6}>
					<Form.Item
						name="logo_url" className="full-width-dragger logo-dragger"
						label='Logo only JPEG or PNG is allowed, Maximum size: 500px * 500px'
						tooltip="Logo is a symbol or other small design adopted by an organisation to identify its products."
					>
						<Upload
							accept=".png, .jpg, .jpeg"
							fileList={Object.keys(allFiles).length ? allFiles.logo_url ? allFiles.logo_url.length ? allFiles['logo_url'] : [] : [] : []}
							onRemove={onRemoveImage}
							className="hideeyeicon"
							listType="picture-card"
							multiple={false}
							showUploadList={true}
							{...uploadLogoProps}
						>
							{Object.keys(allFiles).length > 0 ? allFiles.logo_url ? allFiles.logo_url.length >= 1 ? null : <PlusOutlined /> : <PlusOutlined /> : <PlusOutlined />}
						</Upload>
					</Form.Item>
				</Col>

				{openFormType !== 'Investor' ?
					<>
						{openFormType !== 'Intermediary' ?
							<Col span={5} className="withExtraNote">
								<InputComponent
									type='select' label='Proof of Identity' name='id_proof' placeholder='Proof of Identity' onChange={handleChange}
									options={{ values: [] }}
									rules={vsmMember.validation.proof_identity} disabled
									tooltip='Select the type of document used to identify this  organisation.'
									note={<div /* onClick={openCredebtor} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '18px' }} /></div>}
								/>
							</Col>
							:
							<Col span={6}>
								<Form.Item
									label="Proof of Identity"
									className="full-width-dragger bank-statement"
									name="id_proof"
									tooltip="Upload Proof of Identity record form"
								>
									<Upload.Dragger
										{...uploadPropspProofIndentity}
										beforeUpload={handleFileUploadChangeProofIndentity}
										customRequest={dummyRequestChangeProofIndentity}
										onDownload={onDownloadChangeProofIndentity}
										fileList={Object.keys(allFiles).length ? allFiles['id_proof'] ? allFiles['id_proof'] : [] : []}
										onRemove={() => updateAllFiles(prev => ({ ...prev, id_proof: [] }))}
										disabled={false}
									>
										<span className="ant-upload-drag-icon">
											<InboxOutlined style={{ fontSize: '16px' }} />
										</span>
										<span className="ant-upload-text">
											Drag Proof of Proof of Identity
										</span>
									</Upload.Dragger>
								</Form.Item>
							</Col>
						}
						<Col span={5} className="withExtraNote">
							<InputComponent
								type='select' label='Proof of Address' name='proof_of_address' placeholder='Proof of Address' onChange={handleChange}
								options={{ values: [] }}
								tooltip="Select the type of document used to identify this  organisation’s registered office."
								rules={vsmMember.validation.proof_of_address} disabled
								note={<div /* onClick={openCredebtor} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '18px' }} /></div>}
							/>
						</Col>
					</>
					:
					<>
						<Col span={6}>
							<Form.Item
								label="Passport/Driving License Document"
								className="full-width-dragger bank-statement"
								name="passport_driving_license"
								tooltip="Passport is an official document issued by a government, certifying the holder's identity and citizenship and entitling them to travel under its protection to and from foreign countries. A driving licence is a card showing that you are qualified to drive because you have passed a driving test."
							>
								<Upload.Dragger
									{...uploadPropspassportDrivingLicense}
									beforeUpload={handleFileUploadChangePassportDrivingLicense}
									customRequest={dummyRequestChangePassportDrivingLicense}
									onDownload={onDownloadChangePassportDrivingLicense}
									fileList={Object.keys(allFiles).length ? allFiles['passport_driving_license'] ? allFiles['passport_driving_license'] : [] : []}
									onRemove={() => updateAllFiles(prev => ({ ...prev, passport_driving_license: [] }))}
									disabled={false}
								>
									<span className="ant-upload-drag-icon">
										<InboxOutlined style={{ fontSize: '16px' }} />
									</span>
									<span className="ant-upload-text">
										Drag Proof of Passport/Driving License
									</span>
								</Upload.Dragger>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Utility Bill/Bank Statement Document"
								className="full-width-dragger bank-statement"
								name="utility_document"
								tooltip="A utility bill/ Bank Statement is a monthly statement of the amount a household or business owes for essential services or utilities"
							>
								<Upload.Dragger
									{...uploadPropsBillBankStatementDoc}
									beforeUpload={handleFileUploadChangeBillBankStatementDoc}
									customRequest={dummyRequestChangeBillBankStatementDoc}
									onDownload={onDownloadChangeBillBankStatementDoc}
									fileList={Object.keys(allFiles).length ? allFiles['utility_document'] ? allFiles['utility_document'] : [] : []}
									onRemove={() => updateAllFiles(prev => ({ ...prev, utility_document: [] }))}
									disabled={false}
								>
									<span className="ant-upload-drag-icon">
										<InboxOutlined style={{ fontSize: '16px' }} />
									</span>
									<span className="ant-upload-text">
										Drag Proof of Utility Bill/Bank Statement Document
									</span>
								</Upload.Dragger>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								label="Signed RPA Agreement"
								className="full-width-dragger bank-statement"
								name="rpa_agreement"
								tooltip="A residential purchase agreement (RPA) is a basic document outlining the details of a real estate purchase between a buyer and a seller"
							>
								<Upload.Dragger
									{...uploadPropsSignedRPAAgreement}
									beforeUpload={handleFileUploadChangeSignedRPAAgreement}
									customRequest={dummyRequestChangeSignedRPAAgreement}
									onDownload={onDownloadChangeSignedRPAAgreement}
									fileList={Object.keys(allFiles).length ? allFiles['rpa_agreement'] ? allFiles['rpa_agreement'] : [] : []}
									onRemove={() => updateAllFiles(prev => ({ ...prev, rpa_agreement: [] }))}
									disabled={false}
								>
									<span className="ant-upload-drag-icon">
										<InboxOutlined style={{ fontSize: '16px' }} />
									</span>
									<span className="ant-upload-text">
										Drag Proof of Signed RPA Agreement
									</span>
								</Upload.Dragger>
							</Form.Item>
						</Col>
					</>
				}
				{openFormType === 'Originator' ?
					<Col span={4}>
						<InputComponent
							label='Documents' name='document' placeholder='Documents' onChange={handleChange} disabled
							tooltip='Hover over the document icon below to identify it or use the expand view icon to see all the documents.'
							rules={vsmMember.validation.document}
						/>
					</Col>
					: null}
				{openFormType !== 'Investor' ?
					<Col span={5} className="withExtraNote">
						<InputComponent
							label="Person's Name" name='person_name' placeholder="Person's Name" onChange={handleChange}
							type='select'
							options={{
								values: personList || [{
									id: viewMemberValue && viewMemberValue.credit_risk && viewMemberValue.credit_risk.person_name && viewMemberValue.credit_risk.person_name.id && viewMemberValue.credit_risk.person_name.id,
									full_name: viewMemberValue && viewMemberValue.credit_risk && viewMemberValue.credit_risk.person_name && viewMemberValue.credit_risk.person_name.id && viewMemberValue.credit_risk.person_name.full_name
								}],
								value_key: 'id',
								text_key: 'full_name'
							}}
							onFocus={() => pesronList ? getPersonList() : updatePesronList(false)}
							notFoundContent={pesronList ? <Spin size='small' /> : 'No Content Found'}
							tooltip='A name by which an individual is intimately known or designated and which may be displaced or supplemented by a surname, a cognomen, or a royal name'
							rules={vsmMember.validation.person_name}
							note={<div /* onClick={openPeopleDrawer} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '18px' }} /></div>}
							disabled={openFormType === 'Originator'}
						/>
					</Col>
					: null}
				<Col span={5}>
					<InputComponent
						label='Website' name='website' placeholder='Website' onChange={handleChange}
						tooltip='A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server.'
						rules={vsmMember.validation.website}
					/>
				</Col>
				<Col span={12}>
					<Form.Item label="Phone (To add extension use ext keyword above i.e :  +353 x xxx-xxxx, ext. xxxx)" tooltip={'Phone Type is the type, that would be associated with the Email(Eg.: Primary, home, work). Phone number can be stored with country code and extension numbers.'}>
						<PhoneElement onChange={handleChange} form={form} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label="Email" className="mb-10" tooltip={'a system for sending messages to one or more recipients via telecommunications links between computers using dedicated software'}>
						<EmailElement onChange={handleChange} form={form} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<InputComponent
						type='textarea' label='Description' name='description' placeholder='Description' onChange={handleChange}
						tooltip={"A statement, picture in words, or account that describes; descriptive representation"}
						maxLength={32000}
					/>
				</Col>
				{openFormType === 'Investor' &&
					<Col span={6}>
						<InputComponent
							label='Quoted Rate' name='quoted_rate' placeholder='Quoted Rate' onChange={handleChange} onBlur={(e) => percentagesNode(e, 'quoted_rate')}
							tooltip='A quoted rate is the most recent price at which an investment (or any other type of asset) has traded.'
							rules={vsmMember.validation.quoted_rate}
						/>
					</Col>
				}
				{openFormType === 'Originator' ?
					<>
						<Col span={8}>
							<InputComponent
								label='Relationships' name='relationship' placeholder='Relationships' onChange={handleChange}
								tooltip='Relationships'
								rules={vsmMember.validation.relationship} disabled
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								type='date' label='Formation Year' name='formation_year' placeholder='Formation Year' onChange={handleChange}
								tooltip={"Financial Year Start Month is the starting month from which the Organisation will report its fiscal operations within a period of 12 months."}
								picker="year" disabledDate={disabledFormationYear}
								rules={vsmMember.validation.formation_year}
							/>
						</Col>
					</>
					: null}
				<Col span={24}>
					<AddressElement onChange={handleChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} />
				</Col>
			</Row>
			<AddComponent visible={peopleDrawer} close={closeAddDrawer} />
		</Form>
	)
})

export default UserFormComponent