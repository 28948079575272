import { DateComparator } from "../../../../../utils/GlobalFunction"

export const ActionRenderer = (props) => {
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left'
	}, {
		headerName: 'Date',
		field: 'fx_rate_date',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Currency Code',
		field: 'currency_code.currency_code',
		filter: 'agSetColumnFilter',
	}, {
		headerName: 'Rate',
		field: 'day_value',
	}]
}
