import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form, Row, Col, Spin, Tooltip, Typography } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { useForm } from 'antd/lib/form/Form'
import useStore from '../../../../store'
import { vsmCTEImport, vsmNotify } from '../../../../config/messages'
import moment from 'moment'
import { InfoCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';

const VerifyComponent = observer((props) => {
	const [form] = useForm()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(false)
	const [formDataList, setFormDataList] = useState([])
	const { SavedReportStore, CTEImportStore, ClassificationStore, RoleMappingStore, LedgerDetailStore, CashTransactionStore } = useStore()
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchBankAccount, setFetchBankAccount] = useState(true)
	const [fetchTransactionTypes, setFetchTransactionTypes] = useState(true)
	const [fetchClassification, setFetchClassification] = useState(true)
	const [problematic, setProblematic] = useState()
	const [autoIdentified, setAutoIdentified] = useState()
	const [perviousID, setPerviousID] = useState()
	const [nextID, setNextID] = useState()
	const [rejectedMode, setRejectedMode] = useState(null)
	const [verifiedData, setVerifiedData] = useState()
	const [pendingCount, setPendingCount] = useState(null)
	const [fetchTag, setFetchTag] = useState(true)
	const [currentId, setCurrentId] = useState(null)
	const [titleId, setTitaleId] = useState(null)
	const [fetchOrgRole, setFetchOrgRole] = useState(true)
	const [selectedRoleID, setSelectedRoleID] = useState()

	//set organization switch case
	const getFormDataList = useCallback(() => {
		var FormDataList = []
		switch (form.getFieldValue('entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'main_people_role', 'main_people']
				break;
			default:
				FormDataList = [...FormDataList, 'role_id', 'entity_id']
		}

		switch (form.getFieldValue('related_entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'related_people_role', 'related_people']
				break;
			default:
				FormDataList = [...FormDataList, 'related_role_id', 'related_entity_id']
		}
		return FormDataList
	}, [form])

	//close drawer for verify component
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setVerifiedData()
		setProblematic()
		setAutoIdentified()
		setPerviousID()
		setNextID()
		setFetchClassification(true)
		setFetchTransactionTypes(true)
		setFetchBankAccount(true)
		setFetchCurrency(true)
		setFetchTag(true)
		setFetchOrgRole(true)
		setSelectedRoleID()
	}

	//hadle submit to save data
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = currentId
		data.posted_on = data.posted_on ? moment(data.posted_on).format('YYYY-MM-DD') : null
		data.value_on = data.value_on ? moment(data.value_on).format('YYYY-MM-DD') : null
		CTEImportStore.SaveVerifyData(data).then((data) => {
			if (!autoIdentified && currentId === problematic) {
				setRejectedMode([])
				setAutoIdentified(currentId)
			}
			vsmNotify.success({
				message: data.message
			})
			var verifying_mode = form.getFieldValue('verifying_mode')
			form.resetFields()
			form.setFieldsValue({ verifying_mode })
			if (nextID) {
				setProblematic(nextID)
				verifyAPICall(verifying_mode, nextID)
			} else {
				close()
				if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	//API call to get the data of organization
	const getOrgRelatedData = useCallback((data = {}) => {
		let main_entity = form.getFieldValue('entity_type') === 1 ? "organisation" : "people"
		let related_entity = form.getFieldValue('related_entity_type') === 1 ? "organisation" : "people"
		let payload = { main_entity, related_entity }
		if (main_entity === 'people') {
			let main_people_role = form.getFieldValue('role_id')
			main_people_role = (main_people_role) ? [main_people_role] : null
			let main_people = form.getFieldValue('entity_id')
			main_people = (main_people) ? [main_people] : null
			payload = { ...payload, main_people_role, main_people }
		} else {
			let main_organisation_role = form.getFieldValue('role_id')
			main_organisation_role = (main_organisation_role) ? [main_organisation_role] : null
			let main_organisation = form.getFieldValue('entity_id')
			main_organisation = (main_organisation) ? [main_organisation] : null
			payload = { ...payload, main_organisation_role, main_organisation }
		}
		if (related_entity === 'people') {
			let related_people_role = form.getFieldValue('related_role_id')
			related_people_role = (related_people_role) ? [related_people_role] : null
			payload = { ...payload, related_people_role }
		} else {
			let related_organisation_role = form.getFieldValue('related_role_id')
			related_organisation_role = (related_organisation_role) ? [related_organisation_role] : null
			payload = { ...payload, related_organisation_role }
		}
		payload = { ...payload, ...data }
		SavedReportStore.getDependentList(payload)
	}, [form, SavedReportStore])

	//set switch case of org/people
	useEffect(() => {
		if (props.visible) {
			var data = getFormDataList()
			if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
				setFormDataList(data)
			}
		}
	}, [getFormDataList, setFormDataList, formDataList, props.visible])

	//API call for read
	const verifyAPICall = useCallback((type, id) => {
		setLoading(true)
		setDisabled(true)
		setCurrentId(id)
		CTEImportStore.getVerifyRecordById(type, id).then(async (data) => {
			setLoading(false)
			await setVerifiedData(data)
			setPerviousID(data.previous)
			setNextID(data.next)
			setPendingCount(data.pending)
			setSelectedRoleID(data.role_id)
			form.setFieldsValue({
				bank_account_id: data.bank_account_id,
				entity_type: data.entity_type ? data.entity_type : 1,
				entity_id: data.entity_id,
				role_id: data.role_id,
				related_entity_type: data.related_entity_type ? data.related_entity_type : 1,
				related_entity_id: data.related_entity_id,
				related_role_id: data.related_role_id,
				cte_transaction_classification_id: data.cte_transaction_classification_id,
				cte_transactions_type_id: data.cte_transactions_type_id,
				transaction_details: data.transaction_details,
				face_value: data.credit ? "credit" : "debit",
				amount: data.credit ? data.credit : data.debit,
				currency_id: data.currency_id,
				posted_on: data.posted_on ? moment(data.posted_on) : null,
				value_on: data.value_on ? moment(data.value_on) : null,
				balance: data.balance,
				exchange_ref_id: data.exchange_ref_id && data.exchange_ref_id.toString(),
				customer_reference: data.customer_reference,
				bank_reference: data.bank_reference,
			})
			setFormDataList(getFormDataList())
			SavedReportStore.main_organisation = null
			SavedReportStore.main_people = null
			SavedReportStore.related_organisation_role = null
			SavedReportStore.related_organisation = null
			SavedReportStore.related_people_role = null
			SavedReportStore.related_people = null
		})
		setTitaleId(id)
	}, [CTEImportStore, form, setFormDataList, getFormDataList, SavedReportStore])

	//set id to get verify data
	useEffect(() => {
		if (props.visible) {
			var id = null
			var mode_type = '4,5'

			if (CTEImportStore.verifyValues.data_from === 'inner_list') {
				mode_type = [4, 5].includes(CTEImportStore.verifyValues.status) ? '4,5' : CTEImportStore.verifyValues.status
				id = CTEImportStore.verifyValues.id
				if ([4, 5].includes(CTEImportStore.verifyValues.status)) {
					setProblematic(CTEImportStore.verifyValues.id)
					setAutoIdentified(props.autoIdentified_id && props.autoIdentified_id.id)

					if (!props.autoIdentified_id) {
						setRejectedMode([2])
					}

				} else {
					setProblematic(props.problematic_id && props.problematic_id.id)
					setAutoIdentified(CTEImportStore.verifyValues.id)

					if (!props.problematic_id) {
						setRejectedMode(['4,5'])
					}
				}
			} else {
				if (CTEImportStore.verifyValues) {
					if (CTEImportStore.verifyValues.problematic_tr_id) {
						id = CTEImportStore.verifyValues.problematic_tr_id
					} else {
						id = CTEImportStore.verifyValues.autoidentified_tr_id
					}
					setTitaleId(id)
					setProblematic(CTEImportStore.verifyValues.problematic_tr_id && CTEImportStore.verifyValues.problematic_tr_id)
					setAutoIdentified(CTEImportStore.verifyValues.autoidentified_tr_id && CTEImportStore.verifyValues.autoidentified_tr_id)

					mode_type = CTEImportStore.verifyValues.problematic_tr_id ? '4,5' : (CTEImportStore.verifyValues.autoidentified_tr_id ? 2 : 0)
					var temp = []
					if (!CTEImportStore.verifyValues.problematic_tr_id) { temp.push('4,5') }
					if (!CTEImportStore.verifyValues.autoidentified_tr_id) { temp.push(2) }
					setRejectedMode(temp)
				}
			}
			form.setFieldsValue({ verifying_mode: mode_type })
			if (id) {
				verifyAPICall(mode_type, id)
			} else {
				vsmNotify.error({ message: 'Id not found' })
			}
		}
	}, [props, CTEImportStore, form, verifyAPICall])

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		setSelectedRoleID(form.getFieldValue("role_id"))
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const handleVerifyingMode = (e) => {
		form.resetFields(['tag'])
		form.setFieldsValue({ verifying_mode: e.target.value })
		var payload = {
			type: e.target.value,
		}
		var id = e.target.value === '4,5' ? problematic : (e.target.value === 2 ? autoIdentified : (
			(problematic && autoIdentified) ? (problematic <= autoIdentified ? problematic : autoIdentified) : (
				!problematic ? autoIdentified : problematic
			)
		))
		verifyAPICall(payload, id)
	}

	//API call to get previous transaction
	const perviousVerify = debounce(() => {
		verifyAPICall(form.getFieldValue('verifying_mode'), perviousID)
	}, 500)

	//API call to get next transaction
	const nextVerify = debounce(() => {
		verifyAPICall(form.getFieldValue('verifying_mode'), nextID)
	}, 500)

	// api call on tag change
	const handleTagChange = () => {
		var values = form.getFieldsValue(["tag", "entity_type", "related_entity_type"])
		if (values.tag) {
			SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation")
			var payload = {
				"tag_id": values.tag,
				"entity_type": values.entity_type,
				"related_entity_type": values.related_entity_type
			}
			CashTransactionStore.getTagDetail(payload).then(async data => {
				if (data) {
					setSelectedRoleID(data.roles.id)
					form.setFieldsValue({
						entity_id: data.entity.id, role_id: data.roles.id,
						related_entity_id: data.related_entity.id, related_role_id: data.related_roles.id
					})
					getOrgRelatedData()
					handleChange()
				}
			})
		} else {
			handleChange()
		}
	}

	const handleSuggestion = (item, list, apiCall, dependent, DDArray = null, isRequired = 'not_required') => {
		if (verifiedData && verifiedData.identification_json) {
			let data;
			if (dependent) {
				data = verifiedData.identification_json[dependent]
			} else {
				data = verifiedData.identification_json[item]
			}
			if (data && data.length > 0) {
				data.forEach((item) => (Object.keys(item).forEach((key) => {
					if (key !== "id" && typeof (item[key]) !== 'object') { item.name = item[key] }
				})))
				return (
					<div>
						{data.map((val, index) => {
							var arr = [{ key: item, value: val.id }]
							var object = arr.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
							if ((isRequired === 'not_required' && !DDArray) || (DDArray && DDArray.find(x => x.id === val.id))) {
								return (
									<>
										{index === 0 && <b>Suggestions :</b>}
										<div key={index}>
											<Typography.Link onClick={async () => {
												if (!list || list.length === 0) { await apiCall() }
												await form.setFieldsValue(object)
												handleChange()
											}}>{val.name}</Typography.Link>
											<Tooltip title={val.name}>
												<InfoCircleOutlined className="ml-5" />
											</Tooltip>
										</div>
									</>
								)
							} else {
								return null
							}
						})}
					</div>
				)
			}
		}
		return null
	}

	return CTEImportStore.verifyValues && (
		<Drawer
			visible={props.visible}
			onClose={close}
			title={`Verifying Mode #${titleId} ${CTEImportStore.verifyValues.import_name ? " - " + CTEImportStore.verifyValues.import_name : (props.import_name ? " - " + props.import_name : "")}`}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div className="text-center" key='1'>
					<Button loading={saving} form='previewForm' className="mr-10" htmlType="submit" type="primary" disabled={isDisabled || loading}>Save and Go to Next</Button>
					<Button disabled={saving || loading || !perviousID} className="mr-10" onClick={perviousVerify}> Previous</Button>
					<Button disabled={saving || loading || !nextID} onClick={nextVerify}> Next({pendingCount})</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={saving || loading}>
				{(props.visible) && (
					<Form
						id='previewForm'
						form={form}
						layout='vertical'
						onFinish={handleSubmit}
						initialValues={CTEImportStore.initialValues}
					>
						<Row>
							<Col>
								<InputComponent
									type="radio_button" name="verifying_mode"
									options={{
										values: [{ value: 0, text: 'All' }, { value: '4,5', text: 'Problematic' }, { value: 2, text: 'Identified' }],
										rejected_keys: rejectedMode
									}}
									onChange={handleVerifyingMode}
								/>
							</Col>
						</Row>
						<InputComponent
							required
							label='Transaction Details'
							placeholder="Transaction Details"
							name='transaction_details'
							onChange={handleChange}
							tooltip="Transaction Details is details given by bank in bank statement containing important information about transaction."
							autoComplete="off"
							rules={vsmCTEImport.validation.transaction_details}
						/>

						{/* Face Value */}
						<Row gutter={24}>
							<Col span={12}>
								<div className="ant-form-item-label">
									<label className="ant-form-item-required">Face Value</label>
								</div>

								<Row gutter={24} className="no-wrap">
									<Col flex={'158px'}>
										<InputComponent
											required type="radio_button" name="face_value"
											onChange={handleChange}
											options={{
												values: [
													{ value: 'debit', text: 'Debit' },
													{ value: 'credit', text: 'Credit' },
												]
											}}
										/></Col>
									<Col flex={1}>
										<InputComponent
											name="amount" onChange={handleChange} placeholder="Enter Amount"
											tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
											rules={vsmCTEImport.validation.amount}
											note="If there is only one column with Plus and Minus sign, Enter only Credit column name"
										/>
									</Col>
								</Row>
							</Col>

							<Col span={12}>
								<InputComponent
									label="Balance" name="balance" placeholder="Balance" onChange={handleChange}
									tooltip="Balance per bank is the value of the initial state of the cash account. Just for Reference purpose."
									rules={vsmCTEImport.validation.balance}
									autoComplete="off"
								/>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									className='w-100'
									required type="date" name="posted_on" label="Posted Date" onChange={handleChange}
									rules={vsmCTEImport.validation.posted_date}
									tooltip='The posted date is the day, month, and year when a transaction takes place. It is the date on which funds are taken or added to an account.'
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									className='w-100'
									required type="date" name="value_on" label="Value Date" onChange={handleChange}
									tooltip='The value date is the date when the payment is actually paid/received by the customer. If not, the value date by default remains the same as the posted date.'
									note="If Value Date field is Blank then it will be same as Posted Date field."
								/>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									label="Exchange Ref ID" name="exchange_ref_id" placeholder="Exchange Ref ID"
									tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
									autoComplete="off"
									onChange={handleChange}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									label="Customer Reference" name="customer_reference" placeholder="Customer Reference"
									tooltip="Customer Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
									autoComplete="off"
									onChange={handleChange}
								/>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									label="Bank Reference" name="bank_reference" placeholder="Bank Reference"
									tooltip="Bank Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
									autoComplete="off"
									onChange={handleChange}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									required placeholder="Select Currency"
									label='Currency'
									type="select"
									onChange={handleChange}
									onFocus={() => fetchCurrency && LedgerDetailStore.getAllCurrencyList().then(() => setFetchCurrency(false))}
									notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
									allowClear
									options={{
										values: LedgerDetailStore.currency_list ? LedgerDetailStore.currency_list : [{
											id: verifiedData && verifiedData.global_currency && verifiedData.global_currency.id,
											currency_name: verifiedData && verifiedData.global_currency && verifiedData.global_currency.currency_name,
										}],
										value_key: 'id',
										text_key: 'currency_name',
									}}
									name='currency_id'
									tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
									autoComplete="off"
									rules={vsmCTEImport.validation.currency}
								/>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									required placeholder="Select Bank Account"
									label='Bank Account'
									onChange={handleChange}
									type="select"
									allowClear
									onFocus={() => fetchBankAccount && CTEImportStore.getBankList().then(() => setFetchBankAccount(false))}
									notFoundContent={fetchBankAccount ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: CTEImportStore.bank_list ? CTEImportStore.bank_list : [{
											id: verifiedData && verifiedData.ledgers_bank && verifiedData.ledgers_bank.laravel_through_key,
											bankAccount_names: verifiedData && verifiedData.ledgers_bank && (
												`${verifiedData.ledgers_bank.name} - ${verifiedData.ledgers_bank.account_number ? verifiedData.ledgers_bank.account_number : (
													verifiedData.ledgers_bank.iban ? verifiedData.ledgers_bank.iban : null
												)}`
											),
										}],
										text_key: "bankAccount_names",
										value_key: "id",
									}}
									name='bank_account_id'
									rules={vsmCTEImport.validation.bank_account}
									tooltip="A name or code given to a cash account that indicates the account's purpose."
									note={handleSuggestion("bank_account_id", CTEImportStore.bank_list, CTEImportStore.getBankList, 'bank_account')}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									type="select" allowClear label="Tag" name="tag" placeholder="Select or Click on Suggestion"
									tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
									onChange={handleTagChange}
									onFocus={() => fetchTag && RoleMappingStore.getAllTagsList().then(() => setFetchTag(false))}
									notFoundContent={fetchTag ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: RoleMappingStore.dropdown_tags_list,
										value_key: 'id',
										text_key: 'tag_name',
									}}
									note={handleSuggestion("tag", RoleMappingStore.dropdown_tags_list, RoleMappingStore.getAllTagsList)}
								/>
							</Col>
						</Row>

						{/* Entity Type */}
						<Row gutter={24}>
							<Col span={24}>
								<Row gutter={24}>
									<Col>
										<InputComponent
											type="radio_button" label="Entity Type" name="entity_type"
											onChange={() => {
												getOrgRelatedData()
												setFetchOrgRole(true)
												form.resetFields(['role_id', 'entity_id', 'main_people_role', 'main_people', 'related_role_id', 'related_entity_id', 'related_people_role', 'related_people'])
												handleChange()
											}}
											options={{
												values: [
													{ value: 1, text: 'Organisation' },
													{ value: 2, text: 'People' },
												]
											}}
										/></Col>
									<Col flex={1}>
										<Row gutter={24}>
											{(formDataList.includes('role_id')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="Org. Role" name="role_id" placeholder="Select or Click on Suggestion"
														tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
														rules={vsmCTEImport.validation.main_organisation_role}
														onChange={() => {
															getOrgRelatedData()
															form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
															handleChange()
														}}
														onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
														notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
														options={{
															values: SavedReportStore.main_organisation_roles || (
																verifiedData && [{
																	id: verifiedData.organisation_role && verifiedData.organisation_role.id,
																	role_name: verifiedData.organisation_role && verifiedData.organisation_role.role_name,
																}]
															),
															value_key: 'id',
															text_key: 'role_name',
														}}
														note={handleSuggestion("role_id", null, () => {
															SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation")
															setFetchOrgRole(false)
															getOrgRelatedData()
															form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
															handleChange()
														}, 'organisation_role_id')}
													/>
												</Col>
											)}

											{(formDataList.includes('entity_id')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="Organisation" name="entity_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Org. Role first"}
														disabled={!selectedRoleID}
														tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
														rules={vsmCTEImport.validation.main_organisation}
														onChange={() => {
															getOrgRelatedData()
															form.resetFields(['related_role_id', 'related_entity_id'])
															handleChange()
														}}
														options={{
															values: SavedReportStore.main_organisation || (
																verifiedData && [{
																	organisation_id: verifiedData.organisations && verifiedData.organisations.id,
																	organisation_name: verifiedData.organisations && verifiedData.organisations.organisation_name,
																}]
															),
															value_key: 'organisation_id',
															text_key: 'organisation_name',
															rejected_keys: [form.getFieldValue('related_entity_id')]
														}}
														note={handleSuggestion("entity_id", null, () => {
															getOrgRelatedData()
															form.resetFields(['related_role_id', 'related_entity_id'])
															handleChange()
														}, 'organisation_entity_id', SavedReportStore.main_organisation, 'required')}
													/>
												</Col>
											)}

											{(formDataList.includes('main_people_role')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="People Role" name="role_id"
														placeholder="Select or Click on Suggestion"
														tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
														rules={vsmCTEImport.validation.main_people_role}
														onChange={(data) => {
															getOrgRelatedData()
															setSelectedRoleID(data)
															form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
															handleChange()
														}}
														onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
														notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
														options={{
															values: SavedReportStore.main_people_roles || (
																verifiedData && [{
																	id: verifiedData.people_role && verifiedData.people_role.id,
																	role_name: verifiedData.people_role && verifiedData.people_role.role_name,
																}]
															),
															value_key: "id",
															text_key: "role_name",
														}}
														note={handleSuggestion("role_id", null, () => {
															SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation")
															setFetchOrgRole(false)
															getOrgRelatedData()
															form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
															handleChange()
														}, 'people_role_id')}
													/>
												</Col>
											)}

											{(formDataList.includes('main_people')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="People" name="entity_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Ppl. Role first"}
														tooltip="People is an entity (for example a Person) that has (or had in the past) – directly or indirectly - any business relationship with Credebt Exchange or with another Organisation"
														rules={vsmCTEImport.validation.main_people} disabled={!selectedRoleID}
														onChange={() => {
															getOrgRelatedData()
															form.resetFields(['related_role_id', 'related_entity_id'])
															handleChange()
														}}
														options={{
															values: SavedReportStore.main_people || (
																verifiedData && [{
																	people_id: verifiedData.peoples && verifiedData.peoples.id,
																	people_name: verifiedData.peoples && verifiedData.peoples.first_name + (verifiedData.peoples.last_name ? (" " + verifiedData.peoples.last_name) : ""),
																}]
															),
															value_key: "people_id",
															text_key: "people_name",
														}}
														note={handleSuggestion("entity_id", null, () => {
															getOrgRelatedData()
															form.resetFields(['related_role_id', 'related_entity_id'])
															handleChange()
														}, 'people_entity_id', SavedReportStore.main_people, 'required')}
													/>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>

						{/* Related Entity Type */}
						<Row gutter={24}>
							<Col span={24}>
								<Row gutter={24}>
									<Col>
										<InputComponent
											type="radio_button" label="Related Entity Type" name="related_entity_type"
											onChange={() => {
												getOrgRelatedData()
												form.resetFields(['related_role_id', 'related_entity_id'])
												handleChange()
											}}
											options={{
												values: [
													{ value: 1, text: 'Organisation' },
													{ value: 2, text: 'People' },
												]
											}}
										/></Col>
									<Col flex={1}>
										<Row gutter={24}>
											{(formDataList.includes('related_role_id')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="Rel. Org. Role" name="related_role_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Org. Role first"}
														disabled={!selectedRoleID}
														tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
														rules={vsmCTEImport.validation.related_organisation_role}
														onChange={() => {
															getOrgRelatedData()
															form.resetFields(['related_entity_id'])
															handleChange()
														}}
														options={{
															values: SavedReportStore.related_organisation_role || (
																verifiedData && [{
																	id: verifiedData.related_organisation_role && verifiedData.related_organisation_role.id,
																	role_name: verifiedData.related_organisation_role && verifiedData.related_organisation_role.role_name
																}]),
															value_key: 'id',
															text_key: 'role_name',
														}}
														note={handleSuggestion("related_role_id", null, () => {
															getOrgRelatedData()
															form.resetFields(['related_entity_id'])
															handleChange()
														}, 'organisation_related_role_id', SavedReportStore.related_organisation_role, 'required')}
													/>
												</Col>
											)}

											{(formDataList.includes('related_entity_id')) && (
												<Col span={12}>
													< InputComponent
														required allowClear type="select" label="Rel. Organisation" name="related_entity_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Org. Role first"}
														disabled={!selectedRoleID}
														tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
														rules={vsmCTEImport.validation.related_organisation}
														onChange={handleChange}
														options={{
															values: SavedReportStore.related_organisation || (
																verifiedData && [{
																	organisation_id: verifiedData.related_organisations && verifiedData.related_organisations.id,

																	organisation_name: verifiedData.related_organisations && verifiedData.related_organisations.organisation_name,
																}]
															),
															value_key: 'organisation_id',
															text_key: 'organisation_name',
															rejected_keys: [form.getFieldValue('entity_id')]
														}}
														note={handleSuggestion("related_entity_id", null, handleChange, 'organisation_related_entity_id', SavedReportStore.related_organisation, 'required')}
													/>
												</Col>
											)}

											{(formDataList.includes('related_people_role')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="Rel. People Role" name="related_role_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Org. Role first"}
														tooltip="People Role Relation is the connection between the Roles played by two different People."
														rules={vsmCTEImport.validation.related_people_role} disabled={!selectedRoleID}
														onChange={() => {
															getOrgRelatedData()
															form.resetFields(['related_entity_id'])
															handleChange()
														}}
														options={{
															values: SavedReportStore.related_people_role || (
																verifiedData && [{
																	id: verifiedData.related_people_role && verifiedData.related_people_role.id,
																	role_name: verifiedData.related_people_role && verifiedData.related_people_role.role_name,
																}]
															),
															value_key: "id",
															text_key: "role_name",
														}}
														note={handleSuggestion("related_role_id", null, () => {
															getOrgRelatedData()
															form.resetFields(['related_entity_id'])
															handleChange()
														}, 'people_related_role_id', SavedReportStore.related_people_role, 'required')}
													/>
												</Col>
											)}

											{(formDataList.includes('related_people')) && (
												<Col span={12}>
													<InputComponent
														required allowClear type="select" label="Rel. People" name="related_entity_id"
														placeholder={selectedRoleID ? "Select or Click on Suggestion" : "Please select Org. Role first"}
														tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
														rules={vsmCTEImport.validation.related_people} disabled={!selectedRoleID}
														onChange={handleChange}
														options={{
															values: SavedReportStore.related_people || (
																verifiedData && [{
																	people_id: verifiedData.related_peoples && verifiedData.related_peoples.id,
																	people_name: verifiedData.related_peoples && verifiedData.related_peoples.first_name + (verifiedData.related_peoples.last_name ? (" " + verifiedData.related_peoples.last_name) : ""),
																}]
															),
															value_key: "people_id",
															text_key: "people_name",
														}}
														note={handleSuggestion("related_entity_id", null, handleChange, 'people_related_entity_id', SavedReportStore.related_people, 'required')}
													/>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									required
									placeholder="Select or Click on Suggestion"
									label='Cash Transaction Classification'
									type="select"
									onChange={handleChange}
									allowClear
									onFocus={() => fetchClassification && ClassificationStore.getClassfications().then(() => setFetchClassification(false))}
									notFoundContent={fetchClassification ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: ClassificationStore.classificationList ? ClassificationStore.classificationList : (verifiedData && verifiedData.cte_transaction_classifications && [
											{ id: verifiedData.cte_transaction_classifications.id, transaction_class_name: verifiedData.cte_transaction_classifications.transaction_class_name }
										]),
										value_key: 'id',
										text_key: 'transaction_class_name',
									}}
									name='cte_transaction_classification_id'
									tooltip="Classifications are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
									autoComplete="off"
									rules={vsmCTEImport.validation.transaction_classification}
									note={handleSuggestion("cte_transaction_classification_id", ClassificationStore.classificationList, ClassificationStore.getClassfications)}
								/>
							</Col>
							<Col span={12}>
								<InputComponent
									required placeholder="Select or Click on Suggestion"
									label='Transaction Type'
									type="select"
									onChange={handleChange}
									allowClear
									onFocus={() => fetchTransactionTypes && ClassificationStore.getCteTypes().then(() => setFetchTransactionTypes(false))}
									notFoundContent={fetchTransactionTypes ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: ClassificationStore.cteTypesList ? ClassificationStore.cteTypesList : (verifiedData && verifiedData.cash_transactions_types && [
											{ id: verifiedData.cash_transactions_types.id, type_name: verifiedData.cash_transactions_types.type_name }
										]),
										value_key: 'id',
										text_key: 'type_name',
									}}
									name='cte_transactions_type_id'
									tooltip="Cash Transaction Type indicates the type of cash transaction as defined by the bank. The parameter can be obtained - if available - from the Transaction Type or Transaction Details, i.e. Direct Debit, Transfer, etc.."
									autoComplete="off"
									rules={vsmCTEImport.validation.transaction_type}
								/>
							</Col>
						</Row>
					</Form>
				)}
			</Spin>
		</Drawer>
	)
})

export default VerifyComponent
