import React from 'react'
import { AutoComplete, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, TimePicker, Tooltip } from 'antd'
import {
	InfoCircleOutlined
} from '@ant-design/icons';

const InputComponent = ({ hint, hidden, rowSize, label, required, rules, name, tooltip, type, options, initialValue, extra, note, noStyle = false, valuePropName, ...rest }) => {
	switch (type) {
		case 'checkbox':
			return (
				<Form.Item tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue} valuePropName={valuePropName}>
						<Checkbox {...rest}>{label}</Checkbox>
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'password':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<Input.Password {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'time':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<TimePicker {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'date':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<DatePicker {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'date_range':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<DatePicker.RangePicker {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'number':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} onChange={rest.onChange} initialValue={initialValue}>
						<InputNumber {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'textarea':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<Input.TextArea rows={rowSize ? rowSize : 3} {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'select':
			if (options) {
				return (
					<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden} noStyle={noStyle}>
						<Form.Item name={name} noStyle rules={rules} initialValue={initialValue} >
							<Select
								className={options.className}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								{...rest}
							>
								{options.values && (
									options.values.map((item, index) => {
										if (item) {
											if ((options.accepted_key && options.accepted_key.includes(options.value_key ? item[options.value_key] : item.value)) || (options.rejected_keys && !options.rejected_keys.includes(options.value_key ? item[options.value_key] : item.value)) || !options.rejected_keys) {
												var text = item.text
												if (options.text_key) {
													if (typeof (options.text_key) === 'object') {
														text = ""
														options.text_key.key.forEach((key, index) => {
															if (key.includes("|")) {
																key.split("|").forEach(x => {
																	if (item[x]) {
																		text += item[x]
																		return false
																	}
																})
															} else {
																text += (item[key]) ? item[key] : key
															}
														})
													} else {
														text = item[options.text_key]
													}
												}
												return (
													<Select.Option
														key={index}
														value={options.value_key ? item[options.value_key] : item.value}
													>{text}</Select.Option>
												)
											}
										}
										return null
									})
								)}
							</Select>
						</Form.Item>
						{extra}
						{hint && (
							<Tooltip placement="right" title={tooltip}>
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						)}
					</Form.Item>
				)
			}
			return null

		case 'autocomplete':
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue} >
						<AutoComplete
							options={options}
							filterOption={(inputValue, option) =>
								option.value && option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
							}
							{...rest}
						/>
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)

		case 'radio_button':
			if (options) {
				return (
					<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
						<Form.Item name={name} noStyle rules={rules} initialValue={initialValue} >
							<Radio.Group buttonStyle="solid" {...rest} className={options.className}>
								{options.values && (
									options.values.map((item, index) => {
										if (item) {
											if ((options.accepted_key && options.accepted_key.includes(options.value_key ? item[options.value_key] : item.value)) || (options.rejected_keys && !options.rejected_keys.includes(options.value_key ? item[options.value_key] : item.value)) || !options.rejected_keys) {
												if (options && options.type === 'radio') {
													return (
														<Radio
															style={{ flex: 1, textAlign: 'center' }}
															key={index}
															value={options.value_key ? item[options.value_key] : item.value}
														>{options.text_key ? item[options.text_key] : item.text}</Radio>
													)
												} else {
													return (
														<Radio.Button
															style={{ flex: 1, textAlign: 'center' }}
															key={index}
															value={options.value_key ? item[options.value_key] : item.value}
														>{options.text_key ? item[options.text_key] : item.text}</Radio.Button>
													)
												}

											}
										}
										return null
									})
								)}
							</Radio.Group>
						</Form.Item>
						{hint && (
							<Tooltip placement="right" title={tooltip}>
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						)}
					</Form.Item>
				)
			}
			return null

		default:
			return (
				<Form.Item label={label} tooltip={tooltip} extra={note} required={required ? true : false} hidden={hidden}>
					<Form.Item name={name} noStyle rules={rules} initialValue={initialValue}>
						<Input {...rest} />
					</Form.Item>
					{hint && (
						<Tooltip placement="right" title={hint}>
							<InfoCircleOutlined className="ml-10" />
						</Tooltip>
					)}
				</Form.Item>
			)
	}

}

export default InputComponent
