import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer } from 'antd'
import useStore from '../../../../store'
import PreviewDetailTableElement from '../elements/PreviewDetailTableElement'

const PreviewDetailComponent = observer((props) => {
	const { SavedReportStore } = useStore()
	useEffect(() => {
		var payload = SavedReportStore.formatFormData({
			...SavedReportStore.previewReportValues,
			...props.payload,
			action: 'child_data'
		})
		SavedReportStore.PreviewDetailPayload = { ...payload, ...props.payload }
	}, [SavedReportStore, props.payload])
	return (
		<Drawer
			onClose={props.close}
			title={props.title}
			destroyOnClose={true}
			visible={props.visible}
			onCancel={props.close}
			width="75%"
		>
			<div className="d-flex justify-content-end mb-20">
				<Button
					type={SavedReportStore.ZeroRecord ? 'default' : 'primary'}
					onClick={() => {
						SavedReportStore.ZeroRecord = !SavedReportStore.ZeroRecord
						SavedReportStore.setupPreviewDetailGrid(SavedReportStore.agPreviewDetailGrid)
					}}
				>View Non Zero Postings</Button>
			</div>
			<PreviewDetailTableElement PreviewDetailPinnedRow={SavedReportStore.PreviewDetailPinnedRow} onGridReady={SavedReportStore.setupPreviewDetailGrid} />
		</Drawer>
	)
})

export default PreviewDetailComponent
