import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Spin, Drawer } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import moment from 'moment'
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'

const ViewComponent = observer((props) => {
	const [form] = useForm()
	const [loading, setLoading] = useState(true)
	const [viewValues, setViewValues] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [entityType, setEntityType] = useState(null)
	const [relatedTo, setRelatedTo] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const { OrgBankAccountStore, OrganisationStore, TransactionModelStore, AllTransactionStore, AllTransactionStore: {
		getRecordById, getCsvModals, setStatus, setVatRate, setMappingList, setItemsTable,
		setDependent } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore,
		modal: TransactionModelStore
	}

	const handleApiCall = useCallback(() => {
		setLoading(true)
		setItemsTable(null)
		getRecordById({ id: props.transactionId }).then((data) => {
			setViewValues(data.data)
			setDependent(data.data)
			setMappingList([data.data.type_field_mapping])
			setStatus([data.data.status])
			setVatRate([data.data.tax_setting])
			setLoading(false)
		}).catch((data) => {
			vsmNotify.error({ message: vsmAllTransactions.InvalidRecord })
			setLoading(false)
		})
	}, [props.transactionId, setItemsTable, getRecordById, setDependent, setMappingList, setStatus, setVatRate])

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			handleApiCall()
		}
	}, [props.visible, handleApiCall])

	// Set the form values to edit
	useEffect(() => {
		if (viewValues && props.visible) {
			getCsvModals(viewValues.type_field_mapping_id, "view", viewValues.selected_models)
			store.bank.setCurrency([viewValues.global_currency])
			form.setFieldsValue({
				role_id: viewValues.role_id,
				related_role_id: viewValues.related_role_id,
				entity_id: viewValues.entity_id ? viewValues.entity_id : null,
				related_entity_type: viewValues.related_entity_type,
				related_entity_id: viewValues.related_entity_id ? viewValues.related_entity_id : null,
				type_field_mapping_id: viewValues.type_field_mapping_id,
				currency_id: parseInt(viewValues.currency_id),
				face_value: currencyFormat(viewValues.face_value),
				status_id: viewValues.status_id,
				tax_settings_id: viewValues.tax_settings_id,
				posted_on: viewValues.posted_on && moment(viewValues.posted_on),
				traded_on: viewValues.traded_on && moment(viewValues.traded_on),
				csv_columns: viewValues.csv_columns,
				model: viewValues.selected_models
			})
			setCsvColumn(viewValues.csv_columns)
			setItemsTable(viewValues.transactions_items)
			setEntityType(viewValues.entity_type)
			setRelatedTo(viewValues.related_entity_type)
			setOrgData(viewValues.entity_type === 2 ? viewValues.peoples : viewValues.organisations)
			setRelatedOrgData(viewValues.related_entity_type === "People" ? viewValues.related_peoples : viewValues.related_organisations)
			let arrayList = viewValues.journal_logs
			arrayList.forEach((item, i) => {
				item.index = i + 1;
			});
			store.modal.setArrayList(arrayList)
		}
	}, [getCsvModals, setItemsTable, viewValues, OrganisationStore, props.visible, store.modal, store.bank, form])

	// Reset form and close view form
	const close = () => {
		props.close()
		props.setId(null)
		setViewValues(null)
		setCsvColumn(null)
		setEntityType(null)
		setRelatedTo(null)
		setRelatedOrgData(null)
		setOrgData(null)
		AllTransactionStore.csvModals = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`View Trade Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button onClick={close}>Back to Listing</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="viewform"
					layout="vertical"
				>
					<FormFieldComponent
						form={form}
						currentValues={viewValues}
						tab={"view"}
						entity_type={entityType}
						related_to={relatedTo}
						orgData={orgData}
						relatedOrgData={relatedOrgData}
						csvColumn={csvColumn}
						openParentCashViewDrawer={props.openParentCashViewDrawer}
						close={close}
						handleApiCall={handleApiCall}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default ViewComponent

// currency format with 2 decimal point
function currencyFormat(value) {
	let val = parseFloat(value.replace(/,/g, ""))
	val = val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
	return val
}
