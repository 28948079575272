import React, { useState } from 'react'
import { Form, Button, Modal, Row, Col, } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../store'
import { vsmDashboard, vsmNotify } from '../../../config/messages'

const DeleteComponent = observer((props) => {
    const [saving, setSaving] = useState()
    const { DashboardStore } = useStore()

    const handleSubmit = () => {
        setSaving(true)
        DashboardStore.deleteWidget(props.deleteData.id).then(() => {
            props.close()
            props.getData()
            vsmNotify.success({
                message: vsmDashboard.delete
            })
            props.form.resetFields()
        }).catch(e => {
            if (e.errors) { props.form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
        })
    }

    return (
        <Modal
            centered
            title={`Delete Dashboard Widget - #${props.deleteData.id}`}
            visible={props.visible}
            onCancel={props.close}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
                <Button key="1" form='deleteWidget' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
                <Button key="2" htmlType="button" onClick={() => {
                    props.form.resetFields()
                    props.close()
                }}>Cancel</Button>
            ]}
        >
            <Form form={props.form} id="deleteWidget" onFinish={handleSubmit}>
                {
                    <Row align="middle">
                        <Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
                        <Col span={20}>
                            <h3>Are you sure you want to remove <strong>{props.deleteData.dashboard_name}</strong> widget?</h3>
                        </Col>
                    </Row>
                }
            </Form>
        </Modal>
    )
})

export default DeleteComponent;