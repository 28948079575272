import React from 'react'
import useStore from '../../../../store'
import { FileOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite'
const CreditRiskView = observer(() => {
	const { MemberStore: { viewMemberValue } } = useStore()

	return (
		<div className='members_table credit_risk'>
			<table cellPadding='0' cellSpacing='0' border='0'>
				<tbody className='etr_values' key="tbody">
					<tr>
						<th width="130px">ETR</th>
						<th width="100px">a-ETR</th>
						<th width="100px">b-ETR</th>
						<th width="100px">c-ETR</th>
						<th width="100px">d-ETR</th>
						<th width="100px">f-ETR</th>
					</tr>
					<tr>
						<th width="130px">Sell Rate %</th>
						<td width="100px">{viewMemberValue?.credit_risk?.sell_rate_a_etr ? viewMemberValue?.credit_risk?.sell_rate_a_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.sell_rate_b_etr ? viewMemberValue?.credit_risk?.sell_rate_b_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.sell_rate_c_etr ? viewMemberValue?.credit_risk?.sell_rate_c_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.sell_rate_d_etr ? viewMemberValue?.credit_risk?.sell_rate_d_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.sell_rate_f_etr ? viewMemberValue?.credit_risk?.sell_rate_f_etr : ''}</td>
					</tr>
					<tr>
						<th width="130px">Face Value Charge %</th>
						<td width="100px">{viewMemberValue?.credit_risk?.face_value_change_a_etr ? viewMemberValue?.credit_risk?.face_value_change_a_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.face_value_change_b_etr ? viewMemberValue?.credit_risk?.face_value_change_b_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.face_value_change_c_etr ? viewMemberValue?.credit_risk?.face_value_change_c_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.face_value_change_d_etr ? viewMemberValue?.credit_risk?.face_value_change_d_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.face_value_change_f_etr ? viewMemberValue?.credit_risk?.face_value_change_f_etr : ''}</td>
					</tr>
					<tr>
						<th width="130px">Agent Commission %</th>
						<td width="100px">{viewMemberValue?.credit_risk?.agent_commission_a_etr ? viewMemberValue?.credit_risk?.agent_commission_a_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.agent_commission_b_etr ? viewMemberValue?.credit_risk?.agent_commission_b_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.agent_commission_c_etr ? viewMemberValue?.credit_risk?.agent_commission_c_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.agent_commission_d_etr ? viewMemberValue?.credit_risk?.agent_commission_d_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.agent_commission_f_etr ? viewMemberValue?.credit_risk?.agent_commission_f_etr : ''}</td>
					</tr>
					<tr>
						<th width="130px">Trade Limit %</th>
						<td width="100px">{viewMemberValue?.credit_risk?.trade_limit_a_etr ? viewMemberValue?.credit_risk?.trade_limit_a_etr : ""}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.trade_limit_b_etr ? viewMemberValue?.credit_risk?.trade_limit_b_etr : ""}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.trade_limit_c_etr ? viewMemberValue?.credit_risk?.trade_limit_c_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.trade_limit_d_etr ? viewMemberValue?.credit_risk?.trade_limit_d_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.trade_limit_f_etr ? viewMemberValue?.credit_risk?.trade_limit_f_etr : ''}</td>
					</tr>
					<tr>
						<th width="130px">LDC Premium %</th>
						<td width="100px">{viewMemberValue?.credit_risk?.ldc_premium_a_etr ? viewMemberValue?.credit_risk?.ldc_premium_a_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.ldc_premium_b_etr ? viewMemberValue?.credit_risk?.ldc_premium_b_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.ldc_premium_c_etr ? viewMemberValue?.credit_risk?.ldc_premium_c_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.ldc_premium_d_etr ? viewMemberValue?.credit_risk?.ldc_premium_d_etr : ''}</td>
						<td width="100px">{viewMemberValue?.credit_risk?.ldc_premium_f_etr ? viewMemberValue?.credit_risk?.ldc_premium_f_etr : ''}</td>
					</tr>
				</tbody>
			</table>
			<table>
				<tbody>
					<tr>
						<th width="120px">Arrangement</th>
						<th width="120px">Membership</th>
						<th width="120px">Processing Comission</th>
						<th width="120px">Trade Commission</th>
						<th width="120px">Creditsafe Score</th>
					</tr>
					<tr>
						<td width="120px">{viewMemberValue?.credit_risk?.arrangement ? viewMemberValue?.credit_risk?.arrangement : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.membership ? viewMemberValue?.credit_risk?.membership : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.processing ? viewMemberValue?.credit_risk?.processing : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.trade ? viewMemberValue?.credit_risk?.trade : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.creditsafe_score ? viewMemberValue?.credit_risk?.creditsafe_score : ''}</td>
					</tr>
					<tr>
						<th width="120px">Creditsafe Today's Limit</th>
						<th width="120px">d-ETR Stop+</th>
						<th width="120px">d-ETR Stop-</th>
						<th width="120px">Facility Type</th>
						<th width="120px">d-ETR Purchase Price%</th>
					</tr>
					<tr>
						<td width="120px">{viewMemberValue?.credit_risk?.creditsafe_today_limit ? viewMemberValue?.credit_risk?.creditsafe_today_limit : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.d_etr_stop_plus ? viewMemberValue?.credit_risk?.d_etr_stop_plus : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.d_etr_stop_minus ? viewMemberValue?.credit_risk?.d_etr_stop_minus : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.facility_type ? viewMemberValue?.credit_risk?.facility_type : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.d_etr_purchase_price ? viewMemberValue?.credit_risk?.d_etr_purchase_price : ''}</td>
					</tr>
					<tr>
						<th width="120px">Posting Fee</th>
						<th width="120px">CCJ/HCJ/WUP</th>
						<th width="120px">MDM/ROM/AQ</th>
						<th width="120px">Ticker</th>
						<th width="120px">Instalment ETR</th>
					</tr>
					<tr>
						<td width="120px">{viewMemberValue?.credit_risk?.posting_fee ? viewMemberValue?.credit_risk?.posting_fee : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.CCJ_HCJ_WUP ? viewMemberValue?.credit_risk?.CCJ_HCJ_WUP : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.MDM_ROM_AQ ? viewMemberValue?.credit_risk?.MDM_ROM_AQ : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.ticker ? viewMemberValue?.credit_risk?.ticker : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.instalment_etr ? viewMemberValue?.credit_risk?.instalment_etr === 'true' ? 'True' : 'False' : ""}</td>
					</tr>
					<tr>
						<th width="120px">Reserve %</th>
						<th width="120px">TL Level</th>
						<th width="120px">Experian Report</th>
						<th width="120px">CreditSafe Report</th>
						<th width="120px"></th>
					</tr>
					<tr>
						<td width="120px">{viewMemberValue?.credit_risk?.reserve ? viewMemberValue?.credit_risk?.reserve : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.tl_level ? viewMemberValue?.credit_risk?.tl_level : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.expReport ? <a target='__blank' href={process.env.React_APP_API_URL + viewMemberValue?.credit_risk?.expReport}><FileOutlined /> {viewMemberValue?.credit_risk?.expReport?.split('/').pop()} </a> : ''}</td>
						<td width="120px">{viewMemberValue?.credit_risk?.creditSafeReport ? <a target='__blank' href={process.env.React_APP_API_URL + viewMemberValue?.credit_risk?.creditSafeReport}><FileOutlined /> {viewMemberValue?.credit_risk?.creditSafeReport?.split('/').pop()}</a> : ''}</td>
						<td width="120px"></td>
					</tr>
				</tbody>
			</table>
		</div>
	)
})
export default CreditRiskView