import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form, Spin } from 'antd'
import UserFormComponent from './UserFormComponent'
import { Steps } from 'antd'
import useStore from '../../../../store'
import BankDetailsComponent from './BankDetailsComponent'
import CreditRisksComponent from './CreditRiskComponent'
import UserDetialsComponent from './UserDetailsComponent'
import moment from 'moment'
const { Step } = Steps

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { MemberStore, MemberStore: { JsonToFormData, AddMemberOrgDetail, viewMemberValue, getPermission } } = useStore()
	const [stepState, setStepState] = useState([])
	const [saving, setSaving] = useState(false)
	const [isDisabled, setisDisabled] = useState(false)
	const [allFiles, updateAllFiles] = useState({})
	const [currentTab, setCurrentTab] = useState("account")

	const close = () => {
		MemberStore.userPermission = null
		MemberStore.organisationID = null
		MemberStore.viewMemberValue = null
		MemberStore.openFormType = null
		form.resetFields()
		MemberStore.currentTab = 0
		setSaving(false)
		updateAllFiles({})
		props.close()
		setisDisabled(false)
	}

	const handleSubmit = (data) => {

		// Remove unused fields
		if (data) {
			delete data.proof_identity
			delete data.proof_of_address
			delete data.turn_over
			delete data.tex_id_doc
			delete data.tax_id_number
		}
		setSaving(true)
		if (MemberStore.organisationID || (MemberStore.viewMemberValue && MemberStore.viewMemberValue.id)) {
			data.organisation_id = MemberStore.organisationID || MemberStore.viewMemberValue.id
		}
		if ((MemberStore.openFormType === 'Originator' && MemberStore.currentTab === 3) || (['Intermediary', 'Investor'].includes(MemberStore.openFormType) && MemberStore.currentTab === 2)) {
			data.role_id = MemberStore.userPermission.user && MemberStore.userPermission.user.id && MemberStore.userPermission.user.id
		} else {
			data.role_id = MemberStore.userPermission.org && MemberStore.userPermission.org.id && MemberStore.userPermission.org.id
		}
		if (data.formation_year) {
			data.formation_year = moment(data.formation_year).format("YYYY")
		}
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : "[]"
		}
		if (MemberStore.currentTab === 0) {
			if (data.email && data.email.length > 0) {
				data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
				data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
			}
		}
		if (data && data?.rating_agency && data?.rating_agency.length > 0) {
			data.rating_agency = data.rating_agency.filter(x => x && (x.rating_agency_name && x.rate && x.limit && x.contract && x.long_term && x.short_term))
			data.rating_agency = data.rating_agency.length > 0 ? JSON.stringify(data.rating_agency) : "[]"
		}
		if (data.addresses && data.addresses.length > 0) {
			data.add_address = 1
			data.addresses = data.addresses.filter(x => x && x)
			data.addresses = data.add_address && data.addresses.length > 0 ? JSON.stringify(data.addresses) : "[]"
		} else {
			if (!MemberStore.currentTab) {
				data.add_address = 0
			}
		}

		let actions = {}
		if (data.permissions) {
			data.permissions.map((item) => {
				item.actions.map(action => {
					if (action.is_selected === 1) {
						if (!actions[item.id]) { actions[item.id] = [] }
						actions[item.id].push(action.id)
					}
					return null
				})
				return null
			})
			data.permissions = actions
		}
		// For delete bank unsed key
		if (MemberStore.currentTab === 1 && data.bank) {
			data.bank.forEach((singleBank) => {
				if (singleBank.tab === 'account') {
					delete singleBank.iban
					delete singleBank.swift_bic
				} else {
					delete singleBank.account_number
					delete singleBank.sort_code
				}
			})
		}
		if (data.user_email) {
			data.email = data.user_email
		}
		data.action = 'update'
		let formData
		if (MemberStore.currentTab === 0 || (MemberStore.openFormType === 'Originator' && MemberStore.currentTab === 2)) {
			formData = JsonToFormData(data)
			Object.keys(allFiles).forEach((item) => {
				if (allFiles[item].length > 0 && allFiles[item][0]) {
					if (allFiles[item]) {
						if (allFiles[item][0].hasOwnProperty("originFileObj")) {
							formData.append(item, allFiles[item][0].originFileObj)
						}
					}
				} else {
					if (item) {
						if (item === 'logo_url') {
							if (MemberStore.currentTab === 0) {
								formData.append(`logo_status`, 'deleted')
							}
						} else {
							if (MemberStore.currentTab === 0 || (MemberStore.currentTab === 2 && MemberStore.openFormType === 'Originator')) {
								// formData.append(item, null)
								formData.append(item + `_deleted`, 1)
							}
						}
					}
				}
			})
		} else {
			formData = data
		}
		AddMemberOrgDetail(formData).then(() => {
			if ((MemberStore.currentTab === 3 && MemberStore.openFormType === 'Originator') || (['Intermediary', 'Investor'].includes(MemberStore.openFormType) && MemberStore.currentTab === 2)) {
				close()
			} else {
				MemberStore.setCurrentTab()
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => { setSaving(false); })
	}

	let steps = [{
		id: 1,
		title: `${MemberStore.openFormType} Details`,
		content: <UserFormComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} allFiles={allFiles} updateAllFiles={updateAllFiles} />
	}, {
		id: 2,
		title: 'Bank Details',
		content: <BankDetailsComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} currentTab={currentTab} setCurrentTab={setCurrentTab} />
	}, {
		id: 3,
		title: 'Credit & Risks',
		content: <CreditRisksComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} allFiles={allFiles} updateAllFiles={updateAllFiles} />
	}, {
		id: 4,
		title: 'User Details',
		content: <UserDetialsComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} />
	}]

	const changeTab = (index) => {
		if (viewMemberValue?.is_completed + 1 >= index + 1) {
			MemberStore.currentTab = index
		}
	}

	useEffect(() => {
		if (viewMemberValue) {
			MemberStore.organisationID = viewMemberValue.id
			// set Files to state
			if (viewMemberValue.logo_url) {
				updateAllFiles(prev => ({
					...prev, logo_url: ([
						{
							uid: '-1',
							name: 'image.png',
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.logo_url,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.creditSafeReport) {
				updateAllFiles(prev => ({
					...prev, creditSafeReport: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.creditSafeReport && viewMemberValue.credit_risk.creditSafeReport.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.creditSafeReport,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.expReport) {
				updateAllFiles(prev => ({
					...prev, expReport: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.expReport && viewMemberValue.credit_risk.expReport.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.expReport,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.id_proof) {
				updateAllFiles(prev => ({
					...prev, id_proof: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.id_proof && viewMemberValue.credit_risk.id_proof.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.id_proof,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.bank_proof) {
				updateAllFiles(prev => ({
					...prev, bank_proof: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.bank_proof && viewMemberValue.credit_risk.bank_proof.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.bank_proof,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.bank_statement_proof) {
				updateAllFiles(prev => ({
					...prev, bank_statement_proof: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.bank_statement_proof && viewMemberValue.credit_risk.bank_statement_proof.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.bank_statement_proof,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.aggreement_proof) {
				updateAllFiles(prev => ({
					...prev, aggreement_proof: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.aggreement_proof && viewMemberValue.credit_risk.aggreement_proof.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.aggreement_proof,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.cheque_document) {
				updateAllFiles(prev => ({
					...prev, cheque_document: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.cheque_document && viewMemberValue.credit_risk.cheque_document.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.cheque_document,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.personal_identity_record) {
				updateAllFiles(prev => ({
					...prev, personal_identity_record: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.personal_identity_record && viewMemberValue.credit_risk.personal_identity_record.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.personal_identity_record,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.passport_driving_license) {
				updateAllFiles(prev => ({
					...prev, passport_driving_license: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.passport_driving_license && viewMemberValue.credit_risk.passport_driving_license.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.passport_driving_license,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.utility_document) {
				updateAllFiles(prev => ({
					...prev, utility_document: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.utility_document && viewMemberValue.credit_risk.utility_document.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.utility_document,
						}
					])
				}))
			}
			if (viewMemberValue.credit_risk && viewMemberValue.credit_risk.rpa_agreement) {
				updateAllFiles(prev => ({
					...prev, rpa_agreement: ([
						{
							uid: '-1',
							name: viewMemberValue.credit_risk.rpa_agreement && viewMemberValue.credit_risk.rpa_agreement.split('/').pop(),
							status: 'done',
							url: process.env.React_APP_API_URL + viewMemberValue.credit_risk.rpa_agreement,
						}
					])
				}))
			}

			// Bank Details Tab
			viewMemberValue.get_member_bank && viewMemberValue.get_member_bank.length > 0 && viewMemberValue.get_member_bank.map((item) => {
				setCurrentTab((item.iban !== null) ? "iban" : "account")
				item.is_account = item.iban !== null ? false : true
				item.tab = item.iban !== null ? "iban" : "account"
				return item
			})

			if (viewMemberValue.credit_risk) {
				form.setFieldsValue(viewMemberValue.credit_risk)
				MemberStore.ratingAgencyList = viewMemberValue.credit_risk.rating_agency_list
			}
			if (viewMemberValue.rating_agency_list) {
				MemberStore.ratingAgencyList = viewMemberValue.rating_agency_list
			}
			// Set form field
			form.setFieldsValue({
				organisation_name: viewMemberValue.organisation_name && viewMemberValue.organisation_name,
				trade_name: viewMemberValue.trade_name && viewMemberValue.trade_name,
				parent_organisation_id: viewMemberValue.parent_organisation_id && viewMemberValue.parent_organisation_id,
				type: viewMemberValue && viewMemberValue.member_role_mapping && viewMemberValue.member_role_mapping.role_name && viewMemberValue.member_role_mapping.role_name,
				currency_id: viewMemberValue.currency_id && viewMemberValue.currency_id,
				industry_id: viewMemberValue.global_industries && viewMemberValue.global_industries.id && viewMemberValue.global_industries.id,
				nace_section: viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.get_nace_category && viewMemberValue.global_nace_sections.get_nace_category.id ? viewMemberValue.global_nace_sections.get_nace_category.id : null,
				nace_section_id: viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.id && viewMemberValue.global_nace_sections.id,
				registered_country_id: viewMemberValue.global_countries && viewMemberValue.global_countries.id && viewMemberValue.global_countries.id,
				registration_number: viewMemberValue.registration_number && viewMemberValue.registration_number,
				vat_number: viewMemberValue.vat_number && viewMemberValue.vat_number,
				tex_id_doc: viewMemberValue.tex_id_doc && viewMemberValue.tex_id_doc,
				tax_id_number: viewMemberValue.tax_id_number && viewMemberValue.tax_id_number,
				revenue_access_number: viewMemberValue.revenue_access_number && viewMemberValue.revenue_access_number,
				proof_identity: viewMemberValue.proof_identity && viewMemberValue.proof_identity.id && viewMemberValue.proof_identity.id,
				proof_of_address: viewMemberValue.proof_of_address && viewMemberValue.proof_of_address.id && viewMemberValue.proof_of_address.id,
				person_name: viewMemberValue.credit_risk && viewMemberValue.credit_risk.person_name && viewMemberValue.credit_risk.person_name.id && viewMemberValue.credit_risk.person_name.id,
				website: viewMemberValue.website && viewMemberValue.website,
				phone: viewMemberValue.multiple_phone && viewMemberValue.multiple_phone.length > 0 ? viewMemberValue.multiple_phone : MemberStore.userInitialValues.phone,
				email: viewMemberValue.email && viewMemberValue.email.length > 0 ? viewMemberValue.email : MemberStore.userInitialValues.email,
				description: viewMemberValue.description && viewMemberValue.description,
				relationship: viewMemberValue.relationship && viewMemberValue.relationship,
				formation_year: viewMemberValue.credit_risk && viewMemberValue.credit_risk.formation_year && moment(viewMemberValue.credit_risk.formation_year),
				status_id: viewMemberValue.status_id && viewMemberValue.status_id,
				addresses: viewMemberValue.mutiple_addresses && viewMemberValue.mutiple_addresses.length > 0 ? viewMemberValue.mutiple_addresses : null,
				bank: viewMemberValue.get_member_bank && viewMemberValue.get_member_bank.length > 0 ? viewMemberValue.get_member_bank : MemberStore.bankInitialValues.bank,
				instalment_etr: viewMemberValue.credit_risk && viewMemberValue.credit_risk.instalment_etr === "true" ? true : false,
				rating_agency: viewMemberValue.credit_risk && viewMemberValue.credit_risk.rating_agency && viewMemberValue.credit_risk.rating_agency.length ? viewMemberValue.credit_risk.rating_agency : MemberStore.creditRiskInitialValue.rating_agency,
				first_name: viewMemberValue.is_member_user && viewMemberValue.is_member_user.first_name && viewMemberValue.is_member_user.first_name,
				last_name: viewMemberValue.is_member_user && viewMemberValue.is_member_user.last_name && viewMemberValue.is_member_user.last_name,
				user_email: viewMemberValue.is_member_user && viewMemberValue.is_member_user.email && viewMemberValue.is_member_user.email,
				permissions: viewMemberValue.is_member_user && MemberStore.permission_list && MemberStore.permission_list
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MemberStore.openFormType, viewMemberValue])

	useEffect(() => {
		if (MemberStore.openFormType && props.visible) {
			let permissionPayload = {
				name: MemberStore.openFormType
			}
			getPermission(permissionPayload)
			// Dynamic steps (Remove 3 step in Intermediary & Investor)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MemberStore.openFormType, props.visible])

	useEffect(() => {
		if (MemberStore.openFormType !== 'Originator') {
			setStepState(steps.filter((item) => item.id !== 3))
		} else {
			setStepState(steps)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [MemberStore.openFormType, allFiles])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			title={`Edit Members Details - # ${viewMemberValue && viewMemberValue.id}`}
			placement='right'
			width={'60%'}
			destroyOnClose={true}
			className='member_drawer'
			footer={[
				<div key="1" className="d-flex justify-content-end">
					{MemberStore.openFormType === 'Originator' ? <Button form={`${MemberStore.openFormType}-${MemberStore.currentTab}`} className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save {MemberStore.currentTab !== 3 && '& Next'}</Button> :
						<Button form={`${MemberStore.openFormType}-${MemberStore.currentTab}`} className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save {MemberStore.currentTab !== 2 && '& Next'}</Button>}
					<Button className="ml-10" onClick={close}>Close</Button>
				</div>
			]}
		>
			<Steps type="navigation" size="small" current={MemberStore.currentTab} className="site-navigation-steps mb-10">
				{stepState.map((item, index) => (
					<Step key={item.id} title={item.title} onClick={() => changeTab(index)} className={viewMemberValue?.is_completed + 1 >= index + 1 ? 'member-view-step' : ''} />
				))}
			</Steps>
			<Spin spinning={!viewMemberValue}>
				{stepState.length > 0 && <div className="member_steps">{stepState.length && stepState?.[MemberStore.currentTab]?.content}</div>}
			</Spin>
		</Drawer>
	)
})

export default EditComponent