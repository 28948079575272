import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import { vsmNotify, vsmTransactionModels } from '../../../../config/messages'
import AddComponent from './component/AddComponent'
import EditComponent from './component/EditComponent'
import VerifyComponent from './component/VerifyComponent'
import ListComponent from './component/ListComponent'
import DeleteComponent from './component/DeleteComponent'
import UnpublishComponent from './component/UnpublishComponent'
import PublishComponent from './component/PublishComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const TransactionModels = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [editId, setEditID] = useState()
	const [verifyModal, setVerifyModal] = useState(false)
	const [verifyId, setVerifyID] = useState()
	const [deleteModal, setDeleteModal] = useState(false)
	const [unpublishModal, setUnpublishModal] = useState(false)
	const [publishModal, setPublishModal] = useState(false)
	const { TransactionModelStore: { per_page, setDeleteValues, setPageSize, TogglepublishData, setStatusValues, getAllRelations, agGrid }, UserStore } = useStore()

	// go to add screen
	const gotoAddScreen = () => {
		setAddModal(true)
	}

	//close add modal
	const closeAddScreen = () => setAddModal(false)

	// go to edit screen
	const gotoEditScreen = (id) => {
		setEditModal(true)
		setEditID(id)
	}

	//close edit modal
	const closeEditScreen = () => setEditModal(false)

	// go to verify screen
	const gotoVerifyScreen = (id) => {
		setVerifyModal(true)
		setVerifyID(id)
	}

	//close verify screen
	const closeVerifyScreen = () => setVerifyModal(false)

	// Open confirmation alert before deleting existing transaction field
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		setStatusValues(data)
		let formdata = { model_id: data.id }
		if (!checked && data.published === "Yes") {
			agGrid.api.refreshCells({ force: true });
			setUnpublishModal(true)
		} else {
			if (data.is_tested && checked) {
				getAllRelations(formdata, setPublishModal, handlePublish)
			}
		}
	}

	// Close unpublish modal
	const closeUnpublishModal = () => { setUnpublishModal(false) }

	// Close publish modal
	const closePublishModal = () => { setPublishModal(false) }

	// Handle Publish and call function to publish record
	const handlePublish = (data) => {
		let formdata = {
			id: data.model_id,
			status: true
		}
		TogglepublishData(formdata).then(() => {
			vsmNotify.success({
				message: vsmTransactionModels.publish
			})
		}).catch(e => {
			agGrid.api.refreshCells({ force: true });
		})
	}

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('transaction_model_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.TransactionModels.path} />
			{(!UserStore.checkPermission(26, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.TransactionModels.title}
					extra={
						[
							UserStore.checkPermission(26, 'add') && <Button key="1" onClick={gotoAddScreen}>New</Button>,
							<RecordPerPage key="2" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
							<Button key="3" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
							<EnterFullscreen key='4' />
						]
					}
				>
					<ListComponent gotoEditScreen={gotoEditScreen} gotoVerifyScreen={gotoVerifyScreen} openDeleteModal={openDeleteModal} onSwitchChange={onSwitchChange} />
					<AddComponent visible={addModal} close={closeAddScreen} />
					<EditComponent id={editId} visible={editModal} close={closeEditScreen} />
					<VerifyComponent id={verifyId} visible={verifyModal} close={closeVerifyScreen} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<UnpublishComponent visible={unpublishModal} close={closeUnpublishModal} />
					<PublishComponent visible={publishModal} close={closePublishModal} />
				</PageHeader>
			)}
		</>
	)
})

export default TransactionModels
