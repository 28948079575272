import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { Button, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import GridConfig from '../../../../config/GridConfig'
import useStore from '../../../../store'
import { vsmCommon } from '../../../../config/messages'
import { CurrencyFormat } from '../../../../utils/GlobalFunction'

const ListComponent = observer((props) => {
	const { openEditDrawer, openViewDrawer, openDeleteModal } = props
	const { UserStore, AllTransactionStore, AllTransactionStore: { setupGrid, onGridChanged, RowSelection } } = useStore()

	const ActionRenderer = (prop) => {

		return (
			<div className="action-column">
				{(UserStore.checkPermission(22, 'view')) && <Button title="View Trade Transaction" type="text" onClick={() => { openViewDrawer(prop.data.id) }}><EyeOutlined /></Button>}
				{(UserStore.checkPermission(22, 'edit')) && <Button title="Edit Trade Transaction" type="text" onClick={() => { openEditDrawer(prop.data.id) }}><EditOutlined /></Button>}
				{(UserStore.checkPermission(22, 'delete')) ? (prop.data.splited_merged_count > 0 ? <Tooltip placement="topRight" color={"red"} title={"Splited / Merged transactions cannot be deleted."}><Button disabled title="Delete Trade Transaction" type="text" ><DeleteOutlined /></Button></Tooltip> : <Button title="Delete Trade Transaction" type="text" onClick={() => { openDeleteModal(prop.data) }}><DeleteOutlined /></Button>) : null}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: true,
			filter: false,
			sortable: false,
			floatingFilter: false,
			width: 45,
			suppressMenu: true,
			pinned: 'left'
		}, {
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80,
		}, {
			headerName: 'Exchange ID',
			field: 'exchange_ref_id',
			width: 140,
		}, {
			headerName: 'Trade Transaction',
			field: 'type_field_mapping.mapping_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getMappingFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.mapping_name)]);
					})
				}
			},
			width: 160,
		}, {
			headerName: 'Organisation / People Name',
			field: 'entity_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.peoples && (params.data.peoples.first_name + (params.data.peoples.last_name ? (" " + params.data.peoples.last_name) : ''))) : (params.data.organisations && params.data.organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getOrgFilter({ name: "trade", type: 1 }).then((data) => {
						params.success([null, ...data.data.map(x => x.entity_name)]);
					})
				}
			},
			width: 200,
		}, {
			headerName: 'Role',
			field: 'role_name',
			valueGetter: (params) => (params.data) && ((params.data.entity_type && parseInt(params.data.entity_type) === 2) ? (params.data.people_role && params.data.people_role.role_name) : (params.data.organisation_role && params.data.organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getRoleFilter({ name: "trade", type: 1 }).then((data) => {
						params.success([null, ...data.data.map(x => x.entity_role)]);
					})
				}
			},
			width: 130,
		}, {
			headerName: 'Related Organisation / People Name',
			field: 'related_entity_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_peoples && (params.data.related_peoples.first_name + (params.data.related_peoples.last_name ? (" " + params.data.related_peoples.last_name) : ''))) : (params.data.related_organisations && params.data.related_organisations.organisation_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getOrgFilter({ name: "trade", type: 2 }).then((data) => {
						params.success([null, ...data.data.map(x => x.entity_name)]);
					})
				}
			},
			width: 200,
		}, {
			headerName: 'Related Role',
			field: 'related_role_name',
			valueGetter: (params) => (params.data) && ((params.data.related_entity_type && parseInt(params.data.related_entity_type) === 2) ? (params.data.related_people_role && params.data.related_people_role.role_name) : (params.data.related_organisation_role && params.data.related_organisation_role.role_name)),
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getRoleFilter({ name: "trade", type: 2 }).then((data) => {
						params.success([null, ...data.data.map(x => x.entity_role)]);
					})
				}
			},
			width: 130,
		}, {
			headerName: 'Transaction Ref',
			field: 'reference',
			width: 150,
		}, {
			headerName: 'Traded',
			field: 'traded_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 175,
		}, {
			headerName: 'Posted',
			field: 'posted_on',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				suppressAndOrCondition: true,
			},
			width: 175,
		}, {
			headerName: 'Face Value',
			field: 'face_value',
			type: 'rightAligned',
			cellRendererFramework: (data) => data.data ? <CurrencyFormat value={data.data.face_value} /> : null,
			width: 120,
		}, {
			headerName: 'Currency',
			field: 'global_currency.currency_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					AllTransactionStore.getCurrencyFilter().then((data) => {
						params.success([null, ...data.data.map(x => x.currency_name)]);
					})
				}
			},
			width: 120,
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 115,
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('transaction_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data && data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	var additional_columns = JSON.parse(localStorage.getItem('ADDITIONAL_COLUMN_LIST'))
	var additional_headers = JSON.parse(localStorage.getItem('ADDITIONAL_COLUMN_LIST_HEADER'))

	additional_columns && additional_columns.forEach((value, index) => {
		columns && columns.push({
			headerName: additional_headers[value],
			field: `additional_columns.${index}.field_value`,
			filterParams: {
				filterOptions: [
					'equals'
				]
			}
		})
	})

	return (
		<div className="ag-theme-alpine grid_wrapper" style={{ paddingBottom: '42px' }}>
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditDrawer, openViewDrawer, openDeleteModal
				}}
				onGridReady={setupGrid}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				gridOptions={GridConfig.options}
				rowSelection={'multiple'}
				onRowSelected={RowSelection}
				suppressRowClickSelection={true}
			/>
		</div>
	)
})

export default ListComponent
