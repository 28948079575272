import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Button } from 'antd'
import Chart from "react-apexcharts";

const LineWidget = observer((props) => {
    const [countJournals, setCountJournals] = useState(null)
    const [countId, setCountId] = useState(null)

    const updateCount = (id) => {
        setCountId(id)
        setCountJournals(id === 7 ? props.newCounter.chart['1_week'] : id === 30 ? props.newCounter.chart['1_month'] : id === 365 ? props.newCounter.chart['1_year'] : null)
    }

    const data = countId && {
        options: {
            chart: {
                id: "basic-line",
                toolbar: {
                    show: false,
                },
            },
            noData: {
                text: 'No Data Found.',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '16px',
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    colorStops: [
                        {
                            offset: 0,
                            color: "#fe6384",
                            opacity: 1
                        },
                        {
                            offset: 30,
                            color: "#ffd779",
                            opacity: 1
                        },
                        {
                            offset: 60,
                            color: "#4bc0c0",
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: "#36a2eb",
                            opacity: 1
                        }
                    ]
                }
            },
            xaxis: {
                categories: countJournals.x_value
            },
            tooltip: {
                theme: 'dark',
                x: {
                    show: true
                },
                y: {
                    show: true,
                    title: {
                        formatter: function () {
                            return
                        }
                    },
                    formatter: function (value, { series, dataPointIndex, w }) {
                        return value
                    }
                }
            }
        },
        series: [
            {
                data: countJournals.y_value
            }
        ]
    }

    useEffect(() => {
        if (props.newCounter.settings_json.i.extra_option === 'multiple') {
            setCountId(props.newCounter.settings_json.i.default_selected)
            setCountJournals(props.newCounter.settings_json.i.default_selected === 7 ? props.newCounter.chart['1_week'] : props.newCounter.settings_json.i.default_selected === 30 ? props.newCounter.chart['1_month'] : props.newCounter.settings_json.i.default_selected === 365 ? props.newCounter.chart['1_year'] : null)
        } else {
            setCountId(props.newCounter.settings_json.i.multi_select[0])
            setCountJournals(props.newCounter.settings_json.i.multi_select[0] === 7 ? props.newCounter.chart['1_week'] : null)
        }
    }, [props.newCounter])

    return countId && (
        <Card className="widget_wrapper graph_widget">
            <div className="counter__widget">
                <div className="counter__widget__description">
                    <div className="counter__widget__name">{props.newCounter.dashboard_name}</div>
                    <div className='counter__widget__chart'>
                        <Chart
                            options={data.options}
                            series={data.series}
                            type="line"
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>
            </div>
            <div className="counter__widget__footer">
                <div className="counter__widget__buttons">
                    {props.newCounter.settings_json.i.multi_select.map((buttonItem, index) => {
                        return (
                            <Button
                                type="link"
                                size="small"
                                onClick={() => updateCount(buttonItem)}
                                className={"counter__widget__button " + (buttonItem === countId ? "cs_active" : "")}
                            >
                                {buttonItem === 365 ? '12 Months' : `${buttonItem} Days`}
                            </Button>
                        )
                    })}
                </div>
            </div>
        </Card>
    )
})

export default LineWidget
