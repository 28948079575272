import { Button, Divider, Drawer, PageHeader, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages'
import useStore from '../../../../store'
import PageNotFound from '../../../PageNotFound'
import Unauthorized from '../../../Unauthorized'
import PreviewCashReportTableElement from '../elements/PreviewCashReportTableElement'
import PreviewReportTableElement from '../elements/PreviewReportTableElement'
import FormComponent from './FormComponent'
import NoRecordAlert from './NoRecordAlert'
import PreviewAuditReportTableElement from '../elements/PreviewAuditReportTableElement'
import PreviewOrganisationReportTableElement from '../elements/PreviewOrganisationReportTableElement'
import PreviewPeopleReportTableElement from '../elements/PreviewPeopleReportTableElement'

const PreviewReportComponent = observer((props) => {
	const history = useHistory()
	const [form] = useForm()
	const [saving, setSaving] = useState(true)
	const [tip, setTip] = useState('Loading')
	const [isDisabled, setDisabled] = useState(false)
	const { SavedReportStore, UserStore } = useStore()
	const [formDataList, setFormDataList] = useState([])
	const [PreviewReport, setPreviewReport] = useState(null)
	const [NoDataAlert, setNoDataAlert] = useState(false)
	const defaultFormDataList = ['report_type']
	const [isInitiated, setInitiation] = useState(false)
	const [editFilter, setEditFilter] = useState(false)

	history.listen((location, action) => {
		setInitiation(false)
	})

	const handlePreviewReport = useCallback(() => {
		setSaving(true)
		setTip('Loading Report')
		var data
		if (editFilter) {
			data = SavedReportStore.formatFormData({ ...SavedReportStore.previewReportValues, ...form.getFieldsValue(), form_list: formDataList })
		} else {
			data = SavedReportStore.formatFormData(SavedReportStore.previewReportValues)
		}
		data.name = SavedReportStore.previewReportValues.name
		data.code = SavedReportStore.previewReportValues.code
		data.description = SavedReportStore.previewReportValues.description
		data.action = "preview_report"
		setPreviewReport(null)
		SavedReportStore.PreviewReport(data).then(data => {
			setPreviewReport(data.data)
			if (data.data && Object.keys(data.data).length > 0) { } else {
				setNoDataAlert(true)
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setTip(null)
		})
	}, [setSaving, SavedReportStore, form, formDataList, editFilter])

	const InitaitePreviewReport = useCallback(async () => {
		await setFormDataList(SavedReportStore.previewReportValues.form_list ? SavedReportStore.previewReportValues.form_list : [])
		var previewReportValues = SavedReportStore.formatFormData(SavedReportStore.previewReportValues, false)
		await form.setFieldsValue(previewReportValues)
		handlePreviewReport()
	}, [setFormDataList, SavedReportStore, form, handlePreviewReport])

	useEffect(() => {
		if (!isInitiated && props.visible) {
			if (SavedReportStore.previewReportValues) {
				setInitiation(true)
				InitaitePreviewReport()
				setSaving(false)
				setTip(null)
			} else if (props.report_id) {
				setInitiation(props.report_id)
				SavedReportStore.ReadReport(props.report_id).then(data => {
					InitaitePreviewReport()
					setSaving(false)
					setTip(null)
				}).catch(({ response }) => {
					if (response && response.status === 404) {
						setSaving('404')
					}
					if (response && response.status === 403) {
						setSaving('403')
					}
				})
			}
		}

	}, [InitaitePreviewReport, SavedReportStore, isInitiated, props.report_id, props.visible, history])

	const handleSubmit = (data) => {
		setSaving(true)
		setTip('Saving')
		data.name = SavedReportStore.previewReportValues.name
		data.code = SavedReportStore.previewReportValues.code
		data.description = SavedReportStore.previewReportValues.description
		data.action = "save_report"
		data.form_list = formDataList
		if (SavedReportStore.previewReportValues.id) {
			data.id = SavedReportStore.previewReportValues.id
			SavedReportStore.EditData(data).then(data => {
				vsmNotify.success({
					message: vsmTransactionReport.edit
				})
				close('close_all')
				if (SavedReportStore.my_records === 'my') {
					SavedReportStore.getList({}, UserStore.user.id);
				} else {
					SavedReportStore.getList();
				}
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => {
				setSaving(false)
				setTip(null)
			})
		} else {
			SavedReportStore.AddData(data).then(data => {
				vsmNotify.success({
					message: vsmTransactionReport.save
				})
				close('close_all')
				if (SavedReportStore.my_records === 'my') {
					SavedReportStore.getList({}, UserStore.user.id);
				} else {
					SavedReportStore.getList();
				}
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			}).finally(() => {
				setSaving(false)
				setTip(null)
			})
		}

	}

	const exportReport = (type) => {
		setSaving(true)
		setTip('Exporting...')
		var data = null
		if (editFilter) {
			data = SavedReportStore.formatFormData({ ...SavedReportStore.previewReportValues, ...form.getFieldsValue(), form_list: formDataList })
		} else {
			data = SavedReportStore.formatFormData(SavedReportStore.previewReportValues)
		}
		data.export = type
		if (data.report_type === "audit") {
			SavedReportStore.exportAudit()
			setSaving(false)
			setTip(null)
		} else {
			SavedReportStore.exportReportFromServer(data).finally(() => {
				setSaving(false)
				setTip(null)
			})
		}
	}

	const close = (clear = true) => {
		props.close((!props.addModal && !props.editModal) || clear === 'close_all')
		if (editFilter) {
			SavedReportStore.setPreviewReportValues({
				...SavedReportStore.previewReportValues,
				...form.getFieldsValue(),
				form_list: formDataList
			})
		}
		if (clear && !props.addModal && !props.editModal) {
			props.setReportID(null)
			SavedReportStore.previewReportValues = null
		}
		setInitiation(false)
		setPreviewReport(null)
		setSaving(true)
		setTip('Loading')
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={'Preview Report'}
			destroyOnClose={true}
		>
			<Spin size="large" spinning={saving === true} tip={tip} className="test111" wrapperClassName={(SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type === "audit") ? "full_height_spinner" : ""}>
				{
					(saving === '404') ? (
						<PageNotFound />
					) : (
						(saving === '403') ? (
							<Unauthorized />
						) : (
							<div className="w-100 h-100" style={{ minHeight: '200px' }}>
								{(SavedReportStore.previewReportValues) ? (
									<>
										<PageHeader
											title={SavedReportStore.previewReportValues.name}
											extra={[
												((UserStore.user.id === SavedReportStore.created_by_id || !SavedReportStore.created_by_id) || (UserStore.user.user_role && [2].includes(UserStore.user.user_role.user_role_id))) && ((SavedReportStore.previewReportValues.id && UserStore.checkPermission(47, 'edit')) ||
													(!SavedReportStore.previewReportValues.id && UserStore.checkPermission(47, 'add'))) &&
												<Button key="1" onClick={() => setEditFilter(!editFilter)}>Edit Filters</Button>,
												form.getFieldValue('report_type') === 'trade' && UserStore.checkPermission(47, 'preview_data') && <Button key="2" onClick={() => {
													if (editFilter) {
														SavedReportStore.setPreviewReportValues({
															...SavedReportStore.previewReportValues,
															...form.getFieldsValue(),
															form_list: formDataList
														})
													}
													close(false)
													setTimeout(() => {
														props.openPreviewDataModal(props.report_id)
													}, 100)
												}}>Preview Data</Button>,
												UserStore.checkPermission(47, 'preview_report_export') && <Button key="3" onClick={() => exportReport('csv')}>Export CSV</Button>,
												UserStore.checkPermission(47, 'preview_report_export') && (
													(SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type !== "audit")
												) && (
													<Button key="4" onClick={() => exportReport('pdf')}>Export PDF</Button>
												)
											]}
										>
											<Divider className="pb-5" />
											{(editFilter) && (
												<FormComponent initialValues={SavedReportStore.initialValues} defaultFormDataList={defaultFormDataList} form={form} formDataList={formDataList} setFormDataList={setFormDataList} handleSubmit={handleSubmit}
													setDisabled={setDisabled}
													formButtons={(
														<div className="text-center">
															{UserStore.checkPermission(47, 'preview_report') && <Button className="mr-10" htmlType="button" disabled={isDisabled} onClick={handlePreviewReport}>Preview Report</Button>}
															{UserStore.checkPermission(47, 'add') && <Button className="mr-10" htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>}
														</div>
													)}
												/>
											)}
											<div className="mt-20 h-100" style={{ minHeight: '100px' }}>
												{(PreviewReport) && (
													(SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type === "audit") ? (
														<PreviewAuditReportTableElement onGridReady={SavedReportStore.setupAuditGrid} form={form} rowData={PreviewReport} />
													) : (SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type === "cash") ? (
														<PreviewCashReportTableElement onGridReady={SavedReportStore.setupGridList} form={form} rowData={PreviewReport} />
													) : (SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type === "organisation") ?
														<PreviewOrganisationReportTableElement onGridReady={SavedReportStore.setupGridList} form={form} rowData={PreviewReport} />
														: (SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.report_type === "people") ?
															<PreviewPeopleReportTableElement onGridReady={SavedReportStore.setupGridList} form={form} rowData={PreviewReport} />
															: ((SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.type === 5) ? ( // combined_reports
																Object.keys(PreviewReport).map((key) => {
																	var item = PreviewReport[key]
																	return (
																		<PreviewReportTableElement report_id={key} onGridReady={SavedReportStore.setupGridList} form={form} rowData={item} />
																	)
																})
															) : (
																<PreviewReportTableElement onGridReady={SavedReportStore.setupGridList} form={form} rowData={PreviewReport} />
															))
												)}
											</div>
											{(NoDataAlert) && <NoRecordAlert visible={NoDataAlert} setNoDataAlert={setNoDataAlert} />}
										</PageHeader>
									</>
								) : (null)}
							</div>
						)
					)
				}

			</Spin>
		</Drawer>
	)
})

export default PreviewReportComponent
