import { Button, Col, Divider, Form, Row, Spin } from 'antd'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import useStore from '../../../../store'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent'
import { vsmMember } from '../../../../config/messages'

const BankDetailsComponent = observer(({ form, handleSubmit, setDisabled, currentTab }) => {
	const { MemberStore } = useStore()
	const [currency, setCurrency] = useState(true)
	const [fetchBankType, setFetchBankType] = useState(true)
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const onTabChanges = () => {
		let add_ons = form.getFieldValue("bank");
		if (add_ons.length > 0) {
			add_ons.map(obj => {
				if (obj.tab === "account") {
					obj.is_account = true;
					obj.iban = null
					obj.swift_bic = null
				}
				else {
					obj.is_account = false;
					obj.account_number = null
					obj.sort_code = null
				}
				return null;
			})
		}
		forceUpdate();
	}

	return (
		<Form
			form={form}
			id={`${MemberStore.openFormType}-1`}
			onFinish={handleSubmit}
			layout='vertical'
			initialValues={MemberStore.bankInitialValues}
		>
			<Form.List name='bank'>
				{
					(fields, { add, remove }) => {
						return (
							<>
								{fields.map((field, index) => {
									return (
										<div className="w-100" key={field.key}>
											<div>
												<Row gutter={10} className="align-items-center">
													<Col span={23}>
														<Row gutter={10}>
															<Col span={10}>
																<InputComponent
																	required
																	label="Bank Name"
																	name={[field.name, 'bank_name']}
																	key={[field.key, 'bank_name']}
																	placeholder="Bank Name"
																	onChange={handleChange}
																	tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
																	rules={vsmMember.validation.bank_name}
																	maxLength={100}
																/>
															</Col>
															<Col span={7}>
																<InputComponent
																	required
																	allowClear
																	type="select"
																	label="Bank Account Type"
																	name={[field.name, 'bank_account_type_id']}
																	key={[field.key, 'bank_account_type_id']}
																	placeholder="Select Bank Account Type"
																	onChange={handleChange}
																	options={{
																		values: MemberStore.bankAccountType || (
																			MemberStore.viewMemberValue && MemberStore.viewMemberValue.get_member_bank && MemberStore.viewMemberValue.get_member_bank.length > 0 && MemberStore.viewMemberValue.get_member_bank.map((item) => ({
																				id: item.bank_accounts_type && item.bank_accounts_type.id,
																				name: item.bank_accounts_type && item.bank_accounts_type.name,
																			}))
																		),
																		value_key: "id",
																		text_key: "name"
																	}}
																	onFocus={() => (fetchBankType && MemberStore.getBankAccountTypeList().then(() => setFetchBankType(false)))}
																	notFoundContent={fetchBankType ? <Spin size="small" /> : "No Record Found."}
																	tooltip="A name or code given to a cash account that indicates the account's purpose."
																	rules={vsmMember.validation.account_type}
																/>
															</Col>
															<Col span={7}>
																<InputComponent
																	required
																	allowClear
																	type="select"
																	label="Select Currency"
																	name={[field.name, 'currency_id']}
																	key={[field.key, 'currency_id']}
																	placeholder="Select Currency"
																	onChange={handleChange}
																	rules={vsmMember.validation.currency}
																	tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
																	onFocus={() => (currency && MemberStore.getCurrenciesList().then(() => setCurrency(false)))}
																	notFoundContent={currency ? <Spin size="small" /> : "No Record Found."}
																	options={{
																		values: MemberStore.currenciesList || (
																			MemberStore.viewMemberValue && MemberStore.viewMemberValue.get_member_bank && MemberStore.viewMemberValue.get_member_bank.length > 0 && MemberStore.viewMemberValue.get_member_bank.map((item) => ({
																				id: item.currency_id,
																				currency_name: item.currency && item.currency.currency_name,
																			}))
																		),
																		value_key: "id",
																		text_key: "currency_name"
																	}}
																/>
															</Col>
														</Row>
														<Row gutter={10}>
															<Col span={7} className='mt-25'>
																<InputComponent
																	type="radio_button"
																	name={[field.name, 'tab']}
																	key={[field.key, 'tab']}
																	// initialValue="account"
																	defaultValue={currentTab}
																	onChange={(e) => { handleChange(); onTabChanges(); }}
																	options={{
																		values: [
																			{ value: 'account', text: 'Account Number' },
																			{ value: 'iban', text: 'IBAN Number' }
																		]
																	}}
																/>
															</Col>
															{
																form.getFieldValue("bank") && form.getFieldValue("bank")[index] && form.getFieldValue("bank")[index]["is_account"] ?
																	<>
																		<Col span={6}>
																			<InputComponent
																				required
																				label="Account Number"
																				name={[field.name, 'account_number']}
																				key={[field.key, 'account_number']}
																				placeholder="Account Number"
																				onChange={handleChange}
																				tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
																				rules={vsmMember.validation.account_number}
																				maxLength={50}
																			/>
																		</Col>

																		<Col span={6}>
																			<InputComponent
																				required
																				label="Sort Code"
																				name={[field.name, 'sort_code']}
																				key={[field.key, 'sort_code']}
																				placeholder="Sort Code"
																				onChange={handleChange}
																				tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
																				rules={vsmMember.validation.sort_code}
																				maxLength={50}
																			/>
																		</Col>
																	</>
																	:
																	<>
																		<Col span={6}>
																			<InputComponent
																				required
																				label="IBAN"
																				name={[field.name, 'iban']}
																				key={[field.key, 'iban']}
																				placeholder="International Bank Account Number"
																				onChange={handleChange}
																				tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
																				rules={vsmMember.validation.iba_num}
																			/>
																		</Col>

																		<Col span={6}>
																			<InputComponent
																				required
																				label="Swift or BIC Code"
																				name={[field.name, 'swift_bic']}
																				key={[field.key, 'swift_bic']}
																				placeholder="Swift or BIC Code"
																				onChange={handleChange}
																				tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
																				rules={vsmMember.validation.swift_or_bic_code}
																				maxLength={50}
																			/>
																		</Col>
																	</>
															}
														</Row>
													</Col>
													{
														<Col span={1}><Button type="danger" onClick={async () => {
															await remove(field.name)
															handleChange()
														}} icon={<MinusOutlined />}></Button></Col>
													}

												</Row>
											</div>
										</div>
									)
								})}
								<Divider>
									<Button type="primary" onClick={async () => {
										await add({
											bank_name: null,
											bank_account_type_id: null,
											currency_id: null,
											account_number: null,
											sort_code: null,
											iban: null,
											swift_bic: null,
											tab: 'account',
											is_account: true
										})
										handleChange()
									}} block icon={<PlusOutlined />}>Add Bank</Button>
								</Divider>
							</>
						)
					}
				}
			</Form.List >
		</Form>
	)
})

export default BankDetailsComponent