import Axios from "axios";
import { action, observable, decorate } from "mobx";
import moment from "moment";
import GridConfig from "../../config/GridConfig";
import { convertError, convertTextToID } from "../../utils/GlobalFunction";

export default class CredebtorStore {
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	currentTab=2
	organisationID=null
	CredebtorStatus=[]
	credebtorRole=[]
	AllCredebtorNace=[]
	origanisationOriginatorList=[]
	agGrid = null
	country_list=[]
	parent_organisations_list=[]
	currenciesList=null
	industries_list=null	
	naceCode_list=null
	country_list=[]
	stateCountyList=null
	cityList=null
	personList=null
	orgStatusList = null
	openTypeForm=null
	bankAccountType=[]
	showCredebtorData=[]
	ratingAgencyList=[]
	

	userInitialValues = {
		phone: [{ contact_for: 'Primary' }],
		email: [{ contact_for: 'Primary' }],
		address: [],
		type:this.openTypeForm
	}

	creditRiskInitialValue = {
		rating_agency: [{
			rating_agency_name: null
		}]
	}

    onGridChanged = (params) => {
		localStorage.setItem('credebtors_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

    handleReset = async () => {
		localStorage.removeItem('credebtors_grid')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

    setupGrid = (params) => {
		this.agGrid = this.agGridServer = params
		const { api } = params
		let columnConfig = localStorage.getItem('credebtors_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	setOpenTypeForm = (type) => {
		this.openTypeForm = type
	}

	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
		  if (![document].includes(key)) {
			formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
		  }
		})
		return formData
	}
	
	setCurrentTab = (number) => {
		this.currentTab = number ? number : this.currentTab + 1
	}

    createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('credebtors_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				let filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }

				if (filter_data.final_filter['organisation.global_countries.country_name']) {
					filter_data.final_filter['organisation.global_countries.country_name'].values = convertTextToID(filter_data.final_filter['organisation.global_countries.country_name'], this.country_list, 'country_name', 'id')
				}

				// if (filter_data.final_filter['member_role_mapping.role_name']) {
				// 	filter_data.final_filter['member_role_mapping.role_name'].values = convertTextToID(filter_data.final_filter['member_role_mapping.role_name'], this.memberRole, 'role_name', 'id')
				// }
				// if (filter_data.final_filter['global_industries.name']) {
				// 	filter_data.final_filter['global_industries.name'].values = convertTextToID(filter_data.final_filter['global_industries.name'], this.industries_list, 'name', 'id')
				// }

				if (filter_data.final_filter['organisation.status.status_name']) {
					filter_data.final_filter['organisation.status.status_name'].values = convertTextToID(filter_data.final_filter['organisation.status.status_name'], this.CredebtorStatus, 'status_name', 'id')
				}

				if (filter_data.final_filter['organisation.global_nace_sections.section']) {
					filter_data.final_filter['organisation.global_nace_sections.section'].values = convertTextToID(filter_data.final_filter['organisation.global_nace_sections.section'], this.AllCredebtorNace, 'category_name', 'id')
				}
				if (filter_data.final_filter['role_name']) {
					filter_data.final_filter['role_name'].values = convertTextToID(filter_data.final_filter['role_name'], this.credebtorRole, 'role_name', 'id')
				}
				if (filter_data.final_filter['global_organisations.updated_at']) {
					filter_data.final_filter['global_organisations.updated_at'].values = moment(filter_data.final_filter['global_organisations.updated_at']).format('YYYY-MM-DD')					
				}
				
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}


				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('credebtors_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

    getList = (payload = {}) => {
		return Axios.post(`back-office/credebot-debot`, payload).then(({ data }) => {
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page            
			return data
		})
	}

	showCredebtor = (Id) => {
		var payload = {}
		return Axios.post(`/back-office/credebtor-show/${Id}`, payload).then(({ data }) => {
			this.showCredebtorData = data.data			
			return data
		})
	}

	getCredeborRole = () => {
		var arr = []
		return Axios.post('organisations/role/get').then(({ data }) => {
			data.data.forEach((item) => {
				if (["Creditor", "Debtor"].includes(item.role_name)) {
					arr.push(item)
				}
			})
			this.credebtorRole = arr
			return data
		}).catch(() => {
			this.credebtorRole = []
		})
	}

	getNaceCategory = () => {		
		return Axios.get('organisations/global/nace-sections').then(({ data }) => {			
			this.AllCredebtorNace = data.data
			return data
		}).catch(() => {
			this.AllCredebtorNace = []
		})
	}

	getStatus = () => {
		return Axios.get(`/organisations/filter/status/get`).then(({ data }) => {
			this.CredebtorStatus = data.data
			return data
		}).catch((data) => {
			this.CredebtorStatus = []
			return Promise.reject(data)
		})
	}

	getOriginatorOrganisation = () => {
		return Axios.get(`/back-office/get-originator-organisation`).then(({ data }) => {
			this.origanisationOriginatorList = data.data
			return data
		}).catch((data) => {
			this.origanisationOriginatorList = []
			return Promise.reject(data)
		})		
	}

	getParentOrganisationList = (payload) => {
		return Axios.post(`ledgers/getGlobalOrganisations`, payload).then(({ data }) => {
			this.parent_organisations_list = data.data
			return data
		}).catch((data) => {
			this.parent_organisations_list = null
			return Promise.reject(data)
		})
	}

	getCurrenciesList = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
			this.currenciesList = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.currenciesList = null
			return Promise.reject(data)
		})
	}

	getIndustries = () => {
		return Axios.get(`organisations/global/industries`).then(({ data }) => {
			this.industries_list = data.data
			return data
		})
	}

	getNaceCode = () => {
		return Axios.get(`organisations/global/nace-sections`).then(({ data }) => {
			this.naceCode_list = data.data
			return data
		})
	}

	getCurrenciesList = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
			this.currenciesList = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.currenciesList = null
			return Promise.reject(data)
		})
	}

	// get Country list
	getAllCountries = () => {
		return Axios.get(`organisations/get/countries`).then(({ data }) => {
			this.country_list = data.data
			return data
		}).catch((data) => {
			this.country_list = null
		})
	}

	// call for city list to auto populate
	getCityList = () => {
		return Axios.get(`global/addresses/city/get`).then(({ data }) => {
			var i
			var arrayObj = data.data
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['city']
				delete arrayObj[i].key1
			}
			this.cityList = arrayObj
			return data
		}).catch(() => {
			this.cityList = null
		})
	}

	// call for bank name list to auto populate
	getStateCountyList = () => {
		return Axios.get(`global/addresses/state/get`).then(({ data }) => {
			var i
			var arrayObj = data.data
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['state_county']
				delete arrayObj[i].key1
			}
			this.stateCountyList = arrayObj
			return data
		}).catch(() => {
			this.stateCountyList = null
		})
	}

	getPersonList = () => {
		return Axios.get('back-office/person-list').then(({ data }) => {
			this.personList = data.data
		}).catch(() => {
			this.personList = null
		})
	}

	getRatingAgency = () => {
		return Axios.get('back-office/ratingagency-list').then(({ data }) => {
			this.ratingAgencyList = data.data
			return data
		})
	}

	getOrgStatus = () => {
		return Axios.get(`organisations/status/get`).then(({ data }) => {
			this.orgStatusList = data.data
			return data
		})
	}

	getBankAccountTypeList = () => {
		return Axios.get(`bankaccount/bankAccountType`).then(({ data }) => {
			this.bankAccountType = data.data
			return data
		}).catch((data) => {
			this.bankAccountType = null
			return Promise.reject(data)
		})
	}

	AddNewCredebtor = (info) => {		
		return Axios.post(`/back-office/add-credebot-debot`,info).then(({ data }) => {
			if(data && data.organisation_id)
			{
				this.organisationID = data.organisation_id
			}
			if(this.agGrid)
			{
				this.setupGrid(this.agGrid)
			}

			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	AddCreditorBank = (Id,formData) => {
		return Axios.post(`/back-office/add-credebot-bank/${Id}`,formData).then(({ data }) => {		
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}



}

decorate(CredebtorStore,{
	userInitialValues:observable,
    per_page:observable,
    current_page:observable,
    total:observable,
    agGrid:observable,
	origanisationOriginatorList:observable,
	AllCredebtorNace:observable,
	currentTab:observable,
	parent_organisations_list:observable,
	currenciesList:observable,
	industries_list:observable,
	naceCode_list:observable,
	personList:observable,
	orgStatusList:observable,
	openTypeForm:observable,
	stateCountyList:observable,
	cityList:observable,
	bankAccountType:observable,
	organisationID:observable,
	creditRiskInitialValue:observable,
	showCredebtorData:observable,
	ratingAgencyList:observable,
	onGridChanged:action.bound,
    setPageSize:action.bound,
	getAllCountries:action.bound,
    createDatasource:action.bound,
    setupGrid:action.bound,
	showCredebtor:action.bound,
	getOriginatorOrganisation:action.bound,
	getRatingAgency:action.bound,
	setCurrentTab:action.bound,
	AddNewCredebtor:action.bound,
	AddCreditorBank:action.bound,
	JsonToFormData:action.bound,
	setOpenTypeForm:action.bound,
	handleReset:action.bound,
	getStatus:action.bound,
	getIndustries:action.bound,
	getNaceCode:action.bound,
	getParentOrganisationList:action.bound,
	getCurrenciesList:action.bound,
	getNaceCategory:action.bound,
	getCredeborRole:action.bound,
    getList:action.bound,
	getBankAccountTypeList:action.bound,    
	getPersonList:action.bound
})
