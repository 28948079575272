import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../config/LocalGridConfig"

export default class ClassificationStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	deleteValues = null
	agGrid = null
	bankAccountsList = null
	classificationList = null

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// set bankAccount list
	setBankAccountList = (data) => {
		if (data.banks_accounts) {
			data.banks_accounts.forEach((item) => {
				item.bankAccount_names = item.name + " - " + (item.iban ? item.iban : item.account_number)
			});
		}
		this.bankAccountsList = data
	}

	checkboxList = [
		{ value: 'class', label: 'If Class is Identified using Mapped Regular Expression' },
		{ value: 'tag', label: 'If Tag is Identified using Mapped Regular Expression' },
		{ value: 'bank_account', label: 'If Bank Account is identified as an Internal Bank Account using Mapped Regular Expression' },
		{ value: 'iban', label: 'If IBAN is Identified using Mapped Regular Expression' },
		{ value: 'entity_id', label: 'If Organisation/Person is Identified using Mapped Regular Expression' },
		{ value: 'related_entity_id', label: 'If Related Organisation/Person is Identified using Mapped Regular Expression' },
		{ value: 'role_id', label: 'If Organisation/Person Role is Identified using Mapped Regular Expression' },
		{ value: 'related_role_id', label: 'If Related Organisation/Person Role is Identified using Mapped Regular Expression' }
	]

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('cte_classification_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('cte_classification_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get cash transaction classfications
	getClassfications = () => {
		return Axios.get(`cte/transaction-classification/get`).then(({ data }) => {
			this.classificationList = data.data
			return data
		})
	}

	// call api to get cte transaction types
	getCteTypes = () => {
		return Axios.get(`cte/transaction-classification/get/transacationstype`).then(({ data }) => {
			this.cteTypesList = data.data
			return data
		})
	}

	// call api to get bank accounts
	getBankAccounts = (payload) => {
		this.bankAccountsList = null
		return Axios.post(`cte/transaction-classification/get/filtere-bank-accounts`, payload).then(({ data }) => {
			if (data.data.banks_accounts.length > 0) {
				data.data.banks_accounts.forEach((item) => {
					if (item.account_number) {
						item.bankAccount_names = item.name + " - " + item.account_number
					} else {
						item.bankAccount_names = item.name + " - " + item.iban
					}
				});
			}
			this.bankAccountsList = data.data
			return data
		})
	}

	// call api to get record detail
	getRecordById = (formdata) => {
		return Axios.get(`cte/transaction-classification/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		this.list_data = null
		return Axios.get(`cte/transaction-classification/list`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.srno = index + 1
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.api.setFilterModel(filter)
				this.agGrid.columnApi.applyColumnState({ state: sort })
			}
			return data
		})
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`cte/transaction-classification/add`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`cte/transaction-classification/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`cte/transaction-classification/delete/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(ClassificationStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	deleteValues: observable,
	checkboxList: observable,
	agGrid: observable,
	cteTypesList: observable,
	classificationList: observable,
	bankAccountsList: observable,
	getList: action,
	EditData: action,
	setBankAccountList: action,
	setDeleteValues: action,
	DeleteData: action,
	setupGrid: action,
	setPageSize: action,
	getBankAccounts: action,
	getRecordById: action,
	getCteTypes: action,
	getClassfications: action,
	onGridChanged: action,
})
