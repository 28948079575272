import React, { useState } from 'react'
import { observer } from "mobx-react"
import InputComponent from '../../../../../components/InputComponent'
import { Col, Row, Spin } from 'antd'
import { vsmOrganisations } from '../../../../../config/messages'
import useStore from '../../../../../store'

const AddressSubElement = observer(({ onChange, field, setFetchCountry, fetchCountry }) => {
	const [fetchCity, setFetchCity] = useState(true)
	const [fetchState, setFetchState] = useState(true)
	const { MemberStore: { getAllCountries, country_list, getCityList, cityList, stateCountyList, getStateCountyList, viewMemberValue } } = useStore()

	return (
		<>
			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						label="Type"
						name={[field.name, 'address_type']}
						key={[field.key, 'address_type']}
						placeholder="Address Type"
						onChange={onChange}
						tooltip='The type of the address under which the address can be created.[eg.: Home, Office, Billing Address, Shipping Address]'
						rules={vsmOrganisations.validation.address_type}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 1" required
						name={[field.name, 'address_1']}
						key={[field.key, 'address_1']}
						placeholder="Address Line 1"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_1}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 2"
						name={[field.name, 'address_2']}
						key={[field.key, 'address_2']}
						placeholder="Address Line 2"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Address 3"
						name={[field.name, 'address_3']}
						key={[field.key, 'address_3']}
						placeholder="Address Line 3"
						onChange={onChange}
						tooltip="A contact point used to send physical forms of communication to the organisation."
						rules={vsmOrganisations.validation.address_2and3}
						maxLength={1000}
					/>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="City"
						name={[field.name, 'city']}
						key={[field.key, 'city']}
						placeholder="City"
						onChange={onChange}
						options={cityList}
						tooltip="It is a large human settlement place."
						rules={vsmOrganisations.validation.city}
						onFocus={() => fetchCity && getCityList().then(() => setFetchCity(false))}
						notFoundContent={fetchCity ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						allowClear
						type="autocomplete"
						label="State/County"
						name={[field.name, 'state_county']}
						key={[field.key, 'state_county']}
						placeholder="State/County"
						onChange={onChange}
						options={stateCountyList}
						tooltip="State is defined as a territory with its own government and borders within a larger country."
						rules={vsmOrganisations.validation.state}
						onFocus={() => fetchState && getStateCountyList().then(() => setFetchState(false))}
						notFoundContent={fetchState ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						label="Postal Code"
						name={[field.name, 'postal_code']}
						key={[field.key, 'postal_code']}
						placeholder="Postal Code"
						onChange={onChange}
						tooltip="Each administrative division maintains its own postal code for mail delivery purposes. Having the correct code is essential to your mails delivery."
						rules={vsmOrganisations.validation.postal_code}
						maxLength={16}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						required allowClear
						type="select"
						label="Country" placeholder="Country"
						name={[field.name, 'country_id']}
						key={[field.key, 'country_id']}
						onChange={onChange}
						options={{
							values: country_list || (
								viewMemberValue && viewMemberValue.mutiple_addresses && viewMemberValue.mutiple_addresses.length > 0 && viewMemberValue.mutiple_addresses.map((item) => ({
									id: item.countries && item.countries.id,
									country_name: item.countries && item.countries.country_name,
								}))
							),
							value_key: 'id',
							text_key: 'country_name'
						}}
						rules={vsmOrganisations.validation.country}
						tooltip="A country is a political state, nation, or territory which is controlled."
						onFocus={() => fetchCountry && getAllCountries().then(() => setFetchCountry(false))}
						notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
						autoComplete="off"
					/>
				</Col>
			</Row>
		</>
	)
})

export default AddressSubElement
