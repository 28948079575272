import React, { useEffect, useMemo, useState } from 'react'
import { Form, Button, Checkbox, Card, Spin, Upload, message, Drawer, Row, Col } from 'antd'
import { observer } from 'mobx-react'
import { vsmOrganisations, vsmNotify } from '../../../../config/messages'
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import useStore from '../../../../store'
import InputComponent from '../../../../components/InputComponent'
import PhoneElement from "../elements/PhoneElement";
import AddressElement from "../elements/AddressElement";
import EmailElement from "../elements/EmailElement";
import debounce from 'lodash/debounce'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrgStatusStore, OrganisationStore, OrgBankAccountStore, LedgerStore, OrganisationStore: { JsonToFormData, getAllCountries, AddData, getOrgCurrentLedgerMap, getRecordDetail, month_list, dropdown_countries_list, initialValues, global_setting } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [isChecked, setChecked] = useState(false)
	const [fetchMappedOrg, setFetchMappedOrg] = useState(false)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [fetctNace, setFetchNace] = useState(true)
	const [fetchIndustries, setFetchIndustries] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fileList, updateFileList] = useState([]);
	const [searchOrgKey, setSearchOrgKey] = useState()
	const [naceCode, setNaceCode] = useState()

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.allow_external_app = data.allow_external_app ? 1 : 0
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : "[]"
		}
		if (data.email && data.email.length > 0) {
			data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
			data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
		}
		if (data.addresses && data.addresses.length > 0) {
			data.addresses = data.addresses.filter(x => x && x)
			data.addresses = data.add_address && data.addresses.length > 0 ? JSON.stringify(data.addresses) : "[]"
		}
		setSaving(true)
		var formData = JsonToFormData(data)
		if (fileList.length > 0 && fileList[0]) {
			formData.append('logo_url', fileList[0].hasOwnProperty("originFileObj") ? fileList[0].originFileObj : fileList[0])
		}
		AddData(formData).then(() => {
			if (props.currentTab && props.currentTab.para === "maporg") {
				getOrgCurrentLedgerMap({ search_for: data.organisation_name, related_entity_type: 1 }).then((data) => {
					props.currentTab.form.setFieldsValue({
						entity_id: data.last_inserted_id
					})
					getRecordDetail({ organisation_id: data.last_inserted_id })
				})
			}
			close('add')
			vsmNotify.success({
				message: vsmOrganisations.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			let checkbox = form.getFieldValue("add_address")
			let address = form.getFieldValue("addresses")
			address = address && address.filter(x => x && x)
			if (checkbox && (!address || (address && address.length === 0))) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = (action = null) => {
		props.close(action)
		form.resetFields()
		setDisabled(true)
		setChecked(false)
		setFetchMappedOrg(false)
		setFetchStatus(true)
		setFetchIndustries(true)
		setFetchNace(true)
		setFetchCurrency(true)
		setSearchOrgKey(null)
		OrganisationStore.global_setting = null
	}

	// check whether address checkbox is checked or not
	const addAddress = async (e) => {
		var temp = localStorage.getItem('local_org_country')
		if (!e.target.checked) {
			form.resetFields(["postal_code", "address_1", "address_2", "address_3", "city", "state_county"])
		}
		form.setFieldsValue({ addresses: e.target.checked ? [temp ? { country_id: parseInt(temp) } : null] : null })
		if (fetchCountry) {
			OrganisationStore.getAllCountries().then(() => setFetchCountry(false))
		}
		await setChecked(e.target.checked)
		handleChange()
	}

	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
		},
		fileList,
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				return true;
			}
			else {
				updateFileList([file])
				return false
			}
		}
	};

	const changeNACE = (e) => {
		const naceCode = OrganisationStore.naceCode_list.find(list => list.id === e)
		setNaceCode(naceCode.nace_section)
		form.resetFields(['nace_section_id'])
	}

	// Handle on remove image
	const onRemoveImage = () => {
		form.resetFields(["logo_url"])
		updateFileList([]);
		handleChange();
	}

	// search string for organisation dropdown
	const handleOrgSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchOrgKey(val);
			if (val && val.length >= 1) {
				setFetchMappedOrg(true)
				LedgerStore.getAllGlobalOrganisationList({ search_for: val }).finally(() => setFetchMappedOrg(false))
			}
		};

		return debounce(loadOptions, 500);
	}, [LedgerStore])

	const onCountryChange = (e) => {
		localStorage.setItem('local_org_country', JSON.stringify(e))
	}

	const percentagesNode = (e, name) => {
		let value = Number(e.target.value)
		if (value < 10) {
			form.setFieldsValue({ [name]: value.toFixed(3) })
		} else if (value > 10 && value < 100) {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		} else if (value <= 100) {
			form.setFieldsValue({ [name]: value.toFixed(1) })
		}
	}

	useEffect(() => {
		if (props.visible) {
			var temp = localStorage.getItem('local_org_country')
			if (fetchCountry) {
				OrganisationStore.getAllCountries().then(() => setFetchCountry(false))
			}
			form.setFieldsValue({
				registered_country_id: temp ? parseInt(temp) : null,
				purchase_payment: global_setting?.purchase_payment,
				default_sell_rate_d_etr: global_setting?.default_sell_rate_d_etr,
				default_sell_rate_b_c_etr: global_setting?.default_sell_rate_b_c_etr,
				face_value_charge: global_setting?.face_value_charge,
				creditor_face_value_charge: global_setting?.creditor_face_value_charge,
				ldc_primium: global_setting?.ldc_primium,
				originator_application_fee: global_setting?.originator_application_fee,
				agent_commission: global_setting?.agent_commission,
				arrangement_fee: global_setting?.arrangement_fee,
				processing_commission: global_setting?.processing_commission,
				trade_commission: global_setting?.trade_commission,
				trade_limit_d_etr: global_setting?.trade_limit_d_etr,
				trade_limit_c_etr: global_setting?.trade_limit_c_etr,
				etr_months: global_setting?.etr_months,
				payment_discount: global_setting?.payment_discount,
				requested_credit_limit: global_setting?.requested_credit_limit,
				d_etr_purchase_discount: global_setting?.agreed_rate,
				c_etr_sell_rate: global_setting?.credit_agreed_rate
			})
		}
	}, [props.visible, form, OrganisationStore, fetchCountry, setFetchCountry, global_setting])

	return (
		<Drawer
			title="Add New Organisation"
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div >
			]}
		>
			<Form
				form={form}
				id="addform"
				onFinish={handleSubmit}
				onChange={handleChange}
				layout='vertical'
				initialValues={initialValues}
			>
				<Row gutter={24}>
					<Col span={8}>
						<InputComponent
							type="select" allowClear label="Parent Organisation" name="parent_organisation_id" placeholder="Parent Organisation" onChange={handleChange}
							options={{
								values: LedgerStore.global_organisations_list,
								value_key: 'id',
								text_key: 'organisation_name'
							}}
							tooltip="Parent Organisation Role is an Organisation Role that has primary role in a particular relation between two Organisations in the context of a single Transaction."
							notFoundContent={fetchMappedOrg ? <Spin size="small" /> : (searchOrgKey) ? "No Record Found." : null}
							filterOption={false}
							onSearch={handleOrgSearch}
						/>
					</Col>

					<Col span={8}>
						<InputComponent
							required label="Organisation Name" name="organisation_name" placeholder="Organisation Name" onChange={handleChange}
							tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
							rules={vsmOrganisations.validation.organisation_name}
							maxLength={100}
						/>
					</Col>

					<Col span={8}>
						<InputComponent
							label="Trade Name" name="trade_name" placeholder="Trade Name" onChange={handleChange}
							tooltip="Organisation Trade name is a trading name commonly used by an Organisation to perform business activities."
							rules={vsmOrganisations.validation.trade_name}
							maxLength={100}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={5}>
						<InputComponent
							required allowClear type="select" label="Registration Country" name="registered_country_id" placeholder="Select Registration Country"
							onChange={(e) => {
								handleChange()
								onCountryChange(e)
							}}
							options={{
								values: dropdown_countries_list,
								value_key: 'id',
								text_key: { key: ["iso_code_alpha2", " - ", "country_name"] },
							}}
							tooltip="A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities."
							rules={vsmOrganisations.validation.registration_country}
							onFocus={() => fetchCountry && getAllCountries().then(() => setFetchCountry(false))}
							notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
							autoComplete="off"
						/>
					</Col>

					<Col span={5}>
						<InputComponent
							label="Registration Number" name="registration_number" placeholder="Registration Number" onChange={handleChange}
							tooltip="A number appointed by relevant Company Registration Authorities in a country the organisation was founded in."
							rules={vsmOrganisations.validation.registration_number}
							maxLength={100}
						/>
					</Col>

					<Col span={5}>
						<InputComponent
							label="VAT Number" name="vat_number" placeholder="VAT Number" onChange={handleChange}
							tooltip="A number appointed by relevant Tax Authorities in a country the organisation was founded in."
							rules={vsmOrganisations.validation.vat_number}
							maxLength={100}
						/>
					</Col>

					<Col span={5}>
						<InputComponent
							label="Website" name="website" placeholder="Website URL" onChange={handleChange}
							tooltip="A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server."
							rules={vsmOrganisations.validation.website}
						/>
					</Col>

					<Col span={4}>
						<InputComponent
							allowClear type="select" label="FY Start Month" name="fy_start_month" placeholder="Select FY Start Month" onChange={handleChange}
							options={{
								values: month_list,
								value_key: 'month',
								text_key: 'month'
							}}
							tooltip="Financial Year Start Month is the starting month from which the Organisation will report its fiscal operations within a period of 12 months."
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={9}>
						<Form.Item label="Phone" className="mb-10" required={true}>
							<PhoneElement onChange={handleChange} form={form} />
						</Form.Item>
					</Col>
					<Col span={9}>
						<Form.Item label="Email" className="mb-10" required={true}>
							<EmailElement onChange={handleChange} form={form} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<div className='mt-25'>
							<InputComponent
								type='checkbox'
								name='allow_external_app'
								label="Allow External App"
								valuePropName='checked'
								onChange={handleChange}
								initialValue={false}
							/>
						</div>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={6}>
						<InputComponent
							required allowClear type="select" label="Credebt® Classification" name="industry_id" placeholder="Select Credebt® Classification"
							onChange={handleChange} rules={vsmOrganisations.validation.industry}
							tooltip="Credebt® Classification is a group of productive enterprises or organisations that produce or supply goods, services, or sources of income."
							onFocus={() => (fetchIndustries && OrganisationStore.getIndustries().then(() => setFetchIndustries(false)))}
							notFoundContent={fetchIndustries ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrganisationStore.industries_list,
								value_key: "id",
								text_key: "name"
							}}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required allowClear type="select" label="Organisation Currency" name="currency_id" placeholder="Select Organisation Currency"
							onChange={handleChange} rules={vsmOrganisations.validation.org_currency}
							tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
							onFocus={() => (fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false)))}
							notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrgBankAccountStore.dropdown_currency_list,
								value_key: "id",
								text_key: "currency_name"
							}}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required allowClear type="select" label="NACE Category" placeholder="Select NACE Category"
							onChange={(e) => { handleChange(); changeNACE(e) }}
							tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
							onFocus={() => (fetctNace && OrganisationStore.getNaceCode().then(() => setFetchNace(false)))}
							notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrganisationStore.naceCode_list || [{
									id: OrganisationStore.naceCode_list && OrganisationStore.naceCode_list && OrganisationStore.naceCode_list.id,
									category_name: OrganisationStore.naceCode_list && OrganisationStore.naceCode_list.nace_section && OrganisationStore.naceCode_list.category_name
								}],
								value_key: "id",
								text_key: "category_name"
							}}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required allowClear type="select" label="NACE Code" name="nace_section_id" placeholder="Select NACE Code"
							onChange={handleChange} rules={vsmOrganisations.validation.nace_code}
							tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
							onFocus={() => (fetctNace && OrganisationStore.getNaceCode().then(() => setFetchNace(false)))}
							notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: naceCode,
								value_key: "id",
								text_key: { key: ["code", " - ", "section"] }
							}}
							disabled={naceCode ? false : true}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required allowClear type="select" label="Status" name="status_id" placeholder="Select Status"
							onChange={handleChange} rules={vsmOrganisations.validation.status}
							tooltip="Status name would define whether that particular status is active or inactive."
							onFocus={() => (fetchStatus && OrgStatusStore.getOrgStatus().then(() => setFetchStatus(false)))}
							notFoundContent={fetchStatus ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrgStatusStore.orgStatusList,
								value_key: "id",
								text_key: "status_name"
							}}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							label="Tax Clearance Access No." name="revenue_access_number" placeholder="Tax Clearance Access Number"
							onChange={handleChange} rules={vsmOrganisations.validation.tax_clearence} maxLength={100}
							tooltip="When Revenue issues a Tax Clearance Certificate, they will also give a Tax Clearance Access Number (TCAN)."
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required
							label="d-ETR Monthly Discount Percentage"
							name="d_etr_purchase_discount"
							placeholder="d-ETR Monthly Discount Percentage"
							onChange={handleChange}
							tooltip="The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange"
							rules={vsmOrganisations.validation.discount_percentage}
							addonAfter={<PercentageOutlined />}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							required
							label="c-ETR Sell Rate Percentage"
							name="c_etr_sell_rate"
							placeholder="c-ETR Sell Rate Percentage"
							onChange={handleChange}
							tooltip="The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange"
							rules={vsmOrganisations.validation.sell_rate}
							addonAfter={<PercentageOutlined />}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={4}>
						<InputComponent
							required type="text" label="d-ETR Purchase" name="purchase_payment" placeholder="d-ETR Purchase"
							onBlur={(e) => percentagesNode(e, 'purchase_payment')}
							onChange={handleChange} rules={vsmOrganisations.validation.purchase_payment}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="d-ETR Sell Rate" name="default_sell_rate_d_etr" placeholder="d-ETR Sell Rate"
							onBlur={(e) => percentagesNode(e, 'default_sell_rate_d_etr')}
							onChange={handleChange} rules={vsmOrganisations.validation.default_sell_rate_d_etr}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="b/c-ETR Sell Rate" name="default_sell_rate_b_c_etr" placeholder="b/c-ETR Sell Rate"
							onBlur={(e) => percentagesNode(e, 'default_sell_rate_b_c_etr')}
							onChange={handleChange} rules={vsmOrganisations.validation.default_sell_rate_b_c_etr}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="d-ETR Face Value Charge" name="face_value_charge" placeholder="d-ETR Face Value Charge"
							onBlur={(e) => percentagesNode(e, 'face_value_charge')}
							onChange={handleChange} rules={vsmOrganisations.validation.face_value_charge}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="b/c-ETR Face Value Charge" name="creditor_face_value_charge" placeholder="b/c-ETR Face Value Charge"
							onBlur={(e) => percentagesNode(e, 'creditor_face_value_charge')}
							onChange={handleChange} rules={vsmOrganisations.validation.creditor_face_value_charge}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="ETR LDC Premium" name="ldc_primium" placeholder="ETR LDC Premium"
							onBlur={(e) => percentagesNode(e, 'ldc_primium')}
							onChange={handleChange} rules={vsmOrganisations.validation.ldc_primium}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="d-ETR Agent Commission" name="originator_application_fee" placeholder="d-ETR Agent Commission"
							onBlur={(e) => percentagesNode(e, 'originator_application_fee')}
							onChange={handleChange} rules={vsmOrganisations.validation.originator_application_fee}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="b/c-ETR Agent Commission" name="agent_commission" placeholder="b/c-ETR Agent Commission"
							onBlur={(e) => percentagesNode(e, 'agent_commission')}
							onChange={handleChange} rules={vsmOrganisations.validation.agent_commission}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="Arrangement Fee" name="arrangement_fee" placeholder="Arrangement Fee"
							onChange={handleChange} rules={vsmOrganisations.validation.arrangement_fee}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="Processing Fee" name="processing_commission" placeholder="Processing Fee"
							onChange={handleChange} rules={vsmOrganisations.validation.processing_commission}
						/>
					</Col>

					<Col span={4}>
						<InputComponent
							required type="text" label="Trade Fee" name="trade_commission" placeholder="Trade Fee"
							onChange={handleChange} rules={vsmOrganisations.validation.trade_commission}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="d-ETR Trade Limit" name="trade_limit_d_etr" placeholder="d-ETR Trade Limit"
							onChange={handleChange} rules={vsmOrganisations.validation.trade_limit_d_etr}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="c-ETR Trade Limit" name="trade_limit_c_etr" placeholder="c-ETR Trade Limit"
							onChange={handleChange} rules={vsmOrganisations.validation.trade_limit_c_etr}
						/>
					</Col>
					<Col span={4}>
						<InputComponent
							required type="text" label="ETR Months" name="etr_months" placeholder="ETR Months"
							onChange={handleChange} rules={vsmOrganisations.validation.etr_months}
						/>
					</Col>

					<Col span={4}>
						<InputComponent
							required type="text" label="Early Payment Discount" name="payment_discount" placeholder="Early Payment Discount"
							onChange={handleChange} rules={vsmOrganisations.validation.payment_discount}
						/>
					</Col>

					<Col span={4}>
						<InputComponent
							required type="text" label="Requested Credit Limit" name="requested_credit_limit" placeholder="Requested Credit Limit"
							onChange={handleChange} rules={vsmOrganisations.validation.requested_credit_limit}
						/>
					</Col>

					<Col span={8}>
						<InputComponent
							required type="textarea" label="Description" name="description" placeholder="Organisation Description"
							onChange={handleChange} rowSize={4} rules={vsmOrganisations.validation.description} maxLength={32000}
							tooltip="The purpose of describing the organisation is to provide; an understanding of mission, goals, & objectives, and clarify the focus and importance of the organisation's effort."
						/>
					</Col>

					<Col span={4}>
						<Form.Item label="Logo" tooltip={"Logo is a symbol or other small design adopted by an organisation to identify its products."} extra={[<span key="1" style={{ fontSize: "12px" }}>Only JPG or PNG is allowed, Maximum size : 500px x 500px</span>]}>
							<Form.Item noStyle name="logo_url">
								<Upload
									accept=".png, .jpeg"
									fileList={fileList}
									onRemove={onRemoveImage}
									className="hideeyeicon"
									listType="picture-card"
									multiple={false}
									showUploadList={true}
									{...eventProps}
								>
									{fileList.length >= 1 ? null :
										<div>
											<PlusOutlined />
										</div>}
								</Upload>
							</Form.Item>
						</Form.Item>
					</Col>
				</Row>

				<Form.Item className="mb-0" wrapperCol={{ offset: 8 }}>
					<Form.Item name="add_address" initialValue={false} valuePropName="checked">
						<Checkbox onChange={addAddress}>Add Address</Checkbox>
					</Form.Item>
				</Form.Item>
				<Card hidden={!isChecked}>
					<AddressElement onChange={handleChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} addAddress={addAddress} />
				</Card>
			</Form >
		</Drawer >
	)
})
export default AddComponent
