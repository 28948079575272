import React, { useEffect } from 'react';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import useStore from '../../../../store';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../config/messages';
import { Button } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import { DateComparator } from '../../../../utils/GlobalFunction';
import { observer } from 'mobx-react';

const ListComponent = observer((props) => {
    const { openEditDrawer, openViewDrawer } = props
    const { MailTemplateStore, UserStore } = useStore()

    const ActionRenderer = (props) => {
        return (
            <div className="action-column">
                {UserStore.checkPermission(61, 'edit') &&
                    <Button title="Edit Mails" type="text" onClick={() => { openEditDrawer(props.data) }}><EditOutlined /></Button>
                }
                {UserStore.checkPermission(61, 'view') &&
                    <Button title="View Mails" type="text" onClick={() => { openViewDrawer(props.data) }}><EyeOutlined /></Button>
                }
            </div>
        )
    }

    const gridOptions = {
        columnDefs: [{
            headerName: '# ID',
            field: 'id',
            filter: 'agNumberColumnFilter',
            pinned: 'left',
            width: 80
        }, {
            headerName: 'Template Name',
            field: 'template_name',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 150
        }, {
            headerName: 'Subject',
            field: 'email_subject',
            width: 200
        }, {
            headerName: 'Last Updated By',
            field: 'updated_by',
            filter: 'agSetColumnFilter',
            filterParams: {
                defaultToNothingSelected: true,
            },
            width: 150
        }, {
            headerName: 'Last Updated On',
            field: 'updated_at',
            filter: 'agDateColumnFilter',
            width: 185,
            filterParams: {
                buttons: ['reset'],
                inRangeInclusive: true,
                suppressAndOrCondition: true,
                comparator: DateComparator
            },
        }, {
            headerName: 'Actions',
            field: 'actions',
            type: 'actionColumn',
            filter: false,
            sortable: false,
            pinned: 'right',
            width: 90
        }]
    }

    useEffect(() => {
        MailTemplateStore.getListData()
    }, [MailTemplateStore])

    let columns = []
    let columnConfig = localStorage.getItem('mail_template_grid')
    if (columnConfig) {
        let data = JSON.parse(columnConfig)
        let cols = gridOptions.columnDefs.find(x => !x.field)
        if (cols) {
            columns.push(cols)
        }
        data.forEach(element => {
            cols = gridOptions.columnDefs.find(x => x.field === element.colId)
            if (cols) {
                columns.push(cols)
            }
        });
    } else {
        columns = gridOptions.columnDefs
    }

    return (
        <div className="ag-theme-alpine grid_wrapper">
            <AgGridReact
                rowData={MailTemplateStore.list_data}
                modules={AllModules}
                columnDefs={columns}
                defaultColDef={LocalGridConfig.defaultColDef}
                columnTypes={LocalGridConfig.columnTypes}
                overlayNoRowsTemplate={vsmCommon.noRecord}
                frameworkComponents={{
                    ActionRenderer, openEditDrawer, openViewDrawer
                }}
                onColumnResized={MailTemplateStore.onGridChanged}
                onColumnMoved={MailTemplateStore.onGridChanged}
                onColumnPinned={MailTemplateStore.onGridChanged}
                onGridReady={MailTemplateStore.setupGrid}
                gridOptions={LocalGridConfig.options}
                onFilterChanged={MailTemplateStore.onFilterChanged}
                onSortChanged={MailTemplateStore.onGridChanged}
            />
        </div>
    );
})

export default ListComponent;