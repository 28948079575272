import React, { useState, useEffect } from 'react'
import { Form, Button, Drawer, Spin } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import { vsmCashTransactions, vsmClassifications, vsmNotify } from '../../../../config/messages'
import moment from 'moment'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const { CashTransactionStore, CTEImportStore, ClassificationStore, OrgBankAccountStore, SavedReportStore } = useStore()

	// Make function call to edit new record
	const handleSubmit = (data) => {
		data.posted_on = data.posted_on && moment(data.posted_on).format("YYYY-MM-DD")
		data.value_on = data.value_on && moment(data.value_on).format("YYYY-MM-DD")
		data.cte_fields = data.cte_fields.filter(x => x && x.cte_field_id && x.field_value);
		let fields = CashTransactionStore.editValues.transaction_fields_value
		if (fields && fields.length > 0) {
			fields = fields.map(item => item.cte_field_id)
			let cte_fields = data.cte_fields.map(item => item.cte_field_id)
			let difference = fields.filter(x => !cte_fields.includes(x))
			data.removed_ids = difference
		}
		setSaving(true)
		data.id = CashTransactionStore.editValues.id
		CashTransactionStore.EditData(data).then(() => {
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmCashTransactions.edit
			})
			close()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			CashTransactionStore.getRecordById({ id: props.transactionId }).then((data) => {
				CashTransactionStore.setEditValues(data.data)
				CTEImportStore.setBankAccountList([data.data.ledgers_bank])
				ClassificationStore.cteTypesList = [data.data.cash_transactions_types]
				ClassificationStore.classificationList = [data.data.cte_transaction_classifications]
				OrgBankAccountStore.dropdown_currency_list = [data.data.global_currency]
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmClassifications.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props.visible, props.transactionId, CTEImportStore, OrgBankAccountStore, ClassificationStore, CashTransactionStore])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		CashTransactionStore.editValues = null
		SavedReportStore.main_organisation_roles = null
		SavedReportStore.main_people_roles = null
		SavedReportStore.main_organisation = null
		SavedReportStore.related_organisation = null
		SavedReportStore.related_organisation_role = null
		SavedReportStore.main_people = null
		SavedReportStore.related_people = null
		SavedReportStore.related_people_role = null
		form.resetFields()
		setDisabled(true)
		props.close()
		props.setId(null)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit Cash Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<FormComponent
					form={form}
					visible={props.visible}
					onChange={handleChange}
					onFinish={handleSubmit}
					tab={"edit"}
				/>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
