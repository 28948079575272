import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row, Spin } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../../components/InputComponent'
import { vsmManualJournalLog } from '../../../../../config/messages'
import useStore from '../../../../../store'

const FormComponent = observer(({ title, visible, close, currentValues, formButtons, form, form_id, setDisabled, handleSubmit }) => {
	const [fetchType, setFetchType] = useState(true)
	const { ManualJournalLogStore } = useStore()

	// Set the state value
	useEffect(() => {
		if (visible) {
			setFetchType(true)
		}
	}, [visible])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const handleDependent = (index) => {
		var payload = {
			model_id: form.getFieldValue("model_id"),
			journal_id: form.getFieldValue("journal_id"),
			transactions_type_id: form.getFieldValue("transactions_type_id")
		}
		ManualJournalLogStore.getAllRecords(payload, 0).then(async (val) => {
			if (payload.model_id && !val.model.find(x => x.id === payload.model_id)) {
				form.resetFields(["model_id"])
			}
			if (payload.transactions_type_id && !val.transaction.find(x => x.id === payload.transactions_type_id)) {
				form.resetFields(["transactions_type_id"])
			}
			if (payload.journal_id && !val.journal.find(x => x.id === payload.journal_id)) {
				form.resetFields(["journal_id"])
			}
			await handleChange()
		})
	}

	return (
		<Modal
			centered
			title={title}
			visible={visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={formButtons}
		>
			<Form form={form}
				id={form_id}
				layout="vertical"
				onChange={handleChange}
				onFinish={handleSubmit}
				autoComplete="off"
			>
				<Row gutter={24}>
					<Col span={12}>
						<InputComponent
							type="select" name="model_id" allowClear
							label="Model" placeholder="Choose Model" onChange={() => { handleDependent(); handleChange() }}
							onFocus={() => fetchType && ManualJournalLogStore.getAllRecords({
								model_id: form.getFieldValue('model_id'),
								journal_id: form.getFieldValue('journal_id'),
								transactions_type_id: form.getFieldValue('transactions_type_id')
							}, 0).then(() => setFetchType(false))}
							notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."} autoComplete="off"
							tooltip="Transaction Models are used to define the logic of how the data associated with Transactions, should be processed to create Journal Entries. Transaction Models are used when Database Actions, Events or Triggers are executed."
							options={{
								values: (ManualJournalLogStore.dropdown && ManualJournalLogStore.dropdown[0].model) || (currentValues && currentValues.model && [currentValues.model]),
								value_key: 'id',
								text_key: 'model_name',
							}}
						/>
					</Col>

					<Col span={12}>
						<InputComponent
							type="select" name="transactions_type_id" allowClear
							label="Trade Type" placeholder="Choose Trade Type" onChange={() => { handleDependent(); handleChange() }}
							onFocus={() => fetchType && ManualJournalLogStore.getAllRecords({
								model_id: form.getFieldValue('model_id'),
								journal_id: form.getFieldValue('journal_id'),
								transactions_type_id: form.getFieldValue('transactions_type_id')
							}, 0).then(() => setFetchType(false))}
							notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."} autoComplete="off"
							tooltip="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Sub Types."
							options={{
								values: (ManualJournalLogStore.dropdown && ManualJournalLogStore.dropdown[0].transaction) || (currentValues && currentValues.transaction_type && [currentValues.transaction_type]),
								value_key: 'id',
								text_key: 'type_name',
							}}
						/>
					</Col>

					<Col span={24}>
						<InputComponent
							required type="select" name="journal_id" rules={vsmManualJournalLog.validation.journal}
							label="Journal" placeholder="Choose Journal" onChange={() => { handleDependent(); handleChange() }}
							onFocus={() => fetchType && ManualJournalLogStore.getAllRecords({
								model_id: form.getFieldValue('model_id'),
								journal_id: form.getFieldValue('journal_id'),
								transactions_type_id: form.getFieldValue('transactions_type_id')
							}, 0).then(() => setFetchType(false))}
							notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."} autoComplete="off"
							tooltip="A Journal is an account against which Journal Entries are stored, e.g. in Accounts, this would be one nominal account in the chart of accounts."
							options={{
								values: (ManualJournalLogStore.dropdown && ManualJournalLogStore.dropdown[0].journal) || (currentValues && currentValues.journal && [currentValues.journal]),
								value_key: 'id',
								text_key: 'journal_label',
							}}
						/>
					</Col>

					<Col>
						<InputComponent
							label="&nbsp;"
							type="radio_button" name="type" initialValue="Debit"
							onChange={handleChange}
							options={{
								values: [
									{ value: 'Debit', text: 'Debit' },
									{ value: 'Credit', text: 'Credit' },
								]
							}}
						/>
					</Col>

					<Col flex={1}>
						<InputComponent
							required name="amount" label="Amount" placeholder="Debit / Credit"
							tooltip="A debit is an accounting entry that either increases an asset or expense account, or decreases a liability or equity account.
					A credit is an accounting entry that either increases a liability or equity account, or decreases an asset or expense account."
							rules={vsmManualJournalLog.validation.amount} autoComplete="off"
						/>
					</Col>
				</Row>


				<InputComponent
					type="textarea" label="Description" name="description" placeholder="Description"
					tooltip="The purpose of journal description is to mention  the financial transactions of a business, to be used for the future reconciling of accounts."
					rules={vsmManualJournalLog.validation.description}
					maxLength={1000} autoComplete="off"
				/>

			</Form>
		</Modal>
	)
})

export default FormComponent
