import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../../store'
import { Button, Drawer } from 'antd';
import { afETR, apiURL, cdETR, etrTypeObject as etr_type, rsaETRType } from '../../../../../utils/GlobalFunction'
import moment from 'moment';

const ViewComponent = observer((props) => {
	const { ImportETRStore } = useStore()

	const close = () => {
		ImportETRStore.viewImportETRValues = null
		props.close()
	}


	return (props.visible && ImportETRStore.viewImportETRValues) ? (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'50%'}
			destroyOnClose={true}
			zIndex={1001}
			title={'View '}
			footer={[
				<div className="text-center" key={1}>
					<Button onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<div className='viewETR'>
				<table cellPadding='0' cellSpacing='0' border='0'>
					<tbody key="tbody">
						{
							rsaETRType.includes(ImportETRStore.viewImportETRValues.etr_type) ?
								<>
									<tr>
										<td width="25%">ETR Type</td>
										<td width="25%">Open RSA for</td>
										<td width="25%">Payment Terms</td>
										<td width="25%">Face Value</td>
									</tr>
									<tr>
										<th>{etr_type[ImportETRStore.viewImportETRValues.etr_type]}</th>
										<th>{ImportETRStore.viewImportETRValues?.debtor?.organisation_name ? ImportETRStore.viewImportETRValues?.debtor?.organisation_name : ""}</th>
										<th>{ImportETRStore.viewImportETRValues.payment_discount_term}</th>
										<th>{ImportETRStore.viewImportETRValues.face_value}</th>
									</tr>
									<tr>
										<td width="25%">Number of ETR</td>
										<td width="25%">Currency</td>
										<td width="25%">Revolving Offer Months</td>
										<td width="25%">Attach Invoice</td>
									</tr>
									<tr>
										<th>{ImportETRStore.viewImportETRValues.etr_no}</th>
										<th>{ImportETRStore.viewImportETRValues?.currency?.currency_name ? ImportETRStore.viewImportETRValues?.currency?.currency_name : ""}</th>
										<th>{ImportETRStore.viewImportETRValues.etr_months}</th>
										<th>{ImportETRStore.viewImportETRValues.file_support && <a target={`_blank`} href={apiURL + ImportETRStore.viewImportETRValues.file_support.url}>{ImportETRStore.viewImportETRValues.file_support.name}</a>}</th>
									</tr>
									<tr>
										<td width="25%">Monthly Discount</td>
										<td width="25%">Buy Out Discount</td>
									</tr>
									<tr>
										<th>{ImportETRStore.viewImportETRValues.discount_price} %</th>
										<th>{ImportETRStore.viewImportETRValues.buyout_discount_price} %</th>
									</tr>
								</>
								:
								(afETR.includes(ImportETRStore.viewImportETRValues.etr_type)) ?
									<>
										<tr>
											<td width="25%">ETR Type</td>
											<td width="25%">Select Creditor</td>
											<td width="25%">Batch No</td>
											<td width="25%">ETR Months</td>
										</tr>
										<tr>
											<th>{etr_type[ImportETRStore.viewImportETRValues.etr_type]}</th>
											<th>{ImportETRStore.viewImportETRValues.debtor.organisation_name}</th>
											<th>{ImportETRStore.viewImportETRValues.batch_no}</th>
											<th>{ImportETRStore.viewImportETRValues.etr_months}</th>
										</tr>

										<tr>
											<td>Currency</td>
											<td>Start Date (YYYY/MM/DD)</td>
											<td>End Date (YYYY/MM/DD)</td>
											<td>Purchase Date (YYYY/MM/DD)</td>
										</tr>
										<tr>
											<th>{ImportETRStore.viewImportETRValues.currency && ImportETRStore.viewImportETRValues.currency.currency_name}</th>
											<th>{ImportETRStore.viewImportETRValues.start_date}</th>
											<th>{ImportETRStore.viewImportETRValues.end_date}</th>
											<th>{moment(ImportETRStore.viewImportETRValues.purchase_date).format('YYYY-MM-DD')}</th>
										</tr>
										<tr>
											<td>Asset Name</td>
											<td>Asset Number</td>
											<td>Quantity Number</td>
											<td>Asset Classfication</td>
										</tr>
										<tr>
											<th>{ImportETRStore.viewImportETRValues.asset_name}</th>
											<th>{ImportETRStore.viewImportETRValues.asset_number}</th>
											<th>{ImportETRStore.viewImportETRValues.quantity_numbers}</th>
											<th >{ImportETRStore.viewImportETRValues.asset_classification}</th>
										</tr>
										<tr>
											<td>Sell Rate</td>
											<td>Value</td>
											<td>Supplier/Manufacturer</td>
											<td>Invoice File/Document</td>
										</tr>
										<tr>
											<th>{ImportETRStore.viewImportETRValues.a_f_sell_rate}</th>
											<th>{ImportETRStore.viewImportETRValues.face_value}</th>
											<th>{ImportETRStore.viewImportETRValues.supplier_manufacturer}</th>
											<th>{ImportETRStore.viewImportETRValues.file_support && <a target={`_blank`} href={apiURL + ImportETRStore.viewImportETRValues.file_support.url}>{ImportETRStore.viewImportETRValues.file_support.name}</a>}</th>
										</tr>
										<tr>
											<td colSpan={4}>Description</td>
										</tr>
										<tr>
											<th colSpan={4}>{ImportETRStore.viewImportETRValues.desc && ImportETRStore.viewImportETRValues.desc}</th>
										</tr>
									</>
									: (cdETR.includes(ImportETRStore.viewImportETRValues.etr_type)) ?
										<>
											<tr>
												<td width="25%">ETR Type</td>
												<td width="25%">Upload Revolving ETR Invoice for</td>
												<td width="25%">Select b-ETR</td>
												{ImportETRStore.viewImportETRValues.etr_type === 4 ? <td width="25%">Deliver NoA to</td> : <th></th>}
											</tr>
											<tr>
												<th>{ImportETRStore.viewImportETRValues.etr_type && etr_type[ImportETRStore.viewImportETRValues.etr_type]}</th>
												<th>{ImportETRStore.viewImportETRValues.face_value && ImportETRStore.viewImportETRValues.face_value}</th>
												<th>{ImportETRStore.viewImportETRValues.payment_discount_term && ImportETRStore.viewImportETRValues.payment_discount_term}</th>
												{ImportETRStore.viewImportETRValues.etr_type === 4 ? <th>{ImportETRStore.viewImportETRValues.deliver_to && ImportETRStore.viewImportETRValues.deliver_to}</th> : <th></th>}
											</tr>

											<tr>
												<td width="25%">Payment Terms</td>
												<td width="25%">Currency</td>
												<td width="25%">Face Value</td>
												<td width="25%">Invoice Date</td>
											</tr>
											<tr>
												<th>{ImportETRStore.viewImportETRValues.payment_discount_term && ImportETRStore.viewImportETRValues.payment_discount_term}</th>
												<th>{ImportETRStore.viewImportETRValues.currency && ImportETRStore.viewImportETRValues.currency.currency_name}</th>
												<th>{ImportETRStore.viewImportETRValues.face_value && ImportETRStore.viewImportETRValues.face_value}</th>
												<th>{ImportETRStore.viewImportETRValues.invoice_date && ImportETRStore.viewImportETRValues.invoice_date}</th>
											</tr>

											<tr>
												<td width="25%">Expected Date</td>
												<td width="25%">PO Number</td>
												<td width="25%">Attach PoD</td>
												<td width="25%">Invoice No.</td>
											</tr>
											<tr>
												<th>{ImportETRStore.viewImportETRValues.expected_date && ImportETRStore.viewImportETRValues.expected_date}</th>
												<th>{ImportETRStore.viewImportETRValues.po_no && ImportETRStore.viewImportETRValues.po_no}</th>
												<th>{ImportETRStore.viewImportETRValues.po_attach && <a target={`_blank`} href={apiURL + ImportETRStore.viewImportETRValues.po_attach.url}>{ImportETRStore.viewImportETRValues.po_attach.name}</a>}</th>
												<th>{ImportETRStore.viewImportETRValues.invoice_no && ImportETRStore.viewImportETRValues.invoice_no}</th>
											</tr>
											<tr>
												<td width="25%">Attach Invoice</td>
											</tr>
											<tr>
												<th>{ImportETRStore.viewImportETRValues.file_support && <a target={`_blank`} href={apiURL + ImportETRStore.viewImportETRValues.file_support.url}>{ImportETRStore.viewImportETRValues.file_support.name}</a>}</th>
											</tr>


										</>
										: null
						}
					</tbody>
				</table>
			</div>
		</Drawer>
	) : null;
})

export default ViewComponent;
