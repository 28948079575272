import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card } from 'antd'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import LocalGridConfig from '../../../../config/LocalGridConfig'

const ReportWidget = observer((props) => {

    const { dashboard_name, chart } = props.newCounter
    const { DashboardStore } = useStore()
    const [columnDefs, setColumnDefs] = useState([])

    useEffect(() => {
        let newArray = []
        if (chart && chart.length) {
            Object.keys(chart[0]).forEach((keys) => {
                let newkey = keys.split('_').map(x => x.charAt(0).toUpperCase() + x.substring(1)).join(' ')
                newArray.push({
                    headerName: newkey,
                    field: keys,
                })
            })
            setColumnDefs(newArray)
        }
    }, [chart])

    return chart.length > 0 && columnDefs.length > 0 && (
        <Card className="widget_wrapper" >
            <div className="counter__widget">
                <div className="counter__widget__description">
                    <div className="counter__widget__name">{dashboard_name}</div>
                </div>
            </div>
            <div className="ag-theme-alpine report_widget_grid">
                <AgGridReact
                    modules={AllModules}
                    rowData={chart}
                    columnDefs={columnDefs}
                    onGridReady={DashboardStore.setupGrid}
                    defaultColDef={LocalGridConfig.defaultColDef}
                    columnTypes={LocalGridConfig.columnTypes}
                    overlayNoRowsTemplate={vsmCommon.noRecord}
                    gridOptions={LocalGridConfig.options}
                    pagination={false}
                    suppressRowClickSelection={true}
                    rowSelection={'multiple'}
                />
            </div>
        </Card >
    )
})

export default ReportWidget
