import Axios from "axios"
import { decorate, observable, action } from "mobx"
import GridConfig from "../../config/GridConfig"
import { convertError, convertTextToID } from "../../utils/GlobalFunction"
import debounce from 'lodash/debounce'

export default class AllTransactionStore {
	list_data = null
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	editValues = null
	deleteValues = null
	viewValues = null
	agGrid = null
	exportLoading = false
	listLoading = true

	organisation_dropdown = null
	mapping_dropdown = null
	status_dropdown = null
	vatRate_dropdown = null
	advance_filter = null
	depedentList = null
	initialStatus = null
	csvModals = null
	itemsTable = null
	roleID = null
	dropdown_list = null
	dropdownLodaing = false
	rowSelection = true

	ImportId = null
	list_status = 200

	filter_mapping = []
	filter_org = []
	filter_role = []
	filter_currency = []

	checkBox_dropdown = [
		{ id: 0, text: "Current Date", value: "Current Date" },
		{ id: 1, text: "Posted On", value: "Posted On" },
		{ id: 2, text: "Traded On", value: "Traded On" },
	]

	checkBox_dropdown_val = ["Current Date", "Posted On", "Traded On"]

	transaction_local_grid = [
		{ label: 'Exchange ID', colId: 'exchange_ref_id', hide: false },
		{ label: 'Mapping Type', colId: 'type_field_mapping.mapping_name', hide: false },
		{ label: 'Organisation / People Name', colId: 'entity_name', hide: false },
		{ label: 'Role', colId: 'role_name', hide: false },
		{ label: 'Related Organisation / People Name', colId: 'related_entity_name', hide: false },
		{ label: 'Related Role', colId: 'related_role_name', hide: false },
		{ label: 'Transaction Ref', colId: 'reference', hide: false },
		{ label: 'Traded', colId: 'traded_on', hide: false },
		{ label: 'Posted', colId: 'posted_on', hide: false },
		{ label: 'Face Value', colId: 'face_value', hide: false },
		{ label: 'Currency', colId: 'global_currency.currency_name', hide: false },
	]

	getMappingFilter = () => {
		return Axios.get(`trade/transactions/filter/typefieldmapping/get`).then(data => {
			this.filter_mapping = data.data.data
			return data.data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getOrgFilter = (payload) => {
		return Axios.get(`trade/cash/filter/entity/get/${payload.name}/${payload.type}`).then(data => {
			this.filter_org = data.data.data
			return data.data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getRoleFilter = (payload) => {
		return Axios.get(`trade/cash/filter/role/get/${payload.name}/${payload.type}`).then(data => {
			this.filter_role = data.data.data
			return data.data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getCurrencyFilter = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(data => {
			this.filter_currency = data.data.data
			return data.data
		}).catch(data => {
			Promise.reject(data)
		})
	}


	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// set form values to edit
	setViewValues = (data) => {
		this.viewValues = data
	}

	// set advance filter
	setAdvanceFilter = (data) => {
		this.advance_filter = data
	}

	// set id for import transaction
	setImportId = (data) => {
		this.ImportId = data
	}

	// set mapping values to edit & view
	setMappingList = (data) => {
		this.mapping_dropdown = data
	}

	// set status values to edit & view
	setStatus = (data) => {
		this.status_dropdown = data
	}

	// set vatrate values to edit & view
	setVatRate = (data) => {
		this.vatRate_dropdown = data
	}

	setItemsTable = (data) => {
		this.itemsTable = data
	}

	// set dependent list values to edit & view
	setDependent = (data) => {
		this.depedentList = {
			roles: data.entity_type === 2 ? [data.people_role] : [data.organisation_role],
			related_roles: data.related_entity_type === "Organisation" ? [data.related_organisation_role] : [data.related_people_role],
			entity: data.entity_type === 2 ? [data.peoples] : [data.single_organisations],
			related_entity: data.related_entity_type === "Organisation" ? [data.single_related_organisations] : [data.related_peoples]
		}
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = parseInt(page)
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		const { api } = params
		let columnConfig = localStorage.getItem('transaction_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('transaction_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('transaction_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				var filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }
				if (filter_data.final_filter['type_field_mapping.mapping_name']) {
					filter_data.final_filter['type_field_mapping.mapping_name'].values = convertTextToID(filter_data.final_filter['type_field_mapping.mapping_name'], this.filter_mapping, 'mapping_name', 'id')
				}

				if (filter_data.final_filter['global_currency.currency_name']) {
					filter_data.final_filter['global_currency.currency_name'].values = convertTextToID(filter_data.final_filter['global_currency.currency_name'], this.filter_currency, 'currency_name', 'id')
				}

				if (filter_data.final_filter['entity_name']) {
					filter_data.final_filter['entity_name'].values = convertTextToID(filter_data.final_filter['entity_name'], this.filter_org, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['role_name']) {
					filter_data.final_filter['role_name'].values = convertTextToID(filter_data.final_filter['role_name'], this.filter_role, 'entity_role', 'role_id')
				}

				if (filter_data.final_filter['related_entity_name']) {
					filter_data.final_filter['related_entity_name'].values = convertTextToID(filter_data.final_filter['related_entity_name'], this.filter_org, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['related_role_name']) {
					filter_data.final_filter['related_role_name'].values = convertTextToID(filter_data.final_filter['related_role_name'], this.filter_role, 'entity_role', 'role_id')
				}

				if (this.roleID) {
					filter_data.final_filter.role_name = {
						filterType: "set",
						values: [this.roleID]
					}
				}

				var additional_columns = JSON.parse(localStorage.getItem('ADDITIONAL_COLUMN_LIST'))

				Object.keys(filter_data.final_filter).forEach((headers) => {
					if (headers.includes('additional_columns')) {
						var header_id = headers.split('.')
						var filter_id = additional_columns[header_id[1]]
						filter_data.final_filter[filter_id] = filter_data.final_filter[headers]
						delete filter_data.final_filter[headers]
					}
				})

				filter_data.final_sort.forEach((sort) => {
					if (sort.colId.includes('additional_columns')) {
						var sorting = sort.colId.split('.')
						var sort_id = additional_columns[sorting[1]]
						sort.colId = sort_id
					}
				})

				var payload = {
					filter_type: localStorage.getItem("transaction_related_org_filter") ? 1 : 0,
					import_id: this.ImportId,
					advance_filter: this.advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
					additional_columnlist: JSON.parse(localStorage.getItem("ADDITIONAL_COLUMN_LIST"))
				}
				if (localStorage.getItem("transaction_related_org_filter")) {
					if (!payload.filter_data["related_entity_name"]) {
						payload.filter_data["related_entity_name"] = { "values": [localStorage.getItem("transaction_related_org_filter")], "filterType": "set" }
					}
					if (!payload.filter_data["entity_name"]) {
						payload.filter_data["entity_name"] = { "values": [localStorage.getItem("transaction_related_org_filter")], "filterType": "set" }
					}
				}
				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('transaction_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
					localStorage.removeItem("transaction_related_org_filter")
				})
			}
		}
	}

	// call api to get mapping drop down
	getMappingList = () => {
		return Axios.get(`trade/transactions/mapping/get`).then(({ data }) => {
			this.mapping_dropdown = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.mapping_dropdown = null
			return data
		})
	}

	// call api to get status drop down
	getStatusList = () => {
		return Axios.get(`trade/transactions/status/get`).then(({ data }) => {
			this.status_dropdown = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.status_dropdown = null
			return data
		})
	}

	// call api to get vat_rate drop down
	getVatRateList = () => {
		return Axios.get(`trade/transactions/vat-rate/get`).then(({ data }) => {
			this.vatRate_dropdown = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.vatRate_dropdown = null
			return data
		})
	}

	// call api to get csv & modals
	getCsvModals = (formdata, tab = null, selectedModal) => {
		return Axios.get(`trade/transactions/modelandfield/get-csv-column/${formdata}`).then(({ data }) => {
			var models = data.data.model
			if (models && models.length > 0) {
				models.forEach((x) => {
					var date = null;
					if (x.selected_date) { date = this.checkBox_dropdown_val.length > 0 ? (this.checkBox_dropdown_val.find(item => item.toLowerCase().includes(x.selected_date.replace(/_/g, ' ').trim()))) : null }
					x.field = date ? date : "Current Date"
					x.model = x.selected
				})
			}
			if (tab === "add") { data.fieldList = data.data.field }
			if (tab === "view") {
				models = models && models.filter(item => selectedModal && selectedModal.includes(item.id));
				if (models && models.length > 0) {
					models.forEach((x) => { x.value = x.id; x.label = x.model_name })
				}
			}
			this.csvModals = models
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get dependent list
	getDependentList = debounce((payload) => {
		this.depedentList = null
		this.dropdownLodaing = true
		return Axios.post(`trade/transactions/dependent/get`, payload).then(({ data }) => {
			this.depedentList = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.depedentList = null
			return data
		}).finally(() => this.dropdownLodaing = false)
	}, 100)

	// call api to get records
	getList = (payload = {}) => {
		this.listLoading = true
		return Axios.post(`trade/transactions/list`, payload).then(({ data }) => {

			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			this.listLoading = false
			return data
		}).catch(({ response: { data } }) => {
			if (data.status === 404) {
				this.list_status = data.status
			}
			return Promise.reject(data)
		})
	}

	// call to get record particular ID
	getRecordById = (formdata) => {
		return Axios.get(`trade/transactions/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// export data from server as a csv
	exportData = () => {
		if (this.agGrid) {
			var payload = {
				import_id: this.ImportId,
				filter_data: this.agGrid.api.getFilterModel(),
				advance_filter: this.advance_filter,
				sort_data: this.agGrid.api.getSortModel(),
				per_page: this.per_page,
				page: parseInt(this.agGrid.api.paginationProxy.currentPage + 1)
			}
			return Axios.post(`trade/transaction/export`, payload).then(({ data }) => {
				if (data.data && data.data.csv_link) { window.open(data.data.csv_link, '_blank'); }
				return data
			}).catch(e => {
				return Promise.reject(e)
			}).finally(() => this.exportLoading = false)
		}
	}


	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`trade/transactions/add`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`trade/transactions/edit/${formdata.id}`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`trade/transactions/delete/${formdata.id}`, formdata).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	//get Additional column list
	GetAdditionalColumnList = () => {
		return Axios.get(`get/fields/additional-columns`).then(({ data }) => {
			var pluckData = {}
			data.data.forEach((value) => {
				pluckData[value.id] = value.field_name
			})
			localStorage.setItem("ADDITIONAL_COLUMN_LIST_HEADER", JSON.stringify(pluckData))
			this.dropdown_list = data.data
		})
	}

	BulkStatusChange = (formdata) => {
		let array = this.agGrid && this.agGrid.api.getSelectedNodes()
		let selectedId = [];
		array && array.map((item) => (
			selectedId.push(item.data.id)
		))
		var payload = {
			selected_ids: selectedId,
			status_id: formdata
		}
		return Axios.post(`/trade/transaction/bluk-status-change`, payload).then(({ data }) => {
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			this.agGrid.api.deselectAll()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	RowSelection = () => {
		let array = this.agGrid && this.agGrid.api.getSelectedNodes()
		let selectedId = [];
		array && array.map((item) => (
			selectedId.push(item.data.id)
		))
		if (selectedId.length > 0) {
			this.rowSelection = false
		} else {
			this.rowSelection = true
		}
	}

}

decorate(AllTransactionStore, {
	list_data: observable,
	checkBox_dropdown: observable,
	checkBox_dropdown_val: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	viewValues: observable,
	agGrid: observable,
	mapping_dropdown: observable,
	status_dropdown: observable,
	vatRate_dropdown: observable,
	exportLoading: observable,
	dropdownLodaing: observable,
	listLoading: observable,
	advance_filter: observable,
	ImportId: observable,
	depedentList: observable,
	list_status: observable,
	initialStatus: observable,
	csvModals: observable,
	itemsTable: observable,
	filter_currency: observable,
	filter_role: observable,
	filter_org: observable,
	filter_mapping: observable,
	organisation_dropdown: observable,
	roleID: observable,
	transaction_local_grid: observable,
	dropdown_list: observable,
	rowSelection: observable,
	setEditValues: action,
	setDeleteValues: action,
	setViewValues: action,
	setImportId: action,
	setPageSize: action,
	setupGrid: action,
	onGridChanged: action,
	setMappingList: action,
	setStatus: action,
	setVatRate: action,
	setDependent: action,
	setAdvanceFilter: action,
	setItemsTable: action,
	createDatasource: action,
	getList: action,
	AddData: action,
	EditData: action,
	DeleteData: action,
	getMappingList: action,
	getStatusList: action,
	getVatRateList: action,
	getCsvModals: action,
	getRecordById: action,
	exportData: action,
	getDependentList: action,
	getOraganisationList: action,
	getCurrencyFilter: action,
	getMappingFilter: action,
	getOrgFilter: action,
	getRoleFilter: action,
	GetAdditionalColumnList: action,
	RowSelection: action,
})
