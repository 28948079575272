import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite'
import React from 'react'
import GridConfig from '../../../../config/GridConfig';
import { vsmCommon } from '../../../../config/messages';
import useStore from '../../../../store';
import { EyeOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { apiURL, DateComparator, ETRList, globalStatus } from '../../../../utils/GlobalFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'

const ListComponent = observer((props) => {
	const { AllETRStore, UserStore } = useStore()
	const { openEditTrade, openAuthTrade, openViewTrade } = props

	const ActionRenderer = (props) => {
		return (
			<div className="action-column">
				{UserStore.checkPermission(116, 'list') &&
					<>
						{props.data.status === 3 ?
							<Button title='View ETR' type="text" onClick={() => { openViewTrade(props.data.id) }}><EyeOutlined /></Button>
							: <Button title='Authorize ETR' type="text" onClick={() => { openAuthTrade(props.data.id) }}><CheckCircleOutlined /></Button>
						}
					</>
				}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: 'ID',
			field: 'id',
			pinned: 'left',
			filter: 'agNumberColumnFilter',
			tooltipField: 'id',
			width: 80
		}, {
			headerName: 'Credebtor ID',
			field: 'debtor_id',
			filter: 'agNumberColumnFilter',
			tooltipField: 'debtor.id',
			width: 120
		}, {
			headerName: 'Credebtor Name',
			field: 'credebtor.organisation_name',
			tooltipField: 'debtor.name',
			width: 150
		}, {
			headerName: 'Organisation ID',
			field: 'organisation.id',
			width: 120
		}, {
			headerName: 'Organisation Name',
			field: 'organisation.organisation_name',
			width: 180
		}, {
			headerName: 'Type',
			field: 'type',
			width: 100,
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Creditor', 'Debtor']
			},
			cellRendererFramework: function (data) {
				return (
					<span>{data.data.etr_type === "d-ETR" ? 'Debtor' : data.data.etr_type === "c-ETR" ? 'Creditor' : ''}</span>
				)
			}
		}, {
			headerName: 'ETR Type',
			field: 'etr_type',
			tooltipField: 'Type',
			width: 100,
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ETRList.map(x => x.text)
			},
		}, {
			headerName: 'Invoice ID',
			field: 'invoice_no',
			filter: 'agNumberColumnFilter',
			tooltipField: 'Invoice ID',
			width: 120,
			type: 'rightAligned'
		}, {
			headerName: 'PO ID',
			field: 'po_no',
			filter: 'agNumberColumnFilter',
			tooltipField: 'PO ID',
			width: 120,
			type: 'rightAligned'
		}, {
			headerName: 'Face Value',
			field: 'face_value',
			tooltipField: 'Face Value',
			width: 100,
			type: 'rightAligned'
		}, {
			headerName: 'Currency',
			field: 'currency.currency_code',
			tooltipField: 'Currency',
			width: 110
		}, {
			headerName: 'Invoice Date',
			field: 'invoice_date',
			tooltipField: 'Invoice Date',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				suppressAndOrCondition: true,
				comparator: DateComparator
			},
			width: 120
		}, {
			headerName: 'Created Date',
			field: 'created_at',
			tooltipField: 'Created Date',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				suppressAndOrCondition: true,
				comparator: DateComparator
			},
			width: 120
		}, {
			headerName: 'Status',
			field: 'status',
			tooltipField: 'Status',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Draft', 'Awaiting Approval', 'Approved', 'Rejected']
			},
			cellRendererFramework: function (data) {
				return (
					data.data.status ? <span>{globalStatus('trade_list_status', 'key', data.data.status)}</span> : null
				)
			},
			width: 130
		}, {
			headerName: 'Invoiced File',
			field: 'file_support.url',
			width: 100,
			cellRendererFramework: function (data) {
				return (
					data.data?.file_support?.name ? <a href={apiURL + data.data.file_support.url}><FontAwesomeIcon icon={faFilePdf} color={'red'} /></a> : null
				)
			}
		}, {
			headerName: 'PO File',
			field: 'po_attach.url',
			width: 100,
			cellRendererFramework: function (data) {
				return (
					data.data?.po_attach?.name ? <a href={apiURL + data.data.po_attach.url}><FontAwesomeIcon icon={faFilePdf} color={'red'} /></a> : null
				)
			}
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			filter: false,
			sortable: false,
			pinned: 'right',
			width: 100
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('All_ETR_LIST')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditTrade, openAuthTrade, openViewTrade
				}}
				onGridReady={AllETRStore.setupGrid}
				onColumnResized={AllETRStore.onGridChanged}
				onColumnMoved={AllETRStore.onGridChanged}
				onColumnPinned={AllETRStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent;
