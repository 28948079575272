import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal } from 'antd';
import useStore from '../../../../store';
import FormComponent from './FormComponent';
import { vsmMailTemplate, vsmNotify } from '../../../../config/messages';

const EditComponent = observer((props) => {
    const [form] = Form.useForm()
    const { MailTemplateStore } = useStore()
    const [isDisabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [modaleditorInitalValue, setModalEditorInitalValue] = useState(null);
    const [description, setDescription] = useState(null);
    const [resetContent, setresetContent] = useState(0);

    const handleChange = () => {
        form.validateFields().then(() => {
            let tempValue = form.getFieldValue('email_body')
            const newValue = tempValue?.replace(/(<([^>]+)>)/ig, '')
            if (newValue.length < 3) {
                form.setFields([{
                    name: 'email_body',
                    errors: ['Body should contain minimum 3 characters.']
                }])
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch((e) => {
            if (e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    const handleSubmit = (data) => {
        MailTemplateStore.updateData(data, MailTemplateStore.read_data?.id).then(() => {
            setresetContent(1)
            vsmNotify.success({
                message: vsmMailTemplate.edit
            })
            close()
        }).catch(e => {
            setresetContent(0);
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => {
            setSaving(false)
        })
    }

    const close = () => {
        form.resetFields()
        props.close()
        setDisabled(true)
        setSaving(false)
        MailTemplateStore.setReadData(null)
        setModalEditorInitalValue(null)
        setDescription(null)
        setresetContent(0)
    }

    useEffect(() => {
        if (props.visible && MailTemplateStore.read_data) {
            form.setFieldsValue({
                email_subject: MailTemplateStore.read_data.email_subject,
                template_name: MailTemplateStore.read_data.template_name,
                email_body: MailTemplateStore.read_data.email_body,
            })
            setModalEditorInitalValue(MailTemplateStore.read_data.email_body)
            setDescription(MailTemplateStore.read_data.description)
        }
    }, [MailTemplateStore.read_data, form, props.visible])

    return MailTemplateStore.read_data && (
        <Modal
            centered
            visible={props.visible}
            onCancel={close}
            placement='right'
            title={`Edit Mail # ${MailTemplateStore.read_data.id} - ${MailTemplateStore.read_data.template_name}`}
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
                <div className="text-center">
                    <Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <FormComponent
                form={form}
                onChange={handleChange}
                handleSubmit={handleSubmit}
                modaleditorInitalValue={modaleditorInitalValue}
                description={description}
                view={false}
                resetContent={resetContent}
                setModalEditorInitalValue={setModalEditorInitalValue}
            />
        </Modal>
    );
})

export default EditComponent;