import React, { useCallback, useEffect, useState } from 'react'
import { Form, Row, Col, Spin } from 'antd'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import DynamicFieldElement from '../elements/DynamicFieldElement'
import { vsmCashTransactions } from '../../../../config/messages'
import useStore from '../../../../store'
import TableComponent from '../elements/ReconciledTable'
import moment from 'moment'

const FormComponent = observer(({ visible, onFinish, onChange, tab, openUnlinkModal, openTransViewDrawer, form, parentOpenTransViewDrawer, close }) => {
	const [add_field_form] = Form.useForm()
	const [formDataList, setFormDataList] = useState([])
	const [fetchAccounts, setFetchAccounts] = useState(true)
	const [fetchTag, setFetchTag] = useState(true)
	const [fetchType, setFetchType] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchClassfication, setFetchClassification] = useState(true)
	const [saving, setSaving] = useState(false)
	const [fetchOrgRole, setFetchOrgRole] = useState(true)
	const [selectedRoleID, setSelectedRoleID] = useState()
	const { CashTransactionStore, ClassificationStore, CTEImportStore, SavedReportStore, LedgerBankAccountStore, OrgBankAccountStore, RoleMappingStore } = useStore()

	// set the form values to edit
	useEffect(() => {
		if (visible) {
			setFetchAccounts(true)
			setFetchTag(true)
			setFetchType(true)
			setFetchCurrency(true)
			setFetchClassification(true)
			form.setFieldsValue({ cte_fields: [null] })
		}
	}, [form, visible])

	//set organization switch case
	const getFormDataList = useCallback(() => {
		var FormDataList = []
		switch (form.getFieldValue('entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'main_people_role', 'main_people']
				break;
			default:
				FormDataList = [...FormDataList, 'role_id', 'entity_id']
		}

		switch (form.getFieldValue('related_entity_type')) {
			case 2:
				FormDataList = [...FormDataList, 'related_people_role', 'related_people']
				break;
			default:
				FormDataList = [...FormDataList, 'related_role_id', 'related_entity_id']
		}
		return FormDataList
	}, [form])

	// Set the form values to edit
	useEffect(() => {
		let editValues = CashTransactionStore.editValues
		if (CashTransactionStore.editValues && visible) {
			setSelectedRoleID(editValues.role_id)
			let bank = editValues.ledgers_bank && { id: editValues.bank_account_id, bankAccount_names: editValues.ledgers_bank.name + " - " + (editValues.ledgers_bank.iban ? editValues.ledgers_bank.iban : editValues.ledgers_bank.account_number) }
			CTEImportStore.bank_list = bank && [bank]
			form.setFieldsValue({
				bank_account_id: editValues.bank_account_id,
				entity_type: editValues.entity_type ? editValues.entity_type : 1,
				entity_id: editValues.entity_id,
				role_id: editValues.role_id,
				related_entity_type: editValues.related_entity_type ? editValues.related_entity_type : 1,
				related_entity_id: editValues.related_entity_id,
				related_role_id: editValues.related_role_id,
				cte_transaction_classification_id: editValues.cte_transaction_classification_id,
				cte_transactions_type_id: editValues.cte_transactions_type_id,
				transaction_details: editValues.transaction_details,
				face_value: editValues.credit ? "credit" : "debit",
				amount: editValues.credit ? editValues.credit : editValues.debit,
				currency_id: editValues.currency_id,
				posted_on: editValues.posted_on ? moment(editValues.posted_on) : null,
				value_on: editValues.value_on ? moment(editValues.value_on) : null,
				balance: editValues.balance,
				exchange_ref_id: editValues.exchange_ref_id && editValues.exchange_ref_id.toString(),
				customer_reference: editValues.customer_reference,
				bank_reference: editValues.bank_reference,
				cte_fields: editValues.transaction_fields_value && editValues.transaction_fields_value.length > 0 ? editValues.transaction_fields_value : [null]
			})
		}
	}, [visible, CTEImportStore, CashTransactionStore.editValues, form])

	useEffect(() => {
		if (visible) {
			var data = getFormDataList()
			if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
				setFormDataList(data)
			}
		}
	}, [getFormDataList, setFormDataList, CashTransactionStore.editValues, formDataList, visible])

	const getOrgRelatedData = useCallback((data = {}) => {
		let main_entity = form.getFieldValue('entity_type') === 1 ? "organisation" : "people"
		let related_entity = form.getFieldValue('related_entity_type') === 1 ? "organisation" : "people"
		let payload = { main_entity, related_entity }
		if (main_entity === 'people') {
			let main_people_role = form.getFieldValue('role_id')
			main_people_role = (main_people_role) ? [main_people_role] : null
			let main_people = form.getFieldValue('entity_id')
			main_people = (main_people) ? [main_people] : null
			payload = { ...payload, main_people_role, main_people }
		} else {
			let main_organisation_role = form.getFieldValue('role_id')
			main_organisation_role = (main_organisation_role) ? [main_organisation_role] : null
			let main_organisation = form.getFieldValue('entity_id')
			main_organisation = (main_organisation) ? [main_organisation] : null
			payload = { ...payload, main_organisation_role, main_organisation }
		}
		if (related_entity === 'people') {
			let related_people_role = form.getFieldValue('related_role_id')
			related_people_role = (related_people_role) ? [related_people_role] : null
			payload = { ...payload, related_people_role }
		} else {
			let related_organisation_role = form.getFieldValue('related_role_id')
			related_organisation_role = (related_organisation_role) ? [related_organisation_role] : null
			payload = { ...payload, related_organisation_role }
		}
		payload = { ...payload, ...data }
		SavedReportStore.getDependentList(payload)
	}, [form, SavedReportStore])

	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		onChange()
	}

	// api call on tag change
	const handleTagChange = () => {
		var values = form.getFieldsValue(["tag", "entity_type", "related_entity_type"])
		if (values.tag) {
			SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation")
			var payload = {
				"tag_id": values.tag,
				"entity_type": values.entity_type,
				"related_entity_type": values.related_entity_type
			}
			CashTransactionStore.getTagDetail(payload).then(async (data) => {
				setSelectedRoleID(data.roles.id)
				await form.setFieldsValue({
					entity_id: data.entity.id, role_id: data.roles.id,
					related_entity_id: data.related_entity.id, related_role_id: data.related_roles.id
				})
				getOrgRelatedData()
				handleChange()
			})
		}
	}

	// Handle add field and call function to save new record
	const handleAddField = (data) => {
		setSaving(true)
		add_field_form.validateFields().then(() => {
			LedgerBankAccountStore.AddDynamicField(data).then(() => {
				add_field_form.resetFields()
			}).catch(e => {
				if (e.errors) { add_field_form.setFields(e.errors) }
			}).finally(() => setSaving(false))
		})
	}

	return (
		<Form
			form={form}
			id={"addeditform"}
			onFinish={onFinish}
			layout="vertical"
		>
			<Row gutter={24}>
				{/* Bank Account */}
				<Col span={12}>
					<InputComponent
						required type="select" label="Bank Account" name="bank_account_id" placeholder="Select Bank Account"
						tooltip="A name or code given to a cash account that indicates the account's purpose."
						rules={vsmCashTransactions.validation.bank_account}
						onChange={handleChange}
						onFocus={() => fetchAccounts && CTEImportStore.getBankList().then(() => setFetchAccounts(false))}
						notFoundContent={fetchAccounts ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: CTEImportStore.bank_list,
							value_key: 'id',
							text_key: 'bankAccount_names',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Tag */}
				<Col span={12}>
					<InputComponent
						type="select" allowClear label="Tag" name="tag" placeholder="Search by Tag or Enter Entity Details Below"
						tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						onChange={handleTagChange}
						onFocus={() => fetchTag && RoleMappingStore.getAllTagsList().then(() => setFetchTag(false))}
						notFoundContent={fetchTag ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: RoleMappingStore.dropdown_tags_list,
							value_key: 'id',
							text_key: 'tag_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>
			</Row>

			{/* Entity Type */}
			<Row gutter={24}>
				<Col>
					<InputComponent
						type="radio_button" label="Entity Type" name="entity_type" initialValue={1}
						onChange={() => {
							getOrgRelatedData()
							setFetchOrgRole(true)
							form.resetFields(['role_id', 'entity_id', 'main_people_role', 'main_people', 'related_role_id', 'related_entity_id', 'related_people_role', 'related_people'])
							handleChange()
						}}
						options={{
							values: [
								{ value: 1, text: 'Organisation' },
								{ value: 2, text: 'People' },
							]
						}}
						disabled={tab === "view"}
					/></Col>
				<Col flex={1}>
					<Row gutter={24}>
						{(formDataList.includes('role_id')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Org. Role" name="role_id" placeholder="Select Organisation Role"
									tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
									rules={vsmCashTransactions.validation.org_or_people_role}
									onChange={(data) => {
										getOrgRelatedData()
										setSelectedRoleID(data)
										form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
										handleChange()
									}}
									onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
									notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: SavedReportStore.main_organisation_roles || [{
											id: CashTransactionStore.editValues && CashTransactionStore.editValues.organisation_role && CashTransactionStore.editValues.organisation_role.id,
											role_name: CashTransactionStore.editValues && CashTransactionStore.editValues.organisation_role && CashTransactionStore.editValues.organisation_role.role_name,
										}],
										value_key: 'id',
										text_key: 'role_name',
									}}
									disabled={tab === "view"}
								/>
							</Col>
						)}

						{(formDataList.includes('entity_id')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Organisation" name="entity_id"
									placeholder={selectedRoleID ? "Select Organisation" : "Please select Org. Role first"}
									tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
									rules={vsmCashTransactions.validation.org_or_people}
									onChange={() => {
										getOrgRelatedData()
										form.resetFields(['related_role_id', 'related_entity_id'])
										handleChange()
									}}
									options={{
										values: SavedReportStore.main_organisation || [{
											organisation_id: CashTransactionStore.editValues && CashTransactionStore.editValues.organisations && CashTransactionStore.editValues.organisations.id,
											organisation_name: CashTransactionStore.editValues && CashTransactionStore.editValues.organisations && CashTransactionStore.editValues.organisations.organisation_name,
										}],
										value_key: 'organisation_id',
										text_key: 'organisation_name',
									}}
									disabled={tab === "view" || !selectedRoleID}
								/>
							</Col>
						)}

						{(formDataList.includes('main_people_role')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="People Role" name="role_id"
									placeholder="Select People Role"
									tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
									rules={vsmCashTransactions.validation.org_or_people_role}
									onChange={(data) => {
										getOrgRelatedData()
										setSelectedRoleID(data)
										form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
										handleChange()
									}}
									onFocus={() => fetchOrgRole && SavedReportStore.getMainRoles(form.getFieldValue('entity_type') === 2 ? "people" : "organisation").then(() => setFetchOrgRole(false))}
									notFoundContent={fetchOrgRole ? <Spin size="small" /> : "No Record Found."}
									options={{
										values: SavedReportStore.main_people_roles || [{
											id: CashTransactionStore.editValues && CashTransactionStore.editValues.people_role && CashTransactionStore.editValues.people_role.id,
											role_name: CashTransactionStore.editValues && CashTransactionStore.editValues.people_role && CashTransactionStore.editValues.people_role.role_name,
										}],
										value_key: "id",
										text_key: "role_name",
									}}
									disabled={tab === "view"}
								/>
							</Col>
						)}

						{(formDataList.includes('main_people')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="People" name="entity_id"
									placeholder={selectedRoleID ? "Select People" : "Please select People Role first"}
									tooltip="People is an entity (for example a Person) that has (or had in the past) – directly or indirectly - any business relationship with Credebt Exchange or with another Organisation"
									rules={vsmCashTransactions.validation.org_or_people}
									onChange={() => {
										getOrgRelatedData()
										form.resetFields(['related_role_id', 'related_entity_id'])
										handleChange()
									}}
									disabled={tab === "view" || !selectedRoleID}
									options={{
										values: SavedReportStore.main_people || [{
											people_id: CashTransactionStore.editValues && CashTransactionStore.editValues.peoples && CashTransactionStore.editValues.peoples.id,
											people_name: CashTransactionStore.editValues && CashTransactionStore.editValues.peoples && CashTransactionStore.editValues.peoples.first_name + (CashTransactionStore.editValues.peoples.last_name ? (" " + CashTransactionStore.editValues.peoples.last_name) : ""),
										}],
										value_key: "people_id",
										text_key: "people_name",
									}}
								/>
							</Col>
						)}

					</Row>
				</Col>
			</Row>

			{/* Related Entity Type */}
			<Row gutter={24}>
				<Col>
					<InputComponent
						type="radio_button" label="Related Entity Type" name="related_entity_type" initialValue={1}
						onChange={() => {
							getOrgRelatedData()
							form.resetFields(['related_role_id', 'related_entity_id'])
							handleChange()
						}}
						options={{
							values: [
								{ value: 1, text: 'Organisation' },
								{ value: 2, text: 'People' },
							]
						}}
						disabled={tab === "view" || !selectedRoleID}
					/></Col>
				<Col flex={1}>
					<Row gutter={24}>
						{(formDataList.includes('related_role_id')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Rel. Org. Role" name="related_role_id"
									placeholder="Select Related Organisation Role"
									tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
									rules={vsmCashTransactions.validation.rel_org_or_people_role}
									onChange={() => {
										getOrgRelatedData()
										form.resetFields(['related_entity_id'])
										handleChange()
									}}
									options={{
										values: SavedReportStore.related_organisation_role || [{
											id: CashTransactionStore.editValues && CashTransactionStore.editValues.related_organisation_role && CashTransactionStore.editValues.related_organisation_role.id,
											role_name: CashTransactionStore.editValues && CashTransactionStore.editValues.related_organisation_role && CashTransactionStore.editValues.related_organisation_role.role_name,
										}],
										value_key: 'id',
										text_key: 'role_name',
									}}
									disabled={tab === "view" || !selectedRoleID}
								/>
							</Col>
						)}

						{(formDataList.includes('related_entity_id')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Rel. Organisation" name="related_entity_id"
									placeholder={selectedRoleID ? "Select Related Organisation" : "Please select Rel. Org. Role first"}
									tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
									rules={vsmCashTransactions.validation.rel_org_or_people}
									onChange={handleChange}
									options={{
										values: SavedReportStore.related_organisation || [{
											organisation_id: CashTransactionStore.editValues && CashTransactionStore.editValues.related_organisations && CashTransactionStore.editValues.related_organisations.id,
											organisation_name: CashTransactionStore.editValues && CashTransactionStore.editValues.related_organisations && CashTransactionStore.editValues.related_organisations.organisation_name,
										}],
										value_key: 'organisation_id',
										text_key: 'organisation_name',
									}}
									disabled={tab === "view" || !selectedRoleID}
								/>
							</Col>
						)}

						{(formDataList.includes('related_people_role')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Rel. People Role" name="related_role_id"
									placeholder="Select Related People Role"
									tooltip="People Role Relation is the connection between the Roles played by two different People."
									rules={vsmCashTransactions.validation.rel_org_or_people_role}
									disabled={tab === "view" || !selectedRoleID}
									onChange={() => {
										getOrgRelatedData()
										form.resetFields(['related_entity_id'])
										handleChange()
									}}
									options={{
										values: SavedReportStore.related_people_role || [{
											id: CashTransactionStore.editValues && CashTransactionStore.editValues.related_people_role && CashTransactionStore.editValues.related_people_role.id,
											role_name: CashTransactionStore.editValues && CashTransactionStore.editValues.related_people_role && CashTransactionStore.editValues.related_people_role.role_name,
										}],
										value_key: "id",
										text_key: "role_name",
									}}
								/>
							</Col>
						)}

						{(formDataList.includes('related_people')) && (
							<Col span={12}>
								<InputComponent
									required allowClear type="select" label="Rel. People" name="related_entity_id"
									placeholder={selectedRoleID ? "Select Related People" : "Please select Rel. People Role first"}
									tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
									rules={vsmCashTransactions.validation.rel_org_or_people}
									disabled={tab === "view" || !selectedRoleID}
									onChange={handleChange}
									options={{
										values: SavedReportStore.related_people || [{
											people_id: CashTransactionStore.editValues && CashTransactionStore.editValues.related_peoples && CashTransactionStore.editValues.related_peoples.id,
											people_name: CashTransactionStore.editValues && CashTransactionStore.editValues.related_peoples && CashTransactionStore.editValues.related_peoples.first_name + (CashTransactionStore.editValues.related_peoples.last_name ? (" " + CashTransactionStore.editValues.related_peoples.last_name) : ""),
										}],
										value_key: "people_id",
										text_key: "people_name",
									}}
								/>
							</Col>
						)}
					</Row>
				</Col>
			</Row>

			<Row gutter={24}>
				{/* Cash Transaction Classification */}
				<Col span={8}>
					<InputComponent
						required type="select" label="Cash Transaction Classification" name="cte_transaction_classification_id" placeholder="Select Cash Transaction Classification"
						tooltip="Classifications are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						rules={vsmCashTransactions.validation.cash_trans_classification}
						onChange={handleChange}
						onFocus={() => fetchClassfication && ClassificationStore.getClassfications().then(() => setFetchClassification(false))}
						notFoundContent={fetchClassfication ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.classificationList,
							value_key: 'id',
							text_key: 'transaction_class_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Transaction Type */}
				<Col span={8}>
					<InputComponent
						required type="select" label="Transaction Type" name="cte_transactions_type_id" placeholder="Select Transaction Type"
						tooltip="Cash Transaction Type indicates the type of cash transaction as defined by the bank. The parameter can be obtained - if available - from the Transaction Type or Transaction Details, i.e. Direct Debit, Transfer, etc.."
						rules={vsmCashTransactions.validation.trans_type}
						onChange={handleChange}
						onFocus={() => fetchType && ClassificationStore.getCteTypes().then(() => setFetchType(false))}
						notFoundContent={fetchType ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: ClassificationStore.cteTypesList,
							value_key: 'id',
							text_key: 'type_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Transaction Details */}
				<Col span={8}>
					<InputComponent
						required label="Transaction Details" name="transaction_details" placeholder="Enter Transaction Details"
						tooltip="Transaction Details is details given by bank in bank statement containing important information about transaction."
						rules={vsmCashTransactions.validation.trans_details}
						onChange={handleChange}
						disabled={tab === "view"}
						maxLength={1000}
					/>
				</Col>
			</Row>

			<Row gutter={24}>
				{/* Face Value */}
				<Col span={8}>
					<div className="ant-form-item-label">
						<label className="ant-form-item-required">Face Value</label>
					</div>
					<Row gutter={12} className="no-wrap">
						<Col span={12}>
							<InputComponent
								required type="radio_button" name="face_value" initialValue="debit"
								onChange={handleChange}
								options={{
									values: [
										{ value: 'debit', text: 'Debit' },
										{ value: 'credit', text: 'Credit' },
									]
								}}
								disabled={tab === "view"}
							/>
						</Col>
						<Col span={12}>
							<InputComponent
								name="amount" onChange={handleChange} placeholder="Enter Amount"
								tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
								rules={vsmCashTransactions.validation.amount}
								disabled={tab === "view"}
							/>
						</Col>
					</Row>
				</Col>

				{/* Currency */}
				<Col span={6}>
					<InputComponent
						required type="select" label="Currency" name="currency_id" placeholder="Select Currency"
						tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
						rules={vsmCashTransactions.validation.currency}
						onChange={handleChange}
						onFocus={() => fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: OrgBankAccountStore.dropdown_currency_list,
							value_key: 'id',
							text_key: 'currency_name',
						}}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Posted Date */}
				<Col span={5}>
					<InputComponent
						required type="date" label="Posted Date" name="posted_on"
						tooltip="The posted date is the day, month, and year when a transaction takes place. It is the date on which funds are taken or added to an account."
						onChange={handleChange}
						rules={vsmCashTransactions.validation.posted_date}
						disabled={tab === "view"}
						className="w-100"
					/>
				</Col>

				{/* Balance */}
				<Col span={5}>
					<InputComponent
						label="Balance" name="balance" placeholder="Enter Balance"
						tooltip="Balance per bank is the value of the initial state of the cash account. Just for Reference purpose."
						onChange={handleChange}
						rules={vsmCashTransactions.validation.balance}
						disabled={tab === "view"}
					/>
				</Col>
			</Row>

			<Row gutter={24}>

				{/* Value Date */}
				<Col span={6}>
					<InputComponent
						type="date" label="Value Date" name="value_on"
						tooltip="The value date is the date when the payment is actually paid/received by the customer. If not, the value date by default remains the same as the posted date."
						note="If Value Date field is Blank then it will be same as Posted Date field."
						onChange={handleChange}
						disabled={tab === "view"}
						className="w-100"
					/>
				</Col>

				{/* Exchange Reference ID */}
				<Col span={6}>
					<InputComponent
						label="Exchange Ref ID" name="exchange_ref_id" placeholder="Enter Exchange Reference ID"
						tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
						onChange={handleChange}
						rules={vsmCashTransactions.validation.exchange_ref_id}
						maxLength={100}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Customer Reference */}
				<Col span={6}>
					<InputComponent
						label="Customer Reference"
						name="customer_reference"
						placeholder="Enter Customer Reference"
						tooltip="Customer Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmCashTransactions.validation.customer_reference} onChange={handleChange}
						disabled={tab === "view"}
					/>
				</Col>

				{/* Bank Reference */}
				<Col span={6}>
					<InputComponent
						label="Bank Reference"
						name="bank_reference"
						placeholder="Enter Bank Reference"
						tooltip="Bank Reference will contain invoice reference no. i.e. INV-XYZ123 or other unique name (per-Organisation Relation [i.e. Originator-Debtor] or per-Person Relation)"
						rules={vsmCashTransactions.validation.bank_reference} onChange={handleChange}
						disabled={tab === "view"}
					/>
				</Col>
			</Row>

			<div style={{ maxWidth: "600px" }}>
				{/* Dynamic Field */}
				<DynamicFieldElement
					form={form}
					saving={saving}
					onChange={handleChange}
					add_field_form={add_field_form}
					handleAddField={handleAddField}
					disabled={tab === "view"}
				/>
			</div>

			{/* Reconciled Table */}
			{
				tab === "view" && (
					<div className="mt-20 mb-10">
						<h3>
							<span>Reconciled With Trade Transactions</span>
						</h3>
						<TableComponent openUnlinkModal={openUnlinkModal} close={close} parentOpenTransViewDrawer={parentOpenTransViewDrawer} openTransViewDrawer={openTransViewDrawer} data={CashTransactionStore.editValues && CashTransactionStore.editValues.child_transactions_journal_logs} />
					</div>
				)
			}
		</Form >
	)
})

export default FormComponent
