import React, { useState } from 'react'
import { Form, Button, Modal, Col, Row } from 'antd'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmNotify, vsmFXCurrencies } from '../../../../config/messages'
import useStore from '../../../../store'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { FXCurrenciesStore: { DeleteData, deleteValues } } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmFXCurrencies.delete
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	// Reset form and close delete form
	const close = () => {
		props.close()
		form.resetFields()
	}

	return deleteValues ? (
		<Modal
			centered
			title={`Delete Currency - #${deleteValues.id} - ${deleteValues.currency_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				<Row align="middle">
					<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
					<Col span={20}>
						<h3>Are you sure you want to remove this Currency?</h3>
					</Col>
				</Row>
			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
