import React from 'react'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import SwitchComponent from './SwitchComponent'
import { DateComparator } from '../../../../../../utils/GlobalFunction'
import useStore from '../../../../../../store';

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal, openViewModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(72, 'edit') &&
				<Button title="View Trade Transaction" type="text" onClick={() => { openViewModal(props.data) }}><EyeOutlined /></Button>
			}
			{
				(props.data.status) ? (
					<>
						{UserStore.checkPermission(72, 'edit') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot edit published trade transaction. Please unpublish the trade transaction first and then edit it."}>
							<Button type="text" onClick={() => { openEditModal(props.data) }} disabled><EditOutlined /></Button>
						</Tooltip>}
						{UserStore.checkPermission(72, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You can not delete published trade transaction. Please unpublish first to delete this trade transaction."}>
							<Button type="text" disabled><DeleteOutlined /></Button>
						</Tooltip>}
					</>
				) : (
					<>
						{UserStore.checkPermission(72, 'edit') &&
							<Button title="Edit Trade Transaction" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
						}
						{UserStore.checkPermission(72, 'delete') &&
							<Button title="Delete Trade Transaction" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
						}
					</>
				)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Trade Transaction',
		field: 'mapping_name',
		width: 150
	}, {
		headerName: 'Friendly Name',
		field: 'friendly_name',
		width: 150
	}, {
		headerName: 'Trade Type',
		field: 'transaction_type.type_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Transaction Type',
		field: 'transaction_sub_type.sub_type_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Associated Fields',
		field: 'association_count',
		filter: false,
		sortable: false,
		width: 80
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Yes", "No"],
			defaultToNothingSelected: true
		},
		width: 120
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
