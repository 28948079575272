import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer, Form } from 'antd'
import UserFormComponent from './UserFormComponent'
import { Steps } from 'antd'
import useStore from '../../../../store'
import BankDetailsComponent from './BankDetailsComponent'
import CreditRisksComponent from './CreditRiskComponent'
import UserDetialsComponent from './UserDetailsComponent'
import moment from 'moment'
const { Step } = Steps

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { MemberStore, MemberStore: { openFormType, JsonToFormData, getPermission, AddMemberOrgDetail, getAllCountries } } = useStore()
	const [stepState, setStepState] = useState([])
	const [saving, setSaving] = useState(false)
	const [isDisabled, setisDisabled] = useState(true)
	const [allFiles, updateAllFiles] = useState({})
	const [currentTab, setCurrentTab] = useState("account")

	const close = () => {
		MemberStore.userPermission = null
		MemberStore.organisationID = null
		MemberStore.openFormType = null
		form.resetFields()
		MemberStore.currentTab = 0
		setSaving(false)
		updateAllFiles({})
		props.close()
	}

	// Submit form
	const handleSubmit = (data) => {
		// Remove unused fields for Originator first step
		if (data) {
			delete data.proof_identity
			delete data.proof_of_address
			delete data.turn_over
			delete data.tex_id_doc
			delete data.tax_id_number
		}
		setSaving(true)
		if (MemberStore.organisationID) {
			data.organisation_id = MemberStore.organisationID
		}
		if (MemberStore.userPermission) {
			if ((openFormType === 'Originator' && MemberStore.currentTab === 3) || (['Intermediary', 'Investor'].includes(MemberStore.openFormType) && MemberStore.currentTab === 2)) {
				data.role_id = MemberStore.userPermission.user && MemberStore.userPermission.user.id && MemberStore.userPermission.user.id
			} else {
				data.role_id = MemberStore.userPermission.org && MemberStore.userPermission.org.id && MemberStore.userPermission.org.id
			}
		}
		if (data.formation_year) {
			data.formation_year = moment(data.formation_year).format("YYYY")
		}
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x.contact_value && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : "[]"
		}
		if (data.email && data.email.length > 0) {
			data.email = data.email.filter(x => x.contact_value && (x.contact_for && x.contact_value))
			data.email = data.email.length > 0 ? JSON.stringify(data.email) : "[]"
		}
		if (data.rating_agency && data.rating_agency.length > 0) {
			data.rating_agency = data.rating_agency.filter(x => x && (x.rating_agency_name && x.rate && x.limit && x.contract && x.long_term && x.short_term))
			data.rating_agency = data.rating_agency.length > 0 ? JSON.stringify(data.rating_agency) : "[]"
		}
		if (data.addresses && data.addresses.length > 0) {
			data.add_address = 1
			data.addresses = data.addresses.filter(x => x && x)
			data.addresses = data.add_address && data.addresses.length > 0 ? JSON.stringify(data.addresses) : "[]"
		} else {
			if (!MemberStore.currentTab) {
				data.add_address = 0
			}
		}
		let actions = {}
		if (data.permissions) {
			data.permissions.map((item) => {
				item.actions.map(action => {
					if (action.is_selected === 1) {
						if (!actions[item.id]) { actions[item.id] = [] }
						actions[item.id].push(action.id)
					}
					return null
				})
				return null
			})
			data.permissions = actions
		}
		data.action = 'add'
		// For delete bank unsed key
		if (MemberStore.currentTab === 1 && data.bank) {
			data.bank.forEach((singleBank) => {
				if (singleBank.tab === 'account') {
					delete singleBank.iban
					delete singleBank.swift_bic
				} else {
					delete singleBank.account_number
					delete singleBank.sort_code
				}
			})
		}
		let formData
		if (MemberStore.currentTab === 0 || (openFormType === 'Originator' && MemberStore.currentTab === 2)) {
			formData = JsonToFormData(data)
		} else {
			formData = data
		}
		Object.keys(allFiles).forEach((item) => {
			if (allFiles[item].length > 0 && allFiles[item][0]) {
				if (MemberStore.currentTab === 0 || (openFormType === 'Originator' && MemberStore.currentTab === 2)) {
					formData.append(item, allFiles[item][0].hasOwnProperty("originFileObj") ? allFiles[item][0].originFileObj : allFiles[item][0])
				}
			}
		})
		AddMemberOrgDetail(formData).then((data) => {
			if ((MemberStore.currentTab === 3 && openFormType === 'Originator') || (['Intermediary', 'Investor'].includes(MemberStore.openFormType) && MemberStore.currentTab === 2)) {
				close()
			} else {
				MemberStore.setCurrentTab()
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors); setisDisabled(true) }
		}).finally(() => setSaving(false))
	}

	let steps = [{
		id: 1,
		title: `${openFormType} Details`,
		content: <UserFormComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} allFiles={allFiles} updateAllFiles={updateAllFiles} />
	}, {
		id: 2,
		title: 'Bank Details',
		content: <BankDetailsComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} currentTab={currentTab} setCurrentTab={setCurrentTab} />
	}, {
		id: 3,
		title: 'Credit & Risks',
		content: <CreditRisksComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} allFiles={allFiles} updateAllFiles={updateAllFiles} />
	}, {
		id: 4,
		title: 'User Details',
		content: <UserDetialsComponent form={form} handleSubmit={handleSubmit} setDisabled={setisDisabled} />
	}]

	useEffect(() => {
		if (openFormType && props.visible) {
			getAllCountries()
			// Call permission API
			let permissionPayload = {
				name: openFormType
			}
			getPermission(permissionPayload)
			// Set form field
			form.setFieldsValue({
				status_id: 1370,
				type: openFormType
			})
			// Dynamic steps (Remove 3 step in Intermediary & Investor)
			if (openFormType !== 'Originator') {
				setStepState(steps.filter((item) => item.id !== 3))
			} else {
				setStepState(steps)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openFormType, allFiles])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			title={'Members Details'}
			placement='right'
			width={'60%'}
			destroyOnClose={true}
			className='member_drawer plus_icon_design'
			footer={[
				<div key="1" className="d-flex justify-content-end">
					{/* <Button form={`${openFormType}-${MemberStore.currentTab}`} className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save {MemberStore.currentTab !== 3 && '& Next'}</Button> */}
					{MemberStore.openFormType === 'Originator' ? <Button form={`${MemberStore.openFormType}-${MemberStore.currentTab}`} className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save {MemberStore.currentTab !== 3 && '& Next'}</Button> :
						<Button form={`${MemberStore.openFormType}-${MemberStore.currentTab}`} className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save {MemberStore.currentTab !== 2 && '& Next'}</Button>}
				  	<Button className="ml-10" onClick={close}>Close</Button>
				</div>
			]}
		>
			<Steps type="navigation" size="small" current={MemberStore.currentTab} className="site-navigation-steps mb-10">
				{stepState.map((item) => (
					<Step key={item.id} title={item.title} />
				))}
			</Steps>
			{stepState.length > 0 && <div className="member_steps">{stepState.length && stepState?.[MemberStore.currentTab]?.content}</div>}
		</Drawer>
	)
})

export default AddComponent