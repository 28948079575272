import React, { useState, useEffect, useMemo } from 'react'
import { Form, Button, Col, Row, Spin, Drawer, Card } from 'antd'
import { observer } from 'mobx-react'
import { PlusCircleTwoTone } from '@ant-design/icons'
import debounce from 'lodash/debounce'
import { vsmOrganisations, vsmNotify, vsmRoleMapping } from '../../../../config/messages'
import useStore from '../../../../store'
import OrgDetailComponent from '../../RoleMapping/component/OrgDetailComponent'
import InputComponent from '../../../../components/InputComponent'
import RatingElement from '../elements/RatingElement'
import PplDetailComponent from '../../../PeopleManagement/RoleMapping/component/PplDetailComponent';

const MapOrgWithLedger = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [hide, setHide] = useState(false)
	const [ratingHide, setRatingHide] = useState(true)
	const [currentTab, setCurrentTab] = useState("Organisation")
	const [searchOrgKey, setSearchOrgKey] = useState()
	const [searchROrgKey, setSearchROrgKey] = useState()
	const [fetchRoles, setFetchRoles] = useState(true)
	const [fetchRelatedRole, setFetchRelatedRole] = useState(true)
	const [fetchTags, setFetchTags] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [role, setRole] = useState(null)
	const [updateState, setUpdateState] = useState(true)
	const { UserStore, UserStore: { user }, PeopleStore, OrgBankAccountStore, RoleMappingStore, RoleMappingStore: { getAllTagsList, dropdown_tags_list },
		OrganisationStore: { getOrgRoles, getRatingToMap, getOrgRelatedRoles, getOrgCardValue, dropdown_RelatedRoles, dropdown_ledger_org_list, dropdown_ROrg_list, dropdown_orgRole_list, MapData, getOrgCurrentLedgerMap, getAllROrg, setOrgListNull, fetching, organisationValues, setOrganisationValues, setROrganisationValues, rOrganisationValues } } = useStore()

	// Handle submit and call function to map new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.entity_type = 1
		data.map_current_ledger = 1
		data.is_rating = ratingHide ? 0 : 1
		if (data.rating_table && data.rating_table.length > 0) {
			data.rating_table = data.rating_table.filter(x => x && (x && x.global_exchange_rating_id))
			data.rating_table = !ratingHide && data.rating_table.length > 0 ? data.rating_table : null
		}
		MapData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmOrganisations.map
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		if (props.visible) {
			setRole(null)
			setOrgListNull(null)
			setOrganisationValues(null)
			setROrganisationValues(null)
			setFetchRoles(true)
			setFetchRelatedRole(true)
			setFetchTags(true)
			setCurrentTab("Organisation")
			getRatingToMap()
			setRatingHide(true)
		}
	}, [props.visible, getRatingToMap, setOrgListNull, setOrganisationValues, setROrganisationValues])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = async () => {
		setUpdateState(!updateState)
		if (form.getFieldValue("related_entity_type") === "Ledger") {
			setHide(true)
		} else {
			setHide(false)
		}
		if (currentTab !== form.getFieldValue("related_entity_type")) {
			await form.resetFields(['related_people_id', 'related_role_id', 'related_entity_id'])
			setCurrentTab(form.getFieldValue("related_entity_type"))
			PeopleStore.rPeopleCardValue = null
			setSearchROrgKey(null)
			setFetchRelatedRole(true)
		}
		if (form.getFieldValue("role_id")) {
			var rating = dropdown_orgRole_list && dropdown_orgRole_list.find(x => x.role_name.toLowerCase() === "rating agency")
			if (rating && form.getFieldValue("role_id") === rating.id) {
				setRatingHide(false)
			} else {
				setRatingHide(true)
			}
		}
		validateData()
	}

	// call to validate fields
	const validateData = debounce(() => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}, 200)

	// search string for dropdown
	const handleSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchOrgKey(val);
			getOrgCurrentLedgerMap({ search_for: val, related_entity_type: 1 })
		};

		return debounce(loadOptions, 500);
	}, [getOrgCurrentLedgerMap])

	// search string for dropdowns
	const handleROrgSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchROrgKey(val);
			getAllROrg({ search_for: val, related_entity_type: 1 })
		};

		return debounce(loadOptions, 500);
	}, [getAllROrg])

	// search string for people dropdown
	const handleRPplSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchROrgKey(val);
			PeopleStore.getPeopleSearchList({ search_for: val, related_entity_type: 2 })
		};

		return debounce(loadOptions, 500);
	}, [PeopleStore])


	// set related people data in card
	const handlePeopleChange = (data) => {
		if (data) {
			PeopleStore.getPeopleCardValue(data, 'people')
		} else {
			PeopleStore.rPeopleCardValue = null
		}
	}

	// get organisation data
	const handleOrgChange = (data) => {
		if (data) {
			getOrgCardValue(data, "org")
		} else {
			setOrganisationValues(null)
		}
	}

	// get related organisation data
	const handleROrgChange = (data) => {
		if (data) {
			getOrgCardValue(data, "relatedOrg")
		} else {
			setROrganisationValues(null)
		}
	}

	const handleRoleChange = () => {
		if (form.getFieldValue("role_id") && form.getFieldValue("related_entity_type") === "Organisation") {
			getOrgRelatedRoles({ role_id: form.getFieldValue("role_id") })
				.then(() => setFetchRelatedRole(false))
			form.resetFields(["related_role_id"])
		}
	}

	const handleChangeRole = () => {
		setRole(form.getFieldValue("role_id"))
		handleRoleChange()
	}

	// reset form and close map form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		setFetchCurrency(true)
		setOrganisationValues(null)
		setROrganisationValues(null)
		RoleMappingStore.dropdown_tags_list = null
		PeopleStore.rPeopleCardValue = null
	}

	return (
		<Drawer
			title="Map Organisation with current Ledger"
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='mapform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div >
			]}
		>
			<Form form={form} id="mapform" onFinish={handleSubmit}
				onChange={validateData}
				autoComplete="off"
				layout='vertical'
			>

				<Row gutter={24}>
					<Col span={12}>
						<Row gutter={24}>
							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Organisation" name="entity_id" placeholder="Organisation"
									onChange={(data) => {
										handleChange(data)
										handleOrgChange(data)
									}}
									options={{
										values: dropdown_ledger_org_list,
										value_key: 'id',
										text_key: 'entity_name',
										rejected_keys: [form.getFieldValue('related_entity_id')]
									}}
									tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
									rules={vsmOrganisations.validation.organisation}
									filterOption={false}
									notFoundContent={fetching ? <Spin size="small" /> : (searchOrgKey) ? "No Record Found!" : null}
									onSearch={handleSearch}
									extra={
										UserStore.checkPermission(35, 'add') && <PlusCircleTwoTone className="ml-10" onClick={() => { props.openAddModal("maporg", form) }} />
									}
								/>
							</Col>

							<Col span={12}>
								<InputComponent
									required type="select" allowClear label="Organisation Role" name="role_id"
									placeholder="Select Organisation Role" onChange={async () => { await handleChangeRole(); handleChange() }}
									options={{
										values: dropdown_orgRole_list,
										value_key: 'id',
										text_key: 'role_name',
										rejected_keys: currentTab !== "Ledger" && [form.getFieldValue('related_role_id')]
									}}
									tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
									rules={vsmOrganisations.validation.organisation_role}
									onFocus={() => fetchRoles && getOrgRoles().then(() => setFetchRoles(false))}
									notFoundContent={fetchRoles ? <Spin size="small" /> : "No Record Found."}
								/>
							</Col>
						</Row>
						{(organisationValues) && (<OrgDetailComponent data={organisationValues} />)}
					</Col>

					<Col span={12}>
						<InputComponent
							required type="radio_button" label="Relate to" name="related_entity_type" initialValue="Organisation"
							onChange={handleChange}
							options={{
								values: [
									{ value: 'Organisation', text: 'Organisation' },
									{ value: 'People', text: 'People' },
									{ value: 'Ledger', text: user.default_ledger_name },
								]
							}}
							tooltip="There will be option to select: Organisation/People/Ledger Name."
							rules={vsmOrganisations.validation.related_entity_type}
						/>
						{
							currentTab === 'Ledger' ? null : (
								currentTab === 'Organisation' ?
									(
										<Row gutter={24}>
											<Col span={12}>
												<InputComponent
													hidden={hide} required type="select" allowClear label="Related Organisation" name="related_entity_id" placeholder="Related Organisation"
													onChange={(data) => {
														handleChange(data)
														handleROrgChange(data)
													}}
													options={{
														values: dropdown_ROrg_list,
														value_key: 'id',
														text_key: 'entity_name',
														rejected_keys: [form.getFieldValue('entity_id')]
													}}
													tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
													rules={vsmOrganisations.validation.related_organisation}
													filterOption={false}
													notFoundContent={fetching ? <Spin size="small" /> : (searchROrgKey) ? "No Record Found!" : null}
													onSearch={handleROrgSearch}
												/>
											</Col>

											<Col span={12}>
												<InputComponent
													hidden={hide} disabled={role ? false : true} required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Select Related Role" onChange={handleChange}
													options={{
														values: dropdown_RelatedRoles,
														value_key: 'id',
														text_key: 'role_name',
														rejected_keys: [form.getFieldValue('role_id')]
													}}
													tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
													rules={vsmOrganisations.validation.related_role}
													onFocus={() => fetchRelatedRole && getOrgRelatedRoles({ role_id: form.getFieldValue("role_id") }).then(() => setFetchRelatedRole(false))}
													notFoundContent={fetchRelatedRole ? <Spin size="small" /> : "No Record Found."}
												/>
											</Col>
											{
												(rOrganisationValues) && (<OrgDetailComponent data={rOrganisationValues} />)
											}
										</Row>
									) : (
										<Row gutter={24}>
											<Col span={12}>
												<InputComponent
													hidden={hide} required type="select" allowClear label="Related People" name="related_entity_id" placeholder="Related People"
													onChange={(data) => {
														handleChange(data)
														handlePeopleChange(data)
													}}
													options={{
														values: PeopleStore.dropdown_RPpl_list,
														value_key: 'id',
														text_key: 'entity_name'
													}}
													tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
													rules={vsmOrganisations.validation.related_people}
													filterOption={false}
													notFoundContent={PeopleStore.fetching ? <Spin size="small" /> : (searchROrgKey) ? "No Record Found!" : null}
													onSearch={handleRPplSearch}
												/>
											</Col>
											<Col span={12}>
												<InputComponent
													hidden={hide} disabled={role ? false : true} required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Select Related Role" onChange={handleChange}
													options={{
														values: PeopleStore.dropdown_pplRole_list,
														value_key: 'id',
														text_key: 'role_name'
													}}
													tooltip="People Role Relation is the connection between the Roles played by two different People."
													rules={vsmOrganisations.validation.related_role}
													onFocus={() => fetchRelatedRole && PeopleStore.getPeopleRoles().then(() => setFetchRelatedRole(false))}
													notFoundContent={fetchRelatedRole ? <Spin size="small" /> : "No Record Found."}
												/>
											</Col>
											{
												PeopleStore.rPeopleCardValue && (<PplDetailComponent data={PeopleStore.rPeopleCardValue} />)
											}
										</Row>
									)
							)
						}
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={8}>
						<InputComponent
							mode="tags" type="select" allowClear label="Tags" name="tag_ids" placeholder="Select one or more tags" onChange={handleChange}
							options={{
								values: dropdown_tags_list,
								value_key: 'id',
								text_key: 'tag_name',
							}}
							tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
							rules={vsmOrganisations.validation.tag_name}
							maxTagTextLength={10}
							tokenSeparators={[',']}
							onFocus={() => fetchTags && getAllTagsList().then(() => setFetchTags(false))}
							notFoundContent={fetchTags ? <Spin size="small" /> : "No Record Found."}
						/>
					</Col>
					<Col span={8}>
						<InputComponent
							label="Exchange Reference Id" name="exchange_ref_id" placeholder="Exchange Reference Id" onChange={handleChange}
							tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
							rules={vsmOrganisations.validation.exchange_ref_id}
							maxLength={100}
						/>
					</Col>
					<Col span={8}>
						<InputComponent
							label="Supplier Insurance No." name="supplier_insurance_no" placeholder="Supplier Insurance Number" onChange={handleChange}
							tooltip="Supplier Insurance No."
							rules={vsmRoleMapping.validation.Insurance}
							maxLength={100}
						/>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={8}>
						<InputComponent
							label="Agreed Early Payment Discount" name="agreed_early_payment_discount" placeholder="Agreed Early Payment Discount" onChange={handleChange}
							tooltip="A discount that buyers can receive in exchange for paying invoices early."
							rules={vsmRoleMapping.validation.Discount}
							maxLength={100}
						/>
					</Col>
					<Col span={8}>
						<InputComponent
							allowClear type="select" label="Currency" name="currency_id" placeholder="Select Organisation Currency"
							onChange={handleChange}
							tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
							onFocus={() => (fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false)))}
							notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
							options={{
								values: OrgBankAccountStore.dropdown_currency_list,
								value_key: "id",
								text_key: "currency_name"
							}}
						/>
					</Col>
					<Col span={8}>
						<InputComponent
							label="CRM ID" name="crm_id" placeholder="Enter CRM Refernce ID" onChange={handleChange}
							tooltip="CRM ID"
							rules={vsmRoleMapping.validation.crm}
							maxLength={100}
						/>
					</Col>
				</Row>

				{
					ratingHide ? null : (
						<>
							<Card title="Rating Table" className="mb-25">
								<RatingElement form={form} onChange={validateData} />
							</Card>
							<InputComponent
								required allowClear type="textarea" label="Description" name="additional_description" placeholder="Description"
								onChange={validateData} rules={vsmOrganisations.validation.description} maxLength={100}
								tooltip="The purpose of describing the organisation is to provide; an understanding of mission, goals, & objectives, and clarify the focus and importance of the organisation's effort."
							/>
						</>
					)
				}
			</Form>
		</Drawer >
	)
})
export default MapOrgWithLedger
