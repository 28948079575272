import React from 'react';
import { observer } from 'mobx-react';
import useStore from '../../../../store';
import { Button, Form, Modal } from 'antd';

const ViewComponent = observer((props) => {
    const [form] = Form.useForm()
    const { MailTemplateStore } = useStore()

    const close = () => {
        form.resetFields()
        props.close()
        MailTemplateStore.setReadData(null)
    }

    const createMarkup = () => {
        return { __html: MailTemplateStore.read_data.email_header + MailTemplateStore.read_data.email_body + MailTemplateStore.read_data.email_footer }
    }

    return MailTemplateStore.read_data && (
        <Modal
            centered
            visible={props.visible}
            onCancel={close}
            placement='right'
            title={`View Mail # ${MailTemplateStore.read_data.id} - ${MailTemplateStore.read_data.template_name}`}
            destroyOnClose={true}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            footer={[
                <div className="text-center">
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <h2>{MailTemplateStore.read_data.email_subject}</h2>
            <div dangerouslySetInnerHTML={createMarkup()} />
        </Modal>
    );
})

export default ViewComponent;