import Axios from "axios"
import { decorate, observable, action } from "mobx"
import moment from "moment";
import GridConfig from "../../config/GridConfig"
import { convertTextToID, globalStatus } from "../../utils/GlobalFunction";

export default class CashTransactionStore {
	list_data = null
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	list_data_local = null

	editValues = null
	deleteValues = null
	agGrid = null
	dropdown_list = null
	ImportId = null
	agGridLocal = null
	agGridServer = null
	exportLoading = false
	listLoading = true
	advanceFilter = null
	filterData = null
	list_status = 200
	loader = false

	filter_bank = []
	filter_transaction_type = []
	filter_currency = []
	filter_indentification_catagory = []
	filter_org_ppl = []
	filter_role = []
	filter_relatd_org_ppl = []
	filter_related_role = []

	getBankFilter = () => {
		return Axios.get(`getBankFilter`).then(data => {
			this.filter_bank = data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getTransactionTypesFilter = () => {
		return Axios.get(`cash/transaction/filter/get`).then(data => {
			this.filter_transaction_type = data.data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getCurrencyFilter = () => {
		return Axios.get(`getCurrencyFilter`).then(data => {
			this.filter_currency = data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getCatagoryFilter = () => {
		return Axios.get(`cash/transaction/filter/class/get`).then(data => {
			this.filter_indentification_catagory = data.data.data
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getOrgPplFilter = (payload) => {
		return Axios.get(`trade/cash/filter/entity/get/${payload.name}/${payload.type}`).then(data => {
			if (payload.type === 1) {
				this.filter_org_ppl = data.data.data
			}
			if (payload.type === 2) {
				this.filter_relatd_org_ppl = data.data.data
			}
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	getRoleFilter = (payload) => {
		return Axios.get(`trade/cash/filter/role/get/${payload.name}/${payload.type}`).then(data => {
			if (payload.type === 1) {
				this.filter_role = data.data.data
			}
			if (payload.type === 2) {
				this.filter_related_role = data.data.data
			}
			return data
		}).catch(data => {
			Promise.reject(data)
		})
	}

	// set form values to edit / view
	setEditValues = (data) => {
		this.editValues = data
	}

	// set id for import transaction
	setImportId = (data) => {
		this.ImportId = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	// Setup grid for local
	setupGridLocal = (params) => {
		this.agGrid = this.agGridLocal = params
		let columnConfig = localStorage.getItem('cte_transaction_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('cte_transaction_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	formatAdvanceFilter = (data) => {
		data.exchange_ref_id = data.exchange_ref_id && [parseInt(data.exchange_ref_id)]
		data.customer_reference = data.customer_reference.filter(x => x);
		data.bank_reference = data.bank_reference.filter(x => x);
		data.cte_fields = data.cte_fields.filter(x => x && x.id && x.value);

		var isValid = false
		Object.keys(data).forEach(key => {
			if (data[key]) {
				if (Array.isArray(data[key])) {
					if (data[key].length > 0) {
						isValid = true
					}
				} else {
					isValid = true
				}
			}
		})

		return { data, isValid }
	}

	getListLocal = (data = this.formatAdvanceFilter(this.advanceFilter) || {}) => {
		data.import_id = this.ImportId ? [this.ImportId] : []
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
			this.agGrid.api.showLoadingOverlay();
		}
		return Axios.post(`cash/transaction/advancesearch`, data).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.srno = index + 1
					item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
				});
			}
			this.list_data_local = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('cte_transaction_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid && this.agGrid.columnApi && data.total) {
					this.agGrid.api.setFilterModel(filter)
					this.agGrid.columnApi.applyColumnState({ state: sort })
				}
			}
			if (this.agGrid && this.agGrid.api) {
				if (data.total > 0) { this.agGrid.api.hideOverlay(); }
				if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
				if (name === "general") {
					this.advanceFilter = null
					this.setupGrid(this.agGridServer)
				}
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = this.agGridServer = params
		const { api } = params
		let columnConfig = localStorage.getItem('cte_transaction_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	// reset all the server side filters
	handleReset = () => {
		this.advanceFilter = null
		localStorage.removeItem('cte_transaction_grid')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel
		var final_sort = params.sortModel
		if (final_filter.status_name) {
			var values_changed = []
			final_filter.status_name.values.forEach(x => (
				values_changed.push(globalStatus('cash_transaction_status', 'value', x))
			))
			final_filter.status = final_filter.status_name
			final_filter.status.values = values_changed
			delete final_filter.status_name
		}
		if (final_filter.reconciliation_status_table) {
			var value_changed = []
			final_filter.reconciliation_status_table.values.forEach(x => (
				value_changed.push(globalStatus('reconcile_transaction_status', 'value', x))
			))
			final_filter.reconciliation_status = final_filter.reconciliation_status_table
			final_filter.reconciliation_status.values = value_changed
			delete final_filter.reconciliation_status_table
		}
		final_sort.forEach((x => {
			if (x.colId === 'status_name') {
				x.colId = 'status'
			}
			if (x.colId === 'reconciliation_status_table') {
				x.colId = 'reconciliation_status'
			}
		}))
		return { final_filter, final_sort }
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('cte_transaction_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				var filter_data = this.changeFilterAndSort(params.request)

				if (filter_data.final_filter['ledgers_bank.name']) {
					filter_data.final_filter['ledgers_bank.name'].values = convertTextToID(filter_data.final_filter['ledgers_bank.name'], this.filter_bank, 'name', 'id')
				}

				if (filter_data.final_filter['cash_transactions_types.type_name']) {
					filter_data.final_filter['cash_transactions_types.type_name'].values = convertTextToID(filter_data.final_filter['cash_transactions_types.type_name'], this.filter_transaction_type, 'type_name', 'id')
				}

				if (filter_data.final_filter['global_currency.currency_name']) {
					filter_data.final_filter['global_currency.currency_name'].values = convertTextToID(filter_data.final_filter['global_currency.currency_name'], this.filter_currency, 'currency_name', 'id')
				}

				if (filter_data.final_filter['cte_transaction_classifications.transaction_class_name']) {
					filter_data.final_filter['cte_transaction_classifications.transaction_class_name'].values = convertTextToID(filter_data.final_filter['cte_transaction_classifications.transaction_class_name'], this.filter_indentification_catagory, 'transaction_class_name', 'id')
				}

				if (filter_data.final_filter['entity_name']) {
					filter_data.final_filter['entity_name'].values = convertTextToID(filter_data.final_filter['entity_name'], this.filter_org_ppl, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['role_name']) {
					filter_data.final_filter['role_name'].values = convertTextToID(filter_data.final_filter['role_name'], this.filter_role, 'entity_role', 'role_id')
				}

				if (filter_data.final_filter['related_entity_name']) {
					filter_data.final_filter['related_entity_name'].values = convertTextToID(filter_data.final_filter['related_entity_name'], this.filter_relatd_org_ppl, 'entity_name', 'entity_id')
				}

				if (filter_data.final_filter['related_role_name']) {
					filter_data.final_filter['related_role_name'].values = convertTextToID(filter_data.final_filter['related_role_name'], this.filter_related_role, 'entity_role', 'role_id')
				}

				var payload = {
					filter_type: localStorage.getItem("cash_transaction_related_org_filter") ? 1 : 0,
					import_id: this.ImportId,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}

				if (localStorage.getItem("cash_transaction_related_org_filter")) {
					if (!payload.filter_data["related_entity_name"]) {
						payload.filter_data["related_entity_name"] = { "values": [localStorage.getItem("cash_transaction_related_org_filter")], "filterType": "set" }
					}
					if (!payload.filter_data["entity_name"]) {
						payload.filter_data["entity_name"] = { "values": [localStorage.getItem("cash_transaction_related_org_filter")], "filterType": "set" }
					}
				}

				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay(); }
					params.successCallback(data.data, data.total)
					if (this.agGrid && this.agGrid.columnApi) {
						let columnConfig = localStorage.getItem('cte_transaction_grid')
						if (columnConfig) {
							if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
								this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
							}
						}
					}
					localStorage.removeItem("cash_transaction_related_org_filter")
				})
			}
		}
	}

	// call api to get detail from selected tag
	getTagDetail = (formdata) => {
		return Axios.post(`cash/transaction/related/data`, formdata).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get record detail
	getRecordById = (formdata) => {
		return Axios.post(`cash/transaction/read/${formdata.id}`).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = (payload = {}) => {
		this.listLoading = true
		return Axios.post(`cash/transaction/list`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.srno = index + 1
					item.status_name = globalStatus('cash_transaction_status', 'key', item.status)
					item.reconciliation_status_table = globalStatus('reconcile_transaction_status', 'key', item.reconciliation_status)
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			this.listLoading = false
			return data
		}).catch(({ response: { data } }) => {
			if (data.status === 404) {
				this.list_status = data.status
			}
			return Promise.reject(data)
		})
	}

	// export data from server as a csv
	exportData = () => {
		if (this.agGrid) {
			this.exportLoading = true;
			var final_filter = this.agGrid.api.getFilterModel()
			if (final_filter.status_name) {
				var values_changed = []
				final_filter.status_name.values.forEach(x => (
					values_changed.push(globalStatus('cash_transaction_status', 'value', x))
				))
				final_filter.status = final_filter.status_name
				final_filter.status.values = values_changed
				delete final_filter.status_name
			}
			var payload = {
				import_id: this.ImportId,
				filter_data: final_filter,
				sort_data: this.agGrid.api.getSortModel(),
				per_page: this.per_page,
				page: parseInt(this.agGrid.api.paginationProxy.currentPage + 1)
			}
			return Axios.post(`cash/transaction/export`, payload).then(({ data }) => {
				if (data.data && data.data.csv_link) { window.open(data.data.csv_link, '_blank'); }
				return data
			}).catch(e => {
				return Promise.reject(e)
			}).finally(() => this.exportLoading = false)
		}
	}

	// export data as a csv client side
	exportCilentSideData = async () => {
		this.exportLoading = true;
		var params = {
			columnKeys: ['id', 'posted_on', 'transaction_details', 'cash_transactions_types.type_name', 'debit', 'credit', 'exchange_ref_id', 'entity_name', 'role_name', 'related_entity_name', 'related_role_name', 'status_name'],
			fileName: '1-CashTransaction-' + moment().format("YYYY-MM-DD hh:mm:s"),
		};
		await this.agGrid.api.paginationSetPageSize(this.total)
		await this.agGrid.api.exportDataAsCsv(params)
		await this.agGrid.api.paginationSetPageSize(this.per_page)
		this.exportLoading = false;
	}

	// Call add api to insert new record
	AddData = (formdata) => {
		return Axios.post(`cash/transaction/add`, formdata).then(({ data }) => {
			if (this.agGrid) {
				if (this.advanceFilter) { this.getListLocal() }
				else { this.setupGrid(this.agGrid) }
			} return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`cash/transaction/update/${formdata.id}`, formdata).then(({ data }) => {
			if (this.agGrid) {
				if (this.advanceFilter) { this.getListLocal() }
				else { this.setupGrid(this.agGrid) }
			} return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata, reconcileGrid = null) => {
		return Axios.get(`cash/transaction/delete/${formdata.id}`).then(({ data }) => {
			if (this.agGrid) {
				if (this.advanceFilter) { this.getListLocal() } else {
					this.setupGrid(this.agGrid)
				}
			}
			if (reconcileGrid) {
				reconcileGrid()
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	PendingReconcile = () => {
		this.loader = true
		return Axios.get(`/cash/transaction/auto/reconciliation`).then(({ data }) => {
			this.loader = false
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

}

decorate(CashTransactionStore, {
	list_data: observable,
	list_data_local: observable,
	list_status: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	deleteValues: observable,
	agGrid: observable,
	editValues: observable,
	ImportId: observable,
	dropdown_list: observable,
	advanceFilter: observable,
	filterData: observable,
	exportLoading: observable,
	listLoading: observable,
	agGridLocal: observable,
	agGridServer: observable,
	filter_bank: observable,
	filter_transaction_type: observable,
	filter_currency: observable,
	filter_indentification_catagory: observable,
	filter_org_ppl: observable,
	loader: observable,
	getList: action,
	getRecordById: action,
	EditData: action,
	setDeleteValues: action,
	exportData: action,
	DeleteData: action,
	setupGrid: action,
	onGridChanged: action,
	setPageSize: action,
	setEditValues: action,
	getTagDetail: action,
	handleListData: action,
	setupGridLocal: action,
	onFilterChanged: action,
	getListLocal: action,
	setImportId: action,
	getTransactionTypesFilter: action,
	getBankFilter: action,
	getCurrencyFilter: action,
	getCatagoryFilter: action,
	getOrgPplFilter: action,
	formatAdvanceFilter: action,
	exportCilentSideData: action,
	handleReset: action,
	PendingReconcile: action,
})
