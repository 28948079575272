import React, { useState } from 'react'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import ListComponent from './component/ListComponent'
import { observer } from 'mobx-react-lite'
import { PageHeader } from 'antd';
import useStore from '../../../store';
import RecordPerPage from '../../../components/RecordPerPage';
import EnterFullscreen from '../../../components/EnterFullscreen';
import AuthETRComponent from './component/AuthETRComponent';
import EditETRComponent from './component/EditETRComponent';
import ViewETRComponent from './component/ViewETRComponent';

const AllETR = observer(() => {
	const { UserStore, AllETRStore: { per_page, setPageSize, viewETR } } = useStore()
	const [authETRTrade, setAuthETRTrade] = useState(false)
	const [editETRTrade, setEditETRTrade] = useState(false)
	const [viewETRTrade, setviewETRTrade] = useState(false)

	const openEditTradeDrawer = (data) => {
		setEditETRTrade(true)
	}
	const closeEditTradeDrawer = () => {
		setEditETRTrade(false)
	}

	const openAuthTradeDrawer = (id) => {
		viewETR(id).then(() => {
			setAuthETRTrade(true)
		})
	}
	const closeAuthTradeDrawer = () => {
		setAuthETRTrade(false)
	}

	const viewTradeDrawer = (id) => {
		viewETR(id).then(() => {
			setviewETRTrade(true)
		})
	}
	const closeViewTradeDrawer = () => {
		setviewETRTrade(false)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.allETR.path} />
			<PageHeader
				className={UserStore.fullscreen_class}
				title={`${BreadcrumbConfig.allETR.title}`}
				extra={[
					<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
					<EnterFullscreen key='5' />
				]}
			>
				<ListComponent openEditTrade={openEditTradeDrawer} openAuthTrade={openAuthTradeDrawer} openViewTrade={viewTradeDrawer} />
				<AuthETRComponent visible={authETRTrade} close={closeAuthTradeDrawer} />
				<EditETRComponent visible={editETRTrade} close={closeEditTradeDrawer} />
				<ViewETRComponent visible={viewETRTrade} close={closeViewTradeDrawer} />
			</PageHeader>
		</>
	)
})

export default AllETR;
