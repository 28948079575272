import React from 'react'
import Moment from 'moment'
import NumberFormat from 'react-number-format'
import Axios from 'axios'


export const AppAxios = Axios.create()
export const OriginatorURL = 'https://cm-apps.silicontechnolabs.com/' // CM panel URL

export const DateComparator = (filterLocalDateAtMidnight, cellValue) => {
	//using moment js
	var dateAsString = Moment(cellValue).format('DD/MM/YYYY')
	var dateParts = dateAsString.split("/")
	var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
		return 0
	}

	if (cellDate < filterLocalDateAtMidnight) {
		return -1
	}

	if (cellDate > filterLocalDateAtMidnight) {
		return 1
	}
}

export const app_flag = {
	0: "Credebt-Machine",
	1: "Originator app",
	2: "Both"
}

export const PO_Status = {
	1: 'Draft',
	2: 'Awaiting Approval',
	3: 'Approved',
	4: 'Rejected'
}

export const etr_type = {
	1: 'a-ETR',
	2: 'b-ETR',
	3: 'c-ETR',
	4: 'd-ETR',
	5: 'f-ETR',
	6: 'Rsa'
}

export const globalStatus = (module_name, param_type, param) => {
	let cash_transaction_status = {
		0: 'Pending',
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified'
	}
	let import_etr_status = {
		0: 'Error',
		1: 'Draft',
		2: 'Awaiting Approval',
		3: 'Approved',
		4: 'Rejected',
		5: 'Problematic / Identified'
	}
	let reconcile_status = {
		0: 'Pending(Identified)',
		1: 'Partially Reconciled',
		2: 'Fully Reconciled'
	}
	let cash_import = {
		1: 'Import Success',
		2: 'Partial Import',
		3: 'Pending',
		4: 'In Progress',
		5: 'Failed',
		6: 'Cancel',
		7: 'Delete',
	}
	let cash_import_log = {
		1: 'Imported / added',
		2: 'Auto Identified',
		3: 'Manually Verified',
		4: 'Problematic / Unidentified',
		5: 'Problematic / Identified',
	}
	let reconcile_transaction_status = {
		0: "Pending(Identified)",
		1: "Partially Reconciled",
		2: "Fully Reconciled"
	}
	let ppsn_document_type = {
		1: 'Legal Correspondence',
		2: 'P21 (Tax Balancing Statement)',
		3: 'Tax Assessment',
		4: 'Notice of Tax Credits',
		5: 'Payslip',
		6: 'P60',
		7: 'P45'
	}
	let trade_list_status = {
		1: 'Draft',
		2: 'Awaiting Approval',
		3: 'Approved',
		4: 'Rejected',
	}
	switch (module_name) {
		case 'cash_transaction_status':
			return getStatusValue(cash_transaction_status, param_type, param)
		case 'reconcile_status':
			return getStatusValue(reconcile_status, param_type, param)
		case 'cash_import':
			return getStatusValue(cash_import, param_type, param)
		case 'cash_import_log':
			return getStatusValue(cash_import_log, param_type, param)
		case 'reconcile_transaction_status':
			return getStatusValue(reconcile_transaction_status, param_type, param)
		case 'import_etr_status':
			return getStatusValue(import_etr_status, param_type, param)

		case 'ppsn_document_type':
			return getStatusValue(ppsn_document_type, param_type, param)

		case 'trade_list_status':
			return getStatusValue(trade_list_status, param_type, param)

		default:
			return null
	}
}

const getStatusValue = (array, param_type, param) => {
	if (param_type === 'key') {
		return array[param]
	} else {
		return parseInt(Object.keys(array).find((x) => array[x] === param ? x : false))
	}
}

export const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
	)
}

export const convertTextToID = (text_array, main_array, text_key, id_key) => {
	let new_array = []
	if (text_array && text_array.values && text_array.values.length > 0) {
		text_array.values.forEach(x => {
			var temp = main_array.find(y => y[text_key] === x)
			if (x && temp) { new_array.push(temp[id_key]) } else {
				insertAt(new_array, 0, x)
			}
		})
	}
	return new_array
}

function insertAt(array, index, ...elementsArray) {
	array.splice(index, 0, ...elementsArray);
}

// module path
export const relatedOrgPath = "/organisations/all-organisations/role-mapping"
export const relatedPeoplePath = "/people/all-people/role-mapping"
export const orgBanksPath = "/organisations/all-organisations/bank-accounts"
export const peopleBanksPath = "/people/all-people/bank-accounts"
export const allTransactionPath = "/trade-transactions/all-transactions"
export const cashTransactionPath = "/cash-transactions/all-transaction"
export const peoplePhotoURL = process.env.React_APP_API_URL + 'storage/peoplephoto/'

export const convertError = (formatedErrors) => {
	formatedErrors.forEach((x) => {
		if (x.name.indexOf(".") !== -1) {
			x.name = x.name.split(".");
			x.name.forEach((e, i) => {
				if (!isNaN(parseInt(e))) {
					x.name[i] = parseInt(e);
				}
			});
		}
	});
	return formatedErrors
}

export const ETRList = [
	{ text: 'a-ETR', value: 1 },
	{ text: 'b-ETR', value: 2 },
	{ text: 'c-ETR', value: 3 },
	{ text: 'd-ETR', value: 4 },
	{ text: 'f-ETR', value: 5 },
	{ text: 'Rsa', value: 6 },
]

export const etrTypeObject = {
	1: 'a-ETR',
	2: 'b-ETR',
	3: 'c-ETR',
	4: 'd-ETR',
	5: 'f-ETR',
	6: 'Rsa',
}

export const dETRwithoutBETR = { bko_po_id: 0, friendly_name: "Without b-etr" }
export const cETRwithoutBETR = { id: 0, friendly_name: "Without b-etr" }

// export const PoDInvoiceRequired = [1, 3, 4, 5, 6];

export const rsaETRType = [6];

export const afETR = [1, 5];

export const cdETR = [3, 4];

export const bETR = [2];

export const apiURL = 'http://credebt.silicontechnolabs.com:8001/';

export const ETR_Statuses = {
	1: "Finished",
	2: "Partially Finished",
	3: "In Queue",
	4: "In Progress",
	5: "Failed with Error",
	6: "Cancelled",
	7: "Deleted"
}


export const redIconStatus = [2, 5, 6, 7];

export const redIconViewLogsStatus = [0];

export const MemeberType = [
	{ value: 'Originator', text: 'Originator' },
	{ value: 'Intermediary', text: 'Intermediary' },
	{ value: 'Investor', text: 'Investor' },
]

export const CredebtorType = [
	{ value: 'Creditor', text: 'Creditor' },
	{ value: 'Debtor', text: 'Debtor' }	
]

export const defaultMemberStatus = { id: 1370, status_name: "Authorised" }


export const Credebtor_Credit_Risks = [{
	title: 'Face Value Change %',
	columns_field: [
		{ name: 'face_value_change_a_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_b_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_c_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_d_etr', placeholder: 'Face Value Change', },
		{ name: 'face_value_change_f_etr', placeholder: 'Face Value Change', },
	]
}, {
	title: 'Agent Commission %',
	columns_field: [
		{ name: 'agent_commission_a_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_b_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_c_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_d_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_f_etr', placeholder: 'Agent Commission' }
	]
}, {
	title: 'Trade Limit',
	columns_field: [
		{ name: 'trade_limit_a_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_b_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_c_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_d_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_f_etr', placeholder: 'Trade Limit', validation: 'number' },
	]
}, {
	title: 'LDC Premium &',
	columns_field: [
		{ name: 'ldc_premium_a_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_b_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_c_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_d_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_f_etr', placeholder: 'LDC Premium' },
	]
}]

export const Credit_Risks = [{
	title: 'Sell Rate %',
	columns_field: [
		{ label: 'a-ETR', name: 'sell_rate_a_etr', placeholder: 'Sell Rate %' },
		{ label: 'b-ETR', name: 'sell_rate_b_etr', placeholder: 'Sell Rate %' },
		{ label: 'c-ETR', name: 'sell_rate_c_etr', placeholder: 'Sell Rate %' },
		{ label: 'd-ETR', name: 'sell_rate_d_etr', placeholder: 'Sell Rate %' },
		{ label: 'f-ETR', name: 'sell_rate_f_etr', placeholder: 'Sell Rate %' },
	]
}, {
	title: 'Face Value Change %',
	columns_field: [
		{ name: 'face_value_change_a_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_b_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_c_etr', placeholder: 'Face Value Change' },
		{ name: 'face_value_change_d_etr', placeholder: 'Face Value Change', },
		{ name: 'face_value_change_f_etr', placeholder: 'Face Value Change', },
	]
}, {
	title: 'Agent Commission %',
	columns_field: [
		{ name: 'agent_commission_a_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_b_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_c_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_d_etr', placeholder: 'Agent Commission' },
		{ name: 'agent_commission_f_etr', placeholder: 'Agent Commission' }
	]
}, {
	title: 'Trade Limit',
	columns_field: [
		{ name: 'trade_limit_a_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_b_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_c_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_d_etr', placeholder: 'Trade Limit', validation: 'number' },
		{ name: 'trade_limit_f_etr', placeholder: 'Trade Limit', validation: 'number' },
	]
}, {
	title: 'LDC Premium &',
	columns_field: [
		{ name: 'ldc_premium_a_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_b_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_c_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_d_etr', placeholder: 'LDC Premium' },
		{ name: 'ldc_premium_f_etr', placeholder: 'LDC Premium' },
	]
}]