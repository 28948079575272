import { Drawer, Form, Steps } from 'antd'
import React, { useState } from 'react'
import useStore from '../../../../store'
import BankDetails from './BankDetails'
import CredebtorDetails from './CredebtorDetails'
import CreditRisk from './CreditRisk'

const EditComponent = (props) => {
const [fileList, setFileList] = useState([])
const { CredebtorStore: { currentTab } } = useStore()
const [disabled, setisDisabled] = useState(true)
const [form] = Form.useForm()
const { Step } = Steps;

const close = () => {
	props.setEditDrawer(false)
}


const submitForm = () => {


}

let steps = [
	{
	id: 1,
	title: 'Credebtors Details',
	content: <CredebtorDetails form={form} edit={props.visible} CloseComponent={close} submitForm={submitForm} disabled={disabled} setisDisabled={setisDisabled} setFileList={setFileList} fileList={fileList} />
	},
	{
	id: 2,
	title: 'Bank Details',
	content: <BankDetails form={form} CloseComponent={close} submitForm={submitForm} disabled={disabled} setDisabled={setisDisabled} currentTab={currentTab} />
	},
	{
	id: 3,
	title: 'Credit & Risk Details',
	content: <CreditRisk form={form} />
	},
]

return (
	<Drawer className='member_drawer plus_icon_design' visible={props.visible} onClose={close} width={'60%'} title="Credebtor Details">
	<Steps type="navigation" size="small" current={currentTab} className="site-navigation-steps mb-10">
		{steps.map((item) => (
		<Step key={item.id} title={item.title} />
		))}
	</Steps>
	{steps.length > 0 && <div className="member_steps">{steps.length && steps?.[currentTab]?.content}</div>}
	</Drawer>
)
}

export default EditComponent