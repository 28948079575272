import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from "../../../config/LocalGridConfig"

export default class AlgorithmsStore {
	list_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	testValues = null
	deleteValues = null
	statusValues = null
	agGrid = null

	dropdown_list = null
	unpublish_list = null
	publish_list = null
	transaction_field_list = null

	// set form values to test
	setTestValues = (data) => {
		this.testValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		if (data.association_count > 0) {
			this.getAllRecords()
		}
		this.deleteValues = data
	}

	// set form values to edit
	setStatusValues = (data) => {
		this.statusValues = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('algorithm_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('algorithm_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// call api to get all types list
	getAllRecords = () => {
		Axios.get(`algorithm/getAlgorithmlist`).then(({ data }) => {
			this.dropdown_list = data.data
		})
	}

	// call api to get all transaction fields list
	getAllTransactionFields = () => {
		return Axios.get(`gettransactionfieldlist`).then(({ data }) => {
			this.transaction_field_list = data.data
			return data.data
		})
	}

	// call api tp get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.post(`algorithm/list`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.association_count = item.transaction_model_count || 0
					item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
					item.srno = index + 1
					item.published = item.status ? "Yes" : "No"
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page

			let columnConfig = localStorage.getItem('algorithm_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid && this.agGrid.columnApi && data.total) {
					this.agGrid.columnApi.applyColumnState({ state: sort })
				}
			}
			if (this.agGrid && this.agGrid.api) {
				this.agGrid.api.setFilterModel(filter)
			}

			return data
		})
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call to get record particular ID
	getRecordById = (formdata) => {
		return Axios.get(`algorithm/${formdata.id}`).then(({ data }) => {
			return data.data === null ? Promise.reject(data) : data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`algorithm/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`algorithm/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`algorithm/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// call api to get all types list
	getUnpublishRelation = (formdata) => {
		Axios.get(`algorithm/getAllAssociatedDataList/${formdata.id}`).then(({ data }) => {
			this.unpublish_list = data.data
		})
	}

	// call api to get all types list
	getPublishRelation = (formdata, handlePublish, setPublishModal) => {
		Axios.get(`algorithm/getAllAssociatedUnPublishedDataList/${formdata.id}`).then(({ data }) => {
			this.publish_list = data.data
			if (data && data.count === 0) {
				handlePublish(formdata)
			} else {
				this.agGrid.api.refreshCells({ force: true })
				setPublishModal(true)
			}
		})
	}

	// Call unpublish/publish api
	TogglepublishData = (formdata) => {
		return Axios.post(`algorithm/status/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	fields = []
	readable_format = ''
	formula_status = 'success'
	custom_error_msg = 'Parse Success'

	createReadableFormula = (formula) => {
		this.readable_format = ''
		this.formula_status = 'success'
		this.fields = []
		this.custom_error_msg = 'Parse Success'
		return {
			formula: this.startCreatingReadableFormula(formula),
			status: this.formula_status,
			fields: this.fields
		}
	}

	startCreatingReadableFormula = (formula) => {
		if (formula && formula.component) {
			switch (formula.component) {
				case 'IF':
					return (`IF(${this.startCreatingReadableFormula(formula.child[0])}, ${this.startCreatingReadableFormula(formula.child[1])}, ${this.startCreatingReadableFormula(formula.child[2])})`)

				case 'ELSE IF':
					return (`ELSEIF(${this.startCreatingReadableFormula(formula.child[0])}, ${this.startCreatingReadableFormula(formula.child[1])}, ${this.startCreatingReadableFormula(formula.child[2])})`)


				case 'AND':
					return `AND(${formula.child.map(item => {
						return this.startCreatingReadableFormula(item)
					}).join(', ')})`

				case 'OR':
					return `OR(${formula.child.map(item => {
						return this.startCreatingReadableFormula(item)
					}).join(', ')})`

				case 'Not':
					return `NOT(${this.startCreatingReadableFormula(formula.operand1)})`

				case 'Maximum':
					if (formula.child.length <= 0) {
						this.formula_status = 'error'
					}
					if (formula && (formula.block === 'If-Condition' || formula.block === 'Logical-Block')) {
						return `MAX(${formula.child.map(item => {
							return this.startCreatingReadableFormula(item)
						}).join(', ')}) ${formula.operator.value} ${this.startCreatingReadableFormula(formula.operand2)}`
					} else {
						return `MAX(${formula.child.map(item => {
							return this.startCreatingReadableFormula(item)
						}).join(', ')})`
					}

				case 'Minimum':
					if (formula.child.length <= 0) {
						this.formula_status = 'error'
					}
					if (formula && (formula.block === 'If-Condition' || formula.block === 'Logical-Block')) {
						return `MIN(${formula.child.map(item => {
							return this.startCreatingReadableFormula(item)
						}).join(', ')}) ${formula.operator.value} ${this.startCreatingReadableFormula(formula.operand2)}`
					} else {
						return `MIN(${formula.child.map(item => {
							return this.startCreatingReadableFormula(item)
						}).join(', ')})`
					}


				case 'Is Blank':
					return `is_blank(${this.startCreatingReadableFormula(formula.operand1)})`

				case 'Formula':
					return `${this.startCreatingReadableFormula(formula.operand1)} ${formula.operator.value} ${this.startCreatingReadableFormula(formula.operand2)}`

				case 'Value':
					return this.startCreatingReadableFormula(formula.value)

				case 'Custom':
					let invInput = /^(?!.*([.-]).*\1)[.A-Za-z0-9- ]+$/
					if (formula.value === null || (typeof (formula.value) === 'string' && formula.value.trim() === '') || formula.value === undefined) {
						this.formula_status = 'error'
						this.custom_error_msg = 'Parse Error'
						return ''
					}
					else if (!invInput.test(formula.value)) {
						this.formula_status = 'error'
						this.custom_error_msg = 'It appears you forgot to enter some Custom Values. Please complete the missing information to proceed.'
						return ''
					}
					return typeof (formula.value) === 'string' ? formula.value.trim() : formula.value
				case 'Transaction Fields':
					if (formula.value === null || formula.value === '' || formula.value === undefined) {
						this.formula_status = 'error'
						this.custom_error_msg = 'Parse Error'
						return ''
					}
					if (!this.fields.includes(formula.value)) {
						this.fields.push(formula.value)
					}
					if (this.transaction_field_list) {
						var field = this.transaction_field_list.find(x => x.id === formula.value)
						if (field) {
							return `'${field.field_name}'`
						}
					}
					this.formula_status = 'error'
					this.custom_error_msg = 'Parse Error'
					return ''

				case 'Arithmetic Formula':
					if (formula.value === null || formula.value === '' || formula.value === undefined || formula.value === 'token is empty') {
						this.formula_status = 'error'
						this.custom_error_msg = 'Parse Error'
						return ''
					}
					if (typeof (formula.value) === 'string') {
						this.formula_status = 'error'
						this.custom_error_msg = formula.value
						return ''
					}
					return this.getParsedArithmeticFormula(formula.value)

				default: return ''
			}
		}
	}

	getParsedArithmeticFormula = (formula) => {
		if (formula.operator) {
			if (formula.operand1.value && formula.operand1.value.unit === 0 && formula.operator === '-') {
				return `( ${formula.operator} ${this.getParsedArithmeticFormula(formula.operand2)} )`
			}
			return `( ${this.getParsedArithmeticFormula(formula.operand1)} ${formula.operator} ${this.getParsedArithmeticFormula(formula.operand2)} )`
		} else if (formula.value) {
			if (formula.value.type === 'unit') {
				return formula.value.unit
			} else {
				if (formula.value.type === 'item') {
					if (!this.fields.includes(formula.value.item.item)) {
						this.fields.push(formula.value.item.item)
					}
					if (this.transaction_field_list) {
						var field = this.transaction_field_list.find(x => x.id === formula.value.item.item)
						if (field) {
							return `'${field.field_name}'`
						}
					}
					this.formula_status = 'error'
					return ''

				}
			}
		}
		return ''
	}

	setFormula = (formula) => {
		return this.startSimplifyFormula(formula)
	}
	startSimplifyFormula = (formula) => {
		var block = {}
		var key = {}
		if (formula && formula.block) {
			block = {
				block: formula.block
			}
		}
		if (formula && formula.key !== undefined) {
			key = {
				key: formula.key
			}
		}

		if (formula && formula.component) {

			switch (formula.component) {
				case "AND":
				case "OR":
				case "Minimum":
				case "Maximum":
				case "IF":
				case "ELSE IF":
					var operand2 = {}
					if (formula.operand2) {
						operand2 = { operand2: this.setFormulaForValue(formula.operand2) }
					}
					var operator = {}
					if (formula.operand2) {
						operator = { operator: this.setFormulaForValue(formula.operator) }
					}
					return {
						component: formula.component,
						child: this.setFormulaForLoopValue(formula.child),
						...block,
						...key,
						...operand2,
						...operator
					}

				case "Formula":
					return {
						component: formula.component,
						operand1: this.setFormulaForValue(formula.operand1),
						operand2: this.setFormulaForValue(formula.operand2),
						operator: this.setFormulaForValue(formula.operator),
						...block,
						...key
					}
				case "Not":
				case "Is Blank":
					return {
						component: formula.component,
						operand1: this.setFormulaForValue(formula.operand1),
						...block,
						...key
					}
				case "Value":
				case "Arithmetic Formula":
				case "Custom":
				case "Transaction Fields":
					return {
						component: formula.component,
						value: this.setFormulaForValue(formula.value),
						...block,
						...key
					}
				default: return formula
			}
		}
		return formula
	}

	setFormulaForLoopValue = (formula) => {
		return formula.map(item => this.startSimplifyFormula(item))
	}
	setFormulaForValue = (formula) => {
		if (typeof (formula) === 'string') {
			return this.startSimplifyFormula(formula.trim())
		} else {
			return this.startSimplifyFormula(formula)
		}
	}

}

decorate(AlgorithmsStore, {
	list_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	deleteValues: observable,
	dropdown_list: observable,
	agGrid: observable,
	statusValues: observable,
	unpublish_list: observable,
	publish_list: observable,
	transaction_field_list: observable,
	custom_error_msg: observable,
	getList: action,
	EditData: action,
	setDeleteValues: action,
	DeleteData: action,
	setupGrid: action,
	setPageSize: action,
	setStatusValues: action,
	getUnpublishRelation: action,
	getPublishRelation: action,
	getAllTransactionFields: action,
	createReadableFormula: action,
	setFormula: action,
	onFilterChanged: action,
	onGridChanged: action,
})
