const GridConfig = {
	options: {
		rowModelType: "serverSide",
		animateRows: true,
		pagination: true,
		paginationPageSize: 500,
		cacheBlockSize: 500,
		rowHeight: 30,
		headerHeight: 32,
		floatingFiltersHeight: 32,
		blockLoadDebounceMillis: 500,
		enableRangeSelection: true,
		suppressDragLeaveHidesColumns: true,
	},
	defaultColDef: {
		resizable: true,
		sortable: true,
		filter: 'agTextColumnFilter',
		filterParams: {
			suppressAndOrCondition: true,
			debounceMs: 1000
		},
		floatingFilter: true,
	},
	columnTypes: {
		actionColumn: {
			cellRenderer: 'ActionRenderer',
			lockPosition: 'right',
		},
	},
}

export default GridConfig
