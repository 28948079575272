import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Select, Row, Col, Checkbox, Card } from 'antd'
import useStore from '../../../../../../store'
import { observer } from 'mobx-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import VerifyComponent from '../../../TransactionModels/component/VerifyComponent';

const ViewComponent = observer((props) => {
	const [form] = Form.useForm()
	const [fields, setFields] = useState(null);
	const [verifyId, setVerifyID] = useState()
	const [verifyModal, setVerifyModal] = useState(false)
	const { TransactionFieldMappingStore, TransactionTypeStore, TransactionSubTypeStore } = useStore()

	const store = {
		Mapping: TransactionFieldMappingStore,
		Type: TransactionTypeStore,
		SubType: TransactionSubTypeStore,
		Fields: TransactionFieldMappingStore
	}
	var field_list = store.Fields.transaction_field_list

	// set the form values to edit & // call api for select dropdowns
	useEffect(() => {
		if (store.Mapping.editValues) {
			if (!fields) {
				let type = store.Mapping.editValues.transaction_type
				let subtype = store.Mapping.editValues.transaction_sub_type
				store.Type.dropdown_list = type && [type]
				store.SubType.dropdown_list = subtype && [subtype]
				form.setFieldsValue({
					mapping_name: store.Mapping.editValues.mapping_name,
					transactions_type_id: store.Mapping.editValues.transactions_type_id,
					transactions_sub_type_id: store.Mapping.editValues.transactions_sub_type_id,
					friendly_name: store.Mapping.editValues.friendly_name,
					has_items: store.Mapping.editValues.has_items,
				})
				store.Mapping.editValues.selected_tte_fields.map(Number)
				setFields(store.Mapping.editValues.selected_tte_fields.map(x => x = (x !== 0) ? x : null))
			}
		}
	}, [store, form, fields])

	// reset form and close add form
	const close = () => {
		form.resetFields()
		props.close()
	}

	// set SortableItem component for sort
	const SortableItem = SortableElement(({ value }) => (
		<div className="fields_draggable_wrapper">
			{
				value.value ? (
					<Select allowClear defaultValue={value.value} className="w-100" disabled placeholder="Select Transaction Field">
						{
							(field_list && field_list.map((item, i) => (
								(!fields.includes(item.id) || value.value === item.id) ? (
									<Select.Option key={i} value={item.id}>{item.field_name}</Select.Option>
								) : null
							)))
						}
					</Select>
				) : (<div className="text-center w-100">No transaction field has been mapped currently.</div>)
			}
		</div>
	));

	// set SortableList component for Sortable container
	const SortableList = SortableContainer(({ items }) => (
		<div className="fields_draggable_container">
			{
				items.map((value, index) => (
					<SortableItem key={index} index={index} value={{ value, index }} />
				))
			}
		</div>
	))

	// go to verify screen
	const onModalClick = (ID) => {
		setVerifyModal(true)
		setVerifyID(ID)
	}

	//close verify screen
	const closeVerifyScreen = () => setVerifyModal(false)

	return store.Mapping.editValues ? (
		<Modal
			centered
			title={`Map Trade Type, Transaction Type and Trade Transaction`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form}
				id="editform"
				layout="vertical"
			>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="transactions_type_id" label="Trade Type" tooltip='Trade Type is a main category of Trade (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Trade Types can further be classified by Transaction Type.'>
							<Select placeholder="Select Trade Type" disabled>
								{
									(store.Type.dropdown_list && store.Type.dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.type_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="transactions_sub_type_id" label="Transaction Type" tooltip='Transaction Type is used to further classify Transactions and allow for trade transaction of specific Transaction Models against specific Trade Types and Transaction Types.'>
							<Select placeholder="Select Transaction Types" disabled>
								{
									(store.SubType.dropdown_list && store.SubType.dropdown_list.map((item, index) => (
										<Select.Option key={index} value={item.id}>{item.sub_type_name}</Select.Option>
									)))
								}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<Form.Item name="mapping_name" label="Trade Transaction" tooltip='Trade Transaction is used to give unique custom name of type and subtype combination. It is kind of label that will be used for models and transactions.'>
							<Input placeholder="Trade Transaction" disabled maxLength={100} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="friendly_name" label="Friendly Name" required tooltip='Friendly Name is used to give unique custom name of trade type and transaction type combination. It is kind of label that will be used for models and transactions.'>
							<Input placeholder="Friendly Name" maxLength={100} disabled />
						</Form.Item>
					</Col>
				</Row>

				<Row>
					<Col offset={6} span={26}>
						<Form.Item name="has_items" valuePropName="checked" value="1" tooltip='Check this checkbox if you want to associate this combination of Type and Sub Type with Transaction Items to use while importing or creating new Transaction.'>
							<Checkbox disabled>Can contain transaction items</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Card hoverable={false} className='cardScrollable'>
					{
						(fields) && <SortableList useDragHandle={true} items={fields} />
					}
				</Card>

				{store.Mapping.editValues.associated_models && store.Mapping.editValues.associated_models.length > 0 && <h3 style={{ marginTop: 20 }}>Associated Model</h3>}
				<div className='modalScrollable'>
					<ul title='Associated Model'>
						{store.Mapping.editValues.associated_models && store.Mapping.editValues.associated_models.length > 0 && store.Mapping.editValues.associated_models.map((item) => {
							return (
								<Row gutter={24}>
									<Col span={24} onClick={() => { onModalClick(item.id) }} style={{ marginTop: 10 }}>
										<Checkbox defaultChecked={true} disabled>{item.model_name}</Checkbox>
									</Col>
								</Row>
							)
						})}
					</ul>
				</div>
			</Form>
			<VerifyComponent id={verifyId} visible={verifyModal} close={closeVerifyScreen} />
		</Modal >
	) : null
})

export default ViewComponent
