import { Button, Col, Form, message, Row, Spin, Upload } from 'antd';
import React, { useState } from 'react'
import InputComponent from '../../../../components/InputComponent';
import { vsmCredebtor } from '../../../../config/messages';
import useStore from '../../../../store';
import { CredebtorType } from '../../../../utils/GlobalFunction';
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import EmailElement from '../element/EmailElement';
import PhoneElement from '../element/PhoneElement'
import moment from 'moment';
import AddressElement from '../element/AddressElement';
import { useEffect } from 'react';

const CredebtorDetails = ({ form, submitForm, setisDisabled, fileList, setFileList, edit }) => {
	const { AppsAuthStore: { percentagesNode }, CredebtorStore: { showCredebtorData, showCredebtor, openTypeForm, parent_organisations_list, getOriginatorOrganisation, origanisationOriginatorList, getParentOrganisationList, getCurrenciesList, currenciesList, getIndustries, country_list, getAllCountries, industries_list, getNaceCode, naceCode_list, getPersonList, personList, userInitialValues, getOrgStatus, orgStatusList } } = useStore()
	const [fetchMappedOrg, setFetchMappedOrg] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchOrganisation, setFetchOrganisation] = useState(true)
	const [fetchIndustries, setFetchIndustries] = useState(true)
	const [fetctNace, setFetchNace] = useState(true)
	const [naceCode, setNaceCode] = useState()
	const [pesronList, updatePesronList] = useState(true)
	const [orgStatus, updateOrgStatus] = useState(true)


	useEffect(() => {
		form.setFieldsValue({ types: openTypeForm })
	}, [form, openTypeForm])

	useEffect(() => {
		if (edit) {
			showCredebtor(edit.id).then((data) => {
				form.setFieldsValue(data.data)				
			}).catch((err) => {
				
			})
		}
	}, [edit, showCredebtor, form])


	const changeNACE = (e) => {
		if (e) {
			const naceCode = naceCode_list.find(list => list.id === e)
			setNaceCode(naceCode.nace_section)
			form.resetFields(['nace_section_id'])
		} else {
			setNaceCode(null)
		}
	}

	const handleChange = async () => {
		form.validateFields().then((data) => {
			setisDisabled(false)
		}).catch((e) => {
			if (e.errorFields && e.errorFields.length > 0) {
				setisDisabled(true)
			}
		})
	}

	const disabledFormationYear = (current) => {
		const tooLate = current && current <= moment().endOf('years').year('1950');
		const tooEarly = current && current > moment().endOf('years');
		return tooLate || tooEarly
	};


	// Logo file settings
	const uploadLogoProps = {
		onChange: ({ fileList: newFileList }) => {
			setFileList(newFileList)
		},
		// allFiles[logo_url],
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				return true;
			}
			else {
				setFileList([file])
				return false
			}
		}
	}

	const onRemoveImage = () => {
		setFileList([])
		handleChange();
	}

	return (
		<Form layout='vertical' form={form} initialValues={userInitialValues} id="submit-form" onFinish={submitForm}>
			<Row gutter={24}>
				<Col span={5}>
					<InputComponent
						required label='Organisation Name' name='organisation_name' placeholder='Organisation Name'
						tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
						maxLength={100} rules={vsmCredebtor.validation.organisation_name}
						onChange={handleChange}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						label='Trade Name' name='trade_name' placeholder='Trade Name'
						tooltip='Trade name is a trading name commonly used by an Organisation to perform business activities.'
						maxLength={100} rules={vsmCredebtor.validation.trade_name}
						onChange={handleChange}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						type='select' allowClear label='Parent Organisation(s)' name='parent_organisation_id' placeholder='Parent Organisation(s)'
						options={{
							values: parent_organisations_list || [{
								id: showCredebtorData && showCredebtorData.parent && showCredebtorData.parent.id && showCredebtorData.parent.id,
								organisation_name: showCredebtorData && showCredebtorData.parent && showCredebtorData.parent.organisation_name && showCredebtorData.parent.organisation_name
							}],
							value_key: 'id',
							text_key: 'organisation_name'
						}}
						onFocus={() => (fetchMappedOrg && getParentOrganisationList().then(() => setFetchMappedOrg(false)))}
						tooltip='Parent Organisation Role is an Organisation Role that has primary role in a particular relation between two Organisations in the context of a single Transaction.'
						notFoundContent={fetchMappedOrg ? <Spin size="small" /> : "No Record Found."} filterOption={true}
						onChange={handleChange}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						type='select' allowClear label='Originator Organisation' name='originator_id' placeholder='Originator Organisation' required={true}
						options={{
							values: origanisationOriginatorList || [{
								id: showCredebtorData && showCredebtorData.parent && showCredebtorData.parent.id && showCredebtorData.parent.id,
								organisation_name: showCredebtorData && showCredebtorData.parent && showCredebtorData.parent.organisation_name && showCredebtorData.parent.organisation_name
							}],
							value_key: 'id',
							text_key: 'organisation_name'
						}}
						onFocus={() => (fetchOrganisation && getOriginatorOrganisation().then(() => setFetchOrganisation(false)))}
						notFoundContent={fetchMappedOrg ? <Spin size="small" /> : "No Record Found."} filterOption={true}
						rules={vsmCredebtor.validation.originator_organisation}
						onChange={handleChange}
					/>
				</Col>
				<Col span={3}>
					<InputComponent
						type='select' label='Type(s)' name='types' placeholder='Type(s)' tooltip='Role associated with this Credebtor.'
						options={{ values: CredebtorType }} disabled onChange={handleChange} />
				</Col>
				<Col span={6}>
					<InputComponent
						required type='select' label='Currency' name='currency_id' placeholder='Select Currency'
						options={{
							values: currenciesList || [{
								id: showCredebtorData && showCredebtorData.global_currency && showCredebtorData.global_currency.id && showCredebtorData.global_currency.id,
								currency_name: showCredebtorData && showCredebtorData.global_currency && showCredebtorData.global_currency.currency_name && showCredebtorData.global_currency.currency_name
							}],
							value_key: "id",
							text_key: "currency_name"
						}}
						tooltip='Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger.'
						onFocus={() => (fetchCurrency && getCurrenciesList().then(() => setFetchCurrency(false)))}
						notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
						filterOption={true} rules={vsmCredebtor.validation.org_currency}
						onChange={handleChange}
					/>
				</Col>

				<Col span={6}>
					<InputComponent
						required type='select' name='industry_id' placeholder='Select Credebt® Classification'
						label={<Button type='link' className='p-0' style={{ height: '22px' }} onClick={() => window.open("https://www.convertibill.com/credebtclassifications-popup.html", "", "width=650,height=600")}>Credebt® Classification</Button>}
						options={{
							values: industries_list /* || [{
								id: viewMemberValue && viewMemberValue.global_industries && viewMemberValue.global_industries.id && viewMemberValue.global_industries.id,
								name: viewMemberValue && viewMemberValue.global_industries && viewMemberValue.global_industries.name && viewMemberValue.global_industries.name
							}] */,
							value_key: 'id',
							text_key: 'name'
						}}
						onFocus={() => (fetchIndustries && getIndustries().then(() => setFetchIndustries(false)))}
						notFoundContent={fetchIndustries ? <Spin size="small" /> : "No Record Found."}
						tooltip='A classification is a group of productive enterprises or organisations that produce or supply goods, services or sources of income.'
						rules={vsmCredebtor.validation.industry} filterOption={true}
						onChange={handleChange}
					/>
				</Col>
				<Col span={6}>
					<InputComponent
						allowClear type='select' name='nace_section' placeholder='Select Nace Category' onChange={changeNACE}
						label={<Button type='link' className='p-0' style={{ height: '22px' }} onClick={() => window.open("https://www.convertibill.com/nacecategories-popup.html", "", "width=650,height=600")}>NACE Category</Button>}
						options={{
							values: naceCode_list /* || [{
				id: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.get_nace_category && viewMemberValue.global_nace_sections.get_nace_category.id && viewMemberValue.global_nace_sections.get_nace_category.id,
				category_name: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.get_nace_category && viewMemberValue.global_nace_sections.get_nace_category.category_name && viewMemberValue.global_nace_sections.get_nace_category.category_name
			}] */,
							value_key: "id",
							text_key: "category_name"
						}}
						onFocus={() => (fetctNace && getNaceCode().then(() => setFetchNace(false)))}
						tooltip='Select NACE Code for the selected NACE Category'
						notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."} filterOption={true}

					/>
				</Col>
				<Col span={6}>
					<InputComponent
						required={naceCode ? true : false} allowClear type='select' label='NACE Code' name='nace_section_id' placeholder='Select NACE Code'
						onFocus={() => (fetctNace && getNaceCode().then(() => setFetchNace(false)))}
						notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: naceCode /* || [{
								id: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.id && viewMemberValue.global_nace_sections.id,
								code: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.code && viewMemberValue.global_nace_sections.code,
								section: viewMemberValue && viewMemberValue.global_nace_sections && viewMemberValue.global_nace_sections.section && viewMemberValue.global_nace_sections.section
							}], */,
							value_key: "id",
							text_key: { key: ["code", " - ", "section"] }
						}}
						disabled={naceCode ? false : true} filterOption={true}
						tooltip='Select NACE Code for the selected NACE Category.'
						rules={vsmCredebtor.validation.nace_section_id}
						onChange={handleChange}
					/>
				</Col>
				<Col span={18}>
					<Row gutter={24}>
						<Col span={8}>
							<InputComponent
								type='select' required label='Registration Country' name='registered_country_id' placeholder='Registration Country'
								options={{
									values: country_list/*|| [{
										id: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.id && viewMemberValue.global_countries.id,
										iso_code_alpha2: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.iso_code_alpha3 && viewMemberValue.global_countries.iso_code_alpha3,
										country_name: viewMemberValue && viewMemberValue.global_countries && viewMemberValue.global_countries.country_name && viewMemberValue.global_countries.country_name,
									}] */,
									value_key: 'id',
									text_key: { key: ['iso_code_alpha2', ' - ', 'country_name'] },
								}}
								tooltip='A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities.'
								onFocus={() => fetchCountry && getAllCountries().then(() => setFetchCountry(false))}
								notFoundContent={fetchCountry ? <Spin size='small' /> : 'No Record Found.'}
								autoComplete='off'
								rules={vsmCredebtor.validation.registration_country}
								onChange={handleChange}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								label='Registration Number' name='registration_number' placeholder='Registration Number'
								tooltip='A number appointed by relevant Company Registration Authorities in a country the organisation was founded in.'
								rules={vsmCredebtor.validation.registration_number}
								onChange={handleChange}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								label='Sales Tax/VAT Number' name='vat_number' placeholder='Sales Tax/VAT Number' maxLength={100}
								tooltip='A number appointed by relevant Tax Authorities in a country the organisation was founded in.'
								rules={vsmCredebtor.validation.vat_number}
								onChange={handleChange}
							/>
						</Col>
						<Col span={8} className="withExtraNote">
							<InputComponent
								onChange={handleChange}
								type='select' label='Tax ID Document' name='tex_id_doc' placeholder='Tax ID Document'
								options={{ values: [] }}
								tooltip='Select the type of tax document associated with this organisation.'
								rules={vsmCredebtor.validation.tex_id_doc} disabled
								note={<div /* onClick={openCredebtor} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '16px' }} /></div>}
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label='Tax ID Number' name='tax_id_number' placeholder='Tax ID Number'
								tooltip='Select the personal tax identification number associated with this organisation.'
								rules={vsmCredebtor.validation.tax_id_number} disabled
								onChange={handleChange}
							/>
						</Col>
						<Col span={10}>
							<InputComponent
								label='Tax Clearance Access Number' name='revenue_access_number' placeholder='Tax Clearance Access Number'
								tooltip='When Revenue issues a Tax Clearance Certificate, they will also give a Tax Clearance Access Number (TCAN).'
								rules={vsmCredebtor.validation.tax_clearence}
								onChange={handleChange}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={6}>
					<Form.Item
						name="logo_url" className="full-width-dragger logo-dragger"
						label='Logo only JPEG or PNG is allowed, Maximum size: 500px * 500px'
						tooltip="Logo is a symbol or other small design adopted by an organisation to identify its products.">
						<Upload
							accept=".png, .jpg, .jpeg"
							className="hideeyeicon"
							listType="picture-card"
							multiple={false}
							onRemove={onRemoveImage}
							showUploadList={true}
							fileList={fileList}
							{...uploadLogoProps}
						>
							{fileList.length < 1 && <PlusOutlined />}
						</Upload>
					</Form.Item>
				</Col>
				<Col span={4} className="withExtraNote">
					<InputComponent
						label="Person's Name" name='person_name' placeholder="Person's Name"
						type='select'
						options={{
							values: personList /* || [{
									id: viewMemberValue && viewMemberValue.credit_risk && viewMemberValue.credit_risk.person_name && viewMemberValue.credit_risk.person_name.id && viewMemberValue.credit_risk.person_name.id,
									full_name: viewMemberValue && viewMemberValue.credit_risk && viewMemberValue.credit_risk.person_name && viewMemberValue.credit_risk.person_name.id && viewMemberValue.credit_risk.person_name.full_name
								}] */,
							value_key: 'id',
							text_key: 'full_name'
						}}
						onFocus={() => (pesronList && getPersonList().then(() => updatePesronList(false)))}
						notFoundContent={pesronList ? <Spin size='small' /> : 'No Content Found'}
						tooltip='A name by which an individual is intimately known or designated and which may be displaced or supplemented by a surname, acognomen, or a royal name'
						rules={vsmCredebtor.validation.person_name}
						note={<div /* onClick={openPeopleDrawer} */ key="1" className='extra_note' style={{ color: '#2e4369' }}><PlusCircleOutlined style={{ fontSize: '18px' }} /></div>}
						onChange={handleChange}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						label='Website' name='website' placeholder='Website'
						tooltip='A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server.'
						rules={vsmCredebtor.validation.website}
						onChange={handleChange}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						type='select' label='Status' name='status_id' placeholder='Select Status' onChange={handleChange}
						options={{
							values: orgStatusList/*|| [{
								id: viewMemberValue && vsmCredebtor.status && viewMemberValue.status.id && viewMemberValue.status.id,
								status_name: viewMemberValue && viewMemberValue.status && viewMemberValue.status.status_name && viewMemberValue.status.status_name
							}] */,
							value_key: 'id',
							text_key: 'status_name'
						}}
						onFocus={() => orgStatus && getOrgStatus().then(() => updateOrgStatus(false))}
						notFoundContent={orgStatus ? <Spin size='small' /> : 'No Content Found'}
						tooltip='Select status'
						rules={vsmCredebtor.validation.status_id}
					/>
				</Col>
				<Col span={5}>
					<InputComponent label="Counterparty Since" name="counter_party_since" disabledDate={disabledFormationYear} rules={vsmCredebtor.validation.Counterparty} type="date" picker="year" required={true} placeholder="Counterparty Since" tooltip="Select the year when the company first became the customer of your company." onChange={handleChange} />
				</Col>
				<Col span={5}>
					<InputComponent placeholder="Total Historic Value" label="Total Historic Value" type="text" name="total_historic_value" onBlur={(e) => percentagesNode(form, e, 'total_historic_value')} rules={vsmCredebtor.validation.totalHistoricValue} onChange={handleChange} tooltip="Enter the total value of business over the years that you have done with this company" />
				</Col>

				<Col span={3}>
					<InputComponent type="text" placeholder="Payment Terms" label="Payment Terms" name="average_payment_cycle" required={true} rules={vsmCredebtor.validation.Payment_Terms} onChange={handleChange} />
				</Col>
				<Col span={4}>
					<InputComponent type="text" placeholder="Credebt Notes Issue" label="Credit Notes Issued" name="credit_notes_issued" rules={vsmCredebtor.validation.credebt_notes_issue} onChange={handleChange} tooltip="How many credit notes have you issued to this customer over the years" />
				</Col>
				<Col span={6}>
					<InputComponent type="text" placeholder="Invoice Predicted Per Annum" label="Invoice Predicted Per Annum" name="invoice_predicted_per_annum" rules={vsmCredebtor.validation.invoice_predicted_per_annum} onChange={handleChange} required={true} tooltip="What quantity of invoices do you issue to this Debtor company every year?" />
				</Col>
				<Col span={5}>
					<InputComponent type="text" placeholder="predicted Annual Revenue" label="Predicted Annual Revenue" name="predicted_annual_revenue" rules={vsmCredebtor.validation.predicted_annual_revenue} onChange={handleChange} required={true} tooltip="Enter the counterparty’s Agreed Early Payment Discount" />
				</Col>
				<Col span={5}>
					<InputComponent placeholder="Agreed Early Payment Discount" label="Agreed Early Payment Discount" type="text" name="agreed_early_payment_discount" onBlur={(e) => percentagesNode(form, e, 'agreed_early_payment_discount')} rules={vsmCredebtor.validation.agreed_early_payment_discount} onChange={handleChange} tooltip="Enter the counterparty’s Agreed Early Payment Discount" />
				</Col>
				<Col span={16}>
					<Form.Item label="Phone (To add extension use ext keyword above i.e :+353 x xxx-xxxx, ext. xxxx)" tooltip={'Phone Type is the type, that would be associated with the Email(Eg.: Primary, home, work). Phone number can be stored with country code and extension numbers.'}>
						<PhoneElement form={form} onChange={handleChange} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item label="Email" className="mb-10" tooltip={'a system for sending messages to one or more recipients via telecommunications links between computers using dedicated software'}>
						<EmailElement form={form} onChange={handleChange} />
					</Form.Item>
				</Col>
				<Col span={12}>
					<InputComponent
						type='textarea' label='Description' name='description' placeholder='Description' onChange={handleChange}
						tooltip={"A statement, picture in words, or account that describes; descriptive representation"}
						maxLength={32000}
					/>
				</Col>
				<Col span={24}>
					<AddressElement onChange={handleChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} />
				</Col>
			</Row>
		</Form>
	)
}

export default CredebtorDetails;