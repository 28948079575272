import login_img from '../assets/images/usermanual/login.png'
import forgetpassword_img from '../assets/images/usermanual/forgetpassword.png'
import transaction_type_list_img from '../assets/images/usermanual/transaction_type_list.png'
import pin_column_img from '../assets/images/usermanual/pin_column.png'
import filter_img from '../assets/images/usermanual/filter.png'
import search_img from '../assets/images/usermanual/search.png'
import transaction_type_new_img from '../assets/images/usermanual/transaction_type_new.png'
import transaction_type_edit_img from '../assets/images/usermanual/transaction_type_edit.png'
import transaction_type_delete_img from '../assets/images/usermanual/transaction_type_delete.png'
import transaction_sub_type_list_img from '../assets/images/usermanual/transaction_sub_type_list.png'
import transaction_sub_type_new_img from '../assets/images/usermanual/transaction_sub_type_new.png'
import transaction_sub_type_edit_img from '../assets/images/usermanual/transaction_sub_type_edit.png'
import transaction_field_mapping_list_img from '../assets/images/usermanual/transaction_field_mapping_list.png'
import transaction_field_mapping_unpublish_img from '../assets/images/usermanual/transaction_field_mapping_unpublish.png'
import transaction_field_mapping_new_img from '../assets/images/usermanual/transaction_field_mapping_new.png'
import transaction_field_mapping_view_img from '../assets/images/usermanual/transaction_field_mapping_view.png'
import tax_settings_list_img from '../assets/images/usermanual/tax_settings_list.png'
import tax_settings_new_img from '../assets/images/usermanual/tax_settings_new.png'
import tax_settings_edit_img from '../assets/images/usermanual/tax_settings_edit.png'
import journal_groups_list_img from '../assets/images/usermanual/journal_groups_list.png'
import journal_groups_new_img from '../assets/images/usermanual/journal_groups_new.png'
import journal_groups_edit_img from '../assets/images/usermanual/journal_groups_edit.png'
import journals_list_img from '../assets/images/usermanual/journals_list.png'
import journals_new_img from '../assets/images/usermanual/journals_new.png'
import journals_edit_img from '../assets/images/usermanual/journals_edit.png'
import transaction_field_list_img from '../assets/images/usermanual/transaction_field_list.png'
import transaction_field_new_img from '../assets/images/usermanual/transaction_field_new.png'
import all_transactions_list_img from '../assets/images/usermanual/all_transactions_list.png'
import all_transactions_new_img from '../assets/images/usermanual/all_transactions_new.png'
import all_transactions_advance_filter_img from '../assets/images/usermanual/all_transactions_advance_filter.png'
import algorithms_list_img from '../assets/images/usermanual/algorithms_list.png'
import algorithms_new_img from '../assets/images/usermanual/algorithms_new.png'
import algorithms_view_img from '../assets/images/usermanual/algorithms_view.png'
import transaction_models_list_img from '../assets/images/usermanual/transaction_models_list.png'
import transaction_models_new_img from '../assets/images/usermanual/transaction_models_new.png'
import transaction_models_verify1_img from '../assets/images/usermanual/transaction_models_verify1.png'
import transaction_models_verify2_img from '../assets/images/usermanual/transaction_models_verify2.png'
import import_tte_img from '../assets/images/usermanual/import_tte.png'
import users_list_img from '../assets/images/usermanual/users_list.png'
import users_add_img from '../assets/images/usermanual/users_add.png'
import users_reset_password_img from '../assets/images/usermanual/users_reset_password.png'
import users_view_ledgers_img from '../assets/images/usermanual/users_view_ledgers.png'
import user_roles_list_img from '../assets/images/usermanual/user_roles_list.png'
import user_roles_new_img from '../assets/images/usermanual/user_roles_new.png'
import organisations_list_img from '../assets/images/usermanual/organisations_list.png'
import organisations_add_img from '../assets/images/usermanual/organisations_add.png'
import organisations_edit_img from '../assets/images/usermanual/organisations_edit.png'
import organisations_import_img from '../assets/images/usermanual/organisations_import.png'
import organisations_map_img from '../assets/images/usermanual/organisations_map.png'
import organisations_bank_acc_list_img from '../assets/images/usermanual/organisations_bank_acc_list.png'
import organisations_bank_acc_edit_img from '../assets/images/usermanual/organisations_bank_acc_edit.png'
import organisations_bank_acc_new_img from '../assets/images/usermanual/organisations_bank_acc_new.png'
import role_mapping_list_img from '../assets/images/usermanual/role_mapping_list.png'
import role_mapping_map_new_img from '../assets/images/usermanual/role_mapping_map_new.png'
import org_roles_list_img from '../assets/images/usermanual/org_roles_list.png'
import org_roles_new_img from '../assets/images/usermanual/org_roles_new.png'
import org_roles_edit_img from '../assets/images/usermanual/org_roles_edit.png'
import ledgers_list_img from '../assets/images/usermanual/ledgers_list.png'
import ledgers_new_img from '../assets/images/usermanual/ledgers_new.png'
import ledgers_edit_img from '../assets/images/usermanual/ledgers_edit.png'
import ledgers_add_admin_img from '../assets/images/usermanual/ledgers_add_admin.png'
import ledgers_archive_img from '../assets/images/usermanual/ledgers_archive.png'
import ledgers_proxy_login_img from '../assets/images/usermanual/ledgers_proxy_login.png'
import ledgers_settings_img from '../assets/images/usermanual/ledgers_settings.png'
import ledgers_bank_acc_list_img from '../assets/images/usermanual/ledgers_bank_acc_list.png'
import ledgers_bank_acc_add_img from '../assets/images/usermanual/ledgers_bank_acc_add.png'
import ledgers_bank_acc_map_bank_img from '../assets/images/usermanual/ledgers_bank_acc_map_bank.png'
import regex_list_img from '../assets/images/usermanual/regex_list.png'
import regex_new_img from '../assets/images/usermanual/regex_new.png'
import classification_list_img from '../assets/images/usermanual/classification_list.png'
import classification_new_img from '../assets/images/usermanual/classification_new.png'
import cte_import_list_img from '../assets/images/usermanual/cte_import_list.png'
import cte_import_img from '../assets/images/usermanual/cte_import.png'
import cte_import_view_img from '../assets/images/usermanual/cte_import_view.png'
import cte_import_verify_img from '../assets/images/usermanual/cte_import_verify.png'
import cte_import_view_cash_img from '../assets/images/usermanual/cte_import_view_cash.png'
import all_cash_transactions_advance_filter_img from '../assets/images/usermanual/all_cash_transactions_advance_filter.png'
import all_cash_transactions_edit_img from '../assets/images/usermanual/all_cash_transactions_advance_filter.png'
import all_cash_transactions_list_img from '../assets/images/usermanual/all_cash_transactions_advance_filter.png'
import all_cash_transactions_new_img from '../assets/images/usermanual/all_cash_transactions_advance_filter.png'
import all_cash_transactions_view_img from '../assets/images/usermanual/all_cash_transactions_advance_filter.png'
import reconcile_cash_transactions from '../assets/images/usermanual/reconcile_cash_transactions.png'
import journal_logs from '../assets/images/usermanual/journal_logs.png'
import reconciliation_list_img from '../assets/images/usermanual/reconciliation_list.png'
import reconciliation_edit_cash_img from '../assets/images/usermanual/reconciliation_edit_cash.png'
import reconciliation_view_cash_img from '../assets/images/usermanual/reconciliation_view_cash.png'
import reports_list_img from '../assets/images/usermanual/reports_list_img.png'
import reports_add_img from '../assets/images/usermanual/reports_add_img.png'
import reports_edit_img from '../assets/images/usermanual/reports_edit_img.png'
import reports_add_cash_img from '../assets/images/usermanual/reports_add_cash_img.png'
import reports_audit from '../assets/images/usermanual/reports_audit.png'
import reports_preview from '../assets/images/usermanual/reports_preview.png'
import reports_preview_data from '../assets/images/usermanual/reports_preview_data.png'
import reports_filters from '../assets/images/usermanual/reports_filters.png'
import favorite_preview_data_img from '../assets/images/usermanual/favorite_preview_data.png'
import favorite_edit_filters_img from '../assets/images/usermanual/favorite_edit_filters.png'
import favorite__img from '../assets/images/usermanual/favorite.png'
import fx_currency_list from '../assets/images/usermanual/fx_currency_list.png'
import fx_currency_add from '../assets/images/usermanual/fx_currency_add.png'
import fx_currency_list_ledger_admin from '../assets/images/usermanual/fx_currency_list_ledger_admin.png'
import fx_currency_ledger_admin_api_management from '../assets/images/usermanual/fx_currency_ledger_admin_api_management.png'
import fx_currency_ledger_admin_view_history from '../assets/images/usermanual/fx_currency_ledger_admin_view_history.png'
import fx_currency_ledger_admin_bulk_edit from '../assets/images/usermanual/fx_currency_ledger_admin_bulk_edit.png'
import organisation_status_list from '../assets/images/usermanual/organisation_status_list.png'
import organisation_status_add from '../assets/images/usermanual/organisation_status_add.png'
import rating_agency from '../assets/images/usermanual/rating_agency.png'
import menual_journal_log_list from '../assets/images/usermanual/menual_journal_log_list.png'
import manual_journal_log_add from '../assets/images/usermanual/manual_journal_log_add.png'
import reverse_manual_journal_log from '../assets/images/usermanual/reverse_manual_journal_log.png'
import trigger_list from '../assets/images/usermanual/trigger_list.png'
import trigger_add from '../assets/images/usermanual/trigger_add.png'
import clone_ledger from '../assets/images/usermanual/clone_ledger.png'
import global_settings from '../assets/images/usermanual/global_settings.png'

const UserManualConfig = [
	{
		title: 'Login',
		text: [
			`User can login to the system with their credentials - Email Address & Password`,
			`After Login it will display the Dashboard page.`,
			`Forgot Password link - Clicking on it will navigate to "Forgot Password Page."`
		],
		image: [{ image: login_img, caption: '' }]
	},
	{
		title: 'Forget Password',
		text: [
			`Users can reset the password by adding their email address.`,
			`Password reset link will be sent to the registered email address.`,
			`Back to Login link - Clicking on it will navigate to "Login Page."`,
		],
		image: [{ image: forgetpassword_img, caption: '' }]
	},
	{
		title: 'Transaction Type',
		text: [
			`Transaction type is the main category of Transactions. Transaction type can have multiple Transaction Subtypes.`,
			`On transaction type listing page following information will be displayed:`,
			["ID", "Transaction Type", "Associated Fields", "Last Updated By", "Last Updated On", "Action"],
			`Action`,
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Transaction Type page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Transaction Type page."
			],
			`New button - Clicking on it will navigate to Add Transaction Type page`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000`,
			`Filter options available for all columns except Associated field & Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: transaction_type_list_img, caption: '' },
			{ image: pin_column_img, caption: 'Pin Columns' },
			{ image: filter_img, caption: 'Filter' },
			{ image: search_img, caption: 'Search' },
		],
		child: [
			{
				title: "New",
				text: [
					`Users can add New Transaction Type on this page.`,
					`If data input is not correct then it will display an error message.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Type Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Type Listing page.`
				],
				image: [{ image: transaction_type_new_img, caption: '' }]
			},
			{
				title: "Edit",
				text: [
					`Users can edit any Transaction Type.`,
					`Validation messages will be the same as the Add Transaction Type page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Type Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Type Listing page.`
				],
				image: [{ image: transaction_type_edit_img, caption: '' }]
			},
			{
				title: "Delete",
				text: [
					`Delete icon will be disabled if it is associated with Field Mapping, so users cannot delete those transaction types and the message will be displayed on hover.`,
					`If the delete icon is enabled then the user can delete the transaction type.`,
					`Delete button - It will delete the Transaction Type. After deleting the entry “Successful” message will be displayed and it will navigate to the Transaction Type listing page.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Type Listing page.`,
				],
				image: [{ image: transaction_type_delete_img, caption: '' }]
			}
		]
	},
	{
		title: 'Transaction Sub Type',
		text: [
			`On transaction sub type listing page following information will be displayed:`,
			["ID", "Transaction Sub Type", "Associated Fields", "Last Updated By", "Last Updated On", "Action"],
			`Action`,
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Transaction Sub Type page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Transaction Sub Type page."
			],
			`New button - Clicking on it will navigate to Add Transaction Sub Type page`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000`,
			`Filter options available for all columns except Associated field & Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: transaction_sub_type_list_img, caption: '' },
		],
		child: [
			{
				title: "New",
				text: [
					`Users can add New Transaction Sub Type on this page.`,
					`If data input is not correct then it will display an error message.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Sub Type Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Sub Type Listing page.`
				],
				image: [{ image: transaction_sub_type_new_img, caption: '' }]
			},
			{
				title: "Edit",
				text: [
					`Users can edit any Transaction Sub Type.`,
					`Validation messages will be the same as the Add Transaction Sub Type page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Sub Type Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Sub Type Listing page.`
				],
				image: [{ image: transaction_sub_type_edit_img, caption: '' }]
			},
			{
				title: "Delete",
				text: [
					`Delete icon will be disabled if it is associated with Field Mapping, so users cannot delete those transaction sub types and the message will be displayed on hover.`,
					`If the delete icon is enabled then the user can delete the transaction sub type.`,
					`Delete button - It will delete the Transaction Sub Type. After deleting the entry “Successful” message will be displayed and it will navigate to the Transaction Sub Type listing page.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Sub Type Listing page.`,
				],
			}
		]
	},
	{
		title: 'Transaction Field Mappings',
		text: [
			`On transaction field mapping listing page following information will be displayed:`,
			["ID", "Mapping Name", "Transaction Type", "Transaction Sub Type", "Associated Fields", "Last Updated By", "Last Updated On", "Published / Unpublished",
				[
					'Publish - Clicking icon to “publish”, will display a successful message.',
					'Unpublish - By default new mapping will be unpublished. Clicking the icon to “unpublish” it will open a pop-up window.',
					[
						'Unpublish button - To unpublish. user will have to write text ”UNPUBLISH” and then only the field will be unpublished. After field mapping is unpublished, a successful message will be displayed and it will navigate back to the Transaction Field Mapping Listing page.',
						'Cancel - It will cancel the entry and go back to the Transaction Field Mapping Listing page.'
					]
				], "Action"],
			`Action`,
			[
				"View -  Clicking on the View icon will navigate to the view information of Transaction Field Mapping.",
				"Edit - Clicking on the Edit icon will navigate to the Edit Transaction Field Mapping page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Transaction Field Mapping page."
			],
			`New button - Clicking on it will navigate to Add Transaction Field Mapping page`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000`,
			`Filter options available for all columns except Associated field & Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: transaction_field_mapping_list_img, caption: '' },
			{ image: transaction_field_mapping_unpublish_img, caption: 'Unpublish' },
		],
		child: [
			{
				title: "New",
				text: [
					`Users can add New Transaction Field Mapping on this page and the fields are:`,
					["Type", "Sub Type", "Mapping Name", "Can contain transaction item", "Select one or more transaction fields to associate (Can add multiple transaction fields by clicking on “+” icon. To remove they can click on “-” icon)"],
					`If data input is not correct then it will display an error message.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Field Mapping Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Field Mapping Listing page.`
				],
				image: [{ image: transaction_field_mapping_new_img, caption: '' }]
			},
			{
				title: "Edit",
				text: [
					`Published mapping cannot be edited. To edit any mapping user will have to unpublish first and then only the edit icon will be enabled.`,
					`Validation messages will be the same as the Add Transaction Field Mapping page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Field Mapping Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Field Mapping Listing page.`
				],
			},
			{
				title: "View",
				text: [
					`Users can view field transaction mapping information.`,
					`All fields are disabled on this page so they cannot edit or delete it.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Field Mapping Listing page.`,
				],
				image: [{ image: transaction_field_mapping_view_img, caption: '' }]
			},
			{
				title: "Delete",
				text: [
					`Published mapping cannot be deleted. To delete any mapping user will have to unpublish first and then only the delete icon will be enabled.`,
					`Delete button - It will delete the Transaction Field Mapping. After deleting the entry “Successful” message will be displayed and it will navigate to the Transaction Field Mapping listing page.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Field Mapping Listing page.`,
				],
			}
		]
	},
	{
		title: 'Tax Settings',
		text: [
			`On tax settings listing page following information will be displayed:`,
			["ID", "Tax Name", "Tax Value", "Tax Type", "Associated Journals", "Last Updated By", "Last Updated On", "Action"],
			`Action`,
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Tax Settings page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Tax Settings page."
			],
			`New button - Clicking on it will navigate to Add Tax Settings page`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000`,
			`Filter options available for all columns except Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: tax_settings_list_img, caption: '' },
		],
		child: [
			{
				title: "New",
				text: [
					`Users can add New Tax Settings on this page and the fields are:`,
					["Tax Type", "Tax Name", "Tax Value"],
					`If data input is not correct then it will display an error message.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Tax Settings Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Tax Settings Listing page.`
				],
				image: [{ image: tax_settings_new_img, caption: '' }]
			},
			{
				title: "Edit",
				text: [
					`Users can edit Tax Settings fields:`,
					["Tax Type", "Tax Name", "Tax Value - To edit tax value users will have to do additional configurations.",
						["There will be 3 options for users to choose -", ["Apply to all past data", "Apply to data in specific date range (Choose date range)", "Apply to new data only"],
							"Based on these settings tax value will be applied to the data.",
							"They will have to type “Confirm” word before saving the changes."]
					],
					`Validation messages will be the same as the Add Transaction Type page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Transaction Type Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Type Listing page.`
				],
				image: [{ image: tax_settings_edit_img, caption: '' }]
			},
			{
				title: "Delete",
				text: [
					`Delete icon will be disabled if it is associated with Field Mapping, so users cannot delete those transaction types and the message will be displayed on hover.`,
					`If the delete icon is enabled then the user can delete the transaction type.`,
					`Delete button - It will delete the Transaction Type. After deleting the entry “Successful” message will be displayed and it will navigate to the Transaction Type listing page.`,
					`Cancel button - It will cancel the entry and go back to the Transaction Type Listing page.`,
				],
			}
		]
	},
	{
		title: "Journal Groups",
		text: [
			'On journal group listing page following information will be displayed:',
			['ID', 'Journal Group', 'Journal Subgroup', 'Journal Type', 'Associated Journals', 'Last Updated By', 'Last Updated On', 'Actions'],
			'Actions:',
			['Edit - Clicking on the Edit icon will navigate to the Edit Journal Group page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Journal Group page.'
			],
			'New button - Clicking on it will navigate to Add Journal Group page',
			'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000',
			'Filter options available for all columns except Action columns.',
			'Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns',
			'Search'
		],
		image: [{ image: journal_groups_list_img, caption: '' }],
		child: [
			{
				title: "New",
				text: [
					'Users can add New Journal Group on this page and the fields are:',
					['Journal Group', 'Journal Subgroup', 'Journal Type'],
					'If data input is not correct then it will display an error message.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Journal Group Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Journal Group Listing page.'
				],
				image: [{ image: journal_groups_new_img, caption: '' }],
			},
			{
				title: "Edit",
				text: [
					'Edit icon will be disabled for the Top level (Asset, Income, Liability & Expense) journal group  whose parent ID is 0.',
					'Users can edit only those entries where edit is enabled.',
					'Validation messages will be the same as the Add Journal Group page.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Journal Group Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Journal Group Listing page.'
				],
				image: [{ image: journal_groups_edit_img, caption: '' }],
			},
			{
				title: "Delete",
				text: [
					'Delete icon will be disabled for the Top level (Asset, Income, Liability & Expense) journal group  whose parent ID is 0.',
					'If the delete icon is enabled then the user can delete the Journal Group.',
					'Delete button - It will delete the Journal Group. After deleting the entry “Successful” message will be displayed and it will navigate to the Journal Group listing page.',
					'Cancel button - It will cancel the entry and go back to the Journal Group page.',
				],
			}
		]
	},
	{
		title: "Journals",
		text: [
			'On journals listing page following information will be displayed:',
			['ID', 'Code', 'Type', 'Journal Group', 'Journal Label', 'Tax Settings', 'Last Updated By', 'Last Updated On', 'Actions'],
			'Actions:',
			['Edit - Clicking on the Edit icon will navigate to the Edit Journal page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Journal page.'
			],
			'New button - Clicking on it will navigate to Add Journal page',
			'Import button - Clicking on it will navigate to the Import page.',
			'Export button - Download the files in csv format.',
			'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000',
			'Filter options available for all columns except Action columns.',
			'Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns',
			'Search'
		],
		image: [{ image: journals_list_img, caption: '' }],
		child: [
			{
				title: "New",
				text: [
					'Users can add New Journals on this page and the fields are:',
					['Journal Group', 'Code', 'Label', 'Description', 'Tax Settings'],
					'If data input is not correct then it will display an error message.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Journals Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Journals Listing page.'
				],
				image: [{ image: journals_new_img, caption: '' }],
			},
			{
				title: "Edit",
				text: [
					'Users can edit Journals fields but to edit Tax Settings they have to add additional details:',
					['There will be 3 options for users to choose -',
						["Apply to all past data",
							"Apply to data in specific date range (Choose date range)",
							"Apply to new data only"
						],
						'Based on these settings tax value will be applied to the data.',
						'They will have to type “Confirm” word before saving the changes.',],
					'Validation messages will be the same as the Add Journals page.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Journals Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Journals Listing page.'
				],
				image: [{ image: journals_edit_img, caption: '' }],
			},
			{
				title: "Delete",
				text: [
					'Delete icon will be disabled for the Top level (Asset, Income, Liability & Expense) journal group  whose parent ID is 0.',
					'If the delete icon is enabled then the user can delete the Journal Group.',
					'Delete button - It will delete the Journal Group. After deleting the entry “Successful” message will be displayed and it will navigate to the Journal Group listing page.',
					'Cancel button - It will cancel the entry and go back to the Journal Group page.',
				],
			},
			{
				title: "Import",
				text: [
					'Import file - drag & drop or upload as per the criterias defined.',
					'Option to download sample file.',
					'Import - Import the file with all the data.',
					'Cancel - It will cancel the entry and go back to the Journals page.',
				],
			}
		]
	},
	{
		title: "Transaction Fields",
		text: [
			'On transaction fields listing page following information will be displayed:',
			['ID', 'Field Name', 'Algorithm Name', 'Default Value', 'Last Updated By', 'Last Updated On', 'Actions', 'Published / Unpublished',
				['Publish - Clicking icon to “publish”, will display a successful message.',
					'Unpublish - By default a new transaction field will be unpublished. Clicking the icon to “unpublish” it will open a pop-up window.',
					['Unpublish button - To unpublish user will have to write text ”UNPUBLISH” and then only the field will be unpublished. After the field is unpublished, a successful message will be displayed and it will navigate back to the Transaction Field Listing page.',
						'Cancel - It will cancel the entry and go back to the Transaction Field Listing page.']
				],
			],
			'Actions:',
			['Edit - Clicking on the Edit icon will navigate to the Edit Transaction Field page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Transaction Field page.'
			],
			'New button - Clicking on it will navigate to Add Transaction Field page.',
			'Export button - Download the files in csv format.',
			'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000',
			'Filter options available for all columns except Action columns.',
			'Search'
		],
		image: [{ image: transaction_field_list_img, caption: '' }],
		child: [
			{
				title: "New",
				text: [
					'On add transaction field page, following information will be displayed:',
					['Field Name', 'Field Type', 'Algorithm', 'Default value'],
					'If data input is not correct then it will display an error message.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Transaction Field Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Transaction Field Mapping Listing page.'
				],
				image: [{ image: transaction_field_new_img, caption: '' }],
			},
			{
				title: "Edit",
				text: [
					'User can edit transaction fields. All fields can be edited except Field type.',
					'Validation messages will be the same as the Add Transaction Field page.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Transaction Field Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Transaction Field Listing page.'
				],
			},
			{
				title: "Delete",
				text: [
					'Published field cannot be deleted. To delete any field user will have to unpublish first and then only the delete icon will be enabled.',
					'Delete button - It will delete the Transaction Field. After deleting the entry “Successful” message will be displayed and it will navigate to the Transaction Field listing page.',
					'Cancel button - It will cancel the entry and go back to the Transaction Field Listing page.',
				],
			}
		]
	},
	{
		title: "Trade Transactions",
		text: [
			'On TTE Transactions page following information will be displayed:',
			['ID', 'Exchange ID', 'Mapping Type', 'Organisation/People Name', 'Role', 'Related Organisation/People Name', 'Related Role', 'Transaction Ref', 'Traded', 'Posted', 'Face Value'],
			'Filter options available for all columns except Action columns.',
			'Search',
			'Actions:',
			['View - Clicking on the View icon will navigate to the View Trade Transaction page.',
				'Edit - Clicking on the Edit icon will navigate to the Edit Trade Transaction page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Trade Transaction page.'
			],
			'Buttons:',
			['Advance Filter - Clicking on it would navigate to the Apply Advance Filter Page.',
				'Export - Export the files in CSV format.',
				'New button - Clicking on it will navigate to Add Trade Transaction page',
				'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000'],
		],
		image: [{ image: all_transactions_list_img, caption: '' }],
		child: [
			{
				title: "Apply Advance Filter",
				text: [
					'Users can apply advance filters and the fields are:',
					['Select Transaction Field - User can select from the dropdown menu.', 'Sign - User can select from the dropdown menu.', 'Value - User can add the value.'],
					'Buttons:',
					['Repeater(+) - By clicking this button, user can add more number of advance filters.',
						'AND(+) - By clicking this button, user can add more number of advance filters; where all the conditions would be mandatory.',
						'Apply - By clicking this button, user can apply the filters mentioned. The button will only get enabled when the fields are entered correctly.',
						'Reset - By clicking this button, all the entered values will be erased and the user can re-enter the values.',
						'Cancel - It will cancel the entry and go back to the TTE Transactions page.'],
				],
				image: [{ image: all_transactions_advance_filter_img, caption: '' }],
			}, {
				title: "Add Trade Transaction",
				text: [
					'On add trade transaction page, following information will be displayed:',
					['Organisation Role',
						'Organisation',
						'Relate to',
						['If Organisation is selected:',
							[
								'Related Organisation Role', 'Related Organisation'
							],
							'If People is selected:',
							['Related People'],
							'If Mapping is selected:',
							['Go with the normal flow']
						],
						'Mapping',
						'Currency',
						'Face Value',
						'Status',
						'Vat Rate',
						'Posted On',
						'Traded On',
					],
					'Buttons:',
					['Save - It will save the data in the database and go back to the TTE Transactions page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
						'Cancel - It will cancel the entry and go back to the TTE Transactions page.'
					]
				],
				image: [{ image: all_transactions_new_img, caption: '' }],
			},
			{
				title: "View Trade Transaction",
				text: [
					'On trade transaction page following information will be displayed:',
					['Organisation Role', 'Organisation', 'Relate To', 'Related Org. Role', 'Related Organisation', 'Mapping', 'Currency', 'Face Value', 'Status', 'VAT Rate', 'Posted On', 'Traded On', 'Additional Field Values',
						['EDSO', 'Repair and Maintenance', 'EoT Value', 'Sell Rate', 'Asset Type', 'Current Period', 'Period Number Days', 'Period Days Passed', 'Batch ID', 'Tax ID on Purchase', 'Tax ID on Sales'],
						'Select Models to apply on Transactions',
						'Journal Logs',
						[
							'ID',
							'Journal Type',
							'Code',
							'Journal Label',
							'Mapped Value',
							'Debit(+)',
							'Credit(-)',
							'Posting',
							'Journal Date',
							'Reconciled to',
							['View Cash Transaction',
								[
									'Bank Account',
									'Tag',
									'Entity Type',
									'Org. Role',
									'Organisation',
									'Related Entity Type',
									'Rel. Org. Role',
									'Rel. Organisation',
									'Cash Transaction Classification',
									'Transaction Type',
									'Transaction Details',
									'Face Value',
									'Currency',
									'Posted Date',
									'Balance',
									'Value Date',
									'Exchange Reference',
									'Customer Reference',
									'Bank Reference'
								],
							],
							['Reconciled with Trade Transactions',
								[
									'ID',
									'Mapping Type',
									'Transaction Ref',
									'Journal',
									'Face Value',
									'Journal Value',
									'Traded On',
									'Posted On',
									'Reconciliation On',
									'Exchange ID',
									'Actions',
									[
										'Unlink - User can unlink the trade transaction with the cash transaction.',
										'View - Redirects to View Trade Transaction page.'
									],
								],
							],
						],
						'Transaction Items',
						[
							'ID',
							'Batch',
							'Asset Number',
							'Deposit REceived',
							'Asset Type',
							'Months',
							'Period Type',
							'Value',
							'Sell Rate',
							'EOT',
							'Item Name',
							'Quantity',
							'Unit Price',
							'RM Price',
							'Serial No',
							'Classification',
							'Description',
							'Manufacturer',
							'Address',
							'Country',
							'Site Number',
							'VAT Rate',
							'Tax Type',
							'DD Amount',
						]
					],
				],
			},
			{
				title: "Edit Trade Transaction",
				text: [
					'On edit trade transaction page following information will be displayed:',
					[
						'Organisation Role',
						'Organisation',
						'Relate to',
						'Related Org. Role',
						'Related Organisation',
						'Mapping',
						'Currency',
						'Face Value',
						'Status',
						'VAT Rate',
						'Posted On',
						'Traded On',
						'Additional Field Values',
						[
							'EDSO',
							'Repair and Maintenance',
							'EoT Value',
							'Sell Rate',
							'Asset Type',
							'Current Period',
							'Period Number Days',
							'Period Days Passed',
							'Batch ID',
							'Tax ID on Purchase',
							'Tax ID on Sales'
						],
						'Checkbox - Select Models to Apply on Transaction'
					],
					'Buttons:',
					[
						'Save - It will save the data in the database and go back to the All Transactions page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
						'Cancel - It will cancel the entry and go back to the All Transactions page.'
					]
				],
			},
			{
				title: "Delete Trade Transaction",
				text: [
					'Only delete those entries where the Delete icon is available.',
					'User needs to type ‘CONFIRM’ message.',
					'Delete button - It will delete the entry. After deleting the entry “Successful” message will be displayed and it will navigate to the Organisations page.',
					'Cancel button - It will cancel the entry and go back to the Organisations page.',
				],
			},
		]
	},
	{
		title: "Algorithms",
		text: [

			'On algorithm page following information will be displayed:',
			['ID', 'Algorithm Name', 'Description', 'Last Parse Status', 'Last Updated By', 'Last Updated On', 'Status (Published / Unpublished)', [
				'Publish - Clicking icon to “publish”, will display a successful message.',
				'Unpublish - By default a new algorithm will be unpublished. Clicking the icon to “unpublish” it will open a pop-up window.',
				[
					'Unpublish button - To unpublish. the user will have to write text ”UNPUBLISH” and then only the field will be unpublished. After the algorithm is unpublished, a successful message will be displayed and it will navigate back to the Algorithm Listing page.',
					'Cancel - It will cancel the entry and go back to the Algorithms Listing page.'
				]
			], 'Actions'],
			'Actions',
			[
				'Edit - Clicking on the Edit icon will navigate to the Edit Algorithm page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Algorithm page.',
				'View - Clicking on the View icon will navigate to the View Algorithm page.'
			],
			'New button - Clicking on it will navigate to the Add Algorithm page.',
			'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000',
			'Filter options available for all columns except Action columns.',
			'Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns',
			'Search'
		],
		image: [{ image: algorithms_list_img, caption: '' }],
		child: [
			{
				title: "New",
				text: [
					'Users can add New Algorithms on this page and the fields are:',
					[
						'Algorithm Name',
						'Description',
						'Algorithm Builder - To build algorithm the user will have to follow the below steps:',
						[
							'Choose Operator (If, Minimum, Maximum, Value)',
							'Suppose user selects Minimum, Maximum or Value operator then they can choose any one Operand (Arithmetic Formula, Transaction Fields, Custom)',
							'In case if the user chooses “IF” operator, then inside IF operator there will be conditional operators. After selecting each conditional operator user will have 3 Operands.'
						],
						'Readable format',
						'Use available fields for arithmetic formula builder'
					],
					'If data input is not correct then it will display error messages.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Algorithms Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the AlogrithmsListing page.'
				],
				image: [{ image: algorithms_new_img, caption: '' }],
			},
			{
				title: "Edit",
				text: [
					'Published algorithms cannot be edited. To edit any algorithm user will have to unpublish first and then only the edit icon will be enabled.',
					'Validation messages will be the same as the Add Algorithm page.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Algorithms Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Algorithms Listing page.'
				],
			},
			{
				title: "View",
				text: [
					'Users can view algorithm information.',
					'All fields are disabled on this page so they cannot edit or delete it.',
					'Cancel button - It will cancel the entry and go back to the Algorithms Listing page.'
				],
				image: [{ image: algorithms_view_img, caption: '' }],
			},
			{
				title: "Delete",
				text: [
					'Published algorithms cannot be deleted. To delete any algorithm user will have to unpublish first and then only the delete icon will be enabled.',
					'If the delete icon is enabled then the user can delete the Algorithms.',
					'Delete button - It will delete the Algorithms. After deleting the entry “Successful” message will be displayed and it will navigate to the Algorithms listing page.',
					'Cancel button - It will cancel the entry and go back to the Algorithms listing page.'
				],
			},
		]
	},
	{
		title: "Transaction Models",
		text: [
			'On transaction model page following information will be displayed:',
			['ID', 'Transaction Type', 'Code', 'Model Name', 'Last Updated By', 'Last Updated On', 'Status (Published / Unpublished)', [
				'Publish - Clicking icon to “publish”, will display a successful message.',
				'Unpublish - By default a new model will be unpublished. Clicking the icon to “unpublish” it will open a pop-up window.',
				['Unpublish button - To unpublish. the user will have to write text ”UNPUBLISH” and then only the field will be unpublished. After the algorithm is unpublished, a successful message will be displayed and it will navigate back to the Transaction Models Listing page.',
					'Cancel - It will cancel the entry and go back to the Transaction Models Listing page.'
				]
			]
			],
			'Actions',
			[
				'Edit - Clicking on the Edit icon will navigate to the Edit Transaction Models page.',
				'Delete - Clicking on the Delete icon will navigate to the Delete Transaction Models page.',
				'Verified/Unverified - Clicking on the icon will navigate to the Verified/Unverified page. Red color icon symbolize unverified and Green color icon symbolize verified.'
			],
			'New button - Clicking on it will navigate to the Add Transaction Models page.',
			'Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000',
			'Filter options available for all columns except Action columns.',
			'Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns',
			'Search'
		],
		image: [{ image: transaction_models_list_img, caption: '' }],
		child: [
			{
				title: "New",
				text: [
					'Users can add New Algorithms on this page and the fields are:',
					[
						'Algorithm Name',
						'Description',
						'Algorithm Builder - To build algorithm the user will have to follow the below steps:',
						[
							'Choose Operator (If, Minimum, Maximum, Value)',
							'Suppose user selects Minimum, Maximum or Value operator then they can choose any one Operand (Arithmetic Formula, Transaction Fields, Custom)',
							'In case if the user chooses “IF” operator, then inside IF operator there will be conditional operators. After selecting each conditional operator user will have 3 Operands.'
						],
						'Readable format',
						'Use available fields for arithmetic formula builder'
					],
					'If data input is not correct then it will display error messages.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Algorithms Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the AlogrithmsListing page.'
				],
				image: [{ image: transaction_models_new_img, caption: '' }],
			},
			{
				title: "Edit",
				text: [
					'Published transaction model cannot be edited. To edit any model user will have to unpublish first and then only the edit icon will be enabled.',
					'Validation messages will be the same as the Add Transaction Model page.',
					'Users can hover over “information” icon to know more about the field.',
					'Save button - It will save the data in the database and go back to the Transaction Model Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.',
					'Cancel button - It will cancel the entry and go back to the Transaction Model Listing page.'
				],
			},
			{
				title: "Delete",
				text: [
					'Published transaction models cannot be deleted. To delete any model user will have to unpublish first and then only the delete icon will be enabled.',
					'If the delete icon is enabled then the user can delete the transaction model.',
					'Delete button - It will delete the Transaction Model. After deleting the entry “Successful” message will be displayed and it will navigate to the transaction model listing page.',
					'Cancel button - It will cancel the entry and go back to the transaction model listing page.'
				],
			},
			{
				title: "Verified / Unverified Transactions",
				text: [
					'All the journals for the selected model will be displayed.',
					[
						'ID', 'Journal Type', 'Code', 'Journal Label', 'Mapped Value', 'Debit', 'Credit', 'Posting', 'Journal Date', 'Reconciled To'
					],
					'Transaction Model Field value will be displayed.',
					'Verify with Existing Transaction Button',
					[
						'On this page it will show all the existing transactions',
						'Select date range to fetch transactions',
						'Execute Model on Selected Transactions - On selected transactions it will apply and navigate back to the verified/unverified listing page.',
						'Cancel - Navigate back to the verified/unverified listing page.'
					],
					{ image: [{ image: transaction_models_verify1_img, caption: '' }] },
					"Verify by Entering Fields Manually Button",
					["User can edit transaction model field value after clicking on this button"],
					"Mark Model as Verified Button - Model will be marked as verified and a success message will be displayed and go back to Model Listing page.",
					"Mark Model as Unverified Button - Model will be marked as unverified and a success message will be displayed and go back to Model Listing page.",
					"Cancel Button -  It will cancel the entry and go back to the Model Listing page."
				],
				image: [{ image: transaction_models_verify2_img, caption: '' }],
			},
		]
	},
	{
		title: 'TTE Import',
		text: [
			"On TTE import page, following information will be displayed:",
			[
				"Download Sample File: Would download a CSV sample file.",
				"Input box for selecting/dragging the CSV file. Drag & drop or upload as per the criterias defined.",
				"Textbox: User can input the name of the Import.",
				"Select Mapping: User can select the mapping from the dropdown menu."
			],
			"Buttons:",
			[
				"Start Import in Background - On clicking this button, the import would start in the background",
				"Cancel - Would cancel and redirect to the ‘Import Log’."
			]
		],
		image: [{ image: import_tte_img, caption: '' }]
	},
	{
		title: 'Users',
		text: [
			"On users page, following information will be displayed:",
			[
				"ID",
				"Checkbox",
				"First Name",
				"Last Name",
				"Email",
				"Last Login On",
				"User Add On",
				"Status"
			],
			"Actions:",
			[
				"Edit User - Clicking on the Edit User, would redirect to the Edit User page.",
				"View Logs -",
				"View Ledgers - Clicking on the View Ledgers, would redirect to the View Ledgers page."
			],
			"Buttons:",
			[
				"Show All User - Clicking on this would display all the users.",
				"Show Archived User - Clicking on this would display all the archived users.",
				"Bulk Action - User can select from the predefined actions available to deal with multiple records.",
				["Activate", "Deactivate", "Archive", "Unarchive"],
				"New - Clicking on it will navigate to the Add New User page.",
				"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
				"Filter options available for all columns except Action columns.",
				"Search"
			]
		],
		image: [{ image: users_list_img, caption: '' }],
		child: [
			{
				title: "Add New User",
				text: [
					"User can add new user on this page and the fields are:",
					["Email", "First Name", "Last Name", "Ledger", "Role in Ledger", [
						'After selecting a role they will have options to modify permission which is explained in 15.1.'
					]],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the All Users page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the All Users page."
					]
				],
				image: [{ image: users_add_img, caption: '' }]
			},
			{
				title: "Edit User",
				text: [
					"Users can edit the user on this page and the fields are:",
					["Email ID", "Reset Password", [
						"User can confirm whether the password needs to reset.",
						"Buttons:",
						[
							"Yes - A reset password link would be sent.",
							"No - Redirects to the edit user page"
						],
						{ image: [{ image: users_reset_password_img, caption: '' }] }
					], "First Name", "Last Name", "Ledger", "Role in Ledger"],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the All Users page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the All Users Listing page."
					]
				],
			},
			{
				title: "View Logs",
				text: ["Currently no data is being displayed."]
			},
			{
				title: "View Ledgers",
				text: [
					"Users can view the ledgers on this page and the fields are:",
					["ID", "First Name", "Last Name", "Email", "User Add on"],
					"Buttons:",
					["Cancel - It will cancel the entry and go back to the All Users Listing page."],
					{ image: [{ image: users_view_ledgers_img, caption: '' }] }
				],

			}
		]
	},
	{
		title: 'Manage User Profiles',
		tip: 'User Roles',
		text: [
			"On users role page, following information will be displayed:",
			[
				"ID",
				"Role Name",
				"Number of Users",
				"Last Updated By",
				"Last Updated On",
			],
			"Actions:",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit User Role page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete User Role page.",
			],
			"New button - Clicking on it will navigate to the Add User Role page.",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [{ image: user_roles_list_img, caption: '' }],
		child: [
			{
				title: "Add New User Role",
				text: [
					"On add new user role page, following information will be displayed:",
					["Role Name", "All the application features will be listed so they can assign rights to those features (for example - Add, Edit, Delete, etc) for the role added in the system."],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the User Role Page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the User Role listing page."
					]
				],
				image: [{ image: user_roles_new_img, caption: '' }]
			},
			{
				title: "Edit User Role",
				text: [
					"Super Admin and Ledger Admin roles cannot be edited.",
					"Edit page fields and validation will be same as Add User Role",
					"At the end there is a checkbox to remove all custom permission.",
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the User Role page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the User Role listing page."
					]
				],
			},
			{
				title: "Delete User Role",
				text: [
					"User role cannot be removed if it is associated with users and the delete icon will be disabled. On hover it will display a message.",
					"If the user role is not associated with users then delete icon will be enabled and it can be deleted.",
					"Delete button - It will delete the User role. After deleting the entry “Successful” message will be displayed and it will navigate to the transaction model listing page.",
					"Cancel button - It will cancel the entry and go back to the transaction model listing page."
				],

			}
		]
	},

	{
		title: "Organisations",
		text: [
			"On Organisations page, following information will be displayed:",
			[
				"ID",
				"Organisation Name",
				"Trade Name",
				"Registration Number",
				"Country",
				"Associated Ledger",
				"Created By Ledger",
				"Last Updated On"
			],
			"Action",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Organisation page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Organisation page.",
				"Bank Accounts - Clicking on the bank account icon will navigate to the Bank Accounts page.",
				"Role Mapping - Clicking on the bank account icon will navigate to the Role Mapping page."
			],
			"Buttons:",
			[
				"New - Clicking on it will navigate to Add New Organisation page.",
				"Import - Clicking on it will navigate to the Import page.",
				"Export - Download the files in csv format.",
				"Map Organisation with Current Ledger",
				"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000."
			],
			"Filter options available for all columns except Action columns.",
			"Search",
		],
		image: [
			{ image: organisations_list_img, caption: '' }
		],
		child: [
			{
				title: "Add New Organisation",
				text: [
					"On add new organisation page, following information will be displayed:",
					[
						"Parent Organisation",
						"Organisation Name",
						"Trade Name",
						"Registration Country",
						"Registration Number",
						"VAT Number",
						"FY Start Month",
						"Website",
						"Phone Number",
						"Add Address",
						[
							"Address - 1",
							"Address - 2",
							"Address - 3",
							"City",
							"State/County",
							"Postal Code",
							"Country",
						]
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Organisations page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Organisations page."
					],
				],
				image: [
					{ image: organisations_add_img, caption: '' }
				]
			},
			{
				title: "Import Organisations",
				text: [
					"Import file - drag & drop or upload as per the criterias defined.",
					"Option to download sample file.",
					"Import - Import the file with all the data.",
					"Cancel - It will cancel the entry and go back to the Journals page.",
					"Tabs:",
					[
						"Organisation",
						"Role Mapping"
					],
				],
				image: [
					{ image: organisations_import_img, caption: '' }
				]
			},
			{
				title: "Map Organisation with Current Ledger",
				text: [
					"On Map Organisation with Current Ledger page, following items will be displayed:",
					[
						"Organisation",
						"Organisation Role",
						"Relate to",
						[
							"If Organisation is selected:",
							[
								"Related Organisation ",
								"Related Role",
							],
							"If People is selected",
							[
								"Related People",
								"Related Role"
							],
							"If Mapping is selected:",
							[
								"Go with the normal flow"
							]
						],
						"Tags",
						"Exchange Reference ID"
					],
					"Save - It will save the data in the database and go back to the Organisations page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel - It will cancel the entry and go back to the Organisations page.",
				],
				image: [
					{ image: organisations_map_img, caption: '' }
				]
			},
			{
				title: "Edit Organisation",
				text: [
					"On edit organisation page, following information will be displayed:",
					[
						"Organisation Name",
						"Trade Name",
						"Registration Country",
						"Registration Number",
						"VAT Number",
						"FY Start Month",
						"Website",
						"Phone Number",
						"Add Address",
						[
							"Address - 1",
							"Address - 2",
							"Address - 3",
							"City",
							"State/County",
							"Postal Code",
							"Country"
						],
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Organisations page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Organisations page."
					],
				],
				image: [
					{ image: organisations_edit_img, caption: '' }
				]
			},
			{
				title: "Delete Organisation",
				text: [
					"Only delete those entries where the Delete icon is available.",
					"Delete button - It will delete the entry. After deleting the entry “Successful” message will be displayed and it will navigate to the Organisations page.",
					"Cancel button - It will cancel the entry and go back to the Organisations page."
				]
			},
			{
				title: "Bank Accounts",
				text: [
					"On bank accounts page, following information will be displayed:",
					[
						"ID",
						"Bank Name",
						"Account Type",
						"Currency",
						"Account No.",
						"Sort Code",
						"IBAN",
						"Swift or BIC code",
						"Status"
					],
					"Buttons:",
					[
						"New - Clicking on it will navigate to the Add New Bank Account page.",
						"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000."
					],
					"Filter options available for all columns except Action columns.",
					"Search",
				],
				image: [
					{ image: organisations_bank_acc_list_img, caption: '' }
				]
			},
			{
				title: "Bank Accounts > Add New Bank Account",
				text: [
					"User can add new bank account on this page and the fields are:",
					[
						"Bank Name",
						"Bank Account Type",
						"Currency",
						"Group Button",
						[
							"Account Number",
							[
								"Account Number",
								"Sort Code"
							],
							"IBAN Number",
							[
								"IBAN",
								"Swift or BIC Code"
							]
						]
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Bank Accounts page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Bank Accounts page."
					],
				],
				image: [
					{ image: organisations_bank_acc_new_img, caption: '' }
				]
			},
			{
				title: "Bank Accounts > Edit Bank Account",
				text: [
					"User can edit bank account and the fields are:",
					[
						"Bank Name",
						"Bank Account Type",
						"Currency",
						"Account Number",
						"Sort Code"
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Bank Accounts page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Bank Accounts page."
					]
				],
				image: [
					{ image: organisations_bank_acc_edit_img, caption: '' }
				]
			},
			{
				title: "Role Mapping",
				text: [
					"On role mapping page, following information will be displayed:",
					[
						"ID",
						"Organisation/People Name",
						"Role",
						"Related Organisation/People Name",
						"Related Role",
						"Tags",
						"Last Updated By",
						"Last Updated On"
					],
					"Buttons:",
					[
						"Add Bank Account - Clicking on it will navigate to the Add New Bank Account page.",
						"Map New Role - Clicking on it will navigate to the Map New Role page.",
						"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000."
					],
					"Filter options available for all columns except Action columns.",
					"Search"
				],
				image: [
					{ image: role_mapping_list_img, caption: '' }
				]
			},
			{
				title: "Role Mapping > Map New Role",
				text: [
					"On role mapping page, following information will be displayed:",
					[
						"Organisation",
						"Organisation Role",
						"Relate To",
						[
							"Organisation",
							[
								"Related Organisation",
								"Related Role",
							],
							"People",
							[
								"Related People",
								"Related Role"
							],
							"Master Ledger",
							[
								"Go with the normal flow"
							]
						],
						"TagsGo with the normal flow",
						"Exchange Reference ID"
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Role Mapping page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Role Mapping page."
					]
				],
				image: [
					{ image: role_mapping_map_new_img, caption: '' }
				]
			},
		]
	},
	{
		title: "Organisation Roles",
		text: [
			"On Organisation roles page, following information will be displayed:",
			[
				"ID",
				"Role Name",
				"No. of Organisations",
				"Last Updated By",
				"Last Updated On"
			],
			"Action",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Role page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Role page.",
			],
			"Buttons:",
			[
				"New - Clicking on it will navigate to Add New Organisation role page.",
				"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000."
			],
			"Filter options available for all columns except Action columns.",
			"Search",
		],
		image: [
			{ image: org_roles_list_img, caption: '' }
		],
		child: [
			{
				title: "New Organisation Role",
				text: [
					"On Organisation roles page, following information will be displayed:",
					[
						"Role Name",
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Roles page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Roles page."
					],
				],
				image: [
					{ image: org_roles_new_img, caption: '' }
				]
			},
			{
				title: "Edit Role",
				text: [
					"On Organisation roles page, following information will be displayed:",
					[
						"Role Name",
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Roles page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Roles page."
					],
				],
				image: [
					{ image: org_roles_edit_img, caption: '' }
				]
			},
			{
				title: "Delete Role",
				text: [
					"Only delete those entries where the Delete icon is available.",
					'Delete button - It will delete the entry. After deleting the entry “Successful” message will be displayed and it will navigate to the Role page.',
					'Cancel button - It will cancel the entry and go back to the Role page.'
				],
			},
		]
	},
	{
		title: "Organisation Import",
		text: [
			"On organisation import page, user can select from two available options:",
			[
				"Organisation",
				"Role Mapping",
				[
					"Import file - drag & drop or upload as per the criterias defined.",
					"Option to download sample file.",
					"Import - Import the file with all the data.",
					"Cancel - It will cancel the entry and go back to the Organisations page."
				]
			],
		],
		image: [
			{ image: organisations_import_img, caption: '' }
		],
	},
	{
		title: "Ledgers",
		text: [
			"On ledgers page, following information will be displayed:",
			[
				"ID",
				"Ledger Name",
				"Organisation",
				"Ledger Admin",
				"Child Ledgers",
				"Associated Organisation",
				"Associated Peoples",
				"Total Users",
				"Last Updated On",
				"Status"
			],
			"Actions:",
			[
				"Edit - Clicking on the Edit Ledger, would redirect to the Edit Ledger page.",
				"Archive Ledger - Clicking on the Archive Ledger, would redirect to the Archive Ledger page.",
				"Proxy Login - Clicking on the Proxy Login, would redirect to the Proxy Login page."
			],
			"Buttons:",
			[
				"Show Archived Ledgers - Clicking on it will navigate to the Show Archived Ledgers page.",
				"New - Clicking on it will navigate to the Add New Ledger page.",
				"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000"
			],
			"Filter options available for all columns except Action columns.",
			"Search"
		],
		image: [
			{ image: ledgers_list_img, caption: '' }
		],
		child: [
			{
				title: "Edit Ledgers",
				text: [
					"On ledgers page, following information will be displayed:",
					[
						"Ledger Name",
						"Organisation",
						"Ledger Admin",
						[
							"Add Ledger Admin",
							[
								"Email",
								"First Name",
								"Last Name"
							],
							"Buttons:",
							[
								"Assign to Ledger -",
								"Cancel - It will cancel the entry and go back to the Edit Ledgers page."
							],
							{ image: [{ image: ledgers_add_admin_img, caption: '' }] }
						],
						"Buttons:",
						[
							"Save - It will save the data in the database and go back to the Ledgers page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
							"Cancel - It will cancel the entry and go back to the Ledgers page."
						],
						{ image: [{ image: ledgers_edit_img, caption: '' }] }
					]
				]

			},
			{
				title: "Archive Ledger",
				text: [
					"Buttons:",
					[
						"Archive -It will archive the ledger and send the mail for successful archiving of the ledger.",
						"Cancel - It will cancel the entry and go back to the Ledgers page.",
					],
					{ image: [{ image: ledgers_archive_img, caption: '' }] }
				]
			},
			{
				title: "Proxy Login",
				text: [
					"On clicking Proxy Login icon, the user would be redirected to the Ledger’s page of that particular user.",
					"To return back to the super admin, the user needs to click on the ‘Back to SuperAdmin’ from the available options."
				],
				image: [
					{ image: ledgers_proxy_login_img, caption: '' }
				]
			},
			{
				title: "Add New Ledger",
				text: [
					"On add new ledgers page, following information will be displayed:",
					[
						"Ledger Name",
						"Organisation",
						"Ledger Admin",
						"Checkbox - Send email to ledger admin to setup new ledger"
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Ledgers page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Ledgers page."
					],
				],
				image: [
					{ image: ledgers_new_img, caption: '' }
				]
			},
		]
	},
	{
		title: "Ledger Settings",
		text: [
			"On Ledger Bank Accounts page following information will be displayed:",
			[
				"Ledger Name",
				"Organisation",
				"Ledger Description",
				"Base Currency",
				"Timezone",
				"Logo"
			],
			"Buttons:",
			[
				"Save - It will save the data in the database and go back to the Dashboard page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
				"Cancel - It will cancel the entry and go back to the Dashboard."
			],
			"Data Archival by Financial Year is displayed.",
			[
				"Option to archive or restore financial year data."
			]
		],
		image: [
			{ image: ledgers_settings_img, caption: '' }
		]
	},
	{
		title: "Ledger Bank Accounts",
		text: [
			"On Ledger Bank Accounts page following information will be displayed:",
			[
				"ID",
				"Bank Name",
				"Account Type",
				"Currency",
				"Account No.",
				"Sort Code",
				"IBAN",
				"Swift or BIC code",
				"Status",
				"Actions (Activate / Deactivate)",
				[
					"Confirmation dialog box will open that the time of Activate or Deactivate back."
				]
			],
			"Actions",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Bank Account page.",
				"Manage Bank fields - Clicking on this icon will navigate to the Manage Bank Fields page."
			],
			"New button - Clicking on it will navigate to the Add Regular Expression page.",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [
			{ image: ledgers_bank_acc_list_img, caption: '' }
		],
		child: [
			{
				title: "Add New Bank Account",
				text: [
					"User can add new bank account on this page and the fields are:",
					[
						"Bank Name",
						"Bank Account Type",
						"Currency",
						"Group Button",
						[
							"Account Number",
							[
								"Account Number",
								"Sort Code"
							],
							"IBAN Number",
							[
								"IBAN",
								"Swift or BIC Code"
							]
						]
					],
					"Buttons:",
					[
						"Save - It will save the data in the database and go back to the Bank Accounts page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
						"Cancel - It will cancel the entry and go back to the Bank Accounts page."
					]
				],
				image: [
					{ image: ledgers_bank_acc_add_img, caption: '' }
				],
			},
			{
				title: "Edit",
				text: [
					"Users can edit either Account Number & Sort Code or IBAN & Swift/BIC code.",
					"Validation messages will be the same as the Add Bank page.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Ledger Bank Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Ledger Bank Listing page."
				]
			},
			{
				title: "Map Bank Fields",
				text: [
					"User can map bank import file fields.",
					"They can add regular expression for one or multiple fields.",
					"Save button - It will save the data in the database and go back to the Ledger Bank Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Ledger Bank Listing page."
				],
				image: [
					{ image: ledgers_bank_acc_map_bank_img, caption: '' }
				],
			}
		]
	},
	{
		title: "Cash > Regex",
		text: [
			"On Regex (Regular Expression) page following information will be displayed:",
			[
				"ID",
				"Name",
				"Regular Expression",
				"Description",
				"Last Updated By",
				"Last Updated On",
				"Actions"
			],
			"Actions",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Regular Expression page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Regular Expression page."
			],
			"New Expression button - Clicking on it will navigate to the Add Regular Expression page.",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [
			{ image: regex_list_img, caption: '' }
		],
		child: [
			{
				title: "New",
				text: [
					"Users can add New Regular Expression on this page and the fields are:",
					[
						"Expression Name",
						"Regular Expression",
						"Description",
						"Verification String",
						"Output - Output will be generated automatically.n",
					],
					"If data input is not correct then it will display error messages.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Regular Expression Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Regular Expression Listing page."
				],
				image: [
					{ image: regex_new_img, caption: '' }
				],
			},
			{
				title: "Edit",
				text: [
					"Edit regular expression.",
					"Validation messages will be the same as the Add Regular Expression page.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Regular Expression Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Regular Expression Listing page."
				]
			},
			{
				title: "Delete",
				text: [
					"Delete icon will be disabled if there is bank field mapping with that particular Regex. ",
					"If the delete icon is enabled then the user can delete the Regex.",
					"Delete button - It will delete the Regular Expression. After deleting the entry “Successful” message will be displayed and it will navigate to the Regular Expression listing page.",
					"Cancel button - It will cancel the entry and go back to the Regular Expression listing page.",
				]
			},
		],
	},
	{
		title: "Cash Classification",
		text: [
			"On Regex (Regular Expression) page following information will be displayed:",
			[
				"ID",
				"Transaction Class Name",
				"Code",
				"Description",
				"No. of Transactions",
				"Last Updated By",
				"Last Updated On",
				"Actions"
			],
			"Actions",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Cash Classification page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Cash Classification page."
			],
			"New button - Clicking on it will navigate to Add Cash Classification page.",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [
			{ image: classification_list_img, caption: '' }
		],
		child: [
			{
				title: "New",
				text: [
					"Users can add New Cash Classification on this page and the fields are:",
					[
						"Class Name",
						"Code",
						"Description",
						"Classification Rules (*There are different types of rules which user can add.)",
						"Optional Rules",
					],
					"If data input is not correct then it will display error messages.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Cash Classification Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Cash Classification Listing page."
				],
				image: [
					{ image: classification_new_img, caption: '' }
				],
			},
			{
				title: "Edit",
				text: [
					"Edit cash classification fields.",
					"Validation messages will be the same as the Add Cash Classification page.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Cash Classification Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Cash Classification Listing page."
				]
			},
			{
				title: "Delete",
				text: [
					"Delete icon will be disabled if the Transactions as associated with the classification.",
					"If the delete icon is enabled then the user can delete the Cash Classification.",
					"Delete button - It will delete the Cash Classification. After deleting the entry “Successful” message will be displayed and it will navigate to the Cash Classification listing page.",
					"Cancel button - It will cancel the entry and go back to the Cash Classification page.",
				]
			},
		],
	},
	{
		title: "Cash Import",
		text: [
			"On Cash Import page following information will be displayed:",
			[
				"ID",
				"Import Name",
				"Bank",
				"Account / IBAN",
				"Imported By",
				"Imported On",
				"Identified",
				"Status",
				"Duration",
				"Actions"
			],
			"Actions",
			[
				"View - Clicking on the Edit icon will navigate to the View Log page.",
				"Download Import File with Log - Option to download file.",
				"There is button to navigate to “All Cash Transactions”",
				"Delete - Clicking on the Delete icon will navigate to the Delete page.",
				"Cancel - Clicking on the Cancel icon will navigate to the Cancel page. This action will stop the ongoing import process.",
				"Verified/Unverified - Clicking on the icon will navigate to theVerified/Unverified page. Red color icon symbolize unverified and Green color icon symbolize verified."
			],
			"Import New CSV button - Clicking on it will navigate to the Import Cash Transaction page.",
			"Auto Refresh - Page will auto refresh clicking on it. Option to stop auto refresh.",
			"Reprocess Identification for Selected Imports - This will simply reprocess all transactions in that import and it will go through the identification process using queue again. A message will be displayed when the process will start.",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [
			{ image: cte_import_list_img, caption: '' }
		],
		child: [
			{
				title: "Import",
				text: [
					"On import page, following information will be displayed:",
					[
						"Download Sample File: Would download a CSV sample file.",
						"Input box for selecting/dragging the CSV file. Drag & drop or upload as per the criterias defined."
					],
					"Start Import in Background - On clicking this button, the import would start in the background",
					"Cancel - It will cancel the entry and go back to the Cash Import History page."
				],
				image: [
					{ image: cte_import_img, caption: '' }
				],
			},
			{
				title: "View Log",
				text: [
					"Users can view the complete logo of the selected transaction.",
					"Action buttons",
					[
						"Verify / Unverify",
						[
							"They will view verified or unverified transactions.",
							"If any transaction is unverified they can manually verify it.",
							"Save & go next - Save particular entry and go to next entry.",
							"Previous - Go to previous entry.",
							"Next - Go to next entry. The total next entries are also displayed."
						],
						{ image: [{ image: cte_import_verify_img, caption: '' }] },
						"View - ",
						[
							"View detailed log for that transaction",
							"Back - Navigate back to View Log page."
						],
						{ image: [{ image: cte_import_view_img, caption: '' }] },
						"View Cash Transaction",
						[
							"Can only view the cash transactions but cannot edit anything.",
							"Back - Navigate back to View Log page."
						],
						{ image: [{ image: cte_import_view_cash_img, caption: '' }] }
					]
				]
			},
			{
				title: "Verified / Unverified",
				text: [
					"The page details are explained in 24.2 > b"
				]
			},
			{
				title: "Delete",
				text: [
					"Only delete those entries where the Delete icon is available.",
					"Delete button - It will delete the Cash Import. After deleting the entry “Successful” message will be displayed and it will navigate to the Cash Import listing page.",
					"Cancel button - It will cancel the entry and go back to the Cash Import listing page."
				]
			}
		]
	},
	{
		title: "Cash Transactions",
		text: [
			"On cash transaction listing page following information will be displayed:",
			[
				"ID",
				"Date",
				"Transaction Details",
				"Transaction Type",
				"Debit",
				"Credit",
				"Identified Category",
				"Exchange ID",
				"Organisation/People",
				"Role",
				"Related Organisation/People",
				"Related Role",
				"Status",
				"Actions"
			],
			"Actions",
			[
				"Edit - Clicking on the Edit icon will navigate to the Edit Cash Transaction page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Cash Transaction page.",
				"Verified/Unverified - Clicking on the icon will navigate to the Verified/Unverified page. Red color icon symbolize unverified and Green color icon symbolize verified.",
				"View - Clicking on the Edit icon will navigate to the View Cash Transaction page."
			],
			"Reconciliation Mode button - Select atleast one record to reconcile and it will navigate to the Reconciliation page.",
			"Advance Filter - Navigate to Advance Filter page",
			"Export file will be in csv format.",
			"New button - Clicking on it will navigate to Add Cash Transaction page",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000",
			"Filter options available for all columns except Action columns.",
			"Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns",
			"Search"
		],
		image: [
			{ image: all_cash_transactions_list_img, caption: '' }
		],
		child: [
			{
				title: "New",
				text: [
					"Users can add New Cash Transaction on this page.",
					"If data input is not correct then it will display error messages.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Cash Transaction Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Cash Transaction Listing page."
				],
				image: [
					{ image: all_cash_transactions_new_img, caption: '' }
				],
			},
			{
				title: "View",
				text: [
					"Users can view cash transaction information.",
					"There will be information about “Reconciled with Trade Transaction”",
					[
						"Action button",
						[
							"View",
							[
								"They can view the details of the particular transactions",
								"All fields are disabled on this page so they cannot edit or delete it.",
								"Journal Logs and Transaction items will be displayed."
							],
							"Unlink",
							[
								"Option to unlink trade transactions.",
							],
							"All fields are disabled on this page so they cannot edit or delete it.",
							"Back button - It will cancel the entry and go back to the Cash Transaction Listing page."
						],
						{ image: [{ image: all_cash_transactions_view_img, caption: '' }] },
					]
				]
			},
			{
				title: "Edit",
				text: [
					"Edit cash transactions.",
					"Validation messages will be the same as the Add Cash Transaction page.",
					"Users can hover over “information” icon to know more about the field.",
					"Save button - It will save the data in the database and go back to the Cash Transaction Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Cash Transaction Listing page."
				],
				image: [
					{ image: all_cash_transactions_edit_img, caption: '' }
				],
			},
			{
				title: "Delete",
				text: [
					"Delete button - It will delete the Cash Transaction. After deleting the entry “Successful” message will be displayed and it will navigate to the Cash Transaction listing page.",
					"Cancel button - It will cancel the entry and go back to the Cash Transaction listing page."
				]
			},
			{
				title: "Advance Filter",
				text: [
					"Users can filter transactions by applying advance filter criteria.",
					"Apply - Apply advance filter criteria and accordingly result will be displayed on the cash transaction listing page.",
					"Clear - Clear all data on the page.",
					"Cancel - Navigate back to transaction listing page."
				],
				image: [
					{ image: all_cash_transactions_advance_filter_img, caption: '' }
				],
			},
			{
				title: "Reconcile Mode",
				text: [
					"Reconcile transactions through this page.",
					"Users will search and select rows for trade transactions.",
					"Filter option also available.",
					"Once a user clicks on any transaction it will display Journal Log entries for that transaction.", [
						"To view detailed cash transactions they will click on the View button."
					]
				],
				image: [
					{ image: reconcile_cash_transactions, caption: '' },
					{ image: journal_logs, caption: '' }
				],
			}
		]
	},
	{
		title: "Reconciliation",
		text: [
			"On Reconciliation page, following items will be displayed:",
			[
				"ID",
				[
					"TTE Transaction ID",
					"Mapping Type",
					"Transaction Ref",
					"Face Value",
					"Debit",
					"Credit",
					"Traded On",
					"Posted On",
					"Reconciliation On",
					"Exchange ID",
					"Actions",
					[
						"Unlink",
						"View"
					]
				],
				"Name",
				"Bank Name",
				"Account Number/IBAN",
				"Transaction Details",
				"Transaction Type",
				"Debit(+)",
				"Credit(-)",
				"Identified Category",
				"Exchange ID",
				"Organisation/ People Name",
				"Role",
				"Related Organisation/People Name",
				"Related Role",
				"Status"
			],
			"Actions",
			[
				"Reconcile - Clicking on the icon will navigate to the Reconcile page.",
				"View Cash Transaction - Clicking on the view icon will navigate to the View Cash Transaction page.",
				"Edit - Clicking on the Edit icon will navigate to the Edit Cash Transaction page.",
				"Delete - Clicking on the Delete icon will navigate to the Delete Cash Transaction page."
			],
			"Buttons:",
			[
				"Multiple Reconcile: Redirects to reconcile multiple transactions page.",
				"Export: Export the files in CSV format.",
				"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000."
			],
			"Filter options available for all columns except Action columns.",
			"Search"
		],
		image: [
			{ image: reconciliation_list_img, caption: '' }
		],
		child: [
			{
				title: "Reconcile",
				text: [
					"Users can reconcile and the fields are:",
					[
						"ID",
						"Role",
						"Organisation",
						"Related Role",
						"Related Organisation",
						"Date",
						"Amount"
					],
					"Users can search and select on row of Trade Transaction and the filters would be:",
					[
						"ID",
						"Exchange ID",
						"Transaction Ref",
						"Traded",
						"Posted"
					],
					"To view journal entries they will have to select the trade transaction.",
					"Option to view the complete Journal log for the specific Journal",
					"Cancel button - It will cancel the entry and go back to the Reconciliation page."
				],
				image: [
					{ image: reconcile_cash_transactions, caption: '' },
					{ image: journal_logs, caption: '' }
				],
			},
			{
				title: "View Cash Transaction",
				text: [
					"Users can view the Cash Transaction and the fields are:",
					[
						"Bank Account",
						"Tag",
						"Entity Type",
						"Org. Role",
						"Organisation",
						"Related Entity Type",
						"Rel. Org. Role",
						"Rel. Organisation",
						"Cash Transaction Classification",
						"Transaction Type",
						"Transaction Details",
						"Face Value",
						"Currency",
						"Posted Date",
						"Balance",
						"Value Date",
						"Exchange Ref ID",
						"Customer Reference",
						"Bank Reference"
					],
					"Users can also view the ‘Reconciled with trade Transactions’ and the fields are:",
					[
						[
							"ID",
							"Mapping Type",
							"Transaction Ref",
							"Journal",
							"Face Value",
							"Journal Value",
							"Traded On",
							"Posted On",
							"Reconciliation On",
							"Exchange ID",
							"Actions",

						],
						"Back Button - It will  go back to the Reconciliation page.",
					],
				],
				image: [
					{ image: reconciliation_view_cash_img, caption: '' }
				],
			},
			{
				title: "Edit Cash Transaction",
				text: [
					"Users can edit the Cash Transaction and the fields are:",
					[
						"Bank Account",
						"Tag",
						"Entity Type",
						"Org. Role",
						"Organisation",
						"Related Entity Type",
						"Rel. Org. Role",
						"Rel. Organisation",
						"Cash Transaction Classification",
						"Transaction Type",
						"Transaction Details",
						"Face Value",
						"Currency",
						"Posted Date",
						"Balance",
						"Value Date",
						"Exchange Ref ID",
						"Customer Reference",
						"Bank Reference",
						"Select or Add Field",
						"Enter Field Value"
					],
					"Save button - It will save the data in the database and go back to the Reconciliation page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry, “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Reconciliation page."
				],
				image: [
					{ image: reconciliation_edit_cash_img, caption: '' }
				],
			},
			{
				title: "Delete Cash Transaction",
				text: [
					"User would be able to delete the Cash Transaction and it’s data, when clicked on the delete icon.",
					[
						"The delete button would only be enabled if the word ‘CONFIRM’ is written."
					],
					"Delete Button - Would save the changes and return to the reconciliation page.",
					"Cancel Button - Would return to the reconciliation page without deleting."
				]
			}
		]
	},
	{
		title: "Reports",
		text: [
			"On Reports page following information will be displayed:",
			[
				'ID',
				'Report Name',
				'Code',
				'Type',
				'Currency',
				'Last Generated By',
				'Last Created Date'
			],
			"Actions:",
			[
				"Save to Menu - Clicking on this icon would save the report.",
				"Preview Report - Clicking on this icon would navigate to the preview report page.",
				"Edit - Clicking on this icon will navigate to the edit report page.",
				"Delete - Clicking on this icon will navigate to the delete report page."
			],
			"Show only My Report - Clicking on this button user can view only their reports. If they want to view all reports they will need to click on “Show all Reports”.",
			"New button - Clicking on it will navigate to Add Report page",
			"Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.	",
			"Filter options available for all columns except Action columns.",
			"Search"
		],
		image: [
			{ image: reports_list_img, caption: '' }
		],
		child: [
			{
				title: "Add Report",
				text: [
					"On Add Report page we will display following",
					[
						"Button:",
						["Trade",
							["Name", "Code", "Description", "Type: Would show relevant information according to the type selected.",
								["Global", "Single", "Multiple", "Complex", "Combined Reports"],
								"Journal Type",
								["All", "P&L", "B/S"],
								"Group On",
								["Journals", "Models"],
								"Currency",
								["Ledger", "Transaction"],
								"Button:",
								[
									"Organisation/People - Relevant fields would be shown for organisation and people",
									"Related Organisation/People - Relevant fields would be shown for organisation and people"],
								"Generate On:",
								[
									"All Unarchived Data", "Date Range/s", "Financial Year", "Period"],
								"Buttons:",
								[
									"Preview Data - Navigate to Preview Data page",
									"Preview Report  - Navigate to Preview Report page",
									"Save button - It will save the data in the database and go back to the Report Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
									"Cancel button - It will cancel the entry and go back to the Report Listing page."
								],

							],
							{ image: [{ image: reports_add_img, caption: '' }] },
						],
						[
							"Cash",
							[
								"Name", "Code", "Description", "Button:",
								[
									"Organisation/People - Relevant fields would be shown for organisation and people",
									"Related Organisation/People - Relevant fields would be shown for organisation and people"
								],
								"Bank/s", "Bank Account/s", "Cash Transaction Classification/s", "Transaction Type/s", "Currency/s", "Tag/s", "Exchange ID", "Bank Reference", "Customer Reference", "Org/People Bank IBAN", "Org/People Bank Account No", "Select Additional Columns for Export", "Generate On:",
								["All Unarchived Data", "Date Range/s", "Financial Year"],
								"Button",
								["Preview Report  - Navigate to Preview Report page",
									"Save button - It will save the data in the database and go back to the Report Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
									"Cancel button - It will cancel the entry and go back to the Report Listing page."
								],
								{ image: [{ image: reports_add_cash_img, caption: '' }] },
							],
						],
						[
							"Audit",
							[
								`Name`, `Code`, `Description`, `Button:`,
								[
									`Organisation/People - Relevant fields would be shown for organisation and people`,
									`Related Organisation/People - Relevant fields would be shown for organisation and people`
								],
								`Bank/s`, `Bank Account/s`, `Cash Transaction Classification/s`, `Transaction Type/s`, `Currency/s`, `Tag/s`,
								`Exchange ID`, `Bank Reference`, `Customer Reference`, `Org/People Bank IBAN`, `Org/People Bank Account No`, `Select Additional Columns for Export`, `Generate On:`,
								[`All Unarchived Data`, `Date Range/s`, `Financial Year`],
								`Audit Group On`,
								[`Daily`, `Weekly`, `Monthly`, `Yearly`],
								`Button`,
								[
									`Preview Report  - Navigate to Preview Report page`,
									`Save button - It will save the data in the database and go back to the Report Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
									`Cancel button - It will cancel the entry and go back to the Report Listing page.`
								],
								{ image: [{ image: reports_audit, caption: `` }] },
							]
						]
					],
				],
			},
			{
				title: "Edit Report",
				text: [
					"Edit cash transactions.",
					"Validation messages will be the same as the Add Report Listing page.",
					"Users can hover over “information” icon to know more about the field.",
					"Preview Data - It will redirect to the Preview Data page(27.4.b).",
					"Preview Report - It will redirect to the Preview Report page(27.4.a).",
					"Save button - It will save the data in the database and go back to the Report Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.",
					"Cancel button - It will cancel the entry and go back to the Report Listing page.",
					{ image: [{ image: reports_edit_img, caption: '' }] },
				],
			},
			{
				title: "Delete Report",
				text: [
					"Delete button - It will delete the Report. After deleting the entry, “Successful” message will be displayed and it will navigate to the Report listing page.",
					"Cancel button - It will cancel the entry and go back to the Report listing page.",
				],
			},
			{
				title: "Preview Report",
				text: [
					"Redirects to the Preview Report page",
					{ image: [{ image: reports_preview, caption: '' }] },
					"Explain Preview Data button",
					{ image: [{ image: reports_preview_data, caption: '' }] },
					"Edit Report Filters and Filter Settings",
					{ image: [{ image: reports_filters, caption: '' }] },
					"Export Report to CSV",
					"Export Report to PDF"
				],
			}
		],

	},
	{
		title: "Favourite",
		text: [
			"The reports marked as favourite will be displayed as favourite sub menu",
			"Users will view all the data of the selected report.",
			"Edit Filters",
			"Preview Data",
			"Export CSV - Export in CSV format.",
			"Export PDF - Export in PDF format."
		],
		image: [
			{ image: favorite__img, caption: '' }
		],
		child: [
			{
				title: "Preview Data",
				text: [
					"Users can preview data of the selected report.",
					"Export CSV - Export in CSV format.",
					"Export PDF - Export in PDF format.",
					"Preview Report - Back to Report page.",
					"Edit Filters - Can edit filters and accordingly result will be displayed."
				],
				image: [
					{ image: favorite_preview_data_img, caption: '' }
				],
			},
			{
				title: "Edit Filters",
				text: [
					"Edit filters and accordingly reports will be displayed.",
				],
				image: [
					{ image: favorite_edit_filters_img, caption: '' }
				],
			},
		]
	},
	{
		title: "FX Currencies[Super Admin]",
		text: [
			`On FX currencies listing page following information will be displayed:`,
			[`ID`, `Currency Name`, `Currency Code`, `Currency Symbol`, `Status`, `Actions`],
			`Actions`,
			[`Edit - Clicking on the edit button will navigate to the Edit Currency page.`, `Delete - Clicking on the delete button will navigate to the Delete Currency page.`],
			`New Button - Clicking on the add button will navigate to the Add Currency page.`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.`,
			`Filter options available for all columns except Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: fx_currency_list, caption: '' }
		],
		child: [
			{
				title: `Add`,
				text: [
					`Users can add New Currency on this page.`,
					`If data input is not correct then it will display error messages.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Currency Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Cash Transaction Listing page.`
				],
				image: [
					{ image: fx_currency_add, caption: '' }
				]
			},
			{
				title: `Edit`,
				text: [
					`Edit currencies.`,
					`Validation messages will be the same as the Add Currency page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Currency Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Currency Listing page.`
				]
			},
			{
				title: `Delete`,
				text: [
					`Delete button - It will delete the Currency. After deleting the entry “Successful” message will be displayed and it will navigate to the Currency listing page.`,
					`Cancel button - It will cancel the entry and go back to the Currency listing page.`
				]
			},
		]
	},
	{
		title: 'FX Currencies[Ledger Admin]',
		text: [
			`On FX currencies listing page following information will be displayed:`,
			[`ID`, `Currency Name`, `Currency Code`, `Currency Symbol`, `FX Rate Source`, `Status`, `Actions`],
			`Status: Confirmation dialog box will open at the time of Activate or Deactivate back.`,
			`Actions:`,
			[
				`FX Rate Source - Clicking on the FX rate source button will navigate to the Manage API page.`,
				`View History - Clicking on the delete button will navigate to the View History page.`
			],
			`Bulk Edit Manual Rates Button - Clicking on the button will allow the user to alter multiple records manually`,
			`Import Rate History Button - Clicking on the button will navigate to the import rate history page.`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.`,
			`Filter options available for all columns except Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: fx_currency_list_ledger_admin, caption: '' }
		],
		child: [
			{
				title: `Manage API`,
				text: [
					`User can configure Exchange rate, either by selecting the predefined currency or by manually entering the exchange rate.`,
					`Save - It will save the data in the database and go back to the listing page. Save button will only be enabled after it fulfills all the validations set on this page. After updating the entry “Successful” message will be displayed.`,
					`Cancel - It will cancel the entry and go back to the listing page.`
				],
				image: [
					{ image: fx_currency_ledger_admin_api_management, caption: '' }
				]
			},
			{
				title: `View History`,
				text: [
					`User can view the history of the record and the fields are:`,
					[`ID`, `Date`, `Currency Code`, `Rate`],
					`User can apply filter by selecting a particular date or a date range to view the records on that particular date/s as well as can also filter through currencies.`,
					`Export Button: Download the files in the CSV format.`,
					`Back to List: t will cancel the entry and go back to the Listing page.`
				],
				image: [
					{ image: fx_currency_ledger_admin_view_history, caption: '' }
				]
			},
			{
				title: `Bulk Edit Manual Rates`,
				text: [
					`User can edit manual rates in bulk by adding new rates against the current rates.`,
					`User can choose date or date range for editing manual rates.`,
					`Save - It will save the data in the database and go back to the listing page. Save button will only be enabled after it fulfills all the validations set on this page. After updating the entry “Successful” message will be displayed.`,
					`Cancel - It will cancel the entry and go back to the listing page.`
				],
				image: [
					{ image: fx_currency_ledger_admin_bulk_edit, caption: '' }
				]
			}
		]
	},
	{
		title: `Organisation Status`,
		text: [
			`On Organisation status page, following information will be displayed:`,
			[`ID`, `Status Name`, `No. of Organisations`, `Code`, `Last Updated By`, `Last Updated On`],
			`Actions:`,
			[
				`Edit - Clicking on the Edit icon will navigate to the Edit Status page.`,
				`Delete - Clicking on the Delete icon will navigate to the Delete Status page.`
			],
			`Buttons:`,
			[
				`New - Clicking on it will navigate to the New Organisation status page.`,
				`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.`
			],
			`Filter options available for all columns except Action columns.`,
			`Search`
		],
		image: [
			{ image: organisation_status_list, caption: '' }
		],
		child: [
			{
				title: `New`,
				text: [
					`Users can add New Organisation Status on this page.`,
					`If data input is not correct then it will display error messages.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Buttons:`,
					[
						`Save - It will save the data in the database and go back to the Status page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
						`Cancel - It will cancel the entry and go back to the Status page.`
					]
				],
				image: [
					{ image: organisation_status_add, caption: `` }
				]
			},
			{
				title: `Edit`,
				text: [
					`Edit Organisation Status.`,
					`Validation messages will be the same as the Status page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Organisation Status Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Organisation Status Listing page.`
				]
			},
			{
				title: `Delete`,
				text: [
					`Only delete those entries where the Delete icon is available.`,
					`Delete button - It will delete the entry. After deleting the entry “Successful” message will be displayed and it will navigate to the Status page.`,
					`Cancel button - It will cancel the entry and go back to the Status page.`
				]
			}
		]
	},
	{
		title: `Rating Agencies`,
		text: [
			`Would be a part of Map Organisation with current Ledger, when the role is selected to be ‘Rating Agency’.`,
			`Rating Table`,
			[
				`Select Rating[To Map]`,
				`Mapping[Short/Long]`,
				`Rating`,
				[
					`Short Rating`, `Long Rating`
				],
				`Date Range`,
				`Description`
			]
		],
		image: [
			{ image: rating_agency, caption: `` }
		]
	},
	{
		title: `TTE Manual Journal Log`,
		text: [
			`On Manual Journal Log listing page following information will be displayed:`,
			[`ID`, `Model Code`, `Model`, `Journal Code`, `Journal`, `Transaction ID`, `Transaction Type`, `Debit`, `Credit`, `Created By`, `Last Updated On`, `Actions`],
			`Actions`,
			[
				`Edit - Clicking on the Edit icon will navigate to the Edit Manual Journal Log page.`,
				`Delete - Clicking on the Delete icon will navigate to the Delete Manual Journal Log page.`,
				`Reverse Manual Journal Entry - Clicking on the Reverse icon will navigate to the Reverse Manual Journal Log page.`
			],
			`New button - Clicking on it will navigate to Add Manual Journal Log page.`,
			`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.`,
			`Filter options available for all columns except Action columns.`,
			`Pin columns (Left, Right, No Pin), Autosize particular columns or all columns, Reset Columns`,
			`Search`
		],
		image: [
			{ image: menual_journal_log_list, caption: `` }
		],
		child: [
			{
				title: `New`,
				text: [
					`Users can add New Manual Journal Log on this page.`,
					`If data input is not correct then it will display error messages.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Manual Journal Log listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Manual Journal Log Listing page.`
				],
				image: [
					{ image: manual_journal_log_add, caption: `` }
				]
			},
			{
				title: `Edit`,
				text: [
					`Edit Manual Journal Log.`,
					`Validation messages will be the same as the Manual Journal Log page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Manual Journal Log listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Manual Journal Log listing page.`
				]
			},
			{
				title: `Delete`,
				text: [
					`Delete button - It will delete the Cash Transaction. After deleting the entry “Successful” message will be displayed and it will navigate to the Cash Transaction listing page.`,
					`Cancel button - It will cancel the entry and go back to the Cash Transaction listing page.`
				]
			},
			{
				title: `Reverse`,
				text: [
					`User can reverse any Manual Journal Log entry.`,
					`If data input is not correct then it will display error messages.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Manual Journal Log listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel tvthe entry and go back to the Manual Journal Log Listing page.`
				],
				image: [
					{ image: reverse_manual_journal_log, caption: `` }
				]
			}
		]
	},
	{
		title: `Triggers`,
		text: [
			`On Triggers listing page following information will be displayed:`,
			[`ID`, `Trigger Name`, `When to execute`, `Mapping`, `Transaction Status`, `Model`, `Last Updated By`, `Last Updated Date`],
			`Actions`,
			[
				`Edit - Clicking on the Edit icon will navigate to the Edit Triggers page.`,
				`Delete - Clicking on the Delete icon will navigate to the Delete Triggers page.`
			],
			`Buttons`,
			[
				`New - Clicking on it will navigate to the New Organisation status page.`,
				`Records display per page - Choose the number of records that they want to display on the page like 500, 1000 or 10000.`
			]
		],
		image: [
			{ image: trigger_list, caption: `` }
		],
		child: [
			{
				title: `New`,
				text: [
					`Users can add New Trigger on this page.`,
					`If data input is not correct then it will display error messages.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Trigger Listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Trigger Listing page.`
				],
				image: [
					{ image: trigger_add, caption: `` }
				]
			},
			{
				title: `Edit`,
				text: [
					`Edit Trigger.`,
					`Validation messages will be the same as the add trigger page.`,
					`Users can hover over “information” icon to know more about the field.`,
					`Save button - It will save the data in the database and go back to the Trigger listing page. Save button will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
					`Cancel button - It will cancel the entry and go back to the Trigger listing page.`
				]
			},
			{
				title: `Delete`,
				text: [
					`Only delete those entries where the Delete icon is available.`,
					`Delete button - It will delete the entry. After deleting the entry “Successful” message will be displayed and it will navigate to the Triggers page.`,
					`Cancel button - It will cancel the entry and go back to the Triggers page.`
				]
			}
		]
	},
	{
		title: `Clone Ledger`,
		text: [
			`Clone Ledger check-box would have following items:`,
			[`Clone Data From Ledger`, `Copy Selected Data`]
		],
		image: [
			{ image: clone_ledger, caption: `` }
		]
	},
	{
		title: `Global Settings`,
		text: [
			`On this page, the prices can be set for buy/sell.`,
			`Following values can be configured:`,
			[`Meta Tags`, `Global Settings`, `VAT Settings`, `Trade Settings`, `Trade Calculation Settings`, `Product Settings`, `Report Settings`, `CreditSafe Settings`, `XE Settings`, `SMTP Settings`, `AWS Settings`],
			`Save button - It will save the data in the database. Save button  will only be enabled after it fulfills all the validations set on this page. After saving the entry “Successful” message will be displayed.`,
			`Cancel button - It will cancel the entry.`
		],
		image: [
			{ image: global_settings, caption: `` }
		]
	}
]

export default UserManualConfig
