import React from 'react'
import { EyeOutlined, DownloadOutlined, DeleteOutlined, InfoCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator, redIconStatus } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { UserStore } = useStore()
	var disabled = [6, 7].includes(props.data.status)
	var deleteButton = [1, 5].includes(props.data.status)
	const { openViewDrawer, openDeleteImport, openCancleImport } = props.agGridReact.props.frameworkComponents
	return (
		<div className="action-column">
			{UserStore.checkPermission(115, 'view') && <Button title="View Logs" type="text" onClick={() => openViewDrawer(props.data)} ><EyeOutlined className={redIconStatus.includes(props.data.status) ? 'redVerifyIcon' : 'greenVerifyIcon'} /></Button>}

			<Button title="Download Import File with Log" type="text" onClick={() =>
				window.open(props.data.import_filename_link, '_blank')
			}><DownloadOutlined /></Button>
			
			{(
				!disabled && (
					<>
						{deleteButton ?
							(
								UserStore.checkPermission(115, 'delete') && <Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openDeleteImport(props.data) }}><DeleteOutlined /></Button>
							)
							:
							((
								<Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openCancleImport(props.data) }}><CloseCircleOutlined /></Button>
							))
						}
					</>
				))}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [
		{
			headerName: 'Import Name',
			field: 'import_name',
			tooltipField: 'import_name',
			width: 350
		},
		{
			headerName: 'No of Transactions',
			field: 'total_transactions',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 80
		},
		{
			headerName: 'Imported By',
			field: 'imported_by',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 185
		},
		{
			headerName: 'Imported On',
			field: 'imported_date',
			filter: 'agDateColumnFilter',
			width: 185,
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				comparator: DateComparator
			},
		},
		{
			headerName: 'Status',
			field: 'status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 150
		},
		{
			headerName: 'Duration',
			field: 'run_time',
			cellRendererFramework: function (data) {
				return (
					data.data && (
						<div>
							<span>{data.data.run_time}</span>
							{data.data.exception && (
								<Tooltip title={data.data.exception}>
									<InfoCircleOutlined className="ml-10" size="small" />
								</Tooltip>
							)}
						</div>)
				)
			},
			width: 100
		},
		{
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 150
		}
	],
}
