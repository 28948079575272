import React, { useState } from 'react'
import { observer } from 'mobx-react';
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import useStore from '../../../../store';
import { vsmImportETR, vsmNotify } from '../../../../config/messages';

const CancelComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const { ImportETRStore: { deleteValues, CancelData } } = useStore()
	const [disabled, setDisabled] = useState(true)

	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		CancelData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmImportETR.cancel
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Handle disable / enable button on field change
	const handleChange = () => {
		if (form.getFieldValue("cancel") && form?.getFieldValue("cancel")?.toLowerCase() === "confirm") {
			setDisabled(false)
		} else {
			setDisabled(true)
		}
	}

	// Reset form and close cancle form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return deleteValues && (
		<Modal
			centered
			title={`Cancel Import - #${deleteValues.id} ${deleteValues.import_name} `}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='cancelETRform' loading={saving} htmlType="submit" type="primary" danger>Yes, Delete</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Form form={form} id="cancelETRform" onFinish={handleSubmit}>
				{
					<>

						<Row align="middle">
							<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
							<Col span={20}>
								<h3>Are you sure you want to cancel ongoing Import and Remove all imported transactions ?</h3>
								<Form.Item style={{ marginBottom: 0 }} name="cancel">
									<Input placeholder='Type "CONFIRM" to confirm' onChange={handleChange} />
								</Form.Item>
							</Col>
						</Row>
					</>
				}

			</Form>
		</Modal>
	)
})

export default CancelComponent
