import React from 'react'
import { Button, Tooltip } from 'antd'
import { DeleteOutlined, FileTextOutlined, EditOutlined, StarOutlined, StarFilled } from '@ant-design/icons'
import useStore from '../../../../store'
import { DateComparator } from '../../../../utils/GlobalFunction'

export const ActionRenderer = (props) => {
	const { SavedReportStore, UserStore } = useStore()
	const { openDeleteModal, openEditModal, openPreviewReportModal } = props.agGridReact.props.frameworkComponents
	return (
		<div className="action-column">

			{(props.data && props.data.is_favorite === 1) ? (
				<Tooltip title="Save to Menu">
					<Button type="text" onClick={() => SavedReportStore.saveToMenu(props.data.id, '0').then(data => {
						UserStore.setUserOptions(localStorage.getItem('token'))
					})}><StarFilled className="saveToMenuIcon" /></Button>
				</Tooltip>
			) : (
				<Tooltip title="Save to Menu">
					<Button type="text" onClick={() => SavedReportStore.saveToMenu(props.data.id, '1').then(data => {
						UserStore.setUserOptions(localStorage.getItem('token'))
					})}><StarOutlined className="saveToMenuIcon" /></Button>
				</Tooltip>
			)}

			{UserStore.checkPermission(47, 'preview_report') && (<Tooltip title="Preview Report">
				<Button type="text" onClick={() => openPreviewReportModal(props.data.id)}><FileTextOutlined /></Button>
			</Tooltip>)}

			{/* edit */}
			{UserStore.checkPermission(47, 'edit') && ((props.data && (UserStore.user.id === props.data.created_by || (UserStore.user.user_role && [2].includes(UserStore.user.user_role.user_role_id))))) ? (
				<Tooltip title="Edit"><Button type="text" onClick={() => openEditModal(props.data.id)}><EditOutlined /></Button></Tooltip>
			) : (null)}

			{/* delete */}
			{props.data && (UserStore.user.id === props.data.created_by || (UserStore.user.user_role && [2].includes(UserStore.user.user_role.user_role_id))) ? (
				(props.data && props.data.is_associated) ? (
					UserStore.checkPermission(47, 'delete') && <Tooltip placement="topRight" color={"red"} title={"This report is associated with one or more Combined report, Please remove this report from them to delete this report."}>
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					UserStore.checkPermission(47, 'delete') && (<Tooltip title="Delete">
						<Button type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
					</Tooltip>)
				)
			) : (null)}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Report Name',
		field: 'name',
		width: 150
	}, {
		headerName: 'Code',
		field: 'code',
		valueGetter: (params) => (params.data && params.data.code) ? params.data.code : '',
		width: 130
	}, {
		headerName: 'Type',
		field: 'type',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		valueGetter: (params) => {
			var text_array = ['Global', 'Single', 'Multiple', 'Complex', 'Combined Reports', 'Cash', 'Audit', 'Organisation', 'People']
			return (params.data && params.data.type) ? text_array[params.data.type - 1] : ''
		},
		width: 130
	}, {
		headerName: 'Currency',
		field: 'currency_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		valueGetter: (params) => (params.data && params.data.get_currency) ? params.data.get_currency.currency_name : '',
		width: 150
	}, {
		headerName: 'Last Generated By',
		field: 'last_generated_by',
		valueGetter: (params) => (params.data && params.data.user) ? `${params.data.user.first_name} ${params.data.user.last_name}` : '',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Last Created Date',
		field: 'last_generated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 145
	}]
}
