import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../store'
import { PageHeader, Button } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'
import ImportETRCSV from './component/ImportETRCSV'
import ViewImportLogComponent from './component/ViewImportLogs'
import DeleteComponent from './component/DeleteComponent'
import CancelComponent from './component/CancelComponent'

const ImportETR = observer((props) => {
	const { UserStore, ImportETRStore: { per_page, agGrid, setViewData, setPageSize, setDeleteValues, getList } } = useStore()
	const [importId, setImportId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [deleteImport, setDeleteImport] = useState(false)
	const [autoRefresh, setAutoRefresh] = useState()
	const [cancelImport, setCancelImport] = useState(false)
	const [importDrawer, setImportDrawer] = useState(false)

	const setId = (id) => setImportId(id)

	//open drawer for imoport
	const openImportDrawer = () => {
		setImportDrawer(true)
	}

	// close drawer for import
	const closeImportDrawer = () => setImportDrawer(false)

	// open drawer for view
	const openViewDrawer = (data) => {
		setViewDrawer(true);
		setId(data.id);
		setViewData(data);
	}

	// close drawer for view
	const closeViewDrawer = () => {
		setViewDrawer(false)
		setId();
		setViewData(null);
	}

	// // open delete import modal
	const openDeleteImport = (data) => {
		setDeleteValues(data)
		setDeleteImport(true)
	}

	// close delete import modal
	const closeDeleteImport = () => setDeleteImport(false)

	// open cancle import modal
	const openCancleImport = (data) => {
		setDeleteValues(data)
		setCancelImport(true)
	}

	// close cancle import modal
	const closeCancelImport = () => setCancelImport(false)

	// auto refresh call
	const handleAutoRefresh = () => {
		if (!autoRefresh) {
			getList();
			var temp = setInterval(
				function () { getList(); }
				, 3000);
			setAutoRefresh(temp);
		}
	}

	// stop autoRefresh
	const handleStopAutoRefresh = () => {
		if (autoRefresh) {
			clearInterval(autoRefresh)
			setAutoRefresh(null)
		}
	}

	// clear interval on refresh
	useEffect(() => {
		return () => {
			if (autoRefresh) {
				clearInterval(autoRefresh)
				setAutoRefresh(null)
			}
		};
	}, [autoRefresh])

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('transaction_import_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.ImportETR.path} />
			{(!UserStore.checkPermission(115, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.ImportETR.title}
					extra={[
						autoRefresh ?
							<Button key="1" onClick={handleStopAutoRefresh}>Stop Auto Refresh</Button> :
							<Button key="1" onClick={handleAutoRefresh}>Auto Refresh</Button>,
						UserStore.checkPermission(115, 'import') &&
						<Button key="2"
							onClick={openImportDrawer}>
							Import New CSV
						</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<DeleteComponent
						visible={deleteImport}
						close={closeDeleteImport}
					/>
					<CancelComponent
						visible={cancelImport}
						close={closeCancelImport}
					/>
					<ViewImportLogComponent
						importId={importId}
						visible={viewDrawer}
						setImportId={setImportId}
						setId={setId}
						close={closeViewDrawer}
					/>
					<ImportETRCSV visible={importDrawer} close={closeImportDrawer} />
					<ListComponent
						openViewDrawer={openViewDrawer}
						openCancleImport={openCancleImport}
						openDeleteImport={openDeleteImport}
					/>
				</PageHeader>)}
		</>
	)
})

export default ImportETR
