import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import debounce from 'lodash/debounce'

const EditComponent = observer((props) => {
    const [form] = Form.useForm()
    const [isDisabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)

    const handleSubmit = (data) => {
        setSaving(true)
    }

    // check for valid form values then accordingly make save button disable/enable
    const handleChange = debounce(() => {
        form.validateFields().then((data) => {
            setDisabled(false)
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }, 200)

    const close = () => {
        form.resetFields()
        props.close()
    }

    return (
        <Drawer
            visible={props.visible}
            onClose={close}
            placement='right'
            width={'75%'}
            destroyOnClose={true}
            title='Edit Profile'
            footer={[
                <div className="text-center">
                    <Button form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                    <Button onClick={close}>Cancel</Button>
                </div>
            ]}
        >
            <FormComponent
                form={form}
                onChange={handleChange}
                handleSubmit={handleSubmit}
            />
        </Drawer>
    );
})

export default EditComponent;