import React from 'react'
import { DeleteOutlined, EditOutlined, RetweetOutlined, BankOutlined, EyeOutlined, LoginOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { openDeleteDrawer, openEditDrawer, openBankAccounts, openRoleListing, openViewDrawer, handleProxyLogin } = props.agGridReact.props.frameworkComponents
	const { UserStore: { checkPermission, user } } = useStore()
	return (
		<div className="action-column">

			{checkPermission(41, 'view') && <Button type="text" title="View People" onClick={() => { openViewDrawer(props.data) }}><EyeOutlined /></Button>}

			{checkPermission(41, 'edit') && <Button type="text" title="Edit People" onClick={() => { openEditDrawer(props.data) }}><EditOutlined /></Button>}

			{(!checkPermission(41, 'delete')) ? null : (
				(props.data.match_transactions_count > 0 || props.data.matched_related_transactions_count > 0 || props.data.people_roles_count > 0 || props.data.people_related_roles_count > 0) ? (
					<Tooltip placement="topRight" color={"red"} title={"Sorry you cannot Delete this People as it is associated with Transactions"} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					<Button type="text" title="Delete People" onClick={() => { openDeleteDrawer(props.data) }}><DeleteOutlined /></Button>
				)
			)}

			{checkPermission(41, 'bank_accounts_list') && <Button title={"Bank Accounts"} type="text" onClick={() => { openBankAccounts(props.data) }}><BankOutlined /></Button>}

			{checkPermission(41, 'role_mapping_list') && <Button title={"Role Mapping"} type="text" onClick={() => { openRoleListing(props.data) }}><RetweetOutlined /></Button>}

			{user && user.user_role.user_role_id === 1 && props.data.is_investor_user !== null && <Button title="Proxy Login" type="link" onClick={() => { handleProxyLogin(props.data) }}><LoginOutlined /></Button>}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Name',
		field: 'people_name',
		tooltipField: 'id',
		width: 130
	}, {
		headerName: 'Title',
		field: 'title',
		valueGetter: (params) => (params.data && params.data.title) ? params.data.title : '',
		width: 130
	}, {
		headerName: 'People Role',
		field: 'role_name',
		filter: false,
		sortable: false,
		valueGetter: (params) => (params.data) && (params.data.people_roles && params.data.people_roles.length > 0 ? (params.data.people_roles.map(x => x.role_name)) : null),
		width: 130
	}, {
		headerName: 'Phone',
		field: 'phone',
		width: 180,
		filter: false,
		sortable: false,
		tooltipValueGetter: (params) => {
			return params.data && params.data.phone && params.data.phone.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
		},
		valueGetter: (params) => (
			params.data && params.data.phone && params.data.phone.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
		),
	}, {
		headerName: 'Email',
		field: 'email',
		filter: false,
		sortable: false,
		tooltipValueGetter: (params) => {
			return params.data && params.data.email && params.data.email.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
		},
		valueGetter: (params) => (
			params.data && params.data.email && params.data.email.map(x => x.contact_for + ': ' + x.contact_value).join(', ')
		),
		width: 180
	}, {
		headerName: 'PPS Number',
		field: 'pps_number',
		valueGetter: (params) => (params.data && params.data.pps_number) ? params.data.pps_number : "",
		width: 130
	}, {
		headerName: 'Address',
		field: 'address',
		width: 200,
		filter: false,
		sortable: false,
		tooltipField: 'address'
	}, {
		headerName: 'Created By',
		field: 'created_by',
		valueGetter: (params) => (params.data && params.data.created_by) ? params.data.created_by : "",
		width: 185
	}, {
		headerName: 'Exchange Ref ID',
		field: 'exchange_ref_id',
		width: 150
	}, {
		headerName: 'Last Updated On',
		field: 'last_updated',
		valueGetter: (params) => (params.data && params.data.last_updated) ? params.data.last_updated : null,
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			suppressAndOrCondition: true,
		},
		width: 185
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 200,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 200
	}]
}
