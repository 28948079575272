import { createContext, useContext } from "react"
import UserStore from "./UserStore"
import JournalGroupStore from "./TradeTransaction/Administration/JournalGroupStore"
import TaxSettingStore from "./TradeTransaction/Administration/TaxSettingStore"
import DashboardStore from "./DashboardStore"
import TransactionTypeStore from "./TradeTransaction/Administration/TransactionType/TransactionTypeStore"
import TransactionSubTypeStore from "./TradeTransaction/Administration/TransactionType/TransactionSubTypeStore"
import TransactionFieldStore from "./TradeTransaction/Administration/TransactionFieldStore"
import TransactionFieldMappingStore from "./TradeTransaction/Administration/TransactionType/TransactionFieldMappingStore"
import JournalsStore from "./TradeTransaction/Administration/JournalsStore"
import TransactionModelStore from "./TradeTransaction/Administration/TransactionModelStore"
import AlgorithmsStore from "./TradeTransaction/Administration/AlgorithmsStore"
import ImportStore from "./TradeTransaction/Administration/ImportStore"
import OrganisationStore from "./Organisations/OrganisationStore"
import RoleStore from "./Organisations/Administration/RoleStore"
import LedgerStore from "./Setup/Ledgers/LedgerStore"
import LedgerDetailStore from './Setup/Ledgers/LedgerDetailStore'
import UserManagementStore from './Setup/UserManagementStore'
import RoleManagementStore from './Setup/RoleManagementStore'
import LedgerBankAccountStore from './Setup/Ledgers/BankAccountStore'
import RoleMappingStore from "./Organisations/RoleMappingStore"
import OrgBankAccountStore from "./Organisations/OrgBankAccountStore"
import OrgStatusStore from "./Organisations/OrgStatusStore"
import AuditLogStore from './Setup/Ledgers/AuditLogStore'
import AllTransactionStore from "./TradeTransaction/AllTransactionStore"
import SavedReportStore from "./Reports/SavedReportStore"
import OldImportStore from "./TradeTransaction/Administration/OldImportStore"
import ClassificationStore from "./CashTransaction/ClassificationStore"
import CashTransactionStore from "./CashTransaction/CashTransactionStore"
import ReconciliationStore from "./CashTransaction/ReconciliationStore"
import CTEImportStore from './CashTransaction/ImportStore'
import RegularExpressionStore from './CashTransaction/RegularExpressionStore'
import PeopleStore from './People/PeopleStore'
import PeopleBankAccountStore from './People/BankAccountStore'
import PeopleRoleMappingStore from './People/RoleMappingStore'
import PeopleRolesStore from './People/Administration/RoleStore'
import FXCurrenciesStore from './Administration/FXCurrenciesStore'
import ManualJournalLogStore from "./TradeTransaction/Administration/ManualJournalLog"
import TriggerStore from './TradeTransaction/Administration/TriggerStore'
import GlobalSettingStore from './Setup/GlobalSettingStore'
import TransactionItemsStore from "./TradeTransaction/Administration/TransactionItemsStore"
import IPSettingsStore from "./Setup/IPSettingsStore"
import AppsAuthStore from "./AppsAuthStore"
import MenuManagementStore from "./Setup/MenuManagementStore"
import MailTemplateStore from "./Setup/MailTemplateStore"
import ImportETRStore from "./BackOffice/ImportETRStore"
import PurchaseOrderStore from "./BackOffice/PurchaseOrderStore"
import AllETRStore from './BackOffice/AllETRStore'
import MemberStore from "./BackOffice/MemberStore"
import CredebtorStore from "./BackOffice/CredebtorStore"

const AppContext = createContext({
	UserStore: new UserStore(),
	AppsAuthStore: new AppsAuthStore(),
	JournalGroupStore: new JournalGroupStore(),
	TaxSettingStore: new TaxSettingStore(),
	DashboardStore: new DashboardStore(),
	TransactionTypeStore: new TransactionTypeStore(),
	TransactionSubTypeStore: new TransactionSubTypeStore(),
	TransactionFieldMappingStore: new TransactionFieldMappingStore(),
	TransactionFieldStore: new TransactionFieldStore(),
	JournalsStore: new JournalsStore(),
	TransactionModelStore: new TransactionModelStore(),
	AlgorithmsStore: new AlgorithmsStore(),
	ImportStore: new ImportStore(),
	OrganisationStore: new OrganisationStore(),
	RoleStore: new RoleStore(),
	RoleManagementStore: new RoleManagementStore(),
	LedgerStore: new LedgerStore(),
	LedgerDetailStore: new LedgerDetailStore(),
	UserManagementStore: new UserManagementStore(),
	LedgerBankAccountStore: new LedgerBankAccountStore(),
	RoleMappingStore: new RoleMappingStore(),
	OrgBankAccountStore: new OrgBankAccountStore(),
	OrgStatusStore: new OrgStatusStore(),
	AuditLogStore: new AuditLogStore(),
	SavedReportStore: new SavedReportStore(),
	OldImportStore: new OldImportStore(),
	AllTransactionStore: new AllTransactionStore(),
	ClassificationStore: new ClassificationStore(),
	CashTransactionStore: new CashTransactionStore(),
	ReconciliationStore: new ReconciliationStore(),
	CTEImportStore: new CTEImportStore(),
	RegularExpressionStore: new RegularExpressionStore(),
	PeopleStore: new PeopleStore(),
	PeopleBankAccountStore: new PeopleBankAccountStore(),
	PeopleRoleMappingStore: new PeopleRoleMappingStore(),
	PeopleRolesStore: new PeopleRolesStore(),
	FXCurrenciesStore: new FXCurrenciesStore(),
	ManualJournalLogStore: new ManualJournalLogStore(),
	TriggerStore: new TriggerStore(),
	GlobalSettingStore: new GlobalSettingStore(),
	TransactionItemsStore: new TransactionItemsStore(),
	IPSettingsStore: new IPSettingsStore(),
	MenuManagementStore: new MenuManagementStore(),
	MailTemplateStore: new MailTemplateStore(),
	ImportETRStore: new ImportETRStore(),
	PurchaseOrderStore: new PurchaseOrderStore(),
	AllETRStore: new AllETRStore(),
	MemberStore: new MemberStore(),
	CredebtorStore : new CredebtorStore()
})

const useStore = () => useContext(AppContext)

export default useStore
