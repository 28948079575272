import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Pass_lock from '../../../../assets/images/Pass_lock.png'
import InputComponent from '../../../../components/InputComponent';
import { Form, Divider, Button } from 'antd';
import { vsmChangePass } from '../../../../config/Originator/AppsMessage';

const ChangePassword = observer(() => {
    const [form] = Form.useForm()
    const [isDisabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)

    const handleSubmit = (data) => {
        setSaving(true)
    }

    const handleChange = () => {
        form.validateFields().then((data) => {
            setDisabled(true)
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true)
            }
        })
    }

    return (
        <div className='main__change_pass_wrapper'>
            <div className='card_wrapper'>
                <div className='image_wrapper'>
                    <img src={Pass_lock} alt='' />
                </div>
                <p className='text_wrapper'>Change Password</p>
                <div className='form_wrapper'>
                    <Form
                        form={form}
                        layout='vertical'
                        id="addeditform"
                    >
                        <InputComponent
                            required
                            label='Current Password'
                            placeholder='Current Password'
                            type='password'
                            name='old_password'
                            onChange={handleChange}
                            rules={vsmChangePass.validation.old_password}
                        />

                        <InputComponent
                            required
                            label='New Password'
                            placeholder='New Password'
                            type='password'
                            name='new_password'
                            onChange={handleChange}
                            rules={vsmChangePass.validation.new_password}
                        />

                        <InputComponent
                            required
                            label='Confirm New Password'
                            placeholder='Confirm New Password'
                            type='password'
                            name='confirm_password'
                            onChange={handleChange}
                            rules={vsmChangePass.validation.confirm_password}
                        />
                    </Form>
                </div>
                <Divider className='devider_wrapper' />

                <div className='button_wrapper'>
                    <Button style={{ backgroundColor: '#4FC6E0', color: 'white', height: '44px', width: '126px' }} onClick={handleSubmit} loading={saving} disabled={isDisabled}>Submit</Button>
                    <Button style={{ marginLeft: '15px', height: '44px', width: '126px' }}>Cancel</Button>
                </div>
            </div>
        </div>
    );
})

export default ChangePassword;