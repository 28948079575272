import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import PreviewDetailComponent from '../components/PreviewDetailComponent'
import useStore from '../../../../store'

const PreviewReportTableElement = observer((props) => {
	let columnConfig = []
	let total_column = null
	let gridOptions
	let rowData
	let PinnedData = []
	let closing = 0
	let common_closing = null
	let total_periods = 0

	const [visible, setVisible] = useState()
	const [payload, setPayload] = useState()
	const [title, setTitle] = useState()
	const { SavedReportStore } = useStore()

	const closeDetail = () => {
		setVisible(false)
		setPayload(null)
		setTitle(null)
	}

	if (props.rowData) {
		return (
			<>
				{Object.keys(props.rowData).map((table, index) => {
					if (props.form.getFieldValue('group_on') === 'models') {
						gridOptions = {}
						rowData = []
						PinnedData = []
						if (props.rowData[table].list && props.rowData[table].list[0]) {

							if (!common_closing) {
								common_closing = parseFloat(props.rowData[table].opening_balance)
							}
							rowData = props.rowData[table].list
							if (rowData.slice(-1)[0].journal_desc === 'Total') {
								rowData.pop()
							}
							columnConfig = [
								{
									headerName: 'Journal Type', field: 'journal_type', pinned: 'left',
									width: 130,
									valueGetter: (params) => (params.data && params.data.journal_type) ? params.data.journal_type : ""
								},
								{
									headerName: 'Currency', field: 'currency', pinned: 'left',
									width: 130,
									valueGetter: (params) => (params.data && params.data.currency) ? params.data.currency : ""
								},
								{
									headerName: 'Code', field: 'journal_code', pinned: 'left',
									width: 130,
									valueGetter: (params) => (params.data && params.data.journal_code) ? params.data.journal_code : ""
								},
								{
									headerName: 'Description', field: 'journal_desc', pinned: 'left',
									width: 200,
									valueGetter: (params) => (params.data && params.data.journal_desc) ? params.data.journal_desc : ""
								}
							]
							if (props.rowData[table].list[0].periods) {
								total_periods = []
								props.rowData[table].list[0].periods.forEach((x, i) => {
									var temp = {
										headerName: x.period_name,
										children: [
											{
												headerName: 'Debit', field: 'periods.' + i + '.debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												) : ""
											},
											{
												headerName: 'Credit', field: 'periods.' + i + '.credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												) : ""
											},
											{
												headerName: 'Posting', field: 'periods.' + i + '.posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												) : ""
											}
										]
									}

									total_column = { debit_value: 0, credit_value: 0, posting_value: 0 }

									props.rowData[table].list.forEach(x => {
										total_column.debit_value = parseFloat((total_column.debit_value + x.periods[i].debit_value).toFixed(2))
										total_column.credit_value = parseFloat((total_column.credit_value + x.periods[i].credit_value).toFixed(2))
										total_column.posting_value = parseFloat((total_column.posting_value + x.periods[i].posting_value).toFixed(2))
									})
									total_periods.push(total_column)
									columnConfig.push(temp)
								})
								PinnedData.push({
									journal_desc: 'Total',
									periods: total_periods
								})
							} else {
								columnConfig.push({
									headerName: 'Debit', field: 'debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									width: 110,
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									) : ""
								})
								columnConfig.push({
									headerName: 'Credit', field: 'credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									width: 110,
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									) : ""
								})
								columnConfig.push({
									headerName: 'Posting', field: 'posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									width: 110,
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									) : ""
								})

								total_column = { journal_desc: 'Total', debit_value: 0, credit_value: 0, posting_value: 0 }

								props.rowData[table].list.forEach(x => {
									total_column.debit_value = parseFloat((total_column.debit_value + x.debit_value).toFixed(2))
									total_column.credit_value = parseFloat((total_column.credit_value + x.credit_value).toFixed(2))
									total_column.posting_value = parseFloat((total_column.posting_value + x.posting_value).toFixed(2))
									common_closing = parseFloat((common_closing + x.posting_value).toFixed(2))
								})
								PinnedData.push(total_column)

							}
							gridOptions = {
								columnDefs: columnConfig
							}
							return (
								<>
									<div className="preview_table_header">
										<div>{table}</div>
										{(index === 0 && !props.rowData[table].list[0].periods) && <div>Opening Balance : <NumberFormat value={props.rowData[table].opening_balance} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>}
									</div>
									<div className="mb-0" >
										<div className="ag-theme-alpine">
											<AgGridReact
												domLayout="autoHeight"
												pinnedBottomRowData={PinnedData}
												rowData={rowData}
												modules={AllModules}
												columnDefs={gridOptions.columnDefs}
												defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
												columnTypes={LocalGridConfig.columnTypes}
												overlayNoRowsTemplate={vsmCommon.noRecord}
												gridOptions={{ ...LocalGridConfig.options, pagination: false }}
												onGridReady={(params) => props.onGridReady(params, index)}
												onRowClicked={(params) => {
													if (params.data.journal_desc !== 'Total') {
														setTitle(`${params.data.journal_type} - ${params.data.currency} - ${params.data.journal_code} - ${params.data.journal_desc}`)
														setPayload({
															model_code: params.data.model_id,
															journal_code: params.data.journal_code,
															child_currency: params.data.currency
														})
														setVisible(true)
													}
												}}
												onColumnResized={SavedReportStore.onChildnGridChanged}
												onColumnMoved={SavedReportStore.onChildnGridChanged}
												onColumnPinned={SavedReportStore.onChildnGridChanged}
												onSortChanged={SavedReportStore.onChildnGridChanged}
											/>
										</div>
									</div>
								</>
							)
						}
					} else {
						gridOptions = {}
						rowData = []
						PinnedData = []
						closing = props.rowData[table].opening_balance
						if (props.rowData[table].list && props.rowData[table].list[0]) {
							rowData = props.rowData[table].list
							if (rowData.slice(-1)[0].journal_desc === 'Total') {
								rowData.pop()
							}
							columnConfig = [
								{
									headerName: 'Code', field: 'journal_code', pinned: 'left',
									width: 130,
									valueGetter: (params) => (params.data && params.data.journal_code) ? params.data.journal_code : ""
								},
								{
									headerName: 'Description', field: 'journal_desc', pinned: 'left',
									width: 200,
									valueGetter: (params) => (params.data && params.data.journal_desc) ? params.data.journal_desc : ""
								}
							]
							if (props.rowData[table].list[0].periods) {
								total_periods = []
								props.rowData[table].list[0].periods.forEach((x, i) => {
									var temp = {
										headerName: x.period_name,
										children: [
											{
												headerName: 'Debit', field: 'periods.' + i + '.debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												) : ""
											},
											{
												headerName: 'Credit', field: 'periods.' + i + '.credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												)
													: ""
											},
											{
												headerName: 'Posting', field: 'periods.' + i + '.posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
												width: 110,
												cellRendererFramework: (params) => (params.data) ? (
													<NumberFormat value={params.data.periods[i].posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
												) : ""
											}
										]
									}
									total_column = { debit_value: 0, credit_value: 0, posting_value: 0 }

									props.rowData[table].list.forEach(x => {
										total_column.debit_value = parseFloat((total_column.debit_value + x.periods[i].debit_value).toFixed(2))
										total_column.credit_value = parseFloat((total_column.credit_value + x.periods[i].credit_value).toFixed(2))
										total_column.posting_value = parseFloat((total_column.posting_value + x.periods[i].posting_value).toFixed(2))
									})
									total_periods.push(total_column)
									columnConfig.push(temp)
								})
								PinnedData.push({
									journal_desc: 'Total',
									periods: total_periods
								})
							} else {
								columnConfig.push({
									headerName: 'Debit', field: 'debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									) : ""
								})
								columnConfig.push({
									headerName: 'Credit', field: 'credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									width: 110,
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									)
										: ""
								})
								columnConfig.push({
									headerName: 'Posting', field: 'posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
									width: 110,
									cellRendererFramework: (params) => (params.data) ? (
										<NumberFormat value={params.data.posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
									) : ""
								})

								total_column = { journal_desc: 'Total', debit_value: 0, credit_value: 0, posting_value: 0 }

								props.rowData[table].list.forEach(x => {
									total_column.debit_value = parseFloat((total_column.debit_value + x.debit_value).toFixed(2))
									total_column.credit_value = parseFloat((total_column.credit_value + x.credit_value).toFixed(2))
									total_column.posting_value = parseFloat((total_column.posting_value + x.posting_value).toFixed(2))
									closing = parseFloat((closing + x.posting_value).toFixed(2))
								})
								PinnedData.push(total_column)

							}
							gridOptions = {
								columnDefs: columnConfig
							}
							return (
								<>
									<div className="preview_table_header">
										<div>{table}</div>
										{(!props.rowData[table].list[0].periods) && (
											<div>Opening Balance : <NumberFormat value={(props.rowData[table].opening_balance).toString()} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
										)}
									</div>
									<div className="mb-0">
										<div className="ag-theme-alpine">
											<AgGridReact
												domLayout="autoHeight"
												pinnedBottomRowData={PinnedData}
												rowData={rowData}
												modules={AllModules}
												columnDefs={gridOptions.columnDefs}
												defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
												columnTypes={LocalGridConfig.columnTypes}
												overlayNoRowsTemplate={vsmCommon.noRecord}
												gridOptions={{ ...LocalGridConfig.options, pagination: false }}
												onGridReady={(params) => props.onGridReady(params, index)}
												onRowClicked={(params) => {
													if (params.data.journal_desc !== 'Total') {
														setTitle(`${params.data.journal_type} - ${params.data.journal_code} - ${params.data.journal_desc}`)
														var extra_payload = {}
														if (props.report_id) {
															extra_payload.report_id = parseInt(props.report_id)
															SavedReportStore.previewReportID = props.report_id
														} else {
															SavedReportStore.previewReportID = null
														}
														setPayload({
															journal_code: params.data.journal_code,
															child_currency: params.data.currency,
															...extra_payload
														})
														setVisible(true)
													}
												}}
												onColumnResized={SavedReportStore.onChildnGridChanged}
												onColumnMoved={SavedReportStore.onChildnGridChanged}
												onColumnPinned={SavedReportStore.onChildnGridChanged}
												onSortChanged={SavedReportStore.onChildnGridChanged}
											/>
										</div>
									</div>
									{(props.rowData[table].list[0].periods) ? null : (
										<div className="preview_table_footer">
											<div></div>
											<div>Closing Balance : <NumberFormat value={closing} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
										</div>
									)}
								</>
							)
						}
					}
					return null
				})}
				{(common_closing !== null) && (
					<div className="preview_table_footer">
						<div></div>
						<div>Closing Balance : <NumberFormat value={common_closing} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
					</div>
				)}
				{payload && <PreviewDetailComponent visible={visible} close={closeDetail} payload={payload} title={title} />}
			</>
		)
	}
	return null
})

export default PreviewReportTableElement
