import { notification } from "antd"

const vsmNotify = {
    success: (data) => {
        notification.success({ placement: "bottomRight", duration: 3, ...data })
    },
    error: (data) => {
        notification.error({ placement: "bottomRight", duration: 3, ...data })
    }
}

const vsmChangePass = {
    add: "API Settings saved successfully.",
    validation: {
        old_password: [
            { required: true, message: "Current Password cannot be empty." },
            {
                pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
                message:
                    "Password must have one lowercase, one uppercase, one digit and one special character(_, -, @).",
            },
        ],
        new_password: [
            { required: true, message: "New Password cannot be empty." },
            {
                pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/,
                message:
                    "Password must have one lowercase, one uppercase, one digit and one special character(_, -, @).",
            },
        ],
        confirm_password: [
            { required: true, message: "Confirm New Password cannot be empty." },
            ({ getFieldValue }) => ({
                validator(rule, value) {
                    if (!value || getFieldValue("new_pass") === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        "New Password and Confirm Password does not match."
                    );
                },
            }),
        ],
    }
}

export {
    vsmNotify,
    vsmChangePass
}