import { Button, Form, PageHeader, Spin } from 'antd'
import React from "react";
import AddComponent from './components/AddComponent';
import EditComponent from './components/EditComponent';
import WidgetComponent from './components/WidgetComponent';
import { observer } from 'mobx-react';
import useStore from '../../store';
import { vsmDashboard, vsmNotify } from '../../config/messages';
import DeleteComponent from './components/DeleteComponent';

const Dashboard = observer((props) => {
	const [form] = Form.useForm()
	const { DashboardStore } = useStore()

	class DashboardClass extends React.PureComponent {
		static defaultProps = {
			className: "layout",
			cols: 12,
			rowHeight: 30,
			onLayoutChange: function () { }
		};

		constructor(props) {
			super(props);

			this.state = {
				cols: 12,
				layout: [],
				newCounter: 0,
				openAddModal: false,
				openEditModal: false,
				openDeleteModal: false,
				deleteWidgetData: null,
				apiData: null,
				newLayout: null,
				loader: true
			};

			this.onLayoutChange = this.onLayoutChange.bind(this);
			this.onAddItem = this.onAddItem.bind(this);
			this.onBreakpointChange = this.onBreakpointChange.bind(this);
			this.openAddModal = this.openAddModal.bind(this);
			this.closeAddModal = this.closeAddModal.bind(this);
			this.openEditModal = this.openEditModal.bind(this);
			this.closeEditModal = this.closeEditModal.bind(this);
			this.openDeleteModal = this.openDeleteModal.bind(this);
			this.closeDeleteModal = this.closeDeleteModal.bind(this);
		}

		onAddItem(data) {
			const addWidgetData = {
				'layout': {
					i: JSON.stringify(data),
					x: (this.state.layout.length * 3) % (this.state.cols || 12),
					y: 0, // puts it at the bottom
					w: data.widget_type === 'graphical' ? 6 : data.widget_type === 'reports' ? 9 : 3,
					h: data.widget_type === 'graphical' || data.widget_type === 'reports' ? 7 : 4,
					"moved": false,
					"static": false,
					'type': data.type
				},
				'data': data
			}
			DashboardStore.addWidget(addWidgetData).then(() => {
				this.getDashboardData();
				this.closeAddModal()
				vsmNotify.success({
					message: vsmDashboard.add
				})
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			})
			this.setState({
				// Increment the counter to ensure key is always unique.
				newCounter: this.state.newCounter + 1
			});
		}

		onEditItem(data) {
			const editData = {
				layout: {
					i: JSON.stringify(data),
					x: DashboardStore.readData.settings_json.x,
					y: DashboardStore.readData.settings_json.y,
					w: DashboardStore.readData.settings_json.w,
					h: DashboardStore.readData.settings_json.h,
					"moved": false,
					"static": false,
					'type': data.type,
				},
				data: data
			}
			DashboardStore.editWidget(editData).then(() => {
				this.getDashboardData()
				this.closeEditModal()
				vsmNotify.success({
					message: vsmDashboard.edit
				})
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors) }
			})
			this.setState({
				// Increment the counter to ensure key is always unique.
				newCounter: this.state.newCounter + 1
			});
		}

		// We're using the cols coming back from this to calculate where to add new items.
		onBreakpointChange(breakpoint, cols) {
			this.setState({
				breakpoint: breakpoint,
				cols: cols
			});
		}

		openEditModal(id) {
			DashboardStore.editKey = id
			DashboardStore.readWidgetData(id).then(data => {
				this.setState({ openEditModal: true, apiData: data })
			})
		}

		// close modal edit widget
		closeEditModal() {
			this.setState({ openEditModal: false })
			form.resetFields()
		}

		openDeleteModal(data) {
			this.setState({
				openDeleteModal: true,
				deleteWidgetData: data
			})
		}

		closeDeleteModal() {
			this.setState({
				openDeleteModal: false,
				deleteWidgetData: null
			})
			form.resetFields()
		}

		onLayoutChange(layout) {
			this.setState({
				newLayout: layout,
			})
			this.props.onLayoutChange(layout); // updates status display

			if (this.state.newLayout) {
				const newPos = {
					"layout": this.state.newLayout
				}
				DashboardStore.positionSave(newPos).then(({ data }) => {
					vsmNotify.success({
						message: vsmDashboard.save
					})
				})
				this.setState({
					newLayout: null
				})
			}
		}

		// opens modal to add widget
		openAddModal() {
			this.setState({ openAddModal: true })
		}

		// close modal to add widget
		closeAddModal() {
			this.setState({ openAddModal: false })
			form.resetFields()
		}

		getDashboardData() {
			DashboardStore.getDefaultDashboard().then(() => {
				this.setState({
					layout: DashboardStore.defaultDashboardData,
					loader: false
				})
			})
		}

		componentDidMount() {
			this.getDashboardData()
		}

		render() {
			return (
				<PageHeader
					title="Dashboard"
					extra={[
						<Button key='2' onClick={this.openAddModal} className='ml-10' type="primary">Add Item</Button>

					]}
				>
					{this.state.loader ?
						<div>
							<Spin size="default" />
						</div>
						:
						<WidgetComponent
							data={this.props}
							layout={this.state.layout}
							layoutChange={this.onLayoutChange}
							editModal={this.openEditModal}
							removeItem={(data) => this.openDeleteModal(data)}
						/>
					}
					{this.state.openAddModal && <AddComponent form={form} visible={this.state.openAddModal} close={() => this.closeAddModal()} onAddItem={(data) => { this.onAddItem(data) }} />}
					{this.state.openEditModal && <EditComponent form={form} visible={this.state.openEditModal} close={() => this.closeEditModal()} onEditItem={(data) => { this.onEditItem(data) }} apiData={this.state.apiData} />}
					{this.state.openDeleteModal && <DeleteComponent form={form} visible={this.state.openDeleteModal} close={() => this.closeDeleteModal()} deleteData={this.state.deleteWidgetData} getData={this.getDashboardData()} />}
				</PageHeader>
			);
		}
	}

	return (
		<DashboardClass form={form} />
	)
})
export default Dashboard