import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Form, Button, Spin } from 'antd'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import { vsmAllETR, vsmNotify } from '../../../../config/messages'
import moment from 'moment'
import { etrTypeObject } from '../../../../utils/GlobalFunction'

const AuthETRComponent = observer((props) => {
	const [form] = Form.useForm()
	const { AllETRStore, AllETRStore: { AuthETR, viewValue } } = useStore()
	const [formDataList, setFormDataList] = useState([])
	const [isDisabledAuth, setDisabledAuth] = useState(true);
	const [isDisabledReject, setDisabledReject] = useState(true);
	const [authLoading, setAuthLoading] = useState(false)
	const [rejectLoading, setRejectLoading] = useState(false)
	const [status, setStatus] = useState(null)
	const [loading, setLoading] = useState(true)

	const handleSubmit = (data) => {
		let temp_data = {};
		if (status === 3) {
			setAuthLoading(true)
		} else {
			setRejectLoading(true)
		}
		if (status && data) {
			temp_data.id = viewValue.id
			temp_data.status = status
			if (status === 4) {
				temp_data.reject_reason = data.reject_reason
			}
		}
		AuthETR(temp_data).then(() => {
			if (status === 3) {
				vsmNotify.success({
					message: vsmAllETR.authorised
				})
			} else {
				vsmNotify.success({
					message: vsmAllETR.rejected
				})
			}
			close();
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
		})
	}

	const close = () => {
		setLoading(true)
		setFormDataList([])
		form.resetFields()
		props.close();
		setDisabledAuth(true)
		setDisabledReject(true)
		setRejectLoading(false)
		setAuthLoading(false)
		AllETRStore.viewValue = null
	}

	useEffect(() => {
		if (viewValue && props.visible) {
			setFormDataList([etrTypeObject[viewValue.etr_type]])
			let b_etr_files = viewValue.b_etr_files;
			if (b_etr_files?.length > 0) {
				b_etr_files.map((obj) => {
					obj.upload_po_for = obj?.debtor?.organisation_name ? obj?.debtor?.organisation_name : "";
					obj.currency = obj?.currency?.currency_name ? obj?.currency?.currency_name : "";
					obj.po_date = obj?.po_date ? moment(obj?.po_date).format("YYYY-MM-DD") : "";
					return null;
				})
			}
			form.setFieldsValue({
				etr_type: viewValue.etr_type,
				face_value: viewValue.face_value,
				term: viewValue.payment_discount_term,
				payment_discount: viewValue.payment_discount_term,
				debtor_id: viewValue.debtor ? viewValue.debtor.id : null,
				b_etr: viewValue.betr_id,
				deliver_to: viewValue.deliver_to ? viewValue.deliver_to.id : null,
				reject_reason: viewValue.reject_reason,
				currency_id: viewValue.currency ? viewValue.currency.id : null,
				invoice_date: viewValue.invoice_date ? moment(viewValue.invoice_date, 'YYYY-MMM-DD') : null,
				expected_date: viewValue.expected_date ? moment(viewValue.expected_date) : null,
				po_no: viewValue.po_no,
				friendly_name: viewValue.friendly_name,
				description: viewValue.description,
				invoice_no: viewValue.invoice_no,
				c_etr_sell_rate: viewValue.c_etr_sell_rate,
				d_etr_purchase_discount: viewValue.d_etr_purchase_discount,
				b_etr_files: b_etr_files,
				batch_no: viewValue.batch_no,
				etr_months: viewValue.etr_months,
				a_f_sell_rate: viewValue.a_f_sell_rate,
				start_date: viewValue.start_date,
				end_date: viewValue.end_date,
				purchase_date: viewValue.purchase_date ? moment(viewValue.purchase_date).format('YYYY-MM-DD') : null,
				asset_name: viewValue.asset_name,
				asset_number: viewValue.asset_number,
				a_f_value: viewValue.a_f_value,
				quantity_numbers: viewValue.quantity_numbers,
				value: viewValue.face_value,
				asset_classification: viewValue.asset_classification,
				supplier_manufacturer: viewValue.supplier_manufacturer,
				desc: viewValue.desc,
			})
			setLoading(false)
		}
	}, [form, viewValue, props.visible]);


	return (
		<Drawer
			visible={props.visible}
			onClose={() => close()}
			placement={'right'}
			destroyOnClose
			width='60%'
			title={`Authorise ETR - #${viewValue ? viewValue.id : ''}`}
			footer={[
				<div key={1}>
					<Button
						form="authALLETRForm"
						className="mr-10"
						htmlType="submit"
						type="primary"
						disabled={isDisabledAuth}
						onClick={() => setStatus(3)}
						loading={authLoading}
					>
						Authorised
					</Button>
					<Button
						form="authALLETRForm"
						className="mr-10"
						htmlType="submit"
						type="primary"
						disabled={isDisabledReject}
						onClick={() => setStatus(4)}
						loading={rejectLoading}
					>
						Rejected
					</Button>
					<Button onClick={() => close()}>Cancel</Button>
				</div>
			]}
		>
			<div className="d-flex align-items-center justify-content-center">
				<Spin size="default" spinning={loading} />
			</div>
			<FormComponent
				form={form}
				isAuth={true}
				id="authALLETRForm"
				handleSubmit={handleSubmit}
				formDataList={formDataList}
				isDisabledAuth={isDisabledAuth}
				setDisabledAuth={setDisabledAuth}
				isDisabledReject={isDisabledReject}
				setDisabledReject={setDisabledReject}
			/>
		</Drawer>
	)
})

export default AuthETRComponent
