import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent';
import { Col, Form, Row, Tooltip } from 'antd'
import useStore from '../../../../store';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { cETRwithoutBETR, dETRwithoutBETR, ETRList } from '../../../../utils/GlobalFunction';
import { PercentageOutlined, InfoCircleOutlined } from '@ant-design/icons';


const FormComponent = observer((props) => {
	const { form, handleSubmit, isAuth = false, formDataList, setDisabledAuth, setDisabledReject, id } = props;
	const [selectbETR, setSelectbETR] = useState(null)
	const [rejectReasonRequired, setrejectReasonRequired] = useState(false)
	const { AllETRStore: { viewValue } } = useStore()


	const handleChange = async () => {
		form.validateFields()
			.then((data) => {
			})
			.catch((e) => {
				if (e.errorFields && e.errorFields.length > 0) {
					setDisabledAuth(true);
					setDisabledReject(true);
				}
			});
		isRejectedReasonRequired();
	};

	const isRejectedReasonRequired = () => {
		let fieldsArr = [];
		setrejectReasonRequired(false);
		setDisabledAuth(false);
		setDisabledReject(true);
		if (formDataList.includes('a-ETR') || formDataList.includes('f-ETR')) {
			fieldsArr = ['debtor_id_check', 'batch_check', 'months_check', 'sell_check', 'start_date_check', 'end_date_check', 'purchase_date_check', 'asset_name_check', 'asset_number_check', 'value_check', 'quantity_number_check', 'asset_classfication_check', 'supplier_manufacturer_check', 'file_support_check']
		}
		if (formDataList.includes('d-ETR')) {
			fieldsArr = ['debtor_id_check', 'b_etr_check', 'deliver_to_check', 'term_check', 'face_value_check', 'invoice_date_check', 'expected_date_check', 'invoice_no_check', 'file_support_check'];
			if (form.getFieldValue('b_etr') !== 0) {
				fieldsArr.push('po_id_check');
			}
			else {
				fieldsArr.push('po_no_check');
				fieldsArr.push('po_attach_check');
			}
		}
		if (formDataList.includes('c-ETR')) {
			fieldsArr = ['debtor_id_check', 'b_etr_check', 'payment_discount_check', 'face_value_check', 'invoice_date_check', 'expected_date_check', 'invoice_no_check', 'file_support_check'];
			if (form.getFieldValue('b_etr') !== 0) {
				fieldsArr.push('po_id_check');
			}
			else {
				fieldsArr.push('po_no_check');
				fieldsArr.push('po_attach_check');
			}
		}
		if (formDataList.includes('b-ETR')) {
			fieldsArr = ['expected_date_check', 'file_support_check', 'd_etr_purchase_discount_check', 'c_etr_sell_rate_check'];
			let b_etr_files = form?.getFieldValue("b_etr_files");
			let keys_to_check = [
				"upload_po_for_check",
				"po_date_check",
				"face_value_check",
				"po_no_check"];
			if (b_etr_files && b_etr_files?.length > 0) {
				b_etr_files.map((obj, index) => {
					keys_to_check.map((keyObj) => {
						if (!obj[keyObj]) {
							setrejectReasonRequired(true);
							setDisabledAuth(true);
							setDisabledReject(false);
						}
						return null;
					})
					return null;
				})
			}
		}

		fieldsArr.map((obj) => {
			if (!form.getFieldValue(obj)) {
				setrejectReasonRequired(true);
				setDisabledAuth(true);
				setDisabledReject(false);
			}
			return null;
		})
	}

	const onFinish = (data) => {
		handleSubmit(data)
	}

	useEffect(() => {
		if (viewValue?.betr?.id === 0 || viewValue?.betr_id === 0) {
			if (viewValue.etr_type === 4) {
				setSelectbETR([dETRwithoutBETR])
			} else if (viewValue.etr_type === 3) {
				setSelectbETR([cETRwithoutBETR])
			}
		} else {
			setSelectbETR([viewValue?.betr])
		}
	}, [viewValue])



	return form && viewValue ? (
		<Form
			form={form}
			layout='vertical'
			id={`${id}`}
			onFinish={onFinish}
			onChange={handleChange}
		>
			<Row gutter={[8, 8]}>
				<Col span={4}>
					<InputComponent
						required
						type="select"
						label="ETR Type"
						name="etr_type"
						placeholder="Select ETR Type"
						options={{
							values: ETRList,
							value_key: "value",
							text_key: "text",
						}}
						tooltip="Choose ETR Type"
						disabled={true}
					/>
				</Col>

				{formDataList.includes('a-ETR') || formDataList.includes('f-ETR') ? (
					<>
						<Col span={4}>
							<InputComponent
								required
								type="select"
								label={
									<div className='checked_value'>
										Select Credebtor
										<Tooltip placement='top' title={'Select the Credebtor name that appears on the invoice you are about to upload.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="debtor_id_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name='debtor_id'
								placeholder="Select Debtor"
								options={{
									values: viewValue.debtor ? [{
										id: viewValue && viewValue.debtor.id,
										organisation_name: viewValue && viewValue.debtor.organisation_name
									}] : [],
									value_key: "id",
									text_key: "organisation_name"
								}}
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label={
									<div className='checked_value'>
										Batch No
										<Tooltip placement="top" title={'A batch number is a designation given to products made in the same manufacturing run.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="batch_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="batch_no"
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label={
									<div className='checked_value'>
										Months
										<Tooltip placement="top" title={formDataList.includes('aETR') ? 'a-ETR invoice month' : 'f-ETR invoice month'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="months_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="etr_months"
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label={
									<div className='checked_value'>
										Sell Rate
										<Tooltip placement="top" title={formDataList.includes('aETR') ? 'a-ETR sell rate' : 'f-ETR sell rate'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="sell_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="a_f_sell_rate"
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								allowClear
								type="select"
								label={
									<div className='checked_value'>
										Currency
										<Tooltip placement='top' title={'The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed'}>
											<InfoCircleOutlined />
										</Tooltip>
									</div>
								}
								name="currency_id"
								options={{
									values: [{
										currency_id: viewValue && viewValue.currency && viewValue.currency.id,
										currency_name: viewValue && viewValue.currency && viewValue.currency.currency_name
									}],
									value_key: "currency_id",
									text_key: "currency_name"
								}}
								disabled
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label={
									<div className='checked_value'>
										Start Date (YYYY/MM/DD)
										<Tooltip placement="top" title={formDataList.includes('aETR') ? 'a-ETR start date' : 'f-ETR start date'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="start_date_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="start_date"
								disabled={isAuth}
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label={
									<div className='checked_value'>
										End Date (YYYY/MM/DD)
										<Tooltip placement="top" title={formDataList.includes('aETR') ? 'a-ETR end date' : 'f-ETR end date'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="end_date_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="end_date"
								disabled={isAuth}
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label={
									<div className='checked_value'>
										Purchase Date (YYYY/MM/DD)
										<Tooltip placement="top" title={formDataList.includes('aETR') ? 'a-ETR purchase date' : 'f-ETR purchase date'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="purchase_date_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="purchase_date"
								disabled={isAuth}
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label={
									<div className='checked_value'>
										Asset Name
										<Tooltip placement="top" title={'An asset is a resource with economic value that an individual or corporation owns or controls with the expectation that it will provide a future benefit.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="asset_name_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="asset_name"
								disabled={isAuth}
							/>
						</Col>
						<Col span={5}>
							<InputComponent
								label={
									<div className='checked_value'>
										Asset Number
										<Tooltip placement="top" title={'An asset number uniquely identifies the product you or your organization purchased.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="asset_number_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="asset_number"
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label={
									<div className='checked_value'>
										Value
										<Tooltip placement="top" title={'Value is the monetary, material, or assessed worth of an asset, good, or service.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="value_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="value"
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label={
									<div className='checked_value'>
										Quantity Number
										<Tooltip placement="top" title={'The measurable or countable property or aspect of things.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="quantity_number_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="quantity_numbers"
								disabled={isAuth}
							/>
						</Col>
						<Col span={5}>
							<InputComponent
								label={
									<div className='checked_value'>
										Asset Classfication
										<Tooltip placement="top" title={'Asset classification is a system for assigning assets into groups, based on a number of common characteristics.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="asset_classfication_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="asset_classification"
								disabled={isAuth}
							/>
						</Col>
						<Col span={6}>
							<InputComponent
								label={
									<div className='checked_value'>
										Supplier/Manufacturer
										<Tooltip placement="top" title={'A supplier is a person, company, or organization that sells or supplies something such as goods or equipment to customers.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="supplier_manufacturer_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="supplier_manufacturer"
								disabled={isAuth}
							/>
						</Col>
						<Col span={10}>
							<InputComponent
								type="textarea"
								name="description"
								label="Description"
								maxLength={1000}
								tooltip={formDataList.includes('aETR') ? 'Add a-ETR description' : 'Add f-ETR description'}
								disabled={isAuth}
							/>
						</Col>
						<Col span={10}>
							<Form.Item
								required
								className="full-width-dragger"
								name="file_support"
								label={
									<div className='checked_value'>
										Invoice File/Document
										<Tooltip placement='top' title={'Attach the pdf of the actual invoice and ensure the details of the invoice actual match the details entered in this form'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="file_support_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
							>
								{viewValue && viewValue.file_support && <a href={`${process.env.React_APP_API_URL + viewValue.file_support.url}`} target={`_blank`}>{viewValue.file_support.name}</a>}
							</Form.Item>
						</Col>
					</>
				) : null}

				{formDataList.includes('b-ETR') ?
					<>
						<Col span={6}>
							<InputComponent
								type='date'
								name='expected_date'
								label={
									<div className='checked_value'>
										Expected Date (YYYY/MM/DD)
										<Tooltip placement='top' title={"Mentioned expected date"}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="expected_date_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								placeholder='Select date'
								className='date_wrapper'
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								label="Friendly Name"
								name="friendly_name"
								tooltip="Add friendly name for b-ETR"
								maxLength={100}
								disabled={isAuth}
							/>
						</Col>
						<Col span={10}>
							<InputComponent
								type="textarea"
								name="description"
								label="Description"
								maxLength={1000}
								tooltip={`Add b-ETR description`}
								disabled={isAuth}
							/>
						</Col>
						<Col span={4}>
							<Form.Item
								className="full-width-dragger"
								name="file_support"
								label={
									<div className='checked_value'>
										Attach Invoice
										<Tooltip placement='top' title={'Attach the pdf of the actual invoice and ensure the details of the invoice actual match the details entered in this form'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="file_support_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
							>
								{(viewValue && viewValue.file_support) ?
									<>
										<a href={`${process.env.React_APP_API_URL + viewValue.file_support.url}`} target={`_blank`}>{viewValue.file_support.name}</a>
									</>
									:
									"N/A"
								}
							</Form.Item>
						</Col>
						<Col span={8}>
							<InputComponent
								disabled={isAuth}
								label={
									<div className='checked_value'>
										d-ETR Monthly Discount Percentage
										<Tooltip placement='top' title={'The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="d_etr_purchase_discount_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="d_etr_purchase_discount"
								placeholder="d-ETR Monthly Discount Percentage"
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								disabled={isAuth}
								label={
									<div className='checked_value'>
										c-ETR Sell Rate Percentage
										<Tooltip placement='top' title={'Your current c-ETR sell rate agreed with Credebt Exchange'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="c_etr_sell_rate_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="c_etr_sell_rate"
								placeholder="c-ETR Sell Rate Percentage"
							/>
						</Col>
						<Col span={24}>
							<Form.List name='b_etr_files'>
								{(fields, { remove }) => {
									return fields && fields.map((field, index) => {
										let formListArr = form?.getFieldValue("b_etr_files");
										let po_file = null
										if (formListArr && formListArr?.length > 0) {
											po_file = formListArr[index]["po_file"];
										}
										return (
											<Row gutter={12}>
												<Col span={4}>
													<InputComponent
														label={
															<div className='checked_value'>
																Upload PO For
																<Tooltip placement="top" title={'Select the Debtor name that appears on the invoice you are about to upload.'}>
																	<InfoCircleOutlined />
																</Tooltip>
																<Form.Item
																	valuePropName="checked"
																	name={[field.name, 'upload_po_for_check']}
																>
																	<Checkbox />
																</Form.Item>
															</div>
														}
														name={[field.name, 'upload_po_for']}
														disabled={isAuth}
													/>
												</Col>
												<Col span={4}>
													<InputComponent
														label={
															<div className='checked_value'>
																Currency
																<Tooltip placement="top" title={'The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed.'}>
																	<InfoCircleOutlined />
																</Tooltip>
															</div>
														}
														name={[field.name, 'currency']}
														disabled={isAuth}
													/>
												</Col>
												<Col span={4}>
													<InputComponent
														label={
															<div className='checked_value'>
																PO Date
																<Tooltip placement="top" title={'Enter the PO date (i.e. not necessarily today’s date).'}>
																	<InfoCircleOutlined />
																</Tooltip>
																<Form.Item
																	valuePropName="checked"
																	name={[field.name, 'po_date_check']}
																>
																	<Checkbox />
																</Form.Item>
															</div>
														}
														name={[field.name, 'po_date']}
														disabled={isAuth}
													/>
												</Col>
												<Col span={4}>
													<InputComponent
														label={
															<div className='checked_value'>
																Face Value
																<Tooltip placement="top" title={'Enter the total invoice value including all delivery charges, support charges, tax, duties, etc.'}>
																	<InfoCircleOutlined />
																</Tooltip>
																<Form.Item
																	valuePropName="checked"
																	name={[field.name, 'face_value_check']}
																>
																	<Checkbox />
																</Form.Item>
															</div>
														}
														name={[field.name, 'face_value']}
														disabled={isAuth}
													/>
												</Col>
												<Col span={4}>
													<InputComponent
														label={
															<div className='checked_value'>
																PO Number
																<Tooltip placement="top" title={'Enter the PO number.'}>
																	<InfoCircleOutlined />
																</Tooltip>
																<Form.Item
																	valuePropName="checked"
																	name={[field.name, 'po_no_check']}
																>
																	<Checkbox />
																</Form.Item>
															</div>
														}
														name={[field.name, 'po_no']}
														disabled={isAuth}
													/>
												</Col>
												<Col span={4}>
													<Form.Item
														className="full-width-dragger"
														name="po_file"
														label={
															<div className='checked_value'>
																Attach Invoice
																<Tooltip placement='top' title={'Attach a PDF of the purchase order supporting this invoice.'}>
																	<InfoCircleOutlined />
																</Tooltip>
															</div>
														}
													>
														{po_file && <a href={`${process.env.React_APP_API_URL + po_file.url}`} target={`_blank`}>{po_file.name}</a>}
													</Form.Item>
												</Col>
											</Row>
										)
									})
								}
								}
							</Form.List>
						</Col>
					</>
					:
					null
				}

				{formDataList.includes('c-ETR') || formDataList.includes('d-ETR') ? (
					<>
						<Col span={8}>
							<InputComponent
								required
								type="select"
								label={
									<div className='checked_value'>
										Upload Revolving ETR Invoice for
										<Tooltip placement='top' title={'Select the Debtor name that appears on the invoice you are about to upload'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="debtor_id_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name='debtor_id'
								placeholder="Select Debtor"
								options={{
									values: viewValue.debtor ? [{
										id: viewValue && viewValue.debtor.id,
										organisation_name: viewValue && viewValue.debtor.organisation_name
									}] : [],
									value_key: "id",
									text_key: "organisation_name"
								}}
								disabled={isAuth}
							/>
						</Col>
						<Col span={6}>
							{(formDataList.includes('d-ETR') || formDataList.includes('c-ETR')) && (
								<InputComponent
									required
									type="select"
									label={
										<div className='checked_value'>
											Select b-ETR
											<Tooltip placement='top' title={'Select the Debtor name that appears on the invoice you are about to upload.'}>
												<InfoCircleOutlined />
											</Tooltip>
											<Form.Item
												valuePropName="checked"
												name="b_etr_check"
											>
												<Checkbox />
											</Form.Item>
										</div>
									}
									name="b_etr"
									placeholder="Select b-ETR"
									options={{
										values: selectbETR || (
											selectbETR && selectbETR.map((item) => (
												formDataList.includes('d-ETR') ? {
													bko_po_id: item.bko_po_id && item.bko_po_id,
													friendly_name: item.friendly_name && item.friendly_name
												}
													:
													{
														id: item.id && item.id,
														friendly_name: item.friendly_name && item.friendly_name
													}
											))
										),
										value_key: formDataList.includes('d-ETR') ? "bko_po_id" : "id",
										text_key: "friendly_name"
									}}
									autoComplete="off"
									disabled={isAuth}
								/>
							)}
						</Col>
						{formDataList.includes('d-ETR') &&
							<Col span={6}>
								<InputComponent
									allowClear
									type="select"
									label={
										<div className='checked_value'>
											Deliver NoA to
											<Tooltip placement='top' title={'Debtor email address'}>
												<InfoCircleOutlined />
											</Tooltip>
											<Form.Item
												valuePropName="checked"
												name="deliver_to_check"
											>
												<Checkbox />
											</Form.Item>
										</div>
									}
									name="deliver_to"
									placeholder="Choose Email"
									options={{
										values: [{
											id: viewValue && viewValue.deliver_to && viewValue.deliver_to.id,
											email: viewValue && viewValue.deliver_to && viewValue.deliver_to.email
										}],
										value_key: "id",
										text_key: "email"
									}}
									disabled={isAuth}

								/>
							</Col>
						}
					</>
				)
					: null}
			</Row>
			{formDataList.includes('c-ETR') || formDataList.includes('d-ETR') ? (
				<Row gutter={[8, 8]}>
					{formDataList.includes('d-ETR') ? (
						<Col span={5}>
							<InputComponent
								required
								name='term'
								label={
									<div className='checked_value'>
										Payment Terms
										<Tooltip placement='top' title={'Enter a fixed value to indicate your standard (30 , 60 ,90 days, etc)'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="term_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								disabled={isAuth}
							/>
						</Col>
					) : (
						<Col span={5}>
							<InputComponent
								required
								label={
									<div className='checked_value'>
										Payment Discount
										<Tooltip placement='top' title={'Enter a value for payment discount.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="payment_discount_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="payment_discount"
								addonAfter={<PercentageOutlined />}
								disabled
							/>
						</Col>
					)
					}
					<Col span={3}>
						<InputComponent
							allowClear
							type="select"
							label={
								<div className='checked_value'>
									Currency
									<Tooltip placement='top' title={'The currency is pre-set according to currency selection made when creating the Debtor and cannot be changed'}>
										<InfoCircleOutlined />
									</Tooltip>
								</div>
							}
							name="currency_id"
							options={{
								values: [{
									currency_id: viewValue && viewValue.currency && viewValue.currency.id,
									currency_name: viewValue && viewValue.currency && viewValue.currency.currency_name
								}],
								value_key: "currency_id",
								text_key: "currency_name"
							}}
							disabled
						/>
					</Col>

					<Col span={4}>
						<InputComponent
							required
							label={
								<div className='checked_value'>
									Face Value
									<Tooltip placement='top' title={'Enter the total invoice value including all delivery charges, support charges, tax, duties, etc. (i.e the Total value the Debtor must pay to extinguish their liability)'}>
										<InfoCircleOutlined />
									</Tooltip>
									<Form.Item
										valuePropName="checked"
										name="face_value_check"
									>
										<Checkbox />
									</Form.Item>
								</div>
							}
							name="face_value"
							placeholder="Face Value"

							disabled={isAuth}
							maxLength={10}
						/>
					</Col>


					<Col span={6}>
						<InputComponent
							required
							type='date'
							name='invoice_date'
							label={
								<div className='checked_value'>
									Invoice Date (YYYY/MM/DD)
									<Tooltip placement='top' title={'Enter the issue date (i.e. not necessarily today’s date) as it appears on the invoice you are about to sell'}>
										<InfoCircleOutlined />
									</Tooltip>
									<Form.Item
										valuePropName="checked"
										name="invoice_date_check"
									>
										<Checkbox />
									</Form.Item>
								</div>
							}
							placeholder='Select date'
							className='date_wrapper'
							disabled={isAuth}
						/>
					</Col>

					<Col span={6}>
						<InputComponent
							type='date'
							name='expected_date'
							label={
								<div className='checked_value'>
									Expected Date (YYYY/MM/DD)
									<Tooltip placement='top' title={formDataList.includes('d-ETR') ? "The Date you select here is CRITICAL. Enter the realistic date that you expect the invoice will definetly paid in full" : formDataList.includes('c-ETR') ? 'Expected date is the date when you will settle this invoice with Credebt Exchange. This is also the date when the corresponding d-ETR will be raised/traded by you.' : null}>
										<InfoCircleOutlined />
									</Tooltip>
									<Form.Item
										valuePropName="checked"
										name="expected_date_check"
									>
										<Checkbox />
									</Form.Item>
								</div>
							}
							placeholder='Select date'
							className='date_wrapper'
							disabled={isAuth}
						/>
					</Col>
				</Row>

			) : null}

			{formDataList.includes('c-ETR') || formDataList.includes('d-ETR') ? (
				<Row gutter={[8, 8]}>
					{form.getFieldValue('b_etr') !== 0 ?
						<Col span={6}>
							<InputComponent
								required
								allowClear
								type="select"
								label={
									<div className='checked_value'>
										Select PO Number
										<Tooltip placement='top' title={'Select If this invoice is supported by an official order or Purchase order number.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="po_id_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="po_id"
								placeholder="Select PO Number"
								options={{
									values: viewValue.po ? [{
										po_id: viewValue && viewValue.po && viewValue.po.po_id,
										po_no: viewValue && viewValue.po && viewValue.po.po_no,
										friendly_name: viewValue && viewValue.po && viewValue.po.friendly_name
									}] : [],
									value_key: "po_id",
									text_key: { key: ["po_no", " - ", "friendly_name"] }

								}}
								disabled={isAuth}
							/>
						</Col>
						:
						<Col span={6}>
							<InputComponent
								required
								label={
									<div className='checked_value'>
										PoD
										<Tooltip placement='top' title={formDataList.includes('d-ETR') ? "Enter If this invoice is supported by an official order or Purchase order number." : formDataList.includes('c-ETR') ? 'If this invoice is supported by an official order, or Purchase Order (PO), enter it here.' : null}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="po_no_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								name="po_no"
								placeholder="PoD"
								disabled={isAuth}
							/>
						</Col>
					}
					{form.getFieldValue('b_etr') === 0 &&
						<Col span={6}>
							<Form.Item
								required
								label={
									<div className='checked_value'>
										Attach PoD
										<Tooltip placement='top' title={'Attach a pdf of the purchase order supporting this invoice.'}>
											<InfoCircleOutlined />
										</Tooltip>
										<Form.Item
											valuePropName="checked"
											name="po_attach_check"
										>
											<Checkbox />
										</Form.Item>
									</div>
								}
								className="full-width-dragger"
								name="po_attach"
							>
								{viewValue && viewValue.po_attach && <a href={`http://credebt.silicontechnolabs.com:8001/${viewValue.po_attach.url}`} target={`_blank`}>{viewValue.po_attach.name}</a>}
							</Form.Item>
						</Col>
					}

					<Col span={6}>
						<InputComponent
							required
							label={
								<div className='checked_value'>
									Invoice No.
									<Tooltip placement='top' title={'Enter the invoice number as it appears on the invoice you are going to upload'}>
										<InfoCircleOutlined />
									</Tooltip>
									<Form.Item
										valuePropName="checked"
										name="invoice_no_check"
									>
										<Checkbox />
									</Form.Item>
								</div>
							}
							name="invoice_no"
							placeholder="Invoice No."
							disabled={isAuth}
						/>
					</Col>

					<Col span={6}>
						<Form.Item
							required
							className="full-width-dragger"
							name="file_support"
							label={
								<div className='checked_value'>
									Attach Invoice
									<Tooltip placement='top' title={'Attach the pdf of the actual invoice and ensure the details of the invoice actual match the details entered in this form'}>
										<InfoCircleOutlined />
									</Tooltip>
									<Form.Item
										valuePropName="checked"
										name="file_support_check"
									>
										<Checkbox />
									</Form.Item>
								</div>
							}
						>
							{viewValue && viewValue.file_support && <a href={`${process.env.React_APP_API_URL + viewValue.file_support.url}`} target={`_blank`}>{viewValue.file_support.name}</a>}
						</Form.Item>
					</Col>
				</Row>
			) : null}

			<Col span={24}>
				<InputComponent
					label='Reason for Reject'
					type='textarea'
					required={rejectReasonRequired}
					rules={rejectReasonRequired ? [{ required: true, message: "Reason is required" }] : [{ required: false, message: '' }]}
					name='reject_reason'
					placeholder='Reason for Reject'
					tooltip="Add ETR rejection reason."
				/>
			</Col>
		</Form>
	)
		:
		null
})

export default FormComponent
