import React from 'react'
import { DeleteOutlined, EditOutlined, HomeOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import useStore from '../../../../store'
import SwitchComponent from './SwitchComponent'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal, openExchangeAPIModal, openViewHistory } = props.agGridReact.props.frameworkComponents
	const { UserStore, UserStore: { user } } = useStore()

	return (
		<div className="action-column">
			{
				(user.user_role.user_role_id === 1) ?
					(
						<>
							{UserStore.checkPermission(55, 'edit') && <Button title="Edit FX Currencies" type="text" disabled={props.data.disable_action} onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>}
							{(props.data.currency_association_count > 0 || props.data.ledgers_currency_count > 0) ? (
								<Tooltip placement="topRight" color={"red"} title={"Sorry, you cannot delete this Currency as it is associated with existing Transaction or Ledger."} >
									{UserStore.checkPermission(55, 'delete') && <Button type="text" disabled><DeleteOutlined /></Button>}
								</Tooltip>
							) : (
								UserStore.checkPermission(55, 'delete') && <Button title="Delete FX Currencies" disabled={props.data.disable_action} type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
							)}
						</>
					) : (
						<>
							{UserStore.checkPermission(55, 'add_exchange_rate') && <Button title="FX Rate Source" type="text" onClick={() => { openExchangeAPIModal(props.data) }}><HomeOutlined /></Button>}
							{UserStore.checkPermission(55, 'rate_history') && <Button title="View History" type="text" onClick={() => { openViewHistory(props.data) }}><HistoryOutlined /></Button>}
						</>
					)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		visibleToLedger: 1,
		field: ' ',
		checkboxSelection: true,
		filter: false,
		sortable: false,
		floatingFilter: false,
		width: 70,
		suppressMenu: true,
		pinned: 'left'
	}, {
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Currency Name',
		field: 'currency_name',
		width: 150
	}, {
		headerName: 'Currency Code',
		field: 'currency_code',
		width: 120
	}, {
		headerName: 'Currency Symbol',
		field: 'currency_symbol',
		filter: 'agSetColumnFilter',
		width: 140
	}, {
		visibleToLedger: 1,
		headerName: 'Fx Rate Source',
		field: 'currency_provider.provider_name',
		width: 120
	}, {
		visibleToSuper: 1,
		headerName: 'Status',
		field: 'published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Active", "Deactive"],
			defaultToNothingSelected: true,
		},
		width: 90
	}, {
		visibleToLedger: 1,
		headerName: 'Status',
		field: 'ledger_published',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Active", "Deactive"],
			defaultToNothingSelected: true,
		},
		width: 90
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
