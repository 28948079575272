import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Button, PageHeader } from 'antd'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import ListComponent from './components/ListComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import ReconcileComponent from './components/ReconcileTransaction'
import UnlinkComponent from './components/UnlinkComponent'
import { default as AllTransactionViewComponent } from '../CashTransactions/components/ViewComponent';
import { default as AllTransactionEditComponent } from '../CashTransactions/components/EditComponent';
import { default as AllTransactionDeleteComponent } from '../CashTransactions/components/DeleteComponent';
import { default as TradeTransactionViewComponent } from '../../TradeTransaction/AllTransactions/component/ViewComponent';
import { vsmCashTransactions, vsmNotify } from '../../../config/messages'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const Reconciliation = observer(() => {
	const [reconcileModal, setReconcileModal] = useState(false)
	const [unlinkModal, setUnlinkModal] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [transViewDrawer, setTransViewDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [deleteDrawer, setDeleteDrawer] = useState(false)
	const [saving, setSaving] = useState(false)
	const { CashTransactionStore: { setDeleteValues }, CashTransactionStore, UserStore, ReconciliationStore, ReconciliationStore: { per_page, agGrid, list_data, setEditValues, setUnlinkValues, setPageSize, exportLoading, exportData, listLoading }, LedgerBankAccountStore } = useStore()

	// set id for edit / view drawer
	const setViewId = (id) => setTransactionId(id)

	// Open form for Reconcile Cash Transaction with Trade Transaction’s Journal entry
	const openReconcileModal = (data) => {
		setEditValues(data)
		setReconcileModal(true)
	}

	// Open form for multiple Reconcile Cash Transaction with Trade Transaction’s Journal entry
	const openMultiReconcileModal = (data) => {
		if (agGrid) {
			setSaving(true)
			let selected = agGrid.api.getSelectedRows()
			selected = selected.map(item => item.id)
			if (selected.length > 0) {
				ReconciliationStore.selectedIds = selected
				setReconcileModal(true)
			} else {
				setSaving(false)
				vsmNotify.error({ message: "Please select atleast one record to reconcile." })
			}
		}
	}

	// Close form
	const closeReconcileModal = () => {
		setSaving(false)
		setReconcileModal(false)
	}

	// open drawer for view
	const openViewDrawer = (id) => {
		LedgerBankAccountStore.getDynamicFieldList()
		setViewId(id)
		setViewDrawer(true)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// open drawer for view trade transaction
	const openTransViewDrawer = (data) => {
		setViewId(data.transaction_id)
		setTransViewDrawer(true)
	}

	// close drawer for view
	const closeTransViewDrawer = () => setTransViewDrawer(false)

	// open drawer for Edit
	const openEditDrawer = (id) => {
		LedgerBankAccountStore.getDynamicFieldList()
		setViewId(id)
		setEditDrawer(true)
	}

	// close drawer for Edit
	const closeEditDrawer = () => setEditDrawer(false)

	// open drawer for Delete
	const openDeleteDrawer = (data) => {
		setDeleteValues(data)
		setDeleteDrawer(true)
	}

	// close drawer for Delete
	const closeDeleteDrawer = () => setDeleteDrawer(false)

	// Open confirmation alert before Unlinking Cash Transaction with Trade Transaction
	const openUnlinkModal = (data) => {
		setUnlinkValues(data)
		setUnlinkModal(true)
	}

	// Close confirmation alert for Unlinking record
	const closeUnlinkModal = () => setUnlinkModal(false)

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Reconciliation.path} />
			{(!UserStore.checkPermission(77, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.Reconciliation.title}
					extra={[
						ReconciliationStore.listAPIData && ReconciliationStore.listAPIData.reconcile_pending_transactions_status === false ? <Button key="7" title={`Remaining - ${ReconciliationStore.listAPIData && ReconciliationStore.listAPIData.reconcile_pending_transactions_remaining}`} disabled>Reconcile Pending Transactions</Button> : <Button key="7" title="Reconcile Pending Transactions" loading={CashTransactionStore.loader} onClick={() => CashTransactionStore.PendingReconcile().then(() => {
							vsmNotify.success({
								message: vsmCashTransactions.reconcilePending
							})
						})}>Reconcile Pending Transactions</Button>,
						UserStore.checkPermission(77, 'reconciliation_mode') && <Button key="1" onClick={openMultiReconcileModal} loading={saving}>Multiple Reconcile</Button>,
						UserStore.checkPermission(77, 'export') && <Button key="2" loading={exportLoading} onClick={exportData} disabled={listLoading || (list_data && list_data.length === 0)}>Export</Button>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={ReconciliationStore.handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent openReconcileModal={openReconcileModal} openUnlinkModal={openUnlinkModal} openViewDrawer={openViewDrawer} openEditDrawer={openEditDrawer} openDeleteDrawer={openDeleteDrawer} openTransViewDrawer={openTransViewDrawer} />
					<ReconcileComponent visible={reconcileModal} close={closeReconcileModal} openViewDrawer={openViewDrawer} closeViewDrawer={closeViewDrawer} openParentTransViewDrawer={openTransViewDrawer} />
					<UnlinkComponent tab={"reconcile"} visible={unlinkModal} close={closeUnlinkModal} />
					<AllTransactionViewComponent transactionId={transactionId} setId={setViewId} visible={viewDrawer} close={closeViewDrawer} openParentTransViewDrawer={openTransViewDrawer} />
					<AllTransactionEditComponent transactionId={transactionId} setId={setViewId} visible={editDrawer} close={closeEditDrawer} />
					<AllTransactionDeleteComponent transactionId={transactionId} setId={setViewId} visible={deleteDrawer} reconcileGrid={() => ReconciliationStore.setupGrid(ReconciliationStore.agGrid)} close={closeDeleteDrawer} />
					<TradeTransactionViewComponent transactionId={transactionId} setId={setViewId} visible={transViewDrawer} close={closeTransViewDrawer} openParentCashViewDrawer={openViewDrawer} />
				</PageHeader>
			)}
		</>
	)
})

export default Reconciliation
