import React from 'react'
import { observer } from 'mobx-react'
import { Button, Drawer } from 'antd'
import useStore from '../../../../store'
import { afETR, apiURL, bETR, cdETR, etrTypeObject as etr_type, rsaETRType } from '../../../../utils/GlobalFunction'
import moment from 'moment'

const ViewETRComponent = observer((props) => {
	const { AllETRStore } = useStore()

	return (props.visible && AllETRStore.viewValue) ? (
		<Drawer
			visible={props.visible}
			onClose={props.close}
			placement={'right'}
			width={'60%'}
			title={`View ETR - #${AllETRStore.viewValue.id}`}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button onClick={props.close}>Close</Button>
				</div>
			]}
		>
			<div className='viewETR'>
				<table cellPadding='0' cellSpacing='0' border='0'>
					<tbody key="tbody">
						{
							rsaETRType.includes(AllETRStore.viewValue.etr_type) &&
							<>
								<tr>
									<td width="25%">ETR Type</td>
									<td width="25%">Open RSA for</td>
									<td width="25%">Payment Terms</td>
									<td width="25%">Face Value</td>
								</tr>
								<tr>
									<th>{etr_type[AllETRStore.viewValue.etr_type]}</th>
									<th>{AllETRStore.viewValue?.debtor?.organisation_name ? AllETRStore.viewValue?.debtor?.organisation_name : ""}</th>
									<th>{AllETRStore.viewValue.payment_discount_term}</th>
									<th>{AllETRStore.viewValue.face_value}</th>
								</tr>
								<tr>
									<td width="25%">Number of ETR</td>
									<td width="25%">Currency</td>
									<td width="25%">Revolving Offer Months</td>
									<td width="25%">Attach Invoice</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.etr_no}</th>
									<th>{AllETRStore.viewValue?.currency?.currency_name ? AllETRStore.viewValue?.currency?.currency_name : ""}</th>
									<th>{AllETRStore.viewValue.etr_months}</th>
									<th>{AllETRStore.viewValue.file_support && <a target={`_blank`} href={apiURL + AllETRStore.viewValue.file_support.url}>{AllETRStore.viewValue.file_support.name}</a>}</th>
								</tr>
								<tr>
									<td width="25%">Monthly Discount</td>
									<td width="25%">Buy Out Discount</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.discount_price} %</th>
									<th>{AllETRStore.viewValue.buyout_discount_price} %</th>
								</tr>
							</>
						}
						{(afETR.includes(AllETRStore.viewValue.etr_type)) &&
							<>
								<tr>
									<td width="25%">ETR Type</td>
									<td width="25%">Select Creditor</td>
									<td width="25%">Batch No</td>
									<td width="25%">ETR Months</td>
								</tr>
								<tr>
									<th>{etr_type[AllETRStore.viewValue.etr_type]}</th>
									<th>{AllETRStore.viewValue.debtor.organisation_name}</th>
									<th>{AllETRStore.viewValue.batch_no}</th>
									<th>{AllETRStore.viewValue.etr_months}</th>
								</tr>

								<tr>
									<td>Currency</td>
									<td>Start Date (YYYY/MM/DD)</td>
									<td>End Date (YYYY/MM/DD)</td>
									<td>Purchase Date (YYYY/MM/DD)</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.currency && AllETRStore.viewValue.currency.currency_name}</th>
									<th>{AllETRStore.viewValue.start_date}</th>
									<th>{AllETRStore.viewValue.end_date}</th>
									<th>{moment(AllETRStore.viewValue.purchase_date).format('YYYY-MM-DD')}</th>
								</tr>
								<tr>
									<td>Asset Name</td>
									<td>Asset Number</td>
									<td>Quantity Number</td>
									<td>Asset Classfication</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.asset_name}</th>
									<th>{AllETRStore.viewValue.asset_number}</th>
									<th>{AllETRStore.viewValue.quantity_numbers}</th>
									<th >{AllETRStore.viewValue.asset_classification}</th>
								</tr>
								<tr>
									<td>Sell Rate</td>
									<td>Value</td>
									<td>Supplier/Manufacturer</td>
									<td>Invoice File/Document</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.a_f_sell_rate}</th>
									<th>{AllETRStore.viewValue.face_value}</th>
									<th>{AllETRStore.viewValue.supplier_manufacturer}</th>
									<th>{AllETRStore.viewValue.file_support && <a target={`_blank`} href={apiURL + AllETRStore.viewValue.file_support.url}>{AllETRStore.viewValue.file_support.name}</a>}</th>
								</tr>
								<tr>
									<td colSpan={4}>Description</td>
								</tr>
								<tr>
									<th colSpan={4}>{AllETRStore.viewValue.desc && AllETRStore.viewValue.desc}</th>
								</tr>
							</>
						}
						{(cdETR.includes(AllETRStore.viewValue.etr_type)) &&
							<>
								<tr>
									<td width="25%">ETR Type</td>
									<td width="25%">Upload Revolving ETR Invoice for</td>
									<td width="25%">Select b-ETR</td>
									{AllETRStore.viewValue.etr_type === 4 ? <td width="25%">Deliver NoA to</td> : <th></th>}
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.etr_type && etr_type[AllETRStore.viewValue.etr_type]}</th>
									<th>{AllETRStore.viewValue.face_value && AllETRStore.viewValue.face_value}</th>
									<th>{AllETRStore.viewValue.payment_discount_term && AllETRStore.viewValue.payment_discount_term}</th>
									{AllETRStore.viewValue.etr_type === 4 ? <th>{AllETRStore.viewValue.deliver_to && AllETRStore.viewValue.deliver_to.email}</th> : <th></th>}
								</tr>

								<tr>
									<td width="25%">Payment Terms</td>
									<td width="25%">Currency</td>
									<td width="25%">Face Value</td>
									<td width="25%">Invoice Date</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.payment_discount_term && AllETRStore.viewValue.payment_discount_term}</th>
									<th>{AllETRStore.viewValue.currency && AllETRStore.viewValue.currency.currency_name}</th>
									<th>{AllETRStore.viewValue.face_value && AllETRStore.viewValue.face_value}</th>
									<th>{AllETRStore.viewValue.invoice_date && AllETRStore.viewValue.invoice_date}</th>
								</tr>

								<tr>
									<td width="25%">Expected Date</td>
									<td width="25%">PO Number</td>
									<td width="25%">Attach PoD</td>
									<td width="25%">Invoice No.</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.expected_date && AllETRStore.viewValue.expected_date}</th>
									<th>{AllETRStore.viewValue.po_no && AllETRStore.viewValue.po_no}</th>
									<th>{AllETRStore.viewValue.po_attach && <a target={`_blank`} href={apiURL + AllETRStore.viewValue.po_attach.url}>{AllETRStore.viewValue.po_attach.name}</a>}</th>
									<th>{AllETRStore.viewValue.invoice_no && AllETRStore.viewValue.invoice_no}</th>
								</tr>
								<tr>
									<td width="25%">Attach Invoice</td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.file_support && <a target={`_blank`} href={apiURL + AllETRStore.viewValue.file_support.url}>{AllETRStore.viewValue.file_support.name}</a>}</th>
								</tr>


							</>
						}
						{
							(bETR.includes(AllETRStore.viewValue.etr_type)) &&
							<>
								<tr>
									<td width="25%">ETR Type</td>
									<td width="25%">Expected Date (YYYY/MM/DD)</td>
									<td width="25%">Friendly Name</td>
									<td width="25%">Description</td>
								</tr>
								<tr>
									<th>{etr_type[AllETRStore.viewValue.etr_type]}</th>
									<th>{AllETRStore.viewValue.expected_date && AllETRStore.viewValue.expected_date}</th>
									<th>{AllETRStore.viewValue.friendly_name && AllETRStore.viewValue.friendly_name}</th>
									<th>{AllETRStore.viewValue.description && AllETRStore.viewValue.description}</th>
								</tr>
								<tr>
									<td width="25%">Attach Supporting Document</td>
									<td width="25%">d-ETR Monthly Discount Percentage</td>
									<td width="25%">c-ETR Sell Rate Percentage</td>
									<td width="25%"></td>
								</tr>
								<tr>
									<th>{AllETRStore.viewValue.file_support && <a target={`_blank`} href={apiURL + AllETRStore.viewValue.file_support.url}>{AllETRStore.viewValue.file_support.name}</a>}</th>
									<th>{AllETRStore.viewValue.d_etr_purchase_discount && AllETRStore.viewValue.d_etr_purchase_discount}</th>
									<th>{AllETRStore.viewValue.c_etr_sell_rate && AllETRStore.viewValue.c_etr_sell_rate}</th>
									<th></th>
								</tr>
								<tr><td colSpan={4}>Po Details</td></tr>
								<tr>
									<th colSpan={4}>
										{AllETRStore.viewValue && AllETRStore.viewValue.b_etr_files && AllETRStore.viewValue.b_etr_files.map((item, index) =>
											<div key={index} className='mb-10'>
												<tr>
													<td width='20%'>Upload PO For</td>
													<td width='20%'>Currency</td>
													<td width='20%'>PO Date</td>
													<td width='20%'>Face Value</td>
													<td width='20%'>PO Number</td>
													<td width='20%'>Attach PO File</td>
												</tr>
												<tr>
													<th>{item.debtor.organisation_name}</th>
													<th>{item.currency && item.currency.currency_name}</th>
													<th>{item.po_date ? moment(item.po_date).format("YYYY-MM-DD") : ""}</th>
													<th>{item.face_value && item.face_value}</th>
													<th>{item.po_no && item.po_no}</th>
													<th>{item.po_file && <a target={`_blank`} href={apiURL + item.po_file.url}>{item.po_file.name}</a>}</th>
												</tr>
											</div>
										)}
									</th>
								</tr>
							</>
						}
					</tbody>
				</table>
			</div>
		</Drawer>
	) : null
})

export default ViewETRComponent
