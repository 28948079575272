import React, { useState } from 'react'
import { Form, Button, Modal, Input, Select } from 'antd'
import { vsmNotify, vsmTransactionFields } from '../../../../../config/messages';
import useStore from '../../../../../store'
import { observer } from 'mobx-react';
import InputComponent from '../../../../../components/InputComponent';

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { TransactionFieldStore: { AddData, dropdown_type_list, dropdown_algorithm_list } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [fieldIsCSV, setFieldIsCSV] = useState(false)
	const [fieldIsDate, setFieldIsDate] = useState(false)

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmTransactionFields.add
			})
			setDisabled(true)
			form.resetFields()
			form.setFieldsValue({
				field_type: 'Number'
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		if (form.getFieldValue().field_type === "CSV Column" || form.getFieldValue().field_type === "Date") {
			setFieldIsCSV(true)
			setFieldIsDate(true)
		} else {
			await setFieldIsCSV(false)
			await setFieldIsCSV(false)
		}

		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// Reset form and close add form
	const close = () => {
		form.resetFields()
		setFieldIsCSV(false)
		setFieldIsCSV(false)
		setDisabled(true)
		props.close()
	}

	return (
		<Modal
			centered
			title="New Transaction Field"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				layout="vertical"
				onChange={handleChange}
			>
				<Form.Item name="field_name" rules={vsmTransactionFields.validation.field_name} label="Field Name" required tooltip='Fields are a set of available variables that can be assigned to selected type of Transactions.'>
					<Input id="field_name" placeholder="Field Name" maxLength={100} />
				</Form.Item>

				<Form.Item name="field_type" rules={vsmTransactionFields.validation.field_type} label="Field Type" required extra={[
					<div key={1} className="text-right mr-25">
						<label style={{ fontWeight: 'bold', fontSize: '12px' }}>You will not able to edit type once this is saved</label>
					</div>
				]} tooltip='Field Type is used to classify the transaction fields type. Depending on type, Transaction Field value will get generated.'>
					<Select placeholder="Field Type" onChange={handleChange} showSearch
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						optionFilterProp="children" allowClear>
						{
							(dropdown_type_list && dropdown_type_list.map((item, index) => (
								<Select.Option key={index} value={item}>{item}</Select.Option>
							)))
						}
					</Select>
				</Form.Item>

				{(!fieldIsCSV || !fieldIsDate) ? (
					<Form.Item name="algorithm_id" rules={vsmTransactionFields.validation.algorithm_id} label="Algorithm" tooltip='Algorithms are used in Transaction Models to calculate values for Journal entries and transaction fields.'>
						<Select showSearch placeholder="Algorithm" allowClear
							optionFilterProp="children"
							onChange={handleChange}
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
						>
							{
								(dropdown_algorithm_list && dropdown_algorithm_list.map((item, index) => (
									<Select.Option key={index} value={item.id}>{item.algorithm_name}</Select.Option>
								)))
							}
						</Select>
					</Form.Item>

				) : null}

				<Form.Item name="default_value" rules={vsmTransactionFields.validation.default_value} label="Default Value" tooltip='Default values will be used as a Hard value when there is no algorithm associated or value of CSV Column or algorithm is blank then default value will be used as a field value for particular transaction.'>
					<Input id="default_value" placeholder="Default Value" maxLength={100} />
				</Form.Item>

				<InputComponent
					required type="textarea" label="Description" name="description" placeholder="Description"
					onChange={handleChange} rowSize={3} rules={vsmTransactionFields.validation.description} maxLength={1000}
					tooltip="Description would be used to specify details of the particular transaction field."
				/>

				<InputComponent
					required
					type='select'
					name='app_flag'
					label='App flag'
					placeholder='Select Made for'
					options={{
						values: [{ value: 0, text: 'Credebt-Machine' }, { value: 1, text: 'Originator app' }, { value: 2, text: 'both' }]
					}}
					rules={vsmTransactionFields.validation.app_flag}
					onChange={handleChange}
					tooltip='Based on the App Flag selection, the above transaction fields will be displayed on the relevant page.'
					initialValue={0}
				/>
			</Form>
		</Modal>
	)
})

export default AddComponent
