import { Button, Drawer, Steps } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import useStore from '../../../../store'
import BankDetailView from '../elements/BankDetailView'
import CreditRiskView from '../elements/CreditRiskView'
import OriginatorDetailView from '../elements/OriginatorDetailView'
import UserDetailView from '../elements/UserDetailView'
import EditComponent from './EditComponent'
import { EditOutlined } from '@ant-design/icons';
const { Step } = Steps

const ViewComponent = observer((props) => {
	const { MemberStore, MemberStore: { openFormType } } = useStore()
	const [stepState, setStepState] = useState([])
	const [editDrawer, setEditDrawer] = useState(false)

	const close = () => {
		MemberStore.currentTab = 0
		MemberStore.openFormType = null
		MemberStore.userPermission = null
		MemberStore.organisationID = null
		MemberStore.viewMemberValue = null
		props.close()
	}

	let steps = [{
		id: 1,
		title: `${openFormType} Details`,
		content: <OriginatorDetailView />
	}, {
		id: 2,
		title: 'Bank Details',
		content: <BankDetailView />
	}, {
		id: 3,
		title: 'Credit & Risks',
		content: <CreditRiskView />
	}, {
		id: 4,
		title: 'User Details',
		content: <UserDetailView />
	}]

	const handleSubmit = () => {
		MemberStore.setCurrentTab()
	}

	useEffect(() => {
		if (openFormType) {
			// Dynamic steps (Remove 3 step in Intermediary & Investor)
			if (openFormType !== 'Originator') {
				setStepState(steps.filter((item) => item.id !== 3))
			} else {
				setStepState(steps)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openFormType])

	const openEditMemberDrawer = () => {
		if (MemberStore.viewMemberValue && MemberStore.viewMemberValue.id) {
			MemberStore.viewID = MemberStore.viewMemberValue.id
		}
		MemberStore.userPermission = null
		MemberStore.organisationID = null
		MemberStore.currentTab = 0
		setEditDrawer(true)
	}

	const closeEditTradeDrawer = () => {
		MemberStore.readMemberDetail(MemberStore.viewID)
		setEditDrawer(false)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			title={`View Members Details - #${MemberStore.viewMemberValue && MemberStore?.viewMemberValue?.id}`}
			placement='right'
			width={'60%'}
			destroyOnClose={true}
			className='member_drawer'
			extra={[
				<Button className="mr-40" onClick={openEditMemberDrawer}><EditOutlined /></Button>
			]}
			footer={[
				<div key="1" className="d-flex justify-content-end">
					{openFormType === "Originator" && MemberStore.currentTab !== 3 && <Button className="mr-10" type="primary" onClick={handleSubmit}>Next</Button>}
					{openFormType !== "Originator" && MemberStore.currentTab !== 2 && <Button className="mr-10" type="primary" onClick={handleSubmit}>Next</Button>}
					<Button className="ml-10" onClick={close}>Close</Button>
				</div>
			]}>
			<Steps type="navigation" size="small" current={MemberStore.currentTab} className="site-navigation-steps mb-10">
				{stepState.map((item, index) => (
					<Step key={item.id} title={item.title} onClick={() => MemberStore.currentTab = index} className="member-view-step" />
				))}
			</Steps>
			{stepState.length > 0 && <div className="member_steps">{stepState.length && stepState?.[MemberStore.currentTab]?.content}</div>}
			<EditComponent visible={editDrawer} close={closeEditTradeDrawer} type='in_view' />
		</Drawer>
	)
})

export default ViewComponent