import React, { useState } from 'react'
import { observer } from "mobx-react"
import { Form, Col, Button, Row, Card, Divider } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import AddressSubElement from './SubElements/AddressSubElement'

const AddressElement = observer(({ onChange, form, fetchCountry, setFetchCountry }) => {
	const [SelectTypes, SetSelectedTypes] = useState()

	return (
		<div className="w-100">
			<Form.List name='addresses'>
				{
					(fields, { add, remove }) => {
						return (
							<>
								{fields.map((field, index) => {
									let rejected_types = []
									SelectTypes && SelectTypes.foreach(x => {
										if (x && x.address_type === 'Primary') {
											rejected_types.push('Primary')
										}
									})
									return (
										<div className="w-100" key={field.key}>
											<div>
												<Row gutter={10} className="align-items-center no-wrap">
													<Col flex={1}>
														<Card style={{ margin: 10 }}>
															<AddressSubElement onChange={onChange} field={field} SetSelectedTypes={SetSelectedTypes} rejected_types={rejected_types} form={form} setFetchCountry={setFetchCountry} fetchCountry={fetchCountry} />
														</Card>
													</Col>
													{
														<Col><Button type="danger" onClick={async () => {
															await remove(field.name)
															onChange()
														}} icon={<MinusOutlined />}></Button></Col>
													}
												</Row>
											</div>
										</div>
									)
								})}
								<Divider>
									<Button type="primary" onClick={async () => {
										await add({ country_id: null })
										onChange()
									}} block icon={<PlusOutlined />}>Add Address</Button>
								</Divider>
							</>
						)
					}
				}
			</Form.List >
		</div>
	)
})

export default AddressElement
