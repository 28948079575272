import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Button } from 'antd'
import Chart from "react-apexcharts";

const pieWidget = observer((props) => {
    const [countJournals, setCountJournals] = useState(null)
    const [countId, setCountId] = useState(null)

    const updateCount = (id) => {
        setCountId(id)
        setCountJournals(id === 7 ? props.newCounter.chart['1_week'] : id === 30 ? props.newCounter.chart['1_month'] : id === 365 ? props.newCounter.chart['1_year'] : null)
    }

    const getYcoordinate = () => {
        let currentArr = countJournals?.y_value;
        currentArr = currentArr?.map((obj) => {
            obj = Number(obj);
            return obj;
        })
        return currentArr;
    }

    const options = countId && {
        chart: {
            width: 380,
            type: 'pie',
        },
        noData: {
            text: 'No Data Found.',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '16px',
            }
        },
        colors: ['#fe6384', '#36a2eb', '#4bc0c0'],
        dataLabels: {
            enabled: false
        },
        labels: countJournals.x_value,
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    const series = getYcoordinate();

    useEffect(() => {
        if (props.newCounter.settings_json.i.extra_option === 'multiple') {
            setCountId(props.newCounter.settings_json.i.default_selected)
            setCountJournals(props.newCounter.settings_json.i.default_selected === 7 ? props.newCounter.chart['1_week'] : props.newCounter.settings_json.i.default_selected === 30 ? props.newCounter.chart['1_month'] : props.newCounter.settings_json.i.default_selected === 365 ? props.newCounter.chart['1_year'] : null)
        } else {
            setCountId(props.newCounter.settings_json.i.multi_select[0])
            setCountJournals(props.newCounter.settings_json.i.multi_select[0] === 7 ? props.newCounter.chart['1_week'] : null)
        }
    }, [props.newCounter])

    return countId && (
        <Card className="widget_wrapper graph_widget">
            <div className="counter__widget">
                <div className="counter__widget__description">
                    <div className="counter__widget__name">{props.newCounter.dashboard_name}</div>
                    <div className='counter__widget__chart'>
                        <Chart
                            options={options}
                            series={series}
                            type='pie'
                            width="100%"
                            height="100%"
                        />
                    </div>
                </div>
            </div>
            <div className="counter__widget__footer">
                <div className="counter__widget__buttons">
                    {props.newCounter.settings_json.i.multi_select.map((buttonItem, index) => {
                        return (
                            <Button
                                type="link"
                                size="small"
                                onClick={() => updateCount(buttonItem)}
                                className={"counter__widget__button " + (buttonItem === countId ? "cs_active" : "")}
                            >
                                {buttonItem === 365 ? '12 Months' : `${buttonItem} Days`}
                            </Button>
                        )
                    })}
                </div>
            </div>
        </Card>
    )
})

export default pieWidget
