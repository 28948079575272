import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Form, Input } from "antd";
import { IoIosMail } from "react-icons/io";
import { vsmAuth, vsmNotify } from "../../config/messages";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../store";

const OriginatorForgotPass = observer((props) => {
    const [form] = Form.useForm()
    let history = useHistory()
    const { AppsAuthStore } = useStore()
    const [loading, setLoading] = useState(false)

    const handleSubmit = (data) => {
        setLoading(true)
        AppsAuthStore.doAppsForgetPass(data).then(() => {
            history.push('/apps')
            vsmNotify.success({
                message: vsmAuth.forgot_success
            })
        })
    }

    useEffect(() => {
        if (AppsAuthStore.theme_color && AppsAuthStore.theme_button_color === '1') {
            document.documentElement.style.setProperty('--primary', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_border_radius', '0');
            document.documentElement.style.setProperty('--primaryBG', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        }
        else if ((AppsAuthStore.theme_color && AppsAuthStore.theme_button_color === '2')) {
            document.documentElement.style.setProperty('--primary', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_border_radius', '30px');
            document.documentElement.style.setProperty('--primaryBG', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        }
        else {
            document.documentElement.style.setProperty('--primary', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_border_radius', '0');
            document.documentElement.style.setProperty('--primaryBG', AppsAuthStore.theme_color);
            document.documentElement.style.setProperty('--btn_font_color', '#ffffff');
        }
    }, [AppsAuthStore.theme_color, AppsAuthStore.theme_button_color])

    return (
        <div className={`main__forgot_pass_wrapper`}>
            <div className='login_card_wrapper'>
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item name="email" rules={vsmAuth.validation.email} hasFeedback>
                        <Input prefix={<IoIosMail style={{ color: AppsAuthStore.theme_color, fontSize: '30px' }} />} placeholder="Email Address" className='input_wrapper' />
                    </Form.Item>

                    <div className="text-center">
                        <Button loading={loading} htmlType="submit" block type="primary" className="login_button_wrapper">Reset Password</Button>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to={{
                            pathname: `/apps`,
                        }} type="link" className="p-0 mt-10"><b>Back to login</b></Link>
                    </div>
                </Form>
            </div>
        </div>
    )
})

export default OriginatorForgotPass