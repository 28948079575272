import React, { useState, useEffect, useMemo } from 'react'
import { Form, Button, Drawer, Col, Spin, Row, Card } from 'antd'
import { observer } from 'mobx-react'
import debounce from 'lodash/debounce'
import { vsmRoleMapping, vsmOrganisations, vsmNotify } from '../../../../config/messages'
import useStore from '../../../../store'
import OrgDetailComponent from './OrgDetailComponent'
import InputComponent from '../../../../components/InputComponent'
import PplDetailComponent from '../../../PeopleManagement/RoleMapping/component/PplDetailComponent';
import RatingElement from '../../AllOrganisations/elements/RatingElement'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [currentTab, setCurrentTab] = useState("Organisation")
	const [searchROrgKey, setSearchROrgKey] = useState()
	const [fetchRoles, setFetchRoles] = useState(true)
	const [fetchRelatedRoles, setFetchRetaldRoles] = useState(true)
	const [fetchTags, setFetchTags] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [organisationValues, setOrganisationValues] = useState()
	const [rOrganisationValues, setROrganisationValues] = useState()
	const [loading, setLoading] = useState(true)
	const [updateState, setUpdateState] = useState(true)
	const [ratingHide, setRatingHide] = useState(true)
	const { OrganisationStore, OrgBankAccountStore, PeopleStore, RoleMappingStore, UserStore: { user }, RoleMappingStore: { getAllTagsList, EditData, editValues } } = useStore()

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.organisation_id = props.parent_id
		data.entity_type = 1
		data.is_rating = ratingHide ? 0 : 1
		if (form.getFieldValue("related_entity_type") === "Ledger") {
			data.related_entity_id = null
			data.related_role_id = null
		}
		if (data.rating_table && data.rating_table.length > 0) {
			data.rating_table = data.rating_table.filter(x => x && (x && x.global_exchange_rating_id))
			data.rating_table = !ratingHide && data.rating_table.length > 0 ? data.rating_table : null
		}
		EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmRoleMapping.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			var payload = { organisation_id: props.parent_id && parseInt(props.parent_id), mapping_type: 1 }
			OrganisationStore.getRatingToMap()
			RoleMappingStore.getRecordById(props.mappingId, payload).then((data) => {
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmRoleMapping.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props, OrganisationStore, RoleMappingStore])

	// set the form values to add
	useEffect(() => {
		if (editValues && props.visible) {
			setOrganisationValues(editValues.organisation)
			if (editValues.related_organisation) { setROrganisationValues(editValues.related_organisation) }
			if (editValues.related_people) { PeopleStore.rPeopleCardValue = editValues.related_people }
			OrgBankAccountStore.dropdown_currency_list = editValues.global_currency && [editValues.global_currency]
			setRatingHide(editValues.is_rating === 1 ? false : true)
			form.setFieldsValue({
				entity_id: editValues.entity_id,
				role_id: editValues.role_id,
				related_role_id: editValues.related_role_id,
				related_entity_type: editValues.related_entity_type,
				related_entity_id: editValues.related_entity_id,
				related_people_id: editValues.related_people_id,
				exchange_ref_id: editValues.exchange_ref_id ? editValues.exchange_ref_id.toString() : null,
				tag_ids: (editValues.tag_ids && editValues.tag_ids.length > 0) ? editValues.tag_ids : undefined,
				supplier_insurance_no: editValues.supplier_insurance_no && editValues.supplier_insurance_no.toString(),
				agreed_early_payment_discount: editValues.agreed_early_payment_discount && editValues.agreed_early_payment_discount.toString(),
				currency_id: editValues.currency_id,
				crm_id: editValues.crm_id ? editValues.crm_id.toString() : null,
				rating_table: editValues.rating_table,
				additional_description: editValues.additional_description
			})
			setCurrentTab(editValues.related_entity_type)
		}

	}, [editValues, OrgBankAccountStore, OrganisationStore, PeopleStore, form, props.visible])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = async () => {
		setUpdateState(!updateState)
		if (currentTab !== form.getFieldValue("related_entity_type")) {
			await form.resetFields(['related_people_id', 'related_role_id', 'related_entity_id'])
			OrganisationStore.rOrganisationValues = null
			PeopleStore.rPeopleCardValue = null
			setSearchROrgKey(null)
			setFetchRetaldRoles(true)
			setCurrentTab(form.getFieldValue("related_entity_type"))
		}
		if (form.getFieldValue("role_id")) {
			var rating = OrganisationStore.dropdown_orgRole_list && OrganisationStore.dropdown_orgRole_list.find(x => x.role_name.toLowerCase() === "rating agency")
			if (rating && form.getFieldValue("role_id") === rating.id) {
				setRatingHide(false)
			} else {
				setRatingHide(true)
			}
		}
		validateData()
	}

	const onTabChanges = () => {
		OrganisationStore.dropdown_ROrg_list = []
		OrganisationStore.dropdown_RelatedRoles = []
		PeopleStore.dropdown_RPpl_list = []
		PeopleStore.dropdown_pplRole_list = []
	}

	// call to validate fields
	const validateData = debounce(() => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}, 200)

	// search string for dropdown
	const handleROrgSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchROrgKey(val);
			OrganisationStore.getAllROrg({ search_for: val, related_entity_type: 1 })
		};

		return debounce(loadOptions, 500);
	}, [OrganisationStore])

	// get related organisation data
	const handleROrgChange = (data) => {
		if (data) {
			OrganisationStore.getOrgCardValue(data, "relatedOrg").then(data => {
				setROrganisationValues(data)
			})
		} else {
			setROrganisationValues(null)
		}
	}

	// search string for people dropdown
	const handleRPplSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchROrgKey(val);
			PeopleStore.getPeopleSearchList({ search_for: val, related_entity_type: 2 })
		};

		return debounce(loadOptions, 500);
	}, [PeopleStore])

	// set related people data in card
	const handlePeopleChange = (data) => {
		if (data) {
			PeopleStore.getPeopleCardValue(data, 'people')
		} else {
			PeopleStore.rPeopleCardValue = null
		}
	}

	const handleRoleChange = () => {
		if (form.getFieldValue("role_id") && form.getFieldValue("related_entity_type") === "Organisation") {
			OrganisationStore.getOrgRelatedRoles({ role_id: form.getFieldValue("role_id") })
				.then(() => setFetchRetaldRoles(false))
			form.resetFields(["related_role_id"])
		}
	}

	// reset form and close edit form
	const close = () => {
		props.close()
		props.setId(null)
		form.resetFields()
		setDisabled(true)
		setCurrentTab('Organisation')
		OrganisationStore.rOrganisationValues = null
		OrganisationStore.dropdown_ROrg_list = null
		RoleMappingStore.editValues = null
		setFetchRoles(true)
		setFetchTags(true)
		setFetchCurrency(true)
		RoleMappingStore.dropdown_tags_list = null
		PeopleStore.rPeopleCardValue = null
		OrganisationStore.dropdown_orgRole_list = null
		OrganisationStore.dropdown_RelatedRoles = null
		PeopleStore.dropdown_RPpl_list = null
		PeopleStore.dropdown_pplRole_list = null
		OrgBankAccountStore.dropdown_currency_list = null
	}

	return editValues ? (
		<Drawer
			destroyOnClose={true}
			title={`Edit Mapping of Organisation/People with - ${editValues && editValues.entity_name}`}
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form}
					id="editform"
					onFinish={handleSubmit}
					layout='vertical'
					onChange={validateData}
				>
					<Row gutter={24}>
						<Col span={12}>
							<Row gutter={24}>
								<Col span={12}>
									<InputComponent
										disabled required type="select" label="Organisation" name="entity_id" placeholder="Organisation"
										options={{
											values: [{
												value: editValues.entity_id,
												text: editValues.entity_name,
												rejected_keys: form.getFieldValue("related_entity_type") === "Organisation" && form.getFieldValue("related_entity_id") !== form.getFieldValue("entity_id") && [form.getFieldValue('related_entity_id')]
											}],
										}}
										tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
									/>
								</Col>
								<Col span={12}>
									<InputComponent
										disabled={(editValues.has_transactions_count > 0)}
										required type="select" allowClear label="Organisation Role" name="role_id" placeholder="Select Organisation Role"
										onChange={async () => { await handleRoleChange(); handleChange() }}
										options={{
											values: OrganisationStore.dropdown_orgRole_list || ([
												{ id: editValues.role_id, role_name: editValues.role_name }
											]),
											value_key: 'id',
											text_key: 'role_name',
											rejected_keys: form.getFieldValue("related_entity_type") === "Organisation" && form.getFieldValue("related_role_id") !== form.getFieldValue("role_id") && [form.getFieldValue('related_role_id')]
										}}
										tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
										rules={vsmOrganisations.validation.organisation_role}
										onFocus={() => fetchRoles && OrganisationStore.getOrgRoles().then(() => setFetchRoles(false))}
										notFoundContent={fetchRoles ? <Spin size="small" /> : "No Record Found."}
									/>
								</Col>
							</Row>
							{(organisationValues) && (<OrgDetailComponent data={organisationValues} />)}
						</Col>

						<Col span={12}>

							<InputComponent
								disabled={(editValues.has_transactions_count > 0)} onChange={() => { handleChange(); onTabChanges() }}
								required type="radio_button" label="Relate to" name="related_entity_type" initialValue="Organisation"
								options={{
									values: [
										{ value: 'Organisation', text: 'Organisation' },
										{ value: 'People', text: 'People' },
										{ value: 'Ledger', text: user.default_ledger_name },
									]
								}}
								tooltip="There will be option to select: Organisation/People/Ledger Name."
								rules={vsmOrganisations.validation.related_entity_type}
							/>

							{
								form.getFieldValue("related_entity_type") === "Ledger" ? null : (
									form.getFieldValue("related_entity_type") === "Organisation" ?
										<Row gutter={24}>
											<Col span={12}>
												<InputComponent
													disabled={(editValues.has_transactions_count > 0)}
													required type="select" allowClear label="Related Organisation" name="related_entity_id" placeholder="Related People"
													onChange={(data) => {
														handleChange(data)
														handleROrgChange(data)
													}}
													options={{
														values: OrganisationStore.dropdown_ROrg_list || ([
															{ id: editValues.related_entity_id, entity_name: editValues.related_entity_name }
														]),
														value_key: 'id',
														text_key: 'entity_name',
														rejected_keys: form.getFieldValue("related_entity_type") === "Organisation" && form.getFieldValue("related_entity_id") !== form.getFieldValue("entity_id") && [form.getFieldValue('entity_id')]
													}}
													tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
													rules={vsmOrganisations.validation.related_organisation}
													filterOption={false}
													notFoundContent={OrganisationStore.fetching ? <Spin size="small" /> : (searchROrgKey) ? "No Record Found!" : null}
													onSearch={handleROrgSearch}
												/>
											</Col>
											<Col span={12}>
												<InputComponent
													disabled={(editValues.has_transactions_count > 0)}
													required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Related Role" onChange={handleChange}
													options={{
														values: OrganisationStore.dropdown_RelatedRoles || ([
															{ id: editValues.related_role_id, role_name: editValues.related_role_name }
														]),
														value_key: 'id',
														text_key: 'role_name',
														rejected_keys: form.getFieldValue("related_entity_type") === "Organisation" && form.getFieldValue("related_role_id") !== form.getFieldValue("role_id") && [form.getFieldValue('role_id')]
													}}
													tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
													rules={vsmOrganisations.validation.related_role}
													onFocus={() => fetchRelatedRoles && OrganisationStore.getOrgRelatedRoles({ role_id: form.getFieldValue("role_id") }).then(() => setFetchRetaldRoles(false))}
													notFoundContent={fetchRelatedRoles ? <Spin size="small" /> : "No Record Found."}
												/>
											</Col>
											{
												(rOrganisationValues) && (<OrgDetailComponent data={rOrganisationValues} />)
											}
										</Row>
										:
										<Row gutter={24}>
											<Col span={12}>
												<InputComponent
													disabled={(editValues.has_transactions_count > 0)}
													required type="select" allowClear label="Related People" name="related_entity_id" placeholder="Related Organisation"
													onChange={(data) => {
														handleChange(data)
														handlePeopleChange(data)
													}}
													options={{
														values: PeopleStore.dropdown_RPpl_list || ([
															{ id: editValues.related_entity_id, entity_name: editValues.related_entity_name }
														]),
														value_key: 'id',
														text_key: 'entity_name'
													}}
													tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
													rules={vsmOrganisations.validation.related_people}
													filterOption={false}
													notFoundContent={PeopleStore.fetching ? <Spin size="small" /> : (searchROrgKey) ? "No Record Found!" : null}
													onSearch={handleRPplSearch}
												/>
											</Col>
											<Col span={12}>
												<InputComponent
													disabled={(editValues.has_transactions_count > 0)}
													required type="select" allowClear label="Related Role" name="related_role_id" placeholder="Select Related Role" onChange={handleChange}
													options={{
														values: PeopleStore.dropdown_pplRole_list || ([
															{ id: editValues.related_role_id, role_name: editValues.related_role_name }
														]),
														value_key: 'id',
														text_key: 'role_name'
													}}
													tooltip="People Role Relation is the connection between the Roles played by two different People."
													rules={vsmOrganisations.validation.related_role}
													onFocus={() => fetchRelatedRoles && PeopleStore.getPeopleRoles().then(() => setFetchRetaldRoles(false))}
													notFoundContent={fetchRelatedRoles ? <Spin size="small" /> : "No Record Found."}
												/>
											</Col>
											{
												PeopleStore.rPeopleCardValue && (<PplDetailComponent data={PeopleStore.rPeopleCardValue} />)
											}
										</Row>
								)
							}
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={8}>
							<InputComponent
								mode="tags" type="select" allowClear label="Tags" name="tag_ids"
								placeholder="Select one or more tags" onChange={handleChange}
								options={{
									values: RoleMappingStore.dropdown_tags_list || (
										editValues.tag_ids && editValues.tag_ids.map((x, i) => (typeof (x) === 'object') ? x : { id: x, tag_name: editValues.tags_names[i] })
									),
									value_key: 'id',
									text_key: 'tag_name',
								}}
								tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
								rules={vsmOrganisations.validation.tag_name}
								maxTagTextLength={10}
								tokenSeparators={[',']}
								onFocus={() => fetchTags && getAllTagsList().then(() => setFetchTags(false))}
								notFoundContent={fetchTags ? <Spin size="small" /> : "No Record Found."}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								label="Exchange Reference Id" name="exchange_ref_id" placeholder="Exchange Reference Id" onChange={handleChange}
								tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
								rules={vsmOrganisations.validation.exchange_ref_id}
								maxLength={100}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								disabled={(editValues.has_transactions_count > 0)}
								label="Supplier Insurance No." name="supplier_insurance_no" placeholder="Supplier Insurance Number" onChange={handleChange}
								tooltip="The unique insurance number assigned to each supplier."
								rules={vsmRoleMapping.validation.Insurance}
								maxLength={100}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={8}>
							<InputComponent
								disabled={(editValues.has_transactions_count > 0)}
								label="Agreed Early Payment Discount" name="agreed_early_payment_discount" placeholder="Agreed Early Payment Discount" onChange={handleChange}
								tooltip="A discount that buyers can receive in exchange for paying invoices early."
								rules={vsmRoleMapping.validation.Discount}
								maxLength={100}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								disabled={(editValues.has_transactions_count > 0)}
								allowClear type="select" label="Currency" name="currency_id" placeholder="Select Organisation Currency"
								onChange={handleChange}
								tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
								onFocus={() => (fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false)))}
								notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: OrgBankAccountStore.dropdown_currency_list,
									value_key: "id",
									text_key: "currency_name"
								}}
							/>
						</Col>
						<Col span={8}>
							<InputComponent
								disabled={(editValues.has_transactions_count > 0)}
								label="CRM ID" name="crm_id" placeholder="Enter CRM Refernce ID" onChange={handleChange}
								tooltip="The unique id for the CRM."
								rules={vsmRoleMapping.validation.crm}
								maxLength={100}
							/>
						</Col>
					</Row>

					{
						ratingHide ? null : (
							<>
								<Card title="Rating Table" className="mb-25">
									<RatingElement form={form} onChange={validateData} />
								</Card>
								<InputComponent
									required allowClear type="textarea" label="Description" name="additional_description" placeholder="Description"
									onChange={validateData} rules={vsmOrganisations.validation.description}
									tooltip="The purpose of describing the organisation is to provide; an understanding of mission, goals, & objectives, and clarify the focus and importance of the organisation's effort."
								/>
							</>
						)
					}
				</Form>
			</Spin>
		</Drawer>
	) : null
})

export default EditComponent
