import { Button, PageHeader } from 'antd'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import RecordPerPage from '../../../components/RecordPerPage'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import ListComponent from './components/ListComponent'
import AddComponent from './components/AddComponent'
import EditComponent from './components/EditComponent'
import DeleteComponent from './components/DeleteComponent'
import ActivateComponent from './components/ActivateComponent'
import DeactivateComponent from './components/DeactivateComponent'
import ImportComponent from './components/ImportComponent'
import ExchangeAPI from './components/ExchangeAPI'
import BulkEditRates from './components/BulkEditRates'
import ViewHistortComponent from './components/ViewHistory'
import { vsmNotify } from '../../../config/messages'
import Unauthorized from '../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../components/EnterFullscreen'

const FXCurrencies = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [activateModal, setActivateModal] = useState(false)
	const [deactivateModal, setDeactivateModal] = useState(false)
	const [importModal, setImportModal] = useState(false)
	const [editRatesModal, setEditRatesModal] = useState(false)
	const [exchangeAPIModal, setExchangeAPIModal] = useState(false)
	const [viewHistory, setViewHistory] = useState(false)
	const [historyId, setHistoryId] = useState(null)
	const [currentTab, setCurrentTab] = useState("action")
	const { UserStore, FXCurrenciesStore } = useStore()

	const setTab = (id) => setCurrentTab("action")

	// Open form for add new currency
	const openAddModal = () => setAddModal(true)

	// Close form for close new currency
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing currency and set values to form
	const openEditModal = (data) => {
		FXCurrenciesStore.setEditValues(data)
		setEditModal(true)
	}

	// Close form of edit currency
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing currency
	const openDeleteModal = (data) => {
		FXCurrenciesStore.setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	//Open Modal to import data
	const importData = () => {
		setImportModal(true)
	}

	//Close import modal
	const closeImportModal = () => setImportModal(false)

	//Open Modal to Bulk Edit Manual Rates
	const openEditRatesModal = () => {
		if (FXCurrenciesStore.agGrid) {
			let selected = FXCurrenciesStore.agGrid.api.getSelectedRows()
			if (selected && selected.length > 0) {
				FXCurrenciesStore.selectedIds = selected
				setEditRatesModal(true)
			}
			else { vsmNotify.error({ message: "Please select atleast one record to Bulk Edit Manual Rates." }) }
		}
	}

	//Close Modal of Bulk Edit Manual Rates
	const closeEditRatesModal = () => setEditRatesModal(false)

	// Handle on switch data
	const onSwitchChange = (checked, data) => {
		FXCurrenciesStore.setCurrentValues(data)
		FXCurrenciesStore.agGrid.api.refreshCells({ force: true })
		if (!checked) {
			setDeactivateModal(true)
		} else {
			if ((UserStore.user.user_role.user_role_id === 2) && (data.currency_settings === null)) {
				FXCurrenciesStore.exchangeRateValues = data
				setExchangeAPIModal(true)
				setCurrentTab("status")
			} else {
				setActivateModal(true)
			}
		}
	}

	// Open confirmation alert for activate currency
	const openActivateModal = () => setActivateModal(true)

	// Close confirmation alert for activate currency
	const closeActivateModal = () => setActivateModal(false)

	// Close confirmation alert for deactivate currency
	const closeDeactivateModal = () => setDeactivateModal(false)

	// Open Exchange API for Currency
	const openExchangeAPIModal = (data) => {
		FXCurrenciesStore.setExchangeRateValues(data)
		setExchangeAPIModal(true)
	}

	// Close Exchange API for Currency
	const closeExchangeAPIModal = () => setExchangeAPIModal(false)

	const setId = (id) => setHistoryId(id)

	// Open View History Listing
	const openViewHistory = (data) => {
		setViewHistory(true)
		setId(data.id)
		FXCurrenciesStore.setViewValues(data)
	}

	const closeViewHistory = () => setViewHistory(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('fx_currency_grid')
		FXCurrenciesStore.agGrid.api.setFilterModel(null)
		FXCurrenciesStore.agGrid.api.setSortModel(null)
		FXCurrenciesStore.agGrid.columnApi.resetColumnState()
		FXCurrenciesStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.FXCurrencies.path} />
			{(!UserStore.checkPermission(55, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					key="page"
					title={BreadcrumbConfig.FXCurrencies.title}
					extra={[
						(UserStore.user.user_role.user_role_id === 1) ? (
							UserStore.checkPermission(55, 'add') && <Button key="1" onClick={openAddModal}>New</Button>
						) : (
							<>
								{UserStore.checkPermission(55, 'bulk_edit_rate') && <Button key="2" onClick={openEditRatesModal}>Bulk Edit Manual Rates</Button>}
								{UserStore.checkPermission(55, 'import') && <Button key="3" onClick={importData}>Import Rate History</Button>}
							</>
						),
						<RecordPerPage key="4" defaultValue={FXCurrenciesStore.per_page + ' per page'} onChange={FXCurrenciesStore.setPageSize} />,
						<Button key="5" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='6' />
					]}
				>
					<ListComponent openViewHistory={openViewHistory} openEditModal={openEditModal} openDeleteModal={openDeleteModal} onSwitchChange={onSwitchChange} openExchangeAPIModal={openExchangeAPIModal} />
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<ActivateComponent visible={activateModal} close={closeActivateModal} />
					<DeactivateComponent visible={deactivateModal} close={closeDeactivateModal} />
					<ImportComponent visible={importModal} close={closeImportModal} importData={importData} />
					<BulkEditRates visible={editRatesModal} close={closeEditRatesModal} openEditRatesModal={openEditRatesModal} />
					<ExchangeAPI currentTab={currentTab} setTab={setTab} visible={exchangeAPIModal} close={closeExchangeAPIModal} openActivateModal={openActivateModal} />
					<ViewHistortComponent historyId={historyId} setId={setId} visible={viewHistory} close={closeViewHistory} />
				</PageHeader>
			)}
		</>
	)
})

export default FXCurrencies
