import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Modal } from 'antd'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmPplBankAccount, vsmNotify } from "../../../../config/messages";

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { PeopleBankAccountStore, OrgBankAccountStore } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [currentTab, setCurrentTab] = useState("account")

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		data.people_id = props.parent_id
		PeopleBankAccountStore.AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmPplBankAccount.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
		}).catch(e => {
			if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// set field vaule null based on tab selection
	const changeMapwith = async () => {
		await setCurrentTab(form.getFieldValue("tab"))
		form.setFieldsValue({
			account_number: null,
			sort_code: null,
			iban: null,
			swift_bic: null
		})
		handleChange()
	}

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setDisabled(true)
		setCurrentTab('account')
		PeopleBankAccountStore.fetchBankNames = true
		PeopleBankAccountStore.fetchBankTypes = true
		PeopleBankAccountStore.fetchCurrencies = true
		OrgBankAccountStore.dropdown_currency_list = null
		props.close()
	}

	return (
		<Modal
			destroyOnClose={true}
			centered
			title="Add New Bank Account"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>

			<FormComponent
				visible={props.visible}
				onChange={handleChange}
				onFinish={handleSubmit}
				tab={"add"}
				currentTab={currentTab}
				setCurrentTab={setCurrentTab}
				form={form}
			/>

		</Modal>
	)

})

export default AddComponent
