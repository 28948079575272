import Axios from "axios"
import { decorate, observable, action } from "mobx"

export default class DashboardStore {
	defaultDashboardData = []
	widgetList = null
	readData = null
	editKey = null
	reportList = null
	agGrid = null
	report_data = null
	initialValues = {
		extra_option: 'single',
		x_value: 'days',
		y_value: 'numbers',
		range: '10',
		single_select: '7',
		multi_select: '7'
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		// let columnConfig = localStorage.getItem('tax_setting_grid')
		// if (this.agGrid && this.agGrid.columnApi && columnConfig) {
		// 	this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		// }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// call api to get count of journals
	getDefaultDashboard = () => {
		return Axios('dashboard/get').then(({ data }) => {
			this.defaultDashboardData = data.data
			return data.data
		})
	}

	// Adding new widget
	addWidget = (newWidgetData) => {
		return Axios.post('dashboard/widget/add', newWidgetData).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				// Remove .data from key
				var name1 = name.split(".")[1];
				errors.push({ name: name1, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Delete Widget
	deleteWidget = (id) => {
		return Axios(`dashboard/widget/delete/${id}`).then(({ data }) => {
			return data
		})
	}

	editWidget = (data) => {
		return Axios.post(`dashboard/widget/edit/${this.editKey}`, data).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				// Remove .data from key
				var name1 = name.split(".")[1];
				errors.push({ name: name1, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	getWidgetFunction = () => {
		return Axios('dashboard/widget-function/get').then(({ data }) => {
			this.widgetList = data.data
			return data
		})
	}

	getReports = () => {
		return Axios.get('dashboard/save-reports/get').then(({ data }) => {
			this.reportList = data.data
			return data.data
		})
	}

	readWidgetData = (id) => {
		return Axios(`dashboard/widget/read/${id}`).then(({ data }) => {
			this.readData = data.data
			return data.data
		})
	}

	positionSave = (data) => {
		return Axios.post('dashboard/widget/position/save', data).then(({ data }) => {
			return data
		})
	}

	convertReportData = (data) => {
		data.map((item) => {
			Object.keys(item).map((itemKey) => {
				let abc = '', outerIndex = 0;
				if (typeof item[itemKey] === 'object' && item[itemKey] !== null) {
					if (item[itemKey].length) {
						item[itemKey].map((objItem, index) => {
							let objValue = Object.values(objItem);
							if (index > 0 && outerIndex !== index && abc !== "") {
								abc += ", ";
							}
							outerIndex = index
							return abc += objValue[1];
						})
						item[itemKey] = abc
					} else {
						let objValue = Object.keys(item[itemKey])
						abc += item[itemKey][objValue[1]]
						return item[itemKey] = abc
					}
				}
				return itemKey
			})
			return item
		})
	}

	convertAPIData = (data) => {
		let newX = JSON.parse(data.generated_on_options)
		let newChart = { ...newX }
		newChart.id = data.id
		newChart.name = data.name;
		newChart.code = data.code;
		newChart.description = data.description;
		newChart.report_type = data.report_type

		let url = '/report/preview'
		if (data.report_type === 'audit') {
			url = '/report/audit'
			if (data.action === 'preview_child_report') {
				url = '/report/audit/child'
			}
		}
		if (data.report_type === 'organisation') {
			url = `report/preview/organisation`
		}
		if (data.report_type === 'people') {
			url = `report/preview/people`
		}
		return Axios.post(url, newChart).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(DashboardStore, {
	defaultDashboardData: observable,
	initialValues: observable,
	widgetList: observable,
	readData: observable,
	editKey: observable,
	reportList: observable,
	getDefaultDashboard: action,
	getWidgetFunction: action,
	addWidget: action,
	readWidgetData: action,
	editWidget: action,
	getReports: action,
	setupGrid: action,
	onFilterChanged: action,
	convertReportData: action,
	convertAPIData: action,
})
