import React from 'react';
import { observer } from 'mobx-react-lite';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import useStore from '../../../../store';
import { Button } from 'antd';
import GridConfig from '../../../../config/GridConfig';
import { vsmCommon } from '../../../../config/messages';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';

const ListComponent = observer((props) => {
	const { openEditTradeDrawer, openViewTradeDrawer } = props
	const { PurchaseOrderStore, AppsAuthStore } = useStore()

	const ActionRenderer = (props) => {
		return (
			<div className="action-column">
				{AppsAuthStore.permissions && AppsAuthStore.checkPermission(117, 'edit') &&
					<Button title="Edit Bank Accounts" type="text" onClick={() => { openEditTradeDrawer(props.data.id) }}><EditOutlined /></Button>
				}
				{AppsAuthStore.permissions && AppsAuthStore.checkPermission(117, 'show') &&
					<Button title="Edit Bank Accounts" type="text" onClick={() => { openViewTradeDrawer(props.data.id) }}><EyeOutlined /></Button>
				}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'Credebtor ID',
			field: 'debtor.id',
			filter: 'agNumberColumnFilter',
			tooltipField: 'debtor.id',
			width: 110
		}, {
			headerName: 'ETR Type',
			field: 'etr_type',
			tooltipField: 'Type',
			width: 100,
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['a-ETR', 'c-ETR', 'd-ETR', 'f-ETR']
			},
		}, {
			headerName: 'Status',
			field: 'status',
			tooltipField: 'Status',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: ['Draft', 'Awaiting Approval', 'Approved', 'Rejected']
			},
			width: 130
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			minWidth: 160,
			filter: false,
			sortable: false,
			pinned: 'right',
			width: 175
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('purchase_order_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditTradeDrawer, openViewTradeDrawer
				}}
				onGridReady={PurchaseOrderStore.setupGrid}
				onColumnResized={PurchaseOrderStore.onGridChanged}
				onColumnMoved={PurchaseOrderStore.onGridChanged}
				onColumnPinned={PurchaseOrderStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent;
