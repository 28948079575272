import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useStore from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../img/logo.png';
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import Trade from '../../assets/images/AppsDashboard/Trade.png'
import dashboard from '../../assets/images/AppsDashboard/dashboard.png'
import Credetor from '../../assets/images/AppsDashboard/Credetor.png'
import UserIcon from '../../assets/images/AppsDashboard/user.png'
const { Sider } = Layout;

const AppsSidebarComponent = observer((props) => {
    const location = useLocation()
    const { AppsAuthStore, AppsAuthStore: { originator_user, apps_ledger_base_currency } } = useStore()
    const [openMenu, setOpenMenu] = useState([])
    let menukey = 1
    const icons = {
        86: dashboard,
        87: Trade,
        90: Credetor,
        93: UserIcon,
        // 88: faFileAlt,
        // 91: faUserCircle,
        // 94: faCogs,
        // 95: faMoneyBillAlt,
        // "0_0": faStar
    }

    const AppMenu = (menu_item, open = []) => {
        return menu_item ? Object.keys(menu_item)
            .sort((a, b) => (menu_item[a].sequence_no > menu_item[b].sequence_no) ? 1 : ((menu_item[b].sequence_no > menu_item[a].sequence_no) ? -1 : 0))
            .map((key) => {
                let item = menu_item[key]
                if (item.submenu) {
                    return (
                        <>
                            <Menu.SubMenu key={item.title + menukey} icon={icons[item.id] ? <img src={icons[item.id]} alt='' /> : <FontAwesomeIcon icon={faCircle} />} title={item.title}>
                                {AppMenu(item.submenu, [...open, (item.title + menukey++)])}
                            </Menu.SubMenu>
                        </>
                    )
                } else {
                    if (originator_user && originator_user.user_role && originator_user.user_role.user_role_id !== 1 && [].includes(item.path)) {
                        return null
                    }
                    if (location.pathname.includes(item.path) && openMenu.length <= 0) {
                        if (open.length === 0) {
                            open.push(item.path)
                        }
                        setOpenMenu(open)
                    }
                    return (
                        <>
                            <Menu.Item key={item.path} icon={icons[item.id] ? <img src={icons[item.id]} alt='' /> : <FontAwesomeIcon icon={faCircle} size="xs" className="mr-10" />}>
                                <span>{item.title}</span>
                                {(apps_ledger_base_currency) && <Link to={item.path} />}
                            </Menu.Item>
                        </>
                    )
                }
            }) : null
    }
    return (
        <Sider width="280" trigger={null} collapsible collapsed={props.collapsed} className="sider_area">
            {AppsAuthStore.apps_ledger_base_currency ? (<Link to="/apps/dashboard" className="logo">
                <img src={logo} alt="" />
            </Link>) : (<div className="logo"><img src={logo} alt="" /></div>)}

            <Menu theme="dark" mode="inline" defaultOpenKeys={openMenu} selectedKeys={location.pathname}>
                {AppMenu(AppsAuthStore.apps_menu)}
            </Menu>
        </Sider>
    );
})

export default AppsSidebarComponent;