import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card } from 'antd'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'
import LocalGridConfig from '../../../../config/LocalGridConfig'
import NumberFormat from 'react-number-format'

const GlobalReportWidget = observer((props) => {
    let columnConfig = []
    let total_column = null
    let gridOptions
    let rowData
    let PinnedData = []
    let closing = 0
    let common_closing = null
    let total_periods = 0
    const { DashboardStore, SavedReportStore } = useStore()
    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        chartData === null &&
            SavedReportStore.ReadReport(props.el.settings_json?.i.report_id).then(async (data) => {
                await DashboardStore.convertAPIData(data.data).then(({ data }) => {
                    if (chartData === data || chartData === null) { setChartData(data) }
                })
            })
    }, [DashboardStore, SavedReportStore, chartData, props.el.settings_json])

    if (chartData) {
        return (
            <Card className="widget_wrapper">
                <div className="counter__widget">
                    <div className="counter__widget__description">
                        <div className="counter__widget__name">{props.el.dashboard_name}</div>
                    </div>
                </div>
                {Object.keys(chartData).map((table, index) => {
                    gridOptions = {}
                    rowData = []
                    PinnedData = []
                    closing = chartData[table].opening_balance
                    if (chartData[table].list && chartData[table].list[0]) {
                        rowData = chartData[table].list
                        if (rowData.slice(-1)[0].journal_desc === 'Total') {
                            rowData.pop()
                        }
                        columnConfig = [
                            {
                                headerName: 'Code', field: 'journal_code', pinned: 'left', width: 130,
                                valueGetter: (params) => (params.data && params.data.journal_code) ? params.data.journal_code : ""
                            },
                            {
                                headerName: 'Description', field: 'journal_desc', pinned: 'left', width: 200,
                                valueGetter: (params) => (params.data && params.data.journal_desc) ? params.data.journal_desc : ""
                            }
                        ]
                        if (chartData[table].list[0].periods) {
                            total_periods = []
                            chartData[table].list[0].periods.forEach((x, i) => {
                                var temp = {
                                    headerName: x.period_name,
                                    children: [
                                        {
                                            headerName: 'Debit', field: 'periods.' + i + '.debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                            width: 110,
                                            cellRendererFramework: (params) => (params.data) ? (
                                                <NumberFormat value={params.data.periods[i].debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                            ) : ""
                                        },
                                        {
                                            headerName: 'Credit', field: 'periods.' + i + '.credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                            width: 110,
                                            cellRendererFramework: (params) => (params.data) ? (
                                                <NumberFormat value={params.data.periods[i].credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                            )
                                                : ""
                                        },
                                        {
                                            headerName: 'Posting', field: 'periods.' + i + '.posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                            width: 110,
                                            cellRendererFramework: (params) => (params.data) ? (
                                                <NumberFormat value={params.data.periods[i].posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                            ) : ""
                                        }
                                    ]
                                }
                                total_column = { debit_value: 0, credit_value: 0, posting_value: 0 }

                                chartData[table].list.forEach(x => {
                                    total_column.debit_value = parseFloat((total_column.debit_value + x.periods[i].debit_value).toFixed(2))
                                    total_column.credit_value = parseFloat((total_column.credit_value + x.periods[i].credit_value).toFixed(2))
                                    total_column.posting_value = parseFloat((total_column.posting_value + x.periods[i].posting_value).toFixed(2))
                                })
                                total_periods.push(total_column)
                                columnConfig.push(temp)
                            })
                            PinnedData.push({
                                journal_desc: 'Total',
                                periods: total_periods
                            })
                        } else {
                            columnConfig.push({
                                headerName: 'Debit', field: 'debit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                cellRendererFramework: (params) => (params.data) ? (
                                    <NumberFormat value={params.data.debit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                ) : ""
                            })
                            columnConfig.push({
                                headerName: 'Credit', field: 'credit_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                width: 110,
                                cellRendererFramework: (params) => (params.data) ? (
                                    <NumberFormat value={params.data.credit_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                )
                                    : ""
                            })
                            columnConfig.push({
                                headerName: 'Posting', field: 'posting_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
                                width: 110,
                                cellRendererFramework: (params) => (params.data) ? (
                                    <NumberFormat value={params.data.posting_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                                ) : ""
                            })

                            total_column = { journal_desc: 'Total', debit_value: 0, credit_value: 0, posting_value: 0 }

                            chartData[table].list.forEach(x => {
                                total_column.debit_value = parseFloat((total_column.debit_value + x.debit_value).toFixed(2))
                                total_column.credit_value = parseFloat((total_column.credit_value + x.credit_value).toFixed(2))
                                total_column.posting_value = parseFloat((total_column.posting_value + x.posting_value).toFixed(2))
                                closing = parseFloat((closing + x.posting_value).toFixed(2))
                            })
                            PinnedData.push(total_column)
                        }
                        gridOptions = {
                            columnDefs: columnConfig
                        }

                        return (
                            <>
                                <div className="preview_table_header">
                                    <div>{table}</div>
                                    {(!chartData[table].list[0].periods) && (
                                        <div>Opening Balance : <NumberFormat value={(chartData[table].opening_balance).toString()} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                    )}
                                </div>
                                <div className="mb-0">
                                    <div className="ag-theme-alpine" style={{ height: '320px' }}>
                                        <AgGridReact
                                            pinnedBottomRowData={PinnedData}
                                            rowData={rowData}
                                            modules={AllModules}
                                            columnDefs={gridOptions.columnDefs}
                                            defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false }}
                                            columnTypes={LocalGridConfig.columnTypes}
                                            overlayNoRowsTemplate={vsmCommon.noRecord}
                                            gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                                            onGridReady={(params) => DashboardStore.setupGrid(params, index)}
                                        />
                                    </div>
                                </div>
                                {(chartData[table].list[0].periods) ? null : (
                                    <div className="preview_table_footer">
                                        <div></div>
                                        <div>Closing Balance : <NumberFormat value={closing} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                    </div>
                                )}

                            </>
                        )
                    }
                    return null
                })
                }
                {(common_closing !== null) && (
                    <div className="preview_table_footer">
                        <div></div>
                        <div>Closing Balance : <NumberFormat value={common_closing} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                    </div>
                )}
            </Card>
        )
    }
})

export default GlobalReportWidget
