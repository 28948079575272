import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import { DateComparator } from '../../../../utils/GlobalFunction'
import GridConfig from '../../../../config/GridConfig'
import useStore from '../../../../store'
import { Spin } from 'antd'

const PreviewDetailTableElement = observer((props) => {
	const { SavedReportStore } = useStore()
	const [Headers, SetHeaders] = useState()
	let gridOptions = {
		columnDefs: [
			{ headerName: 'Transaction ID', field: 'transaction_id', filter: 'agNumberColumnFilter', width: 80, },
			{
				headerName: 'Organization', field: 'entity_name', filter: 'agSetColumnFilter', filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						SavedReportStore.getOrgFilter('entity').then((data) => {
							params.success([null, ...data.data.map(x => x.entity_name)]);
						})
					}
				},
				width: 200,
			},
			{
				headerName: 'Related Organization', field: 'related_entity_name', filter: 'agSetColumnFilter', filterParams: {
					defaultToNothingSelected: true,
					buttons: ['apply', 'reset'],
					values: (params) => {
						SavedReportStore.getOrgFilter('related_entity').then((data) => {
							params.success([null, ...data.data.map(x => x.related_entity_name)]);
						})
					}
				},
				width: 200,
			},
			{
				headerName: 'Face Value', field: 'face_value', type: 'rightAligned', filter: 'agNumberColumnFilter',
				width: 130,
				cellRendererFramework: (params) => (params.data) ? (
					<NumberFormat value={params.data.face_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
				) : null
			},
			{
				headerName: 'Journal Log Date', field: 'journal_log_date',
				filter: 'agDateColumnFilter',
				width: 185,
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					comparator: DateComparator
				}
			},
		]
	}
	useEffect(() => {
		SavedReportStore.PreviewDetails({ ...SavedReportStore.PreviewDetailPayload, page: 1, zero_record: true }).then((data) => {
			SavedReportStore.PreviewDetailPinnedRow = data.extra_data
			if (data.data && data.data[0] && data.data[0].periods) {
				var period_columns = []
				data.data[0].periods.forEach((x, i) => {
					var temp = {
						headerName: x.period_name,
						children: [
							{
								headerName: 'Debit', field: 'periods.' + i + '.debit', type: 'rightAligned', filter: 'agNumberColumnFilter',
								width: 110,
								cellRendererFramework: (params) => (params.data && params.data.periods) ? (
									<NumberFormat value={params.data.periods[i].debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
								) : null
							},
							{
								headerName: 'Credit', field: 'periods.' + i + '.credit', type: 'rightAligned', filter: 'agNumberColumnFilter',
								width: 110,
								cellRendererFramework: (params) => (params.data && params.data.periods) ? (
									<NumberFormat value={params.data.periods[i].credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
								) : null
							},
							{
								headerName: 'Posting', field: 'periods.' + i + '.posting', type: 'rightAligned',
								width: 110,
								filter: false,
								cellRendererFramework: (params) => (params.data && params.data.periods) ? (
									<NumberFormat value={params.data.periods[i].posting} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
								) : null

							}
						]
					}
					period_columns.push(temp)
				})
				SetHeaders(period_columns)
			} else {
				SetHeaders([
					{
						headerName: 'Debit', field: 'debit', type: 'rightAligned', filter: 'agNumberColumnFilter',
						width: 110,
						cellRendererFramework: (params) => (params.data) ? (
							<NumberFormat value={params.data.debit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						) : null
					},
					{
						headerName: 'Credit', field: 'credit', type: 'rightAligned', filter: 'agNumberColumnFilter',
						width: 110,
						cellRendererFramework: (params) => (params.data) ? (
							<NumberFormat value={params.data.credit} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						) : null
					},
					{
						headerName: 'Posting', field: 'posting', type: 'rightAligned',
						width: 110,
						filter: false,
						cellRendererFramework: (params) => (params.data) ? (
							<NumberFormat value={params.data.posting} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
						) : null
					}
				])
			}
		})
	}, [SavedReportStore])
	if (Headers) {
		SavedReportStore.previewDetailGrid = [...gridOptions.columnDefs, ...Headers]
		return (
			<div className="" style={{ height: '85vh', marginBottom: 24, paddingBottom: 10 }}>
				<div className="ag-theme-alpine grid_wrapper">
					<AgGridReact
						modules={AllModules}
						columnDefs={SavedReportStore.previewDetailGrid}
						defaultColDef={{ ...GridConfig.defaultColDef, sortable: false }}
						columnTypes={GridConfig.columnTypes}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						onGridReady={props.onGridReady}
						gridOptions={{ ...GridConfig.options, pagination: true }}
						pinnedBottomRowData={SavedReportStore.PreviewDetailPinnedRow}
						onColumnResized={SavedReportStore.onChildnGridChanged}
						onColumnMoved={SavedReportStore.onChildnGridChanged}
						onColumnPinned={SavedReportStore.onChildnGridChanged}
						onSortChanged={SavedReportStore.onChildnGridChanged}
					/>
				</div>
			</div>
		)
	} else {
		return (
			<div className="d-flex justify-content-center">
				<Spin spinning={!Headers} />
			</div>
		)
	}
})

export default PreviewDetailTableElement
