import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import React from 'react';
import { FileOutlined } from '@ant-design/icons'

import useStore from '../../../../store'

const OriginatorDetailView = observer(() => {
	const { MemberStore: { viewMemberValue } } = useStore()

	return (
		<div className='members_table'>
			<table cellPadding='0' cellSpacing='0' border='0'>
				<tbody key="tbody">
					<tr>
						<th width="100px">Organisation Name</th>
						<th width="100px">Trade Name</th>
						<th width="100px">Parent Orgination(s)</th>
						<th width="100px">Types (s)</th>
						<th width="130px" rowspan="4">{viewMemberValue?.logo_url ? <img style={{ width: '140px', height: '140px', objectFit: 'cover', display: 'block', margin: '0 auto' }} src={process.env.React_APP_API_URL + viewMemberValue?.logo_url} alt="" /> : 'LOGO'}</th>
					</tr>
					<tr>
						<td height={"40px"}>{viewMemberValue?.organisation_name ? viewMemberValue?.organisation_name : ''}</td>
						<td height={"40px"}>{viewMemberValue?.trade_name ? viewMemberValue?.trade_name : ''}</td>
						<td height={"40px"}>{viewMemberValue?.parent?.organisation_name ? viewMemberValue?.parent?.organisation_name : ''}</td>
						<td height={"40px"}>{viewMemberValue?.member_role_mapping ? viewMemberValue?.member_role_mapping?.role_name ? viewMemberValue?.member_role_mapping?.role_name : '' : ''}</td>
					</tr>
					<tr>
						<th>Currency</th>
						{viewMemberValue?.member_role_mapping?.role_name === "Originator" && <th>Turn Over</th>}
						<th><Button type='link' className='p-0' style={{ height: '22px' }} onClick={() => window.open("https://www.convertibill.com/credebtclassifications-popup.html", "", "width=650,height=600")}>Credebt® Classification</Button></th>
						<th colSpan={viewMemberValue?.member_role_mapping?.role_name !== "Originator" && 2}><Button type='link' className='p-0' style={{ height: '22px', fontSize: '14px' }} onClick={() => window.open("https://www.convertibill.com/nacecategories-popup.html", "", "width=650,height=600")}>NACE Category NACE Code</Button></th>
					</tr>
					<tr>
						<td height={"40px"}>{viewMemberValue?.global_currency?.currency_name ? viewMemberValue?.global_currency?.currency_name : ''}</td>
						{viewMemberValue?.member_role_mapping?.role_name === "Originator" && <td height={"40px"}></td>}
						<td height={"40px"}>{viewMemberValue?.global_industries ? viewMemberValue?.global_industries?.name : ""}</td>
						<td colSpan={viewMemberValue?.member_role_mapping?.role_name !== "Originator" && 2} height={"40px"}>{viewMemberValue?.global_nace_sections ? `${viewMemberValue?.global_nace_sections?.get_nace_category?.category_name}-${viewMemberValue?.global_nace_sections?.code}` : ""}</td>
					</tr>
					<tr>
						<th>Registration Country</th>
						<th>Registration Number</th>
						<th>Sales Tax / VAT Number</th>
						{viewMemberValue?.member_role_mapping?.role_name !== "Investor" ? <th>{viewMemberValue?.member_role_mapping?.role_name === "Intermediary" ? 'Website' : 'Tax ID Document'}</th> : <th>Quoted Rate</th>}
						{viewMemberValue?.member_role_mapping?.role_name !== "Investor" ? <th>{viewMemberValue?.member_role_mapping?.role_name === "Intermediary" ? 'Proof Of Identity' : 'Formation Year'}</th> : <th>Sign RPA Agreement</th>}
					</tr>
					<tr>
						<td height={"40px"}>{viewMemberValue?.global_countries?.country_name ? viewMemberValue?.global_countries?.country_name : ''}</td>
						<td height={"40px"}>{viewMemberValue?.registration_number ? viewMemberValue?.registration_number : ''}</td>
						<td height={"40px"}>{viewMemberValue?.vat_number ? viewMemberValue?.vat_number : ''}</td>
						{viewMemberValue?.member_role_mapping?.role_name !== "Investor" ? <td height={"40px"}>{viewMemberValue?.member_role_mapping?.role_name === "Intermediary" ? viewMemberValue?.website ? viewMemberValue?.website : '' : ''}</td> : <td height={"40px"}>{viewMemberValue.credit_risk && viewMemberValue.credit_risk?.quoted_rate}</td>}
						{viewMemberValue?.member_role_mapping?.role_name !== "Investor" ? <td height={"40px"}>{viewMemberValue?.member_role_mapping?.role_name === "Originator" ? viewMemberValue?.credit_risk.formation_year ? viewMemberValue?.credit_risk?.formation_year : '' : <a href={process.env.React_APP_API_URL + viewMemberValue?.credit_risk?.id_proof}><FileOutlined /> {viewMemberValue?.credit_risk?.id_proof?.split('/').pop()}</a>}</td> : <td>{viewMemberValue.credit_risk.rpa_agreement && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.rpa_agreement}><FileOutlined /> {viewMemberValue?.credit_risk?.rpa_agreement?.split('/').pop()}</a>}</td>}
					</tr>
					{
						viewMemberValue?.member_role_mapping?.role_name === "Investor" &&
						<>
							<tr>
								<th>Bank Statement Document</th>
								<th>Check Document</th>
								<th>Personal Identity</th>
								<th>Password / Driving Licence</th>
								<th>Utility Bill / Bank Statement</th>
							</tr>
							<tr>
								<td>{viewMemberValue.credit_risk.bank_statement_proof && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.bank_statement_proof}><FileOutlined /> {viewMemberValue?.credit_risk?.bank_statement_proof?.split('/').pop()} </a>}</td>
								<td>{viewMemberValue.credit_risk.cheque_document && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.cheque_document}><FileOutlined /> {viewMemberValue?.credit_risk?.cheque_document?.split('/').pop()}</a>}</td>
								<td>{viewMemberValue.credit_risk.personal_identity_record && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.personal_identity_record}><FileOutlined /> {viewMemberValue?.credit_risk?.personal_identity_record?.split('/').pop()}</a>}</td>
								<td>{viewMemberValue.credit_risk.passport_driving_license && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.passport_driving_license}><FileOutlined /> {viewMemberValue?.credit_risk?.passport_driving_license?.split('/').pop()}</a>}</td>
								<td>{viewMemberValue.credit_risk.utility_document && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.utility_document}><FileOutlined /> {viewMemberValue?.credit_risk?.utility_document?.split('/').pop()}</a>}</td>
							</tr>
						</>
					}
					{
						viewMemberValue?.member_role_mapping?.role_name === "Investor" &&
						<>
							<tr>
								<th colSpan={3}>Description</th>
								<th>Phone</th>
								<th>Email</th>
							</tr>
							<tr>
								<td height={"40px"} colSpan={3}>{viewMemberValue?.description ? viewMemberValue?.description : ""}</td>
								<td height={"40px"}>{viewMemberValue?.multiple_phone && viewMemberValue?.multiple_phone?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
								<td>{viewMemberValue?.email && viewMemberValue?.email?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
							</tr>
						</>
					}
					{
						viewMemberValue?.member_role_mapping?.role_name === "Originator" &&
						<>
							<tr>
								<th>Tax Id Number</th>
								<th>Tax Clearance Access No.</th>
								<th>Proof of Identity</th>
								<th>Proof of Address</th>
								<th>Document</th>
							</tr>
							<tr>
								<td height={"40px"}>{viewMemberValue?.tax_id_number ? viewMemberValue?.tax_id_number : ''}</td>
								<td height={"40px"}>{viewMemberValue?.revenue_access_number ? viewMemberValue?.revenue_access_number : ''}</td>
								<td height={"40px"}>{''}</td>
								<td height={"40px"}>{''}</td>
								<td height={"40px"}>{''}</td>
							</tr>
						</>
					}
					{
						viewMemberValue?.member_role_mapping?.role_name === "Intermediary" &&
						<>
							<tr>
								<th colSpan="2">Proof of Bank Statement</th>
								<th colSpan="1">Proof of Agreement Statement</th>
								<th colSpan="1">Person's Name</th>
								<th colSpan="1">Buy Rate</th>
							</tr>
							<tr>
								<td colSpan="2">{viewMemberValue.credit_risk.bank_proof && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.bank_proof}><FileOutlined /> {viewMemberValue?.credit_risk?.bank_proof?.split('/').pop()} </a>}</td>
								<td colSpan="1">{viewMemberValue.credit_risk.aggreement_proof && <a href={process.env.React_APP_API_URL + viewMemberValue.credit_risk.aggreement_proof}><FileOutlined /> {viewMemberValue?.credit_risk?.aggreement_proof?.split('/').pop()}</a>}</td>
								<td colSpan="1">{viewMemberValue?.credit_risk?.person_name ? `${viewMemberValue?.credit_risk?.person_name?.full_name}` : ''}</td>
								<td colSpan="1">{viewMemberValue?.credit_risk ? viewMemberValue?.credit_risk?.buy_rate : ''}</td>
							</tr>
						</>
					}
					{
						viewMemberValue?.member_role_mapping?.role_name === "Intermediary" &&
						<>
							<tr>
								<th >Phone</th>
								<th >Email</th>
								{viewMemberValue?.member_role_mapping?.role_name === "Originator" && <th>Formation Year</th>}
								<th colSpan={"4"}>Description</th>
							</tr>
							<tr>
								<td height={"40px"}>{viewMemberValue?.multiple_phone && viewMemberValue?.multiple_phone?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
								<td>{viewMemberValue?.email && viewMemberValue?.email?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
								{viewMemberValue?.member_role_mapping?.role_name === "Originator" && <td height={"40px"}>{viewMemberValue?.credit_risk.formation_year && viewMemberValue?.credit_risk?.formation_year}</td>}
								<td height={"40px"} colspan="4">{viewMemberValue?.description ? viewMemberValue?.description : ""}</td>
							</tr>
						</>
					}
					{
						viewMemberValue?.member_role_mapping?.role_name === "Originator" &&
						<>
							<tr>
								<th>Person's Name</th>
								<th >Website</th>
								<th >Phone</th>
								<th >Email</th>
								<th colspan="2">Relationships</th>
							</tr>
							<tr>
								<td colSpan="1">{viewMemberValue?.credit_risk?.person_name ? `${viewMemberValue?.credit_risk?.person_name?.full_name}` : ''}</td>
								<td height={"40px"}>{viewMemberValue?.website ? viewMemberValue?.website : ''}</td>
								<td height={"40px"}>{viewMemberValue?.multiple_phone && viewMemberValue?.multiple_phone?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
								<td>{viewMemberValue?.email && viewMemberValue?.email?.map((x, index) => {
									return (
										<p key={index}>{x.contact_value ? x.contact_value : ''}</p>
									)
								})}</td>
								<td height={"40px"} colspan="2">{viewMemberValue?.relationsdip ? viewMemberValue.relationship : ''}</td>
							</tr>
						</>
					}
					{viewMemberValue?.member_role_mapping?.role_name === "Originator" &&
						<>
							<tr>
								<th colspan="6">Description</th>

							</tr>
							<tr>
								<td height={"40px"} colspan="6">{viewMemberValue?.description ? viewMemberValue?.description : ""}</td>
							</tr>
						</>
					}
					<tr>
						<th colspan="6">Address Details:</th>
					</tr>
					<tr>
						<th colspan="6" className='address_design'>
							{
								viewMemberValue?.mutiple_addresses && viewMemberValue?.mutiple_addresses.map((item) => {

									return (
										<tr width='100%'>
											{
												Object.entries(item).map((data, index) => {
													var add = data[0] === "countries" ? data[1] ? data[1]?.country_name : data[1] : data[1] ? data[1] : ""
													return (
														!['id', 'country_id', 'entity_id', 'entity_type'].includes(data[0]) &&
														<span>
															<tr>
																<th width="120px" colspan={2}>{data[0].split('_').join(' ').charAt(0).toUpperCase() + data[0].split('_').join(' ').substring(1)}</th>
															</tr>
															<tr>
																<td height="40px" width="120px" colSpan={2}>{add}</td>
															</tr>
														</span>
													)
												})}
											<span><tr><th></th></tr><tr><td></td></tr></span>
											<span><tr><th></th></tr><tr><td></td></tr></span>
										</tr>
									)
								})
							}
						</th>
					</tr>
				</tbody>
			</table >
		</div >
	)
})

export default OriginatorDetailView;