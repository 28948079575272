import React from 'react'
import { Menu, Layout, Dropdown, Button, Divider, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import useStore from '../store'
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import Tour from 'reactour'

const { Header } = Layout;

const HeaderComponent = observer((props) => {
	const { UserStore: { ledger, user, changeLedger }, UserStore } = useStore()

	// handle switch ledger
	const handleChangeLedger = (id) => {
		let formdata = {
			ledger_id: id
		}
		changeLedger(formdata)
	}

	const userMenu = (
		<Menu className="header_user_ledger_menu">
			<Menu.ItemGroup title="Current Ledger">
				{
					(ledger && ledger.map((item, index) => (
						(user.default_ledger_id === item.ledger_id) ? (
							<Menu.Item onClick={() => { handleChangeLedger(item.ledger_id, item.name) }} key={index} value={item.ledger_id}>
								<div className="d-flex">
									<div className="ledger_profile_photo" style={{ backgroundImage: `url(${item.logo_url})` }}></div>
									<div>
										<div>{item.name}</div>
										<div><small>{item.role_name}</small></div>
									</div>
								</div>
							</Menu.Item>
						) : null
					)))
				}
			</Menu.ItemGroup>
			{
				(ledger && ledger.length > 1) ? (
					<Menu.ItemGroup title="Ledger List">
						{
							(ledger && ledger.map((item, index) => (
								(user.default_ledger_id !== item.ledger_id) ? (
									<Menu.Item onClick={() => { handleChangeLedger(item.ledger_id, item.name) }} key={index} value={item.ledger_id}>
										<div className="d-flex">
											<div className="ledger_profile_photo" style={{ backgroundImage: `url(${item.logo_url})` }}></div>
											<div>
												<div>{item.name}</div>
												<div><small>{item.role_name}</small></div>
											</div>
										</div>
									</Menu.Item>
								) : null
							)))
						}
					</Menu.ItemGroup>
				) : null
			}


			<Divider style={{ margin: 0 }} />
			<Menu.Item key="logout" onClick={props.logout}>
				{(localStorage.getItem('previous_token')) ? 'Back To SuperAdmin' : ('Logout')}
			</Menu.Item>
		</Menu>
	)

	const steps = [
		{
			selector: '.first-step',
			content: 'For Help and Guide please click here.',
		},
	];

	return (
		<>
			<Header className="site-layout-background site-header">
				<div className="d-flex align-items-center">
					{(props.collapsed) ? (
						<MenuUnfoldOutlined className="trigger" onClick={() => { props.setCollapsed(false) }} />
					) : (
						<MenuFoldOutlined className="trigger" onClick={() => { props.setCollapsed(true) }} />
					)}
					<div className="ml-20">CREDEBT</div>
				</div>
				<div className="d-flex align-items-center">
					<Link to="/user-manual" className="mr-15 d-flex first-step"><Tooltip title="Credebt Machine - User Manual"><QuestionCircleOutlined style={{ fontSize: '20px', color: '#555' }} /></Tooltip></Link>
					<Dropdown overlay={userMenu}>
						<Button type={"primary"}>
							<UserOutlined />
							{(props.user && props.user.first_name && <span>{props.user.first_name} </span>)}
							{(props.user && props.user.last_name && <span>&nbsp;{props.user.last_name}</span>)}
							<DownOutlined />
						</Button>
					</Dropdown>
				</div>
			</Header>

			<Tour
				steps={steps}
				isOpen={UserStore.highlight_user_guide}
				onRequestClose={() => UserStore.highlight_user_guide = false}
			/>
		</>
	)
})

export default HeaderComponent
