import React, { useState } from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmPeople } from '../../../../config/messages'
import useStore from '../../../../store'
import { Form, Row, Col, Upload, message, Checkbox } from 'antd'
import PhoneElement from '../elements/PhoneElement'
import EmailElement from '../elements/EmailElement'
import AddressElement from '../elements/AddressElement'
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const FormComponent = observer(({ handleSubmit, onChange, form, fileList, updateFileList, setAddAddress, addAddress, setImgchanged }) => {
	const { PeopleStore, OrganisationStore } = useStore()
	const [imgDisabled, setImgDisabled] = useState(false)
	const [fetchCountry, setFetchCountry] = useState(true)

	const onPreview = async file => {
		let src = file.url;
		if (!src) {
			src = await new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};

	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
		},
		fileList,
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				setImgDisabled(true)
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				setImgDisabled(true)
				return true;
			}
			else {
				setImgchanged(true)
				updateFileList([file])
				setImgDisabled(false)
				return false
			}
		}
	};

	// Handle on remove image
	const onRemoveImage = () => {
		setImgchanged(true)
		form.setFields([{ name: "logo_url", errors: [] }])
		updateFileList([]);
		setImgDisabled(false)
	}

	const uploadButton = (
		<div>
			<PlusOutlined />
		</div>
	);

	const onFinish = (data) => {
		handleSubmit(data)
	}

	const disabledDate = (current) => {
		return current && current > moment().endOf('day');
	}

	return (
		<Form
			form={form}
			layout='vertical'
			id="addeditform"
			onFinish={onFinish}
			initialValues={PeopleStore.initialValues}
		>
			<Row gutter={24}>
				<Col span={5}>
					<InputComponent
						placeholder="Select"
						label='Salutation'
						type="select"
						onChange={onChange}
						allowClear
						options={{
							values: [{ value: 'Mr.', text: 'Mr.' }, { value: 'Ms.', text: 'Ms.' }, { value: 'Mrs.', text: 'Mrs.' }]
						}}
						name='salutation'
						tooltip="A gesture or utterance made as a greeting or acknowledgement"
						autoComplete="off"
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required
						label='First Name'
						placeholder="Enter your First Name"
						name='first_name'
						onChange={onChange}
						tooltip="A personal Name that is given to user/ person at birth or baptism and used before a family name."
						autoComplete="off"
						rules={vsmPeople.validation.first_name}
						maxLength={100}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						label='Last Name'
						placeholder="Enter your Last Name"
						name='last_name'
						onChange={onChange}
						tooltip="It is the Name of the family to which a person/ user is born."
						autoComplete="off"
						rules={vsmPeople.validation.last_name}
						maxLength={100}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						label='Title'
						placeholder="Title"
						name='title'
						onChange={onChange}
						tooltip="A title can describe the responsibilities of the position."
						autoComplete="off"
						rules={vsmPeople.validation.title}
						maxLength={100}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						type="date"
						name="date_of_birth"
						onChange={onChange}
						disabledDate={disabledDate}
						label='Date of Birth'
						placeholder="Select Date of Birth"
						rules={vsmPeople.validation.date_of_birth}
						tooltip="The month, day, and year a person was born."
					/>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={9}>
					<Form.Item label="Phone" className="mb-10">
						<PhoneElement onChange={onChange} form={form} peopleValues={PeopleStore.peopleValues && PeopleStore.peopleValues} />
					</Form.Item>
				</Col>
				<Col span={9}>
					<Form.Item label="Email" className="mb-10">
						<EmailElement onChange={onChange} form={form} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<div className='mt-25'>
						<InputComponent
							type='checkbox'
							name='allow_external_app'
							label="Allow External App"
							valuePropName='checked'
							onChange={onChange}
							initialValue={false}
							disabled={PeopleStore.peopleValues && PeopleStore.peopleValues.is_investor_user_count > 0 ? true : false}
						/>
					</div>
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={5}>
					<InputComponent
						label='Former Last Name'
						placeholder="Enter Former Last Name"
						name='former_last_name'
						onChange={onChange}
						tooltip="It is the name of the family one may have used in the past for any reason."
						autoComplete="off"
						rules={vsmPeople.validation.former_last_name}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						label='Notes'
						placeholder="Enter Notes"
						name='notes'
						onChange={onChange}
						tooltip="The notes are the observations presented to simply describe the field/s."
						autoComplete="off"
						rules={vsmPeople.validation.notes}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						type="select"
						label='PPSN Document'
						placeholder="Select PPSN Document"
						name='ppsn_document_type'
						onChange={onChange}
						allowClear
						options={{
							values: [{ value: 1, text: 'Legal Correspondence' }, { value: 2, text: 'P21 (Tax Balancing Statement)' }, { value: 3, text: 'Tax Assessment' }, { value: 4, text: 'Notice of Tax Credits' }, { value: 5, text: 'Payslip' }, { value: 6, text: 'P60' }, { value: 7, text: 'P45' },]
						}}
						tooltip="PPSN Document is the type of the document."
						autoComplete="off"
						rules={vsmPeople.validation.ppsn_document_type}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						label='PPS Number'
						placeholder="Enter your PPS Number"
						name='pps_number'
						onChange={onChange}
						tooltip="A Personal Public Service (PPS) Number is a unique reference number that helps you access social welfare benefits, public services and information in Ireland. A PPS Number is always 7 numbers followed by either one or two letters. You can use your PPS Number to access public services in Ireland."
						autoComplete="off"
						rules={vsmPeople.validation.pps_number}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						label='Pronounced'
						placeholder="Enter Pronounced"
						name='pronounced'
						onChange={onChange}
						tooltip="It indicates how the name of the person should be pronounced."
						autoComplete="off"
						rules={vsmPeople.validation.pronounced}
					/>
				</Col>
			</Row>

			<Form.Item name="photo_url" label="Photo" extra={[<span key="1" style={{ fontSize: "12px" }}>Only JPG or PNG is allowed, Maximum size : 500px x 500px</span>]}
				tooltip='It is image of the person.'>
				<Upload
					accept=".png, .jpeg"
					fileList={fileList}
					onRemove={onRemoveImage}
					onPreview={onPreview}
					className="hideeyeicon"
					listType="picture-card"
					multiple={false}
					showUploadList={true}
					{...eventProps}
					disabled={imgDisabled}
					onChange={onChange}
					maxCount={1}
				>
					{fileList && fileList.length >= 1 ? null : uploadButton}
				</Upload>
			</Form.Item>

			<Form.Item className="mb-0">
				<Form.Item name="add_address" valuePropName="checked">
					<Checkbox onClick={(e) => {
						var temp = localStorage.getItem('local_org_country')
						onChange()
						setAddAddress(e.target.checked)
						form.setFieldsValue({ address: e.target.checked ? [temp ? { country_id: parseInt(temp) } : null] : null })
						if (fetchCountry) {
							OrganisationStore.getAllCountries().then(() => setFetchCountry(false))
						}
					}}>Add Address</Checkbox>
				</Form.Item>
			</Form.Item>

			{addAddress && <AddressElement onChange={onChange} form={form} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} />}

		</Form>
	)
})

export default FormComponent
