import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Col, Form, Row, Spin } from 'antd';
import InputComponent from '../../../components/InputComponent';
import { vsmDashboard } from '../../../config/messages';
import useStore from '../../../store';

const FormComponent = observer(({ defaultFormDataList, form, formDataList, setFormDataList, initialValues, handleSubmit, setDisabled, setSelectMultiple, selectMultiple, singleSelect, setFetchWidget, fetchWidget, selectSingle, setSelectSingle, fetchReport, setFetchReport }) => {
    const multiSelectArr = [{ key: 7, value: '7 days' }, { key: 30, value: '30 days' }, { key: 365, value: '12 months' }];
    const CheckboxGroup = Checkbox.Group;
    const { DashboardStore: { getWidgetFunction, getReports, widgetList, readData, reportList } } = useStore()
    const [plainOptions, setPlainOptions] = useState();
    const [checkedList, setCheckedList] = useState();
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const getFormDataList = useCallback(() => {
        var FormDataList = defaultFormDataList
        switch (form.getFieldValue('widget_type')) {
            case 'number':
                FormDataList = [...FormDataList, 'extra_option', 'widget', 'title']
                break;
            case 'graphical':
                FormDataList = [...FormDataList, 'title', 'widget', 'extra_option', 'type']
                // eslint-disable-next-line default-case
                switch (form.getFieldValue('type')) {
                    case 'line':
                    case 'bar':
                        FormDataList = [...FormDataList, 'title', 'widget', 'extra_option', 'type', 'x_value', 'y_value']
                        break
                    case 'pie':
                        FormDataList = [...FormDataList, 'title', 'widget', 'extra_option', 'type']
                }
                break;
            case 'reports':
                FormDataList = [...FormDataList, 'title', 'extra_option', 'report_id', 'range']
                break;
            default:
        }

        switch (form.getFieldValue('extra_option')) {
            case 'single':
                FormDataList = [...FormDataList, 'single']
                break;

            case 'multiple':
                FormDataList = [...FormDataList, 'multiple']
                break;

            default:
        }

        switch (form.getFieldValue('x_value')) {
            case 'days':
                form.setFieldsValue({ y_value: 'numbers' })
                break;
            case 'numbers':
                form.setFieldsValue({ y_value: 'days' })
                break;
            default:
        }
        return FormDataList
    }, [defaultFormDataList, form])

    const onFinish = (data) => {
        if (data.widget_type === "reports") {
            data.extra_option = indeterminate
            data.report_fields = checkedList
        }
        handleSubmit(data)
    }

    const handleChange = async () => {
        await setFormDataList(getFormDataList())
        form.validateFields().then(() => {
            setDisabled(false)
        }).catch((e) => {
            setDisabled(true)
        })
    }
    const onDaysChange = (e) => {
        let newSelectedList = selectMultiple;
        if (newSelectedList.includes(e.target.value)) {
            if (newSelectedList.length > 1) {
                let index = newSelectedList.indexOf(e.target.value)
                if (index !== null) {
                    newSelectedList.splice(index, 1)
                    setSelectMultiple([...newSelectedList])
                }
            }
            let currentSelected = form.getFieldValue("default_selected");
            let keySelected = multiSelectArr.filter(x => x.value === currentSelected);
            if (!keySelected.includes(newSelectedList) && currentSelected === e.target.value) {
                let sortedArr = selectMultiple.sort(function (a, b) { return b.key - a.key });
                let newSelectedValue = sortedArr[0];
                form.setFieldsValue({
                    default_selected: newSelectedValue,
                })
            }
        } else {
            setSelectMultiple([...newSelectedList, e.target.value])
        }

        handleChange()
    }

    const onSingleDaysChange = (e) => {
        if (selectSingle.includes(e.target.value)) {
            if (selectSingle.length > 1) {
                let index = selectSingle.indexOf(e.target.value)
                if (index !== null) {
                    selectMultiple.splice(index, 1)
                    setSelectSingle([...selectSingle])
                }
            }
        }
        handleChange()
    }

    const showFields = (e) => {
        const [getFields] = reportList?.filter(item => item.id === e && item)
        setPlainOptions(getFields.report_fields)
        setCheckedList(getFields.report_fields)
    }

    const onChangeCheckbox = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    }

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    const changeWidgetType = () => {
        form.resetFields(['type', 'report_id'])
        setPlainOptions()
        setCheckedList()
        form.setFieldsValue(initialValues)
        handleChange();
    }

    useEffect(() => {
        setSelectSingle([7])
    }, [setSelectSingle])

    useEffect(() => {
        var data = getFormDataList()
        if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
            setFormDataList(data)
        }
    }, [getFormDataList, setFormDataList, formDataList])

    useEffect(() => {
        if (readData) {
            setPlainOptions(readData.settings_json.i?.report_avl_fields)
            setCheckedList(readData.settings_json.i?.report_fields)
        }
    }, [readData])

    return (
        <Form
            form={form}
            layout='vertical'
            onFinish={onFinish}
            id='addEditForm'
            initialValues={initialValues}
        >
            {formDataList.includes('widget_type') && (
                <InputComponent
                    required
                    label="Widget Type"
                    type="select"
                    name="widget_type"
                    placeholder="Select Available Widget"
                    onChange={changeWidgetType}
                    options={{
                        values: [{ value: 'number', text: 'Number' }, { value: 'graphical', text: 'Graphical' }, { value: 'reports', text: 'Reports' }]
                    }}
                    tooltip="Choose an element of a graphical user interface that displays information or provides a specific way for a user to interact with the application"
                    rules={vsmDashboard.validation.widget_type}
                />
            )}

            {formDataList.includes('widget') && (
                <InputComponent
                    required
                    label="Widget"
                    type="select"
                    name="widget_id"
                    placeholder="Select Available Widget"
                    onChange={handleChange}
                    options={{
                        values: widgetList || [{
                            id: readData && readData.widget && readData.widget.id,
                            widget_title: readData && readData.widget && readData.widget.widget_title,
                        }],
                        value_key: 'id',
                        text_key: 'widget_title'
                    }}
                    tooltip="Choose the available widget from the predefined list."
                    rules={vsmDashboard.validation.widget_type}
                    onFocus={() => fetchWidget && getWidgetFunction().then(() => setFetchWidget(false))}
                    notFoundContent={fetchWidget ? <Spin size="small" /> : "No Record Found."}
                />
            )}

            {formDataList.includes('title') && (
                <InputComponent
                    required
                    label="Title"
                    name="title"
                    placeholder="Enter Title"
                    onChange={handleChange}
                    tooltip="The name given to something to identify or describe it"
                    rules={vsmDashboard.validation.title}
                    maxLength={100}
                    autoComplete="off"
                />
            )}

            {formDataList.includes('type') && (
                <InputComponent
                    label="Type"
                    type="select"
                    allowClear
                    name="type"
                    placeholder="Select type"
                    onChange={handleChange}
                    tooltip="Things having common traits or characteristics that distinguish them as a group or class"
                    options={{
                        values: [{ value: 'line', text: 'Line' }, { value: 'bar', text: 'Bar' }, (form.getFieldValue('widget_id') === 5 || form.getFieldValue('widget_id') === 6) && { value: 'pie', text: 'Pie' }]
                    }}
                    rules={vsmDashboard.validation.type}
                />
            )}

            <Row gutter={24}>
                <Col span={12}>
                    {formDataList.includes('x_value') && (
                        <InputComponent
                            required
                            type="radio_button"
                            label="X Value"
                            name="x_value"
                            onChange={handleChange}
                            options={{
                                values: [{ value: 'days', text: 'Days' }, { value: 'numbers', text: 'Numbers' }],
                            }}
                            tooltip="Integers that refer to the X coordinate of the widget"
                        />
                    )}
                </Col>
                <Col span={12}>
                    {formDataList.includes('y_value') && (
                        <InputComponent
                            required
                            type="radio_button"
                            label="Y Value"
                            name="y_value"
                            onChange={handleChange}
                            options={{
                                values: [{ value: 'days', text: 'Days' }, { value: 'numbers', text: 'Numbers' }],
                            }}
                            tooltip="Integers that refer to the Y coordinate of the widget"
                            disabled={true}
                        />
                    )}
                </Col>
            </Row>

            {(formDataList.includes('report_id')) && (
                <InputComponent
                    label="Select Saved Reports"
                    type="select"
                    allowClear
                    name="report_id"
                    placeholder="Select Saved Reports"
                    onChange={(e) => { handleChange(); showFields(e) }}
                    tooltip="A report is a document that presents information in an organised format for a specific audience and purpose."
                    options={{
                        values: reportList || [{
                            id: readData && readData.report && readData.report.id,
                            name: readData && readData.report && readData.report.name,
                        }],
                        value_key: "id",
                        text_key: "name",
                    }}
                    rules={vsmDashboard.validation.saved_reports}
                    onFocus={() => fetchReport && getReports().then(() => setFetchReport(false))}
                    notFoundContent={fetchReport ? <Spin size="small" /> : "No Record Found."}
                />
            )}

            <Row gutter={24}>
                {!formDataList.includes('report_id') ? (
                    formDataList.includes('extra_option') && (
                        <Col>
                            <InputComponent
                                required
                                type="radio_button"
                                label="Extra Options"
                                name="extra_option"
                                onChange={() => {
                                    let currentSelected = multiSelectArr[0].key
                                    setSelectMultiple([currentSelected])
                                    form.setFieldsValue({
                                        default_selected: multiSelectArr[0].key,
                                    })
                                    handleChange();
                                }}
                                options={{
                                    values: [{ value: 'single', text: 'Single' }, { value: 'multiple', text: 'Multiple' }],
                                }}
                                tooltip="Something that may be chosen among alternatives."
                            />
                        </Col>
                    )
                ) : null}

                <Col>
                    {formDataList.includes('report_id') && formDataList.includes('extra_option') ?
                        (
                            plainOptions && (
                                <>
                                    <Form.Item
                                        valuePropName="checked"
                                        name='extra_option'
                                        label='Extra Option'
                                        tooltip='Something that may be chosen among alternatives.'
                                    >
                                        <Checkbox indeterminate={indeterminate} onChange={(e) => { onCheckAllChange(e); handleChange() }} checked={checkAll}>Show all columns to add in Reports</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="report_fields"
                                        label='Fields'
                                        valuePropName="checked"
                                        tooltip='Something that may be chosen among alternatives.'
                                    >
                                        <CheckboxGroup className='mt-5' options={plainOptions} value={checkedList} onChange={(e) => { onChangeCheckbox(e); handleChange() }} />
                                    </Form.Item>
                                </>
                            )
                        ) : (
                            <>
                                {formDataList.includes('single') && formDataList.includes('extra_option') && (
                                    <>
                                        <Form.Item
                                            name="single_select"
                                            valuePropName="checked"
                                        >
                                            {singleSelect.map((item, index) => (
                                                <Checkbox key={index} value={item.key} className='mt-30' checked={selectSingle.includes(item.key)} onChange={(e) => { onSingleDaysChange(e) }}>{item.value}</Checkbox>
                                            ))}
                                        </Form.Item>
                                    </>
                                )}

                                {formDataList.includes('multiple') && formDataList.includes('extra_option') && (
                                    <>
                                        <Form.Item
                                            name="multi_select"
                                            valuePropName="checked"
                                        >
                                            {multiSelectArr.map((item, index) => (
                                                <Checkbox key={index} value={item.key} className='mt-30' checked={selectMultiple.includes(item.key)} onChange={(e) => { onDaysChange(e) }}>{item.value}</Checkbox>
                                            ))}
                                        </Form.Item>
                                    </>
                                )}
                            </>
                        )}
                </Col>

                {!formDataList.includes('report_id') && formDataList.includes('multiple') && formDataList.includes('extra_option') && (
                    <Col>
                        <InputComponent
                            required
                            type="radio_button"
                            label="Default Selected"
                            name="default_selected"
                            tooltip="Select default values to be displayed."
                            onChange={handleChange}
                            options={{
                                values: selectMultiple.map(x => {
                                    let obj = { value: '', key: x }
                                    obj.value = (x === 7 ? '7 days' : x === 30 ? '30 days' : '12 months');
                                    return obj;
                                }).sort(function (a, b) { return a.key - b.key }),
                                value_key: "key",
                                text_key: "value",
                            }}
                        />
                    </Col>
                )}
            </Row>

            {(formDataList.includes('range')) && plainOptions && (
                <InputComponent
                    required
                    type="radio_button"
                    label="Choose Range"
                    name="range"
                    onChange={handleChange}
                    options={{
                        values: [{ value: '10', text: 'Last 10 records' }, { value: '25', text: 'Last 25 records' }, { value: '50', text: 'Last 50 records' }],
                    }}
                    tooltip="A set of different things of the same general type."
                />
            )}
        </Form>
    );
})

export default FormComponent;