import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { EditOutlined, FileTextOutlined, HistoryOutlined, UpSquareOutlined, DownSquareOutlined, SettingOutlined } from '@ant-design/icons'
import LocalGridConfig from '../../../../config/LocalGridConfig'
import useStore from '../../../../store'
import { vsmCommon } from '../../../../config/messages'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../utils/GlobalFunction'
import SwitchComponent from './SwitchComponent'

const ListComponent = observer((props) => {
	const { openEditModal, openDeleteModal, openResetPass, openModifyPermission, bulkAction, onSwitchChange, openRestoreUser, openViewLedger, openAPISettingDrawer } = props
	const { UserManagementStore: { list_data, getList, setupGrid, onFilterChanged, onGridChanged }, UserStore } = useStore()

	const ActionRenderer = (props) => {
		const { openEditModal, openDeleteModal, openRestoreUser, openViewLedger, openAPISettingDrawer } = props.agGridReact.props.frameworkComponents
		const { UserStore } = useStore()
		const Store = {
			User: UserStore
		}
		return (
			<div className="action-column">

				{/* For Edit */}
				{UserStore.checkPermission(64, 'edit') && (
					(Store.User.user.user_role && Store.User.user.user_role.user_role_id === 1) ||
					(
						(
							(UserStore.user.id === props.data.created_by) ||
							(Store.User.user.user_role && Store.User.user.user_role.user_role_id === 2)
						) &&
						(props.data.user_role && props.data.user_role.user_role_id !== 1) &&
						(!Store.User.superUser.includes(props.data.id))
					)
				) ? (
					<Tooltip placement="topRight" title={"Edit User"} >
						<Button disabled={props.data.deleted_at !== null} type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
					</Tooltip>
				) : (null)}

				{/* For Archive and Restore */}

				{(props.data.deleted_at !== null) ? (
					(
						(props.data.id === 1) ||
						(Store.User.superUser && Store.User.superUser.includes(props.data.id)) ||
						(
							(Store.User.user.user_role && ![1, 2].includes(Store.User.user.user_role.user_role_id)) &&
							(UserStore.user.id !== props.data.created_by)
						) ||
						(
							(Store.User.user.user_role.user_role_id && [1, 2].includes(Store.User.user.user_role.user_role_id) && Store.User.user.id !== 1) &&
							(props.data.user_role && [1].includes(props.data.user_role.user_role_id))
						)
					) ? null : (
						(
							UserStore.checkPermission(64, 'user_restore') && <Tooltip placement="topRight" title={"Restore User"} >
								<Button type="text" onClick={() => { openRestoreUser(props.data) }}><DownSquareOutlined /></Button>
							</Tooltip>
						)
					)
				) : (
					(
						(props.data.id === 1) ||
						(Store.User.superUser && Store.User.superUser.includes(props.data.id)) ||
						(
							(Store.User.user.user_role && ![1, 2].includes(Store.User.user.user_role.user_role_id)) &&
							(UserStore.user.id !== props.data.created_by)
						) ||
						(
							(Store.User.user.user_role.user_role_id && [1, 2].includes(Store.User.user.user_role.user_role_id) && Store.User.user.id !== 1)
							&& (props.data.user_role && [1].includes(props.data.user_role.user_role_id))
						)
					) ? null : (
						(
							UserStore.checkPermission(64, 'archive') && <Tooltip placement="topRight" title={"Archive User"} >
								<Button disabled={props.data.id === Store.User.user.id} type="text" onClick={() => { openDeleteModal(props.data) }}><UpSquareOutlined /></Button>
							</Tooltip>
						)
					)
				)}

				{/* For Logs */}
				{UserStore.checkPermission(64, 'view_logs') && (
					<Tooltip placement="topRight" title={"View Logs"} >
						<Button disabled={props.data.deleted_at !== null} type="text" ><HistoryOutlined /></Button>
					</Tooltip>
				)}

				{/* For View Ledger */}
				{UserStore.checkPermission(64, 'view_ledger') && (
					<Tooltip placement="topRight" title={"View Ledgers"} >
						<Button disabled={props.data.deleted_at !== null} type="text" onClick={() => openViewLedger(props.data)}><FileTextOutlined /></Button>
					</Tooltip>
				)}

				{
					props.data.api_access && <Tooltip placement="topRight" title={"API Settings"}>
						<Button onClick={() => openAPISettingDrawer(props.data)}><SettingOutlined /></Button>
					</Tooltip>
				}

			</div>
		)
	}

	useEffect(() => {
		getList()
	}, [getList])

	const gridOptions = {
		columnDefs: [{
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			checkboxSelection: (params) => (
				UserStore.superUser && !UserStore.superUser.includes(params.data.id) && ((UserStore.user.id === 1 && (params.data.id !== 1)) ||
					(UserStore.user.id !== 1 && (params.data.user_role.user_role_id !== 1))) ? true : false
			),
			filter: false,
			sortable: false,
			floatingFilter: false,
			width: 55,
			suppressMenu: true,
			pinned: 'left',
		}, {
			headerName: '#Id',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'First Name',
			field: 'first_name',
			width: 110
		}, {
			headerName: 'Last Name',
			field: 'last_name',
			width: 110
		}, {
			headerName: 'Email',
			field: 'email',
			width: 150
		}, {
			headerName: 'Role',
			field: 'user_role.role_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 110
		}, {
			headerName: 'User Group',
			field: 'user_groups.group_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			valueGetter: (params) => (
				params.data && params.data.user_groups && params.data.user_groups.map(x => x.group_name).join(', ')
			),
			width: 110
		}, {
			headerName: 'Last Login On',
			field: 'last_login_at',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				comparator: DateComparator
			},
			width: 185
		}, {
			headerName: 'User Add On',
			field: 'created_at',
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				comparator: DateComparator
			},
			width: 185
		}, {
			headerName: 'Status',
			cellRendererFramework: function (data) {
				const { onSwitchChange } = data.agGridReact.props
				return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
			},
			field: 'status',
			sortable: false,
			filter: 'agSetColumnFilter',
			filterParams: {
				values: ["Activated", "Deactivated"],
				defaultToNothingSelected: true
			},
			width: 90
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 180
		}]
	}

	var column = gridOptions.columnDefs

	if (!UserStore.checkPermission(64, 'status')) {
		column = gridOptions.columnDefs.filter(x => x.headerName !== 'Status')
	}

	if (!UserStore.checkPermission(64, 'bulk_action') || (
		!UserStore.checkPermission(64, 'user_restore') &&
		!UserStore.checkPermission(64, 'archive') &&
		!UserStore.checkPermission(64, 'status')
	)) {
		column = gridOptions.columnDefs.filter(x => x.headerCheckboxSelection !== true)
	}

	let columns = []
	let columnConfig = localStorage.getItem('users_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = column.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = column.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = column
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={list_data}
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditModal, openDeleteModal, openResetPass, openModifyPermission, bulkAction, openRestoreUser, openViewLedger, openAPISettingDrawer
				}}
				onGridReady={setupGrid}
				onColumnResized={onGridChanged}
				onColumnMoved={onGridChanged}
				onColumnPinned={onGridChanged}
				onSwitchChange={onSwitchChange}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={onFilterChanged}
				onSortChanged={onGridChanged}
				suppressRowClickSelection={true}
				rowSelection={'multiple'}
				isRowSelectable={function (rowNode) {
					return (
						UserStore.superUser && !UserStore.superUser.includes(rowNode.data.id) && ((UserStore.user.id === 1 && (rowNode.data && rowNode.data.id !== 1)) ||
							(UserStore.user.id !== 1 && (rowNode.data && rowNode.data.user_role && rowNode.data.user_role.user_role_id !== 1)) ? true : false
						)
					)
				}}
			/>
		</div>
	)

})

export default ListComponent
