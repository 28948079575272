import React, { useState, useEffect, useMemo } from 'react'
import { Form, Button, Input, Select, Radio, Checkbox, Row, Col, Card, Spin, Upload, message, Drawer } from 'antd'
import { PlusOutlined, PercentageOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { vsmNotify, vsmOrganisations } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'
import PhoneElement from '../elements/PhoneElement';
import AddressElement from '../elements/AddressElement';
import debounce from 'lodash/debounce'
import EmailElement from '../elements/EmailElement';

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrgStatusStore, OrganisationStore, OrgBankAccountStore, LedgerStore, OrganisationStore: { JsonToFormData, getAllCountries, EditData, organisationValues, month_list, dropdown_countries_list, initialValues, naceCode_list } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [isNonEditable, setNonEditable] = useState(true)
	const [isNewFields, setNewFields] = useState(false)
	const [currentYearDisable, setCurrentYearDisable] = useState(false)
	const [checkDisable, setCheckDisable] = useState(false)
	const [checkBoxHide, setCheckBoxHide] = useState(false)
	const [isChecked, setChecked] = useState(false)
	const [fetchCountry, setFetchCountry] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [fetctNace, setFetchNace] = useState(true)
	const [fetchIndustries, setFetchIndustries] = useState(true)
	const [fetchCurrency, setFetchCurrency] = useState(true)
	const [fetchMappedOrg, setFetchMappedOrg] = useState(false)
	const [imgchanged, setImgchanged] = useState(false)
	const [fileList, updateFileList] = useState([]);
	const [loading, setLoading] = useState(true)
	const [searchOrgKey, setSearchOrgKey] = useState()
	const currentDate = new Date()
	const currentMonth = currentDate.getMonth()
	const [naceCode, setNaceCode] = useState(null)

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		data.allow_external_app = data.allow_external_app ? 1 : 0
		if (data.phone && data.phone.length > 0) {
			data.phone = data.phone.filter(x => x && (x.contact_for && x.contact_value))
			data.phone = data.phone.length > 0 ? JSON.stringify(data.phone) : "[]"
		}
		if (data.email && data.email.length > 0) {
			data.email = data.email.filter(x => x && (x.contact_for && x.contact_value))
			data.email = data.email.length > 0 ? JSON.stringify(data.email) : null
		}
		if (data.addresses && data.addresses.length > 0) {
			data.addresses = data.addresses.filter(x => x && x)
			data.addresses = data.add_address && data.addresses.length > 0 ? JSON.stringify(data.addresses) : "[]"
		}
		setSaving(true)
		data.id = organisationValues.id
		let month_name = month_list.find(item => item.id === data.fy_start_month)
		data.fy_start_month = month_name?.month
		data.country = data.country ? data.country : null
		var formData = JsonToFormData(data)
		if (fileList.length > 0 && fileList[0] && imgchanged) {
			formData.append('logo_url', fileList[0].hasOwnProperty("originFileObj") ? fileList[0].originFileObj : fileList[0])
		} else if (fileList.length === 0) {
			formData.append('logo_status', 'deleted')
		}
		EditData(formData, organisationValues.id).then(() => {
			close()
			vsmNotify.success({
				message: vsmOrganisations.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible && props.transactionId) {
			setLoading(true)
			OrganisationStore.getRecordDetail({ organisation_id: props.transactionId }).then((data) => {
				LedgerStore.global_organisations_list = data.parent && [data.parent]
				OrgStatusStore.orgStatusList = data.status && [data.status]
				OrganisationStore.naceCode_list = data.global_nace_sections && [data.global_nace_sections]
				OrganisationStore.industries_list = data.global_industries && [data.global_industries]
				OrgBankAccountStore.dropdown_currency_list = data.global_currency && [data.global_currency]
				setChecked(data.mutiple_addresses && data.mutiple_addresses.length > 0 ? true : false)
				setLoading(false)
			}).catch((data) => {
				setLoading(false)
			})
		}
	}, [props, LedgerStore, OrgStatusStore, OrganisationStore, OrgBankAccountStore])


	// set the form values to edit
	useEffect(() => {
		if (organisationValues && props.visible) {
			let month_name = month_list.find(item => (
				item.month && organisationValues.fy_start_month && item.month.toLowerCase() === organisationValues.fy_start_month.toLowerCase()
			))
			updateFileList(organisationValues.logo_url ? ([
				{
					uid: '-1',
					name: 'image.png',
					status: 'done',
					url: process.env.React_APP_API_URL + organisationValues.logo_url,
				},
			]) : [])
			form.setFieldsValue({
				parent_organisation_id: organisationValues.parent_organisation_id,
				organisation_name: organisationValues.organisation_name,
				trade_name: organisationValues.trade_name,
				registered_country_id: organisationValues.registered_country_id,
				registration_number: organisationValues.registration_number,
				vat_number: organisationValues.vat_number,
				fy_start_month: month_name ? month_name.id : null,
				apply_year_on: organisationValues.apply_year_on,
				transfer_in: organisationValues.transfer_in,
				website: organisationValues.website,
				phone: organisationValues.mutiple_phones && organisationValues.mutiple_phones.length > 0 ? organisationValues.mutiple_phones : OrganisationStore.initialValues.phone,
				email: organisationValues.email && organisationValues.email.length > 0 ? organisationValues.email : OrganisationStore.initialValues.email,
				add_address: (organisationValues.mutiple_addresses && organisationValues.mutiple_addresses.length > 0 ? true : false),
				addresses: organisationValues.mutiple_addresses && organisationValues.mutiple_addresses.length > 0 ? organisationValues.mutiple_addresses : null,
				status_id: organisationValues.status_id,
				currency_id: organisationValues.currency_id,
				description: organisationValues.description,
				industry_id: organisationValues.industry_id,
				nace_section: organisationValues.global_nace_sections && organisationValues.global_nace_sections.get_nace_category && organisationValues.global_nace_sections.get_nace_category.id ? organisationValues.global_nace_sections.get_nace_category.id : null,
				nace_section_id: organisationValues.global_nace_sections && organisationValues.global_nace_sections.id ? organisationValues.global_nace_sections.id : null,
				revenue_access_number: organisationValues.revenue_access_number,
				allow_external_app: organisationValues.allow_external_app,
				c_etr_sell_rate: organisationValues.c_etr_sell_rate,
				d_etr_purchase_discount: organisationValues.d_etr_purchase_discount,
				purchase_payment: organisationValues?.settings_json?.purchase_payment,
				default_sell_rate_d_etr: organisationValues?.settings_json?.default_sell_rate_d_etr,
				default_sell_rate_b_c_etr: organisationValues?.settings_json?.default_sell_rate_b_c_etr,
				face_value_charge: organisationValues?.settings_json?.face_value_charge,
				creditor_face_value_charge: organisationValues?.settings_json?.creditor_face_value_charge,
				ldc_primium: organisationValues?.settings_json?.ldc_primium,
				originator_application_fee: organisationValues?.settings_json?.originator_application_fee,
				agent_commission: organisationValues?.settings_json?.agent_commission,
				arrangement_fee: organisationValues?.settings_json?.arrangement_fee,
				processing_commission: organisationValues?.settings_json?.processing_commission,
				trade_commission: organisationValues?.settings_json?.trade_commission,
				trade_limit_d_etr: organisationValues?.settings_json?.trade_limit_d_etr,
				trade_limit_c_etr: organisationValues?.settings_json?.trade_limit_c_etr,
				etr_months: organisationValues?.settings_json?.etr_months,
				payment_discount: organisationValues?.settings_json?.payment_discount,
				requested_credit_limit: organisationValues?.settings_json?.requested_credit_limit
			})
		}
	}, [organisationValues, form, month_list, props.visible, OrganisationStore.initialValues.phone, OrganisationStore.initialValues.email])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		setCurrentYearDisable(form.getFieldsValue().fy_start_month < currentMonth)
		if (form.getFieldsValue().apply_year_on === "current") {
			if (form.getFieldsValue().fy_start_month === currentMonth) {
				setCheckDisable(false)
			} else {
				setCheckDisable(true)
			}
			setCheckBoxHide(false)
		} else {
			setCheckBoxHide(true)
		}
		form.validateFields().then((d) => {
			let checkbox = form.getFieldValue("add_address")
			let address = form.getFieldValue("addresses")
			address = address && address.filter(x => x && x)
			if (!isNonEditable) {
				setNewFields(true)
				if (form.getFieldsValue().confirm && form.getFieldsValue().confirm.toLowerCase() === "confirm") {
					if (checkbox && (!address || (address && address.length === 0))) {
						setDisabled(true)
					} else {
						setDisabled(false)
					}
				} else {
					setDisabled(true)
				}
			} else {
				if (checkbox && (!address || (address && address.length === 0))) {
					setDisabled(true)
				} else {
					setDisabled(false)
				}
			}
		}).catch(d => {
			if (d.errorFields && d.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close edit form
	const close = () => {
		props.setId(null)
		form.resetFields()
		setDisabled(true)
		setNonEditable(true)
		setNewFields(false)
		setCheckDisable(false)
		setCurrentYearDisable(false)
		setFetchStatus(true)
		setFetchIndustries(true)
		setFetchNace(true)
		setFetchCurrency(true)
		setFetchMappedOrg(false)
		setImgchanged(false)
		setSearchOrgKey(null)
		OrganisationStore.naceCode_list = null
		setNaceCode(null)
		props.close()
	}

	// enable FY Start Month for editing
	const editAnyway = () => {
		setNonEditable(false)
	}

	const changeNACE = (e) => {
		const naceCode = OrganisationStore.naceCode_list.find(list => list.id === e)
		setNaceCode(naceCode.nace_section)
		form.resetFields(['nace_section_id'])
	}

	// check whether address checkbox is checked or not
	const addAddress = async (e) => {
		var temp = localStorage.getItem('local_org_country')
		if (!e.target.checked) {
			form.resetFields(["postal_code", "address_1", "address_2", "address_3", "city", "state_county"])
		}
		form.setFieldsValue({ addresses: e.target.checked ? [temp ? { country_id: parseInt(temp) } : null] : null })
		if (fetchCountry) {
			OrganisationStore.getAllCountries().then(() => setFetchCountry(false))
		}
		await setChecked(e.target.checked)
		handleChange()
	}

	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
		},
		fileList,
		beforeUpload: file => {
			let isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			let isLt1M = file.size / 1024 / 1024 < 1;

			if (!isJpgOrPng) {
				message.error(`Upload valid image. Only JPG, JPEG or PNG are allowed.`);
				return true;
			} else if (!isLt1M) {
				message.error('File size should not be more than 500px x 500px and 1 MB');
				return true;
			}
			else {
				setImgchanged(true)
				updateFileList([file])
				return false
			}
		}
	};

	// Handle on remove image
	const onRemoveImage = () => {
		setImgchanged(false)
		form.resetFields(["logo_url"])
		updateFileList([]);
		handleChange();
	}

	// search string for organisation dropdown
	const handleOrgSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchOrgKey(val);
			if (val && val.length >= 1) {
				setFetchMappedOrg(true)
				LedgerStore.getAllGlobalOrganisationList({ search_for: val }).finally(() => setFetchMappedOrg(false))
			}
		};

		return debounce(loadOptions, 500);
	}, [LedgerStore])

	const onCountryChange = (e) => {
		localStorage.setItem('local_org_country', JSON.stringify(e))
	}

	const percentagesNode = (e, name) => {
		let value = Number(e.target.value)
		if (value < 10) {
			form.setFieldsValue({ [name]: value.toFixed(3) })
		} else if (value >= 10 && value <= 99) {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		} else {
			form.setFieldsValue({ [name]: value.toFixed(1) })
		}
	}

	return (
		<Drawer
			title={`Edit Organisation - #${organisationValues && organisationValues.id} - ${organisationValues && organisationValues.organisation_name}`}
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button key="2" className="ml-10" htmlType="button" onClick={close}>Cancel</Button>
				</div >
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form}
					id="editform"
					onFinish={handleSubmit}
					onChange={handleChange}
					layout='vertical'
					initialValues={initialValues}
				>

					<Row gutter={24}>
						<Col span={8}>
							<InputComponent
								type="select" allowClear label="Parent Organisation" name="parent_organisation_id" placeholder="Parent Organisation" onChange={handleChange}
								tooltip="Parent Organisation Role is an Organisation Role that has primary role in a particular relation between two Organisations in the context of a single Transaction."
								notFoundContent={fetchMappedOrg ? <Spin size="small" /> : (searchOrgKey) ? "No Record Found." : null}
								filterOption={false}
								onSearch={handleOrgSearch}
								options={{
									values: LedgerStore.global_organisations_list,
									value_key: 'id',
									text_key: 'organisation_name',
									rejected_keys: [organisationValues && organisationValues.id]
								}}
							/>
						</Col>

						<Col span={8}>
							<InputComponent required label="Organisation Name" name="organisation_name" placeholder="Organisation Name" onChange={handleChange}
								tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
								rules={vsmOrganisations.validation.organisation_name}
								maxLength={100}
							/>
						</Col>

						<Col span={8}>
							<InputComponent label="Trade Name" name="trade_name" placeholder="Trade Name" onChange={handleChange}
								tooltip="Organisation Trade name is a trading name commonly used by an Organisation to perform business activities."
								rules={vsmOrganisations.validation.trade_name}
								maxLength={100}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={5}>
							<InputComponent required allowClear type="select" label="Registration Country" name="registered_country_id" placeholder="Select Registration Country"
								onChange={(e) => {
									handleChange()
									onCountryChange(e)
								}}
								options={{
									values: dropdown_countries_list || (
										organisationValues && organisationValues.global_countries && [{
											id: organisationValues.global_countries.id,
											country_name: organisationValues.global_countries.country_name,
										}]
									),
									value_key: 'id',
									text_key: 'country_name'
								}}
								tooltip="A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities."
								rules={vsmOrganisations.validation.registration_country}
								onFocus={() => fetchCountry && getAllCountries().then(() => setFetchCountry(false))}
								notFoundContent={fetchCountry ? <Spin size="small" /> : "No Record Found."}
							/>
						</Col>

						<Col span={5}>
							<InputComponent
								label="Registration Number" name="registration_number" placeholder="Registration Number" onChange={handleChange}
								tooltip="A number appointed by relevant Company Registration Authorities in a country the organisation was founded in."
								rules={vsmOrganisations.validation.registration_number}
								maxLength={100}
							/>
						</Col>

						<Col span={5}>
							<InputComponent
								label="VAT Number" name="vat_number" placeholder="VAT Number" onChange={handleChange}
								tooltip="A number appointed by relevant Tax Authorities in a country the organisation was founded in."
								rules={vsmOrganisations.validation.vat_number}
								maxLength={100}
							/>
						</Col>

						<Col span={5}>
							<InputComponent
								label="Website" name="website" placeholder="Website URL" onChange={handleChange}
								tooltip="A website is a collection of web pages and related content that is identified by a common domain name and published on at least one web server."
								rules={vsmOrganisations.validation.website}
							/>
						</Col>

						<Col span={4}>
							{(organisationValues && (organisationValues.associated_ledgers === null)) ? (
								<Form.Item label="FY Start Month" tooltip="Financial Year Start Month is the starting month from which the Organisation will report its fiscal operations within a period of 12 months.">
									<Form.Item name="fy_start_month" noStyle required>
										<Select placeholder="Select FY Start Month" onChange={handleChange}>
											{
												(month_list && month_list.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.month}</Select.Option>
												)))
											}
										</Select>
									</Form.Item>
								</Form.Item>
							) : (
								<Form.Item label="FY Start Month" tooltip="Financial Year Start Month is the starting month from which the Organisation will report its fiscal operations within a period of 12 months.">
									<Form.Item name="fy_start_month" noStyle required>
										<Select placeholder="Select FY Start Month" disabled={isNonEditable} onChange={handleChange}>
											{
												(month_list && month_list.map((item, index) => (
													<Select.Option key={index} value={item.id}>{item.month}</Select.Option>
												)))
											}
										</Select>
									</Form.Item>
								</Form.Item>
							)}
						</Col>
					</Row>
					{(organisationValues && (organisationValues.associated_ledgers !== null)) && <div className="ant-form-item-extra mb-20"><span key="1" style={{ fontSize: "12px" }}>If you edit Financial Month, you will need to select some additional configuration too. <Button type="link" onClick={editAnyway} style={{ padding: 0, height: "auto", fontSize: "12px" }}>Click here</Button> to edit it anyway.</span></div>}

					{(isNewFields) ? (
						<>
							<Row gutter={24}>
								<Col className="w-100">
									<div className="w-100">
										<Form.Item name="apply_year_on" initialValue="next">
											<Radio.Group buttonStyle="solid" onChange={handleChange}>
												<Radio.Button key="1" value="current" disabled={currentYearDisable}>Apply to Current Year</Radio.Button>
												<Radio.Button key="2" value="next">Apply to Next Year</Radio.Button>
											</Radio.Group>
										</Form.Item>
									</div>
								</Col>
								<Col>
									<Form.Item className="mb-0" noStyle>
										<Form.Item name="transfer_in" valuePropName="checked" hidden={checkBoxHide}>
											<Checkbox style={{ fontSize: "12px" }} disabled={checkDisable}>Move existing transactions of current month to next Financial Year</Checkbox>
										</Form.Item>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={24}>
								<Col span={12} className="w-100">
									<Form.Item noStyle>
										<Form.Item name="confirm">
											<Input placeholder="Type 'CONFIRM' as this action cannot be undone." />
										</Form.Item>
									</Form.Item>
								</Col>
							</Row>
						</>
					) : (
						null
					)}

					<Row gutter={24}>
						<Col span={9}>
							<Form.Item label="Phone" className="mb-10" required={true}>
								<PhoneElement onChange={handleChange} form={form} />
							</Form.Item>
						</Col>
						<Col span={9}>
							<Form.Item label="Email" className="mb-10" required={true}>
								<EmailElement onChange={handleChange} form={form} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<div className='mt-25'>
								<InputComponent
									type='checkbox'
									name='allow_external_app'
									label="Allow External App"
									valuePropName='checked'
									onChange={handleChange}
									initialValue={false}
									disabled={organisationValues && props.visible && organisationValues.is_originator_user_count > 0 ? true : false}
								/>
							</div>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={6}>
							<InputComponent
								required allowClear type="select" label="Credebt® Classification" name="industry_id" placeholder="Select Credebt® Classification"
								onChange={handleChange} rules={vsmOrganisations.validation.industry}
								tooltip="Credebt® Classification is a group of productive enterprises or organisations that produce or supply goods, services, or sources of income."
								onFocus={() => (fetchIndustries && OrganisationStore.getIndustries().then(() => setFetchIndustries(false)))}
								notFoundContent={fetchIndustries ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: OrganisationStore.industries_list,
									value_key: "id",
									text_key: "name"
								}}
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required allowClear type="select" label="Organisation Currency" name="currency_id" placeholder="Select Organisation Currency"
								onChange={handleChange} rules={vsmOrganisations.validation.org_currency}
								tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
								onFocus={() => (fetchCurrency && OrgBankAccountStore.getCurrencyList().then(() => setFetchCurrency(false)))}
								notFoundContent={fetchCurrency ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: OrgBankAccountStore.dropdown_currency_list,
									value_key: "id",
									text_key: "currency_name"
								}}
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required allowClear type="select" label="NACE Category" name='nace_section' placeholder="Select NACE Category"
								onChange={(e) => { handleChange(); changeNACE(e) }}
								tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
								onFocus={() => (fetctNace && OrganisationStore.getNaceCode().then(() => setFetchNace(false)))}
								notFoundContent={fetctNace ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: naceCode_list || [{
										id: organisationValues && organisationValues.global_nace_sections && organisationValues.global_nace_sections.get_nace_category && organisationValues.global_nace_sections.get_nace_category.id,
										category_name: organisationValues && organisationValues.global_nace_sections && organisationValues.global_nace_sections.get_nace_category && organisationValues.global_nace_sections.get_nace_category.category_name
									}],
									value_key: "id",
									text_key: "category_name"
								}}
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required allowClear type="select" label="NACE Code" name="nace_section_id" placeholder="Select NACE Code"
								onChange={handleChange} rules={vsmOrganisations.validation.nace_code}
								tooltip="NACE (Nomenclature of Economic Activities) is the European statistical classification of economic activities. NACE groups organisations according to their business activities."
								options={{
									// values: naceCode,
									values: naceCode || [{
										id: organisationValues && organisationValues.global_nace_sections && organisationValues.global_nace_sections.id,
										code: organisationValues && organisationValues.global_nace_sections && organisationValues.global_nace_sections.code,
										section: organisationValues && organisationValues.global_nace_sections && OrganisationStore.organisationValues.global_nace_sections.section
									}],
									value_key: "id",
									text_key: { key: ["code", " - ", "section"] }
								}}
								disabled={naceCode ? false : true}
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required allowClear type="select" label="Status" name="status_id" placeholder="Select Status"
								onChange={handleChange} rules={vsmOrganisations.validation.status}
								tooltip="A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities."
								onFocus={() => (fetchStatus && OrgStatusStore.getOrgStatus().then(() => setFetchStatus(false)))}
								notFoundContent={fetchStatus ? <Spin size="small" /> : "No Record Found."}
								options={{
									values: OrgStatusStore.orgStatusList,
									value_key: "id",
									text_key: "status_name"
								}}
							/>
						</Col>
					</Row>

					<Row gutter={24}>
						<Col span={6}>
							<InputComponent
								label="Tax Clearance Access No." name="revenue_access_number" placeholder="Tax Clearance Access Number"
								onChange={handleChange} rules={vsmOrganisations.validation.tax_clearence} maxLength={100}
								tooltip="Tax Clearance Access Number"
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required
								label="d-ETR Monthly Discount Percentage"
								name="d_etr_purchase_discount"
								placeholder="d-ETR Monthly Discount Percentage"
								onChange={handleChange}
								tooltip="The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange"
								rules={vsmOrganisations.validation.discount_percentage}
								addonAfter={<PercentageOutlined />}
							/>
						</Col>

						<Col span={6}>
							<InputComponent
								required
								label="c-ETR Sell Rate Percentage"
								name="c_etr_sell_rate"
								placeholder="c-ETR Sell Rate Percentage"
								onChange={handleChange}
								tooltip="The monthly discount percentage is pre-set according to RSA agreement with Credebt Exchange"
								rules={vsmOrganisations.validation.sell_rate}
								addonAfter={<PercentageOutlined />}
							/>
						</Col>

					</Row>

					<Row gutter={24}>
						<Col span={4}>
							<InputComponent
								required type="text" label="d-ETR Purchase" name="purchase_payment" placeholder="d-ETR Purchase"
								onBlur={(e) => percentagesNode(e, 'purchase_payment')}
								onChange={handleChange} rules={vsmOrganisations.validation.purchase_payment}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="d-ETR Sell Rate" name="default_sell_rate_d_etr" placeholder="d-ETR Sell Rate"
								onBlur={(e) => percentagesNode(e, 'default_sell_rate_d_etr')}
								onChange={handleChange} rules={vsmOrganisations.validation.default_sell_rate_d_etr}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="b/c-ETR Sell Rate" name="default_sell_rate_b_c_etr" placeholder="b/c-ETR Sell Rate"
								onBlur={(e) => percentagesNode(e, 'default_sell_rate_b_c_etr')}
								onChange={handleChange} rules={vsmOrganisations.validation.default_sell_rate_b_c_etr}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="d-ETR Face Value Charge" name="face_value_charge" placeholder="d-ETR Face Value Charge"
								onBlur={(e) => percentagesNode(e, 'face_value_charge')}
								onChange={handleChange} rules={vsmOrganisations.validation.face_value_charge}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="b/c-ETR Face Value Charge" name="creditor_face_value_charge" placeholder="b/c-ETR Face Value Charge"
								onBlur={(e) => percentagesNode(e, 'creditor_face_value_charge')}
								onChange={handleChange} rules={vsmOrganisations.validation.creditor_face_value_charge}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="ETR LDC Premium" name="ldc_primium" placeholder="ETR LDC Premium"
								onBlur={(e) => percentagesNode(e, 'ldc_primium')}
								onChange={handleChange} rules={vsmOrganisations.validation.ldc_primium}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="d-ETR Agent Commission" name="originator_application_fee" placeholder="d-ETR Agent Commission"
								onBlur={(e) => percentagesNode(e, 'originator_application_fee')}
								onChange={handleChange} rules={vsmOrganisations.validation.originator_application_fee}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="b/c-ETR Agent Commission" name="agent_commission" placeholder="b/c-ETR Agent Commission"
								onBlur={(e) => percentagesNode(e, 'agent_commission')}
								onChange={handleChange} rules={vsmOrganisations.validation.agent_commission}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="Arrangement Fee" name="arrangement_fee" placeholder="Arrangement Fee"
								onChange={handleChange} rules={vsmOrganisations.validation.arrangement_fee}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="Processing Fee" name="processing_commission" placeholder="Processing Fee"
								onChange={handleChange} rules={vsmOrganisations.validation.processing_commission}
							/>
						</Col>

						<Col span={4}>
							<InputComponent
								required type="text" label="Trade Fee" name="trade_commission" placeholder="Trade Fee"
								onChange={handleChange} rules={vsmOrganisations.validation.trade_commission}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="d-ETR Trade Limit" name="trade_limit_d_etr" placeholder="d-ETR Trade Limit"
								onChange={handleChange} rules={vsmOrganisations.validation.trade_limit_d_etr}
								disabled={organisationValues && organisationValues.associated_detr_count && organisationValues.associated_detr_count > 0 ? true : false}
							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="c-ETR Trade Limit" name="trade_limit_c_etr" placeholder="c-ETR Trade Limit"
								onChange={handleChange} rules={vsmOrganisations.validation.trade_limit_c_etr}
								disabled={organisationValues && organisationValues.associated_cetr_count && organisationValues.associated_cetr_count > 0 ? true : false}

							/>
						</Col>
						<Col span={4}>
							<InputComponent
								required type="text" label="ETR Months" name="etr_months" placeholder="ETR Months"
								onChange={handleChange} rules={vsmOrganisations.validation.etr_months}
							/>
						</Col>

						<Col span={4}>
							<InputComponent
								required type="text" label="Early Payment Discount" name="payment_discount" placeholder="Early Payment Discount"
								onChange={handleChange} rules={vsmOrganisations.validation.payment_discount}
							/>
						</Col>

						<Col span={4}>
							<InputComponent
								required type="text" label="Requested Credit Limit" name="requested_credit_limit" placeholder="Requested Credit Limit"
								onChange={handleChange} rules={vsmOrganisations.validation.requested_credit_limit}
							/>
						</Col>

						<Col span={8}>
							<InputComponent
								required type="textarea" label="Description" name="description" placeholder="Organisation Description"
								onChange={handleChange} rowSize={4} rules={vsmOrganisations.validation.description} maxLength={32000}
								tooltip="The purpose of describing the organisation is to provide; an understanding of mission, goals, & objectives, and clarify the focus and importance of the organisation's effort."
							/>
						</Col>


						<Col span={8}>
							<Form.Item label="Logo" tooltip="Logo is a symbol or other small design adopted by an organisation to identify its products." extra={[<span key="1" style={{ fontSize: "12px" }}>Only JPG or PNG is allowed, Maximum size : 500px x 500px</span>]}>
								<Form.Item noStyle name="logo_url">
									<Upload
										accept=".png, .jpeg"
										fileList={fileList}
										onRemove={onRemoveImage}
										className="hideeyeicon"
										listType="picture-card"
										multiple={false}
										showUploadList={true}
										{...eventProps}
									>
										{fileList.length >= 1 ? null :
											<div>
												<PlusOutlined />
											</div>}
									</Upload>
								</Form.Item>
							</Form.Item>
						</Col>
					</Row>


					<Form.Item className="mb-0" wrapperCol={{ offset: 8 }}>
						<Form.Item name="add_address" valuePropName="checked">
							<Checkbox onChange={addAddress}>Add Address</Checkbox>
						</Form.Item>
					</Form.Item>
					<Card title="Address Details:" hidden={!isChecked}>
						<AddressElement onChange={handleChange} fetchCountry={fetchCountry} setFetchCountry={setFetchCountry} addAddress={addAddress} />
					</Card>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
