import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Radio, Col, Spin } from 'antd'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import useStore from '../../../../store'
import { vsmOrgBankAccount, vsmRoleMapping, vsmNotify } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [currentTab, setCurrentTab] = useState(null)
	const [loading, setLoading] = useState(true)
	const { OrgBankAccountStore, OrgBankAccountStore: { EditData, editValues } } = useStore()
	const { id } = useParams()

	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		data.organisation_id = id
		EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmOrgBankAccount.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			var payload = { organisation_id: props.parent_id && parseInt(props.parent_id), mapping_type: 1 }
			OrgBankAccountStore.getRecordById(props.accountId, payload).then((data) => {
				OrgBankAccountStore.setEditValues(data.data)
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmRoleMapping.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props, OrgBankAccountStore])

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({
				bank_name: editValues.banks.name,
				bank_account_type_id: editValues.bank_account_type_id,
				currency_id: editValues.currency_id,
				account_number: editValues.account_number,
				sort_code: editValues.sort_code,
				iban: editValues.iban,
				swift_bic: editValues.swift_bic,
				tab: editValues.account_number ? "account" : "iban"
			})
			if (editValues.account_number) {
				setCurrentTab("account")
			} else {
				setCurrentTab("iban")
			}
		}
	}, [editValues, setCurrentTab, form, props])

	// check for valid form values then accordingly make save button disable/enable & set value of journal type
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
			if ((data.tab === "account" && data.hasOwnProperty("iban")) || (data.tab === "iban" && data.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
		}).catch(e => {
			if ((e.values.tab === "account" && e.values.hasOwnProperty("iban")) || (e.values.tab === "iban" && e.values.hasOwnProperty("account_number"))) {
				changeMapwith()
			}
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// set field vaule null based on tab selection
	const changeMapwith = async () => {
		await setCurrentTab(form.getFieldValue("tab"))
		form.setFieldsValue({
			account_number: null,
			sort_code: null,
			iban: null,
			swift_bic: null
		})
		handleChange()
	}

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		props.setId(null)
		OrgBankAccountStore.editValues = null
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Bank Account - #${editValues.id} - ${editValues.banks.name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form form={form} id="editform" onFinish={handleSubmit}
					layout='vertical'
					onChange={handleChange}
				>
					<InputComponent
						disabled required type="autocomplete" label="Bank Name" name="bank_name" placeholder="Bank Name"
						options={[editValues.banks]}
						tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
						rules={vsmOrgBankAccount.validation.bank_name}
					/>
					<InputComponent
						disabled required type="select" label="Bank Account Type" name="bank_account_type_id" placeholder="Select Bank Account Type"
						options={{
							values: [editValues.bank_accounts_type],
							value_key: 'id',
							text_key: 'name',
						}}
						tooltip="A name or code given to a cash account that indicates the account's purpose."
						rules={vsmOrgBankAccount.validation.bank_ac_type}
					/>
					<InputComponent
						disabled required type="select" label="Currency" name="currency_id" placeholder="Select Currency"
						options={{
							values: [editValues.currency],
							value_key: 'id',
							text_key: 'currency_name',
						}}
						tooltip="Currency is a medium of exchange for goods and services."
						rules={vsmOrgBankAccount.validation.currency}
					/>
					<Col>
						<Form.Item name="tab">
							<Radio.Group buttonStyle="solid">
								<Radio.Button value="account" disabled={editValues.iban ? true : false}>Account Number</Radio.Button>
								<Radio.Button value="iban" disabled={editValues.account_number ? true : false}>IBAN Number</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
					{
						currentTab === "account" ?
							<>
								<InputComponent
									required label="Account Number" name="account_number" placeholder="Account Number" onChange={handleChange}
									tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
									rules={vsmOrgBankAccount.validation.account_num}
									maxLength={50}
								/>
								<InputComponent
									required label="Sort Code" name="sort_code" placeholder="Sort Code" onChange={handleChange}
									tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
									rules={vsmOrgBankAccount.validation.sort_code}
									maxLength={50}
								/>
							</>
							:
							<>
								<InputComponent
									required label="IBAN" name="iban" placeholder="International Bank Account Number" onChange={handleChange}
									tooltip="An IBAN, or International Bank Account Number, is a standard international numbering system developed to identify an overseas/foreign bank account."
									rules={vsmOrgBankAccount.validation.iba_num}
								/>
								<InputComponent
									required label="Swift or BIC Code" name="swift_bic" placeholder="Swift or BIC Code" onChange={handleChange}
									tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
									rules={vsmOrgBankAccount.validation.swift_or_bic_code}
									maxLength={50}
								/>
							</>
					}
				</Form>
			</Spin>
		</Modal>
	) : null
})

export default EditComponent
