import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import { DateComparator } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'

const PreviewDataTableElement = observer((props) => {
	const { SavedReportStore } = useStore()
	let gridOptions = {
		columnDefs: [
			{ headerName: '# ID', field: 'id', filter: 'agNumberColumnFilter', pinned: 'left', width: 80, },
			{
				headerName: 'Exchange ID', field: 'exchange_ref_id', filter: 'agTextColumnFilter',
				width: 150,
				valueGetter: (params) => (params.data && params.data.exchange_ref_id) ? params.data.exchange_ref_id : ""
			},
			{
				headerName: 'Organization / People', field: 'entity_name', filter: 'agSetColumnFilter',
				width: 200,
				filterParams: {
					defaultToNothingSelected: true,
				},
				valueGetter: (params) => (params.data && params.data.entity_name) ? params.data.entity_name : ""
			},
			{
				headerName: 'Related Organization / People', field: 'related_entity_name', filter: 'agSetColumnFilter',
				width: 200,
				filterParams: {
					defaultToNothingSelected: true,
				},
				valueGetter: (params) => (params.data && params.data.related_entity_name) ? params.data.related_entity_name : ""
			},
			{
				headerName: 'Transaction Ref', field: 'reference', filter: 'agTextColumnFilter',
				width: 130,
				valueGetter: (params) => (params.data && params.data.reference) ? params.data.reference : ""
			},
			{
				headerName: 'Traded', field: 'traded_on',
				filter: 'agDateColumnFilter',
				valueGetter: (params) => (params.data && params.data.traded_on) ? params.data.traded_on : "",
				width: 185,
				filterParams: {
					buttons: ['reset'],
					inRangeInclusive: true,
					comparator: DateComparator
				}
			},
			{
				headerName: 'Posted', field: 'posted_on',
				filter: 'agDateColumnFilter',
				valueGetter: (params) => (params.data && params.data.posted_on) ? params.data.posted_on : "",
				width: 185,
				filterParams: {
					buttons: ['reset'],
					comparator: DateComparator
				}
			},
			{
				headerName: 'Face Value', field: 'face_value',
				width: 110,
				type: 'rightAligned', filter: 'agNumberColumnFilter',
				cellRendererFramework: (params) => (params.data) ? (
					<NumberFormat value={params.data.face_value} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
				) : null
			}
		]
	}

	if (props.rowData) {
		return (
			<div className="" style={{ height: '70vh', marginBottom: 24 }}>
				<div className="ag-theme-alpine grid_wrapper">
					<AgGridReact
						rowData={props.rowData}
						modules={AllModules}
						columnDefs={gridOptions.columnDefs}
						defaultColDef={{ ...LocalGridConfig.defaultColDef }}
						columnTypes={LocalGridConfig.columnTypes}
						overlayNoRowsTemplate={vsmCommon.noRecord}
						onGridReady={props.onGridReady}
						gridOptions={{ ...LocalGridConfig.options, pagination: false }}
						onColumnResized={SavedReportStore.onChildnGridChanged}
						onColumnMoved={SavedReportStore.onChildnGridChanged}
						onColumnPinned={SavedReportStore.onChildnGridChanged}
						onSortChanged={SavedReportStore.onChildnGridChanged}
					/>
				</div>
			</div>
		)

	} else {
		return null
	}
})

export default PreviewDataTableElement
