import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Checkbox, Tooltip, Form, Col, DatePicker, Spin, Row, Button, Divider } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import InputComponent from '../../../../components/InputComponent'
import { vsmAllTransactions, vsmOrganisations } from '../../../../config/messages';
import useStore from '../../../../store';
import { default as JournalLogsComponent } from '../../Administration/TransactionModels/component/JournalLines/component/ListComponent';
import moment from 'moment';
import TableComponent from '../elements/ItemsTabel';
import { default as ManualJournalAddComponent } from '../../Administration/ManualJournalLog/components/AddComponent'
import { default as TransactionItemsAddComponent } from '../../Administration/TransactionItems/components/AddComponent'
import { PhoneOutlined, GlobalOutlined, EnvironmentFilled } from '@ant-design/icons';

const FormFieldComponent = observer((props) => {
	const [addModal, setAddModal] = useState(false)
	const [addItemModal, setAddItemModal] = useState(false)
	const [fetchRole, setFetchRole] = useState(true)
	const [fetchMapping, setFetchMapping] = useState(true)
	const [fetchStatus, setFetchStatus] = useState(true)
	const [fetchVatRate, setFetchVatRate] = useState(true)
	const { OrgBankAccountStore, UserStore, OrganisationStore, AllTransactionStore: { getMappingList, getStatusList, getVatRateList, dropdownLodaing, checkBox_dropdown, checkBox_dropdown_val, depedentList, mapping_dropdown, vatRate_dropdown, status_dropdown, csvModals, itemsTable } } = useStore()
	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore,
		user: UserStore
	}
	const currentDate = new Date()

	useEffect(() => {
		setFetchRole(true)
		setFetchMapping(true)
		setFetchStatus(true)
		setFetchVatRate(true)
	}, [])

	// Open form for add manual journal entry
	const openAddModal = () => setAddModal(true)

	// Close form for add manual journal entry
	const closeAddModal = () => setAddModal(false)

	// Open form for add transaction item
	const openAddItemModal = () => setAddItemModal(true)

	// Close form for add transaction item
	const closeAddItemModal = () => setAddItemModal(false)

	useEffect(() => {
		if (props.tab === "add") {
			props.form.setFieldsValue({ model: csvModals })
		}
	}, [csvModals, props.tab, props.form])

	return (
		<>
			<Row gutter={24}>
				<Col span={12}>
					{(props.entity_type === 3) ? null : (
						<>
							{(props.entity_type === 2) ? (
								<Row gutter={24}>
									<Col span={12}>
										<InputComponent
											required type="select" label="People Role" name="role_id"
											disabled={props.tab !== "add"}
											placeholder="Select People Role"
											tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
											rules={vsmAllTransactions.validation.organisation_role}
											onChange={props.onChange}
											onFocus={() => fetchRole && store.organisation.getOrgRoles().then(() => setFetchRole(false))}
											notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: props.tab === "add" ? store.organisation.dropdown_orgRole_list : (depedentList && depedentList.roles),
												value_key: "id",
												text_key: "role_name"
											}}
										/>
									</Col>
									<Col span={12}>
										<InputComponent
											required type="select" label="People" name="entity_id"
											placeholder="Select People"
											tooltip="People is an entity (for example a Person) that has (or had in the past) – directly or indirectly - any business relationship with Credebt Exchange or with another Organisation"
											rules={vsmOrganisations.validation.organisation}
											onChange={props.onChange}
											disabled={props.tab !== "add" || !props.roleId}
											options={{
												values: depedentList && depedentList.entity,
												value_key: "id",
												text_key: "entity_name",
											}}
										/>
									</Col>
								</Row>
							) : (
								<Row gutter={24}>
									<Col span={12}>
										{/* Organisation Role */}
										<InputComponent
											required
											disabled={props.tab !== "add"}
											type="select"
											label="Organisation Role"
											name="role_id"
											placeholder="Select Organisation Role"
											tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
											rules={vsmAllTransactions.validation.organisation_role}
											onChange={props.onChange}
											onFocus={() => fetchRole && store.organisation.getOrgRoles().then(() => setFetchRole(false))}
											notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: props.tab === "add" ? store.organisation.dropdown_orgRole_list : (depedentList && depedentList.roles),
												value_key: "id",
												text_key: "role_name"
											}}
										/>
									</Col>
									<Col span={12}>
										{/* Organisation */}
										<InputComponent
											required
											disabled={props.tab !== "add" || !props.roleId}
											type="select"
											label="Organisation"
											name="entity_id"
											placeholder="Select Organisation"
											tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name). It tells system that this transaction involves this organisation as one of role."
											rules={vsmOrganisations.validation.organisation}
											onChange={props.onChange}
											notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
											options={{
												values: depedentList && depedentList.entity,
												value_key: "id",
												text_key: "entity_name",
												rejected_keys: props.relatedOrgData ? [props.relatedOrgData.id] : null
											}}
										/>
									</Col>
								</Row>
							)}
							{/* organisation detail card */}
							{(props.entity_type === 3) ? null : (
								(props.entity_type === 2) ? (
									props.orgData && <PeopleCardComponent props={props.orgData} />
								) : (
									props.orgData && <CardComponent props={props.orgData} />
								)
							)}
						</>
					)}
				</Col>


				<Col span={12}>

					{/* Relate to */}
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="radio_button"
						label="Relate to"
						name="related_entity_type"
						initialValue="Organisation"
						options={{
							values: [
								{ value: 'Organisation', text: 'Organisation' },
								{ value: 'People', text: 'People' },
								{ value: 'Ledger', text: store.user.user.default_ledger_name },
							]
						}}
					/>


					{/* Related Organisation */}
					{
						(props.related_to === "Ledger") ? null : (
							<>
								{(props.related_to === "People") ? (
									<Row gutter={24}>
										<Col span={12}>
											<InputComponent
												required
												disabled={props.tab !== "add" || !props.orgData}
												type="select"
												label="Related People Role"
												name="related_role_id"
												placeholder="Select Related People Role"
												tooltip="People Role Relation is the connection between the Roles played by two different People."
												rules={vsmAllTransactions.validation.related_people_role}
												onChange={props.onChange}
												notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
												options={{
													values: depedentList && depedentList.related_roles,
													value_key: "id",
													text_key: "role_name"
												}}
											/>
										</Col>
										<Col span={12}>
											<InputComponent
												required
												disabled={props.tab !== "add" || !props.relatedRoleId}
												type="select"
												label="Related People"
												name="related_entity_id"
												placeholder="Select Related People"
												tooltip="Related People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
												rules={vsmOrganisations.validation.related_people}
												onChange={props.onChange}
												notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
												options={{
													values: depedentList && depedentList.related_entity,
													value_key: "id",
													text_key: "entity_name"
												}}
											/>
										</Col>
									</Row>
								) : (
									<Row gutter={24}>
										{/* Related Org. Role */}
										<Col span={12}>
											<InputComponent
												required
												disabled={props.tab !== "add" || !props.orgData}
												type="select"
												label="Related Org. Role"
												name="related_role_id"
												placeholder="Select Related Organisation Role"
												tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
												rules={vsmAllTransactions.validation.related_role}
												onChange={props.onChange}
												notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
												options={{
													values: depedentList && depedentList.related_roles,
													value_key: "id",
													text_key: "role_name"
												}}
											/>
										</Col>
										<Col span={12}>
											<InputComponent
												required
												disabled={props.tab !== "add" || !props.relatedRoleId}
												type="select"
												label="Related Organisation"
												name="related_entity_id"
												placeholder="Select Organisation"
												tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
												rules={vsmOrganisations.validation.related_organisation}
												onChange={props.onChange}
												notFoundContent={dropdownLodaing ? <Spin size="small" /> : "No Record Found."}
												options={{
													values: depedentList && depedentList.related_entity,
													value_key: "id",
													text_key: "entity_name",
													rejected_keys: props.orgData ? [props.orgData.id] : null
												}}
											/>
										</Col>
									</Row>
								)}
								{(props.related_to === "Ledger") ? null : (
									(props.related_to === "People") ? (
										props.relatedOrgData && <PeopleCardComponent props={props.relatedOrgData} />
									) : (
										props.relatedOrgData && <CardComponent props={props.relatedOrgData} />
									))}
							</>
						)}
				</Col>
				<Divider />
				{/* Mapping */}
				<Col span={12}>
					< InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Trade Transaction"
						name="type_field_mapping_id"
						placeholder="Select Trade Transaction"
						tooltip="Select Trade Transaction Name which is combination of type & sub type. It Defines which fields and models will be generated on imported transactions."
						rules={vsmAllTransactions.validation.mapping}
						onChange={props.onChange}
						onFocus={() => fetchMapping && getMappingList().then(() => setFetchMapping(false))}
						notFoundContent={fetchMapping ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: mapping_dropdown,
							value_key: "id",
							text_key: "mapping_name"
						}}
					/>
				</Col>
				<Col span={24}></Col>
				{/* Currency */}
				<Col span={4}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Currency"
						name="currency_id"
						placeholder="Select Currency"
						tooltip="Currency is a medium of exchange for goods and services. Select one which is base currency for this transaction."
						rules={vsmAllTransactions.validation.currency}
						initialValue={store.user.ledger_base_currency.id}
						onChange={props.onChange}
						options={{
							values: store.bank.dropdown_currency_list,
							value_key: "id",
							text_key: "currency_name"
						}}
					/>
				</Col>

				{/* Face value */}
				<Col span={4}>
					<InputComponent
						required
						disabled={props.tab === "view"}
						label="Face value"
						name="face_value"
						placeholder="Face value"
						tooltip="Face Value is one of the fundamental attributes defining any Transaction, that stores a monetary value of the Transaction."
						rules={vsmAllTransactions.validation.face_value}
						onChange={props.onChange}
					/>
				</Col>

				{/* Status */}
				<Col span={4}>
					<InputComponent
						required
						disabled={props.tab === "view"}
						type="select"
						label="Status"
						name="status_id"
						placeholder="Select Status"
						tooltip="Status is an attribute of a Transaction, it tells actual status of Transaction at the moment."
						rules={vsmAllTransactions.validation.status}
						onChange={props.onChange}
						initialValue={1}
						onFocus={() => props.tab === "edit" && (fetchStatus && getStatusList().then(() => setFetchStatus(false)))}
						notFoundContent={props.tab === "edit" && (fetchStatus ? <Spin size="small" /> : "No Record Found.")}
						options={{
							values: status_dropdown,
							value_key: "id",
							text_key: "status_name"
						}}
					/>
				</Col>

				{/* Vat Rate */}
				<Col span={4}>
					<InputComponent
						required
						disabled={props.tab !== "add"}
						type="select"
						label="Vat Rate"
						name="tax_settings_id"
						placeholder="Select Vat Rate"
						tooltip="VAT is levied on sale of goods and services and paid by producers to the government, the actual tax is levied from customers or end users who purchase these goods and services."
						rules={vsmAllTransactions.validation.vat_rate}
						onChange={props.onChange}
						onFocus={() => fetchVatRate && getVatRateList().then(() => setFetchVatRate(false))}
						notFoundContent={fetchVatRate ? <Spin size="small" /> : "No Record Found."}
						options={{
							values: vatRate_dropdown,
							value_key: "id",
							text_key: { key: ["tax_name", " - ", "tax_value"] },
						}}
					/>
				</Col>

				{/* Posted On */}
				<Col span={4}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Posted On"
						name="posted_on"
						placeholder="Select Posted Date"
						tooltip="The posted date is the day, month, and year when the trade transaction is Posted."
						rules={vsmAllTransactions.validation.posted_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>

				{/* Traded On */}
				<Col span={4}>
					<DatePickerComponent
						required
						disabled={props.tab !== "add"}
						label="Traded On"
						name="traded_on"
						placeholder="Select Traded Date"
						tooltip="The traded date is the day, month, and year when the trade transaction is Traded."
						rules={vsmAllTransactions.validation.traded_date}
						onChange={props.onChange}
						initialValue={moment(currentDate)}
					/>
				</Col>
			</Row >

			{/* Additional Field Values */}
			{
				props.csvColumn && props.csvColumn.length > 0 && (
					<Card
						title={<div>
							<span>Additional Field Values (CSV Column Types) Needed based on Selected Trade Transaction :</span>
							<Tooltip placement="topRight" title={"Additional values are added based on selected mapping. You can enter value for each additional fields. Values or Journal log will be generated based on that."}>
								<InfoCircleOutlined className="ml-10" />
							</Tooltip>
						</div>}
						className="ant-col ant-form-item-control"
					>
						<Form.List name='csv_columns'>
							{
								(fields, { add, remove }) => {
									return (
										<Row gutter={24}>
											{
												fields && fields.map((field, index) => {
													if ((props.tab === "add" || props.tab === "edit") && !props.form.getFieldValue("csv_columns")[field.name].read_only) {
														return (
															<Col span={6} key={index}>
																<InputComponent
																	required={props.form.getFieldValue("csv_columns")[field.name].is_required}
																	disabled={props.tab === "view"}
																	label={props.form.getFieldValue("csv_columns")[field.name].field_name}
																	key={[field.key, "default_value"]}
																	name={[field.name, "default_value"]}
																	placeholder={props.form.getFieldValue("csv_columns")[field.name].field_name}
																/>
															</Col>
														)
													} else if (props.tab === "view") {
														return (
															<Col span={6} key={index}>
																<InputComponent
																	required={props.form.getFieldValue("csv_columns")[field.name].is_required}
																	disabled={props.tab === "view"}
																	label={props.form.getFieldValue("csv_columns")[field.name].field_name}
																	key={[field.key, "default_value"]}
																	name={[field.name, "default_value"]}
																	placeholder={props.form.getFieldValue("csv_columns")[field.name].field_name}
																/>
															</Col>
														)
													} else {
														return false
													}
												})
											}
										</Row>
									)
								}
							}
						</Form.List>
					</Card>
				)
			}

			{/* Select Models to Apply on Transaction */}
			<div className={props.csvColumn && props.csvColumn.length > 0 ? "mt-25" : ""}>
				{
					csvModals && csvModals.length > 0 && (
						<Card
							title={<div>
								<span>Select Models to Apply on Transaction</span>
								<Tooltip placement="topRight" title={"Model list is displayed based on selected mapping."}>
									<InfoCircleOutlined className="ml-10" />
								</Tooltip>
							</div>}
							className="ant-col ant-form-item-control"
						>
							<CheckboxComponent
								name="model"
								options={csvModals}
								disabled={props.tab !== "add"}
								onChange={props.onChange}
								dropdown={checkBox_dropdown}
								dropdown_val={checkBox_dropdown_val}
							/>
						</Card>
					)
				}
			</div>

			{/* journal logs table */}
			{
				props.tab === "view" && (
					<div className="mt-20 mb-10">
						<div className="d-flex justify-space-between">
							<h3><span>Journal Logs :</span></h3>
							<Button className="mt-5 mb-5" onClick={openAddModal}>Add Manual Journal Entry</Button>
						</div>
						<JournalLogsComponent close={props.close} openParentCashViewDrawer={props.openParentCashViewDrawer} currentView={"verify"} />
						<ManualJournalAddComponent visible={addModal} close={closeAddModal} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} />
					</div>
				)
			}

			{/* transactions items table */}
			{
				props.tab === "view" && (
					<div className="mt-20 mb-10">
						<div className="d-flex justify-space-between">
							<h3><span>Transactions Items :</span></h3>
							<Button className="mt-5 mb-5" onClick={openAddItemModal}>New</Button>
						</div>
						<TableComponent data={itemsTable} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} />
						<TransactionItemsAddComponent visible={addItemModal} close={closeAddItemModal} transactionId={props.currentValues && props.currentValues.id} handleApiCall={props.handleApiCall} />
					</div>
				)
			}
		</>
	)
})

export default FormFieldComponent

const DatePickerComponent = ({ label, required, rules, name, tooltip, initialValue, ...rest }) => {
	return (
		<Form.Item label={label} required={required} tooltip={tooltip}>
			<Form.Item name={name} initialValue={initialValue} noStyle rules={rules}>
				<DatePicker {...rest} className={'d-flex w-100'} />
			</Form.Item>
		</Form.Item>
	)
}

const CheckboxComponent = ({ name, options, dropdown, onChange, disabled }) => {
	return (options && options.length >= 0) ? (
		disabled ? (
			<Form.Item name={name}>
				<Checkbox.Group
					valuePropName="checked"
					options={options}
					onChange={onChange}
					disabled={disabled}
				/>
			</Form.Item>
		) : (
			<Form.List name={name} initialValue={options} >
				{
					(fields) => (
						<Row gutter={24}>
							{fields.map((field, index) => {
								return options[index] && (
									<Col span={12} key={field.key}>
										<Row gutter={24}>
											<Col span={12}>
												<Form.Item
													valuePropName="checked"
													noStyle
													name={[field.name, "model"]}
													key={[field.key, "model"]}
													initialValue={index >= 0 && options[index].selected ? true : false}
												>
													<Checkbox disabled={disabled}>{index >= 0 && options[index].model_name}</Checkbox>
												</Form.Item>
											</Col>
											<Col span={12}>
												<InputComponent
													required
													type="select" disabled={disabled}
													initialValue={"Current Date"}
													options={{
														values: dropdown,
													}}
													onChange={onChange}
													name={[field.name, 'field']}
													key={[field.key, 'field']}
													placeholder="Select DB Field"
												/>
											</Col>
										</Row>
									</Col>
								)
							})}
						</Row>
					)
				}
			</Form.List>
		)
	) : null
}

const CardComponent = ({ props }) => {
	return (
		<Card size="small" className="mb-20">
			<h3><b>{props.organisation_name} ({props.trade_name ? props.trade_name + ' - ' : ''}{props.global_countries ? props.global_countries.country_name : ''})</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						{(props.multiple_phone && props.multiple_phone.length) ? (
							<tr>
								<td style={{ width: '30px' }}><PhoneOutlined /></td>
								<td>{props.multiple_phone.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{props.website ? (
							<tr>
								<td><GlobalOutlined /></td>
								<td>{props.website && props.website}</td>
							</tr>
						) : null}
						{props.addresses ? (
							<tr>
								<td><EnvironmentFilled /></td>
								<td><div>
									{props.addresses.address_1}
									{props.addresses.address_2 && (', ' + props.addresses.address_2)}
									{props.addresses.address_3 && (', ' + props.addresses.address_3)}
									{props.addresses.city && (', ' + props.addresses.city)}
									{props.addresses.state_county && (', ' + props.addresses.state_county)}
									{props.addresses.countries && (', ' + props.addresses.countries.country_name)}
									{props.addresses.postal_code && (', ' + props.addresses.postal_code)}
								</div>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</Card>
	)
}

const PeopleCardComponent = ({ props }) => {
	return (
		<Card size="small" className="mb-20 mr-25">
			<h3><b>{props.first_name + ' ' + (props.last_name ? props.last_name : '')} ({props.title ? props.title + ' - ' : ''}{props.pps_number ? props.pps_number : ''})</b></h3>
			<div className="ant-table-content ant-table-small ant-table">
				<table>
					<tbody className="ant-table-tbody">
						{(props.phone && props.phone.length) ? (
							<tr>
								<td style={{ width: '30px' }}><PhoneOutlined /></td>
								<td>{props.phone.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{(props.email && props.email.length) ? (
							<tr>
								<td style={{ width: '30px' }}><PhoneOutlined /></td>
								<td>{props.email.map(x => x.contact_value).join(', ')}</td>
							</tr>
						) : null}

						{props.website ? (
							<tr>
								<td><GlobalOutlined /></td>
								<td>{props.website && props.website}</td>
							</tr>
						) : null}
						{props.single_address ? (
							<tr>
								<td><EnvironmentFilled /></td>
								<td>
									<div>
										{props.single_address.address_1}
										{props.single_address.address_2 && (', ' + props.single_address.address_2)}
										{props.single_address.address_3 && (', ' + props.single_address.address_3)}
										{props.single_address.city && (', ' + props.single_address.city)}
										{props.single_address.state_county && (', ' + props.single_address.state_county)}
										{props.single_address.countries && (', ' + props.single_address.countries.country_name)}
										{props.single_address.postal_code && (', ' + props.single_address.postal_code)}
									</div>
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		</Card>
	)
}
