import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{(UserStore.checkPermission(66, 'edit') && props.data && ([1, 2].includes(props.data.id) || props.data.role_name === 'Originator')) ? (null) : (
				<Tooltip placement="topRight" title={"Edit Role"} >
					<Button type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
				</Tooltip>
			)}
			{
				(UserStore.checkPermission(66, 'delete')) ? (
					(props.data.no_of_users > 0 || props.data.role_name === 'Originator') ? (
						<Tooltip placement="topRight" color={"red"} title={"User role cannot be removed as it is associated with users."}>
							<Button type="text" disabled><DeleteOutlined /></Button>
						</Tooltip>
					) : (
						<Tooltip placement="topRight" title={"Delete Role"} >
							<Button type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
						</Tooltip>
					)
				) : null

			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Role Name',
		field: 'role_name',
		width: 130
	}, {
		headerName: 'No. of Users',
		field: 'no_of_users',
		width: 100
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 170
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		width: 185,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 80
	}]
}
