import { Button, Drawer, Form } from 'antd';
import React from 'react';
import { Steps } from 'antd';
import CredebtorDetails from './CredebtorDetails';
import BankDetails from './BankDetails';
import CreditRisk from './CreditRisk';
import useStore from '../../../../store';
import { useState } from 'react';
import moment from 'moment';
import { vsmNotify } from '../../../../config/messages';

const AddComponent = (props) => {
	const [disabled, setisDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [fileList, setFileList] = useState([])
	const { CredebtorStore: { currentTab, JsonToFormData, AddNewCredebtor, setOpenTypeForm, setCurrentTab, AddCreditorBank, organisationID } } = useStore()
	const { Step } = Steps;

	const [form] = Form.useForm()

	const close = () => {
		form.resetFields()
		setOpenTypeForm(null)
		setSaving(false)
		props.setAddDrawer(false)
	}

	const submitForm = (e) => {
		setSaving(true)
		if (currentTab === 0) {
			e.add_address = e.addresses ? 1 : 0
			if (e.counter_party_since) {
				e.counter_party_since = moment(e.counter_party_since).format("YYYY")
			}
			if (e.phone && e.phone.length > 0) {
				e.phone = e.phone.filter(x => x.contact_value && (x.contact_for && x.contact_value))
				e.phone = e.phone.length > 0 ? JSON.stringify(e.phone) : "[]"
			}
			if (e.email && e.email.length > 0) {
				e.email = e.email.filter(x => x.contact_value && (x.contact_for && x.contact_value))
				e.email = e.email.length > 0 ? JSON.stringify(e.email) : "[]"
			}
			if (e.addresses && e.addresses.length > 0) {
				e.addresses = e.addresses.filter(x => x && x)
				e.addresses = e.add_address && e.addresses.length > 0 ? JSON.stringify(e.addresses) : "[]"
			}
			if (fileList.length > 0) {
				e.logo_url = fileList[0].originFileObj
			}
			var data = JsonToFormData(e)
			AddNewCredebtor(data).then(() => {
				setCurrentTab()
				vsmNotify.success({ message: 'Add SuccessFully' })
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors); setisDisabled(true) }
			}).finally(() => setSaving(false))
		}

		if (currentTab === 1) {
			if (currentTab === 1 && e.bank) {
				e.bank.forEach((singleBank) => {
					if (singleBank.tab === 'account') {
						delete singleBank.iban
						delete singleBank.swift_bic
					} else {
						delete singleBank.account_number
						delete singleBank.sort_code
					}
				})
			}

			if (e.bank && organisationID) {
				e.organisation_id = organisationID
			}
			AddCreditorBank(organisationID, e).then(() => {
				vsmNotify.success({ message: 'SuccessFully Add' })
				setCurrentTab()
			}).catch(e => {
				if (e.errors) { form.setFields(e.errors); setisDisabled(true) }
			}).finally(() => setSaving(false))

		}
	}

	let steps = [
		{
			id: 1,
			title: 'Credebtors Details',
			content: <CredebtorDetails form={form} CloseComponent={close} submitForm={submitForm} disabled={disabled} setisDisabled={setisDisabled} setFileList={setFileList} fileList={fileList} />
		},
		{
			id: 2,
			title: 'Bank Details',
			content: <BankDetails form={form} CloseComponent={close} submitForm={submitForm} disabled={disabled} setDisabled={setisDisabled} currentTab={currentTab} />
		},
		{
			id: 3,
			title: 'Credit & Risk Details',
			content: <CreditRisk form={form} setDisabled={setisDisabled} />
		},
	]


	return (
		<Drawer className='member_drawer plus_icon_design' visible={props.visible} onClose={close} placement='right' width={'60%'} title="Credebtors Details" destroyOnClose={true}
			footer={[<Button loading={saving} key="submit" form='submit-form' htmlType='submit' disabled={disabled}>Submit</Button>, <Button onClick={close}>Close</Button>]}
		>
			<Steps type="navigation" size="small" current={currentTab} className="site-navigation-steps mb-10">
				{steps.map((item) => (
					<Step key={item.id} title={item.title} />
				))}
			</Steps>
			{steps.length > 0 && <div className="member_steps">{steps.length && steps?.[currentTab]?.content}</div>}
		</Drawer>
	)
}

export default AddComponent