import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Col, Row } from 'antd'
import useStore from '../../../../../store'
import { observer } from 'mobx-react'
import { WarningFilled } from '@ant-design/icons'
import { vsmJournals, vsmNotify } from '../../../../../config/messages'

const DeleteComponent = observer((props) => {
	const [form] = Form.useForm()
	const { JournalsStore: { DeleteData, deleteValues } } = useStore()
	const [saving, setSaving] = useState()

	// Make function call to delete exixting record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = deleteValues.id
		DeleteData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmJournals.delete
			})
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
		})
	}

	// set the form values to delete
	useEffect(() => {
		if (deleteValues) {
			form.setFieldsValue({
				journal_name: deleteValues.journal_name,
			})
		}
	}, [deleteValues, form])

	return deleteValues ? (
		<Modal
			centered
			title={`Delete Journal - #${deleteValues.id} - ${deleteValues.journal_label}`}
			visible={props.visible}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" form='deleteform' loading={saving} htmlType="submit" type="primary" danger>Delete</Button>,
				<Button key="2" htmlType="button" onClick={() => {
					form.resetFields()
					props.close()
				}}>Cancel</Button>
			]}
		>
			<Form form={form} id="deleteform" onFinish={handleSubmit}>
				{
					<>
						<Form.Item name="transfer_id" style={{ display: 'none' }}>
							<Input type="hidden" />
						</Form.Item>
						<Row align="middle">
							<Col span={4}><WarningFilled style={{ fontSize: 45, color: '#ff4d4f' }} /></Col>
							<Col span={20}>
								<h3>Are you sure you want to remove this Journal?</h3>
							</Col>
						</Row>
					</>
				}

			</Form>
		</Modal>
	) : null
})

export default DeleteComponent
