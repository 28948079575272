import Axios from "axios";
import { action, decorate, observable } from "mobx";
import GridConfig from "../../config/GridConfig";

export default class PurchaseOrderStore {
    per_page = GridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    agGrid = null
    purchaseOrderDetails = null

    // change page size, default page size is GridConfig.options.paginationPageSize
    setPageSize = (page = GridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    handleReset = () => {
        localStorage.removeItem('purchase_order_grid')
        this.agGrid.api.setFilterModel(null)
        this.agGrid.api.setSortModel(null)
        this.agGrid.columnApi.resetColumnState()
        this.agGrid.api.onFilterChanged(null)
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('purchase_order_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = this.agGridServer = params
        const { api } = params
        let columnConfig = localStorage.getItem('purchase_order_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
        var datasource = this.createDatasource(GridConfig.options)
        api.setServerSideDatasource(datasource)
    }

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                let columnConfig = localStorage.getItem('purchase_order_grid')
                if (columnConfig) {
                    this.onGridChanged(params)
                }

                var payload = {
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
                }
                this.getList(payload).then((data) => {
                    if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.data, data.total)
                    let columnConfig = localStorage.getItem('purchase_order_grid')
                    if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                        if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
                            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
                        }
                    }
                })
            }
        }
    }

    getList = (payload = {}) => {
        return Axios.post(`back-office/po/list/`, payload).then(({ data }) => {
            this.list_data = data.data
            this.total = data.total
            this.current_page = data.current_page
            return data
        })
    }

    ReadPurchaseOrder = (recordID) => {
        return Axios.get(`purchaseorder/read/${recordID}`).then(({ data }) => {
            this.purchaseOrderDetails = data.data
            return data
        }).catch(() => {
            this.read_data = null
            this.view_values = null
        })
    }
}

decorate(PurchaseOrderStore, {
    per_page: observable,
    current_page: observable,
    total: observable,
    agGrid: observable,
    purchaseOrderDetails: observable,
    setPageSize: action,
    handleReset: action,
    setupGrid: action,
    onGridChanged: action,
    createDatasource: action,
    getList: action,
    ReadPurchaseOrder: action,
})