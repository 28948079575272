import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import CloseExpand from '../assets/images/CloseExpand.svg'
import Expand from '../assets/images/Expand.svg' //

const TinyComponent = (props) => {
  const handleEditorChange = (e) => {
    if (props.editorChange) {
      props.editorChange(e);
    }
  }

  const defaultConfig = {
    selector: 'textarea#full-featured-non-premium',
    menubar: false,
    plugins:
      'print preview paste importcss searchreplace autolink  save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
    toolbar: 'undo redo | formatselect | ' +
      'bold italic backcolor | expandEditor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | ' +
      'removeformat | help | ' +
      'expandEditor',
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    statusbar: false,
    width: 470,
    setup: function (editor) {
      editor.ui.registry.addButton('expandEditor', {
        text: props.openModal ? `<img src=${CloseExpand} alt="Expand" title="Expand" class="toolicon"/>` : `<img src=${Expand} alt="Expand" title="Expand" class="toolicon"/>`,
        onAction: () => { props.openModal ? props.close() : props.openExpandModal(editor) },
      });
    }
  }

  return (
    <Editor
      apiKey='vi7ianft967i4q32oqeb31cr23lnx7n5swytlogxdqsubtto'
      onEditorChange={handleEditorChange}
      init={{ ...defaultConfig }}
      initialValue={props.editorvalue}
      onPaste={handleEditorChange}
      disabled={props.view}
      key={props.resetContent}
    />
  );
}

export default TinyComponent;
