import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Drawer, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import moment from 'moment';
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = useForm()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [relatedTo, setRelatedTo] = useState(null)
	const [mapping, setMapping] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [orgId, setOrgId] = useState(null)
	const [relatedOrgId, setRelatedOrgId] = useState(null)
	const [roleId, setRoleId] = useState(null)
	const [relatedRoleId, setRelatedRoleId] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const { OrgBankAccountStore, OrganisationStore, AllTransactionStore, PeopleStore, AllTransactionStore: { AddData, getDependentList, getStatusList, getCsvModals } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		data.posted_on = moment(data.posted_on).format("YYYY-MM-DD")
		data.traded_on = moment(data.traded_on).format("YYYY-MM-DD")
		data.model = data.model ? data.model.filter(x => (x.model === true)) : null
		data.model = data.model && data.model.map((item) => ({ model: item.id, field: item.field }));
		setSaving(true)
		AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to refresh
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			getStatusList()
			store.bank.getCurrencyList()
			setLoading(false)
		}
	}, [getStatusList, store.bank, props.visible])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		var role_id = form.getFieldValue("role_id")
		if (role_id !== roleId) {
			setRelatedOrgData(null)
			setOrgData(null)
			setOrgId(null)
			setRelatedOrgId(null)
			setRelatedRoleId(null)
			await form.resetFields(['entity_id', 'related_role_id', 'related_entity_id'])
		}

		var entity_id = form.getFieldValue("entity_id")
		if ((orgId !== entity_id)) { await form.resetFields(['related_role_id', 'related_entity_id']) }

		var related_entity_type = form.getFieldValue("related_entity_type")
		if (relatedTo !== related_entity_type) {
			await form.resetFields(['related_role_id', 'related_entity_id'])
			await setRelatedTo(related_entity_type)
			setRelatedOrgData(null)
		}

		var related_role_id = form.getFieldValue("related_role_id")
		if ((related_role_id !== relatedRoleId)) { await form.resetFields(['related_entity_id']) }

		var related_entity_id = form.getFieldValue("related_entity_id")

		var related_entity_type_payload = (related_entity_type === "People") ? 2 : (related_entity_type === "Ledger") ? 3 : 1

		if (role_id && entity_id && related_role_id && related_entity_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || related_role_id !== relatedRoleId || (relatedOrgId !== related_entity_id) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_role_id: related_role_id, related_entity_id: related_entity_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id && related_role_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || (related_role_id !== relatedRoleId) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_role_id: related_role_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id && related_entity_type &&
			(role_id !== roleId || (orgId !== entity_id) || (relatedTo !== related_entity_type))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id, related_entity_type: related_entity_type_payload })
		} else if (role_id && entity_id &&
			(role_id !== roleId || (orgId !== entity_id))
		) {
			getDependentList({ role_id: role_id, entity_id: entity_id })
		} else if (role_id && role_id !== roleId) {
			getDependentList({ role_id: role_id })
		}

		if (entity_id && (orgId !== entity_id)) {
			OrganisationStore.getOrgCardValue(entity_id, "org").then((data) => {
				setOrgData(data)
			})
		}

		if (related_entity_id && (relatedOrgId !== related_entity_id)) {
			if (related_entity_type === "People") {
				PeopleStore.getPeopleCardValue(related_entity_id, 'people').then((data) => {
					setRelatedOrgData(data)
				})
			} else {
				OrganisationStore.getOrgCardValue(related_entity_id, "relatedOrg").then((data) => {
					setRelatedOrgData(data)
				})
			}
		}

		var mapping_id = form.getFieldValue("type_field_mapping_id")
		if (mapping_id && (mapping_id !== mapping)) {
			setMapping(mapping_id)
			getCsvModals(mapping_id, "add").then((data) => {
				setCsvColumn(data.fieldList)
				form.setFieldsValue({ csv_columns: data.fieldList })
			})
		}

		setOrgId(entity_id)
		setRelatedOrgId(related_entity_id)
		setRoleId(role_id)
		setRelatedRoleId(related_role_id)

		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		})
	}

	// Reset form and close add form
	const close = () => {
		props.close()
		setDisabled(true)
		setRelatedTo(null)
		setOrgData(null)
		setRelatedOrgData(null)
		setRoleId(null)
		setRelatedRoleId(null)
		setCsvColumn(null)
		setMapping(null)
		AllTransactionStore.csvModals = null
		PeopleStore.rPeopleCardValue = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Add Trade Transaction`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="addform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="addform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout="vertical"
				>
					<FormFieldComponent
						form={form}
						onChange={handleChange}
						tab={"add"}
						entity_type={1}
						related_to={relatedTo}
						orgData={orgData}
						relatedOrgData={relatedOrgData}
						roleId={roleId}
						relatedRoleId={relatedRoleId}
						csvColumn={csvColumn}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default AddComponent
