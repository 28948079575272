import React from 'react'
import { EyeOutlined, DownloadOutlined, CloseCircleOutlined, ImportOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { DateComparator } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { UserStore, ImportStore } = useStore()
	const { openViewDrawer, openCancleImport, openDeleteImport } = props.agGridReact.props.frameworkComponents
	var disabled = [6, 7].includes(props.data.status)
	var deleteButton = [1, 5].includes(props.data.status)
	return (
		<div className="action-column">
			{UserStore.checkPermission(32, 'view') && (
				<Button title="View Logs" type="text" onClick={() => openViewDrawer(props.data.id)} ><EyeOutlined className={props.data.error_count_in_models === 0 && props.data.error_count_in_data === 0 ? null : "redVerifyIcon"} /></Button>
			)}

			{UserStore.checkPermission(22, 'list') && (
				<Button title="Go to Imported Transactions" type="text"><Link to={`/trade-transactions/all-transactions/${props.data.id}`}><ImportOutlined /></Link></Button>
			)}

			<Button title="Download Import File with Log" type="text" onClick={() => ImportStore.downloadFile(props.data, 'trade')}><DownloadOutlined /></Button>

			{(UserStore.checkPermission(32, 'import_delete') || UserStore.checkPermission(32, 'import_cancel')) && (
				!disabled && (
					<>
						{deleteButton ?
							(UserStore.checkPermission(32, 'import_delete') && (
								<Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openDeleteImport(props.data) }}><DeleteOutlined /></Button>
							))
							:
							(UserStore.checkPermission(32, 'import_cancel') && (
								<Button title="Cancel Import or Remove All Imported Transactions" type="text" onClick={() => { openCancleImport(props.data) }}><CloseCircleOutlined /></Button>
							))}
					</>
				))}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '#Id',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Trade Transaction Name',
		field: 'type_field_mapping.mapping_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Import Name',
		field: 'import_name',
		tooltipField: 'import_name',
		width: 220
	}, {
		headerName: 'No of Transactions',
		field: 'total_transactions',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 80
	}, {
		headerName: 'Imported By',
		field: 'imported_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Imported On',
		field: 'created_at',
		filter: 'agDateColumnFilter',
		width: 185,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		},
	}, {
		headerName: 'Status',
		field: 'status_name',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Duration',
		field: 'run_time',
		cellRendererFramework: function (data) {
			return (
				data.data && (
					<div>
						<span>{data.data.run_time}</span>
						{data.data.exception && (
							<Tooltip title={data.data.exception}>
								<InfoCircleOutlined className="ml-10" size="small" />
							</Tooltip>
						)}
					</div>)
			)
		},
		width: 80
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 150
	}],
}
