import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Form, Button, Drawer, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import FormFieldComponent from './FormFields'
import useStore from '../../../../store'
import { vsmAllTransactions, vsmNotify } from '../../../../config/messages'
import moment from 'moment'

const EditComponent = observer((props) => {
	const [form] = useForm()
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)
	const [disabled, setDisabled] = useState(true)
	const [editValues, setEditValues] = useState(null)
	const [orgData, setOrgData] = useState(null)
	const [relatedTo, setRelatedTo] = useState(null)
	const [entityType, setEntityType] = useState(null)
	const [relatedOrgData, setRelatedOrgData] = useState(null)
	const [csvColumn, setCsvColumn] = useState(null)
	const { OrgBankAccountStore, OrganisationStore, AllTransactionStore, AllTransactionStore: { EditData,
		getRecordById, getCsvModals, setStatus, setVatRate, setMappingList,
		setDependent } } = useStore()

	const store = {
		bank: OrgBankAccountStore,
		organisation: OrganisationStore
	}

	// Handle submit and call function to save edited record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmAllTransactions.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			getRecordById({ id: props.transactionId }).then((data) => {
				setEditValues(data.data)
				setDependent(data.data)
				setMappingList([data.data.type_field_mapping])
				setStatus([data.data.status])
				setVatRate([data.data.tax_setting])
				setLoading(false)
			}).catch((data) => {
				vsmNotify.error({ message: vsmAllTransactions.InvalidRecord })
				setLoading(false)
			})
		}
	}, [props.visible, props.transactionId, getRecordById, setDependent, setMappingList, setStatus, setVatRate, store.organisation])

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			getCsvModals(editValues.type_field_mapping_id, "view", editValues.selected_models)
			store.bank.setCurrency([editValues.global_currency])
			form.setFieldsValue({
				role_id: editValues.role_id,
				related_role_id: editValues.related_role_id,
				entity_id: editValues.entity_id ? editValues.entity_id : null,
				related_entity_type: editValues.related_entity_type,
				related_entity_id: editValues.related_entity_id ? editValues.related_entity_id : null,
				type_field_mapping_id: editValues.type_field_mapping_id,
				currency_id: parseInt(editValues.currency_id),
				face_value: editValues.face_value.replace(/,/g, ''),
				status_id: editValues.status_id,
				tax_settings_id: editValues.tax_settings_id,
				posted_on: editValues.posted_on && moment(editValues.posted_on),
				traded_on: editValues.traded_on && moment(editValues.traded_on),
				csv_columns: editValues.csv_columns,
				model: editValues.selected_models
			})
			setCsvColumn(editValues.csv_columns)
			setEntityType(editValues.entity_type)
			setRelatedTo(editValues.related_entity_type)
			setOrgData(editValues.entity_type === 2 ? editValues.peoples : editValues.organisations)
			setRelatedOrgData(editValues.related_entity_type === "People" ? editValues.related_peoples : editValues.related_organisations)
		}
	}, [getCsvModals, editValues, OrganisationStore, store.bank, props.visible, form])

	// Check for valid form values then accordingly made save button disable / enable
	const handleChange = async () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			} else {
				setDisabled(false)
			}
		})
	}

	// Reset form and close edit form
	const close = () => {
		props.close()
		props.setId(null)
		setEditValues(null)
		setDisabled(true)
		setCsvColumn(null)
		setEntityType(null)
		setRelatedTo(null)
		setRelatedOrgData(null)
		setOrgData(null)
		AllTransactionStore.csvModals = null
		form.resetFields()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Edit Trade Transaction - #${props.transactionId}`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form="editform" loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<Spin size="large" spinning={loading} tip={"Loading"}>
				<Form
					form={form} id="editform"
					onChange={handleChange}
					onFinish={handleSubmit}
					layout="vertical"
				>
					<FormFieldComponent
						form={form}
						onChange={handleChange}
						currentValues={editValues}
						tab={"edit"}
						entity_type={entityType}
						related_to={relatedTo}
						orgData={orgData}
						relatedOrgData={relatedOrgData}
						csvColumn={csvColumn}
					/>
				</Form>
			</Spin>
		</Drawer>
	)
})

export default EditComponent
