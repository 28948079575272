import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from '../../../config/LocalGridConfig'

export default class ImportStore {
	list_data = null
	view_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0

	dropdown_list = null
	viewLogValues = null
	modal_list = null
	dbField_list = null
	deleteValues = null
	logValues = null

	checkBox_dropdown = [
		{ id: 0, text: "Current Date", value: "Current Date" },
		{ id: 1, text: "Issued On", value: "Issued On" },
		{ id: 2, text: "Posted On", value: "Posted On" },
		{ id: 3, text: "Traded On", value: "Traded On" },
		{ id: 4, text: "Settled On", value: "Settled On" },
		{ id: 5, text: "Closed On", value: "Closed On" },
		{ id: 6, text: "Forwarded On", value: "Forwarded On" }
	]

	setViewLogValues = (data) => {
		this.viewLogValues = data
	}

	setLogValues = (data) => {
		this.logValues = data
	}

	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	setModalList = (data) => {
		this.modal_list = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('transaction_import_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('transaction_import_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// check delete or cancle
	getCheck = (id) => {
		return Axios.get(`trade/transactionsimport/checkImportStatus/${id}`).then(({ data }) => {
			return data.data
		})
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('transaction_import_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('transaction_import_grid', columnConfig)
		}
	}

	downloadFile = (data, type) => {
		return Axios.post(`transaction/import/file-link/get`, { import_id: data.id, type }).then(({ data }) => {
			if (data.data && data.data.csv_link) { window.open(data.data.csv_link, '_blank'); }
			return data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.get(`trade/transactionsimport/list`).then(({ data }) => {
			this.list_data = data.data

			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (item.status === 1) {
						item.status_name = "Import Successfully"
					} else if (item.status === 2) {
						item.status_name = "Partial Import"
					} else if (item.status === 3) {
						item.status_name = "Pending"
					} else if (item.status === 4) {
						item.status_name = "In Process"
					} else if (item.status === 5) {
						item.status_name = "Failed"
					} else if (item.status === 6) {
						item.status_name = "Cancelled"
					} else {
						item.status_name = "Deleted"
					}
					item.run_time = item.run_time ? item.run_time : null
					item.imported_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('algorithm_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid && this.agGrid.columnApi && data.total) {
					this.agGrid.columnApi.applyColumnState({ state: sort })
				}
			}
			if (this.agGrid && this.agGrid.api) {
				this.agGrid.api.setFilterModel(filter)
			}
		})
	}

	// call to get record particular ID
	getRecordById = (id) => {
		return Axios.get(`trade/transactionsimport/read/${id}`).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			return Promise.reject(data)
		})
	}

	// call api to get record log
	getViewLogs = (id) => {
		return Axios.get(`trade/transactionsimport/view-logs/${id}`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.error = item.error_in_model ? "Error" : "Valid"
				});
			}
			this.view_data = data.data
			return data
		})
	}

	getLogDetails = (id) => {
		return Axios.get(`trade/transaction/log/get/${id}`).then(({ data }) => {
			this.logValues = data.data === "Created" ? "Created" : JSON.parse(data.data)
			return data
		})
	}

	// call api to get model from mapping field
	getSelectedModels = (formdata) => {
		return Axios.get(`trade/transactions/modelandfield/get/${formdata.id}`).then(({ data }) => {
			var i;
			var arrayObj = data.data.model
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['id'];
				arrayObj[i].label = arrayObj[i]['model_name'];
				delete arrayObj[i].key1;
			}
			this.modal_list = arrayObj
			this.dbField_list = data.data
			return this.dbField_list
		})
	}

	// call api to get all mapping list
	getAllMappingList = () => {
		Axios.get(`trade/transactions/mapping/get`).then(({ data }) => {
			this.dropdown_list = data.data
			return data;
		})
	}

	// call excecute transactions api
	executeTransactions = (formdata) => {
		return Axios.post(`trade/transactionsimport/add`, formdata).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	CancelData = (formdata) => {
		return Axios.get(`trade/transactionsimport/cancleTransaction/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`trade/transactionsimport/delete/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	deleteMapping = (formdata) => {
		return Axios.post(`import/deleteByMapping/`, formdata).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(ImportStore, {
	dropdown_list: observable,
	viewLogValues: observable,
	list_data: observable,
	total: observable,
	agGrid: observable,
	current_page: observable,
	per_page: observable,
	modal_list: observable,
	dbField_list: observable,
	view_data: observable,
	logValues: observable,
	deleteValues: observable,
	checkBox_dropdown: observable,
	getAllMappingList: action,
	executeTransaction: action,
	getList: action,
	setupGrid: action,
	setPageSize: action,
	setViewLogValues: action,
	setDeleteValues: action,
	setLogValues: action,
	getSelectedModels: action,
	setModalList: action,
	getViewLogs: action,
	DeleteData: action,
	CancelData: action,
	getLogDetails: action,
	getRecordById: action,
	getCheck: action,
	onGridChanged: action,
	onChildnGridChanged: action,
	downloadFile: action
})
