import { observer } from 'mobx-react';
import React from 'react';

const OriginatorDashboard = observer(() => {
    return (
        <div>
            OriginatorDashboard
        </div>
    );
})

export default OriginatorDashboard;