import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { Col, Divider, Form, Row, Spin } from 'antd'
import InputComponent from '../../../../components/InputComponent'
import { vsmTransactionReport } from '../../../../config/messages'
import useStore from '../../../../store'
import TradeIdElement from '../elements/TradeIdElement'
import FieldValuesElement from '../elements/FieldValuesElement'
import TransactionDateElement from '../elements/TransactionDateElement'
import DateSelectionElement from '../elements/DateSelectionElement'
import PeriodSelectionElement from '../elements/PeriodSelectionElement'
import CombinedReportsElement from '../elements/CombinedReportsElement'
import BankRefElement from '../elements/BankRefElement'
import CustomerRefElement from '../elements/CustomerRefElement'
import OrgPeopleBankIBANElement from '../elements/OrgPeopleBankIBANElement'
import OrgPeopleBankAccNoElement from '../elements/OrgPeopleBankAccNoElement'
import moment from 'moment'
import debounce from 'lodash/debounce'

const FormComponent = observer(({ defaultFormDataList, form, formDataList, setFormDataList, initialValues, setDisabled, handleSubmit, formButtons }) => {
	const typeChange = ['generate_on', 'journal_type', 'group_on', 'currency', 'main_entity', 'related_entity']
	const { TransactionFieldMappingStore, TransactionTypeStore, SavedReportStore, TransactionModelStore, TransactionFieldStore, LedgerBankAccountStore, CTEImportStore, LedgerDetailStore, ClassificationStore, RoleMappingStore, OrganisationStore, OrgBankAccountStore, OrgStatusStore, PeopleStore } = useStore()
	const cashChange = ['main_entity', 'related_entity', 'cte_banks', 'cte_bank_accounts', 'cte_classification', 'cte_transaction_type', 'cte_currency', 'cte_tags', 'cte_exchange_id', 'cte_bank_ref', 'cte_customer_ref', 'org_people_bank_iban', 'org_people_bank_acc_no', 'generate_on', 'additional_columns']
	const org_ppl_Change = ['exchange_ref_id', 'bank_name', 'account_type', 'currency_id', 'bank_status', 'account_type', 'account_number_iban']

	const [DateRange, SetDateRange] = useState([]);
	const [DPREPORTS, SETDPREPORTS] = useState(false)
	const [BASEDONMODEL, SETBASEDONMODEL] = useState(false)
	const [BASEDONMAPPING, SETBASEDONMAPPING] = useState(false)
	const [BASEDONTRANSTYPE, SETBASEDONTRANSTYPE] = useState(false)
	const [BASEDONJOURNAL, SETBASEDONJOURNAL] = useState(false)
	const [GENERATEONFINANCIAL, SETGENERATEONFINANCIAL] = useState(false)
	const [CASHBANKACCOUNT, SETCASHBANKSACCOUNT] = useState(false)
	const [CASHBANKS, SETCASHBANKS] = useState(false)
	const [CASHCURRENCY, SETCASHCURRENCY] = useState(false)
	const [CASHTRANSACTIONTYPE, SETCASHTRANSACTIONTYPE] = useState(false)
	const [CASHCLASSIFICATION, SETCASHCLASSIFICATION] = useState(false)
	const [CASHTAGS, SETCASHTAGS] = useState(false)
	const [FIELDVALUES, SETFIELDVALUES] = useState(false)
	const [ASSOCIATEDLEDGER, SETASSOCIATEDLEDGER] = useState(false)
	const [GETCOUNTRY, SETGETCOUNTRY] = useState(false)
	const [BANKNAME, SETBANKNAME] = useState(false)
	const [ACCOUNTTYPE, SETACCOUNTTYPE] = useState(false)
	const [CURRENCY, SETCURRENCY] = useState(false)
	const [ORGSTATUS, SETORGSTATUS] = useState(false)
	const [TRADENAME, SETTRADENAME] = useState(false)
	const [TITLE, SETTITLE] = useState(false)
	const [PPSNUMBER, SETPPSNUMBER] = useState(false)
	const [searchOrgKey, setSearchOrgKey] = useState()
	const [searchPplKey, setSearchPplKey] = useState()

	const disabledDate = current => {
		if (!DateRange || DateRange.length === 0) { return false }
		return moment(current).format("Y-M-D") === moment(DateRange[0]).format("Y-M-D")
	}

	const getFormDataList = useCallback(() => {
		var FormDataList = defaultFormDataList
		switch (form.getFieldValue('report_type')) {
			case 'trade':
				FormDataList = [...FormDataList, 'type']
				switch (form.getFieldValue('type')) {
					case 1: // global
						FormDataList = [...FormDataList, ...typeChange]
						break;
					case 2: // single
						FormDataList = [...FormDataList, ...typeChange, 'single_based_on']
						switch (form.getFieldValue('single_based_on')) {
							case 'single_model':
								FormDataList = [...FormDataList, 'single_model']
								if (!BASEDONMODEL) {
									SETBASEDONMODEL(true)
									SavedReportStore.getAllModels()
								}
								break;
							case 'single_mapping':
								FormDataList = [...FormDataList, 'single_mapping']
								if (!BASEDONMAPPING) {
									SETBASEDONMAPPING(true)
									TransactionFieldMappingStore.getAllRecords()
								}
								break;
							case 'single_transaction_type':
								FormDataList = [...FormDataList, 'single_transaction_type']
								if (!BASEDONTRANSTYPE) {
									SETBASEDONTRANSTYPE(true)
									TransactionTypeStore.getAllRecords()
								}
								break;
							case 'single_journal':
								FormDataList = [...FormDataList, 'single_journal']
								if (!BASEDONJOURNAL) {
									SETBASEDONJOURNAL(true)
									TransactionModelStore.getAllJournalsList()
								}
								break;
							default:
								FormDataList = [...FormDataList, 'single_transaction']
						}
						break;
					case 3: // multiple
						FormDataList = [...FormDataList, ...typeChange, 'multiple_based_on']
						switch (form.getFieldValue('multiple_based_on')) {
							case 'multiple_model':
								FormDataList = [...FormDataList, 'multiple_model']
								if (!BASEDONMODEL) {
									SETBASEDONMODEL(true)
									SavedReportStore.getAllModels()
								}
								break;
							case 'multiple_mapping':
								FormDataList = [...FormDataList, 'multiple_mapping']
								if (!BASEDONMAPPING) {
									SETBASEDONMAPPING(true)
									TransactionFieldMappingStore.getAllRecords()
								}
								break;
							case 'multiple_transaction_type':
								FormDataList = [...FormDataList, 'multiple_transaction_type']
								if (!BASEDONTRANSTYPE) {
									SETBASEDONTRANSTYPE(true)
									TransactionTypeStore.getAllRecords()
								}
								break;
							case 'multiple_journal':
								FormDataList = [...FormDataList, 'multiple_journal']
								if (!BASEDONJOURNAL) {
									SETBASEDONJOURNAL(true)
									TransactionModelStore.getAllJournalsList()
								}
								break;
							default:
								FormDataList = [...FormDataList, 'multiple_transaction']
						}
						break;
					case 4: // complex
						FormDataList = [...FormDataList, ...typeChange, 'transaction_type', 'journals', 'transaction_mappings', 'transaction_ids', 'field_values', 'transaction_dates']
						if (!BASEDONJOURNAL) { SETBASEDONJOURNAL(true); TransactionModelStore.getAllJournalsList() }
						if (!BASEDONTRANSTYPE) { SETBASEDONTRANSTYPE(true); TransactionTypeStore.getAllRecords() }
						if (!BASEDONMAPPING) { SETBASEDONMAPPING(true); TransactionFieldMappingStore.getAllRecords() }
						if (!BASEDONMODEL) { SETBASEDONMODEL(true); SavedReportStore.getAllModels() }
						if (!FIELDVALUES) { SETFIELDVALUES(true); TransactionFieldStore.getAllRecord() }
						break;

					case 5: // combined_reports
						FormDataList = [...FormDataList, 'combined_reports']
						if (!DPREPORTS) {
							SETDPREPORTS(true)
							SavedReportStore.getAllRecords()
						}
						break;
					default:
				}
				switch (form.getFieldValue('type')) {
					case 1: // global
					case 2: // single
					case 3: // multiple
					case 4: // complex
						switch (form.getFieldValue('main_entity')) {
							case 'people':
								FormDataList = [...FormDataList, 'main_people_role', 'main_people']
								break;
							default:
								FormDataList = [...FormDataList, 'main_organisation_role', 'main_organisation']
						}

						switch (form.getFieldValue('related_entity')) {
							case 'people':
								FormDataList = [...FormDataList, 'related_people_role', 'related_people']
								break;
							default:
								FormDataList = [...FormDataList, 'related_organisation_role', 'related_organisation']
						}

						switch (form.getFieldValue('generate_on')) {
							case 'date_range':
								FormDataList = [...FormDataList, 'date_selection']
								switch (form.getFieldValue('date_selection')) {
									case 'range':
										FormDataList = [...FormDataList, 'date_range']
										break;
									case 'single':
										FormDataList = [...FormDataList, 'date']
										break;
									case 'auto':
										FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
										break;
									default:
								}
								break;
							case 'financial_year':
								FormDataList = [...FormDataList, 'financial_year']
								if (!GENERATEONFINANCIAL) {
									SETGENERATEONFINANCIAL(true)
									SavedReportStore.getFinancialYearList()
								}
								break;
							case 'periods':
								FormDataList = [...FormDataList, 'period_selection']
								switch (form.getFieldValue('period_selection')) {
									case 'custom':
										FormDataList = [...FormDataList, 'period_date_range']
										break;
									case 'auto':
										FormDataList = [...FormDataList, 'period_x_value', 'period_x_value_type']
										break;
									default:
								}
								break;
							default:
						}
						break;
					default:
				}
				break;
			case 'audit':
			case 'cash':
				FormDataList = [...FormDataList, ...cashChange]
				if (!BASEDONTRANSTYPE) { SETBASEDONTRANSTYPE(true); TransactionTypeStore.getAllRecords() }
				if (!CASHBANKS) { SETCASHBANKS(true); LedgerBankAccountStore.getBanksNameList() }
				if (!CASHBANKACCOUNT) { SETCASHBANKSACCOUNT(true); CTEImportStore.getBankList() }
				if (!CASHCURRENCY) { SETCASHCURRENCY(true); LedgerDetailStore.getAllCurrencyList() }
				if (!CASHTRANSACTIONTYPE) { SETCASHTRANSACTIONTYPE(true); SavedReportStore.getCashTransactionType() }
				if (!CASHCLASSIFICATION) { SETCASHCLASSIFICATION(true); ClassificationStore.getClassfications() }
				if (!CASHTAGS) { SETCASHTAGS(true); RoleMappingStore.getAllTagsList() }
				if (form.getFieldValue('report_type') === "audit") {
					FormDataList = [...FormDataList, 'audit_group_on']
				}
				switch (form.getFieldValue('main_entity')) {
					case 'people':
						FormDataList = [...FormDataList, 'main_people_role', 'main_people']
						break;
					default:
						FormDataList = [...FormDataList, 'main_organisation_role', 'main_organisation']
				}

				switch (form.getFieldValue('related_entity')) {
					case 'people':
						FormDataList = [...FormDataList, 'related_people_role', 'related_people']
						break;
					default:
						FormDataList = [...FormDataList, 'related_organisation_role', 'related_organisation']
				}
				switch (form.getFieldValue('generate_on')) {
					case 'date_range':
						FormDataList = [...FormDataList, 'date_selection']
						switch (form.getFieldValue('date_selection')) {
							case 'range':
								FormDataList = [...FormDataList, 'date_range']
								break;
							case 'single':
								FormDataList = [...FormDataList, 'date']
								break;
							case 'auto':
								FormDataList = [...FormDataList, 'date_x_value', 'date_x_value_type']
								break;
							default:
						}
						break;
					case 'financial_year':
						FormDataList = [...FormDataList, 'financial_year']
						if (!GENERATEONFINANCIAL) {
							SETGENERATEONFINANCIAL(true)
							SavedReportStore.getFinancialYearList()
						}
						break;
					default:
				}
				break;

			case 'people':
			case 'organisation':
				FormDataList = [...FormDataList, ...org_ppl_Change]
				if (!BANKNAME) { SETBANKNAME(true); OrgBankAccountStore.getBanksNameList() }
				if (!ACCOUNTTYPE) { SETACCOUNTTYPE(true); OrgBankAccountStore.getBankAccountTypeList() }
				if (!CURRENCY) { SETCURRENCY(true); OrgBankAccountStore.getCurrencyList() }
				switch (form.getFieldValue('account_number_iban')) {
					case 'iban':
						FormDataList = [...FormDataList, 'account_iban_number', 'swift_bic_code']
						break;
					default:
						FormDataList = [...FormDataList, 'account_iban_number', 'sort_code']
				}
				break;
			default:
		}
		switch (form.getFieldValue('report_type')) {
			case 'people':
				if (!TITLE) { SETTITLE(true); SavedReportStore.getTitleList() }
				if (!PPSNUMBER) { SETPPSNUMBER(true); SavedReportStore.getPPSNumberList() }
				FormDataList = [...FormDataList, 'people_name', 'title', 'pps_number']
				break;
			case 'organisation':
				FormDataList = [...FormDataList, 'organisation_name', 'trade_name', 'registration_country', 'associated_ledger', 'organisation_status',]
				if (!ASSOCIATEDLEDGER) { SETASSOCIATEDLEDGER(true); SavedReportStore.getAssociatedLedger() }
				if (!GETCOUNTRY) { SETGETCOUNTRY(true); OrganisationStore.getAllCountries() }
				if (!ORGSTATUS) { SETORGSTATUS(true); OrgStatusStore.getOrgStatus() }
				if (!TRADENAME) { SETTRADENAME(true); SavedReportStore.getTradeNameList() }
				break;
			default:
		}
		return FormDataList
	}, [defaultFormDataList, form, typeChange, DPREPORTS, BASEDONMODEL, BASEDONJOURNAL, BASEDONMAPPING, BASEDONTRANSTYPE, GENERATEONFINANCIAL, FIELDVALUES, CASHBANKS, CASHBANKACCOUNT, CASHCURRENCY, CASHTRANSACTIONTYPE, CASHCLASSIFICATION, CASHTAGS, ASSOCIATEDLEDGER, GETCOUNTRY, BANKNAME, ACCOUNTTYPE, CURRENCY, ORGSTATUS, TRADENAME, TITLE, PPSNUMBER, TransactionFieldMappingStore, SavedReportStore, TransactionModelStore, TransactionTypeStore, TransactionFieldStore, cashChange, LedgerBankAccountStore, CTEImportStore, LedgerDetailStore, ClassificationStore, RoleMappingStore, OrganisationStore, OrgBankAccountStore, OrgStatusStore, org_ppl_Change])

	const getOrgRelatedData = useCallback((data = {}) => {
		let main_entity = form.getFieldValue('main_entity')
		let related_entity = form.getFieldValue('related_entity')
		let payload = { main_entity, related_entity }
		if (main_entity === 'people') {
			let main_people_role = form.getFieldValue('main_people_role')
			main_people_role = (main_people_role && main_people_role.length > 0) ? main_people_role : null
			let main_people = form.getFieldValue('main_people')
			main_people = (main_people && main_people.length > 0) ? main_people : null
			payload = { ...payload, main_people_role, main_people }
		} else {
			let main_organisation_role = form.getFieldValue('main_organisation_role')
			main_organisation_role = (main_organisation_role && main_organisation_role.length > 0) ? main_organisation_role : null
			let main_organisation = form.getFieldValue('main_organisation')
			main_organisation = (main_organisation && main_organisation.length > 0) ? main_organisation : null
			payload = { ...payload, main_organisation_role, main_organisation }
		}
		if (related_entity === 'people') {
			let related_people_role = form.getFieldValue('related_people_role')
			related_people_role = (related_people_role && related_people_role.length > 0) ? related_people_role : null
			payload = { ...payload, related_people_role }
		} else {
			let related_organisation_role = form.getFieldValue('related_organisation_role')
			related_organisation_role = (related_organisation_role && related_organisation_role.length > 0) ? related_organisation_role : null
			payload = { ...payload, related_organisation_role }
		}
		payload = { ...payload, ...data }
		SavedReportStore.getDependentList(payload)
	}, [form, SavedReportStore])

	useEffect(() => {
		var data = getFormDataList()
		if (JSON.stringify(formDataList) !== JSON.stringify(data)) {
			setFormDataList(data)
		}
	}, [getFormDataList, setFormDataList, formDataList])

	useEffect(() => {
		if (SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.main_entity) {
			SavedReportStore.getMainRoles(SavedReportStore.previewReportValues.main_entity)
			let main_entity = SavedReportStore.previewReportValues.main_entity
			let related_entity = SavedReportStore.previewReportValues.related_entity
			let main_organisation = SavedReportStore.previewReportValues.main_organisation
			let main_organisation_role = SavedReportStore.previewReportValues.main_organisation_role
			let related_organisation_role = SavedReportStore.previewReportValues.related_organisation_role
			let main_people = SavedReportStore.previewReportValues.main_people
			let main_people_role = SavedReportStore.previewReportValues.main_people_role
			let related_people_role = SavedReportStore.previewReportValues.related_people_role
			getOrgRelatedData({ main_entity, related_entity, main_organisation, main_organisation_role, related_organisation_role, main_people, main_people_role, related_people_role })
		} else {
			if (!SavedReportStore.previewReportValues || (SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.type !== 5)) { // combined_reports
				SavedReportStore.getMainRoles()
				let main_entity = 'organisation'
				let related_entity = 'organisation'
				let main_organisation = [0]
				let main_organisation_role = [0]
				let related_organisation_role = [0]
				let main_people = [0]
				let main_people_role = [0]
				let related_people_role = [0]
				getOrgRelatedData({ main_entity, related_entity, main_organisation, main_organisation_role, related_organisation_role, main_people, main_people_role, related_people_role })
			}
		}
	}, [SavedReportStore, getOrgRelatedData])



	const handleChange = async () => {
		await setFormDataList(getFormDataList())
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const getPeopleRole = () => {
		if (!SavedReportStore.main_people_roles && form.getFieldValue('main_entity') === 'people') {
			SavedReportStore.getMainRoles(form.getFieldValue('main_entity'))
		}
	}

	const onFinish = (data) => {
		data.form_list = formDataList
		data = SavedReportStore.formatFormData({ ...SavedReportStore.previewReportValues, ...data })

		data.action = "save_report"
		handleSubmit(data)
	}

	const handleSearch = useMemo(() => {
		const loadOptions = (val) => {
			setSearchPplKey(val);
			PeopleStore.getAllPeople({ search_for: val, mapping_type: 2 })
		};

		return debounce(loadOptions, 500);
	}, [PeopleStore])

	// search string for dropdown
	const handleSearchOrg = useMemo(() => {
		const loadOptions = (val) => {
			setSearchOrgKey(val);
			OrganisationStore.getOrgCurrentLedgerMap({ search_for: val, related_entity_type: 1 })
		};

		return debounce(loadOptions, 500);
	}, [OrganisationStore])

	return (
		<Form
			form={form}
			layout='vertical'
			initialValues={initialValues}
			onFinish={onFinish}
			id='addeditform'
		>
			{(formDataList.includes('report_type')) && (
				<Row>
					<Col span={18} md={{ span: 15 }}>
						<InputComponent
							disabled={SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.id}
							required type="radio_button" name="report_type"
							onChange={async (data) => {
								if (form.getFieldValue('generate_on') === 'periods') {
									await form.setFields([{ name: "generate_on", value: "all_unarchive_data" }])
								}
								handleChange(data)
							}}
							options={{
								values: [{ value: 'trade', text: 'Trade' }, { value: 'cash', text: 'Cash' }, { value: 'audit', text: 'Audit' }, { value: 'organisation', text: 'Organisation' }, { value: 'people', text: 'People' }]
							}}
							rules={vsmTransactionReport.validation.type}
						/>
					</Col>
				</Row>
			)}

			<Row gutter={24}>
				<Col span={12}>
					{(formDataList.includes('name')) && (
						<InputComponent
							required label="Name" name="name" placeholder="Report Name"
							onChange={handleChange}
							tooltip="A Report Name refers to the specific report created by the user."
							rules={vsmTransactionReport.validation.name}
							maxLength={100}
							autoComplete="off"
						/>
					)}
					{(formDataList.includes('code')) && (
						<InputComponent
							required label="Code" name="code" placeholder="Report Code"
							onChange={handleChange}
							tooltip="A Report Code refers to the unique code assigned to any specific report."
							rules={vsmTransactionReport.validation.code}
							maxLength={10}
						/>
					)}
				</Col>
				<Col span={12}>
					{(formDataList.includes('description')) && (
						<InputComponent
							className="default_size_textarea"
							required type="textarea" label="Description" name="description" placeholder="Report Description"
							onChange={handleChange}
							tooltip="Description is the information stored for the specific report generated."
							rules={vsmTransactionReport.validation.description}
							maxLength={1000}
						/>
					)}
				</Col>
			</Row>

			<Row gutter={24}>
				{(formDataList.includes('people_name')) && (
					<Col span={12}>
						<InputComponent
							required
							type="select"
							label="People Name"
							name="people_name"
							placeholder="People Name"
							notFoundContent={PeopleStore.fetching ? <Spin size="small" /> : searchPplKey ? "No Record Found." : null}
							onSearch={handleSearch}
							onChange={handleChange}
							options={{
								values: PeopleStore.drowpdown_people_list,
								value_key: 'id',
								text_key: 'entity_name',
							}}
							tooltip="A personal Name that is given to user/ person at birth or baptism and used along with family name."
						/>
					</Col>
				)}
				{(formDataList.includes('title')) && (
					<Col span={12}>
						<InputComponent
							required
							type="select"
							label="Title"
							name="title"
							placeholder="Title"
							onChange={handleChange}
							options={{
								values: SavedReportStore.title_list,
								value_key: 'id',
								text_key: 'title',
							}}
							tooltip="A title can describe the responsibilities of the position."
						/>
					</Col>
				)}
			</Row>

			<Row gutter={24}>
				{(formDataList.includes('pps_number')) && (
					<Col span={12}>
						<InputComponent
							required
							type="select"
							label="PPS Number"
							name="pps_number"
							placeholder="PPS Number"
							onChange={handleChange}
							options={{
								values: SavedReportStore.ppsNumber_list,
								value_key: 'id',
								text_key: 'pps_number',
							}}
							tooltip="A Personal Public Service (PPS) Number is a unique reference number that helps you access social welfare benefits, public services and information in Ireland. A PPS Number is always 7 numbers followed by either one or two letters. You can use your PPS Number to access public services in Ireland."
						/>
					</Col>
				)}
			</Row>

			<Row gutter={24}>
				<Col span={12}>
					{(formDataList.includes('organisation_name')) && (
						<InputComponent
							required type="select" allowClear label="Organisation Name" name="organisation_name" placeholder="Organisation Name"
							onChange={(data) => {
								handleChange(data)
							}}
							options={{
								values: OrganisationStore.dropdown_ledger_org_list,
								value_key: 'id',
								text_key: 'entity_name',
							}}
							tooltip="Name is one of the basic parameters of an Organisation, describing primary name of a given Organisation (usually it's the Company's legal name)."
							filterOption={false}
							notFoundContent={OrganisationStore.fetching ? <Spin size="small" /> : (searchOrgKey) ? "No Record Found!" : null}
							onSearch={handleSearchOrg}
						/>
					)}
				</Col>

				<Col span={12}>
					{(formDataList.includes('trade_name')) && (
						<InputComponent
							name='trade_name'
							label='Trade Name'
							placeholder='Trade Name'
							onChange={handleChange}
							tooltip="Organisation Trade name is a trading name commonly used by an Organisation to perform business activities."
							type="select"
							options={{
								values: SavedReportStore.tradeName_list,
								value_key: "id",
								text_key: "trade_name",
							}}
						/>
					)}
				</Col>
			</Row>

			<Row gutter={24}>
				<Col span={12}>
					{(formDataList.includes('registration_country')) && (
						<InputComponent
							name='registration_country'
							label='Registration Country'
							placeholder='Registration Country'
							onChange={handleChange}
							tooltip="A company register is a register of legal entities in the jurisdiction they operate under, for the purpose of protection, accountability and control of legal entities."
							type="select"
							options={{
								values: OrganisationStore.dropdown_countries_list,
								value_key: 'id',
								text_key: { key: ["iso_code_alpha2", " - ", "country_name"] },
							}}
						/>
					)}
				</Col>
				<Col span={12}>
					{(formDataList.includes('associated_ledger')) && (
						<InputComponent
							name='associated_ledger'
							label='Associated Ledger'
							placeholder='Associated Ledger'
							onChange={handleChange}
							tooltip="Organisation Trade name is a trading name commonly used by an Organisation to perform business activities."
							type="select"
							options={{
								values: SavedReportStore.associated_ledger,
								value_key: "id",
								text_key: "organisation_name",
							}}
						/>
					)}
				</Col>
			</Row>

			<Row gutter={24}>
				{(formDataList.includes('exchange_ref_id')) && (
					<Col span={12}>
						<InputComponent
							label="Exchange Reference Id"
							name="exchange_ref_id" placeholder="Exchange Reference Id"
							tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
							onChange={handleChange}
							rules={vsmTransactionReport.validation.cte_exchange_id}
						/>
					</Col>
				)}

				{(formDataList.includes('bank_name')) && (
					<Col span={12}>
						<InputComponent
							name='bank_name'
							label='Bank Name'
							placeholder='Bank Name'
							onChange={handleChange}
							tooltip="Bank Name (for a Bank Account) is the name of a banking or cash processing organisation that operates a specific bank account."
							allowClear
							type="autocomplete"
							options={OrgBankAccountStore.bankNameList}
						/>
					</Col>
				)}
			</Row>

			<Row gutter={24}>
				{(formDataList.includes('account_type')) && (
					<Col span={12}>
						<InputComponent
							type="select"
							allowClear
							label="Account Type"
							name="account_type"
							placeholder="Select Account Type"
							onChange={handleChange}
							options={{
								values: OrgBankAccountStore.dropdown_bank_type_list,
								value_key: 'id',
								text_key: 'name'
							}}
							tooltip="A name or code given to a cash account that indicates the account's purpose."
						/>
					</Col>
				)}

				{(formDataList.includes('currency_id')) && (
					<Col span={12}>
						<InputComponent
							type="select"
							allowClear
							label="Currency"
							name="currency_id"
							placeholder="Currency"
							onChange={handleChange}
							options={{
								values: OrgBankAccountStore.dropdown_currency_list,
								value_key: 'id',
								text_key: 'currency_name'
							}}
							tooltip="A name or code given to a cash account that indicates the account's purpose."
						/>
					</Col>
				)}
				<Col span={12}></Col>
			</Row>

			{(formDataList.includes('account_number_iban')) && (
				<InputComponent
					type="radio_button" name="account_number_iban" initialValue="account"
					options={{
						values: [
							{ value: 'account', text: 'Account Number' },
							{ value: 'iban', text: 'IBAN Number' }
						]
					}}
					onChange={() => {
						handleChange()
						form.resetFields(['account_iban_number', 'sort_code', 'swift_bic_code'])
					}}
				/>
			)}

			<Row gutter={24}>
				{(formDataList.includes('account_iban_number')) && (
					<Col span={12}>
						<InputComponent
							label="Account Number/IBAN" name="account_iban_number" placeholder="Account Number/IBAN" onChange={handleChange}
							tooltip="Account Number is a short version of the cash account number (alternative to or part of IBAN)."
							// rules={vsmTransactionReport.validation.account_num}
							maxLength={50}
						/>
					</Col>
				)}
				{(formDataList.includes('sort_code')) && (
					<Col span={12}>
						<InputComponent
							label="Sort Code" name="sort_code" placeholder="Sort Code" onChange={handleChange}
							tooltip="A Sort Code is a number code, which is used by various banks (i.e. in Ireland, United Kingdom, etc.)."
							// rules={vsmTransactionReport.validation.sort_code}
							maxLength={50}
						/>
					</Col>
				)}

				{(formDataList.includes('swift_bic_code')) && (
					<Col span={12}>
						<InputComponent
							label="Swift/BIC Code" name="swift_bic_code" placeholder="Swift/BIC Code" onChange={handleChange}
							tooltip="A SWIFT code is an international bank code that identifies particular banks worldwide. It's also known as a Bank Identifier Code (BIC)."
							// rules={vsmTransactionReport.validation.swift_or_bic_code}
							maxLength={50}
						/>
					</Col>
				)}
			</Row>

			<Row gutter={24}>
				{(formDataList.includes('organisation_status')) && (
					<Col span={12}>
						<InputComponent
							type="select"
							allowClear
							label="Organisation Status"
							name="organisation_status"
							placeholder="Organisation Status"
							onChange={handleChange}
							options={{
								values: OrgStatusStore.orgStatusList,
								value_key: "id",
								text_key: "status_name"
							}}
							tooltip="Status would define whether that particular status is active or inactive."
						/>
					</Col>
				)}
				{(formDataList.includes('bank_status')) && (
					<Col span={12}>
						<InputComponent
							type="select"
							allowClear
							label="Bank Status "
							name="bank_status"
							placeholder="Bank Status "
							onChange={handleChange}
							options={{
								values: [{ value: '1', text: 'Active' }, { value: '0', text: 'Deactive' }]
							}}
							tooltip="Status would define whether that particular status is active or inactive."
						/>
					</Col>
				)}
			</Row>

			{(formDataList.includes('type')) && (
				<InputComponent
					disabled={SavedReportStore.previewReportValues && SavedReportStore.previewReportValues.id}
					required type="radio_button" label="Type" name="type"
					onChange={handleChange}
					options={{
						values: [{ value: 1, text: 'Global' }, { value: 2, text: 'Single' }, { value: 3, text: 'Multiple' }, { value: 4, text: 'Complex' }, { value: 5, text: 'Combined Reports' }]
					}}
					tooltip="Type is one of the parameter of the reports to add further options of report."
					rules={vsmTransactionReport.validation.type}
				/>
			)}

			<Row gutter={24}>
				{(formDataList.includes('journal_type')) && (
					<Col>
						<InputComponent
							label="Journal Type"
							required type="radio_button" name="journal_type"
							onChange={handleChange}
							options={{
								values: [{ value: 'all', text: 'All' }, { value: 'P&L', text: 'P&L' }, { value: 'B/S', text: 'B/S' }]
							}}
							tooltip="Display both P&L and B/S or either one report."
							rules={vsmTransactionReport.validation.journal_type}
						/>
					</Col>
				)}

				{(formDataList.includes('group_on')) && (
					<Col>
						<InputComponent
							label="Group On"
							required type="radio_button" name="group_on"
							onChange={handleChange}
							options={{
								className: 'd-flex w-100',
								values: [{ value: 'journals', text: 'Journals' }, { value: 'models', text: 'Models' }]
							}}
							tooltip="This will group report data either by Journals or Models."
							rules={vsmTransactionReport.validation.group_on}
						/>
					</Col>
				)}

				{(formDataList.includes('currency')) && (
					<Col>
						<InputComponent
							label="Currency"
							required type="radio_button" name="currency"
							onChange={handleChange}
							options={{
								values: [{ value: 'ledger', text: 'Ledger' }, { value: 'transaction', text: 'Transaction' }]
							}}
							tooltip="Report data will be grouped based on Ledger currency or grouped by Transaction currency."
							rules={vsmTransactionReport.validation.currency}
						/>
					</Col>
				)}

			</Row>

			<Row gutter={24} className="no-wrap">
				<Col flex="210px" style={{ minWidth: "210px" }}>
					{(formDataList.includes('main_entity')) && (
						<div className="mt-24">
							<InputComponent
								type="radio_button" name="main_entity"
								options={{
									values: [{ value: 'organisation', text: 'Organisation' }, { value: 'people', text: 'People' }]
								}}
								onChange={() => {
									getPeopleRole()
									getOrgRelatedData()
									form.resetFields(['main_organisation_role', 'main_organisation', 'main_people_role', 'main_people', 'related_organisation_role', 'related_organisation', 'related_people_role', 'related_people'])
								}}
							/>
						</div>
					)}
				</Col>
				<Col flex={1}>
					<Row gutter={24}>
						{(formDataList.includes('main_organisation_role')) && (
							<Col span={12}>
								<InputComponent
									label="Org. Role/s"
									type="select" mode="multiple" name="main_organisation_role" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['main_organisation', 'related_organisation_role', 'related_organisation'])
									}}
									options={{
										values: SavedReportStore.main_organisation_roles,
										value_key: "id",
										text_key: "role_name",
									}}
									tooltip="In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child."
								/>
							</Col>
						)}
						{(formDataList.includes('main_organisation')) && (
							<Col span={12}>
								<InputComponent
									label="Organisation/s"
									type="select" mode="multiple" name="main_organisation" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['related_organisation_role', 'related_organisation'])
									}}
									tooltip="An Organisation refers to the particular firm, institution or company to fetch their reports."
									options={{
										values: SavedReportStore.main_organisation,
										value_key: "organisation_id",
										text_key: "organisation_name",
									}}
								/>
							</Col>
						)}
						{(formDataList.includes('main_people_role')) && (
							<Col span={12}>
								<InputComponent
									label="People Role/s"
									type="select" mode="multiple" name="main_people_role" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['main_people', 'related_people_role', 'related_people'])
									}}
									options={{
										values: SavedReportStore.main_people_roles,
										value_key: "id",
										text_key: "role_name",
									}}
									tooltip="In Credebt Machine system, People Role is a function of an People in particular Transaction. People play certain Roles that are connected with other People Roles through Role Relations, in which People are positioned as either a Parent or a Child."
								/>
							</Col>
						)}
						{(formDataList.includes('main_people')) && (
							<Col span={12}>
								<InputComponent
									label="People/s"
									type="select" mode="multiple" name="main_people" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['related_people_role', 'related_people'])
									}}
									tooltip="An People refers to the particular firm, institution or company to fetch their reports."
									options={{
										values: SavedReportStore.main_people,
										value_key: "people_id",
										text_key: "people_name",
									}}
								/>
							</Col>
						)}
					</Row>
				</Col>
			</Row>
			<Row gutter={24} className="no-wrap">
				<Col flex="210px" style={{ minWidth: "210px" }}>
					{(formDataList.includes('related_entity')) && (
						<div className="mt-24">
							<InputComponent
								type="radio_button" name="related_entity"
								options={{
									values: [{ value: 'organisation', text: 'Organisation' }, { value: 'people', text: 'People' }]
								}}
								onChange={() => {
									getOrgRelatedData()
									form.resetFields(['related_people_role', 'related_people'])
								}}
							/>
						</div>
					)}
				</Col>
				<Col flex={1}>
					<Row gutter={24}>
						{(formDataList.includes('related_organisation_role')) && (
							<Col span={12}>
								<InputComponent
									label="Related Org. Role/s"
									type="select" mode="multiple" name="related_organisation_role" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['related_organisation'])
									}}
									tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
									options={{
										values: SavedReportStore.related_organisation_role,
										value_key: "id",
										text_key: "role_name",
									}}
								/>
							</Col>
						)}
						{(formDataList.includes('related_organisation')) && (
							<Col span={12}>
								<InputComponent
									label="Related Organisation/s"
									type="select" mode="multiple" name="related_organisation" placeholder="All" onChange={handleChange}
									tooltip="Related Organisation is an Organisation that have subordinate Role in a particular relation between two Organisations in the context of a single Transaction."
									options={{
										values: SavedReportStore.related_organisation,
										value_key: "organisation_id",
										text_key: "organisation_name",
									}}
								/>
							</Col>
						)}
						{(formDataList.includes('related_people_role')) && (
							<Col span={12}>
								<InputComponent
									label="Related People Role/s"
									type="select" mode="multiple" name="related_people_role" placeholder="All" onChange={() => {
										handleChange()
										getOrgRelatedData()
										form.resetFields(['related_people'])
									}}
									tooltip="People Role Relation is the connection between the Roles played by two different People."
									options={{
										values: SavedReportStore.related_people_role,
										value_key: "id",
										text_key: "role_name",
									}}
								/>
							</Col>
						)}
						{(formDataList.includes('related_people')) && (
							<Col span={12}>
								<InputComponent
									label="Related People/s"
									type="select" mode="multiple" name="related_people" placeholder="All" onChange={handleChange}
									tooltip="Related People is an People that have subordinate Role in a particular relation between two People in the context of a single Transaction."
									options={{
										values: SavedReportStore.related_people,
										value_key: "people_id",
										text_key: "people_name",
									}}
								/>
							</Col>
						)}
					</Row>
				</Col>
			</Row>


			{(formDataList.includes('single_based_on') || (formDataList.includes('multiple_based_on'))) && (
				<Form.Item className="mb-0" required label="Based On">
					<Row className="w-100 no-wrap">
						<Col flex="477px">
							{formDataList.includes('single_based_on') && (
								<InputComponent
									type="radio_button" name="single_based_on"
									onChange={handleChange}
									options={{
										values: [{ value: 'single_transaction', text: 'Transaction' }, { value: 'single_model', text: 'Model' }, { value: 'single_mapping', text: 'Trade Transaction' }, { value: 'single_transaction_type', text: 'Trade Type' }, { value: 'single_journal', text: 'Journal' }]
									}}
								/>
							)}
							{formDataList.includes('multiple_based_on') && (
								<InputComponent
									type="radio_button" name="multiple_based_on"
									onChange={handleChange}
									options={{
										values: [{ value: 'multiple_transaction', text: 'Transaction' }, { value: 'multiple_model', text: 'Model' }, { value: 'multiple_mapping', text: 'Trade Transaction' }, { value: 'multiple_transaction_type', text: 'Trade Type' }, { value: 'multiple_journal', text: 'Journal' }]
									}}
								/>
							)}
						</Col>
						<Col flex={1}>
							{(formDataList.includes('single_transaction') && (
								<InputComponent
									name="single_transaction" placeholder="Transaction ID"
									hint="Transaction ID is a unique ID given to any financial Transaction."
									onChange={handleChange}
									rules={vsmTransactionReport.validation.single_transaction}
								/>
							))}
							{(formDataList.includes('single_model') && (
								<InputComponent
									type="select" name="single_model" placeholder="Model" className="w-100" onChange={handleChange}
									options={{
										values: SavedReportStore.dropdown_models,
										value_key: 'id',
										text_key: 'model_name',
									}}
									hint="Choose Transaction Model name."
									rules={vsmTransactionReport.validation.single_model}
								/>
							))}
							{(formDataList.includes('single_mapping') && (
								<InputComponent
									type="select" name="single_mapping" placeholder="Trade Transaction" className="w-100" onChange={handleChange}
									options={{
										values: TransactionFieldMappingStore.dropdown_list,
										value_key: 'id',
										text_key: 'mapping_name'
									}}
									hint="Choose Trade Transaction."
									rules={vsmTransactionReport.validation.single_mapping}
								/>
							))}
							{(formDataList.includes('single_transaction_type') && (
								<InputComponent
									type="select" name="single_transaction_type" placeholder="Trade Type" className="w-100" onChange={handleChange}
									options={{
										values: TransactionTypeStore.dropdown_list,
										value_key: 'id',
										text_key: 'type_name'
									}}
									hint="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Transaction Type."
									rules={vsmTransactionReport.validation.single_transaction_type}
								/>
							))}
							{
								(formDataList.includes('single_journal') && (
									<InputComponent
										type="select" name="single_journal" placeholder="Journal" className="w-100" onChange={handleChange}
										options={{
											values: TransactionModelStore.journal_list,
											value_key: 'id',
											text_key: { key: ['journal_code', ' - ', 'journal_label'] }
										}}
										hint="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Transaction Type."
										rules={vsmTransactionReport.validation.single_journal}
									/>
								))}
							{(formDataList.includes('multiple_transaction') && (
								<InputComponent
									mode="tags" type="select" name="multiple_transaction" placeholder="Transaction ID" onChange={handleChange}
									options={{
										values: []
									}}
									notFoundContent={null}
									hint="Transaction ID is a unique ID given to any financial Transaction."
									rules={vsmTransactionReport.validation.multiple_transaction}
								/>
							))}
							{(formDataList.includes('multiple_model') && (
								<InputComponent
									mode="multiple" type="select" name="multiple_model" placeholder="Model" className="w-100" onChange={handleChange}
									options={{
										values: SavedReportStore.dropdown_models,
										value_key: 'id',
										text_key: 'model_name',
									}}
									hint="Choose Transaction Model name."
									rules={vsmTransactionReport.validation.multiple_model}
								/>
							))}
							{(formDataList.includes('multiple_mapping') && (
								<InputComponent
									mode="multiple" type="select" name="multiple_mapping" placeholder="Trade Transaction" className="w-100" onChange={handleChange}
									options={{
										values: TransactionFieldMappingStore.dropdown_list,
										value_key: 'id',
										text_key: 'mapping_name'
									}}
									hint="Choose Trade Transaction."
									rules={vsmTransactionReport.validation.multiple_mapping}
								/>
							))}
							{(formDataList.includes('multiple_transaction_type') && (
								<InputComponent
									mode="multiple" type="select" name="multiple_transaction_type" placeholder="Trade Type" className="w-100" onChange={handleChange}
									options={{
										values: TransactionTypeStore.dropdown_list,
										value_key: 'id',
										text_key: 'type_name'
									}}
									hint="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Transaction Type."
									rules={vsmTransactionReport.validation.multiple_transaction_type}
								/>
							))}
							{(formDataList.includes('multiple_journal') && (
								<InputComponent
									mode="multiple" type="select" name="multiple_journal" placeholder="Journal" className="w-100" onChange={handleChange}
									options={{
										values: TransactionModelStore.journal_list,
										value_key: 'id',
										text_key: { key: ['journal_code', ' - ', 'journal_label'] }
									}}
									hint="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Transaction Type."
									rules={vsmTransactionReport.validation.multiple_journal}
								/>
							))}
						</Col>
					</Row>
				</Form.Item>
			)}
			<Row gutter={24}>
				{(formDataList.includes('cte_banks')) && (
					<Col span={12}>
						<InputComponent
							label="Bank/s"
							type="select" mode="multiple" name="cte_banks" onChange={() => {
								handleChange()
								form.resetFields(['cte_bank_accounts'])
							}}
							placeholder="Select Bank/s"
							options={{
								values: LedgerBankAccountStore.bankNameList,
								value_key: 'id',
								text_key: 'name',
							}}
							tooltip="Bank name (for a Cash Account) is the name of a banking or cash processing organisation that operates a specific cash account."
						/>
					</Col>
				)}
				{(formDataList.includes('cte_bank_accounts')) && (
					<Col span={12}>
						<InputComponent
							label="Bank Account/s"
							type="select" mode="multiple" name="cte_bank_accounts" onChange={handleChange}
							placeholder="Select Bank Account/s"
							options={{
								values: CTEImportStore.bank_list,
								value_key: 'id',
								text_key: 'bankAccount_names',
								rejected_keys: form.getFieldValue('cte_banks') && form.getFieldValue('cte_banks').length > 0 && CTEImportStore.bank_list && CTEImportStore.bank_list.map(x => x.id),
								accepted_key: CTEImportStore.bank_list && CTEImportStore.bank_list.map(x => form.getFieldValue('cte_banks') && form.getFieldValue('cte_banks').includes(x.bank_id) ? x.id : null)
							}}
							tooltip="A name or code given to a cash account that indicates the account's purpose."
						/>
					</Col>
				)}
				{(formDataList.includes('cte_classification')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Cash Transaction Classification/s"
							type="select" mode="multiple" name="cte_classification" onChange={handleChange}
							placeholder="Select Classification/s"
							options={{
								values: ClassificationStore.classificationList,
								value_key: 'id',
								text_key: 'transaction_class_name',
							}}
							tooltip="Classifications are used by CTE to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						/>
					</Col>
				)}
				{(formDataList.includes('transaction_type')) && (
					<Col span={12}>
						<InputComponent
							label="Trade Type/s"
							type="select" mode="multiple" name="transaction_type" onChange={handleChange}
							placeholder="Select one or many Trade Type/s"
							options={{
								values: TransactionTypeStore.dropdown_list,
								value_key: 'id',
								text_key: 'type_name',
							}}
							tooltip="Trade Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Trade Type can further be classified by Transaction Type."
						/>
					</Col>
				)}
				{(formDataList.includes('cte_transaction_type')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Transaction Type/s"
							type="select" mode="multiple" name="cte_transaction_type" onChange={handleChange}
							placeholder="Select one or many Transaction Type/s"
							options={{
								values: SavedReportStore.cteTypesList,
								value_key: 'id',
								text_key: 'text',
							}}
							tooltip="Transaction Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Sub Types."
						/>
					</Col>
				)}

				{(formDataList.includes('cte_currency')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Currency/s"
							type="select" mode="multiple" name="cte_currency" onChange={handleChange}
							placeholder="Select Currency/s"
							options={{
								values: LedgerDetailStore.currency_list,
								value_key: 'id',
								text_key: 'currency_name',
							}}
							tooltip="Currency is a medium of exchange for goods and services. Setting Base currency will set this currency as default currency throughout Ledger."
						/>
					</Col>
				)}

				{(formDataList.includes('cte_tags')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Tag/s"
							type="select" mode="multiple" name="cte_tags" onChange={handleChange}
							placeholder="Select One or More Tag/s"
							options={{
								values: RoleMappingStore.dropdown_tags_list,
								value_key: 'id',
								text_key: 'tag_name',
							}}
							tooltip="Tags are used by Cash Transaction Engine [CTE] to increase software’s ability to accurately identify external (both inbound and outbound) cash transactions against Organisations stored in the system’s database."
						/>
					</Col>
				)}

				{(formDataList.includes('cte_exchange_id')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Exchange Id"
							name="cte_exchange_id" placeholder="Exchange Id"
							tooltip="Exchange ID is a unique numerical value assigned to an Organisation Role in Exchange 2.0 software."
							onChange={handleChange}
							rules={vsmTransactionReport.validation.cte_exchange_id}
						/>
					</Col>
				)}
				{(formDataList.includes('additional_columns') && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Select Additional Columns for Export"
							type="select" mode="multiple" name="additional_columns" onChange={handleChange}
							placeholder="Select Additional Columns"
							options={{
								values: [
									{ value: "Bank Account No.", text: "Bank Account No." },
									{ value: "Valued On", text: "Valued On" },
									{ value: "Customer Reference", text: "Customer Reference" },
									{ value: "Bank Reference", text: "Bank Reference" },
									{ value: "Ledger Role Mapping ID", text: "Ledger Role Mapping ID" },
									{ value: "Organisation / People Role", text: "Organisation / People Role" },
									{ value: "Related Organisation / People Role", text: "Related Organisation / People Role" },
									{ value: "Currency", text: "Currency" },
									{ value: "Financial Year", text: "Financial Year" },
									{ value: "Reconciled Status", text: "Reconciled Status" },
									{ value: "Reconciled TTE Transaction ID ", text: "Reconciled TTE Transaction ID " }
								],
							}}
							tooltip="Select additional columns you want in export files along with default column."
						/>
					</Col>
				))}
				{(formDataList.includes('cte_bank_ref')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Bank Reference">
							<div className="w-100">
								<BankRefElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('cte_customer_ref')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Customer Reference">
							<div className="w-100">
								<CustomerRefElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('org_people_bank_iban')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Org/People Bank IBAN">
							<div className="w-100">
								<OrgPeopleBankIBANElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('org_people_bank_acc_no')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Org/People Bank Account No">
							<div className="w-100">
								<OrgPeopleBankAccNoElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('journals')) && (
					<Col span={12}>
						<InputComponent
							label="Journal/s"
							type="select" mode="multiple" name="journals" onChange={handleChange}
							placeholder="Select one or many Journal/s"
							options={{
								values: TransactionModelStore.journal_list,
								value_key: 'id',
								text_key: { key: ['journal_code', ' - ', 'journal_label'] },
							}}
							tooltip="Transaction Type is a main category of Transaction (for example a-ETR, b-ETR, c-ETR, d-ETR, f-ETR, etc. Transaction Types can further be classified by Sub Types."
						/>
					</Col>
				)}

				{(formDataList.includes('transaction_mappings')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<InputComponent
							label="Type Mapping/s"
							type="select" mode="multiple" name="transaction_mappings" onChange={handleChange}
							placeholder="Select one or many Transaction Type Mapping/s"
							options={{
								values: TransactionFieldMappingStore.dropdown_list,
								value_key: 'id',
								text_key: 'mapping_name',
							}}
							tooltip="Choose Transaction Mapping."
						/>
					</Col>
				)}

				{(formDataList.includes('transaction_ids')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Transaction ID">
							<div className="w-100">
								<TradeIdElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('field_values')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Field Values">
							<div className="w-100">
								<FieldValuesElement onChange={handleChange} form={form} />
							</div>
						</Form.Item>
					</Col>
				)}

				{(formDataList.includes('transaction_dates')) && (
					<Col span={12}>
						<Divider style={{ marginTop: -10 }}>AND</Divider>
						<Form.Item className="mb-0" label="Transaction Date/s">
							<div className="w-100">
								<TransactionDateElement onChange={handleChange} />
							</div>
						</Form.Item>
					</Col>
				)}


			</Row>


			{formDataList.includes('generate_on') && (
				<InputComponent
					required type="radio_button" label="Generate On" name="generate_on"
					onChange={handleChange}
					options={{
						values: [{ value: 'all_unarchive_data', text: 'All Unarchived Data' }, { value: 'date_range', text: 'Date Range/s' }, { value: 'financial_year', text: 'Financial Year' }, { value: 'periods', text: 'Period' }],
						rejected_keys: form.getFieldValue('report_type') === 'trade' ? null : ['periods']
					}}
					tooltip="Generate on can be used to generate reports with more filters in terms of Transaction's Date range and Year."
					rules={vsmTransactionReport.validation.generate_on}
				/>
			)}

			{(formDataList.includes('date_selection')) && (
				<DateSelectionElement SetDateRange={SetDateRange} disabledDate={disabledDate} formDataList={formDataList} onChange={handleChange} />
			)}

			{(formDataList.includes('financial_year')) && (
				<div className="w400">
					<InputComponent
						label="Financial Year"
						required type="select" name="financial_year" placeholder="Financial Year" onChange={handleChange}
						options={{
							values: SavedReportStore.financial_year_list,
						}}
						tooltip="User can select the financial year to fetch reports in that particular range."
						rules={vsmTransactionReport.validation.financial_year}
					/>
				</div>
			)}

			{(formDataList.includes('period_selection')) && (
				<PeriodSelectionElement formDataList={formDataList} onChange={handleChange} />
			)}

			{(formDataList.includes('combined_reports') && (
				<div className="w600">
					<h1>Select Multiple Report below, Up to 12 reports</h1>
					<CombinedReportsElement onChange={handleChange} form={form} />
				</div>
			))}

			{formDataList.includes('audit_group_on') && (
				<InputComponent
					required
					label="Audit Group On"
					onChange={handleChange}
					type="radio_button" name='audit_group_on'
					options={{
						values: [{ value: 'day', text: 'Daily' }, { value: 'week', text: 'Weekly' }, { value: 'month', text: 'Monthly' }, { value: 'year', text: 'Yearly' }]
					}}
					tooltip="Audit Group On"
				/>
			)}

			{formButtons}
		</Form >
	)
})

export default FormComponent
