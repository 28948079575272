import React, { useState, useEffect } from 'react'
import { Form, Button, Modal, Input, Spin } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../../store'
import { vsmRoles, vsmNotify } from '../../../../../config/messages'
import InputComponent from '../../../../../components/InputComponent'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { OrganisationStore, RoleStore: { EditData, editValues } } = useStore()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(false)
	const [fetchRole, setFetchRole] = useState(true)
	const [disableRoleName, setDisabledRoleName] = useState(false)
	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmRoles.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// set the form values to edit
	useEffect(() => {
		setDisabled(true)
		if (props.visible && editValues) {
			let roles = editValues.associated_role_names ? editValues.associated_role_names : []
			let role_ids = roles.length > 0 ? roles.map((item) => item.id) : []
			OrganisationStore.dropdown_orgRole_list = roles
			if (editValues.role_name === 'Originator' || editValues.role_name === 'Intermediary' || editValues.role_name === 'Creditor' || editValues.role_name === 'Debtor') {
				setDisabledRoleName(true)
			}
			form.setFieldsValue({
				role_name: editValues.role_name,
				related_role_ids: role_ids
			})
		}
	}, [editValues, form, OrganisationStore, props])

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((d) => {
			setDisabled(false)
		}).catch(d => {
			setDisabled(true)
		})
	}

	// reset form and close edit form
	const close = () => {
		setDisabledRoleName(false)
		props.close()
		form.resetFields()
		setDisabled(false)
		setFetchRole(true)
	}

	return editValues ? (
		<Modal
			centered
			title={`Edit Role - #${editValues.id} - ${editValues.role_name}`}
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='editform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
		>
			<Form form={form} id="editform" onFinish={handleSubmit}
				layout='vertical'
				onChange={handleChange}
			>
				<Form.Item name="role_name" rules={vsmRoles.validation.role_name} label="Role Name" required tooltip='In Credebt Machine system, Organisation Role is a function of an Organisation in particular Transaction. Organisations play certain Roles that are connected with other Organisation Roles through Role Relations, in which Organisations are positioned as either a Parent or a Child.'>
					<Input placeholder="Role Name" maxLength={50} disabled={disableRoleName} />
				</Form.Item>
				<InputComponent
					required type="select" mode="multiple" label="Related Role/s" name="related_role_ids" placeholder="Choose Related Roles"
					tooltip="Organisation Role Relation is the connection between the Roles played by two different Organisations."
					onChange={handleChange} rules={vsmRoles.validation.related_role}
					onFocus={() => fetchRole && OrganisationStore.getOrgRoles().then(() => setFetchRole(false))}
					notFoundContent={fetchRole ? <Spin size="small" /> : "No Record Found."}
					options={{
						values: OrganisationStore.dropdown_orgRole_list,
						value_key: 'id',
						text_key: 'role_name',
						rejected_keys: [editValues.id]
					}}
				/>
			</Form>
		</Modal >
	) : null
})

export default EditComponent
