import React from 'react'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openDeleteModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(33, 'edit') &&
				<Button title="Edit Tax Settings" type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			}
			{
				(props.data.association_count > 0) ? (
					UserStore.checkPermission(33, 'delete') && <Tooltip placement="topRight" color={"red"} title={"Sorry, You cannot delete this Tax Setting as it is associated with existing Journals."} >
						<Button type="text" disabled><DeleteOutlined /></Button>
					</Tooltip>
				) : (
					UserStore.checkPermission(33, 'delete') &&
					<Button title="Delete Tax Settings" type="text" onClick={() => { openDeleteModal(props.data) }}><DeleteOutlined /></Button>
				)
			}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Tax Name',
		field: 'tax_name',
		width: 150
	}, {
		headerName: 'Tax Value',
		valueGetter: (params) => (params.data && params.data.tax_value) ? params.data.tax_value + ' %' : null,
		field: 'tax_value',
		width: 80
	}, {
		headerName: 'Tax Type',
		field: 'tax_type',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 130
	}, {
		headerName: 'Associated Journals',
		field: 'association_count',
		filter: 'agNumberColumnFilter',
		width: 80
	}, {
		headerName: 'Last Updated By',
		field: 'updated_by',
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 185
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 185
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 115
	}]
}
