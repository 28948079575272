import React, { useEffect } from 'react'
import { observer } from "mobx-react";
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import useStore from '../../../../../store';
import { DateComparator } from '../../../../../utils/GlobalFunction';
import LocalGridConfig from '../../../../../config/LocalGridConfig';
import { vsmCommon } from '../../../../../config/messages';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const ListComponent = observer((props) => {
	const { openEditDrawer, openDeleteDrawer } = props
	const { TriggerStore, UserStore } = useStore()

	const ActionRenderer = (prop) => {
		return (
			<div className="action-column">
				{prop.data.is_importing ? (
					(UserStore.checkPermission(83, 'edit')) && <Tooltip placement="topRight" color={"red"} title={"Sorry, you cannot update this Trigger as import process is going on."} ><Button title="Edit Trigger" type="text" disabled><EditOutlined /></Button></Tooltip>
				) : (
					(UserStore.checkPermission(83, 'edit')) && <Button title="Edit Trigger" type="text" onClick={() => { openEditDrawer(prop.data) }}><EditOutlined /></Button>
				)}

				{prop.data.is_importing ? (
					(UserStore.checkPermission(83, 'delete')) && <Tooltip placement="topRight" color={"red"} title={"Sorry, you cannot delete this Trigger as import process is going on."} ><Button title="Delete Trigger" type="text" disabled><DeleteOutlined /></Button></Tooltip>
				) : (
					(UserStore.checkPermission(83, 'delete')) && <Button title="Delete Trigger" type="text" onClick={() => { openDeleteDrawer(prop.data) }}><DeleteOutlined /></Button>
				)}
			</div>
		)
	}

	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80
		}, {
			headerName: 'Trigger Name',
			field: 'trigger_name',
			valueGetter: (params) => (params.data && params.data.trigger_name) ? params.data.trigger_name : '',
			width: 220
		}, {
			headerName: 'When to execute',
			field: 'when_to_execute',
			filter: 'agSetColumnFilter',
			tooltipValueGetter: (params) => (params.data && params.data.when_to_execute) ? params.data.when_to_execute.map(x => typeof (x) === 'object' ? x.time_schedule : x) : '',
			valueGetter: (params) => (params.data && params.data.when_to_execute) ? params.data.when_to_execute.map(x => typeof (x) === 'object' ? x.time_schedule : x) : '',
			filterParams: {
				defaultToNothingSelected: true,
				values: ['Import', 'New Transaction', 'Transaction Status Change', 'Timely Schedule']
			},
			width: 220
		}, {
			headerName: 'Trade Transaction',
			field: 'field_mapping',
			filter: 'agSetColumnFilter',
			tooltipValueGetter: (params) => (params.data && params.data.field_mapping) ? params.data.field_mapping.mapping_name : '',
			valueGetter: (params) => (params.data && params.data.field_mapping) ? params.data.field_mapping.mapping_name : '',
			width: 185
		}, {
			headerName: 'Transaction Status',
			field: 'tte_status',
			filter: 'agSetColumnFilter',
			valueGetter: (params) => (params.data && params.data.tte_status) ? params.data.tte_status.status_name : '',
			width: 150
		}, {
			headerName: 'Model',
			field: 'selected_models',
			filter: 'agSetColumnFilter',
			tooltipValueGetter: (params) => {
				return params.data && params.data.selected_models && (
					params.data.selected_models.map(x => `${x.model_name} (${x.date})`)
				)
			},
			valueGetter: (params) => {
				return params.data && params.data.selected_models && (
					params.data.selected_models.map(x => `${x.model_name} (${x.date})`)
				)
			},
			width: 220
		}, {
			headerName: 'Last Updated By',
			field: 'updated_by',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
			},
			width: 185
		}, {
			headerName: 'Last Updated On',
			field: 'last_updated_on',
			filter: 'agDateColumnFilter',
			width: 185,
			filterParams: {
				buttons: ['reset'],
				inRangeInclusive: true,
				suppressAndOrCondition: true,
				comparator: DateComparator
			}
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			sortable: false,
			filter: false,
			pinned: 'right',
			width: 115
		}]
	}

	useEffect(() => {
		TriggerStore.getList()
	}, [TriggerStore])

	let columns = []
	let columnConfig = localStorage.getItem('trigger_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={TriggerStore.list_data}
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditDrawer, openDeleteDrawer
				}}
				onColumnResized={TriggerStore.onGridChanged}
				onColumnMoved={TriggerStore.onGridChanged}
				onColumnPinned={TriggerStore.onGridChanged}
				onGridReady={TriggerStore.setupGrid}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={TriggerStore.onFilterChanged}
				onSortChanged={TriggerStore.onGridChanged}
			/>
		</div>
	)
})

export default ListComponent
