import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { PageHeader, Button, Spin } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import EditComponent from './component/EditComponent'
import DeleteComponent from './component/DeleteComponent'
import AddComponent from './component/AddComponent'
import ImportComponent from './component/ImportComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const Journals = observer(() => {
	const [addModal, setAddModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [importModal, setImportModal] = useState(false)
	const { JournalsStore: { per_page, agGrid, setEditValues, setDeleteValues, setPageSize, exportData, exportLoading, list_data, listLoading }, JournalGroupStore, TaxSettingStore, UserStore } = useStore()
	const store = {
		JournalGroup: JournalGroupStore,
		TaxSetting: TaxSettingStore
	}

	// Open form for add new transaction type
	const openAddModal = () => {
		store.JournalGroup.getAllRecords()
		store.TaxSetting.getAllRecords()
		setAddModal(true)
	}

	// Close form for add new transaction type
	const closeAddModal = () => setAddModal(false)

	// Open form for edit existing transaction type and set values to form
	const openEditModal = (data) => {
		store.JournalGroup.getAllRecords()
		store.TaxSetting.getAllRecords()
		setEditValues(data)
		setEditModal(true)
	}

	// Open form for edit transaction type
	const closeEditModal = () => setEditModal(false)

	// Open confirmation alert before deleting existing transaction type
	const openDeleteModal = (data) => {
		setDeleteValues(data)
		setDeleteModal(true)
	}

	// Close confirmation alert for deleting record
	const closeDeleteModal = () => setDeleteModal(false)

	//Open Modal to import data
	const importData = () => {
		setImportModal(true)
	}

	//Open import modal
	const closeImportModal = () => setImportModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('journal_grid')
		agGrid.api.setFilterModel(null)
		agGrid.api.setSortModel(null)
		agGrid.columnApi.resetColumnState()
		agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.Journals.path} />
			{(!UserStore.checkPermission(24, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={"has_export_spinner " + UserStore.fullscreen_class}
					title={BreadcrumbConfig.Journals.title}
					extra={[
						(UserStore.checkPermission(24, 'add')) && <Button key="1" onClick={openAddModal}>New</Button>,
						<Button key="2" onClick={exportData} disabled={listLoading || (list_data && list_data.length === 0)}>Export</Button>,
						(UserStore.checkPermission(24, 'import')) && <Button key="3" onClick={importData}>Import</Button>,
						<RecordPerPage key="4" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="5" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key="6" />
					]}
				>
					<Spin spinning={exportLoading}>
						<ListComponent openEditModal={openEditModal} openDeleteModal={openDeleteModal} />
					</Spin>
					<AddComponent visible={addModal} close={closeAddModal} />
					<EditComponent visible={editModal} close={closeEditModal} />
					<DeleteComponent visible={deleteModal} close={closeDeleteModal} />
					<ImportComponent visible={importModal} close={closeImportModal} importData={importData} />
				</PageHeader>
			)
			}
		</>
	)
})

export default Journals
