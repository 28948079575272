import React from 'react';
import { observer } from 'mobx-react-lite';
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import useStore from '../../../../store';
import { Button } from 'antd';
import GridConfig from '../../../../config/GridConfig';
import { vsmCommon } from '../../../../config/messages';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

const ListComponent = observer((props) => {
	const { openEditMemberDrawer, openViewMemberDrawer } = props
	const { MemberStore, UserStore } = useStore()

	const ActionRenderer = (props) => {
		return (
			<div className="action-column">
				{UserStore.checkPermission(119, 'edit') && <Button title="Edit Member" type="text" onClick={() => { openEditMemberDrawer(props.data.id) }} ><EditOutlined /></Button>}
				{UserStore.checkPermission(119, 'view') && <Button title="Edit Member" type="text" onClick={() => { openViewMemberDrawer(props.data.id) }} ><EyeOutlined /></Button>}
			</div>
		)
	}
	
	const gridOptions = {
		columnDefs: [{
			headerName: '# ID',
			field: 'id',
			filter: 'agNumberColumnFilter',
			pinned: 'left',
			width: 80,
		}, {
			headerName: 'First Name',
			field: 'is_member_user.first_name',
			tooltipField: 'is_member_user.first_name',
			width: 120
		}, {
			headerName: 'Last Name',
			field: 'is_member_user.last_name',
			tooltipField: 'is_member_user.last_name',
			width: 120
		}, {
			headerName: 'Organisation Name',
			field: 'organisation_name',
			tooltipField: 'organisation_name',
			width: 180
		}, {
			headerName: 'Credebt Classification',
			field: 'global_industries.name',
			filter: 'agSetColumnFilter',
			tooltipField: 'global_industries.name',
			width: 150,
			filterParams: {
				defaultToNothingSelected: false,
				values: (params) => {
					MemberStore.getIndustries().then((data) => {
						params.success([...data.data.map(x => x.name)]);
					})
				},
				buttons: ['apply', 'reset']
			},
		}, {
			headerName: 'Email',
			field: 'is_member_user.email',
			tooltipField: 'is_member_user.email',
			width: 150
		}, {
			headerName: 'Role',
			filter: 'agSetColumnFilter',
			field: 'member_role_mapping.role_name',
			tooltipField: 'member_role_mapping.role_name',
			width: 120,
			filterParams: {
				defaultToNothingSelected: false,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					MemberStore.getMemberRole().then((data) => {
						data.data.forEach((item) => {							
							if (["Originator", "Intermediary", "Investor"].includes(item.role_name)) {
								arr.push(item.role_name)
							}
						})
						params.success(arr);
					})
				},
			},
		}, {
			headerName: 'Trade Name',
			field: 'trade_name',
			tooltipField: 'trade_name',
			width: 120
		}, {
			headerName: 'Country',
			field: 'global_countries.country_name',
			tooltipField: 'global_countries.country_name',
			filter: 'agSetColumnFilter',
			width: 110,
			filterParams: {
				defaultToNothingSelected: false,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					MemberStore.getAllCountries().then((data) => {
						data.data.forEach((item) => {
							arr.push(item.country_name)
						})
						params.success(arr);
					})
				},
			},
		}, {
			headerName: 'Status',
			field: 'status.status_name',
			tooltipField: 'status.status_name',
			filter: 'agSetColumnFilter',
			filterParams: {
				defaultToNothingSelected: true,
				buttons: ['apply', 'reset'],
				values: (params) => {
					var arr = []
					MemberStore.getStatus().then((data) => {
						data.data.forEach((item) => {
							if (["Authorised", "Pending"].includes(item.status_name)) {
								arr.push(item.status_name)
							}
						})
						params.success(arr);
					})
				},
			},
			width: 110
		}, {
			headerName: 'Last Updated On',
			field: 'updated_at',
			filter: "agDateColumnFilter",
			tooltipField: 'updated_at',
			width: 150,
			cellRendererFramework: (params) => {
				return (
					<span>
						{params.data?.updated_at
							? moment(params.data?.updated_at).format('YYYY-MM-DD')
							: null}
					</span>
				);
			},
		}, {
			headerName: 'Actions',
			field: 'actions',
			type: 'actionColumn',
			filter: false,
			sortable: false,
			pinned: 'right',
			width: 90
		}]
	}

	let columns = []
	let columnConfig = localStorage.getItem('member_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openEditMemberDrawer, openViewMemberDrawer
				}}
				onGridReady={MemberStore.setupGrid}
				onColumnResized={MemberStore.onGridChanged}
				onColumnMoved={MemberStore.onGridChanged}
				onColumnPinned={MemberStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent;
