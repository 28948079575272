import React, { useState } from 'react'
import { observer } from "mobx-react"
import InputComponent from '../../../../components/InputComponent'
import { vsmOrganisations } from '../../../../config/messages'
import { Form, Col, Button, Row } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const EmailElement = observer(({ onChange, form }) => {
    const [SelectTypes, SetSelectedTypes] = useState([{ contact_for: 'Primary' }])
    return (
        <div className="w-100">
            <Form.List name='email'>
                {
                    (fields, { add, remove }) => {
                        return fields.map((field, index) => {
                            let rejected_types = []
                            var accepted_index;
                            SelectTypes && SelectTypes.map((x, index) => {
                                if (x && x.contact_for === 'Primary') {
                                    accepted_index = index
                                    rejected_types.push('Primary')
                                }
                                return x
                            })
                            return (
                                <div className="w-100" key={field.key}>
                                    <Row gutter={10} style={{ flexFlow: 'row' }}>

                                        <Col flex={1}>
                                            <InputComponent
                                                placeholder="Email Type"
                                                type="select"
                                                name={[field.name, 'contact_for']}
                                                key={[field.key, 'contact_for']}
                                                onChange={() => {
                                                    onChange()
                                                    SetSelectedTypes(form.getFieldValue('email'))
                                                }}
                                                options={{
                                                    values: [{ value: 'Primary', text: 'Primary' }, { value: 'Home', text: 'Home' }, { value: 'Office', text: 'Office' }, { value: 'Other', text: 'Other' }],
                                                    rejected_keys: rejected_types,
                                                    accepted_key: index === accepted_index && 'Primary'
                                                }}
                                                autoComplete="off"
                                                rules={form.getFieldValue('email').length >= 1 ? vsmOrganisations.validation.email_type : [{ required: false }]}
                                            />
                                        </Col>

                                        <Col flex={1}>
                                            <InputComponent
                                                placeholder="Enter your Email"
                                                name={[field.name, 'contact_value']}
                                                key={[field.key, 'contact_value']}
                                                onChange={onChange}
                                                tooltip="Messages distributed by electronic means from one computer user to one or more recipients via a network."
                                                autoComplete="off"
                                                maxLength={100}
                                                rules={form.getFieldValue('email').length >= 1 ? vsmOrganisations.validation.email_with_required : vsmOrganisations.validation.email_without_required}
                                            />
                                        </Col>

                                        <Col>
                                            <div className="d-flex">
                                                {
                                                    (fields.length > 1) && (
                                                        <Button type="danger" onClick={() => {
                                                            remove(field.name)
                                                            SetSelectedTypes(form.getFieldValue('email'))
                                                            onChange()
                                                        }} icon={<MinusOutlined />}></Button>
                                                    )
                                                }
                                                {(index === (fields.length - 1)) && (fields.length < 12) && (
                                                    <Button className={(fields.length > 1) ? "ml-10" : null} type="primary" onClick={async () => {
                                                        await add({ id: null })
                                                        onChange()
                                                    }} icon={<PlusOutlined />} />
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            )
                        })
                    }
                }
            </Form.List>
        </div>
    )
})

export default EmailElement
