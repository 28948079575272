import React from 'react'
import { observer } from 'mobx-react-lite'
import useStore from '../../../../store'

const UserDetailView = observer(() => {
	const { MemberStore: { viewMemberValue } } = useStore()

	//get_member_bank
	return (
		<div className='members_table'>
			<table cellPadding='0' cellSpacing='0' border='0'>
				<tbody key="tbody">
					<tr>
						<th width="100px">First Name</th>
						<th width="100px">Last Name</th>
						<th width="100px">Email</th>
						<th >Status</th>
					</tr>
					{
						viewMemberValue?.is_member_user ?
							<tr>
								<td width="100px">{viewMemberValue?.is_member_user?.first_name ? viewMemberValue?.is_member_user?.first_name : ''}</td>
								<td width="100px">{viewMemberValue?.is_member_user?.last_name ? viewMemberValue?.is_member_user?.last_name : ''}</td>
								<td width="100px">{viewMemberValue?.is_member_user?.email ? viewMemberValue?.is_member_user?.email : ''}</td>
								<td width="100px">{viewMemberValue?.status && viewMemberValue?.status?.status_name ? viewMemberValue?.status?.status_name : ''}</td>
							</tr> :
							<tr>
								<td width="100px">{''}</td>
								<td width="100px">{''}</td>
								<td width="100px">{''}</td>
								<td width="100px">{''}</td>
							</tr>
					}
				</tbody>
			</table>
		</div>
	)
})
export default UserDetailView