import React from 'react'
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import PageNotFound from '../pages/PageNotFound';
import ForgetPassword from "../pages/ForgetPassword";
import JournalGroups from "../pages/TradeTransaction/Administration/JournalGroups";
import TaxSettings from "../pages/TradeTransaction/Administration/TaxSettings";
import TransactionFields from "../pages/TradeTransaction/Administration/TransactionFields";
import Algorithms from "../pages/TradeTransaction/Administration/Algorithms";
import { default as AlgorithmsAddComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/AddComponent';
import { default as AlgorithmsEditComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/EditComponent';
import { default as AlgorithmsViewComponent } from '../pages/TradeTransaction/Administration/Algorithms/component/ViewComponent';
import ResetPassword from "../pages/ResetPassword";
import UserManual from '../pages/UserManual';
import TransactionTypes from '../pages/TradeTransaction/Administration/TransactionType/TransactionTypes';
import TransactionSubTypes from '../pages/TradeTransaction/Administration/TransactionType/TransactionSubTypes';
import TransactionFieldMapping from '../pages/TradeTransaction/Administration/TransactionType/TransactionFieldMapping';
import Journals from '../pages/TradeTransaction/Administration/Journals';
import TransactionModels from '../pages/TradeTransaction/Administration/TransactionModels';
import { default as TransactionModalAddComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/AddComponent';
import { default as TransactionModalEditComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/EditComponent';
import { default as TransactionModalVerifyComponent } from '../pages/TradeTransaction/Administration/TransactionModels/component/VerifyComponent';
import Import from '../pages/TradeTransaction/Import';
import ImportAddComponent from '../pages/TradeTransaction/Import/component/AddComponent'
import Organisations from "../pages/OrganisationManagement/AllOrganisations";
import Roles from "../pages/OrganisationManagement/Administration/Roles";
import Ledgers from "../pages/Setup/Ledgers/AllLedgers";
import { default as LedgersAddComponent } from '../pages/Setup/Ledgers/AllLedgers/component/AddComponent';
import { default as LedgersEditComponent } from '../pages/Setup/Ledgers/AllLedgers/component/EditComponent';
import LedgersSettings from "../pages/Setup/Ledgers/LedgerSettings";
import UserManagement from '../pages/Setup/UserManagement';
import RoleManagement from '../pages/Setup/RoleManagement'
import LedgersBankAccounts from '../pages/Setup/Ledgers/BankAccounts'
import RoleMapping from "../pages/OrganisationManagement/RoleMapping";
import BankAccounts from "../pages/OrganisationManagement/BankAccounts";
import Organisation_Status from '../pages/OrganisationManagement/OrganisationStatus';
import SavedReports from '../pages/Reports/SavedReports';
import { default as SavedReportsAddComponent } from '../pages/Reports/SavedReports/components/AddComponent';
import AuditLogs from "../pages/Setup/Ledgers/AuditLogs"
import OldImport from '../pages/TradeTransaction/OldImport';
import AllTransactions from '../pages/TradeTransaction/AllTransactions';
import PreviewReportComponent from '../pages/Reports/SavedReports/components/PreviewReportComponent';
import PreviewDataComponent from '../pages/Reports/SavedReports/components/PreviewDataComponent';
import { default as EditReportComponent } from '../pages/Reports/SavedReports/components/EditComponent';
import RegularExpression from '../pages/CashTransaction/RegularExpression';
import CashTransactions from '../pages/CashTransaction/CashTransactions'
import Classifications from '../pages/CashTransaction/Classifications';
import Reconciliation from '../pages/CashTransaction/Reconciliation';
import CTEImport from '../pages/CashTransaction/Import'
import People from '../pages/PeopleManagement/AllPeople'
import PeopleBankAccounts from '../pages/PeopleManagement/BankAccounts'
import PeopleRoleMapping from '../pages/PeopleManagement/RoleMapping'
import PeopleRoles from '../pages/PeopleManagement/Administration/Roles'
import FXCurrencies from '../pages/Administration/FXCurrencies'
import ViewPeople from '../pages/PeopleManagement/AllPeople/component/ViewComponent'
import ViewOrganisation from '../pages/OrganisationManagement/AllOrganisations/component/ViewComponent';
import ManualJournalLog from '../pages/TradeTransaction/Administration/ManualJournalLog';
import Triggers from "../pages/TradeTransaction/Administration/Triggers";
import GlobalSetting from '../pages/Setup/GlobalSetting'
import MenuManagement from '../pages/Setup/MenuManagement'


//  --------------------------- All Routes of Originator ----------------------------

import OriginatorLogin from '../pages/Originator/OriginatorLogin';
import OriginatorForgotPass from '../pages/Originator/OriginatorForgotPass';
import OriginatorDashboard from '../pages/Originator/OriginatorDashboard';
import TradeFloor from '../pages/Originator/Pages/TradeFloor';
import Profile from '../pages/Originator/Pages/UserProfile';
import ChangePassword from '../pages/Originator/Pages/ChangePass';
import MailTemplate from '../pages/Setup/MailTemplate';
import ImportETR from '../pages/BackOffice/ImportETR';
import PurchaseOrder from '../pages/BackOffice/PurchaseOrder';
import AllETR from '../pages/BackOffice/AllETR';
import Members from '../pages/BackOffice/Members';
import Credebtors from '../pages/BackOffice/Credebtors'

export const RouterConfig = [
	{
		title: 'Login',
		path: '/',
		component: Login,
		default: true
	},
	{
		title: 'User Manual',
		path: '/user-manual',
		component: UserManual,
		auth: true,
	},
	{
		title: 'Forget Password',
		path: '/forget-password',
		component: ForgetPassword,
		default: true
	},
	{
		title: 'Reset Password',
		path: '/reset/:id',
		component: (props) => <ResetPassword {...props} />,
		default: true
	},
	{
		title: 'Dashboard',
		path: '/dashboard',
		component: Dashboard,
		authdefault: true,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Journal Types',
		path: '/trade-transactions/administration/journal-groups',
		component: JournalGroups,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Tax Settings',
		path: '/trade-transactions/administration/tax-settings',
		component: TaxSettings,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Fields',
		path: '/trade-transactions/administration/transaction-fields',
		component: TransactionFields,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Journals',
		path: '/trade-transactions/administration/journals',
		component: Journals,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Types',
		path: '/trade-transactions/administration/trade-transaction/trade-type',
		component: TransactionTypes,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Sub Types',
		path: '/trade-transactions/administration/trade-transaction/transaction-type',
		component: TransactionSubTypes,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Field Mapping',
		path: '/trade-transactions/administration/trade-transaction/trade-transaction',
		component: TransactionFieldMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Models',
		path: '/trade-transactions/administration/transaction-model',
		component: TransactionModels,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Models',
		path: '/trade-transactions/administration/transaction-model/new',
		component: TransactionModalAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Models',
		path: '/trade-transactions/administration/transaction-model/edit/:id',
		component: TransactionModalEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Transaction Models',
		path: '/trade-transactions/administration/transaction-model/verify/:id',
		component: TransactionModalVerifyComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/trade-transactions/administration/algorithms',
		component: Algorithms,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/trade-transactions/administration/algorithms/new',
		component: AlgorithmsAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/trade-transactions/administration/algorithms/edit/:id',
		component: AlgorithmsEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Algorithms',
		path: '/trade-transactions/administration/algorithms/view/:id',
		component: AlgorithmsViewComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import',
		path: '/trade-transactions/administration/import-log',
		component: Import,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import Trade Transaction',
		path: '/trade-transactions/import',
		component: ImportAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import Trade Transaction',
		path: '/trade-transactions/administration/import-log/new',
		component: ImportAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Organisations',
		path: '/organisations/all-organisations',
		component: Organisations,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Role Mapping',
		path: '/organisations/all-organisations/role-mapping/:id',
		component: RoleMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'User & Role Management',
		path: '/setup/all-users',
		component: UserManagement,
		auth: true,
		setting: { header: true, nav: true }
	}, {
		title: 'User Roles',
		path: '/setup/manage-user-profiles',
		component: RoleManagement,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Listing',
		path: '/organisations/all-organisations/bank-accounts',
		component: BankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Accounts',
		path: '/organisations/all-organisations/bank-accounts/:id',
		component: BankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'roles',
		path: '/organisations/administration/roles',
		component: Roles,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Organisation Status',
		path: '/organisations/administration/status',
		component: Organisation_Status,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/all-ledgers',
		component: Ledgers,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/add-ledgers',
		component: LedgersAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers',
		path: '/setup/all-ledgers/edit/:id',
		component: LedgersEditComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledger Settings',
		path: '/setup/ledger-settings',
		component: LedgersSettings,
		ledgerDefault: true,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Ledgers Bank Accounts',
		path: '/setup/bank-accounts',
		component: LedgersBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Saved Reports',
		path: '/reports/saved-reports',
		component: SavedReports,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Create Reports',
		path: '/reports/saved-reports/new',
		component: SavedReportsAddComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Audit Logs',
		path: '/setup/audit-logs',
		component: AuditLogs,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Transactions',
		path: '/trade-transactions/all-transactions',
		component: AllTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Old Import',
		path: '/trade-transactions/old-import',
		component: OldImport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Reports',
		path: '/reports/saved-reports/preview-report',
		component: PreviewReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All Transactions',
		path: '/trade-transactions/all-transactions/:id',
		component: AllTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Reports',
		path: '/reports/saved-reports/preview-report/:report_id',
		component: PreviewReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Data',
		path: '/reports/saved-reports/preview-data',
		component: PreviewDataComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Preview Data',
		path: '/reports/saved-reports/preview-data/:report_id',
		component: PreviewDataComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Reports',
		path: '/reports/saved-reports/edit-report',
		component: EditReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Edit Reports',
		path: '/reports/saved-reports/edit-report/:report_id',
		component: EditReportComponent,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Regular Expression',
		path: '/cash-transactions/administration/regular-expression',
		component: RegularExpression,
		auth: true,
		setting: { header: true, nav: true }
	}, {
		title: 'Cash Transactions',
		path: '/cash-transactions/all-transaction',
		component: CashTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Cash Transactions',
		path: '/cash-transactions/all-transaction/:id',
		component: CashTransactions,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Classifications',
		path: '/cash-transactions/administration/classification',
		component: Classifications,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Reconciliation',
		path: '/cash-transactions/cash-reconciliation',
		component: Reconciliation,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import History',
		path: '/cash-transactions/import',
		component: CTEImport,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Import ETR',
		path: '/back-office/import-etr',
		component: ImportETR,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Purchase Order',
		path: '/back-office/purchase-order',
		component: PurchaseOrder,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'All ETR',
		path: '/back-office/all-etr',
		component: AllETR,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Members',
		path: '/back-office/members',
		component: Members,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title:'Credebtors',
		path:'/back-office/credebtors',
		component:Credebtors,
		auth:true,
		setting:{header : true , nav :true}
	},
	{
		title: 'People Management',
		path: '/people/all-people',
		component: People,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Role Mapping',
		path: '/people/all-people/role-mapping/:id',
		component: PeopleRoleMapping,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Listing',
		path: '/people/all-people/bank-accounts',
		component: PeopleBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Bank Accounts',
		path: '/people/all-people/bank-accounts/:id',
		component: PeopleBankAccounts,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'User Roles',
		path: '/people/roles',
		component: PeopleRoles,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'FX Currencies Management',
		path: '/administration/currencies',
		component: FXCurrencies,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View Organisation',
		path: '/organisations/all-organisations/view-organisation/:id',
		component: ViewOrganisation,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'View People',
		path: '/people/all-people/view-people/:id',
		component: ViewPeople,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Manual Journal Log',
		path: '/trade-transactions/administration/manual-journal-log',
		component: ManualJournalLog,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Triggers',
		path: '/trade-transactions/administration/triggers',
		component: Triggers,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Global Setting',
		path: '/setup/global-settings',
		component: GlobalSetting,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Menu Management',
		path: '/setup/menu-management',
		component: MenuManagement,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Manage Emails',
		path: '/setup/mail-templates',
		component: MailTemplate,
		auth: true,
		setting: { header: true, nav: true }
	},
	{
		title: 'Page Not Found',
		path: '/',
		component: PageNotFound,
		errorpage: true,
		setting: { header: true, nav: true }
	},
]

export const Apps_RouterConfig = [
	{
		title: 'Originator Login',
		apps_path: '/apps',
		component: OriginatorLogin,
		apps_default: true
	},
	{
		title: 'Forgot Password',
		apps_path: '/apps/orignator-forget-password',
		component: OriginatorForgotPass,
		apps_default: true
	},
	{
		title: 'Dashboard',
		apps_path: '/apps/dashboard',
		component: OriginatorDashboard,
		apps_authdefault: true,
		apps_auth: true,
		apps_setting: { header: true, nav: true }
	},
	{
		title: 'Trade Floor',
		apps_path: '/apps/trade-floor/trade-list',
		component: TradeFloor,
		apps_authdefault: true,
		apps_auth: true,
		apps_setting: { header: true, nav: true }
	},
	{
		title: 'My Profile',
		apps_path: '/apps/my-user-account/view-edit-profile',
		component: Profile,
		apps_authdefault: true,
		apps_auth: true,
		apps_setting: { header: true, nav: true }
	},
	{
		title: 'Change Password',
		apps_path: '/apps/my-user-account/change-password',
		component: ChangePassword,
		apps_authdefault: true,
		apps_auth: true,
		apps_setting: { header: true, nav: true }
	},
]
