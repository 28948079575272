import React, { useState } from 'react';
import { Button, Col, Row, Table } from 'antd';
import { observer } from 'mobx-react';
import loginBG from '../../../../assets/images/loginBG.jpg'
import { useHistory } from 'react-router-dom';
import EditComponent from './components/EditComponent'

const Profile = observer((props) => {
    const { Column } = Table;
    let history = useHistory()
    const [editProfileDrawer, setEditProfileDrawer] = useState(false)

    // open Edit Profile Drawer
    const openEditProfileDrawer = () => {
        setEditProfileDrawer(true)
    }

    // close Edit Profile Drawer
    const closeEditProfileDrawer = () => setEditProfileDrawer(false)

    const goToChangePass = () => {
        history.push('');
    }

    const details_info = [
        {
            key: '1',
            label: <div>First Name</div>,
            value: <div>james</div>,
        },
        {
            key: '2',
            label: <div>Last Name</div>,
            value: <div>Smith</div>,
        },
        {
            key: '3',
            label: <div>Organisation Name</div>,
            value: <div>Lorem Ipsum is simply dummy text of the printing</div>,
        },
        {
            key: '4',
            label: <div>Registration Country</div>,
            value: <div>Washington</div>,
        },
        {
            key: '5',
            label: <div>Registration Number</div>,
            value: <div>ED151458498465</div>,
        },
        {
            key: '6',
            label: <div>VAT Number</div>,
            value: <div>5454987212324847897</div>,
        },
        {
            key: '7',
            label: <div>Website</div>,
            value: <div>www.dummy.com</div>,
        },
        {
            key: '8',
            label: <div>Phone</div>,
            value: <div>609-319-6725</div>,
        },
        {
            key: '9',
            label: <div>Email</div>,
            value: <div>info@tippcheese.ie</div>,
        },
        {
            key: '10',
            label: <div>Credebt® Classification</div>,
            value: <div>Lorem Ipsum is simply dummy text of the printing</div>,
        },
        {
            key: '11',
            label: <div>Currency</div>,
            value: <div>USD</div>,
        },
        {
            key: '12',
            label: <div>NACE Code & Category</div>,
            value: <div>132458</div>,
        },
        {
            key: '13',
            label: <div>Tax Clearance Access No</div>,
            value: <div>8654897847848</div>,
        },
        {
            key: '14',
            label: <div>Address</div>,
            value: <div>1393 Dane Street Spokane, Washington, United States - 08232</div>,
        },
    ];

    return (
        <>
            <Row gutter={24}>
                <Col span={8}>
                    <div className='image_card_wrapper'>
                        <div className='img_wrapper'>
                            <img src={loginBG} alt='' />
                        </div>
                        <p className='title_tag'>James Smith</p>
                        <p className='desc_tag'>info@tippcheese.ie</p>
                        <div className='button_tag'>
                            <Button style={{ backgroundColor: '#4FC6E0', color: 'white' }} onClick={goToChangePass}>Change Password</Button>
                            <Button style={{ marginLeft: '15px' }} onClick={openEditProfileDrawer}>Edit Profile</Button>
                        </div>
                    </div>
                </Col>
                <Col span={16}>
                    <Table dataSource={details_info} pagination={false} title={false} className="no_header" bordered>
                        <Column dataIndex="label" key="label" />
                        <Column dataIndex="value" key="value" />
                    </Table>
                </Col>
            </Row>
            <EditComponent visible={editProfileDrawer} close={closeEditProfileDrawer} />
        </>
    );
})

export default Profile;