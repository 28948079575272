import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useForm } from 'antd/lib/form/Form'
import { Button, Spin, Drawer } from 'antd'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import { vsmNotify, vsmTransactionReport } from '../../../../config/messages'

const AddComponent = observer((props) => {
	const [form] = useForm()
	const [saving, setSaving] = useState(false)
	const [tip, setTip] = useState(null)
	const [isDisabled, setDisabled] = useState(true)
	const { SavedReportStore, UserStore } = useStore()
	const [formDataList, setFormDataList] = useState([])
	const defaultFormDataList = ['report_type', 'name', 'code', 'description']

	const handleSubmit = (data) => {
		setSaving(true)
		setTip('Saving')
		SavedReportStore.AddData(data).then(data => {
			vsmNotify.success({
				message: vsmTransactionReport.save
			})
			props.close()
			if (SavedReportStore.my_records === 'my') {
				SavedReportStore.getList({}, UserStore.user.id);
			} else {
				SavedReportStore.getList();
			}
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => {
			setSaving(false)
			setTip(null)
		})
	}

	useEffect(() => {
		SavedReportStore.previewReportValues = null
	}, [SavedReportStore])

	const close = () => {
		props.close()
		SavedReportStore.previewReportValues = null
		setSaving(true)
		setTip('Loading')
	}

	return (
		<>
			<Drawer
				visible={props.visible}
				onClose={close}
				placement='right'
				width={'75%'}
				title='Add Report'
				destroyOnClose={true}
				footer={[
					<div className="text-center">
						{form.getFieldValue('report_type') === 'trade' && UserStore.checkPermission(47, 'preview_data') && <Button className="mr-10" htmlType="button" disabled={isDisabled}
							onClick={() => {
								SavedReportStore.setPreviewReportValues({ ...SavedReportStore.previewReportValues, ...form.getFieldsValue(), form_list: formDataList })
								props.openPreviewDataModal(props.report_id)
							}}
						>Preview Data</Button>}
						{UserStore.checkPermission(47, 'preview_report') && <Button className="mr-10" htmlType="button" disabled={isDisabled}
							onClick={() => {
								SavedReportStore.setPreviewReportValues({ ...SavedReportStore.previewReportValues, ...form.getFieldsValue(), form_list: formDataList })
								props.openPreviewReportModal(props.report_id)
							}}
						>Preview Report</Button>}
						{UserStore.checkPermission(47, 'add') && <Button form='addeditform' className="mr-10" htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>}
						<Button onClick={close}>Cancel</Button>
					</div>
				]}
			>
				<Spin size="large" spinning={saving} tip={tip}>
					<FormComponent defaultFormDataList={defaultFormDataList} form={form} formDataList={formDataList} setFormDataList={setFormDataList} handleSubmit={handleSubmit} initialValues={SavedReportStore.initialValues}
						setDisabled={setDisabled}
					/>

				</Spin>
			</Drawer>
		</>
	)
})

export default AddComponent
