import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from '../../config/LocalGridConfig'
import { etrTypeObject, globalStatus } from "../../utils/GlobalFunction"

export default class ImportETRStore {
	list_data = null
	view_data = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	viewRowData = null;
	viewETRType = null;
	viewLogValues = null
	deleteValues = null
	logValues = null	
	viewImportETRValues = null;

	initialValues = {
		etr_type: 1
	}

	creditRiskInitialValue = {
		rating_agency: [{
			rating_agency_name: null
		}]
	}


	setViewLogValues = (data) => {
		this.viewLogValues = data
	}

	setLogValues = (data) => {
		this.logValues = data
	}

	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	setViewData = (data) => {
		this.viewRowData = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('import_etr_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('import_etr_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	// Set column width after resizing colums
	onChildnGridChanged = (params) => {
		let columnConfig = localStorage.getItem('import_etr_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			localStorage.setItem('import_etr_grid', columnConfig)
		}
	}


	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.get(`back-office/import-etr-log`).then(({ data }) => {
			this.list_data = data.data;

			if (data.data.length) {
				data.data.forEach((item, index) => {
					if (item.status === 1) {
						item.status_name = "Import Successfully"
					} else if (item.status === 2) {
						item.status_name = "Partial Import"
					} else if (item.status === 3) {
						item.status_name = "Pending"
					} else if (item.status === 4) {
						item.status_name = "In Process"
					} else if (item.status === 5) {
						item.status_name = "Failed"
					} else if (item.status === 6) {
						item.status_name = "Cancelled"
					} else {
						item.status_name = "Deleted"
					}
					item.run_time = item.run_time ? item.run_time : null
				});
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			let columnConfig = localStorage.getItem('import_etr_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid && this.agGrid.columnApi && data.total) {
					this.agGrid.columnApi.applyColumnState({ state: sort })
				}
			}
			if (this.agGrid && this.agGrid.api) {
				this.agGrid.api.setFilterModel(filter)
			}
		})
	}

	// call api to get record log
	getViewLogs = (id) => {
		return Axios.get(`back-office/import-etr-log/read/${id}`).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item, index) => {
					item.etr_type = etrTypeObject[item.etr_type];
					item.error = item.error_in_model ? "Error" : "Valid"
					item.status_text = globalStatus('import_etr_status', 'key', item.status)
				});
			}
			this.view_data = data.data;
			this.viewETRType = data.etr_type;
			// this.viewETRType = 6;
			return data
		})
	}

	importETR = (formdata) => {
		return Axios.post(`back-office/import-etr`, formdata).then(({ data }) => {
			this.getList();
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	CancelData = (formdata) => {
		return Axios.get(`back-office/import-etr/cancel/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`back-office/import-etr-log/delete/${formdata.id}`).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
	// call api to get all mapping list
	readImportETR = (id) => {
		return Axios.get(`back-office/import-etr/invoice/read/${id}`).then(({ data }) => {
			this.viewImportETRValues = data.data;
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}
}

decorate(ImportETRStore, {
	viewLogValues: observable,
	list_data: observable,
	total: observable,
	agGrid: observable,
	current_page: observable,
	per_page: observable,
	view_data: observable,
	viewETRType: observable,	
	logValues: observable,
	deleteValues: observable,
	viewImportETRValues: observable,
	viewRowData: observable,

	importETR: action,

	getList: action,
	setupGrid: action,
	setPageSize: action,
	setViewLogValues: action,
	setDeleteValues: action,
	setLogValues: action,
	getViewLogs: action,
	DeleteData: action,
	CancelData: action,
	getCheck: action,
	onGridChanged: action,
	onChildnGridChanged: action,
	downloadFile: action,
	readImportETR: action,
	setViewData: action,
})
