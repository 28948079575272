import React from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { Col, Row } from 'antd'
import DateRangeIterationElement from './DateRangeIterationElement'
import { vsmTransactionReport } from '../../../../config/messages'

const PeriodSelectionElement = observer(({ formDataList, form, onChange }) => (
	<Row gutter={24}>
		<Col>
			<InputComponent
				label="Period Selection"
				type="radio_button" name='period_selection'
				onChange={onChange}
				options={{
					className: "d-flex w-100",
					values: [{ value: 'custom', text: 'Custom' }, { value: 'auto', text: 'Auto' }]
				}}
			/>
		</Col>
		<Col >
			{(formDataList.includes('period_x_value') || formDataList.includes('period_x_value_type')) && (
				<>
					<label className="horizontal-required">Date Custom</label>
					<Row>
						{(formDataList.includes('period_x_value')) && (
							<Col span={4}>
								<InputComponent
									required type="number" name="period_x_value" min={2} max={12} className="w-100" onChange={onChange}
									rules={vsmTransactionReport.validation.period_x_value}
								/>
							</Col>
						)}
						{(formDataList.includes('period_x_value_type')) && (
							<Col span={20}>
								<InputComponent
									onChange={onChange}
									type="radio_button" name='period_x_value_type'
									options={{
										className: "d-flex w-100",
										values: [{ value: 'day', text: 'Day/s' }, { value: 'week', text: 'Week/s' }, { value: 'month', text: 'Month/s' }, { value: 'quarter', text: 'Quarter/s' }, { value: 'year', text: 'Year/s' }]
									}}
									tooltip="A period allows iteration over a set of dates and times, recurring at regular intervals, over a given period."
								/>
							</Col>
						)}
					</Row>
				</>
			)}
			{(formDataList.includes('period_date_range')) && (
				<>
					<label className="horizontal-required">Date Range</label>
					<DateRangeIterationElement form={form} onChange={onChange} />
				</>
			)}
		</Col>
	</Row>
))

export default PeriodSelectionElement
