import { observer } from "mobx-react-lite"
import React from "react"
import useStore from "../../../../store"
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'

const PreviewOrganisationReportTableElement = observer((props) => {
    const { SavedReportStore } = useStore()

    let gridOptions = {
        columnDefs: [
            { headerName: '# ID', field: 'id', filter: 'agNumberColumnFilter', pinned: 'left', width: 80, },

            {
                headerName: 'Organisation Name', field: 'organisation_name',
                width: 170,
                valueGetter: (params) => (params.data && params.data.organisation_name) ? params.data.organisation_name : "",
            },

            {
                headerName: 'Trade Name', field: 'trade_name',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.trade_name) ? params.data.trade_name : "",
            },

            {
                headerName: 'Role (Associated Organisation)', field: 'role',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (
                    params.data && params.data.organisation_role_mapping && params.data.organisation_role_mapping.map(x => x.role_name).join(', ')
                ),
            },

            {
                headerName: 'Registration Number', field: 'registration_number',
                width: 170,
                valueGetter: (params) => (params.data && params.data.registration_number) ? params.data.registration_number : "",
            },

            {
                headerName: 'Country', field: 'registration_country',
                width: 170,
                valueGetter: (params) => (params.data && params.data.global_countries) ? params.data.global_countries.country_name : "",
            },

            {
                headerName: 'Associated Ledger', field: 'associated_ledgers',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.associated_ledgers) ? params.data.associated_ledgers.name : "",
            },

            {
                headerName: 'Organisation Status', field: 'organisation_status',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.organisation_status) ? params.data.organisation_status : "",
            },

            {
                headerName: 'Exchange Ref Id', field: 'exchange_ref_id',
                width: 170,
                valueGetter: (params) => (params.data && params.data.exchange_ref_id) ? params.data.exchange_ref_id : "",
            },

            {
                headerName: 'Bank Name', field: 'bank_name',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_name) ? params.data.bank_name : "",
            },

            {
                headerName: 'Bank Account Type', field: 'bank_account_type_name',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_account_type_name) ? params.data.bank_account_type_name : "",
            },

            {
                headerName: 'Currency', field: 'currency',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.currency) ? params.data.currency : "",
            },

            {
                headerName: 'Account Number', field: 'account_number',
                width: 170,
                valueGetter: (params) => (params.data && params.data.account_no) ? params.data.account_no : "",
            },

            {
                headerName: 'IBAN Number', field: 'iban_number',
                width: 170,
                valueGetter: (params) => (params.data && params.data.iban_number) ? params.data.iban_number : "",
            },

            {
                headerName: 'Sort Code', field: 'sort_code',
                width: 170,
                valueGetter: (params) => (params.data && params.data.sort_code) ? params.data.sort_code : "",
            },

            {
                headerName: 'Swift or BIC Code', field: 'swift_bic_code',
                width: 170,
                valueGetter: (params) => (params.data && params.data.swift_bic_code) ? params.data.swift_bic_code : "",
            },

            {
                headerName: 'Bank Status', field: 'bank_status',
                width: 170,
                filter: 'agSetColumnFilter',
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.bank_status) ? params.data.bank_status : "",
            },
        ]
    }

    if (props.rowData && Array.isArray(props.rowData)) {
        return (
            <div className="" style={{ height: '70vh', marginBottom: 24 }}>
                <div className="ag-theme-alpine grid_wrapper">
                    <AgGridReact
                        rowData={props.rowData}
                        modules={AllModules}
                        columnDefs={gridOptions.columnDefs}
                        defaultColDef={{ ...LocalGridConfig.defaultColDef }}
                        columnTypes={LocalGridConfig.columnTypes}
                        overlayNoRowsTemplate={vsmCommon.noRecord}
                        onGridReady={props.onGridReady}
                        gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                        onColumnResized={SavedReportStore.onChildnGridChanged}
                        onColumnMoved={SavedReportStore.onChildnGridChanged}
                        onColumnPinned={SavedReportStore.onChildnGridChanged}
                        onSortChanged={SavedReportStore.onChildnGridChanged}
                    />
                </div>
            </div>
        )

    } else {
        return null
    }
})

export default PreviewOrganisationReportTableElement