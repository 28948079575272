import React, { useState } from 'react'
import { observer } from 'mobx-react'
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig'
import useStore from '../../../store'
import Unauthorized from '../../Unauthorized'
import { Button, Dropdown, Menu, PageHeader } from 'antd'
import RecordPerPage from '../../../components/RecordPerPage'
import EnterFullscreen from '../../../components/EnterFullscreen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { DownOutlined } from "@ant-design/icons";
import ListComponent from './component/ListComponent'
import AddComponent from './component/AddComponent'
import { MemeberType } from '../../../utils/GlobalFunction'
import EditComponent from './component/EditComponent'
import ViewComponent from './component/ViewComponent'


const Members = observer(() => {
	const { UserStore, MemberStore: { per_page, setPageSize, handleReset, setOpenFormType, readMemberDetail, exportData, exportLoading } } = useStore()
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)

	const menu = (
		<Menu>
			{MemeberType.map((item, index) => (
				<Menu.Item key={index} onClick={() => { setOpenFormType(item.text); setAddDrawer(true) }}>{item.text}</Menu.Item>
			))}
		</Menu>
	)

	const closeAddDrawer = () => {
		setAddDrawer(false)
		setOpenFormType(null)
	}

	const exportMembersDetails = () => {
		exportData()
	}

	const openEditMemberDrawer = (id) => {
		readMemberDetail(id)
		setEditDrawer(true)
	}

	const closeEditTradeDrawer = () => {
		setEditDrawer(false)
	}

	const openViewMemberDrawer = (id) => {
		readMemberDetail(id)
		setViewDrawer(true)
	}

	const closeViewMemberDrawer = () => {
		setViewDrawer(false)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.memebers.path} />
			{(!UserStore.checkPermission(119, 'list') ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.memebers.title}
					extra={[
						UserStore.checkPermission(119, 'list') && <Button key="1" loading={exportLoading} onClick={exportMembersDetails}>Export</Button>,
						UserStore.checkPermission(119, 'add') && <Dropdown overlay={menu}><Button>Add Members <DownOutlined /></Button></Dropdown>,
						<RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent openEditMemberDrawer={openEditMemberDrawer} openViewMemberDrawer={openViewMemberDrawer} />
					<AddComponent visible={addDrawer} close={closeAddDrawer} />
					<EditComponent visible={editDrawer} close={closeEditTradeDrawer} />
					<ViewComponent visible={viewDrawer} close={closeViewMemberDrawer} />
				</PageHeader>
			))}
		</>
	)
})

export default Members