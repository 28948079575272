import React, { useState } from 'react'
import { Form, Button, Drawer } from 'antd'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import FormComponent from './FormComponent'
import { vsmCashTransactions, vsmNotify } from '../../../../config/messages'
import moment from 'moment'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const { CashTransactionStore, SavedReportStore } = useStore()

	// Make function call to add new record
	const handleSubmit = (data) => {
		data.posted_on = data.posted_on && moment(data.posted_on).format("YYYY-MM-DD")
		data.value_on = data.value_on && moment(data.value_on).format("YYYY-MM-DD")
		data.cte_fields = data.cte_fields.filter(x => x && x.cte_field_id && x.field_value);
		setSaving(true)
		CashTransactionStore.AddData(data).then(() => {
			close()
			if (props.handleViewDetailCall) { props.handleViewDetailCall() }
			vsmNotify.success({
				message: vsmCashTransactions.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable/enable
	const handleChange = () => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	// reset form and close add form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
		SavedReportStore.main_organisation_roles = null
		SavedReportStore.main_people_roles = null
		SavedReportStore.main_organisation = null
		SavedReportStore.related_organisation = null
		SavedReportStore.related_organisation_role = null
		SavedReportStore.main_people = null
		SavedReportStore.related_people = null
		SavedReportStore.related_people_role = null
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`Add New Cash Transaction`}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button disabled={disabled} form='addeditform' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="ml-10" onClick={close}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent
				form={form}
				visible={props.visible}
				onChange={handleChange}
				onFinish={handleSubmit}
				tab={"add"}
			/>
		</Drawer>

	)
})

export default AddComponent
