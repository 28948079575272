import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Button, PageHeader, DatePicker, Select } from 'antd'
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../../config/BreadcrumbConfig'
import ListComponent from './component/ListComponent'
import RecordPerPage from '../../../../components/RecordPerPage'
import ViewComponent from './component/ViewComponent'
import Unauthorized from '../../../Unauthorized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import EnterFullscreen from '../../../../components/EnterFullscreen'

const AuditLogs = observer(() => {
	const [viewModal, setViewModal] = useState(false)
	const [id, setId] = useState(false)
	const [dateFilter, setDateFilter] = useState(null);
	const [selectFilter, setSelectFilter] = useState(null);
	const { UserStore, AuditLogStore, AuditLogStore: { per_page, userList, setPageSize, exportData, getUserList } } = useStore()

	useEffect(() => {
		getUserList()
	}, [getUserList])

	const setAuditId = (data) => setId(data)

	// Open form for View audit log
	const openViewModal = (data) => {
		setAuditId(data)
		setViewModal(true)
	}

	// Open form for View audit log
	const closeViewModal = () => setViewModal(false)

	// reset function
	const handleReset = async () => {
		await localStorage.removeItem('audit_log')
		AuditLogStore.agGrid.api.setFilterModel(null)
		AuditLogStore.agGrid.api.setSortModel(null)
		AuditLogStore.agGrid.columnApi.resetColumnState()
		AuditLogStore.agGrid.api.onFilterChanged(null)
	}

	return (
		<>
			<BreadcrumbComponent items={BreadcrumbConfig.AuditLogs.path} />
			{(!UserStore.checkPermission(82, 'list')) ? (<Unauthorized />) : (
				<PageHeader
					className={UserStore.fullscreen_class}
					title={BreadcrumbConfig.AuditLogs.title}
					extra={[
						<Select placeholder="Choose User" onChange={setSelectFilter}
							showSearch allowClear optionFilterProp="children"
							filterOption={(input, option) =>
								option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							} style={{ width: 140 }}
						>
							{userList && userList.map((item) => {
								return (<Select.Option value={item.id}>{item.first_name + " " + item.last_name}</Select.Option>)
							})}
						</Select>,
						<DatePicker disabledDate={function disabledDate(current) {
							return current && current.valueOf() > Date.now();
						}} key="3" onChange={setDateFilter} placeholder="Select From Date" />,
						<Button key="2" onClick={exportData}>Export as CSV</Button>,
						<RecordPerPage key="1" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
						<Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo} /></Button>,
						<EnterFullscreen key='5' />
					]}
				>
					<ListComponent dateFilter={dateFilter} selectFilter={selectFilter} openViewModal={openViewModal} />
					<ViewComponent id={id} setId={setAuditId} visible={viewModal} close={closeViewModal} />
				</PageHeader>
			)}
		</>
	)
})

export default AuditLogs
