import React, { useState, useEffect, useCallback } from 'react'
import { observer } from 'mobx-react';
import useStore from '../../../../../store'
import { Button, Drawer, PageHeader } from 'antd'
import ListComponent from './ListComponent'
import ViewImportLogDetailsComponent from './ViewImportLogDetailsComponent'
import { useHistory } from 'react-router-dom';
import ContentLoader from '../../../../../components/ContentLoader';
import { vsmImportETR, vsmNotify } from '../../../../../config/messages';
import { default as ViewETRComponent } from './ViewComponent';

const ViewImportLogComponent = observer((props) => {
	const [viewImportLogDetails, setViewImportLogDetails] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [loading, setLoading] = useState(true)

	const { ImportETRStore, ImportETRStore: { viewRowData, getViewLogs, setViewLogValues, setLogValues } } = useStore()
	const Store = { Import: ImportETRStore }
	let history = useHistory()


	// show error message & go back
	const invalidRecord = useCallback(() => {
		vsmNotify.error({
			message: vsmImportETR.InvalidRecord
		})
		history.goBack()
		setLoading(false)
	}, [history])

	// set data on refresh page
	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			Store.Import.view_data = null
			setViewLogValues(null)
			getViewLogs(props.importId).then((data) => {
				if (data) {
					setLoading(false)
				} else {
					invalidRecord()
				}
			}).catch((data) => {
				invalidRecord()
			})
		}

	}, [Store.Import, invalidRecord, setViewLogValues, getViewLogs, props.visible, props.importId])


	// open drawer for view
	const openViewDrawer = (data) => {
		ImportETRStore.readImportETR(data.id).then((res) => {
			setViewDrawer(true)
		}).catch(() => {
			vsmNotify.error({
				message: vsmImportETR.InvalidRecord
			})
		})
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	// open log detail drawer
	const openSetViewImportLogDetails = (data) => {
		setViewLogValues(data)
		if (data.import_log_line) {
			setLogValues(JSON.parse(data.import_log_line))
		}
		else {
			setLogValues('');
		}
		setViewImportLogDetails(true)
	}

	// close log detail drawer
	const closeSetViewImportLogDetails = () => setViewImportLogDetails(false)

	// Reset form id and close view form
	const close = () => {
		props.close()
		props.setId(null)
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title={`View Log - #${viewRowData && viewRowData.id} ${viewRowData && viewRowData.import_name}`}
			destroyOnClose={true}
		>
			{
				!loading && viewRowData ?
					<PageHeader
						extra={[
							<Button type="primary" key="1"><a href={viewRowData.import_filename_link}>Download Import File with Status Column</a></Button>,
							<Button key="2" onClick={close}>Back to List</Button>
						]}
					>
						<ListComponent
							openSetViewImportLogDetails={openSetViewImportLogDetails}
							openViewDrawer={openViewDrawer}
						/>
						<ViewImportLogDetailsComponent
							visible={viewImportLogDetails}
							close={closeSetViewImportLogDetails}
						/>
						<ViewETRComponent visible={viewDrawer} close={closeViewDrawer} />
					</PageHeader> : <ContentLoader />
			}
		</Drawer>
	)
})

export default ViewImportLogComponent
