import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Button, Form, Card, Upload, message, Row, Col } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import useStore from '../../../../store'
import InputComponent from '../../../../components/InputComponent'
import { vsmCTEImport, vsmNotify } from '../../../../config/messages'
import sampleImport from '../../../../assets/CashTransactionsCheckFile.csv'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const { CTEImportStore } = useStore()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)

	useEffect(() => {
		if (props.visible) {
			CTEImportStore.getBankList()
		}
	}, [CTEImportStore, props])

	const close = () => {
		props.close()
		form.resetFields()
		setSaving(false)
		setDisabled(true)
	}

	const handleSubmit = (data) => {
		setSaving(true)
		const formData = new FormData();
		form.getFieldValue('file').fileList.forEach((item, index) => {
			formData.append('files[]', item.originFileObj);
		})
		formData.append('dragged_files', JSON.stringify(form.getFieldValue('dragged_files')));
		CTEImportStore.AddData(formData).then(() => {
			close()
			vsmNotify.success({
				message: vsmCTEImport.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// check for valid form values then accordingly make save button disable / enable
	const handlChange = (data) => {
		setDisabled(true)
		if (form.getFieldValue('file').fileList.length <= 0) { } else {
			if (form.getFieldValue('file').fileList.length <= 10) {
				form.validateFields().then((data) => {
					var error = false
					form.getFieldValue('file').fileList.forEach(item => {
						if (item.status !== 'done') {
							error = true
							return false
						}
					})
					if (!error) { setDisabled(false) }
				})
			} else {
				form.setFields([{ name: "file", errors: ["Maximum 10 Files are allowed"] }])
			}
		}
	}

	const beforeUpload = ({ file, onSuccess, onError }) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 50;
		setTimeout(() => {
			if (!isCSV) {
				message.error('You can only upload CSV file!')
				onError('You can only upload CSV file!')
				return true;
			} else if (!isLt20M) {
				message.error('Please upload CSV file with size of less then 50 MB.')
				onError('Please upload CSV file with size of less then 50 MB.')
				return true;
			} else {
				onSuccess("ok")
				return false
			}
		}, 0)
	}

	const handleFileChange = async (info) => {
		var infoFile = info.fileList
		var tempValue = []
		if (infoFile && infoFile.length > 0) {
			infoFile.map((item, index) => {
				var bankName = null
				bankName = CTEImportStore.bank_list.find((field) => {
					return field.bankAccount_names.toUpperCase().endsWith(item.name.replace('.csv', '').split('-')[0].toUpperCase().trim())
				})

				var tempBankName = null
				if (form.getFieldValue('dragged_files') && form.getFieldValue('dragged_files')[index] && form.getFieldValue('dragged_files')[index]['bank_id']) {
					tempBankName = form.getFieldValue('dragged_files')[index]['bank_id']
				} else {
					tempBankName = bankName ? bankName.id : undefined
				}

				tempValue.push({
					file_name: item.name + '-' + index,
					bank_id: tempBankName
				})
				return null
			})

		}
		await form.setFields([{ name: "file", errors: [] }])
		await form.setFieldsValue({ dragged_files: tempValue })
		handlChange()
	}

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title='Import Cash Transaction'
			destroyOnClose={true}
			footer={[
				<div className="text-center" key="div">
					<Button key="1" form='addeditform' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled} onClick={handleSubmit}>Start Import in Background</Button>
					<Button key="2" onClick={close} > Cancel</Button>
				</div>
			]}
		>
			<Card>
				<Form form={form}
					labelCol={{ span: 6 }}
					id="addform"
					onFinish={handleSubmit}
				>
					<div className="text-right mr-25"><a href={sampleImport}>Download Sample File</a></div>
					<Form.Item className="full-width-dragger w-100" name='file' required extra={[
						<div>
							<label>Make sure file contain account number in start to automatically map it with bank account</label>
						</div>
					]} tooltip='To start your import easily you can drag CSV file or you can upload file directly choosing from files.'>
						<Upload.Dragger
							accept=".csv"
							name='file'
							multiple={true}
							onChange={handleFileChange}
							customRequest={beforeUpload}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">Drag your CSV file here or select by clicking here</p>
						</Upload.Dragger>
					</Form.Item>

					<Form.List name='dragged_files'>
						{
							(fields) => {
								return fields && fields.map((field, index) => {
									return (
										<>
											<label>Import File {index + 1}</label>
											<Row gutter={24}>
												<Col span={12}>
													<InputComponent
														required name={[field.name, 'file_name']}
														placeholder="Give your Import Name - Default will be Filename + Timestamp"
														onChange={handlChange}
														tooltip="Give some name to import to identify it later on. By default it will take filename + current timestamp combination."
														rules={vsmCTEImport.validation.file_name}
														maxLength={100}
														autoComplete="off"
													/>
												</Col>
												<Col span={12}>
													<InputComponent
														required placeholder="Select Bank Account"
														type="select"
														allowClear
														options={{
															values: CTEImportStore.bank_list,
															text_key: "bankAccount_names",
															value_key: "id",
														}}
														name={[field.name, 'bank_id']}
														key={[field.key, 'bank_id']}
														onChange={handlChange}
														tooltip="A name or code given to a cash account that indicates the account's purpose."
														rules={vsmCTEImport.validation.bank_name}
														maxLength={100}
														autoComplete="off"
													/>
												</Col>
											</Row>
										</>
									)
								})
							}
						}
					</Form.List>
				</Form>
			</Card>
		</Drawer >
	)
})

export default AddComponent
