import { Drawer } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'

const EditETRComponent = observer((props) => {
    return (
        <Drawer
            visible={props.visible}
            onClose={props.close}
            width={'60%'}
            placement={'right'}
            title={'Edit'}
        >
            <p>Edit</p>
        </Drawer>
    )
})

export default EditETRComponent