import React from 'react'
import { DownSquareOutlined, EditOutlined, UpSquareOutlined, LoginOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import SwitchComponent from './SwitchComponent'
import { DateComparator } from '../../../../../utils/GlobalFunction'
import useStore from '../../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal, openArchiveModal, openRestoreModal, handleProxyLogin } = props.agGridReact.props.frameworkComponents
	const { UserStore, UserStore: { user } } = useStore()

	return (
		<div className="action-column">

			{(UserStore.checkPermission(68, 'edit') && props.data.id !== 1) ? (
				<Button title="Edit Ledger" type="text" disabled={props.data.deleted_at !== null} onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>
			) :
				null
			}

			{(!UserStore.checkPermission(68, 'delete')) ? null : (
				(props.data.id !== 1 && props.data.deleted_at !== null) ? (
					<Button title="Restore Ledger" type="text" disabled={props.data.status === false} onClick={() => { openRestoreModal(props.data) }}><DownSquareOutlined /></Button>
				) : (props.data.id !== 1) ? (
					<Button title="Archive Ledger" type="text" disabled={(user.user_role) && (user.user_role.user_role_id !== 1)} onClick={() => { openArchiveModal(props.data) }}><UpSquareOutlined /></Button>
				) : null
			)
			}

			{((user.user_role) && (props.data) && (user.user_role.user_role_id === 1) && (props.data.status === true) && props.data.deleted_at === null && user.id !== props.data.id) ? (
				<Button title="Proxy Login" type="link" onClick={() => { handleProxyLogin(props.data) }} style={{ paddingLeft: 5 }}><LoginOutlined /></Button>
			) :
				null
			}

		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Ledger Name',
		field: 'name',
		width: 120
	}, {
		headerName: 'Organisation',
		field: 'organisation_name',
		valueGetter: (params) => (params.data && params.data.organisation_name) ? params.data.organisation_name : null,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
		},
		width: 150
	}, {
		headerName: 'Ledger Admin',
		field: 'ledger_admin_name',
		valueGetter: (params) => (params.data && params.data.ledger_admin_name) ? params.data.ledger_admin_name : null,
		width: 160
	}, {
		headerName: 'Child Ledgers',
		field: 'parent_count',
		width: 100
	}, {
		headerName: 'Associated Organisations',
		field: 'related_organisation_count',
		width: 100
	}, {
		headerName: 'Associated Peoples',
		field: 'related_people_count',
		width: 100
	}, {
		headerName: 'Total Users',
		field: 'total_user_count',
		width: 100
	}, {
		headerName: 'Last Updated On',
		field: 'updated_at',
		filter: 'agDateColumnFilter',
		width: 185,
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			comparator: DateComparator
		}
	}, {
		headerName: 'Status',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
			values: ["Active", "Deactive"]
		},
		width: 90
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
