import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import SwitchComponent from './SwitchComponent'
import useStore from '../../../../store'

export const ActionRenderer = (props) => {
	const { openEditModal } = props.agGridReact.props.frameworkComponents
	const { UserStore } = useStore()
	return (
		<div className="action-column">
			{UserStore.checkPermission(41, 'bank_accounts_edit') && <Button type="text" onClick={() => { openEditModal(props.data) }}><EditOutlined /></Button>}
		</div>
	)
}

export const gridOptions = {
	columnDefs: [{
		headerName: '#ID',
		field: 'id',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Bank Name',
		field: 'banks.name',
		width: 150
	}, {
		headerName: 'Account Type',
		field: 'bank_accounts_type.name',
		filter: 'agSetColumnFilter',
		valueGetter: (params) => (params.data && params.data.bank_accounts_type) ? params.data.bank_accounts_type.name : null,
		width: 150
	}, {
		headerName: 'Currency',
		field: 'currency.currency_name',
		filter: 'agSetColumnFilter',
		width: 130
	}, {
		headerName: 'Account No.',
		field: 'account_number',
		valueGetter: (params) => (params.data && params.data.account_number) ? params.data.account_number : null,
		width: 150
	}, {
		headerName: 'Sort Code',
		field: 'sort_code',
		valueGetter: (params) => (params.data && params.data.sort_code) ? params.data.sort_code : null,
		width: 150
	}, {
		headerName: 'IBAN',
		field: 'iban',
		valueGetter: (params) => (params.data && params.data.iban) ? params.data.iban : null,
		width: 150
	}, {
		headerName: 'Swift or BIC Code',
		field: 'swift_bic',
		valueGetter: (params) => (params.data && params.data.swift_bic) ? params.data.swift_bic : null,
		width: 150
	}, {
		headerName: 'Status',
		cellRendererFramework: function (data) {
			const { onSwitchChange } = data.agGridReact.props
			return <SwitchComponent onSwitchChange={onSwitchChange} values={data.data} />
		},
		field: 'published',
		sortable: false,
		filter: 'agSetColumnFilter',
		filterParams: {
			values: ["Active", "Deactive"]
		},
		width: 150
	}, {
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		sortable: false,
		filter: false,
		pinned: 'right',
		width: 115
	}]
}
