import React from 'react';
import { Card, Col, Layout, Row } from 'antd';
import { observer } from 'mobx-react';
import { MenuUnfoldOutlined, MenuFoldOutlined, SearchOutlined, BellOutlined, SettingOutlined } from '@ant-design/icons';
import InputComponent from '../InputComponent';
import { FaRegUserCircle } from "react-icons/fa";
import useStore from '../../store';
const { Header } = Layout;

const AppsHeaderComponent = observer((props) => {
    const { AppsAuthStore } = useStore()
    return (
        <>
            <Header className="site-layout-background site-header">
                <div className="d-flex align-items-center">
                    {(props.collapsed) ? (
                        <MenuUnfoldOutlined className="trigger" onClick={() => { props.setCollapsed(false) }} />
                    ) : (
                        <MenuFoldOutlined className="trigger" onClick={() => { props.setCollapsed(true) }} />
                    )}
                </div>
                <div className="d-flex align-items-center m-0">
                    <InputComponent
                        name='global_search'
                        placeholder='Search'
                        className='global_search_input_wrapper'
                        prefix={<SearchOutlined />}
                    />

                    <Card className='card_wrapper'>
                        <BellOutlined />
                    </Card>
                    <Card className='card_wrapper'>
                        <SettingOutlined />
                    </Card>
                    {/* {JSON.stringify(AppsAuthStore.originator_user.)} */}
                    <Card className='profile_card_wrapper'>
                        <Row gutter={24}>
                            <Col span={4}>
                                <FaRegUserCircle className='icon_wrapper' />
                            </Col>

                            <Col span={20}>
                                {AppsAuthStore.originator_user && <span style={{ color: '#ffffff', fontWeight: 'bold' }}>{AppsAuthStore.originator_user.first_name} {AppsAuthStore.originator_user.last_name}</span>}
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Header>
        </>
    )
})

export default AppsHeaderComponent;