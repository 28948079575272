import { observer } from 'mobx-react'
import React, { useState } from 'react'
import useStore from '../../../../store'
import { Form, Button, Modal, Input, Select, Col, Row, Checkbox, Card, Spin } from 'antd'
import { vsmNotify, vsmUserManagement } from '../../../../config/messages'
import InputComponent from '../../../../components/InputComponent'
import debounce from 'lodash/debounce'

const AddComponent = observer((props) => {
	const [form] = Form.useForm()
	const [saving, setSaving] = useState()
	const [disabled, setDisabled] = useState(true)
	const [nameDisabled, setNameDisabled] = useState(false)
	const [id, setId] = useState()
	const [ledger, setLedger] = useState([])
	const { UserManagementStore, UserStore } = useStore()
	const [SelectedLedger, setSelectedLedger] = useState(null)
	const [hidePermissionTable, setHidePermissionTable] = useState(false)
	const [enablePermission, setEnablePermission] = useState(true)
	const [fetchRoles, setFetchRoles] = useState(true)
	const [fetchLedger, setFetchLedger] = useState(true)
	const [fetchUserGroup, setFetchUserGroup] = useState(true)
	const [dealMakerGroup, setDealMakerGroup] = useState(false)
	const [isOrgList, setIsOrgList] = useState(false)
	const Store = {
		UserManagement: UserManagementStore,
		User: UserStore
	}

	// check for valid form values then accordingly make save button disable / enable & set value of user
	const handleChange = async (value) => {
		setSelectedLedger(form.getFieldValue('ledger'))
		if (form.getFieldValue('ledger') !== 1 && form.getFieldValue('role') === 1) {
			await form.setFieldsValue({ role: null })
		}
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	}

	const getNamesFromEMail = debounce(() => {
		var payload = {}
		let invEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0, 66})\.([a-z]{2, 6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1, 2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1, 2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1, 2})\]?$)/i;

		if (!invEmail.test(form.getFieldValue('email'))) {
			payload = {
				email: form.getFieldValue('email')
			}
			Store.UserManagement.getNameFromEmail(payload).then((data) => {
				if (data.data) {
					setNameDisabled(true)
					form.setFieldsValue({
						first_name: data.data.first_name,
						last_name: data.data.last_name
					})
					setId(data.data.id)
					setLedger(data.data.ledger_ids)
					handleChange()
				}
			}).catch(e => {
				setId(null)
				setNameDisabled(false)
				if (e.errors) { form.setFields(e.errors) }
			})
		} else {
			setId(null)
			setNameDisabled(false)
		}
	}, 200)

	// reset form and close add form
	const close = () => {
		form.resetFields()
		setEnablePermission(true)
		setHidePermissionTable(false)
		setDisabled(true)
		setNameDisabled(false)
		props.close()
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true)
		var actions = {}
		if (data.permissions) {
			data.permissions.map((item) => {
				item.actions.map(action => {
					if (action.is_selected === 1) {
						if (!actions[item.id]) { actions[item.id] = [] }
						actions[item.id].push(action.id)
					}
					return null
				})
				return null
			})
			data.permissions = actions
		} else {
			data.permissions = null
		}
		data.dm_user_group_status = data.dm_user_group_status && data.dm_user_group_status === true ? 1 : 0
		data.id = id ? id : null
		UserManagementStore.AddData(data).then(() => {
			props.close()
			vsmNotify.success({
				message: vsmUserManagement.addUser
			})
			setEnablePermission(true)
			setHidePermissionTable(false)
			setDisabled(true)
			setNameDisabled(false)
			form.resetFields()
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const handleChangeForRole = (data) => {
		UserManagementStore.getPermissionsList({ role_id: data }).then((data) => {
			setEnablePermission(false)
			form.setFieldsValue({
				permissions: data.data
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
		UserManagementStore.getOriginatorOrgList({ role_id: data }).then((data) => {
			if (data.data.length > 0) {
				setIsOrgList(true)
			} else {
				setIsOrgList(false)
			}
		})
	}

	const handleModifyPermissions = () => {
		setHidePermissionTable(true)
	}

	const handleDealMakerGroup = (e) => {
		setDealMakerGroup(e.target.checked)
	}

	return (
		<Modal
			centered
			title="Add New User"
			visible={props.visible}
			onCancel={close}
			cancelButtonProps={{ style: { display: 'none' } }}
			okButtonProps={{ style: { display: 'none' } }}
			footer={[
				<Button key="1" disabled={disabled} form='addform' loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={() => { close() }}>Cancel</Button>
			]}
			width={650}
		>
			<Form form={form} id="addform" onFinish={handleSubmit}
				onChange={handleChange}
				layout={'vertical'}
			>

				<Row gutter={24}>
					<Col span={24}>
						<Form.Item name="email" rules={vsmUserManagement.validation.email} label="Email" required tooltip='Messages distributed by electronic means from one computer user to one or more recipients via a network.'>
							<Input placeholder="Email Address" maxLength={100} onChange={getNamesFromEMail} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="first_name" rules={vsmUserManagement.validation.first_name} label="First Name" required tooltip='A personal Name that is given to user/ person at birth or baptism and used before a family name.'>
							<Input disabled={nameDisabled} placeholder="First Name" maxLength={100} />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="last_name" rules={vsmUserManagement.validation.last_name} label="Last Name" required tooltip='It is the Name of the family to which a person/ user is born.'>
							<Input disabled={nameDisabled} placeholder="Last Name" maxLength={100} />
						</Form.Item>
					</Col>

					{(Store.User.user.user_role && Store.User.user.user_role.user_role_id === 1) ? (
						<Col span={12}>
							<Form.Item name="ledger" rules={vsmUserManagement.validation.ledger} label="Ledger" required tooltip='Ledger stores data for every Transaction. Select Ledger you want to associate this user with.'>
								<Select placeholder="Select Ledger" onChange={handleChange} showSearch allowClear
									optionFilterProp="children"
									onFocus={() => fetchLedger && UserManagementStore.getLedgerList().then(() => setFetchLedger(false))}
									notFoundContent={fetchLedger ? <Spin size="small" /> : "No Record Found."}
									filterOption={(input, option) =>
										option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{
										UserManagementStore.ledger_list && UserManagementStore.ledger_list.map((item, index) => {
											if (ledger.includes(item.id)) {
												return null
											}
											return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
										})
									}
								</Select>
							</Form.Item>
						</Col>
					) : null}

					<Col span={12}>
						<Form.Item name="role" rules={vsmUserManagement.validation.role} label="Role in Ledger" required extra={[
							((Store.User.user.user_role.user_role_id && [1, 2].includes(Store.User.user.user_role.user_role_id)) || UserStore.checkPermission(66, 'add')) ?
								(<span key="1" style={{ textAlign: 'right' }}>
									<Button onClick={handleModifyPermissions} disabled={enablePermission} type="link" style={{ padding: 0, height: "auto" }}>Modify Permission</Button>
								</span>) : null
						]} tooltip='The function assumed or played by a person associated with group of permissions.'>
							<Select placeholder="Select Roles" allowClear onChange={(data) => {
								handleChange(data)
								handleChangeForRole(data)
							}}
								showSearch
								optionFilterProp="children"
								onFocus={() => fetchRoles && UserManagementStore.getRoleList().then(() => setFetchRoles(false))}
								notFoundContent={fetchRoles ? <Spin size="small" /> : "No Record Found."}
								filterOption={(input, option) =>
									option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{
									UserManagementStore.dropdown_role_list && UserManagementStore.dropdown_role_list.map((item, index) => {
										if (item.id === 1) {
											if (SelectedLedger === 1 && Store.User.user && Store.User.user.user_role && [1].includes(Store.User.user.user_role.user_role_id)) {
												return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
											}
										} else if (item.id === 2) {
											if (Store.User.user && Store.User.user.user_role && [1, 2].includes(Store.User.user.user_role.user_role_id)) {
												return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
											}
										} else {
											return <Select.Option key={index} value={item.id}>{item.role_name}</Select.Option>
										}
										return null
									})
								}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={12}>
						<InputComponent name="dm_user_group_status" type='checkbox' onChange={(e) => { handleDealMakerGroup(e); handleChange() }} label="Grant access of Deal Maker System" valuePropName={'checked'} tooltip="Add User Group for DealMaker" />
					</Col>
					{isOrgList && (
						<Col span={12}>
							<InputComponent
								required={isOrgList ? true : false}
								type='select'
								name='global_organisation_id'
								label="Organisation"
								placeholder="Select Organisation"
								allowClear
								onChange={handleChange}
								options={{
									values: UserManagementStore.org_list_dropdown,
									value_key: 'id',
									text_key: 'name'
								}}
								rules={vsmUserManagement.validation.organisation}
							/>
						</Col>
					)}
				</Row>

				{dealMakerGroup ? (
					<Row gutter={24}>
						<Col span={12}>
							<InputComponent
								onChange={handleChange}
								required
								mode='multiple'
								name='user_group_id'
								label="User Group"
								type="select"
								placeholder="Select User Group"
								showSearch
								optionFilterProp="children"
								onFocus={() => fetchUserGroup && UserManagementStore.getUserGroup().then(() => setFetchUserGroup(false))}
								notFoundContent={fetchUserGroup ? <Spin size="small" /> : "No Record Found."}
								filterOption={(input, option) =>
									option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								options={{
									values: UserManagementStore.dropdown_user_group,
									value_key: 'id',
									text_key: 'group_name'
								}}
								rules={vsmUserManagement.validation.user_group_id}
								tooltip="Allow User Group for DealMaker"
							/>
						</Col>
					</Row>
				) : null}

				{(hidePermissionTable) ? (
					<>
						<Row>
							<Col span={12}>Features</Col>
							<Col span={12}>Actions</Col>
						</Row>
						<Form.Item name="permissions" rules={vsmUserManagement.validation.permissions} className='permissions_form_item'>
							<Form.List name="permissions">
								{
									(fields => {
										return (
											fields.map(field => {
												var value = form.getFieldValue('permissions')[field.key]
												var isDisabled = false
												value.actions.map((action, index) => {
													if (action.action_name === 'list' && action.is_selected !== 1) {
														isDisabled = true
													}
													return null
												})
												var all_checked = 0
												value.actions.map((action, index) => {
													if (action.is_selected === 1) {
														all_checked++
													}
													return null
												})
												return (
													<>
														{value.actions.length > 0 && (
															<Card className="mt-10">
																<Row gutter={24}>
																	<Col span={10} style={{ borderRight: '1px solid #f0f0f0' }}><b>{value.title}</b></Col>
																	<Col span={14} >
																		<Checkbox checked={all_checked === value.actions.length} onChange={(e) => {
																			var temp = form.getFieldValue('permissions')

																			temp[field.key].actions.map((action, index) => {
																				temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																				return null
																			})
																			form.setFieldsValue({
																				permissions: temp
																			})
																		}}>Check all</Checkbox>

																		{value.actions.map((action, index) => {
																			var temp = action.action_name.replace(/_/g, ' ')
																			return (
																				<div>
																					<Form.Item
																						noStyle
																						name={[field.name, 'actions', index, 'is_selected']}
																					>
																						<Checkbox disabled={isDisabled && action.action_name !== 'list'} checked={action.is_selected === 1 ? true : false} value={action.id} onChange={(e) => {
																							var temp = form.getFieldValue('permissions')
																							temp[field.key].actions[index].is_selected = e.target.checked ? 1 : 0
																							if (temp[field.key].actions[index].action_name === 'list' && !e.target.checked) {
																								temp[field.key].actions.map((action, index) => {
																									temp[field.key].actions[index].is_selected = 0
																									return null
																								})
																							}

																							form.setFieldsValue({
																								permissions: temp
																							})
																						}}>{temp.includes('ETR') ? temp : temp.charAt(0).toUpperCase() + temp.slice(1)}</Checkbox>
																					</Form.Item>
																				</div>
																			)
																		})}
																	</Col>
																</Row>
															</Card>
														)}
													</>
												)
											})
										)
									})
								}
							</Form.List>
						</Form.Item>
					</>
				) : null}
			</Form>
		</Modal>
	)
})

export default AddComponent
