import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Drawer, Button, Form, Upload, message, Row, Col } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import useStore from '../../../../store'
import InputComponent from '../../../../components/InputComponent'
import { vsmImportETR, vsmNotify } from '../../../../config/messages'
import a_ETR_uploadSample from '../../../../assets/a_ETR_uploadSample.csv';
import c_ETR_uploadSample from '../../../../assets/c_ETR_uploadSample.csv';
import d_ETR_uploadSample from '../../../../assets/d_ETR_uploadSample.csv';
import f_ETR_uploadSample from '../../../../assets/f_ETR_uploadSample.csv';
import RSA_uploadSample from '../../../../assets/RSA_uploadSample.csv';

import { ETRList } from '../../../../utils/GlobalFunction'
import debounce from "lodash/debounce";

const ImportETRCSV = observer((props) => {
	const [form] = Form.useForm()
	const { ImportETRStore } = useStore()
	const [isDisabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState(false)
	const [uploadError, setuploadError] = useState(false)
	const [isFormUpdated, setisFormUpdated] = useState(false)

	const [attachInvoice, updateAttachInvoice] = useState([])


	useEffect(() => {
		if (isFormUpdated) {
			setisFormUpdated(false)
		}
	}, [isFormUpdated, setisFormUpdated])

	const close = () => {
		props.close()
		form.resetFields()
		setSaving(false)
		setuploadError(false)
		setisFormUpdated(false)
		updateAttachInvoice([])
		setDisabled(true)
	}

	const handleSubmit = (data) => {
		setSaving(true)
		const formData = new FormData();
		attachInvoice.forEach((item, index) => {
			formData.append(`pdf_files[${index}]`, item.originFileObj);
		})
		if (data?.drag_csv_file?.fileList?.length > 0) {
			formData.append('drag_csv_file', data?.drag_csv_file?.fileList[0]?.originFileObj);
		}
		formData.append('import_name', data.import_name);
		formData.append('etr_type', data.etr_type);
		ImportETRStore.importETR(formData).then(() => {
			close()
			vsmNotify.success({
				message: vsmImportETR.add
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	const handleChange = debounce(() => {
		setisFormUpdated(true);
		form.validateFields().then((data) => {
			if (data?.drag_csv_file?.fileList?.length > 0 && !uploadError) {
				setDisabled(false)
			}
			else {
				setDisabled(true)
			}
		}).catch(e => {
			setDisabled(true)
		})
	}, 500)


	const beforeUpload = ({ file, onSuccess, onError }) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === '';
		let isLt20M = file.size / 1024 / 1024 < 5;
		setTimeout(() => {
			if (!isCSV) {
				setuploadError(true);
				message.error('You can only upload CSV file!')
				onError('You can only upload CSV file!')
				return true;
			} else if (!isLt20M) {
				setuploadError(true);
				message.error('Please upload CSV file with size of less then 5 MB.')
				onError('Please upload CSV file with size of less then 5 MB.')
				return true;
			} else {
				setuploadError(false);
				onSuccess("ok")
				return false
			}
		}, 0)
	}

	const handleFileChange = async (info) => {
		await form.setFields([{ name: "drag_csv_file", errors: [] }])
		handleChange()
	}

	const getSampleFile = () => {
		if (form.getFieldValue('etr_type') === 1) {
			return a_ETR_uploadSample;
		}
		else if (form.getFieldValue('etr_type') === 3) {
			return c_ETR_uploadSample;
		}
		else if (form.getFieldValue('etr_type') === 4) {
			return d_ETR_uploadSample;
		}
		else if (form.getFieldValue('etr_type') === 5) {
			return f_ETR_uploadSample;
		}
		else if (form.getFieldValue('etr_type') === 6) {
			return RSA_uploadSample;
		}
		return a_ETR_uploadSample;
	}

	const getETRName = () => {
		let etr_name = 'a-ETR';
		let filterData = ETRList.filter(x => x.value === form.getFieldValue('etr_type'));
		if (filterData.length > 0) {
			etr_name = filterData[0]['text'];
		}
		return etr_name;
	}

	//------------------------- Attach PO/Invoice File -------------------------------//
	const uploadPropsAttachInvoice = {
		name: "file",
		accept: ".pdf",
		multiple: true,
		fileList: attachInvoice,
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1;
				x.valid = isLt1M;
				return x;
			})
			newFileList = newFileList.filter(x => x.valid === true);
			updateAttachInvoice(newFileList);
		},
	};
	const handleFileUploadChangeAttachInvoice = (file) => {
		let isCSV = file.type === "application/pdf";
		let isLt1M = file.size / 1024 / 1024 < 1;

		if (!isCSV) {
			message.error(
				`Only pdf file is allowed to upload and File size should not be more than 1MB`
			);
			return true;
		} else if (!isLt1M) {
			message.error(
				"Please upload PDF file with size of less then 5 MB."
			);
			// updateAttachInvoice([]);
			return true;
		} else {
			return false
		}
	};
	const dummyRequestAttachInvoice = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};


	const onDownloadAttachInvoice = (info) => {
		window.open(info.url, "_blank");
	};


	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			placement='right'
			width={'75%'}
			title='Import ETR'
			destroyOnClose={true}
			footer={[
				<div className="text-center" key="div">
					<Button key="1"
						form='importETRForm' className="mr-10"
						loading={saving} htmlType="submit" type="primary"
						disabled={isDisabled}
					>
						Save
					</Button>
					<Button key="2" onClick={close} > Cancel</Button>
				</div>
			]}
		>
			<Form form={form}
				id="importETRForm"
				onChange={handleChange}
				initialValues={ImportETRStore.initialValues}
				layout='vertical'
				onFinish={handleSubmit}
			>
				<Row gutter={24}>
					<Col span={16}>
						<Form.Item className="full-width-dragger w-100" name='drag_csv_file'
							rules={vsmImportETR.validation.drag_csv_file}
							required extra={[
								<div className='text-right mr-25'>
									<a href={getSampleFile()}>Download Sample File {getETRName()}</a>
								</div>
							]} tooltip='To start your import easily you can drag CSV file or you can upload file directly choosing from files.'>
							<Upload.Dragger
								accept=".csv"
								name='drag_csv_file'
								maxCount={1}
								multiple={false}
								onChange={handleFileChange}
								customRequest={beforeUpload}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">Drag your CSV file here or select by clicking here</p>
							</Upload.Dragger>
						</Form.Item>
					</Col>
					<Col span={8}>
						<InputComponent
							required
							type="select"
							label='Select ETR Type'
							options={{
								values: ETRList,
								value_key: "value",
								text_key: "text",
								rejected_keys: [2]
							}}
							placeholder="Select ETR Type"
							name="etr_type"
							rules={vsmImportETR.validation.etr_type}
							tooltip="Choose ETR type for import process"
							onChange={handleChange}
						/>
						<InputComponent
							required
							name="import_name"
							onChange={handleChange}
							label='Import Name'
							maxLength={100}
							placeholder="Give your Import Name - Default will be Filename + Timestamp"
							tooltip="Give some name to import to identify it later on. By default it will take filename + current timestamp combination."
							rules={vsmImportETR.validation.import_name}
						/>
					</Col>
					<Col span={24}>
						<Form.Item
							required
							className="full-width-dragger"
							name="pdf_files"
							tooltip="Drag PO or Invoice CSV file or you can upload file directly choosing from files."
							rules={vsmImportETR.validation.pdf_files}
						>
							<Upload.Dragger
								{...uploadPropsAttachInvoice}
								beforeUpload={handleFileUploadChangeAttachInvoice}
								customRequest={dummyRequestAttachInvoice}
								onDownload={onDownloadAttachInvoice}
								fileList={attachInvoice}
								disabled={false}
							>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Drag PO file and/or Invoice File
								</p>
							</Upload.Dragger>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Drawer >
	)
})

export default ImportETRCSV
