import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import { vsmCommon } from '../../../../../config/messages'
import useStore from '../../../../../store'
import LocalGridConfig from '../../../../../config/LocalGridConfig'
import moment from 'moment'

const ListComponent = observer((props) => {
	const { FXCurrenciesStore } = useStore()
	const ref = useRef(props.filter);

	useEffect(() => {
		if (FXCurrenciesStore.agGridLocal) {
			ref.current = props.filter;
			FXCurrenciesStore.agGridLocal.api.onFilterChanged();
		}
	}, [props.filter, FXCurrenciesStore])

	const isExternalFilterPresent = () => {
		return ref.current !== 'everyone';
	};

	const doesExternalFilterPass = (node) => {
		let currencies = props.form.getFieldValue("currencies")
		let date = props.form.getFieldValue("date")
		let date_range = props.form.getFieldValue("date_range")
		let dateCondition = date && moment(node.data.fx_rate_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')
		let dateRangeCondition = date_range && moment(node.data.fx_rate_date).format('YYYY-MM-DD') >= moment(date_range[0]).format('YYYY-MM-DD') && moment(node.data.fx_rate_date).format('YYYY-MM-DD') <= moment(date_range[1]).format('YYYY-MM-DD')
		if (ref.current) {
			if (currencies && !date && !date_range) {
				return node.data.currency_code.id === currencies
			} else if (currencies && date && !date_range) {
				return (node.data.currency_code.id === currencies && dateCondition)
			} else if (currencies && date_range && !date) {
				return (node.data.currency_code.id === currencies && dateRangeCondition)
			} else if (!currencies && (date_range || date)) {
				return date_range ? dateRangeCondition : dateCondition
			} else {
				return true
			}
		} else { return true }
	};

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				rowData={FXCurrenciesStore.viewHistory_list}
				modules={AllModules}
				columnDefs={gridOptions.columnDefs}
				defaultColDef={LocalGridConfig.defaultColDef}
				columnTypes={LocalGridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{ ActionRenderer }}
				isExternalFilterPresent={isExternalFilterPresent}
				doesExternalFilterPass={doesExternalFilterPass}
				onGridReady={FXCurrenciesStore.setupGridInner}
				onColumnResized={FXCurrenciesStore.onChildnGridChanged}
				onColumnMoved={FXCurrenciesStore.onChildnGridChanged}
				onColumnPinned={FXCurrenciesStore.onChildnGridChanged}
				gridOptions={LocalGridConfig.options}
				onFilterChanged={FXCurrenciesStore.onFilterChangedInner}
				onSortChanged={FXCurrenciesStore.onFilterChangedInner}
			/>
		</div>
	)
})

export default ListComponent
