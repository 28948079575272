import React from 'react'
import { observer } from "mobx-react";
import { Image, Row, Col } from 'antd'
import useStore from '../../../../store';
import RelatedOrganisationList from "../../../PeopleManagement/AllPeople/elements/ListElement/RelatedOrganization";
import RelatedPeopleList from "../../../PeopleManagement/AllPeople/elements/ListElement/RelatedPeople";
import TradeTransactions from "../../../PeopleManagement/AllPeople/elements/ListElement/TradeTransactions";
import CashTransactions from "../../../PeopleManagement/AllPeople/elements/ListElement/CashTransactions";
import Banks from "../../../PeopleManagement/AllPeople/elements/ListElement/Banks";

const ViewElement = observer((props) => {
	const { OrganisationStore: { viewOrgValues } } = useStore()

	var arrays = [], size = 2;
	var a = viewOrgValues.entity_attributes

	if (a && a.length) {
		for (let i = 0; i < a.length; i += size)
			arrays.push(a.slice(i, i + size));
	}

	return (
		<>
			<div class="ant-table ant-table-bordered custom-table">
				<div class="ant-table-container ">
					<div class="ant-table-content ant-table-small">
						<table>
							<tbody className="ant-table-tbody">
								<tr>
									<th width="150px">Organisation Name:</th>
									<td>{viewOrgValues.organisation_name && viewOrgValues.organisation_name}</td>
									<th width="150px">Registration Number:</th>
									<td>{viewOrgValues.registration_number && viewOrgValues.registration_number}</td>
									<td width="150px" rowspan="4">{viewOrgValues.logo_url && <Image src={process.env.React_APP_API_URL + viewOrgValues.logo_url} alt="" />}</td>
								</tr>
								<tr>
									<th>Parent Organisation:</th>
									<td>{viewOrgValues.parent && viewOrgValues.parent.organisation_name}</td>
									<th>FY Start Month:</th>
									<td>{viewOrgValues.fy_start_month}</td>
								</tr>
								<tr>
									<th>Trade Name:</th>
									<td>{viewOrgValues.trade_name && viewOrgValues.trade_name}</td>
									<th>Tax Clearance Access No:</th>
									<td>{viewOrgValues.revenue_access_number && viewOrgValues.revenue_access_number}</td>
								</tr>
								<tr>
									<th>Registration Country:</th>
									<td>{viewOrgValues.global_countries && viewOrgValues.global_countries.country_name}</td>
									<th>Organisation Currency:</th>
									<td>{viewOrgValues.global_currency && viewOrgValues.global_currency.currency_name}</td>
								</tr>
								<tr>
									<th>VAT No:</th>
									<td>{viewOrgValues.vat_number && viewOrgValues.vat_number}</td>
									<th>NACE Category & Code:</th>
									<td colspan="2">{viewOrgValues.global_nace_sections &&
										<>
											<span><strong>Category Name: </strong>{viewOrgValues.global_nace_sections.get_nace_category.category_name}</span><br />
											<span><strong>Category Code: </strong>{viewOrgValues.global_nace_sections.code + '-' + viewOrgValues.global_nace_sections.section}</span>
										</>
									}</td>
								</tr>
								<tr>
									<th>d-ETR Monthly Discount Percentage:</th>
									<td>{viewOrgValues.status && viewOrgValues.d_etr_purchase_discount}</td>
									<th>c-ETR Sell Rate Percentage:</th>
									<td colspan="4">{viewOrgValues.website && viewOrgValues.c_etr_sell_rate}</td>
								</tr>
								<tr>
									<th>Status:</th>
									<td>{viewOrgValues.status && viewOrgValues.status.status_name}</td>
									<th>Website:</th>
									<td colspan="4">{viewOrgValues.website && viewOrgValues.website}</td>
								</tr>
								{
									arrays && arrays.length > 0 && arrays.map((item) => {
										return (
											<tr>
												{item.map((val, i) => (
													<>
														<th>{val.attribute_name}:</th>
														<td colspan={i === 1 && '2'}>{(val.attribute_value === 'null' || val.attribute_value === null) ? '' : val.attribute_value}</td>
													</>
												))}
											</tr>
										)
									})
								}
							</tbody>
						</table>

						<table>
							<tbody className="ant-table-tbody">
								<tr>
									<th>Description:</th>
								</tr>
								<tr>
									<td>{viewOrgValues.description}</td>
								</tr>
							</tbody>
						</table>
						<Row>
							{(viewOrgValues.mutiple_addresses && viewOrgValues.mutiple_addresses.length > 0) &&
								<Col span={14}>
									<table>
										<tbody >

											<tr>
												<th>Address Details:</th>
												<td>{viewOrgValues.mutiple_addresses.map((item, index) => {
													return (
														<div key={index}>
															<ul>
																<li>{
																	(item.address_type ? 'Address Type: ' + item.address_type + ', ' : '') + 'Address Details: ' +
																	item.address_1 +
																	(item.address_2 ? ', ' + item.address_2 : '') +
																	(item.address_3 ? ', ' + item.address_3 : '') +
																	(item.city ? ', ' + item.city : '') +
																	(item.state_county ? ', ' + item.state_county : '') +
																	(item.postal_code ? ', ' + item.postal_code : '') + ', ' +
																	item.countries.country_name
																}</li>
															</ul>
														</div>
													)
												})}</td>
											</tr>
										</tbody>
									</table>
								</Col>
							}
							{
								viewOrgValues.multiple_phone && viewOrgValues.multiple_phone.length > 0 &&
								<Col span={12}>
									<table>
										<tbody className="ant-table-tbody">
											{
												viewOrgValues.multiple_phone && viewOrgValues.multiple_phone.map((item, index) => {
													var header = null
													if (index === 0) {
														header = <tr><th width="150px" colspan="2">Phone:</th></tr>
													}
													return (
														<>
															{header}
															<tr>
																<th>{item.contact_for}:</th>
																<td>{item.contact_value}</td>
															</tr>
														</>
													)
												})
											}
										</tbody>
									</table>
								</Col>
							}
							{
								viewOrgValues.email && viewOrgValues.email.length > 0 &&
								<Col span={12}>
									<table>
										<tbody className="ant-table-tbody">
											{
												viewOrgValues.email && viewOrgValues.email.map((item, index) => {
													var header = null
													if (index === 0) {
														header = <tr><th width="150px" colspan="2">Email:</th></tr>
													}
													return (
														<>
															{header}
															<tr>
																<th>{item.contact_for}:</th>
																<td>{item.contact_value}</td>
															</tr>
														</>
													)
												})
											}
										</tbody>
									</table>
								</Col>
							}
							{viewOrgValues?.settings_json &&
								<table>
									<tbody className="ant-table-tbody">
										<tr><th colspan="10">ETRs Data:</th></tr>
										<tr>
											<th>d-ETR Purchase Price</th>
											<td>{viewOrgValues?.settings_json?.purchase_payment && viewOrgValues?.settings_json?.purchase_payment}</td>

											<th>d-ETR Sell Rate</th>
											<td>{viewOrgValues?.settings_json?.default_sell_rate_d_etr && viewOrgValues?.settings_json.default_sell_rate_d_etr}</td>

											<th>b/c-ETR Sell Rate</th>
											<td>{viewOrgValues?.settings_json?.default_sell_rate_b_c_etr && viewOrgValues?.settings_json.default_sell_rate_b_c_etr}</td>

											<th>d-ETR Face Value Charge</th>
											<td>{viewOrgValues?.settings_json?.face_value_charge && viewOrgValues?.settings_json.face_value_charge}</td>

											<th>b/c-ETR Face Value Charge</th>
											<td>{viewOrgValues?.settings_json?.creditor_face_value_charge && viewOrgValues?.settings_json.creditor_face_value_charge}</td>
										</tr>
										<tr>
											<th>ETR LDC Premium</th>
											<td>{viewOrgValues?.settings_json?.ldc_primium && viewOrgValues?.settings_json.ldc_primium}</td>

											<th>d-ETR Agent Commission</th>
											<td>{viewOrgValues?.settings_json?.originator_application_fee && viewOrgValues?.settings_json.originator_application_fee}</td>

											<th>b/c-ETR Agent Commission</th>
											<td>{viewOrgValues?.settings_json?.agent_commission && viewOrgValues?.settings_json.agent_commission}</td>

											<th>Arrangement Fee</th>
											<td>{viewOrgValues?.settings_json?.arrangement_fee && viewOrgValues?.settings_json.arrangement_fee}</td>

											<th>Processing Fee</th>
											<td>{viewOrgValues?.settings_json?.processing_commission && viewOrgValues?.settings_json.processing_commission}</td>
										</tr>
										<tr>
											<th>Trade Fee</th>
											<td>{viewOrgValues?.settings_json?.trade_commission && viewOrgValues?.settings_json.trade_commission}</td>

											<th>d-ETR Trade Limit</th>
											<td>{viewOrgValues?.settings_json?.trade_limit_d_etr && viewOrgValues?.settings_json.trade_limit_d_etr}</td>

											<th>c-ETR Trade Limit</th>
											<td>{viewOrgValues?.settings_json?.trade_limit_c_etr && viewOrgValues?.settings_json.trade_limit_c_etr}</td>

											<th>ETR Months</th>
											<td>{viewOrgValues?.settings_json?.etr_months && viewOrgValues?.settings_json.etr_months}</td>

											<th>Early Payment Discount</th>
											<td>{viewOrgValues?.settings_json?.payment_discount && viewOrgValues?.settings_json.payment_discount}</td>
										</tr>
									</tbody>
								</table>
							}
						</Row>
					</div>
				</div>
			</div>


			<RelatedOrganisationList id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<RelatedPeopleList id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<TradeTransactions id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<CashTransactions id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

			<Banks id={props.id} currentTab={"organiation"} handleViewDetailCall={props.handleViewDetailCall} />

		</>
	)
})

export default ViewElement
