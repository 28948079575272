import React from 'react';
import { observer } from 'mobx-react';

const FormComponent = observer((props) => {
    return (
        <div>
            FormComponent
        </div>
    );
})

export default FormComponent;