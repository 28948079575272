import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Row, Col, Button, Drawer } from 'antd'
import ListComponent from './ListComponent'
import ViewImportLogDetailsComponent from './ViewImportLogDetailsComponent'
import VerifyComponent from '../VerifyComponent'
import ViewComponent from '../../../CashTransactions/components/ViewComponent'
import useStore from '../../../../../store'
import ContentLoader from '../../../../../components/ContentLoader';
import { useHistory } from 'react-router-dom';
import { vsmCTEImport, vsmNotify } from '../../../../../config/messages';

const ViewImportLogComponent = observer((props) => {
	const { CTEImportStore, UserStore, LedgerBankAccountStore } = useStore()
	const [viewImportLogDetails, setViewImportLogDetails] = useState(false)
	const [loading, setLoading] = useState(true)
	const [importValues, setImportValues] = useState(null)
	const [verifyDrawer, setVerifyDrawer] = useState(false)
	const [transactionId, setTransactionId] = useState(null)
	const [viewDrawer, setViewDrawer] = useState(false)
	let history = useHistory()

	// show error message & go back
	const invalidRecord = useCallback(() => {
		vsmNotify.error({
			message: vsmCTEImport.InvalidRecord
		})
		history.goBack()
		setLoading(false)
	}, [history])

	const close = () => {
		props.close()
		props.setId(null)
	}

	const openSetViewImportLogDetails = (data) => {
		CTEImportStore.setViewLogValues(data)
		CTEImportStore.getLogDetails(data.id)
		setViewImportLogDetails(true)
	}

	// close log detail drawer
	const closeSetViewImportLogDetails = () => setViewImportLogDetails(false)

	//open verify drawer
	const openVerifyDrawer = (data, type = 'inner_list') => {
		setVerifyDrawer(true)
		CTEImportStore.setVerifyValues({ ...data, data_from: type })
	}

	//close verify drawer
	const closeVerifyDrawer = () => {
		setVerifyDrawer(false)
		recordDetails()
	}

	// set id for view component
	const setViewId = (id) => setTransactionId(id)

	// open drawer for view
	const openViewDrawer = (id) => {
		LedgerBankAccountStore.getDynamicFieldList()
		setViewDrawer(true)
		setViewId(id)
	}

	// close drawer for view
	const closeViewDrawer = () => setViewDrawer(false)

	const recordDetails = useCallback(() => {
		CTEImportStore.setViewLogValues()
		CTEImportStore.getRecordById(props.importId).then((data) => {
			if (data) {
				CTEImportStore.getViewLogList(props.importId)
				setImportValues(data)
				setLoading(false)
			} else {
				invalidRecord()
			}
		}).catch((data) => {
			invalidRecord()
		})
	}, [CTEImportStore, invalidRecord, props.importId])

	useEffect(() => {
		if (props.visible) {
			setLoading(true)
			recordDetails()
		}
	}, [CTEImportStore, recordDetails, invalidRecord, props])

	return (
		<Drawer
			visible={props.visible}
			onClose={close}
			title={`View Log - #${importValues && importValues.id} ${importValues && importValues.import_name}`}
			placement='right'
			width={'75%'}
			destroyOnClose={true}
			footer={[
				<div key="1" className="d-flex justify-content-center">
					<Button type="primary" key="1"><a href={importValues && importValues.import_filename_link}>Download Import File with Status Column</a></Button>
					<Button className="ml-10" onClick={close}>Back to List</Button>
				</div>
			]}
		>
			{
				!loading && importValues ?
					<>
						<Row gutter={24}>
							<Col flex={1}>
								<Row gutter={24}>
									<Col>
										<ul className="pl-15">
											<li>Total Imported Transactions: {importValues.total_transactions}</li>
											<li>Successfully Imported : {importValues.imported_transactions}</li>
											<li>Identified Transactions : {importValues.identified_count}</li>
										</ul>
									</Col>
									<Col>
										<ul className="pl-15">
											<li>Problematic with Identified Count : {importValues.problematic_with_identified_count}</li>
											<li>Bank Name:  {importValues.bank_account && importValues.bank_account.name}</li>
											<li>Bank Account Number / IBAN: {importValues.bank_account && importValues.bank_account.account_number ? importValues.bank_account.account_number : importValues.bank_account.iban}</li>
										</ul>
									</Col>
								</Row>
							</Col>
							<Col>
								{
									CTEImportStore.viewValues.problematic_tr_id || CTEImportStore.viewValues.autoidentified_tr_id ? (
										UserStore.checkPermission(76, 'edit') && <Button key="1" onClick={() => openVerifyDrawer(CTEImportStore.viewValues, 'outer_list')}>Verifying mode</Button>
									) : (
										UserStore.checkPermission(76, 'edit') && <Button key="1" disabled>Verifying mode</Button>
									)
								}
							</Col>
						</Row>

						<ListComponent openSetViewImportLogDetails={openSetViewImportLogDetails} openVerifyDrawer={openVerifyDrawer} openViewDrawer={openViewDrawer} />
						<ViewImportLogDetailsComponent visible={viewImportLogDetails} close={closeSetViewImportLogDetails} />
						{CTEImportStore.viewValues && <VerifyComponent
							problematic_id={CTEImportStore.viewValues.problematic_tr_id}
							autoIdentified_id={CTEImportStore.viewValues.autoidentified_tr_id}
							import_name={CTEImportStore.viewValues.import_name}
							visible={verifyDrawer}
							close={closeVerifyDrawer} />}
						<ViewComponent transactionId={transactionId} setId={setViewId} visible={viewDrawer} close={closeViewDrawer} />
					</> : <ContentLoader />
			}
		</Drawer>
	)
})

export default ViewImportLogComponent
