import Axios from "axios";
import { action, decorate, observable } from "mobx";
import GridConfig from "../../config/GridConfig";
import { convertError, convertTextToID, etr_type } from "../../utils/GlobalFunction";

export default class AllETRStore {
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	agGrid = null
	viewValue = null
	etr_type = [
		{ id: 1, etr_type: 'a-ETR' },
		{ id: 2, etr_type: 'b-ETR' },
		{ id: 3, etr_type: 'c-ETR' },
		{ id: 4, etr_type: 'd-ETR' },
		{ id: 5, etr_type: 'f-ETR' },
		{ id: 6, etr_type: 'Rsa' }
	]
	filter_status = [
		{ id: 1, status_name: 'Draft' },
		{ id: 2, status_name: 'Awaiting Approval' },
		{ id: 3, status_name: 'Approved' },
		{ id: 4, status_name: 'Rejected' },
	]
	initialValues = {
		etr_type: 1
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	onGridChanged = (params) => {
		localStorage.setItem('All_ETR_LIST', JSON.stringify(params.columnApi.getColumnState()))
	}

	setupGrid = (params) => {
		this.agGrid = this.agGridServer = params
		const { api } = params
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('All_ETR_LIST')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				let filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }

				if (filter_data.final_filter['etr_type']) {
					filter_data.final_filter['etr_type'].values = convertTextToID(filter_data.final_filter['etr_type'], this.etr_type, 'etr_type', 'id')
				}

				if (filter_data.final_filter['status']) {
					filter_data.final_filter['status'].values = convertTextToID(filter_data.final_filter['status'], this.filter_status, 'status_name', 'id')
				}

				let payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
					etr_type: [1, 2, 3, 4, 5],
				}
				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('All_ETR_LIST')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

	getList = (payload = {}) => {
		return Axios.post(`back-office/all-etr`, payload).then(({ data }) => {
			if (data.data.length) {
				data.data.forEach((item) => {
					item.etr_type = etr_type[item.etr_type];
				})
			}
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	viewETR = (id) => {
		return Axios.get(`back-office/all-etr/read/${id}`).then(({ data }) => {
			this.viewValue = data.data
			return data
		})
	}

	AuthETR = (data) => {
		return Axios.post(`back-office/all-etr/authorise/${data.id}`, data).then((data) => {
			if (this.setupGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = [];
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}
}


decorate(AllETRStore, {
	per_page: observable,
	current_page: observable,
	total: observable,
	agGrid: observable,
	viewValue: observable,
	onGridChanged: action,
	setupGrid: action,
	createDatasource: action,
	getList: action,
	setPageSize: action,
	viewETR: action
})
