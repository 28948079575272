import { notification } from "antd"
import IBAN from 'iban'

const vsmAuth = {
	success: "Logged in successful",
	forgot_success: "An email successfully sent to user with link to set new password.",
	reset_success: "Password has been updated successfully.",
	validation: {
		email: [
			{ required: true, message: "Email Address cannot be empty." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address, please enter the valid email address" },
		],
		password: [
			{ required: true, message: "Password cannot be empty." },
			{ pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/, message: "Password entry does not meet criteria." },
		],
		confirmpassword: [
			{ required: true, message: "Confirm Password cannot be empty." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue('password') === value) {
						return Promise.resolve();
					}
					return Promise.reject('New Password and Confirm Password does not match.');
				},
			}),
		]
	}
}

const vsmCommon = {
	noRecord: "No Records Found.",
}

const vsmUserManagement = {
	addUser: "User created successfully.",
	editUser: "User updated successfully.",
	deleteUser: "User Archived successfully.",
	modifyUser: "Permission updated successfully.",
	resetUser: "User password reset successfully.",
	restoreUser: "User Restored successfully.",
	activeUser: "User Activated Successfully",
	deactiveUser: "User Deactivated Successfully",
	validation: {
		first_name: [
			{ required: true, message: "First Name cannot be blank." },
			{ min: 3, message: "First Name should have minimum of 3 characters." },
			{ pattern: /^([A-Za-z0-9.-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/, message: "First Name should contain Alphabetical, Dash, Dot ,  and Numerical characters only. Other Special characters are not allowed." }
		],
		last_name: [
			{ required: true, message: "Last Name cannot be blank." },
			{ min: 3, message: "Last Name should have minimum of 3 characters." },
			{ pattern: /^([A-Za-z0-9.-\s\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/, message: "Last Name should contain Alphabetical, Dash, Dot and Numerical characters only. Other Special characters are not allowed." }
		],
		email: [
			{ required: true, message: "Email cannot be blank." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		role: [
			{ required: true, message: "Please select the Role. It cannot be blank." },
		],
		ledger: [
			{ required: true, message: "Please select the Ledger. It cannot be blank." },
		],
		user_group_id: [
			{ required: true, message: "Please select the User Group. It cannot be blank." },
		],
		organisation: [
			{ required: true, message: "Please select Organisation." },
		],
		permissions: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					let permissionsArray = getFieldValue('permissions');
					if (permissionsArray) {
						let verified = false
						permissionsArray.map((item) => {
							if (item.actions.length > 0) {
								let is_selected_index = item.actions.filter(x => x.is_selected === 1)
								if (is_selected_index.length > 0) {
									verified = true
								}
							}
							return null
						})
						if (verified) {
							return Promise.resolve();
						} else {
							return Promise.reject('Atleast one permission should be selected.');
						}
					} else {
						return Promise.resolve();
					}
				}
			})
		]

	}
}

const vsmRolemNagement = {
	addRole: "User role created successfully.",
	editRole: "User role updated successfully.",
	deleteRole: "User role removed successfully.",
	selectAtleastOneRole: "Please select at least one permission to create role. ",
	validation: {
		role_name: [
			{ required: true, message: "Role name cannot be blank." },
			{ pattern: /^[a-zA-Z ]*$/, message: "Role name can contain only alphabets." }
		]
	}
}

const vsmTransactionFields = {
	add: "Transaction Field Added Successfully.",
	delete: "Transaction Field Removed Successfully.",
	edit: "Transaction Field Updated successfully.",
	unpublish: "Transaction Field Unpublished successfully.",
	publish: "Transaction Field Published successfully.",
	validation: {
		field_name: [
			{ required: true, message: "Field name cannot be blank." },
			// { min: 3, message: "Transaction Field should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Field name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		field_type: [{ required: true, message: "Please select Field type." }],
		algorithm_id: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue('field_type')) {
						if (["CSV Column", "Date"].includes(getFieldValue('field_type'))) {
							return Promise.resolve();
						} else {
							if (getFieldValue('default_value')) {
								return Promise.resolve();
							} else {
								if (getFieldValue('algorithm_id')) {
									return Promise.resolve();
								} else {
									return Promise.reject('For Field type other than CSV Column and Date, Either Algorithm or Default value is required.');
								}
							}
						}
					} else {
						return Promise.resolve();
					}
				}
			})
		],
		default_value: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue('field_type')) {
						if (["CSV Column", "Date"].includes(getFieldValue('field_type'))) {
							if (["Date"].includes(getFieldValue('field_type'))) {
								let invInput = /^[+-]?[0-9]+$/
								if (getFieldValue('default_value') && !invInput.test(getFieldValue('default_value'))) {
									return Promise.reject("Only positive or negative numbers are allowed when field type is Date.")
								}
							}

							if (["CSV Column"].includes(getFieldValue('field_type'))) {
								let invInput = /^[A-Za-z0-9-. ]*$/
								if (!invInput.test(getFieldValue('default_value'))) {
									return Promise.reject("Emojis and Special characters are not allowed as an input in default value.")
								}
							}

							// return Promise.resolve();
						} else {
							if (getFieldValue('default_value')) {
								if (["Number", "Charges"].includes(getFieldValue('field_type'))) {
									let invInput = /^[0-9]+([.][0-9]+)?$/
									if (!invInput.test(getFieldValue('default_value'))) {
										return Promise.reject("Only Integer or Decimal Numbers are allowed")
									}
								} else if (["Flag"].includes(getFieldValue('field_type'))) {
									let invInput = /^[01]$/
									if (!invInput.test(getFieldValue('default_value'))) {
										return Promise.reject("Only 0 or 1 number is allowed when field type is Flag.")
									}
								} else if (["Text"].includes(getFieldValue('field_type'))) {
									let invInput = /^[A-Za-z0-9-. ]*$/
									if (!invInput.test(getFieldValue('default_value'))) {
										return Promise.reject("Emojis and Special characters are not allowed as an input in default value.")
									}
								} else {
									return Promise.resolve();
								}
							} else {
								if (!getFieldValue('algorithm_id')) {
									return Promise.reject('For Field type other than CSV Column and Date, Either Algorithm or Default value is required.');
								}
							}
						}
					}
					return Promise.resolve();
				}
			})
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
		],
		app_flag: [
			{ required: true, message: "Please select made for." },
		]
	}
}

const vsmTransactionTypes = {
	add: "Trade Type Saved successfully.",
	edit: "Trade Type Updated successfully.",
	delete: "Trade Type Removed successfully.",
	validation: {
		type_name: [
			{ required: true, message: "Trade Type cannot be blank." },
			// { min: 3, message: "Type name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Trade Type should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break" }
		]
	}
}

const vsmTransactionSubTypes = {
	add: "Transactions Type Saved successfully",
	edit: "Transactions Type Updated successfully",
	delete: "Transactions Type Removed successfully",
	validation: {
		sub_type_name: [
			{ required: true, message: "Transactions Type cannot be blank." },
		]
	}
}

const vsmTransactionFieldMapping = {
	add: "Trade Transaction saved successfully.",
	edit: "Trade Transaction updated successfully.",
	delete: "Trade Transaction Removed Successfully.",
	unpublish: "Trade Transaction Unpublished Successfully.",
	publish: "Trade Transaction Published Successfully.",
	validation: {
		type: [{ required: true, message: "Please select Trade Type." }],
		sub_type: [{ required: true, message: "Please select Trade Type." }],
		mapping_name: [
			{ required: true, message: "Trade Transaction cannot be blank." },
			// { min: 3, message: "Mapping name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Trade Transaction should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		friendly_name: [
			{ required: true, message: "Friendly name cannot be blank." },
			// { min: 3, message: "Mapping name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Friendly Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		transfer_id: [{ required: true, message: "Please select transaction type to transfer." }],
	}
}

const vsmTransactionModels = {
	add: "Transaction Model Saved successfully.",
	edit: "Transaction Model Updated successfully.",
	delete: "Transaction Model removed Successfully.",
	unpublish: "Transaction Model Unpublished Successfully.",
	publish: "Transaction Model Published Successfully.",
	verify: "Transaction Model marked as Verified in Database and Model is ready to be Published.",
	unverify: "Transaction Model marked as Unverified in Database and Model is Unpublished now.",
	journal_line: "Minimum one Journal Line should be added in Model.",
	selectedRecord: "Please select at least one record.",
	verifyAllSuccess: "All existing Transactions are verfied successfully.",
	somethingWentWrong: "Something Went Wrong",
	InvalidRecord: "Invalid Request.",
	validation: {
		mapping_name: [{ required: true, message: "Please select Trade Transaction." }],
		model_code: [
			{ required: true, message: "Code cannot be blank." },
			{ min: 3, message: "Code should contain minimum 3 characters" },
			{ pattern: /^[a-zA-Z0-9\S ]*$/, message: "Code should not contain white space character like new line and tab." }
		],
		model_name: [
			{ required: true, message: "Model Name cannot be blank." },
			{ min: 3, message: "Model Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]*$/, message: "Model Name should not contain white space character like new line and tab." }
		],
		model_description: [
			{ required: true, message: "Model Description cannot be blank." },
			{ min: 10, message: "Model Description should contain minimum 10 characters." },
		],
		value: [
			{ pattern: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/, message: "Only numbers are allowed. Special characters & alphabets are not allowed." }
		]
	}
}

const vsmJournalLines = {
	delete: "Journal line deleted from here, You need to save model to make changes in database.",
	validation: {
		journal_id: [
			{ required: true, message: "Please select Journal." },
		],
		algorithm_id: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("map_with") === "Algorithm") {
						if ((getFieldValue('algorithm_id') === null || getFieldValue('algorithm_id') === undefined || getFieldValue('algorithm_id') === '') && (getFieldValue('field_id') === null || getFieldValue('field_id') === undefined || getFieldValue('field_id') === '')) {
							return Promise.reject('Please select Algorithm.');
						}
					}
					return Promise.resolve();
				}
			})
		],
		field_id: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("map_with") === "Field") {
						if ((getFieldValue('field_id') === null || getFieldValue('field_id') === undefined || getFieldValue('field_id') === '') && (getFieldValue('algorithm_id') === null || getFieldValue('algorithm_id') === undefined || getFieldValue('algorithm_id') === '')) {
							return Promise.reject('Please select Field.');
						}
					}
					return Promise.resolve();
				}
			})
		]
	}
}

const vsmTaxSettings = {
	delete: "Tax Setting Removed successfully",
	add: "Tax Setting Saved successfully",
	edit: "Tax Setting Updated successfully",
	validation: {
		tax_name: [
			{ required: true, message: "Tax name cannot be blank." },
			{ min: 3, message: "Tax name should contain minimum 3 characters." },
			{ pattern: /^[ A-Za-z0-9-@]*$/, message: "Tax name should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		tax_type: [
			{ required: true, message: "Please select Tax Type." }
		],
		enum_value: [
			{ pattern: /^[a-zA-Z&\s]*$/, message: "Tax Type should contain Space or Alphabetical characters only. Numbers and other Special characters are not allowed." }
		]
	}
}


const vsmJournalGroups = {
	add: "Journal subgroup Saved successfully",
	edit: "Journal subgroup Updated successfully",
	delete: "Journal subgroup Removed Successfully",
	validation: {
		journal_group_name: [
			{ required: true, message: "Journal subgroup cannot be blank." },
			{ max: 100, message: "Journal subgroup should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Journal subgroup should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		parent_id: [{ required: true, message: "Please select parent Journal group." }],
		transfer_id: [{ required: true, message: "Please select journal group to transter." }]
	}
}

const vsmJournals = {
	add: "Journal Saved successfully",
	edit: "Journal Updated successfully",
	delete: "Journal Removed successfully",
	import: "Journal Imported Successfully",
	validation: {
		journal_groups: [{ required: true, message: "Please select Journal Group." }],
		journal_code: [
			{ required: true, message: "Code cannot be blank." },
			{ min: 3, message: "Code should contain minimum of 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Code should contain Alphabetical or Numeric characters only. Space and Other Special characters are not allowed." }
		],
		journal_label: [
			{ required: true, message: "Label cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Journal Label should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		journal_desc: [
			{ required: true, message: "Description cannot be blank" },
			{ min: 10, message: "Description should contain minimum of 10 characters." },
		],
		tax_settings_id: [{ required: true, message: "Please select Tax Setting." }],
		transfer_id: [{ required: true, message: "Please select journal type to transter." }]
	}
}

const vsmAlgorithm = {
	add: "Algorithm Saved successfully",
	edit: "Algorithm Updated successfully",
	delete: "Algorithm Removed successfully",
	publish: "Algorithm Published Successfully.",
	unpublish: "Algorithm Unpublished Successfully.",
	InvalidRecord: "This record is no longer available.",
	validation: {
		algorithm_name: [
			{ required: true, message: "Algorithm Name cannot be blank." },
			// { min: 3, message: "Algorithm Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]*$/, message: "Algorithm Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		algorithm_desc: [
			{ required: true, message: "Algorithm Description cannot be blank." },
			{ min: 10, message: "Algorithm Description should contain minimum 10 characters." },
		]
	}
}

const vsmImport = {
	add: "Import Trade Started successfully",
	delete: "Import Removed successfully.",
	cancel: "Import Stopped & Removed successfully.",
	InvalidRecord: "Invalid Request.",
	validation: {
		import_name: [
			{ required: true, message: "Import Name cannot be blank." },
			{ min: 3, message: "Import Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Import Name Should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		mapping_id: [
			{ required: true, message: "Please select Trade Transaction Name" },
		],
		model: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					// if (!getFieldValue("model") || getFieldValue("model").length === 0) {
					// 	return Promise.reject('Please Select model.');
					// }
					return Promise.resolve();
				}
			}),
		],
		file: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (!getFieldValue("import_filename") || getFieldValue("import_filename").fileList.length === 0) {
						return Promise.reject('File must be added/uploaded.');
					}
					return Promise.resolve();
				}
			})
		],
		extrafile: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (!getFieldValue("extrafile") || getFieldValue("extrafile").fileList.length === 0) {
						return Promise.reject('File must be added/uploaded.');
					}
					return Promise.resolve();
				}
			})
		],
		items_mapping: [
			{ required: true, message: "Please select mapping column for items." }
		]
	}
}

const vsmImportETR = {
	add: "Import ETR Started successfully",
	delete: "Import ETR Removed successfully.",
	cancel: "Import Stopped & Removed successfully.",
	InvalidRecord: "Invalid Request.",
	validation: {
		import_name: [
			{ required: true, message: "Import Name cannot be blank." },
			{ min: 3, message: "Import Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Import Name Should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		etr_type: [
			{ required: true, message: "Please select ETR Type" },
		],
		model: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					// if (!getFieldValue("model") || getFieldValue("model").length === 0) {
					// 	return Promise.reject('Please Select model.');
					// }
					return Promise.resolve();
				}
			}),
		],
		drag_csv_file: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (!getFieldValue("drag_csv_file") || getFieldValue("drag_csv_file").fileList.length === 0) {
						return Promise.reject('File must be added/uploaded.');
					}
					return Promise.resolve();
				}
			})
		],
		pdf_files: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (!getFieldValue("pdf_files") || getFieldValue("pdf_files").fileList.length === 0) {
						return Promise.reject('File must be added/uploaded.');
					}
					return Promise.resolve();
				}
			})
		],
		items_mapping: [
			{ required: true, message: "Please select mapping column for items." }
		]
	}
}

const vsmAllETR = {
	authorised: 'Authorised Successfully',
	rejected: 'Rejected Successfully'
}

const vsmOrganisations = {
	add: "Organisation Saved successfully.",
	edit: "Organisation Updated successfully.",
	delete: "Organisation Removed successfully.",
	map: "Organisation Mapped successfully.",
	import: "Organisations Records Successfully created !",
	importRole: "Organisations Roles Successfully created !",
	validation: {
		organisation: [
			{ required: true, message: "Please select Organisation." },
		],
		organisation_role: [
			{ required: true, message: "Please select Organisation Role." },
		],
		related_entity_type: [
			{ required: true, message: "Please select Entity Type." },
		],
		related_organisation: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") === 'Organisation') {
						if ((getFieldValue('related_entity_id') === null || getFieldValue('related_entity_id') === undefined || getFieldValue('related_entity_id') === '') &&
							(getFieldValue('related_people_id') === null || getFieldValue('related_people_id') === undefined || getFieldValue('related_people_id') === '')) {
							return Promise.reject('Please select Related Organisation.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		related_people: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") === "People") {
						if ((getFieldValue('related_entity_id') === null || getFieldValue('related_entity_id') === undefined || getFieldValue('related_entity_id') === '') &&
							(getFieldValue('related_people_id') === null || getFieldValue('related_people_id') === undefined || getFieldValue('related_people_id') === '')) {
							return Promise.reject('Please select Related People.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		related_role: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") !== "Ledger") {
						if ((getFieldValue('related_role_id') === null || getFieldValue('related_role_id') === undefined || getFieldValue('related_role_id') === '') &&
							(getFieldValue('related_role_id') === null || getFieldValue('related_role_id') === undefined || getFieldValue('related_role_id') === '')) {
							return Promise.reject('Please select Related Role Name.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		tag_name: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					let pattern = /^[^\t\n]+$/
					let data = getFieldValue("tag_ids")
					let min = data && data.find(item => typeof (item) === "string" && item.length < 2)
					let max = data && data.find(item => typeof (item) === "string" && item.length > 100)
					let check = data && data.find(item => typeof (item) === "string" && !pattern.test(item))
					if (min) {
						return Promise.reject('Tag Names should contain minimum 2 characters.');
					}
					if (check) {
						return Promise.reject('Tags should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break.');
					}
					if (max) {
						return Promise.reject('Tag Names can contain maximum 100 characters.');
					}
					return Promise.resolve();
				}
			}),
		],
		exchange_ref_id: [
			{ min: 3, message: "Exchange Reference Id should contain minimum 3 digits." },
			{ pattern: /^[0-9]+$/, message: "ASCII characters are allowed." }
		],
		organisation_name: [
			{ required: true, message: "Organisation Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Organisation Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		trade_name: [
			{ min: 1, message: "Organisation Trade Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Trade Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		registration_country: [
			{ required: true, message: "Please select Registration Country." },
		],
		registration_number: [
			{ min: 1, message: "Registration Number should contain minimum 1 character." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		vat_number: [
			{ min: 3, message: "VAT Number should contain minimum 3 characters." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		website: [
			{ min: 1, message: "Please enter a valid Website URL and must start with http:// or https://", type: 'url' },
		],
		phone_with_required: [
			{ required: true, message: "Phone Number cannot be empty." },
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		phone_without_required: [
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		email_with_required: [
			{ required: true, message: "Email Address cannot be empty." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		email_without_required: [
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		status: [
			{ required: true, message: "Please select Status" }
		],
		address_type: [
			{ pattern: /^[^\t\n]+$/, message: "Address Type should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_1: [
			{ required: true, message: "Address cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Address should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_2and3: [
			{ pattern: /^[^\t\n]+$/, message: "Address should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		city: [
			{ max: 100, message: "City should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "City should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		state: [
			{ max: 100, message: "State/County should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "State/County should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		postal_code: [
			{ min: 1, message: "Postal Code should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Postal code should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		industry: [
			{ required: true, message: "Please select Credebt® Classification." },
		],
		nace_code: [
			{ required: true, message: "Please select NACE Code." },
		],
		org_currency: [
			{ required: true, message: "Please select Currency." },
		],
		tax_clearence: [
			{ pattern: /^[^\t\n]+$/, message: "Tax Clearance Access Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
			{ min: 5, message: "Description should contain minimum 5 digits." },
		],
		purchase_payment: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		default_sell_rate_d_etr: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		default_sell_rate_b_c_etr: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		face_value_charge: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		creditor_face_value_charge: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		ldc_primium: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		originator_application_fee: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		agent_commission: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		arrangement_fee: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		processing_commission: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		trade_commission: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		trade_limit_d_etr: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		trade_limit_c_etr: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		etr_months: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		payment_discount: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		requested_credit_limit: [
			{ required: true, message: "It cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Numeric fields should contain Numeric and decimal values up to six digits after decimal point. ' }
		],
		country: [
			{ required: true, message: "Please select Country." },
		],
		rating: [
			{ required: true, message: "Please select Rating To Map." },
		],
		short_rating: [
			{ required: true, message: "Short Rating should not be blank." },
			{ whitespace: true, message: "Short Rating should not be blank." },
		],
		long_rating: [
			{ required: true, message: "Long Rating should not be blank." },
			{ whitespace: true, message: "Long Rating should not be blank." },
		],
		discount_percentage: [
			{ required: true, message: "Agreed Early Payment Discount cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Agreed Early Payment Discount should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("d_etr_purchase_discount") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Agreed Early Payment Discount can not greater then 100");
					}
				},
			}),
		],
		sell_rate: [
			{ required: true, message: "Agreed Early Payment Discount cannot be blank." },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: "Agreed Early Payment Discount should contain Numeric Values & Dot (Decimal upto 6 places). Other special characters and Alphabets are not allowed" },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (getFieldValue("c_etr_sell_rate") <= 100) {
						return Promise.resolve();
					} else {
						return Promise.reject("Agreed Early Payment Discount can not greater then 100");
					}
				},
			}),
		],
		email_type: [
			{ required: true, message: "Please select Email Type." },
		],
		phone_type: [
			{ required: true, message: "Please select Phone Type." },
		]
	}
}

const vsmRoleMapping = {
	add: "Organisation Role Mapping Saved successfully.",
	edit: "Organisation Role Mapping Updated successfully.",
	delete: "Organisation Role Mapping Removed successfully.",
	mapBank: "Bank Account mapped successfully with selected role mapping.",
	InvalidRecord: "Invalid Request.",
	validation: {
		organisation_role: [
			{ required: true, message: "Please select Role to map with Ledger." },
		],
		related_role: [
			{ required: true, message: "Please select Related Role." },
		],
		Insurance: [
			{ min: 3, message: "Supplier Insurance Number should contain minimum 3 digits." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		Discount: [
			{ min: 3, message: "Agreed Early Payment Discount should contain minimum 3 digits." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		crm: [
			{ min: 3, message: "CRM  should contain minimum 3 digits." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
	}
}

const vsmOrgBankAccount = {
	add: "Bank account Saved successfully.",
	edit: "Bank account Updated successfully.",
	activated: "Bank account activated successfully.",
	deactivated: "Bank account deactivated successfully.",
	validation: {
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		account_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('Account Number cannot be blank.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." }
		],
		iba_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('IBAN cannot be blank.');
						}
						else if (getFieldValue("iban") && !IBAN.isValid(getFieldValue("iban"))) {
							return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax')
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Sort code cannot be blank as you have added account number.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9- ]*$/, message: "Sort Code should contain Space, Numeric and Dash characters only. Alphabets or special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Swift or BIC code cannot be blank as you have added IBAN');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		bank_ac_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		]
	}
}

const vsmRoles = {
	add: "Organisation Role Created successfully.",
	edit: "Organisation Role Updated successfully.",
	delete: "Organisation Role Removed successfully.",
	validation: {
		role_name: [
			{ required: true, message: "Role Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Role Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		related_role: [
			{ required: true, message: "Please select Related Role." },
		],
	}
}

const vsmOrgStatus = {
	add: "Status Saved successfully.",
	edit: "Status Updated successfully.",
	delete: "Status Removed successfully.",
	validation: {
		status_name: [
			{ required: true, message: "Status Name cannot be blank." },
			{ min: 1, message: "Status Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Status Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		status_code: [
			{ required: true, message: "Status Code cannot be blank." },
			{ min: 1, message: "Status Code should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Status Code should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
	}
}

const vsmLedgers = {
	add: "Ledger Saved successfully.",
	edit: "Ledger Updated successfully.",
	delete: "Ledger Removed successfully.",
	archive: "Ledger Archiving started. We will notify you by email when archiving is completed.",
	restore: "Ledger restoring started. We will notify you by email when restoring is completed",
	publish: "Ledger enabled successfully.",
	unpublish: "Ledger disabled successfully.",

	InvalidRecord: "Invalid Request.",
	validation: {
		name: [
			{ required: true, message: "Ledger Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Ledger Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		organisation: [
			{ required: true, message: "Please select Organisation" },
		],
		ledger_admin: [
			{ required: true, message: "Please select Ledger Admin." },
		],
		clone_data: [
			// ({ getFieldValue }) => ({
			// 	validator(value, rule) {
			// 		if (getFieldValue('clone_ledger')) {
			// 			return Promise.reject('Please select ledger.')
			// 		}
			// 		return Promise.resolve();
			// 	}
			// })
			{ required: true, message: "Please select ledger." },
		]
	}
}

const vsmLedgerDetails = {
	edit: "Ledger Updated successfully.",
	archive: "Financial Year Archiving started. We will notify you by email when archiving is completed.",
	restore: "Financial Year restoring started. We will notify you by email when restoring is completed.",
	validation: {
		name: [
			{ required: true, message: "Ledger Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Ledger Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		organisation: [
			{ required: true, message: "Please select Organisation" },
		],
		currency: [
			{ required: true, message: "Currency cannot be blank." },
		],
		description: [
			{ min: 1, message: "Description should contain minimum 1 character." },
		],
		ledger_admin: [
			{ required: true, message: "Please select Ledger Admin." },
		],
		timezone: [
			{ required: true, message: "Time zone cannot be blank." },
		]
	}
}

const vsmBankAccount = {
	add: "Bank account Saved successfully.",
	edit: "Bank account Updated successfully.",
	activated: "Bank account activated successfully.",
	deactivated: "Bank account deactivated successfully.",
	validation: {
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 1, message: "Bank Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		account_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') && (getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('Account Number cannot be blank.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Account Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		iba_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') && (getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('IBAN cannot be blank.');
						}
						else if (getFieldValue("iban") && !IBAN.isValid(getFieldValue("iban"))) {
							return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax')
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') && (getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Sort code cannot be blank as you have added account number.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9- ]*$/, message: "Sort Code should contain Space, Numeric & Dash characters only. Alphabets or Special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') && (getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Swift or BIC code cannot be blank as you have added IBAN');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		bank_ac_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		]
	}
}

const vsmLedgerMapBank = {
	save: "Map Bank Import File Fields saved successfully ",
	validation: {
		with_required: [
			({ getFieldsValue, getFieldValue }) => ({
				validator(rule, value) {
					var count = 0
					var dynamic_field = {}
					if (getFieldValue('dynamic_field')) {
						getFieldValue('dynamic_field').forEach((x, index) => {
							if (x) { dynamic_field['dynamic_field.' + index + '.name'] = x.name }
						})
					}
					if (getFieldValue('cte_fields')) {
						getFieldValue('cte_fields').forEach((x, index) => {
							if (x) { dynamic_field['cte_fields.' + index + '.name'] = x.name }
						})
					}
					var other_field = getFieldsValue(['transaction_details', 'credit', 'debit', 'balance', 'posted_on', 'value_on', 'customer_reference', 'bank_reference'])
					Object.values({ ...dynamic_field, ...other_field }).map(item => (item === value ? count++ : null))
					if (value && count > 1) { return Promise.reject('CSV Column Name already exists.') }
					return Promise.resolve();
				}
			}),
			{ required: true, message: "CSV Column Name cannot be blank." },
			{ min: 2, message: "CSV Column Name should contain minimum 2 characters." },
			{ pattern: /^[^\t\n]+$/, message: "CSV Column Name should contain Space, Dash, Alphabetical, Numeric and also support UTF-8 characters with exception of some white characters such as new line entries, tabs." }
		],
		without_required: [
			({ getFieldsValue, getFieldValue }) => ({
				validator(rule, value) {
					var count = 0
					var dynamic_field = {}
					if (getFieldValue('dynamic_field')) {
						getFieldValue('dynamic_field').forEach((x, index) => {
							if (x) { dynamic_field['dynamic_field.' + index + '.name'] = x.name }
						})
					}
					if (getFieldValue('cte_fields')) {
						getFieldValue('cte_fields').forEach((x, index) => {
							if (x) { dynamic_field['cte_fields.' + index + '.name'] = x.name }
						})
					}
					var other_field = getFieldsValue(['transaction_details', 'credit', 'debit', 'balance', 'posted_on', 'value_on', 'customer_reference', 'bank_reference'])
					Object.values({ ...dynamic_field, ...other_field }).map(item => (item === value ? count++ : null))
					if (value && count > 1) { return Promise.reject('CSV Column Name already exists.') }
					return Promise.resolve();
				}
			}),
		],
		field: [
			{ required: true, message: "Please select Field." }
		],
		journal: [
			{ required: true, message: "Please select Journal for reconciliation process." }
		],
		regex: [
			{ required: true, message: "Please select regular expression details for at least one field." }
		],
		bank_account: [
			{ required: true, message: "Please select Bank Account." }
		]
	}
}

const vsmAllTransactions = {
	add: "Transaction Saved successfully.",
	edit: "Transaction Updated successfully.",
	delete: "Transaction Removed successfully.",
	add_additional_column: 'Changes saved successfully.',
	InvalidRecord: "Invalid Request.",
	BulkStatusChange: 'Transaction bulk status change is being applied.',
	validation: {
		organisation: [
			{ required: true, message: "Please select Organisation." }
		],
		organisation_role: [
			{ required: true, message: "Please select Organisation Role." }
		],
		related_role: [
			{ required: true, message: "Please select Related Organisation Role." }
		],
		related_people_role: [
			{ required: true, message: "Please select Related People Role." }
		],
		mapping: [
			{ required: true, message: "Please select Trade Transaction." },
		],
		currency: [
			{ required: true, message: "Please select Currency." },
		],
		face_value: [
			{ required: true, message: "Face Value cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,5})|[0-9]+)$/, message: "Face value should contain Numeric characters and dot only (Upto five decimal points). Other special characters or alphabets are not allowed." }
		],
		status: [
			{ required: true, message: "Please select Status" },
		],
		vat_rate: [
			{ required: true, message: "Please select VAT Rate" },
		],
		posted_date: [
			{ required: true, message: "Please select Posted Date" },
		],
		traded_date: [
			{ required: true, message: "Please select Traded Date" },
		],
		value: [
			{ required: true, message: "Please enter the value" },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Amount should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		additionalColumnList: [
			{ required: true, message: "Please select at least 1 checkbox." },
		]
	}
}

const vsmTransactionReport = {
	save: "Report Saved successfully.",
	delete: "Report Removed successfully.",
	edit: "Report Updated successfully.",
	validation: {
		report_type: [
			{ required: true, message: "Please select report type." },
		],
		name: [
			{ required: true, message: "Report name cannot be blank." },
			{ min: 3, message: "Report name should have minimum 3 characters." },
			{ pattern: /^[^\t\n]*$/, message: "Report Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		code: [
			{ required: true, message: "Code cannot be blank." },
			{ pattern: /^([A-Za-z0-9-.\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/, message: "Code should contain numbers and alphabetical characters only." }
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
			{ min: 1, message: "Description should have minimum 1 characters." },
			{ pattern: /^[^\t\n]*$/, message: "Description should contain numbers, alphabetical and Latin special characters only." }
		],
		type: [
			{ required: true, message: "Please select at least one Trade Type" }
		],
		journal_type: [
			{ required: true, message: "Please select at least one Journal Type" }
		],
		group_on: [
			{ required: true, message: "Please select at least one Group On" }
		],
		currency: [
			{ required: true, message: "Please select at least one Currency" }
		],
		generate_on: [
			{ required: true, message: "Please select Generate On" }
		],
		date_range: [
			{ required: true, message: "Date Range cannot be blank." }
		],
		date: [
			{ required: true, message: "Please select date" }
		],
		date_x_value: [
			{ pattern: /^([2-9]|[1]+[0-2])*$/, message: "Auto Period Setting should be numeric between 2 and 12 Only." }
		],
		financial_year: [
			{ required: true, message: "Please select Financial year." }
		],
		period_date_range: [
			{ required: true, message: "Please select at least two Period range" }
		],
		period_x_value: [
			{ pattern: /^([2-9]|[1]+[0-2])*$/, message: "Auto Period Setting should be numeric between 2 and 12 Only." }
		],
		single_transaction: [
			{ required: true, message: "Transaction cannot be blank." },
			{ pattern: /^[0-9]*$/, message: "Transaction must be a number." }
		],
		multiple_transaction: [
			{ required: true, message: "Transaction cannot be blank." },
			({ getFieldValue }) => ({
				validator(value, rule) {
					let pattern = /^[0-9]*$/
					let data = getFieldValue("multiple_transaction")
					let check = data && data.find(item => !pattern.test(item))
					if (check) {
						return Promise.reject('Transaction must be a number.');
					}
					return Promise.resolve();
				}
			}),
		],
		single_model: [
			{ required: true, message: "Please select at least one Model" }
		],
		multiple_model: [
			{ required: true, message: "Please select at least one Model" }
		],
		single_mapping: [
			{ required: true, message: "Please select at least one Trade Transaction" }
		],
		single_transaction_type: [
			{ required: true, message: "Please select at least one Trade Type" }
		],
		single_journal: [
			{ required: true, message: "Please select at least one Journal" }
		],
		multiple_journal: [
			{ required: true, message: "Please select at least one Journal" }
		],
		combined_reports: [
			{ required: true, message: "Please select at least one Report" }
		],
		transaction_field: [
			{ required: true, message: "Please select at least one transaction field" }
		],
		operator: [
			{ required: true, message: "Please select at least one operator" }
		],
		value: [
			{ required: true, message: "Please enter value" }
		],
		org_people_bank_iban: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (value && !IBAN.isValid(value)) {
						return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax');
					} else {
						return Promise.resolve();
					}
				}
			}),
		],
		cte_exchange_id: [
			{ min: 3, message: "Exchange Reference Id should contain minimum 3 digits." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		cte_bank_ref: [
			{ min: 3, message: "Bank Reference should contain minimum 3 values." },
		],
		cte_customer_ref: [
			{ min: 3, message: "Customer Reference should contain minimum 3 values." },
		],
		account_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('Account Number cannot be blank.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." }
		],
		iba_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('IBAN cannot be blank.');
						}
						else if (getFieldValue("iban") && !IBAN.isValid(getFieldValue("iban"))) {
							return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax')
						}
					}
					return Promise.resolve();
				}
			})
		], sort_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Sort code cannot be blank as you have added account number.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9- ]*$/, message: "Sort Code should contain Space, Numeric and Dash characters only. Alphabets or special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Swift or BIC code cannot be blank as you have added IBAN');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		title: [
			{ pattern: /^[^\t\n]+$/, message: "Title should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		people_name: [
			{ pattern: /^[^\t\n]+$/, message: "Title should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
	}
}

const vsmRegularExpression = {
	add: "Regular Expression Saved successfully.",
	edit: "Regular Expression Updated successfully.",
	delete: "Regular Expression Removed successfully.",
	validation: {
		expression_name: [
			{ required: true, message: "Expression Name cannot be blank." },
			{ min: 3, message: "Expression Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Expression Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		regular_expression: [
			{ required: true, message: "Regular Expression cannot be blank." },
			{ min: 3, message: "Regular Expression should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Regular Expression should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
			{ min: 3, message: "Description should contain minimum 3 characters." },
		],
		verification_string: [
			{ pattern: /^[^\t\n]+$/, message: "Verification String should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
	}
}

const vsmCashTransactions = {
	add: "Transaction Saved successfully.",
	edit: "Transaction Updated successfully.",
	delete: "Transaction Removed successfully.",
	reconcilePending: 'Auto reconcile process successfully.',
	validation: {
		bank_account: [
			{ required: true, message: "Please select Bank." }
		],
		org_or_people: [
			{ required: true, message: "Please select Organisation / People." }
		],
		org_or_people_role: [
			{ required: true, message: "Please select Organisation Role / People Role." }
		],
		rel_org_or_people: [
			{ required: true, message: "Please select Related Organisation / People." }
		],
		rel_org_or_people_role: [
			{ required: true, message: "Please select Related Organisation Role / People Role." }
		],
		cash_trans_classification: [
			{ required: true, message: "Please select Cash Transaction Classification." }
		],
		trans_type: [
			{ required: true, message: "Please select Transaction Type." }
		],
		trans_details: [
			{ required: true, message: "Transaction Details cannot be blank." },
			{ min: 3, message: "Transaction Details should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Transaction Details should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		amount: [
			{ required: true, message: "Amount cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Amount should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		currency: [
			{ required: true, message: "Please select Currency." },
		],
		posted_date: [
			{ required: true, message: "Posted Date cannot be blank" },
		],
		balance: [
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Balance should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		exchange_ref_id: [
			{ min: 3, message: "Exchange Reference Id should contain minimum 3 digits." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		bank_reference: [
			{ min: 3, message: "Bank Reference should contain minimum 3 values." },
		],
		customer_reference: [
			{ min: 3, message: "Customer Reference should contain minimum 3 values." },
		],
	}
}

const vsmClassifications = {
	add: "Cash Transaction Classification Saved successfully.",
	edit: "Cash Transaction Classification Updated successfully.",
	delete: "Cash Transaction Classification Removed successfully.",
	InvalidRecord: "Invalid Request.",
	validation: {
		class_name: [
			{ required: true, message: "Class Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Class Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		code: [
			{ required: true, message: "Code cannot be blank." },
			{ min: 2, message: "Code should contain minimum 2 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Code should contain Alphanumeric values." }
		],
		description: [
			{ required: true, message: "Description cannot be blank." },
		],
		classification: [
			{ required: true, message: "Please select any one classification rule." }
		],
		customer_reference: [
			{ min: 3, message: "Customer Reference should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Customer Reference should contain Space, Dash, Alphabetical, Numeric with exception of some white characters such as new line entries, tabs." }
		],
		bank_reference: [
			{ min: 3, message: "Bank Reference should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Reference should contain Space, Dash, Alphabetical, Numeric with exception of some white characters such as new line entries, tabs." }
		],
		transaction_details: [
			{ min: 3, message: "Transaction Details should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Transaction Details should contain Space, Dash, Alphabetical, Numeric with exception of some white characters such as new line entries, tabs." }
		]
	}
}

const vsmReconciliation = {
	reconcile: "Transaction Reconciled successfully.",
	unlink: "Transaction Unlinked successfully."
}

const vsmCTEImport = {
	add: "Import Transaction Process Started Successfully",
	delete: "Import Removed successfully",
	cancel: "Import Stopped & Removed successfully",
	InvalidRecord: "Invalid Request",
	validation: {
		file_name: [
			{ required: true, message: "Import Name cannot be blank." },
			{ min: 3, message: "Import Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Import Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		bank_name: [
			{ required: true, message: "Please select Bank Account" },
		],
		transaction_details: [
			{ required: true, message: "Transaction Details cannot be blank." },
			{ min: 3, message: "Transaction Details should contain minimum 3 characters" },
			{ pattern: /^[^\t\n]+$/, message: "Transaction Details should contain Space, Dash, Alphabetical, Numeric and also support UTF-8 characters with exception of some white characters such as new line entries, tabs." }
		],
		balance: [
			{ pattern: /^[0-9]+([.][0-9]+)?$/, message: "Balance should contain Dot and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		amount: [
			{ required: true, message: "Amount cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Amount should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		posted_date: [
			{ required: true, message: "Posted Date cannot be blank" },
		],
		currency: [
			{ required: true, message: "Please select Currency" },
		],
		bank_account: [
			{ required: true, message: "Please select Bank Account" },
		],
		main_organisation_role: [
			{ required: true, message: "Please select Organisation role." },
		],
		main_organisation: [
			{ required: true, message: "Please select Organisation." },
		],
		main_people_role: [
			{ required: true, message: "Please select People role." },
		],
		main_people: [
			{ required: true, message: "Please select People." },
		],
		related_organisation_role: [
			{ required: true, message: "Please select related Organisation role." },
		],
		related_organisation: [
			{ required: true, message: "Please select related Organisation." },
		],
		related_people_role: [
			{ required: true, message: "Please select related People role." },
		],
		related_people: [
			{ required: true, message: "Please select related People" },
		],
		transaction_classification: [
			{ required: true, message: "Please select Transaction Classification." },
		],
		transaction_type: [
			{ required: true, message: "Please select Transaction Type." },
		]
	}
}

const vsmPeople = {
	add: "People Saved successfully.",
	edit: "People Updated successfully",
	delete: "People Removed successfully.",
	import: "People Records Successfully created !",
	importRole: "People Roles Successfully created !",
	validation: {
		first_name: [
			{ required: true, message: "First Name cannot be blank." },
			{ min: 1, message: "First Name should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "First Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		last_name: [
			{ pattern: /^[^\t\n]+$/, message: "Last Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		title: [
			{ pattern: /^[^\t\n]+$/, message: "Title should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		date_of_birth: [
			// { required: true, message: "Please select Date of Birth" },
		],
		phone_with_required: [
			{ required: true, message: "Phone Number cannot be empty." },
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		phone_without_required: [
			{ pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/, message: "Phone Number should contain Space, Plus (+), Dash or Numeric character and “ext” keyword only. Other Special characters or Alphabets are not allowed." }
		],
		email_with_required: [
			{ required: true, message: "Email Address cannot be empty." },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		email_without_required: [
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address." },
		],
		secondary_email: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue('secondary_email')) {
						let pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
						if (!pattern.test(getFieldValue('secondary_email'))) {
							return Promise.reject('Invalid email address.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		pps_number: [
			{ pattern: /^[^\t\n]+$/, message: "PPS Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break" }
		],
		former_last_name: [
			{ min: 1, message: "Former Last Name should contain minimum 1 character." },
			{ max: 100, message: "Former Last Name should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Former Last Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		notes: [
			{ min: 1, message: "Notes should contain minimum 1 character." },
			{ max: 131072, message: "Notes should contain maximum 131072 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Former Last Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		ppsn_document_type: [
			// { required: true, message: "PPSN Document cannot be blank." },
		],
		photo_URL: [
			{ min: 1, message: "Photo URL should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Photo URL should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		pronounced: [
			{ min: 1, message: "Notes should contain minimum 1 character." },
			{ max: 100, message: "Notes should contain maximum 100 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Pronounced should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		address_type: [
			{ min: 1, message: "Address Type contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Address type should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		address_1: [
			{ required: true, message: 'Address cannot be blank.' },
			{ min: 2, message: "Address should contain minimum 2 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Address should not contain white space character like new line and tab." }
		],
		address_2and3: [
			{ min: 2, message: "Address should contain minimum 2 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Address should not contain white space character like new line and tab." }
		],
		city: [
			{ min: 3, message: "City should contain minimum 3 characters." },
			{ max: 100, message: "City should contain maximum 100 characters." },
			{ pattern: /^([ A-Za-z0-9-\s]*)$/, message: "City should contain Space, Dash, Alphabetical, Numeric characters." }
		],
		state: [
			{ min: 3, message: "State/County should contain minimum 3 characters." },
			{ max: 100, message: "State/County should contain maximum 100 characters." },
			{ pattern: /^[ A-Za-z0-9-]*$/, message: "State/County should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		postal_code: [
			{ min: 2, message: "Postal Code should contain minimum 2 characters." },
			{ pattern: /^[ A-Za-z0-9-/]*$/, message: "Postal code should contain Space, Dash, Forward Slash, Alphabetical and Numeric characters are allowed. Other special characters are not allowed." }
		],
		country: [
			{ required: true, message: 'Please select Country.' }
		],
		organisation: [
			{ required: true, message: "Please select Organisation." },
		],
		organisation_role: [
			{ required: true, message: "Please select People Role." },
		],
		related_entity_type: [
			{ required: true, message: "Please select Entity Type." },
		],
		related_organisation: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") === 'People') {
						if ((getFieldValue('related_entity_id') === null || getFieldValue('related_entity_id') === undefined || getFieldValue('related_entity_id') === '') &&
							(getFieldValue('related_people_id') === null || getFieldValue('related_people_id') === undefined || getFieldValue('related_people_id') === '')) {
							return Promise.reject('Please select Related People.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		related_people: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") === "People") {
						if ((getFieldValue('related_entity_id') === null || getFieldValue('related_entity_id') === undefined || getFieldValue('related_entity_id') === '') &&
							(getFieldValue('related_people_id') === null || getFieldValue('related_people_id') === undefined || getFieldValue('related_people_id') === '')) {
							return Promise.reject('Please select Related People.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		related_role: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("related_entity_type") !== "Ledger") {
						if ((getFieldValue('related_role_id') === null || getFieldValue('related_role_id') === undefined || getFieldValue('related_role_id') === '') &&
							(getFieldValue('related_role_id') === null || getFieldValue('related_role_id') === undefined || getFieldValue('related_role_id') === '')) {
							return Promise.reject('Please select Related Role Name.');
						}
					}
					return Promise.resolve();
				}
			}),
		],
		tag_name: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					let pattern = /^([ A-Za-z0-9\s\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/
					let data = getFieldValue("tag_ids")
					let min = data && data.find(item => typeof (item) === "string" && item.length < 3)
					let max = data && data.find(item => typeof (item) === "string" && item.length > 100)
					let check = data && data.find(item => typeof (item) === "string" && !pattern.test(item))
					if (min) {
						return Promise.reject('Tag Names should contain minimum 3 characters.');
					}
					if (check) {
						return Promise.reject('Tag Names should contain Alphabetical, Numbers & Latin (UTF - 8) characters with exception of some white characters such as new line entries, tabs. Other Special characters are not allowed.');
					}
					if (max) {
						return Promise.reject('Tag Names can contain maximum 100 characters.');
					}
					return Promise.resolve();
				}
			}),
		],
		exchange_ref_id: [
			{ min: 3, message: "Exchange Reference Id should contain minimum 3 digits." },
			{ pattern: /^[0-9]*$/, message: "Exchange Reference Id should contain Numeric values only. Special characters & Alphabets are not allowed." }
		],
		email_type: [
			{ required: true, message: "Please select Email Type." },
		],
		phone_type: [
			{ required: true, message: "Please select Phone Type." },
		]
	}
}

const vsmPplBankAccount = {
	add: "Bank account Saved successfully.",
	edit: "Bank account Updated successfully.",
	activated: "Bank account activated successfully.",
	deactivated: "Bank account deactivated successfully.",
	validation: {
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Bank Name should contain Space, Dash, Alphabetical, Numeric and also support UTF-8 characters with exception of some white characters such as new line entries, tabs." }
		],
		account_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('Account Number cannot be blank.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." }
		],
		iba_num: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('account_number') === null || getFieldValue('account_number') === undefined || getFieldValue('account_number') === '') &&
							(getFieldValue('iban') === null || getFieldValue('iban') === undefined || getFieldValue('iban') === '')) {
							return Promise.reject('IBAN cannot be blank.');
						}
						else if (getFieldValue("iban") && !IBAN.isValid(getFieldValue("iban"))) {
							return Promise.reject('IBAN must be provided in valid format and valid IBAN syntax')
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "account") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Sort code cannot be blank as you have added account number.');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9-]*$/, message: "Sort Code should contain Numeric & Dash characters only. Alphabets or Special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("tab") === "iban") {
						if ((getFieldValue('sort_code') === null || getFieldValue('sort_code') === undefined || getFieldValue('sort_code') === '') &&
							(getFieldValue('swift_bic') === null || getFieldValue('swift_bic') === undefined || getFieldValue('swift_bic') === '')) {
							return Promise.reject('Swift or BIC code cannot be blank as you have added IBAN');
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		bank_ac_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		]
	}
}

const vsmPeopleRoles = {
	add: "People Role Created successfully.",
	edit: "People Role Updated successfully.",
	delete: "People Role Removed successfully.",
	import: "People Roles Records Successfully created !",
	validation: {
		role_name: [
			{ required: true, message: "Role Name cannot be blank." },
			{ pattern: /^[ A-Za-z0-9-]*$/, message: "Role name can contain alphabets, numbers and dash only." }
		],
		related_roles: [
			{ required: true, message: "Please select Related Role." },
		]
	}
}

const vsmFXCurrencies = {
	add: "Currency Saved successfully.",
	edit: "Currency Updated successfully.",
	delete: "Currency Removed successfully.",
	activated: "Currency activated successfully.",
	deactivated: "Currency deactivated successfully.",
	import: "File successfully imported.",
	exchange_rate: "Exchange rate saved successfully.",
	validation: {
		currency_name: [
			{ required: true, message: "Currency Name cannot be blank." },
			{ min: 1, message: "Currency Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Currency Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		currency_code: [
			{ required: true, message: "Code cannot be blank." },
			{ pattern: /^(.{3})$/, message: "Code should contain 3 characters only." },
			{ pattern: /^([A-Za-z]{3})$/, message: "Code should contain Alphabetical characters only. Numbers and other special characters are not allowed." }
		],
		currency_symbol: [
			{ required: true, message: "Symbol cannot be blank." },
			{ min: 1, message: "Symbol should contain only 1 character." },
		],
		manual_currency: [
			{ required: true, message: "Exchange rate cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Exchange Rate should contain only decimal values up to 6 digits after decimal point." }
		],
		date: [
			{ required: true, message: "Please select date." },
		],
	}
}
const vsmPeopleRoleMapping = {
	add: "People Role Mapping Saved successfully.",
	edit: "People Role Mapping Updated successfully.",
	delete: "People Role Mapping Removed successfully.",
	mapBank: "Bank Account mapped successfully with selected role mapping.",
	validation: {
		organisation_role: [
			{ required: true, message: "Please select Role to map with Ledger." },
		],
		related_role: [
			{ required: true, message: "Please select Related Role." },
		],

	}
}

const vsmManualJournalLog = {
	add: "Manual Journal Entry Saved successfully.",
	edit: "Manual Journal Entry Updated successfully.",
	delete: "Manual Journal Log Removed successfully.",
	reverse: "Manual Journal Entry Reversed successfully.",
	validation: {
		journal: [
			{ required: true, message: "Please select Journal." },
		],
		amount: [
			{ required: true, message: "Amount cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Amount should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		description: [
			{ min: 1, message: "Description should have minimum 1 character." }
		]
	}
}
const vsmTrigger = {
	add: "Trigger Saved successfully.",
	edit: "Trigger Updated successfully.",
	delete: "Trigger Removed successfully.",
	validation: {
		trigger_name: [
			{ required: true, message: "Trigger Name cannot be blank." },
			{ min: 1, message: "Trigger Name should contain minimum 1 character" },
			{ pattern: /^[^\t\n]+$/, message: "Trigger Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		when_to_execute: [
			{ required: true, message: "Please select When to Execute" },
		],
		what_to_execute: [
			{ required: true, message: "Please select What to Execute" },
		],
		mapping: [
			{ required: true, message: "Please select Trade Transaction Name" },
		],
		transaction_status: [
			{ required: true, message: "Please select Transaction Status" },
		]
	}
}

const vsmGlobalSetting = {
	add: 'Settings Saved successfully.',
	validation: {
		buy_price: [
			{ required: true, message: "This field is required." },
			{ pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/, message: "Buy Price should contain Dot (Decimal upto 2 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		sell_price: [
			{ required: true, message: "This field is required." },
			{ pattern: /^(([0-9]+\.[0-9]{1,2})|[0-9]+)$/, message: "Sell Price should contain Dot (Decimal upto 2 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
	}
}

const vsmTransactionItems = {
	add: "Transaction items Saved successfully.",
	edit: "Transaction items Updated successfully.",
	delete: "Transaction items Removed successfully.",
	validation: {
		batch: [
			{ required: true, message: "Batch cannot be blank." },
			{ min: 1, message: "Batch should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Batch should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		assetnumber: [
			{ required: true, message: "Asset Number cannot be blank." },
			{ min: 1, message: "Asset Number should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Only Numbers, Dash -, Dot . , Alphabetical and Latin special characters are allowed. Other symbol characters are not allowed" }
		],
		item: [
			{ required: true, message: "Asset Name cannot be blank." },
			{ min: 1, message: "Item Name should contain minimum 1 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Asset Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		deposit: [
			{ required: true, message: "Deposit Received cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Deposit Received should contain Dot (Decimal upto 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		eot: [
			{ required: true, message: "EOT cannot be blank." },
			{ min: 1, message: "EOT should contain minimum 1 characters." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "EOT should contain Dot (Decimal up to 6 places) and Numeric Values only. Other special characters and Alphabets are not allowed." }
		],
		quantity: [
			{ required: true, message: "Quantity cannot be blank." },
			{ min: 1, message: "Quantity should contain minimum 1 characters." },
			{ pattern: /^[0-9]*$/, message: "Quantity should contain Numeric Values only. Special characters and Alphabets are not allowed." }
		],
		unitprice: [
			{ required: true, message: "Unit Price cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Unit Price should contain only decimal values up to six digits after decimal point." }
		],
		rmprice: [
			{ required: true, message: "Repair & Maintenance Price cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "Repair & Maintenance Price should contain only decimal values up to six digits after decimal point." }
		],
		serialnumber: [
			{ min: 1, message: "Serial Number should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Serial Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		classification: [
			{ required: true, message: "Asset Classification cannot be blank." },
			{ min: 1, message: "Asset Classification should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Asset Classification should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		manufacturer: [
			{ min: 1, message: "Supplier/Manufacturer should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Supplier/Manufacturer should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		sitenumber: [
			{ min: 1, message: "Site No should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Site No should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		ddamount: [
			{ required: true, message: "DD Amount ex VAT cannot be blank." },
			{ pattern: /^(([0-9]+\.[0-9]{1,6})|[0-9]+)$/, message: "DD Amount ex VAT should contain only decimal values up to six digits after decimal point." }
		],
		taxtype: [
			{ required: true, message: "Please select Tax Type." },
		],
		vatrate: [
			{ required: true, message: "Please select Vat Rate." },
		]
	}
}

const vsmIpSettings = {
	add: "API Settings saved successfully.",
	edit: "API Settings updated successfully.",
	validation: {
		ip_address: [
			{ required: true, message: "Allowed IP cannot be blank" },
			{ pattern: /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/, message: "Only numeric values with dot is allowed" }
		],
		expiry_date: [
			{ required: true, message: "Please choose expiry date" },
		],
		data_limit: [
			{ required: true, message: "Please choose Data limit." },
		]
	}
}

const vsmMenuManagement = {
	edit: "Menu updated successfully.",
	validation: {
		apps: [
			{ required: true, message: "Please select Apps." },
		],
		title: [
			{ required: true, message: "Menu Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Menu Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
			{ min: 3, message: "Menu Name should contain minimum 3 characters." },
		]
	}
}

const vsmMailTemplate = {
	edit: 'Email Updated successfully.',
	validation: {
		email_subject: [
			{ required: true, message: "Subject cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Subject should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		body: [
			{ required: true, message: "Body cannot be blank." },
			// { min: 3, message: "Body should contain minimum 3 characters." },
		]
	}
}

const vsmDashboard = {
	add: 'Widget Saved successfully.',
	edit: 'Widget Updated successfully.',
	delete: 'Widget Removed successfully.',
	save: 'Widgets positions saved successfully.',
	validation: {
		title: [
			{ required: true, message: "Title cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Title should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		widget_type: [
			{ required: true, message: "Please select Widget" },
		],
		saved_reports: [
			{ required: true, message: "Please select Saved Report" },
		],
		type: [
			{ required: true, message: "Please select Type" },
		]
	}
}

const vsmMember = {
	memberAdd: 'Originator Details Saved Successfully.',
	bankAccount: 'Bank Account Saved Successfully.',
	CRAdd: 'Credit & Risks Saved Successfully.',
	userDetails: 'User Details Saved Successfully.',
	memberEdit: 'Originator Details Updated Successfully.',
	bankAccountEdit: 'Bank Account Updated Successfully.',
	CREdit: 'Credit & Risks Updated Successfully.',
	userDetailsEdit: 'User Details Updated Successfully.',
	intermediaryMemberAdd: 'Intermediary Details Saved Successfully.',
	intermediaryMemberEdit: 'Intermediary Details Updated Successfully.',
	investorMemberAdd: 'investor Details Saved Successfully.',
	investorMemberEdit: 'investor Details Updated Successfully.',
	validation: {
		organisation_name: [
			{ required: true, message: "Organisation Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Organisation Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		trade_name: [
			{ min: 1, message: "Organisation Trade Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Trade Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		org_currency: [
			{ required: true, message: "Please select Currency." },
		],
		industry: [
			{ required: true, message: "Please select Credebt® Classification." },
		],
		nace_section_id: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("nace_section") && !getFieldValue("nace_section_id")) {
						return Promise.reject('Please select NACE Code.');
					}
					return Promise.resolve();
				}
			})
		],
		registration_country: [
			{ required: true, message: "Please select Registration Country." },
		],
		registration_number: [
			{ min: 1, message: "Registration Number should contain minimum 1 character." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Code should contain Alphabetical or Numeric characters only. Space and Other Special characters are not allowed." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		vat_number: [
			{ min: 3, message: "VAT Number should contain minimum 3 characters." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		website: [
			{ min: 1, message: "Please enter a valid Website URL and must start with http:// or https://", type: 'url' },
		],
		description: [
			{ min: 5, message: "Description should contain minimum 5 digits." },
		],
		tax_clearence: [
			{ pattern: /^[^\t\n]+$/, message: "Tax Clearance Access Number should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		buy_rate: [
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		quoted_rate: [
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		account_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		account_number: [
			{ required: true, message: "Account Number cannot be blank." },
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." },
		],
		iba_num: [
			{ required: true, message: "IBAN cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].iban !== null) {
						if (!IBAN.isValid(fields[index].iban)) {
							return Promise.reject("IBAN must be provided in valid format and valid IBAN syntax");
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "account") {
						if (fields[index].sort_code === null || fields[index].sort_code === undefined || fields[index].sort_code === '') {
							return Promise.reject("Sort code cannot be blank as you have added account number.");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9-]*$/, message: "Sort Code should contain Numeric & Dash characters only. Alphabets or Special characters are not allowed." }

		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "iban") {
						if (fields[index].swift_bic === null || fields[index].swift_bic === undefined || fields[index].swift_bic === '') {
							return Promise.reject("Swift or BIC code cannot be blank as you have added IBAN");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		],
		common_field_val: [
			{ required: true, message: 'This field is required.' },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		common_field_number_val: [
			{ required: true, message: 'This field is required.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'It should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		arrangement: [
			{ required: true, message: 'Arrangement cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Arrangement should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		membership: [
			{ required: true, message: 'Membership cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Membership should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		processing: [
			{ required: true, message: 'Processing cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Processing should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		trade: [
			{ required: true, message: 'Trade cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Trade should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		creditsafe_score: [
			{ required: true, message: 'Creditsafe Score cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Creditsafe Score should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		creditsafe_today_limit: [
			{ required: true, message: 'Creditsafe Today’s Limit cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Creditsafe Today’s Limit should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		d_etr_stop_plus: [
			{ required: true, message: 'd-ETR Stop+ cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'd-ETR Stop+ should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		d_etr_stop_minus: [
			{ required: true, message: 'd-ETR Stop- cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'd-ETR Stop- should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		facility_type: [
			{ required: true, message: 'Please select Facility Type' }
		],
		d_etr_purchase_price: [
			{ required: true, message: 'd-ETR Purchase Price % cannot be blank.' },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		posting_fee: [
			{ required: true, message: 'Posting Fee cannot be blank.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Posting Fee should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		reserve: [
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		tl_level: [
			{ required: true, message: 'Please select TL Level' },
		],
		rating_agency_rate: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Rate should should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		rating_agency_limit: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Limit should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		rating_agency_contract: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Contract should should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		rating_agency_long_term: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Long Term should should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		rating_agency_short_term: [
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Sort Term should should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		user_first_name: [
			{ required: true, message: 'First Name cannot be blank.' },
			{ pattern: /^[^\t\n]+$/, message: "First Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		user_last_name: [
			{ required: true, message: 'Last Name cannot be blank.' },
			{ pattern: /^[^\t\n]+$/, message: "Last Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space - the tab - the hard line break - the carriage return - the hard page break." }
		],
		user_email: [
			{ required: true, message: 'Email cannot be blank.' },
			{ pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Please enter the valid email address" },

		]
	}
}
const vsmCredebtor = {

	validation : {
		organisation_name: [
			{ required: true, message: "Organisation Name cannot be blank." },
			{ pattern: /^[^\t\n]+$/, message: "Organisation Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		trade_name: [
			{ min: 1, message: "Organisation Trade Name should contain minimum 1 character." },
			{ pattern: /^[^\t\n]+$/, message: "Trade Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." }
		],
		originator_organisation: [
			{ required: true, message: "Please select Originator." },
		],
		org_currency: [
			{ required: true, message: "Please select Currency." },
		],
		industry: [
			{ required: true, message: "Please select Credebt® Classification." },
		],
		nace_section_id: [
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (getFieldValue("nace_section") && !getFieldValue("nace_section_id")) {
						return Promise.reject('Please select NACE Code.');
					}
					return Promise.resolve();
				}
			})
		],
		registration_country: [
			{ required: true, message: "Please select Registration Country." },
		],
		registration_number: [
			{ min: 1, message: "Registration Number should contain minimum 1 character." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Code should contain Alphabetical or Numeric characters only. Space and Other Special characters are not allowed." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		vat_number: [
			{ min: 3, message: "VAT Number should contain minimum 3 characters." },
			{ pattern: /^[\000-\377]+$/, message: "ASCII characters are allowed." }
		],
		Counterparty:[
			{required :true , message: 'Please select Counterparty since'}
		],
		totalHistoricValue:[
			{min : 1 , message:'Total Historic Value should contain minimum 1 character'},
			{max : 12 , message:'Total Historic Value should not be greature than  12 character'},
			{pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Total Historic Value should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		Payment_Terms:[
			{required:true,message:'Payment Terms cannot be blank'},
			{min : 1 , message:'Payment Terms should contain minimum 1 character'},
			{max : 3 , message:'Payment Terms should not be greature than 3 character'},
			{pattern : /^[0-9\b]+$/,message:'Payment Terms should contain Numeric Values. Other special characters and Alphabets are not allowed'}
		],
		credebt_notes_issue:[
			{max : 12 , message : 'Credit Notes Issued should not be greature than 12 characters'},
			{pattern : /^[0-9\b]+$/,message:'Credit Notes Issued should contain only Numeric Values. Other special characters and Alphabets are not allowed'}
		],
		invoice_predicted_per_annum:[
			{required:true,message:'Invoice Predicted Per Annum cannot be blank'},
			{min : 1 , message : 'Invoice Predicted Per Annum should contain minimum 1 character'},
			{max : 2 , message : 'Invoice Predicted Per Annum should not be greature than 2 character'},
			{pattern : /^[0-9\b]+$/,message:'Invoice Predicted Per Annum should contain only Numeric Values. Other special characters and Alphabets are not allowed'}
		],
		predicted_annual_revenue :[
			{required:true,message:'Predicted Annual Revenue cannot be blank'},
			{min : 1 , message : 'Predicted Annual Revenue should contain minimum 1 character'},
			{max : 10 , message : 'Predicted Annual Revenue should not be greature than 10 character'},
			{pattern : /^[0-9\b]+$/,message:'Predicted Annual Revenue should contain only Numeric Values. Other special characters and Alphabets are not allowed'}
		],
		agreed_early_payment_discount:[
			{required:true,message:"Enter the counterparty’s Agreed Early Payment Discount"},
			{min : 1 , message:'counterparty’s Agreed Early Payment Discount should contain minimum 1 character'},
			{max : 12 , message:'counterparty’s Agreed Early Payment Discount should not be greature than 12 character'},
			{pattern: /^((\d+)((\.\d{1,6})?))$/, message:'counterparty’s Agreed Early Payment Discount should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		
		bank_name: [
			{ required: true, message: "Bank Name cannot be blank." },
			{ min: 3, message: "Bank Name should contain minimum 3 characters." },
			{ pattern: /^[^\t\n]+$/, message: "Bank Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break." },
		],
		account_type: [
			{ required: true, message: "Please select Bank Account Type." },
		],
		account_number: [
			{ required: true, message: "Account Number cannot be blank." },
			{ min: 3, message: "Account Number should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9]*$/, message: "Account Number should contain Alphabets and Numeric characters only. Special characters are not allowed." },
		],
		iba_num: [
			{ required: true, message: "IBAN cannot be blank." },
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].iban !== null) {
						if (!IBAN.isValid(fields[index].iban)) {
							return Promise.reject("IBAN must be provided in valid format and valid IBAN syntax");
						}
					}
					return Promise.resolve();
				}
			})
		],
		sort_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "account") {
						if (fields[index].sort_code === null || fields[index].sort_code === undefined || fields[index].sort_code === '') {
							return Promise.reject("Sort code cannot be blank as you have added account number.");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Sort Code should contain minimum 3 characters." },
			{ pattern: /^[0-9-]*$/, message: "Sort Code should contain Numeric & Dash characters only. Alphabets or Special characters are not allowed." }
		],
		swift_or_bic_code: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					let fields = getFieldValue("bank")
					let current_field = rule.field.split("."); // For Getting Current Field Object
					let index = parseInt(current_field[1]); // Index of current object
					if (fields[index].tab === "iban") {
						if (fields[index].swift_bic === null || fields[index].swift_bic === undefined || fields[index].swift_bic === '') {
							return Promise.reject("Swift or BIC code cannot be blank as you have added IBAN");
						}
					}
					return Promise.resolve();
				}
			}),
			{ min: 3, message: "Swift or BIC Code should contain minimum 3 characters." },
			{ pattern: /^[A-Za-z0-9 -]*$/, message: "Swift or BIC Code should contain Space, Dash, Alphabetical or Numeric characters only. Other Special characters are not allowed." }
		],
		currency: [
			{ required: true, message: "Please select Currency." }
		],
		common_field_val: [
			{ required: true, message: 'This field is required.' },
			{ pattern: /^(\d{1}(?:\.\d{1,5})?|\d{2}(?:\.\d{1,4})?|100(?:\.0{1,3})?)$/, message: "Invalid percentage." },
		],
		common_field_number_val: [
			{ required: true, message: 'This field is required.' },
			{ pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'It should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		No_Trade_Starting_Price : [
			{min : 1 , message:'No Trade Starting Proce Value should contain minimum 1 character'},
			{max : 12 , message:'No Trade Starting Proce Value should not be greature than  12 character'},
			{pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'No Trade Starting Proce Value should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		aig_discretionary_limit:[
			{required:true,message:"Enter the counterparty’s Agreed Early Payment Discount"},
			{min : 1 , message:'counterparty’s Agreed Early Payment Discount should contain minimum 1 character'},
			{max : 12 , message:'counterparty’s Agreed Early Payment Discount should not be greature than 12 character'},
			{pattern: /^((\d+)((\.\d{1,6})?))$/, message:'counterparty’s Agreed Early Payment Discount should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],
		Total_Values:[
			{required : true , message : 'Total Value cannot be blank'},
			{min : 1 , message:'Total Value should contain minimum 1 character'},
			{max : 10 , message:'Total Value should not be greature than 10 character'},
		],
		average_payment_cycle:[
			{required : true , message : 'Average Payment Cycle cannot be blank'},
			{min : 1 , message:'Average Payment Cycle should contain minimum 1 character'},
			{max : 12 , message:'Average Payment Cycle should not be greature than 12 character'},
			{pattern: /^((\d+)((\d{1,6})?))$/, message:'Average Payment Cycle should contain only numeric values'}
		],
		requested_credit_limit : [
			{min : 1 , message:'Requested Credit Limit Value should contain minimum 1 character'},
			{max : 12 , message:'Requested Credit Limit Value should not be greature than  12 character'},
			{pattern: /^((\d+)((\.\d{1,6})?))$/, message: 'Requested Credit Limit Value should contain only numeric values and dot (decimal values up to six digits). Other special characters and Alphabets are not allowed' }
		],	
	}
}


const vsmNotify = {
	success: (data) => {
		notification.success({ placement: "bottomRight", duration: 3, ...data })
	},
	error: (data) => {
		notification.error({ placement: "bottomRight", duration: 3, ...data })
	}
}

export {
	vsmNotify,
	vsmAuth,
	vsmCommon,
	vsmUserManagement,
	vsmRolemNagement,
	vsmTransactionFields,
	vsmTransactionTypes,
	vsmTransactionSubTypes,
	vsmTransactionFieldMapping,
	vsmJournalGroups,
	vsmTaxSettings,
	vsmJournals,
	vsmTransactionModels,
	vsmJournalLines,
	vsmAlgorithm,
	vsmImport,
	vsmOrganisations,
	vsmRoles,
	vsmLedgers,
	vsmLedgerDetails,
	vsmBankAccount,
	vsmOrgBankAccount,
	vsmRoleMapping,
	vsmLedgerMapBank,
	vsmAllTransactions,
	vsmTransactionReport,
	vsmRegularExpression,
	vsmCashTransactions,
	vsmClassifications,
	vsmReconciliation,
	vsmCTEImport,
	vsmPeople,
	vsmPplBankAccount,
	vsmPeopleRoles,
	vsmFXCurrencies,
	vsmPeopleRoleMapping,
	vsmOrgStatus,
	vsmManualJournalLog,
	vsmTrigger,
	vsmGlobalSetting,
	vsmTransactionItems,
	vsmIpSettings,
	vsmMenuManagement,
	vsmMailTemplate,
	vsmDashboard,
	vsmImportETR,
	vsmAllETR,
	vsmMember,
	vsmCredebtor
}
