import Axios from "axios"
import { decorate, observable, action } from "mobx"
import LocalGridConfig from '../../config/LocalGridConfig'
import { vsmNotify } from '../../config/messages'

export default class UserManagementStore {
	list_data = null
	all_data = []
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	dropdown_role_list = []

	editValues = null
	deleteValues = null
	resetValues = null
	statusValues = null
	restoreValues = null
	permission_list = null
	view_ledger_list = null
	modifyPermissionValues = null
	dropdown_user_group = null
	agGrid = null
	ledger_list = null
	archive = false
	org_list_dropdown = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data
	}

	// set form values to reset password
	setResetPassword = (data) => {
		this.resetValues = data
	}

	// set form values to modify permissions
	setModufyPermissionValues = (data) => {
		this.modifyPermissionValues = data
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data
	}

	// set form values to restore
	setRestoreValues = (data) => {
		this.restoreValues = data
	}

	setDataTableValues = (data) => {
		this.permission_list = data
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params
		let columnConfig = localStorage.getItem('users_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
	}

	// Set column width after resizing colums
	onGridChanged = (params) => {
		localStorage.setItem('users_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	}

	changeArchiveValue = (value) => {
		this.archive = value
		this.list_data = []
		this.all_data.forEach((item, index) => {
			if (this.archive === true) {
				if (item.deleted_at !== null) {
					this.list_data.push(item)
				}
			} else {
				this.list_data.push(item)
			}
		});
	}

	// call api to get records
	getList = (payload = {}) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel()
			var sort = this.agGrid.columnApi.getColumnState()
		}
		return Axios.post(`user/list`, payload).then(({ data }) => {
			if (data.data.length) {
				var tempData = []
				data.data.forEach((item, index) => {
					if (item.user_role === null) {
						item.user_role = { role_name: null }
					}
					item.srno = index + 1
					item.status = item.status === 1 ? "Activated" : "Deactivated"

					if (this.archive === true && item.deleted_at !== null) {
						tempData.push(item)
					}
				});
			}
			this.list_data = tempData
			this.all_data = data.data
			if (this.archive === false) { this.list_data = data.data }
			let columnConfig = localStorage.getItem('users_grid')
			if (this.agGrid && this.agGrid.columnApi && columnConfig) {
				this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
			} else {
				if (this.agGrid && this.agGrid.columnApi) {
					this.agGrid.api.setFilterModel(filter)
					this.agGrid.columnApi.applyColumnState({ state: sort })
				}
			}
			return data
		})
	}

	//call api to get permissions list
	getPermissionsList = (formdata) => {
		return Axios.post('permission/list', formdata).then(({ data }) => {
			this.permission_list = data.data
			return data
		}).catch(() => {
			this.permission_list = null
		})
	}

	//call api to get role list
	getRoleList = () => {
		return Axios.get('user/role/list').then(({ data }) => {
			this.dropdown_role_list = data.data
			return data
		})
	}

	getViewLedgerList = (formdata) => {
		return Axios.get(`user/${formdata.id}`).then(({ data }) => {
			this.view_ledger_list = [data.data]
			return data
		})
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`user/add`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`user/edit/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.post(`user/delete/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	// BulkAction for Approve, Disapprove and Delete
	BulkAction = (formdata) => {
		let array = this.agGrid && this.agGrid.api.getSelectedNodes()
		let selectedId = [];
		array && array.map((item) => (
			selectedId.push(item.data.id)
		))
		var payload = { id: selectedId, action: formdata }
		return Axios.post(`user/bulk/action`, payload).then(({ data }) => {
			vsmNotify.success({
				message: data.message
			})
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			vsmNotify.error({
				message: data.errors[0].errors[0]
			})
			// message.error(data.errors[0].errors[0]);
			return Promise.reject(data)
		})
	}

	//api call for modify user's permission
	ModifyPermissionData = (formdata) => {
		return Axios.post(`user/role/permission/create`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	ActivateDeactivateDate = (formdata) => {
		return Axios.post(`user/status/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	RestoreData = (formdata) => {
		return Axios.post(`user/restore/${formdata.id}`, formdata).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	getNameFromEmail = (formdata) => {
		return Axios.post(`user/email/check`, formdata).then(({ data }) => {
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = errors
			return Promise.reject(data)
		})
	}

	getLedgerList = () => {
		return Axios.post('user/ledger/list').then(({ data }) => {
			this.ledger_list = data.data
			return data
		})
	}

	getUserGroup = () => {
		return Axios.get('user/get/groups').then(({ data }) => {
			this.dropdown_user_group = data.data
			return data
		})
	}

	getOriginatorOrgList = (formData) => {
		return Axios.post('originator/organisations/get', formData).then(({ data }) => {
			this.org_list_dropdown = data.data
			return data
		})
	}
}

decorate(UserManagementStore, {
	list_data: observable,
	all_data: observable,
	total: observable,
	current_page: observable,
	per_page: observable,
	editValues: observable,
	deleteValues: observable,
	resetValues: observable,
	permission_list: observable,
	setResetPassword: observable,
	agGrid: observable,
	dropdown_role_list: observable,
	modifyPermissionValues: observable,
	statusValues: observable,
	restoreValues: observable,
	ledger_list: observable,
	archive: observable,
	view_ledger_list: observable,
	dropdown_user_group: observable,
	org_list_dropdown: observable,
	getList: action,
	getPermissionsList: action,
	getRoleList: action,
	AddData: action,
	EditData: action,
	DeleteData: action,
	setModufyPermissionValues: action,
	setStatusValues: action,
	setEditValues: action,
	setDeleteValues: action,
	BulkAction: action,
	setupGrid: action,
	setPageSize: action,
	ActivateDeactivateDate: action,
	setRestoreValues: action,
	getNameFromEmail: action,
	getLedgerList: action,
	changeArchiveValue: action,
	getViewLedgerList: action,
	setDataTableValues: action,
	onFilterChanged: action,
	onGridChanged: action,
	getUserGroup: action,
	getOriginatorOrgList: action,
})
