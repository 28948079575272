import React from 'react'
// import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import LocalGridConfig from '../../../../config/LocalGridConfig'
import { vsmCommon } from '../../../../config/messages'
import NumberFormat from 'react-number-format'
import { DateComparator } from '../../../../utils/GlobalFunction'
import useStore from '../../../../store'

const CashReportWidget = (props) => {
    const { SavedReportStore } = useStore()

    let gridOptions = {
        columnDefs: [
            { headerName: '# ID', field: 'id', filter: 'agNumberColumnFilter', pinned: 'left', width: 80, },
            {
                headerName: 'Date', field: 'date', filter: 'agDateColumnFilter', width: 185,
                filterParams: {
                    buttons: ['reset'],
                    inRangeInclusive: true,
                    comparator: DateComparator
                }
            },
            {
                headerName: 'Transaction Details', field: 'transaction_details',
                width: 220,
                valueGetter: (params) => (params.data && params.data.transaction_details) ? params.data.transaction_details : "",
            },
            {
                headerName: 'Transaction Type', field: 'transactions_type', filter: 'agSetColumnFilter',
                width: 150,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.transactions_type) ? params.data.transactions_type : "",
            },
            {
                headerName: 'Amount (Debit / Credit)', field: 'amount', filter: 'agNumberColumnFilter',
                width: 130,
                cellRendererFramework: (params) => (params.data) ? (
                    <NumberFormat value={params.data.amount} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} />
                ) : null
            },
            {
                headerName: 'Identified Category', field: 'identified_category', filter: 'agSetColumnFilter',
                width: 150,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.identified_category) ? params.data.identified_category : "",
            },
            {
                headerName: 'Exchange ID', field: 'exchange_ref_id',
                width: 130,
                valueGetter: (params) => (params.data && params.data.exchange_ref_id) ? params.data.exchange_ref_id : "",
            },
            {
                headerName: 'Organisation / People Name', field: 'entity_name', filter: 'agSetColumnFilter',
                width: 200,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.entity_name) ? params.data.entity_name : "",
            },
            {
                headerName: 'Related Organisation / People Name', field: 'related_entity_name', filter: 'agSetColumnFilter',
                width: 200,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.related_entity_name) ? params.data.related_entity_name : "",
            },
            {
                headerName: 'Status', field: 'status', filter: 'agSetColumnFilter',
                width: 110,
                filterParams: {
                    defaultToNothingSelected: true,
                },
                valueGetter: (params) => (params.data && params.data.status) ? params.data.status : "",
            }
        ]
    }

    if (props.rowData && Array.isArray(props.rowData)) {
        return (
            <div className="" style={{ height: '70vh', marginBottom: 24 }}>
                <div className="ag-theme-alpine grid_wrapper">
                    <AgGridReact
                        rowData={props.rowData}
                        modules={AllModules}
                        columnDefs={gridOptions.columnDefs}
                        defaultColDef={{ ...LocalGridConfig.defaultColDef }}
                        columnTypes={LocalGridConfig.columnTypes}
                        overlayNoRowsTemplate={vsmCommon.noRecord}
                        onGridReady={props.onGridReady}
                        gridOptions={{ ...LocalGridConfig.options, pagination: false }}
                        onColumnResized={SavedReportStore.onChildnGridChanged}
                        onColumnMoved={SavedReportStore.onChildnGridChanged}
                        onColumnPinned={SavedReportStore.onChildnGridChanged}
                        onSortChanged={SavedReportStore.onChildnGridChanged}
                    />
                </div>
            </div>
        )

    } else {
        return null
    }
}

export default CashReportWidget;