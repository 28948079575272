import React, { useState, useEffect } from 'react'
import { Form, Button } from 'antd'
import { observer } from 'mobx-react'
import FormComponent from './FormComponent'
import useStore from '../../../../store'
import { vsmFXCurrencies, vsmNotify } from '../../../../config/messages'

const EditComponent = observer((props) => {
	const [form] = Form.useForm()
	const { FXCurrenciesStore: { EditData, editValues } } = useStore()
	const [disabled, setDisabled] = useState(true)
	const [saving, setSaving] = useState()

	// Make function call to edit existing record
	const handleSubmit = (data) => {
		setSaving(true)
		data.id = editValues.id
		EditData(data).then(() => {
			close()
			vsmNotify.success({
				message: vsmFXCurrencies.edit
			})
		}).catch(e => {
			if (e.errors) { form.setFields(e.errors) }
		}).finally(() => setSaving(false))
	}

	// Set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({
				currency_name: editValues.currency_name,
				currency_code: editValues.currency_code,
				currency_symbol: editValues.currency_symbol,
			})
		}
	}, [editValues, props.visible, form])

	// reset form and close edit form
	const close = () => {
		props.close()
		form.resetFields()
		setDisabled(true)
	}

	return editValues ? (
		<FormComponent
			form={form}
			form_id="editform"
			title={`Edit Currency - #${editValues.id} - ${editValues.currency_name}`}
			visible={props.visible}
			close={close}
			handleSubmit={handleSubmit}
			setDisabled={setDisabled}
			formButtons={[
				<Button key="1" form='editform' disabled={disabled} loading={saving} htmlType="submit" type="primary">Save</Button>,
				<Button key="2" htmlType="button" onClick={close}>Cancel</Button>
			]}
		/>
	) : null
})

export default EditComponent
