import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class MailTemplateStore {
    list_data = null
    per_page = LocalGridConfig.options.paginationPageSize
    current_page = 1
    total = 0
    agGrid = null

    read_data = null

    // to set read data
    setReadData = (data) => {
        this.read_data = data
    }

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params
        let columnConfig = localStorage.getItem('mail_template_grid')
        if (this.agGrid && this.agGrid.columnApi && columnConfig) {
            this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
        }
    }

    // Set column width after resizing colums
    onGridChanged = (params) => {
        localStorage.setItem('mail_template_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
            this.agGrid.api.hideOverlay();
        }
    }

    getListData = () => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel()
            var sort = this.agGrid.columnApi.getColumnState()
        }
        return Axios.get(`emailtemplates/list`).then(({ data }) => {

            if (data.data.length) {
                data.data.forEach((item) => {
                    item.updated_by = item.users ? (item.users.first_name + " " + item.users.last_name) : null
                });
            }

            this.list_data = data.data
            this.total = data.total
            this.current_page = data.current_page

            let columnConfig = localStorage.getItem('mail_template_grid')
            if (this.agGrid && this.agGrid.columnApi && columnConfig) {
                this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
            } else {
                if (this.agGrid && this.agGrid.columnApi && data.total) {
                    this.agGrid.columnApi.applyColumnState({ state: sort })
                }
            }
            if (this.agGrid && this.agGrid.api) {
                this.agGrid.api.setFilterModel(filter)
            }
        })
    }

    ReadData = (ID) => {
        return Axios.get(`emailtemplates/read/${ID}`).then(({ data }) => {
            this.setReadData(data.data)

            return data
        }).catch(() => {
            this.setReadData(null)
        })
    }

    updateData = (formData, ID) => {
        return Axios.post(`emailtemplates/update/${ID}`, formData).then(({ data }) => {
            this.getListData()
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }
}

decorate(MailTemplateStore, {
    list_data: observable,
    per_page: observable,
    current_page: observable,
    total: observable,
    agGrid: observable,
    read_data: observable,
    setPageSize: action,
    setupGrid: action,
    onGridChanged: action,
    getListData: action,
    onFilterChanged: action,
    setReadData: action,
    updateData: action,
})