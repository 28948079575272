import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class MenuManagementStore {
    structure_library_data = null;
    CopiedStructureData = null;
    Structure = [];
    savestructureform = null;
    readValues = null

    setReadValues = (data) => {
        this.editValues = data;
    };

    addItemKey = (mainArray) => {
        if (mainArray && mainArray.length) {
            mainArray.forEach((submenu, index) => {
                submenu.expanded = true
                let nameField = `name_${index}`;
                submenu[nameField] = submenu.name
                if (submenu.children) {
                    this.addItemKey(submenu.children)
                }
            })
        }
    }

    getMenu = (panel) => {
        return Axios.post(`get/menu`, panel).then(({ data, index }) => {
            if (data.data) {
                if (data.data.length > 0) {
                    this.addItemKey(data.data)
                }
            }
            this.structure_library_data = data.data
            return data
        }).catch(({ response: { data } }) => {
            return Promise.reject(data);
        });
    };

    updateMenu = (formData) => {
        return Axios.post(`update/menu/sequence`, formData).then(({ data }) => {
            return data
        }).catch(({ response: { data } }) => {
            var errors = []
            Object.keys(data.errors).forEach(name => {
                errors.push({ name, errors: data.errors[name] })
            })
            data.errors = errors
            return Promise.reject(data)
        })
    }

    SetStructure = (data) => {
        this.Structure = data;
    };
    setCopiedStructureData = (data) => {
        this.CopiedStructureData = data;
    };
    setSavestructureform = (data) => {
        this.savestructureform = data;
    };
}

decorate(MenuManagementStore, {
    structure_library_data: observable,
    CopiedStructureData: observable,
    Structure: observable,
    savestructureform: observable,
    readValues: observable,
    setReadValues: action,
    SetStructure: action,
    setCopiedStructureData: action,
    setSavestructureform: action,
    fetchMenu: action,
})