import React from 'react'
import { observer } from 'mobx-react'
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from "@ag-grid-enterprise/all-modules"
import { ActionRenderer, gridOptions } from './GridComponent'
import GridConfig from '../../../../config/GridConfig'
import { vsmCommon } from '../../../../config/messages'
import useStore from '../../../../store'

const ListComponent = observer((props) => {
	const { openDeleteDrawer, openEditDrawer, openRoleListing, openBankAccounts, openViewDrawer, handleProxyLogin } = props
	const { PeopleStore } = useStore()

	let columns = []
	let columnConfig = localStorage.getItem('people_grid')
	if (columnConfig) {
		let data = JSON.parse(columnConfig)
		let cols = gridOptions.columnDefs.find(x => !x.field)
		if (cols) {
			columns.push(cols)
		}
		data.forEach(element => {
			cols = gridOptions.columnDefs.find(x => x.field === element.colId)
			if (cols) {
				columns.push(cols)
			}
		});
	} else {
		columns = gridOptions.columnDefs
	}

	return (
		<div className="ag-theme-alpine grid_wrapper">
			<AgGridReact
				modules={AllModules}
				columnDefs={columns}
				defaultColDef={GridConfig.defaultColDef}
				columnTypes={GridConfig.columnTypes}
				overlayNoRowsTemplate={vsmCommon.noRecord}
				frameworkComponents={{
					ActionRenderer, openDeleteDrawer, openEditDrawer, openRoleListing, openBankAccounts, openViewDrawer, handleProxyLogin
				}}
				onGridReady={PeopleStore.setupGrid}
				onColumnResized={PeopleStore.onGridChanged}
				onColumnMoved={PeopleStore.onGridChanged}
				onColumnPinned={PeopleStore.onGridChanged}
				gridOptions={GridConfig.options}
			/>
		</div>
	)
})

export default ListComponent
