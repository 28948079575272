import { Button, Dropdown, Menu, PageHeader } from 'antd';
import React, { useState } from 'react'
import ListComponent from './component/ListComponent';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent'
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
// import UserStore from '../../../store/UserStore';
import AddComponent from './component/AddComponent';
import EditComponent from './component/EditComponent';
import RecordPerPage from '../../../components/RecordPerPage';
import EnterFullscreen from '../../../components/EnterFullscreen';
import useStore from '../../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { DownOutlined } from "@ant-design/icons";
import { observer } from 'mobx-react-lite';
import { CredebtorType } from '../../../utils/GlobalFunction';

const Credebtors = observer(() => {
  const {UserStore,CredebtorStore : {per_page,setPageSize,handleReset,setOpenTypeForm}} = useStore()
  const [AddDrawer,setAddDrawer] = useState(false)
  const [EditDrawer,setEditDrawer] = useState(false)

  const menu = (
		<Menu>
			{CredebtorType.map((item, index) => (
				<Menu.Item key={index} onClick={() => { setAddDrawer(true);setOpenTypeForm(item.text)}}>{item.text}</Menu.Item>
			))}
		</Menu>
	)

  return (
    <>
        <BreadcrumbComponent items={BreadcrumbConfig.credebtors.path} />
        <PageHeader
        className={UserStore.fullscreen_class}
        title={BreadcrumbConfig.credebtors.title}
        extra={[
          UserStore.checkPermission(120, 'list') && <Button key="1" >Export</Button>,
          UserStore.checkPermission(120, 'add') && <Dropdown overlay={menu}><Button>Add Credebtor<DownOutlined /></Button></Dropdown>,
          <RecordPerPage key="3" defaultValue={per_page + ' per page'} onChange={setPageSize} />,
          <Button key="4" title="Reset" onClick={handleReset}><FontAwesomeIcon icon={faUndo}/></Button>,
          <EnterFullscreen key='5' />
        ]}
        >
        <ListComponent openEditDrawer={EditDrawer} setOpenEditDrawer={setEditDrawer} />
        <AddComponent visible={AddDrawer} setAddDrawer={setAddDrawer} />
        <EditComponent visible={EditDrawer} setEditDrawer={setEditDrawer} />
        </PageHeader>
    </>
  )
})
export default Credebtors;