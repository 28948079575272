import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { BreadcrumbConfig } from '../../../config/BreadcrumbConfig';
import PageNotFound from '../../PageNotFound';
import useStore from '../../../store';
import { Button, Card, Col, Form, PageHeader, Row, Affix } from 'antd';
import InputComponent from '../../../components/InputComponent';
import { cloneDeep } from 'lodash'
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { vsmMenuManagement, vsmNotify } from '../../../config/messages';
import { useHistory } from 'react-router-dom';

const MenuManagement = observer(() => {
    const [form] = Form.useForm();
    let history = useHistory()
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const { UserStore, MenuManagementStore } = useStore()
    const [isDisabled, setDisabled] = useState(true);
    const [saving, setSaving] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [sequenceNum, setSequenceNum] = useState([]);
    const [errors, setErrors] = useState([]);
    const deStructuredArray = []

    const handleSubmit = () => {
        setSaving(true)
        createPayloadToSend(treeData)
        if (deStructuredArray.length > 0) {
            APICall(deStructuredArray)
        }
    }

    const APICall = (deStructuredArray) => {
        MenuManagementStore.updateMenu({ menu: deStructuredArray }).then(() => {
            setSaving(false)
            vsmNotify.success({
                message: vsmMenuManagement.edit
            })
            form.resetFields()
            setDisabled(true)
            setTreeData([])
            setSequenceNum([])
            setErrors([])
        }).catch(e => {
            if (e.errors) { form.setFields(e.errors) }
        }).finally(() => setSaving(false))
    }

    const createPayloadToSend = async (mainArray) => {
        if (mainArray && mainArray.length) {
            mainArray.forEach((menus) => {
                deStructuredArray.push(menus)
                if (menus.children) {
                    createPayloadToSend(menus.children)
                }
            })
        }
        deStructuredArray.map((menus, index) => {
            menus.sequence_no = sequenceNum[index]
            menus.title = menus.name

            delete menus.children
            delete menus.expanded
            delete menus.name_1
            return menus
        })
    }

    // Check for valid form values then accordingly made save button disable / enable
    const handleChange = () => {
        form.validateFields().then((data) => {
            let tempObj = errors.filter(x => x !== null)
            if (tempObj.length > 0) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true);
            }
        })
    }

    const setSequenceNumber = (MainArray) => {
        if (MainArray && MainArray.length) {
            MainArray.forEach((arrayItem) => {
                setSequenceNum(sequenceNum => [...sequenceNum, arrayItem.sequence_no])
                if (arrayItem.children) {
                    setSequenceNumber(arrayItem.children)
                }
            })
        }
    }

    const handlePanel = (panelName) => {
        if (!panelName) {
            setTreeData([])
            setDisabled(true)
        } else {
            var payload = {
                type: panelName
            }
            MenuManagementStore.getMenu(payload).then(({ data }) => {
                let structure = cloneDeep(data)
                setSequenceNumber(structure)
                setTreeData(structure ? structure : []);
            })
        }
    }

    const returnsError = (value, tempTreeIndex) => {
        // let pattern = /^[^\t\n]+$/
        let pattern = /^\S[^\t\n]+\S$/
        let currentIndex = null

        if (errors && errors.length) {
            errors.forEach((treeItem, index) => {
                if (treeItem && (treeItem.index === tempTreeIndex)) {
                    currentIndex = index
                }
            })
        }
        let errorObj = null
        if (value) {
            if (!pattern.test(value)) {
                errorObj = {
                    index: tempTreeIndex,
                    message: 'Menu Name should allow for all Unicode characters (UTF-8 encoding for DB storage) with exception of some white-space characters such as: - nonbreaking space, the tab, the hard line break, the carriage return, the hard page break.'
                }
            } else if (value.length < 3) {
                errorObj = {
                    index: tempTreeIndex,
                    message: 'Menu Name should contain minimum 3 characters.'
                }
            }
        } else {
            errorObj = {
                index: tempTreeIndex,
                message: 'Menu Name cannot be blank.'
            }
        }

        if ((currentIndex === null) && errorObj) {
            setErrors([...errors, errorObj])
        } else {
            if (currentIndex !== null) {
                errors.splice(currentIndex, 1)
            }
            if (errorObj) {
                setErrors([...errors, errorObj])
            } else {
                setErrors([...errors])
            }
        }
    }

    const close = () => {
        form.resetFields()
        setDisabled(true)
        setTreeData([])
        setSequenceNum([])
        setErrors([])
        history.push(`/dashboard`)
    }

    useEffect(() => {
        form.validateFields().then((data) => {
            let tempObj = errors.filter(x => x !== null)
            if (tempObj.length > 0) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }).catch(e => {
            if (e.errorFields && e.errorFields.length > 0) {
                setDisabled(true);
            }
        })
    }, [errors, form])

    return (
        <>
            <BreadcrumbComponent items={BreadcrumbConfig.Menu_Management.path} />
            {(!UserStore.checkPermission(112, 'list')) ? (<PageNotFound />) : (
                <>
                    <PageHeader title={BreadcrumbConfig.Menu_Management.title}>
                        <Form
                            form={form}
                            layout='horizontal'
                            id='updateMenu'
                            onFinish={handleSubmit}
                            className='innerForm'
                        >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <InputComponent
                                        required
                                        type="select"
                                        label='Select Apps'
                                        placeholder="Select Apps"
                                        name='panel'
                                        onChange={(panel) => { handlePanel(panel); handleChange() }}
                                        allowClear
                                        options={{
                                            values: [{ value: 'credebt_machine', text: 'Credebt Machine' }, { value: "originator", text: 'Originator' }, { value: 'investor', text: 'Investor' }, { value: 'intermediary', text: 'Intermediary' }]
                                        }}
                                        tooltip="Choose App to rearrange or edit menu structure."
                                        rules={vsmMenuManagement.validation.apps}
                                    />
                                </Col>
                            </Row>
                            {treeData && treeData.length > 0 ? <Card style={{ marginBottom: '20px' }}>
                                <SortableTree
                                    className='menu-management-section'
                                    style={{ height: '100%' }}
                                    isVirtualized={false}
                                    maxDepth={4}
                                    treeData={treeData}
                                    onChange={data => {
                                        setTreeData(data)
                                    }}
                                    onMoveNode={(data) => {
                                        if (data.nextParentNode === null) {
                                            data.node.parent_id = 0
                                        } else {
                                            data.node.parent_id = data.nextParentNode.id
                                        }
                                    }}
                                    generateNodeProps={({ node, path, treeIndex }) => {
                                        let tempTreeIndex = treeIndex
                                        return ({
                                            title: (
                                                <>
                                                    <InputComponent
                                                        required
                                                        style={{ fontSize: '14px', paddingTop: '6px' }}
                                                        value={node.name}
                                                        onChange={event => {
                                                            const name = event.target.value;
                                                            returnsError(name, tempTreeIndex)
                                                            setTreeData(changeNodeAtPath({
                                                                treeData: treeData,
                                                                path,
                                                                getNodeKey,
                                                                newNode: { ...node, name },
                                                            }))
                                                        }}
                                                        maxLength={22}
                                                        rules={vsmMenuManagement.validation.title}
                                                    />
                                                    {errors.length ? (errors.map((item) => {
                                                        if (item && (item.index === tempTreeIndex)) {
                                                            return (
                                                                <div><span style={{ color: '#ff4d4f' }}>{item.message}</span></div>
                                                            )
                                                        }
                                                        return null
                                                    })) : null}
                                                </>
                                            ),
                                        })
                                    }
                                    }
                                />
                            </Card> : null}
                            <Affix offsetBottom={20}>
                                <div>
                                    <Button form='updateMenu' className="mr-10" loading={saving} htmlType="submit" type="primary" disabled={isDisabled}>Save</Button>
                                    <Button className="mr-10" onClick={close}>Cancel</Button>
                                </div>
                            </Affix>
                        </Form>
                    </PageHeader>
                </>
            )}
        </>
    );
})

export default MenuManagement;