import Axios from "axios";
import { action, observable, decorate } from "mobx";
import moment from "moment";
import GridConfig from "../../config/GridConfig";
import { vsmMember, vsmNotify } from "../../config/messages";
import { convertTextToID } from "../../utils/GlobalFunction";
import { convertError } from "../../utils/GlobalFunction";

export default class MemberStore {
	per_page = GridConfig.options.paginationPageSize
	current_page = 1
	total = 0
	agGrid = null
	openFormType = null
	parent_organisations_list = null
	country_list = null
	industries_list = null
	bankAccountType = null
	naceCode_list = null
	currenciesList = null
	cityList = null
	stateCountyList = null
	viewMemberValue = null
	orgStatusList = null
	userPermission = null
	organisationID = null
	ratingAgencyList = null
	permission_list = null
	exportLoading = false
	memberRole = []
	memberStatus = []
	currentTab = 0
	personList = null
	viewID = null

	userInitialValues = {
		phone: [{ contact_for: 'Primary' }],
		email: [{ contact_for: 'Primary' }],
		address: [],
	}

	MemberRoles = [
		{ id: 35, role: 'Originator' },
		{ id: 41, role: 'Intermediary' },
		{ id: 38, role: 'Investor' }
	]

	bankInitialValues = {
		bank: [{
			bank_name: null,
			bank_account_type_id: null,
			currency_id: null,
			account_number: null,
			sort_code: null,
			iban: null,
			swift_bic: null,
			tab: 'account',
			is_account: true
		}]
	}

	creditRiskInitialValue = {
		rating_agency: [{
			rating_agency_name: null
		}]
	}

	setCurrentTab = (number) => {
		this.currentTab = number ? number : this.currentTab + 1
	}

	setOpenFormType = (type) => {
		this.openFormType = type
	}

	onGridChanged = (params) => {
		localStorage.setItem('member_grid', JSON.stringify(params.columnApi.getColumnState()))
	}

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = GridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(page)
	}

	handleReset = async () => {
		await localStorage.removeItem('member_grid')
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
	}

	setupGrid = (params) => {
		this.agGrid = this.agGridServer = params
		const { api } = params
		let columnConfig = localStorage.getItem('member_grid')
		if (this.agGrid && this.agGrid.columnApi && columnConfig) {
			this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
		}
		var datasource = this.createDatasource(GridConfig.options)
		api.setServerSideDatasource(datasource)
	}

	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				let columnConfig = localStorage.getItem('member_grid')
				if (columnConfig) {
					this.onGridChanged(params)
				}
				let filter_data = { final_filter: params.request.filterModel, final_sort: params.request.sortModel }
				if (filter_data.final_filter['member_role_mapping.role_name']) {
					filter_data.final_filter['member_role_mapping.role_name'].values = convertTextToID(filter_data.final_filter['member_role_mapping.role_name'], this.memberRole, 'role_name', 'id')
				}
				if (filter_data.final_filter['global_industries.name']) {
					filter_data.final_filter['global_industries.name'].values = convertTextToID(filter_data.final_filter['global_industries.name'], this.industries_list, 'name', 'id')
				}
				if (filter_data.final_filter['global_countries.country_name']) {
					filter_data.final_filter['global_countries.country_name'].values = convertTextToID(filter_data.final_filter['global_countries.country_name'], this.country_list, 'country_name', 'id')
				}
				if (filter_data.final_filter['status.status_name']) {
					filter_data.final_filter['status.status_name'].values = convertTextToID(filter_data.final_filter['status.status_name'], this.memberStatus, 'status_name', 'id')
				}
				if (filter_data.final_filter['updated_at']) {
					filter_data.final_filter['updated_at'].values = moment(filter_data.final_filter['updated_at']).format('YYYY-MM-DD')
				}
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}


				this.getList(payload).then((data) => {
					if (data.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.data, data.total)
					let columnConfig = localStorage.getItem('member_grid')
					if (this.agGrid && this.agGrid.columnApi && columnConfig) {
						if (JSON.stringify(this.agGrid.columnApi.getColumnState()) !== columnConfig) {
							this.agGrid.columnApi.applyColumnState({ state: JSON.parse(columnConfig) })
						}
					}
				})
			}
		}
	}

	getStatus = () => {
		return Axios.get(`/organisations/filter/status/get`).then(({ data }) => {
			this.memberStatus = data.data
			return data
		}).catch((data) => {
			this.memberStatus = []
			return Promise.reject(data)
		})
	}

	getList = (payload = {}) => {
		return Axios.post(`back-office/member-user-list`, payload).then(({ data }) => {
			this.list_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	getPermission = (payload) => {
		return Axios.post(`back-office/get-member-permission`, payload).then(({ data }) => {
			this.userPermission = data.data
		})
	}

	// get parent Org list
	getParentOrganisationList = (payload) => {
		return Axios.post(`ledgers/getGlobalOrganisations`, payload).then(({ data }) => {
			this.parent_organisations_list = data.data
			return data
		}).catch((data) => {
			this.parent_organisations_list = null
			return Promise.reject(data)
		})
	}

	// call to get list of Currencies
	getCurrenciesList = () => {
		return Axios.get(`globalcurreny/ledger/currency`).then(({ data }) => {
			this.currenciesList = data.data
			return data
		}).catch(({ response: { data } }) => {
			this.currenciesList = null
			return Promise.reject(data)
		})
	}

	// get Country list
	getAllCountries = () => {
		return Axios.get(`organisations/get/countries`).then(({ data }) => {
			this.country_list = data.data
			return data
		}).catch((data) => {
			this.country_list = null
		})
	}

	// call for city list to auto populate
	getCityList = () => {
		return Axios.get(`global/addresses/city/get`).then(({ data }) => {
			var i
			var arrayObj = data.data
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['city']
				delete arrayObj[i].key1
			}
			this.cityList = arrayObj
			return data
		}).catch(() => {
			this.cityList = null
		})
	}

	// call for bank name list to auto populate
	getStateCountyList = () => {
		return Axios.get(`global/addresses/state/get`).then(({ data }) => {
			var i
			var arrayObj = data.data
			for (i = 0; i < arrayObj.length; i++) {
				arrayObj[i].value = arrayObj[i]['state_county']
				delete arrayObj[i].key1
			}
			this.stateCountyList = arrayObj
			return data
		}).catch(() => {
			this.stateCountyList = null
		})
	}

	// get Credebt® Classification list
	getIndustries = () => {
		return Axios.get(`organisations/global/industries`).then(({ data }) => {
			this.industries_list = data.data
			return data
		})
	}

	// get NACE code list
	getNaceCode = () => {
		return Axios.get(`organisations/global/nace-sections`).then(({ data }) => {
			this.naceCode_list = data.data
			return data
		})
	}

	// call for bank account types list
	getBankAccountTypeList = () => {
		return Axios.get(`bankaccount/bankAccountType`).then(({ data }) => {
			this.bankAccountType = data.data
			return data
		}).catch((data) => {
			this.bankAccountType = null
			return Promise.reject(data)
		})
	}

	// call api to get organisations roles list
	getOrgStatus = () => {
		return Axios.get(`organisations/status/get`).then(({ data }) => {
			this.orgStatusList = data.data
			return data
		})
	}

	getRatingAgency = () => {
		return Axios.get('back-office/ratingagency-list').then(({ data }) => {
			this.ratingAgencyList = data.data
			return data
		})
	}

	JsonToFormData = (data) => {
		let noFormDataArray = ['logo_url', 'id_proof', 'bank_proof', 'bank_statement_proof', 'aggreement_proof', 'cheque_document', 'personal_identity_record', 'passport_driving_license', 'utility_document', 'rpa_agreement', 'creditSafeReport', 'expReport']
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!noFormDataArray.includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			} else {
				formData.delete(key)
			}
		})
		return formData
	}

	//call api to get permissions list
	getPermissionsList = (formdata) => {
		return Axios.post('permission/list', formdata).then(({ data }) => {
			this.permission_list = data.data
			return data
		}).catch(() => {
			this.permission_list = null
		})
	}

	getMemberRole = () => {
		var arr = []
		return Axios.post('organisations/role/get').then(({ data }) => {
			data.data.forEach((item) => {
				if (["Originator", "Intermediary", "Investor"].includes(item.role_name)) {
					arr.push(item)
				}
			})
			this.memberRole = arr
			return data
		}).catch(() => {
			this.memberRole = []
		})
	}

	getPersonList = () => {
		return Axios.get('back-office/person-list').then(({ data }) => {
			this.personList = data.data
		}).catch(() => {
			this.personList = null
		})
	}

	AddMemberOrgDetail = (formdata) => {
		let submitType
		if (this.currentTab === 0 || (this.openFormType === 'Originator' && this.currentTab === 2)) {
			submitType = formdata.get('action')
		} else {
			submitType = formdata.action
		}
		let url = submitType === 'update' ? `/back-office/edit-member/${this.viewMemberValue?.id}` : `back-office/add-member`
		let message;
		if (this.openFormType === 'Intermediary') {
			message = submitType === 'update' ? vsmMember.intermediaryMemberEdit : vsmMember.intermediaryMemberAdd
		} else if (this.openFormType === 'Investor') {
			message = submitType === 'update' ? vsmMember.investorMemberEdit : vsmMember.investorMemberAdd
		} else {
			message = submitType === 'update' ? vsmMember.memberEdit : vsmMember.memberAdd
		}

		if (this.currentTab === 1) {
			url = `back-office/add-member-bank`
			message = submitType === 'update' ? vsmMember.bankAccountEdit : vsmMember.bankAccount
		} else if (this.currentTab === 3 || (['Intermediary', 'Investor'].includes(this.openFormType) && this.currentTab === 2)) {
			url = submitType === 'update' && this.viewMemberValue && this.viewMemberValue.is_member_user && this.viewMemberValue.is_member_user.id ? `back-office/add-member-user/${this.viewMemberValue.is_member_user.id}` : `back-office/add-member-user`
			message = submitType === 'update' ? vsmMember.userDetailsEdit : vsmMember.userDetails
		} else if (this.currentTab === 2 && this.openFormType === 'Originator') {
			url = `back-office/add-credit-risk`
			message = submitType === 'update' ? vsmMember.CREdit : vsmMember.CRAdd
		}

		return Axios.post(url, formdata).then(({ data }) => {
			vsmNotify.success({
				message: message
			})
			if (data && data.organisation_id) {
				this.organisationID = data.organisation_id
			} else if (this.viewMemberValue && this.viewMemberValue.id) {
				this.organisationID = this.viewMemberValue.id
			}
			if (this.agGrid) {
				this.setupGrid(this.agGrid)
			}
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			Object.keys(data.errors).forEach(name => {
				errors.push({ name, errors: data.errors[name] })
			})
			data.errors = convertError(errors)
			return Promise.reject(data)
		})
	}

	readMemberDetail = (id) => {
		return Axios.post(`back-office/member-show/${id}`).then(({ data }) => {
			this.setOpenFormType(data.data.member_role_mapping.role_name)
			this.viewMemberValue = data.data
			return data
		})
	}

	// export data from server as a csv
	exportData = () => {
		if (this.agGrid) {
			this.exportLoading = true;
			return Axios.get(`back-office/originator/export`).then(({ data }) => {
				if (data.data && data.data.csv_link) { window.open(data.data.csv_link, '_blank'); }
				return data
			}).catch(e => {
				return Promise.reject(e)
			}).finally(() => this.exportLoading = false)
		}
	}
}

decorate(MemberStore, {
	per_page: observable,
	current_page: observable,
	total: observable,
	agGrid: observable,
	openFormType: observable,
	userInitialValues: observable,
	parent_organisations_list: observable,
	country_list: observable,
	industries_list: observable,
	naceCode_list: observable,
	bankInitialValues: observable,
	bankAccountType: observable,
	currenciesList: observable,
	cityList: observable,
	stateCountyList: observable,
	orgStatusList: observable,
	userPermission: observable,
	organisationID: observable,
	ratingAgencyList: observable,
	permission_list: observable,
	viewMemberValue: observable,
	exportLoading: observable,
	getStatus: action,
	currentTab: observable,
	memberStatus: observable,
	memberRole: observable,
	personList: observable,
	viewID: observable,
	getMemberRole: action,
	handleReset: action,
	onGridChanged: action,
	setupGrid: action,
	createDatasource: action,
	getList: action,
	getPermission: action,
	setOpenFormType: action,
	getParentOrganisationList: action,
	getAllCountries: action,
	getIndustries: action,
	getNaceCode: action,
	getBankAccountTypeList: action,
	getCurrenciesList: action,
	getCityList: action,
	getStateCountyList: action,
	JsonToFormData: action,
	AddMemberOrgDetail: action,
	getOrgStatus: action,
	getRatingAgency: action,
	getPermissionsList: action,
	readMemberDetail: action,
	setCurrentTab: action,
	getPersonList: action,
	exportData: action
})