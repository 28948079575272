import React, { useState } from 'react'
import { Layout, Menu } from 'antd';
import useStore from '../store';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../img/logo.png';
import logo_bull from '../img/logo_bull.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faExchangeAlt, faSitemap, faFileAlt, faCogs, faCircle, faBriefcase, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faUser, faStar, faMoneyBillAlt } from '@fortawesome/free-regular-svg-icons'
const { Sider } = Layout;

const SidebarComponent = observer((props) => {
	const location = useLocation()
	const { UserStore: { menu, user, ledger_base_currency } } = useStore()
	const [openMenu, setOpenMenu] = useState([])
	let menukey = 1
	const icons = {
		1: faTachometerAlt,
		20: faExchangeAlt,
		34: faSitemap,
		40: faUser,
		46: faFileAlt,
		54: faUserCircle,
		60: faCogs,
		74: faMoneyBillAlt,
		114: faBriefcase,
		"0_0": faStar,
		55: faMoneyBill
	}

	// iterate the menu items
	const AppMenu = (menu_item, open = []) => {
		return menu_item ? Object.keys(menu_item)
			.sort((a, b) => (menu_item[a].sequence_no > menu_item[b].sequence_no) ? 1 : ((menu_item[b].sequence_no > menu_item[a].sequence_no) ? -1 : 0))
			.map((key) => {
				let item = menu_item[key]
				if (item.submenu) {
					return (
						<Menu.SubMenu key={item.title + menukey} icon={
							<FontAwesomeIcon icon={icons[item.id] ? icons[item.id] : faCircle} />
						} title={item.title}>
							{AppMenu(item.submenu, [...open, (item.title + menukey++)])}
						</Menu.SubMenu>
					)
				} else {
					if (user && user.user_role && user.user_role.user_role_id !== 1 && [].includes(item.path)) {
						return null
					}
					if (location.pathname.includes(item.path) && openMenu.length <= 0) {
						if (open.length === 0) {
							open.push(item.path)
						}
						setOpenMenu(open)
					}
					return (
						<Menu.Item key={item.path} icon={<FontAwesomeIcon icon={icons[item.id] ? icons[item.id] : faCircle} size="xs" className="mr-10" />}>
							<span>{item.title}</span>
							{(ledger_base_currency) && <Link to={item.path} />}
						</Menu.Item>
					)
				}
			}) : null
	}
	return (
		<Sider width="280" trigger={null} collapsible collapsed={props.collapsed} className="sider_area">
			{ledger_base_currency ? (
				props.collapsed ? (<Link to="/dashboard" className="logo">
					<img src={logo_bull} alt="" />
				</Link>) : (<div className="logo"><img src={logo} alt="" /></div>)
			) : (
				props.collapsed ? (<Link to="/dashboard" className="logo">
					<img src={logo_bull} alt="" />
				</Link>) : (<div className="logo"><img src={logo} alt="" /></div>)
			)}
			<Menu theme="dark" mode="inline" defaultOpenKeys={openMenu} selectedKeys={location.pathname}>
				{AppMenu(menu)}
			</Menu>
		</Sider>
	)
})

export default SidebarComponent
