import React from 'react'
import { Route, Switch } from 'react-router'
import { Apps_RouterConfig } from '../config/RouterConfig'
import PageNotFound from '../pages/PageNotFound'
import useStore from '../store'

const OriginatorAppRouter = () => {
    const { AppsAuthStore: { originator_user } } = useStore()
    return (
        <Switch>
            {
                Apps_RouterConfig.map((item, index) => {
                    var exact = true
                    if (item.errorpage === true) {
                        exact = false
                    }
                    if (originator_user && originator_user.user_role && originator_user.user_role.user_role_id !== 1 && [].includes(item.apps_path)) {
                        return (
                            <Route key={index} exact={exact} path={item.apps_path} >
                                <PageNotFound />
                            </Route>
                        )
                    } else {
                        return (
                            <Route key={index} exact={exact} path={item.apps_path} component={item.component} />
                        )
                    }

                })
            }
        </Switch>
    )
}

export default OriginatorAppRouter