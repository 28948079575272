import React from 'react'
import { observer } from 'mobx-react'
import InputComponent from '../../../../components/InputComponent'
import { vsmMember } from '../../../../config/messages'
import { Credit_Risks } from '../../../../utils/GlobalFunction'
import RatingAgencyElement from '../elements/RatingAgencyElement'
import useStore from '../../../../store'
import { InboxOutlined } from '@ant-design/icons'
import { Checkbox, Col, Divider, Form, message, Row, Upload } from 'antd'
import { useState } from 'react'

const CreditRisksComponent = observer(({ form, handleSubmit, setDisabled, allFiles, updateAllFiles }) => {
	const { MemberStore: { openFormType, creditRiskInitialValue } } = useStore()
	const [renderComp, setRenderComp] = useState(false)

	const handleChange = (() => {
		form.validateFields().then((data) => {
			setDisabled(false)
		}).catch(e => {
			if (e.errorFields && e.errorFields.length > 0) {
				setDisabled(true)
			}
		})
	})

	const uploadPropsExperienceReport = {
		name:"file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['expReport'] ? allFiles['expReport'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, expReport: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeExperienceReport = (file) => {
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Experience Report must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestExperianceReport = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadExperienceReport = (info) => {
		window.open(info.url, "_blank")
	}

	const uploadPropsCreditSafeReport = {
		name: "file",
		accept: ".csv, .xlsx",
		multiple: false,
		fileList: Object.keys(allFiles).length ? allFiles['creditSafeReport'] ? allFiles['creditSafeReport'] : [] : [],
		onChange: ({ fileList: newFileList }) => {
			newFileList.map((x) => {
				let isLt1M = x.size / 1024 / 1024 < 1
				x.valid = isLt1M
				return x
			})
			newFileList = newFileList.filter(x => x.valid === true)
			updateAllFiles(prev => ({ ...prev, creditSafeReport: newFileList.length ? [newFileList[newFileList.length - 1]] : [] }))
		}
	}

	const handleFileUploadChangeCreditSafeReport = (file) => {		
		let isCSV = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		let isLt1M = file.size / 1024 / 1024 < 1

		if (!isCSV) {
			message.error(`Credit Safe Report must be in xls, xlsx, csv format. File Size should be less then 1 MB.`)
			return true
		} else if (!isLt1M) {
			message.error("Please upload PDF file with size of less then 1 MB.")
			return true
		} else {
			return false
		}
	}

	const dummyRequestCreditSafeReport = ({ file, fileList, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok")
		}, 0)
	}

	const onDownloadCreditSafeReport = (info) => {
		window.open(info.url, "_blank")
	}

	const percentagesNode = (e, name) => {
		let value = Number(e.target.value)
		if (value < 10) {
			form.setFieldsValue({ [name]: value.toFixed(3) })
		} else if (value > 10 && value < 100) {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		} else if (value <= 100) {
			form.setFieldsValue({ [name]: value.toFixed(1) })
		} else {
			form.setFieldsValue({ [name]: value.toFixed(2) })
		}
	}

	return (
		<Form
			form={form}
			id={`${openFormType}-2`}
			onFinish={handleSubmit}
			layout='vertical'
			initialValues={creditRiskInitialValue}
		>
			<Row gutter={24}>
				{Credit_Risks.map((item) => {
					return (
						<Col span={24}>
							<Row gutter={24}>
								<Col span={4}>
									<p>{item.title}</p>
								</Col>
								{item.columns_field.map((fields) => {
									return (
										<Col span={4}>
											<InputComponent
												required maxLength={12} onChange={handleChange} onBlur={(e) => percentagesNode(e, fields.name)}
												label={fields.label && fields.label} name={fields.name} placeholder={fields.placeholder}
												tooltip={fields.placeholder}
												rules={fields.validation === 'number' ? vsmMember.validation.common_field_number_val : vsmMember.validation.common_field_val}
											/>
										</Col>
									)
								})}
							</Row>
						</Col>
					)
				})}

				<Divider className='ant-divider-with-text' />

				<Col span={5}>
					<InputComponent
						required label='Arrangement' name='arrangement' placeholder='Arrangement' onChange={handleChange}
						tooltip='One-time setup charge for arranging Membership as indicted on the RSA Offer' onBlur={(e) => percentagesNode(e, 'arrangement')}
						rules={vsmMember.validation.arrangement}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required label='Membership' name='membership' placeholder='Membership' onChange={handleChange}
						tooltip='Monthly charge for Exchange Membership as indicted on the RSA Offer' onBlur={(e) => percentagesNode(e, 'membership')}
						rules={vsmMember.validation.membership}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required label='Processing Commission' name='processing' placeholder='Processing' onChange={handleChange}
						tooltip='It is a one-time fee charged by the lender for the cost incurred by it for processing the loan.'
						rules={vsmMember.validation.processing} onBlur={(e) => percentagesNode(e, 'processing')}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						required label='Trade Commission' name='trade' placeholder='Trade' onChange={handleChange}
						tooltip='The buying or selling of goods or services between people or countries'
						rules={vsmMember.validation.trade} onBlur={(e) => percentagesNode(e, 'trade')}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required label='Creditsafe Score' name='creditsafe_score' placeholder='Creditsafe Score' onChange={handleChange}
						tooltip="A numerical rating that measures a person's likelihood to repay a debt"
						rules={vsmMember.validation.creditsafe_score} onBlur={(e) => percentagesNode(e, 'creditsafe_score')}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange}
						required label="Creditsafe Today's Limit" name='creditsafe_today_limit' placeholder="Creditsafe Today's Limit"
						tooltip="The credit limit is the recommendation of the total amount of credit that should be outstanding at one time."
						rules={vsmMember.validation.creditsafe_today_limit} onBlur={(e) => percentagesNode(e, 'creditsafe_today_limit')}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange}
						required label="d-ETR Stop +" name='d_etr_stop_plus' placeholder="d-ETR Stop +" onBlur={(e) => percentagesNode(e, 'd_etr_stop_plus')}
						tooltip="d-ETR Stop +" rules={vsmMember.validation.d_etr_stop_plus}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange}
						required label="d-ETR Stop -" name='d_etr_stop_minus' placeholder="d-ETR Stop -"
						tooltip="d-ETR Stop -" onBlur={(e) => percentagesNode(e, 'd_etr_stop_minus')}
						rules={vsmMember.validation.d_etr_stop_minus}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						onChange={handleChange}
						required type='select' label="Facility Type" name='facility_type' placeholder="Facility Type"
						options={{
							values: [{ value: 'Fixed', text: 'Fixed' }, { value: 'Variable', text: 'Variable' }]
						}}
						tooltip="Types of facility that has formal financial assistance program offered by a lending institution to help a company that requires operating capital."
						rules={vsmMember.validation.facility_type}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange} onBlur={(e) => percentagesNode(e, 'd_etr_purchase_price')} maxLength={12}
						required label="d-ETR Purchase Price %" name='d_etr_purchase_price' placeholder="d-ETR Purchase Price %"
						tooltip="d-ETR Purchase Price %"
						rules={vsmMember.validation.d_etr_purchase_price}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange}
						required label="Posting Fee" name='posting_fee' placeholder="Posting Fee"
						tooltip="Discretionary charge per posted ETR as indicted on the RSA Offer"
						rules={vsmMember.validation.posting_fee} onBlur={(e) => percentagesNode(e, 'posting_fee')}
					/>
				</Col>
				<Col span={4}>
					<InputComponent
						onChange={handleChange}
						label="CCJ/HCJ/WUP" name='CCJ_HCJ_WUP' placeholder="CCJ/HCJ/WUP"
						tooltip="CCJ/HCJ/WUP" maxLength={100}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange}
						label="MDM/ROM/AQ" name='MDM_ROM_AQ' placeholder="MDM/ROM/AQ"
						tooltip="MDM/ROM/AQ" maxLength={100}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						onChange={handleChange} maxLength={100}
						label="Ticker" name='ticker' placeholder="Ticker"
						tooltip='A ticker is a symbol, a unique combination of letters and numbers that represent a particular stock or security listed on an exchange.'
					/>
				</Col>
				<Col span={5}>
					<Form.Item name="instalment_etr" valuePropName="checked">
						<Checkbox onChange={handleChange}>Instalment ETR</Checkbox>
					</Form.Item>
				</Col>
				<Col span={4}>
					<InputComponent
						onChange={handleChange} onBlur={(e) => percentagesNode(e, 'reserve')}
						label='Reserve' name='reserve' placeholder='Reserve'
						tooltip="The reserve ratio, set by the central bank, is the percentage of a commercial bank's deposits that it must keep in cash as a reserve in case of mass customer withdrawals."
						rules={vsmMember.validation.reserve}
					/>
				</Col>
				<Col span={5}>
					<InputComponent
						required type='select' label='TL level' name='tl_level' placeholder='TL level' onChange={handleChange}
						options={{
							values: [{ value: 'Warning', text: 'Warning' }, { value: 'Enforced', text: 'Enforced' }]
						}}
						tooltip='TL level'
						rules={vsmMember.validation.tl_level}
					/>
				</Col>
				<Col span={6}>
					<Form.Item
						label="Experience Report"
						className="full-width-dragger"
						name="expReport"
						tooltip="This report contains detailed information of your credit/loan history, including identity information, credit accounts, loans, credit cards, payments, and recent enquiries."
						rules={vsmMember.validation.experience_report}
					>
						<Upload.Dragger
							{...uploadPropsExperienceReport}
							beforeUpload={handleFileUploadChangeExperienceReport}
							customRequest={dummyRequestExperianceReport}
							onDownload={onDownloadExperienceReport}
							fileList={Object.keys(allFiles).length ? allFiles['expReport'] ? allFiles['expReport'] : [] : []}
							disabled={false}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Drag Experience Report File
							</p>
						</Upload.Dragger>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item
						label="CreditSafe Report"
						className="full-width-dragger"
						name="creditSafeReport"
						tooltip="Drag PDF CreditSafe Report"
						rules={vsmMember.validation.creditsafe_report}
					>
						<Upload.Dragger
							{...uploadPropsCreditSafeReport}
							beforeUpload={handleFileUploadChangeCreditSafeReport}
							customRequest={dummyRequestCreditSafeReport}
							onDownload={onDownloadCreditSafeReport}
							fileList={Object.keys(allFiles).length ? allFiles['creditSafeReport'] ? allFiles['creditSafeReport'] : [] : []}
							disabled={false}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Drag Credit Safe Report File
							</p>
						</Upload.Dragger>
					</Form.Item>
				</Col>
				<Col span={24}>
					<RatingAgencyElement onChange={handleChange} form={form} setRenderComp={setRenderComp} renderComp={renderComp} />
				</Col>
			</Row>
		</Form>
	)
})

export default CreditRisksComponent