import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react"
import { AgGridReact } from '@ag-grid-community/react'
import { AllModules } from '@ag-grid-enterprise/all-modules';
import LocalGridConfig from '../../../../config/LocalGridConfig';
import NumberFormat from 'react-number-format';
import useStore from '../../../../store';
import moment from 'moment';
import { Card } from 'antd';

const AuditReportWidget = observer((props) => {
    const { DashboardStore, SavedReportStore } = useStore()
    const [chartData, setChartData] = useState(null)

    var columnDefs = [{
        headerName: 'Group On',
        field: 'group_on',
        filter: false,
        sortable: false,
        pinned: 'left',
        width: 130
    }]

    let list_data = []
    let list_object = {}
    let display_key = ''
    let PinnedData = []

    if (chartData) {
        Object.keys(chartData).forEach((key) => {
            let item = chartData[key]
            Object.keys(item).forEach((group) => {
                var value = item[group]
                if (group !== 'columns') {

                    var s_group = group.split('_')
                    if (s_group[0] < 10) { s_group[0] = '0' + s_group[0] }
                    var c_group = s_group.join('-')
                    list_object[group] = {
                        group_on: c_group,
                        group_range: c_group,
                        account_number: key,
                        [key]: {
                            payments: {
                                main: value.payments ? value.payments.main : 0,
                                errors: value.payments ? value.payments.errors : 0
                            },
                            receipts: {
                                main: value.receipts ? value.receipts.main : 0,
                                errors: value.receipts ? value.receipts.errors : 0
                            },
                            external: {
                                main: value.external ? value.external.main : 0,
                                errors: value.external ? value.external.errors : 0
                            }
                        }
                    }
                }
            })
            columnDefs.push({
                headerName: key,
                headerGroupComponentFramework: (params) => {
                    return (
                        <div className="d-flex align-items-center h-100 cs_pointer" onClick={() => {
                        }}>{key}</div>
                    )
                },
                children: [
                    {
                        headerName: 'Receipt',
                        headerComponentFramework: () => {
                            return (
                                <div className="d-flex align-items-center h-100 cs_pointer" onClick={() => {
                                }}>Receipt</div>
                            )
                        },
                        field: `${key}.receipts`,
                        width: 80,
                        cellRendererFramework: (params) => (
                            <div onClick={() => {
                                if (params.data.group_on !== 'Total') {
                                }
                            }} style={styles.cells}>
                                {(params.data && params.data[key] && params.data[key].receipts) ? (
                                    <>
                                        <div><NumberFormat value={params.data[key].receipts.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                        <div className={params.data[key].receipts.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].receipts.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                    </>
                                ) : <div style={styles.cells}>0</div>}
                            </div>
                        ),
                        cellStyle: { 'line-height': '16px', 'padding-top': '7px', 'padding': '7px' },
                        filter: false,
                        sortable: false,
                    },
                    {
                        headerName: 'Payment',
                        headerComponentFramework: () => {
                            return (
                                <div className="d-flex align-items-center h-100 cs_pointer" onClick={() => {
                                }}>Payment</div>
                            )
                        },
                        field: `${key}.payments`,
                        width: 80,
                        cellRendererFramework: (params) => (
                            <div onClick={() => {
                                if (params.data.group_on !== 'Total') {
                                }
                            }} style={styles.cells}>
                                {(params.data && params.data[key] && params.data[key].payments) ? (
                                    <>
                                        <div><NumberFormat value={params.data[key].payments.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                        <div className={params.data[key].payments.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].payments.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                    </>
                                ) : <div style={styles.cells}>0</div>}
                            </div>
                        ),
                        cellStyle: { 'line-height': '16px', 'padding-top': '7px', 'padding': '7px' },
                        filter: false,
                        sortable: false,
                    },
                    {
                        headerName: 'External',
                        headerComponentFramework: () => {
                            return (
                                <div className="d-flex align-items-center h-100 cs_pointer" onClick={() => {
                                }}>External</div>
                            )
                        },
                        field: `${key}.external`,
                        width: 80,
                        cellRendererFramework: (params) => (
                            <div onClick={() => {
                                if (params.data.group_on !== 'Total') {
                                }
                            }} style={styles.cells}>
                                {(params.data && params.data[key] && params.data[key].external) ? (
                                    <>
                                        <div><NumberFormat value={params.data[key].external.main} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                        <div className={params.data[key].external.errors !== 0 ? 'text-danger' : 'text-success'}><NumberFormat value={params.data[key].external.errors} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true} decimalScale={2} /></div>
                                    </>
                                ) : <div style={styles.cells}>0</div>}
                            </div>
                        ),
                        cellStyle: { 'line-height': '16px', 'padding-top': '7px', 'padding': '7px' },
                        filter: false,
                        sortable: false,
                    },
                ],
            })
        })
        let list_total = {
            group_on: 'Total',
            group_range: 'Total',
            account_number: 'Total',
        }
        Object.keys(list_object).forEach((key) => {
            Object.keys(list_object[key]).forEach(account => {
                list_total[account] = list_total[account] || {}
                if (!['group_on', 'group_range', 'account_number'].includes(account)) {
                    Object.keys(list_object[key][account]).forEach(item => {
                        list_total[account][item] = list_total[account][item] || {}
                        Object.keys(list_object[key][account][item]).forEach(value => {
                            list_total[account][item][value] = list_total[account][item][value] || 0
                            list_total[account][item][value] += list_object[key][account][item][value]
                        })
                    })
                }

            })
        })
        PinnedData.push(list_total)
        let prev_key = null
        Object.keys(list_object)
            .sort((a, b) => {
                if (['month', 'week'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
                    var _a = a.split('_')
                    var _b = b.split('_')
                    _a[1] = parseInt(_a[1])
                    _a[0] = parseInt(_a[0])
                    _b[1] = parseInt(_b[1])
                    _b[0] = parseInt(_b[0])
                    if ((_a[1] < _b[1]) || (_a[1] === _b[1] && _a[0] < _b[0])) {
                        return -1;
                    }
                    if (_a[1] > _b[1] || (_a[1] === _b[1] && _a[0] > _b[0])) {
                        return 1;
                    }
                } else if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
                    var dateA = new Date(a), dateB = new Date(b)
                    return dateA - dateB
                } else {
                    if (a < b) { return -1; }
                    if (a > b) { return 1; }
                }
                return 0;
            })
            .forEach((key) => {
                let new_key = ''
                if (prev_key) {
                    if (['day'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
                        let date = new Date(prev_key)
                        while (date < new Date(key)) {
                            date = new Date(date.setDate(date.getDate() + 1))
                            list_data.push({
                                group_on: moment(date).format('YYYY-MM-DD'),
                                group_range: moment(date).format('YYYY-MM-DD'),
                            })
                        }
                    }
                    if (['week', 'month'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
                        let max_limit = 12 // month
                        if (SavedReportStore.previewReportValues.audit_group_on === 'week') {
                            max_limit = 52 // week
                        }
                        new_key = prev_key
                        while (new_key !== key && key !== 'Total') {
                            var _key = new_key.split('_')
                            _key[1] = parseInt(_key[1])
                            _key[0] = parseInt(_key[0])
                            var _temp = _key[0] + 1
                            if (_key[0] >= max_limit) {
                                new_key = (1) + '_' + (_key[1] + 1)
                                display_key = '01-' + (_key[1] + 1)
                            } else {
                                if (_temp < 10) { _temp = '0' + _temp }
                                new_key = (_key[0] + 1) + '_' + _key[1]
                                display_key = _temp + '-' + _key[1]
                            }
                            if (new_key !== key) {
                                list_data.push({
                                    group_on: display_key,
                                    group_range: display_key,
                                })
                            }
                        }
                    }
                    if (['year'].includes(SavedReportStore.previewReportValues.audit_group_on)) {
                        new_key = parseInt(prev_key)
                        while (new_key !== parseInt(key) && key !== 'Total') {
                            new_key = new_key + 1
                            if (new_key !== parseInt(key)) {
                                list_data.push({
                                    group_on: new_key,
                                    group_range: new_key,
                                })
                            }
                        }
                    }

                }
                list_data.push(list_object[key])
                prev_key = key
            })
    }

    const styles = {
        cells: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: '100%'
        }
    }

    useEffect(() => {
        chartData === null &&
            SavedReportStore.ReadReport(props.el.settings_json?.i.report_id).then(async (data) => {
                await DashboardStore.convertAPIData(data.data).then(({ data }) => {
                    if (chartData === data || chartData === null) { setChartData(data) }
                })
            })
    }, [DashboardStore, SavedReportStore, chartData, props.el.settings_json])

    return (
        <Card className="widget_wrapper">
            <div className="counter__widget">
                <div className="counter__widget__description">
                    <div className="counter__widget__name">{props.el.dashboard_name}</div>
                </div>
            </div>
            <div className="ag-theme-alpine" style={{ height: '330px' }}>
                <AgGridReact
                    rowHeight={50}
                    rowData={list_data}
                    modules={AllModules}
                    columnDefs={columnDefs}
                    pinnedBottomRowData={PinnedData}
                    onGridReady={props.onGridReady}
                    onBodyScroll={props.onGridReady}
                    defaultColDef={{ ...LocalGridConfig.defaultColDef, filter: false, floatingFilter: false, autoHeight: false }}
                    onColumnResized={SavedReportStore.onChildnGridChanged}
                    onColumnMoved={SavedReportStore.onChildnGridChanged}
                    onColumnPinned={SavedReportStore.onChildnGridChanged}
                    onSortChanged={SavedReportStore.onChildnGridChanged}
                />
            </div>
        </Card>
    )
})

export default AuditReportWidget;