import React from 'react'
import { EyeFilled, FileTextFilled } from '@ant-design/icons'
import { Button } from 'antd'
import { DateComparator, etrTypeObject, redIconViewLogsStatus } from '../../../../../utils/GlobalFunction'
// import NumberFormat from 'react-number-format';

export const ActionRenderer = (props) => {
	const { openSetViewImportLogDetails, openViewDrawer } = props.agGridReact.props.frameworkComponents
	return (
		<div className="action-column">
			<Button type="text" onClick={() => { openViewDrawer(props.data) }}><EyeFilled /></Button>
			<Button type="text" onClick={() => { openSetViewImportLogDetails(props.data) }}><FileTextFilled className={redIconViewLogsStatus.includes(props.data.status) ? "redVerifyIcon" : "greenVerifyIcon"} /></Button>
		</div>
	)
}


export const gridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Credebtor ID',
		field: 'credebtor.id',
		filter: 'agNumberColumnFilter',
		tooltipField: 'credebtor.id',
		width: 110
	}, {
		headerName: 'Credebtor Name',
		field: 'credebtor.organisation_name',
		tooltipField: 'credebtor.organisation_name',
		width: 185,
	}, {
		headerName: 'Role',
		field: 'type',
		width: 100,
		cellRendererFramework: function (data) {
			return (
				<span>{data.data.etr_type === 'd-ETR' ? 'Debtor' : data.data.etr_type === 'c-ETR' ? 'Creditor' : ''}</span>
			)
		}
	}, {
		headerName: 'ETR Type',
		field: 'etr_type',
		width: 100,
		filter: 'agSetColumnFilter',
		filterParams: {
			defaultToNothingSelected: true,
			buttons: ['apply', 'reset'],
			values: [etrTypeObject[1], etrTypeObject[3], etrTypeObject[4], etrTypeObject[5], etrTypeObject[6]]
		},
	}, {
		headerName: 'Invoice ID',
		field: 'invoice_no',
		tooltipField: 'invoice_no',
		width: 185
	}, {
		headerName: 'POD',
		field: 'po_no',
		tooltipField: 'po_no',
		width: 185
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		tooltipField: 'face_value',
		width: 110
	}, {
		headerName: 'Currency',
		field: 'currency.currency_code',
		tooltipField: 'currency.currency_code',
		width: 110
	}, {
		headerName: 'Invoice Date',
		field: 'invoice_date',
		tooltipField: 'invoice_date',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 130
	}, {
		headerName: 'Created Date',
		field: 'created_at',
		tooltipField: 'created_at',
		filter: 'agDateColumnFilter',
		filterParams: {
			buttons: ['reset'],
			inRangeInclusive: true,
			suppressAndOrCondition: true,
			comparator: DateComparator
		},
		width: 150
	},
	{
		headerName: 'Status',
		filter: 'agSetColumnFilter',
		field: 'status_text',
		width: 130
	},
	{
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 175
	}],
}

export const rsaGridOptions = {
	columnDefs: [{
		headerName: '# ID',
		field: 'id',
		filter: 'agNumberColumnFilter',
		pinned: 'left',
		width: 80
	}, {
		headerName: 'Debtor ID',
		field: 'credebtor.id',
		tooltipField: 'credebtor.id',
		width: 185
	}, {
		headerName: 'Debtor Name',
		field: 'credebtor.organisation_name',
		tooltipField: 'credebtor.organisation_name',
		width: 185
	},
	{
		headerName: 'Offer',
		field: 'discount_price',
		tooltipField: 'discount_price',
		width: 120
	}, {
		headerName: 'Face Value',
		field: 'face_value',
		tooltipField: 'face_value',
		width: 120
	}, {
		headerName: 'Payment Terms',
		field: 'payment_discount_term',
		tooltipField: 'payment_discount_term',
		width: 120
	}, {
		headerName: 'Number of ETR',
		field: 'etr_no',
		tooltipField: 'etr_no',
		filter: 'agNumberColumnFilter',
		width: 120
	}, {
		headerName: 'Revolving Offer Months',
		field: 'etr_months',
		tooltipField: 'etr_months',
		filter: 'agNumberColumnFilter',
		width: 120
	},
	{
		headerName: 'Actions',
		field: 'actions',
		type: 'actionColumn',
		minWidth: 160,
		filter: false,
		sortable: false,
		pinned: 'right',
		width: 175
	}]
}
